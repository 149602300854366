import React, { useContext } from 'react'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import DialogTab from '../../dialog_tab/DialogTab'
import { Tooltip } from 'react-tooltip'

/**
 * Визуальный компонент отображает информацию о выбранном пользователе
 * 
 */
const MyCompanyUserInfo = () => {
    const { CompanyAdminStore, DialogTabStore, userStore } = useContext(Context)

    const onReturnButtonClick = () => {
        CompanyAdminStore.setIsUserCreating(false)
        CompanyAdminStore.setIsUserEditing(false)
        CompanyAdminStore.setSelectedUser(null)
    }

    const onBlockButtonClick = () => {
        DialogTabStore.setParentName('CompanyUserInfo')
        DialogTabStore.setDialogTabIsOpen(true)      
        if (CompanyAdminStore.selectedUser.is_active) {
            DialogTabStore.setDialogTabTitle("Блокировка пользователя") 
            DialogTabStore.setDialogTabText("Вы уверены, что хотите заблокировать этого пользователя?") 
        } else {
            DialogTabStore.setDialogTabTitle("Снятие блокировки") 
            DialogTabStore.setDialogTabText("Вы уверены, что хотите разблокировать этого пользователя?") 
        } 
    }
 
    const handleBlockUserClick = () => {
        if (CompanyAdminStore.selectedUser.is_active)
            CompanyAdminStore.editUser({is_active: false}, CompanyAdminStore.selectedUser.id)
        else 
            CompanyAdminStore.editUser({is_active: true}, CompanyAdminStore.selectedUser.id)

        DialogTabStore.setDialogTabIsOpen(false) 
    }

    return (
        <div id='company-user-info' className='tw-w-full tw-min-w-fit tw-flex tw-flex-col tw-justify-between tw-h-[calc(100%_-_3rem)] tw-rounded-md tw-bg-white'>
            <dl>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Фамилия</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{CompanyAdminStore.selectedUser.last_name}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Имя</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{CompanyAdminStore.selectedUser.first_name}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Логин</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{CompanyAdminStore.selectedUser.username}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Телефон</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{CompanyAdminStore.selectedUser.phone_number}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Email</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{CompanyAdminStore.selectedUser.email}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ID</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{CompanyAdminStore.selectedUser.id}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Статус</dt>
                    <dd className={`${!CompanyAdminStore.selectedUser.is_active && 'tw-text-red-400'} tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0`}>
                        {CompanyAdminStore.selectedUser.is_active ? "Активен" : "Не активен"}
                    </dd>
                </div>
            </dl>

            <div className='tw-flex tw-justify-center'>
                <button className='tw-w-24 tw-m-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white disabled:tw-bg-gray-300 disabled:tw-border-gray-300  hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600' 
                    type='button'
                    onClick={onReturnButtonClick}
                >
                    Назад
                </button>
                <button className='tw-m-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700 
                    hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 
                    focus-visible:tw-outline-gray-600 disabled:tw-border-gray-400 disabled:tw-text-gray-400' 
                    type='button'
                    disabled={!userStore.checkPermission("company.my.user:edit")}
                    onClick={onBlockButtonClick}
                    data-tooltip-id="company-user-info-tooltip" data-tooltip-delay-show={1000}
                        data-tooltip-content={`${!userStore.checkPermission("company.my.user:edit") 
                            ? 'Для редактирования пользователей запросите повышение привилегий у администратора' 
                            : ''}`}
                >
                    {CompanyAdminStore.selectedUser.is_active ? "Заблокировать" : "Разблокировать"} 
                </button>
            </div>
            <DialogTab 
                parentName='CompanyUserInfo'
                dialogTabFunction={handleBlockUserClick}
            />
            <Tooltip id="company-user-info-tooltip" className="tw-max-w-sm" place="top"/>
        </div>
    )
}

export default observer(MyCompanyUserInfo)