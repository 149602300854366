import React, { useContext } from 'react'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'


/**
 * Визуальный компонент отображает панель настроек типов действий для отчета по модулю "Планировщик"
 * 
 * @param {Boolean} reportType Вид отчета
 * @param {Function} onFilterChange Функция обработки изменения фильтра
 * 
 */
const ReportActionPanel = ({reportType, onFilterChange}) => {
    const { plannerStore } = useContext(Context)

    const handleDoneFilterChange = () => {
        plannerStore.setReportFilters('isDoneAction', !plannerStore.reportFilters.isDoneAction, true)
        onFilterChange(true)
    }

    const handleOutdatedFilterChange = () => {
        plannerStore.setReportFilters('isOutdatedAction', !plannerStore.reportFilters.isOutdatedAction, true)
        onFilterChange(true)
    }

    const handlePlannedFilterChange = () => {
        plannerStore.setReportFilters('isPlannedAction', !plannerStore.reportFilters.isPlannedAction, true)
        onFilterChange(true)
    }

    const handleByMeFilterChange = () => {
        plannerStore.setReportFilters('isAssignedByMe', !plannerStore.reportFilters.isAssignedByMe, true)
        onFilterChange(true)
    }

    return (
        <>
            { reportType &&
                <div className='tw-my-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2 tw-pt-2 tw-border-gray-300'>
                    <input
                        id="by-me"
                        type='checkbox'
                        className={`tw-block tw-rounded-sm tw-border
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                tw-ring-gray-400
                            `}
                        checked={plannerStore.reportFilters.isAssignedByMe}
                        onChange={handleByMeFilterChange}
                        disabled={plannerStore.reportFilters.isAllActions}
                    />
                    <label htmlFor="by-me" className='tw-text-sm tw-text-gray-900'>Назначенные мной действия</label>
                </div>
            }
            { reportType === 'actions' &&
                <>
                    <div className='tw-mt-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                        <input
                            id="completed"
                            type='checkbox'
                            className={`tw-block tw-rounded-sm tw-border
                                    tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                    focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                    tw-ring-gray-400
                                `}
                            checked={plannerStore.reportFilters.isDoneAction}
                            onChange={handleDoneFilterChange}
                        />
                        <label htmlFor="completed" className='tw-text-sm tw-text-gray-900'>Выполненные действия</label>
                    </div>
                    <div className='tw-mt-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                        <input
                            id="expired"
                            type='checkbox'
                            className={`tw-block tw-rounded-sm tw-border
                                    tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                    focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                    tw-ring-gray-400
                                `}
                            checked={plannerStore.reportFilters.isOutdatedAction}
                            onChange={handleOutdatedFilterChange}
                        />
                        <label htmlFor="expired" className='tw-text-sm tw-text-gray-900'>Просроченные действия</label>
                    </div>
                    <div className='tw-mt-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                        <input
                            id="planned"
                            type='checkbox'
                            className={`tw-block tw-rounded-sm tw-border
                                    tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                    focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                    tw-ring-gray-400
                                `}
                            checked={plannerStore.reportFilters.isPlannedAction}
                            onChange={handlePlannedFilterChange}
                        />
                        <label htmlFor="planned" className='tw-text-sm tw-text-gray-900'>Запланированные действия</label>
                    </div>
                </>
            }
        </>
    )
}

export default observer(ReportActionPanel)