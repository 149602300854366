import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { serviceMessageTimeOut } from '../../../../../config/constTypes'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'

  
/**
 * Визуальный компонент отображает модальное окно запроса действия у пользователя при сохранении записи
 *
 * @param {Boolean} isVisible Признак видимости (отображения) модального окна
 * @param {Function} onCloseModal Обработчик клика мыши на кнопке закрытия модального окна
 * @param {Object[]} recordInputFormQuestions Массив вопросов
 * @param {Object} formDataObject Значения полей записи
 * @param {Function} saveRecord Функция сохранения записи
 * @param {Object} selectedDataObject Редактируемая запись
 * 
 */
const RecordInputForm = ({isVisible, onCloseModal, recordInputFormQuestions, formDataObject, saveRecord, selectedDataObject}) => {
    const [questions, setQuestions] = useState([])
    const [args, setArgs] = useState([])

    const {
        handleSubmit,
    } = useForm()

    const handleAnswerRadioClick = (questionForm, form_option) => {
        let formArgs = args.filter(item => item.form_id !== form_option.form_id)
        formArgs.push(form_option)

        setArgs(formArgs)

        setQuestions(questions.map(form => {
            return {...form, choices:
                form.choices.map((option) => {
                    if (form.id === form_option.form_id && option.id === form_option.id)
                        return {...option, isChecked: true}
                    else if (form.id === form_option.form_id && option.id !== form_option.id)
                        return {...option, isChecked: false}
                    
                    return option
                })
            }
        }))
    }
    
    const handleAnswerCheckboxClick = (questionForm, form_option) => {
        const foundOption = args.find(item => item.id === form_option.id)
        const foundOptions = args.filter(item => item.form_id === form_option.form_id)
        
        if (foundOptions.length < questionForm.max_choices || foundOption) {
            if (foundOption)
                setArgs(args.filter(item => item.id !== form_option.id))
            else 
                setArgs([...args, form_option])
            
            setQuestions(questions.map(form => {
                return {...form, choices:
                    form.choices.map((option) => {
                        if (option.id === form_option.id)
                            return {...option, isChecked: !form_option.isChecked}

                        return option
                    })
                }
            }))
        }
    }

    const onSubmit = () => {
        let answeredQuestions = 0,
            chosenArgs = {}

        setQuestions(questions.map(question => {
            if (args.find(item => item.form_id === question.id)) {
                answeredQuestions++
                return {...question, notAnswered: false}
            } else
                return {...question, notAnswered: true}
        }))

        if (answeredQuestions < questions.length) {
            toast.error('Пожалуйста, ответьте на все вопросы', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        } else {
            args.map(item => {
                chosenArgs = {...chosenArgs, ...item.args}
            })

            if (selectedDataObject)
                saveRecord(selectedDataObject.record_id, {...formDataObject, arguments: chosenArgs})
            else
                saveRecord({...formDataObject, arguments: chosenArgs})
            
            onCloseModal()
        }
    }

    useEffect(() => {
        if (isVisible)
            setQuestions(recordInputFormQuestions.blocks[0].input_forms.map(form => {
                return {...form, choices:
                    form.choices.map((option, index) => {
                        return {...option,
                            form_id: form.id,
                            id: form.id + '_' + (index + 1),
                            isChecked: false
                        }
                    })
                }
            }))
        else {
            setQuestions([])
            setArgs([])
        }
    }, [isVisible])

    return (
        <Transition.Root show={isVisible} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={onCloseModal}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-out tw-duration-300"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in tw-duration-200"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
                    <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="tw-ease-out tw-duration-300"
                            enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                            enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leave="tw-ease-in tw-duration-200"
                            leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                        >
                            <Dialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-text-left tw-shadow-xl 
                                                    tw-transition-all tw-max-h-[calc(100vh_-_2rem)] sm:tw-my-4 sm:tw-w-full sm:tw-max-w-md">
                                <div className="tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 sm:tw-p-6 sm:tw-pb-4">
                                    <div className="sm:tw-flex sm:tw-items-start tw-flex tw-flex-col tw-max-h-[calc(100vh_-_4rem)]">
                                        <form className=" tw-w-full tw-mt-3 tw-text-left sm:tw-ml-2 sm:tw-mt-0 sm:tw-text-left tw-overflow-y-auto">
                                            {questions.map(form => 
                                                <Fragment key={form.id}>
                                                    <div className={`tw-mt-1 tw-font-semibold tw-text-md  sm:tw-col-span-2 sm:tw-mt-0 tw-flex tw-items-center 
                                                        ${form.notAnswered ? 'tw-text-red-500' : 'tw-text-gray-900'}`}
                                                    >
                                                        <p>{form.question}</p>
                                                        <span>
                                                            { form.notAnswered && 
                                                                <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-ml-1 tw-text-red-500" aria-hidden="true"/> 
                                                            }
                                                        </span>
                                                    </div>
                                                    {form.choices.map((option, index) => {
                                                        return <div key={form.id + '_' + index} className='tw-text-sm tw-my-4 tw-flex tw-flex-row tw-items-center'>
                                                            <input
                                                                checked={false || option.isChecked}
                                                                id={option.id}
                                                                type={form.max_choices === 1 ? "radio" : "checkbox"}
                                                                className='tw-mr-4 tw-cursor-pointer'
                                                                name={option.id}
                                                                onChange={() => {form.max_choices === 1 ? handleAnswerRadioClick(form, option) : handleAnswerCheckboxClick(form, option)}}
                                                            />
                                                            <label className='tw-cursor-pointer' htmlFor={option.id}>{option.text}</label>
                                                        </div>
                                                    })}
                                                </Fragment>
                                            )}
                                        </form>
                                        <div className='tw-my-4 tw-flex tw-justify-center tw-w-full'>
                                            <button className='tw-w-24 tw-m-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white disabled:tw-bg-gray-300 disabled:tw-border-gray-300  hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600' 
                                                type='button'
                                                onClick={handleSubmit(onSubmit)}
                                            >
                                                ОК
                                            </button>
                                            <button className='tw-w-24 tw-m-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700 hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600' 
                                                type='button'
                                                onClick={onCloseModal}
                                            >
                                                Отмена 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default RecordInputForm