import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'
import { toHHMMSS } from '../../../../../functions/toHHMMSS'


/**
 * Визуальный компонент отображает содержимое файла видео в формате mp4, прикрепленного к записи
 * 
 * @param {Object} file Файл видео
 * @param {String} fileName Имя файла
 * 
 */
const VideoPlayer = ({file, fileName}) => {
    const [videoScale, setVideoScale] = useState(100)
    const [src, setSrc] = useState([])
    const [duration, setDuration] = useState(0)
    const [played, setPlayed] = useState(0)

    const handleProgress = (info) => {
        setPlayed(info.playedSeconds)
    }

    const handleScaleDecrease = () => {
        if (videoScale > 10) {
            setVideoScale(videoScale - 10)
        }
    }

    const handleScaleIncrease = () => {
        if (videoScale < 100) {
            setVideoScale(videoScale + 10)
        }
    }

    useEffect(() => {
        setSrc([{src: file, type: 'video/mp4'}])
    }, [])

    return (
        <div className='tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-text-white tw-bg-black'>
            <div className='tw-text-sm tw-text-center tw-h-8 tw-py-1 tw-text-white tw-bg-gray-600'>
                {fileName}
            </div>
            <div className='tw-relative tw-grow tw-overflow-auto'>
                <ReactPlayer
                    className="tw-absolute tw-top-0 tw-left-0 tw-mx-auto tw-ring-media-focus tw-data-[focus]:ring-4"
                    url={src}
                    controls={true}
                    width={videoScale + '%'}
                    height={videoScale + '%'}
                    progressInterval={1000}
                    onProgress={handleProgress}
                    onDuration={(seconds) => setDuration(seconds)}
                />
            </div>
            <div className='tw-text-xs tw-font-semibold tw-h-8 tw-flex tw-flex-row tw-justify-between tw-items-center tw-px-4 tw-border-t tw-border-gray-300'>
                <div className='tw-text-left tw-py-1'>
                        Просмотрено: {toHHMMSS(Math.floor(played))}/{toHHMMSS(Math.floor(duration))}
                </div>
                <div className='tw-flex tw-flex-row tw-justify-end tw-items-center'>
                    <button 
                        className='tw-text-gray-200 hover:tw-text-white focus-visible:tw-text-gray-800 focus-visible:tw-outline 
                                focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-800 focus-visible:tw-rounded-sm'
                        type='button'
                        onClick={handleScaleDecrease}
                    >
                        <MinusIcon  className="tw-w-5 tw-h-5" aria-hidden="true"/>
                    </button>
                    <span className='tw-text-center tw-w-24'>Масштаб {videoScale + '%'}</span>
                    <button 
                        className='tw-text-gray-200 hover:tw-text-white focus-visible:tw-text-gray-800 focus-visible:tw-outline 
                                focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-800 focus-visible:tw-rounded-sm'
                        type='button'
                        onClick={handleScaleIncrease}
                    >
                        <PlusIcon  className="tw-w-5 tw-h-5" aria-hidden="true"/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default VideoPlayer