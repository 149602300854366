import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ArrowUpTrayIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { checkDataModelPermission } from '../../../../../functions/checkPermissions'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/**
 * Визуальный компонент отображает кнопку с выпадающим меню "Экспорт"
 * 
 * @param {Object} dataModel Выбранная таблица
 * @param {Boolean} notPrintRecord Признак недоступности пунктов меню экспорта выбранной записи таблицы
 * @param {Boolean} itemHistory Признак экспорта истории изменений записи таблицы
 * @param {Function} onPrintTableClick Обработчик клика мыши на кнопке экспорта всех записей в файл Excel
 * @param {Function} onPrintRecordClick Обработчик клика мыши на кнопке экспорта выбранной записи в файл Excel
 * 
 */
function DataExportMenu({dataModel, notPrintRecord, itemHistory, onPrintTableClick, onPrintRecordClick}) {
    const [isRead, isReadDescription] = checkDataModelPermission(dataModel, 'read') 

    return (
        <>
            <Menu as='div' className='tw-relative tw-inline-block tw-text-left'>
                <div data-tooltip-id="export-tooltip" 
                    data-tooltip-content={isRead || !dataModel
                        ? "Экспорт данных"
                        : isReadDescription}
                >
                    <Menu.Button 
                        className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 tw-ring-gray-300 tw-shadow-md hover:tw-bg-gray-600  disabled:tw-bg-gray-400'
                        disabled={!isRead}
                    >
                        <ArrowUpTrayIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="tw-transition tw-ease-out tw-duration-100"
                    enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                    enterTo="tw-transform tw-opacity-100 tw-scale-100"
                    leave="tw-transition tw-ease-in tw-duration-75"
                    leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                    leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                >
                    <Menu.Items className="tw-absolute tw-right-0 tw-z-30 tw-mt-2 tw-w-64 tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                        <div className="tw-py-1">
                            { onPrintTableClick &&
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                                'tw-block tw-w-full tw-px-4 tw-py-2 tw-text-sm tw-text-left hover:tw-cursor-pointer disabled:tw-text-gray-300'
                                            )}
                                            disabled={!dataModel || itemHistory}
                                            onClick={() => onPrintTableClick('xlsx')}
                                        >
                                            Экспорт таблицы в XLSX
                                        </button>
                                    )}
                                </Menu.Item>
                            }
                            { onPrintTableClick &&
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                                'tw-block tw-w-full tw-px-4 tw-py-2 tw-text-sm tw-text-left hover:tw-cursor-pointer disabled:tw-text-gray-300'
                                            )}
                                            disabled={!dataModel || itemHistory}
                                            onClick={() => onPrintTableClick('ods')}
                                        >
                                            Экспорт таблицы в ODS
                                        </button>
                                    )}
                                </Menu.Item>
                            }
                            { onPrintRecordClick &&
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            type='button'
                                            className={classNames(
                                                active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                                'tw-block tw-w-full tw-px-4 tw-py-2 tw-text-sm tw-text-left hover:tw-cursor-pointer disabled:tw-text-gray-300'
                                            )}
                                            disabled={notPrintRecord}
                                            onClick={() => onPrintRecordClick('xlsx')}
                                        >
                                            {itemHistory ? 'Экспорт истории записи в XLSX' : 'Экспорт записи в XLSX'}
                                        </button>
                                    )}
                                </Menu.Item>
                            }
                            { onPrintRecordClick &&
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            type='button'
                                            className={classNames(
                                                active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                                'tw-block tw-w-full tw-px-4 tw-py-2 tw-text-sm tw-text-left hover:tw-cursor-pointer disabled:tw-text-gray-300'
                                            )}
                                            disabled={notPrintRecord}
                                            onClick={() => onPrintRecordClick('ods')}
                                        >
                                            {itemHistory ? 'Экспорт истории записи в ODS' : 'Экспорт записи в ODS'}
                                        </button>
                                    )}
                                </Menu.Item>
                            }
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
            <Tooltip id="export-tooltip" place="top"/>
        </>
    )
}

export default DataExportMenu