import { $importHost } from "../http/http"
import { documentAPI } from "../config/constsURN"


/**
 * Класс реализует API для работы с шаблонами импорта
 * @class
 */
class ImporterService {

    /**
     * Отправляет запрос на выполнение импорта данных с сервера домена
     * @static
     * @param {Object} config Конфигурация подключения к серверу домена
     * @returns {Object} Импортируемые данные
     */
    static async importServerData(config) {
        const {data} = await $importHost.post(documentAPI.IMPORT_DATA_API, config)
        return data
    }

    // временна функция для отладки
    static async importViewData(domain) {
        const {data} = await $importHost.get(documentAPI.IMPORT_VIEW_API + '/' + domain + '/users' )
        return data
    }
    
    // временна функция для отладки
    static async migrateData(domain) {
        const {data} = await $importHost.get(documentAPI.IMPORT_MIGRATE_API + '/' + domain)
        return data
    }
    
    /**
     * Отправляет запрос на получение списка существующих шаблонов импорта
     * @static
     * @param {Number} count Лимит на количество возвращаемых шаблонов
     * @returns {Object[]} Массив шаблонов импорта
     */
    static async getImporters(count = 10) {
        const {data} = await $importHost.get(documentAPI.IMPORTER_API + '/search', {params: {count, total: true}})
        return data
    }

    /**
     * Отправляет запрос на получение списка всех существующих шаблонов импорта с учетом фильтров
     * @static
     * @param {Object[]} filters Фильтры для возвращаемых шаблонов импорта
     * @returns {Object[]} Массив шаблонов импорта
     */
    static async getAllImporters(filters) {
        let data = []
        let response
        let offset = 0
        const count = 50

        do {
            response = await $importHost.get(documentAPI.IMPORTER_API + '/search', {params: {offset, count, total: true, filter: filters}})
            data = data.concat(response.data)
            offset += count
        } while (response?.data?.length === count)

        return data
    }

    /**
     * Отправляет запрос на получение одного шаблона импорта
     * @static
     * @param {String} id ID шаблона импорта
     * @returns {Object} Шаблон импорта
     */
    static async getOneImporter(id) {
        const {data} = await $importHost.get(documentAPI.IMPORTER_API + '/' + id)
        return data
    }
    
    /**
     * Отправляет запрос на создание одного шаблона импорта
     * @static
     * @param {Object} importer Шаблон импорта
     * @returns {String} ID шаблона импорта
     */
    static async createImporter(importer) {
        const {data} = await $importHost.post(documentAPI.IMPORTER_API, importer)
        return data
    }

    /**
     * Отправляет запрос на обновление одного шаблона импорта
     * @static
     * @param {Object} importer Шаблон импорта
     * @returns {String} ID шаблона импорта
     */
    static async updateImporter(importer) {
        const {data} = await $importHost.put(documentAPI.IMPORTER_API + '/' + importer.id, importer)
        return data
    }

    /**
     * Отправляет запрос на удаление одного шаблона импорта
     * @static
     * @param {String} id ID шаблона импорта
     * @returns null
     */
    static async deleteImporter(id) {
        const {data} = await $importHost.delete(documentAPI.IMPORTER_API + '/' + id)
        return data
    }

    /**
     * Отправляет запрос на выполнение предварительного импорта данных из файла по выбранному шаблону. 
     * В результате импорта станет возможным предварительный просмотр данных
     * @static
     * @param {String} id ID выбранного шаблона импорта
     * @param {Object} config Конфигурация импорта данных
     * @returns {Object} ID процесса импорта
     */
    static async importFileData(id, config) {
        const {data} = await $importHost.post(documentAPI.IMPORTER_RUN_API + '/templates/' + id + '/excel', config)
        return data
    }

    /**
     * Отправляет запрос на подтверждение импорта данных из файла по выбранному шаблону. 
     * Данные фиксируются в таблицах
     * @static
     * @param {String} id ID процесса импорта
     * @returns null
     */
    static async confirmDataImport(id) {
        const {data} = await $importHost.post(documentAPI.IMPORTER_PROCESS_API + '/' + id + '/commit')
        return data
    }

    /**
     * Отправляет запрос на отмену импорта данных из файла по выбранному шаблону. 
     * Данные будут удалены из таблиц
     * @static
     * @param {String} id ID процесса импорта
     * @returns null
     */
    static async abortDataImport(id) {
        const {data} = await $importHost.post(documentAPI.IMPORTER_PROCESS_API + '/' + id + '/abort')
        return data
    }

    /**
     * Отправляет запрос на получение информации о процессе импорта данных 
     * @static
     * @param {String} id ID процесса импорта
     * @returns {Object} ID процесса импорта
     */
    static async getImportProcess(id) {
        const {data} = await $importHost.get(documentAPI.IMPORTER_PROCESS_API + '/' + id)
        return data
    }

    /**
     * Отправляет запрос на обновление таймаута предварительного просмотра импортируемых данных 
     * @static
     * @param {String} id ID процесса импорта
     * @returns {Object} ID процесса импорта
     */
    static async refreshImportTimeout(id) {
        const {data} = await $importHost.post(documentAPI.IMPORTER_PROCESS_API + '/' + id + '/refresh')
        return data
    }

}

export default ImporterService