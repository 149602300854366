import React, { useContext, useEffect } from 'react'
import { Context } from '../../../..'
import PageTitle from '../common/panels/PageTitle'
import { observer } from 'mobx-react-lite'
import PlannerTabs from './PlannerTabs'
import PlannerPanel from './PlannerPanel'
import { useLocation } from 'react-router-dom'

/**
 * Компонент реализует логику работы с планировщиком действий
 * 
 */
const PlannerContainer = () => {
    const { plannerStore, userStore, docStore } = useContext(Context)
    const location = useLocation()
    const recommendedFilters = JSON.parse(localStorage.getItem('recommendedFilters'))
    const plannedFilters = JSON.parse(localStorage.getItem('plannedFilters'))

    useEffect(() => {
        if (plannedFilters)
            plannerStore.setPlannedFilters('', plannedFilters, false)
        docStore.getOneDataModel('recommended_actions', (data) =>  plannerStore.setRecommendedDataModel(data))
        docStore.getOneDataModel('planned_actions', (data) =>  plannerStore.setPlannedDataModel(data))
        plannerStore.getCompanyUsers()
        plannerStore.getSecurityMeasures()
        plannerStore.getActions('my_actions', [], false)
        plannerStore.getRecommendedFiltersList()
    }, [])

    useEffect(() => {
        if (plannerStore.recommendedDataModel)
            if (recommendedFilters) {
                plannerStore.setSavedRecommendedFilters(recommendedFilters)
                plannerStore.getActions('recommended_actions', plannerStore.getAppliedRecommendedFilters(recommendedFilters), false)
            } else {
                plannerStore.getActions('recommended_actions', [], false)
            }
    }, [plannerStore.recommendedDataModel])

    useEffect(() => {
        if (plannerStore.plannedDataModel) {
            if (location.search === '') {
                if (plannedFilters) {
                    plannerStore.getActions('planned_actions', plannerStore.checkActionFilters(userStore.user.email), false)
                } else {
                    const today = new Date()
                    const nextMonth = today.setMonth(today.getMonth() + 1)
                    plannerStore.setPlannedFilters('tillDate', nextMonth, true)
                    const actionFilters = plannerStore.checkActionFilters(userStore.user.email)
                    plannerStore.getActions('planned_actions', actionFilters, false)
                }
            }
        }
    }, [plannerStore.plannedDataModel])

    return (
        <>
            <div className='tw-flex tw-items-center'>
                <PageTitle title='Планировщик' />
                <PlannerTabs/>
            </div>
            
            <div id='planner-page' className='tw-h-full tw-max-h-full tw-w-full tw-flex tw-flex-row tw-gap-x-8'>
                <PlannerPanel/>
            </div>
        </>
    )
}

export default observer(PlannerContainer)