import { makeAutoObservable } from "mobx"
import { toast } from "react-toastify"
import DocumentService from "../services/DocumentService"
import AuthService from "../services/AuthService"
import { showErrorToast } from "../functions/errorHandlers"
import { getFieldValue, serviceMessageTimeOut, responseTimeOut } from "../config/constTypes"

/**
 * Класс реализует хранилище информации о применяемых фильтрах
 * @class
 *
 * @property {Boolean} isFiltering Применение фильтров
 * @property {Object[]} selectedFilters  Массив используемых фильтров
 * @property {Object[]} filteredFields  Массив полей, к которым применяются фильтры
 * @property {Object[]} searchFilters  Массив полей, по которым происходит поиск
 * @property {String} searchField Значения поля поиска
 * @property {Boolean} isFiltersOpen Признак видимости окна настройки фильтров 
 * @property {Boolean} isPlannedFilters Признак видимости фильтров для запланированных действий  
 * @property {Object[]} recommendedFilters  Массив фильтров для рекомендованных действий
 * @property {Object[]} savedRecommendedFilters Массив фильтров для рекомендованных действий
 * @property {Object} recommendedDataModel Данные таблицы рекомендованных действий
 * @property {Object} plannedDataModel Данные таблицы запланированных действий
 * @property {Object} plannedFilters Данные фильтров таблицы запланированных действий
 */
class PlannerStore {
    tabs = [
        {name: 'Действия', value: 'actions'},
        {name: 'Календарь', value: 'calendar'},
    ]
    dataModel = null
    selectedTab = 'actions'
    selectedAction = null
    myActionRecordID = null
    editedMyAction = null
    isLoading = false
    editMode = null
    isRecommendedActionsLoading = false
    isMyActionsLoading = false
    isPlannedActionsLoading = false
    isUpdatingActionList = false
    isSortingRecommendedActions = false
    recommendedActions = []
    myActions = []
    plannedActions = []
    periodicActions = []
    calendarActions = []
    companyUsers = []
    isAllUsers = false
    isFilterChanged = false
    isFiltersOpen = false
    isPlannedFilters = true
    securityMeasuresCategories = []
    recommendedFilters = []
    savedRecommendedFilters = []
    recommendedDataModel = null
    plannedDataModel = null
    isActionFormOpen = false
    isDoneFormOpen = false
    calendarRange = null
    calendarDate = null
    plannedFilters = {
        fromDate: null,
        tillDate: null,
        isDoneActionShow: false,
        isOutdatedActionShow: true,
        isPlannedActionShow: true,
        isMyRecommendedActionShow: true,
        isAllActions: false,
        isAssignedToMe: true,
        isAssignedByMe: false,
        isGrouped: false
    }
    reportFilters = {
        fromDate: null,
        tillDate: null,
        isDoneAction: true,
        isOutdatedAction: true,
        isPlannedAction: true,
        isAllActions: false,
        isAssignedByMe: false,
        selectedUser: null,
        selectedType: null
    }
    plannedCategoriesDefaultOpen = {}
    recommendedCategoriesDefaultOpen = {}

    /**
    * Конструктор с указанием, что все свойства класса observable
    * @constructor
    */
    constructor(){
        makeAutoObservable(this)
    }

    /**
     * Метод осуществляет сохранение информации об открытых категориях запланированных действий
     * @method
     *
     * @param {Object} data Информации об открытых категориях запланированных действий
     */
    setPlannedCategoriesDefaultOpen(data) {
        sessionStorage.setItem('plannedCategoriesDefaultOpen', JSON.stringify(data))
        this.plannedCategoriesDefaultOpen = data
    }

    /**
     * Метод осуществляет сохранение информации об открытых категориях рекомендованных действий
     * @method
     *
     * @param {Object} data Информации об открытых категориях рекомендованных действий
     */
    setRecommendedCategoriesDefaultOpen(data) {
        sessionStorage.setItem('recommendedCategoriesDefaultOpen', JSON.stringify(data))
        this.recommendedCategoriesDefaultOpen = data
    }

    /**
     * Метод осуществляет сохранение текущей выбранной таблицы с действиями
     * @method
     *
     * @param {Object} dataModel Текущая таблица
     */
    setDataModel(dataModel) {
        this.dataModel = dataModel
    }
    
    /**
     * Метод осуществляет сохранение текущей выбранной вкладки планировщика
     * @method
     *
     * @param {String} tab Текущая вкладка
     */
    setSelectedTab(tab) {
        this.selectedTab = tab
    }

    /**
     * Метод осуществляет сохранение текущего выбранного действия
     * @method
     *
     * @param {Object} action Действие
     */
    setSelectedAction(action) {
        this.selectedAction= action
    }

    /**
     * Метод осуществляет сохранение record_id текущего выбранного действия
     * @method
     *
     * @param {String} record_id Действие
     */
    setMyActionRecordID(record_id) {
        this.myActionRecordID= record_id
    }

    /**
     * Метод осуществляет сохранение текущего редактируемого действия из "Моих действий"
     * @method
     *
     * @param {Object} action Действие
     */
    setEditedMyAction(action) {
        this.editedMyAction= action
    }

    /**
     * Метод осуществляет сохранение режима редактирования действия
     * @method
     *
     * @param {Object} mode Режим редактирования действия
     */
    setEditMode(mode) {
        this.editMode = mode
    }

    /**
     * Метод сохраняет признак наличия изменений в списке моих действий
     * @method
     * 
     * @param {Boolean} bool Признак наличия изменений
     */
    setIsUpdatingActionList(bool) {
        this.isUpdatingActionList = bool
    }

    /**
     * Метод сохраняет признак необходимости повторной сортировки списка рекомендованных действий
     * @method
     * 
     * @param {Boolean} bool Признак необходимости повторной сортировки
     */
    setIsSortingRecommendedActions(bool) {
        this.isSortingRecommendedActions = bool
    }

    /**
     * Метод сохраняет признак загрузки списка рекомендуемых действий
     * @method
     * 
     * @param {Boolean} bool Признак загрузки
     */
    setIsRecommendedActionsLoading(bool) {
        this.isRecommendedActionsLoading = bool
    }

    /**
     * Метод сохраняет признак загрузки списка моих действий
     * @method
     * 
     * @param {Boolean} bool Признак загрузки
     */
    setIsMyActionsLoading(bool) {
        this.isMyActionsLoading = bool
    }

    /**
     * Метод сохраняет признак загрузки списка запланированных действий
     * @method
     * 
     * @param {Boolean} bool Признак загрузки
     */
    setIsPlannedActionsLoading(bool) {
        this.isPlannedActionsLoading = bool
    }

    /**
     * Метод осуществляет сохранение списка рекомендованных действий
     * @method
     *
     * @param {Object[]} actions Список действий
     */
    setRecommendedActions(actions) {
        this.recommendedActions = actions
    }

    /**
     * Метод осуществляет сохранение списка моих действий
     * @method
     *
     * @param {Object[]} actions Список действий
     */
    setMyActions(actions) {
        this.myActions = actions
    }

    /**
     * Метод осуществляет сохранение списка запланированных действий
     * @method
     *
     * @param {Object[]} actions Список действий
     */
    setPlannedActions(actions) {
        this.plannedActions = actions
    }

    /**
     * Метод осуществляет сохранение списка запланированных действий
     * @method
     *
     * @param {Object[]} actions Список действий
     */
    setPeriodicActions(actions) {
        this.periodicActions = actions
    }

    /**
     * Метод осуществляет сохранение списка запланированных действий в формате для календаря
     * @method
     *
     * @param {Object[]} actions Список действий
     */
    setCalendarActions(actions) {
        this.calendarActions = actions
    }

    /**
     * Метод осуществляет сохранение списка пользователей компании
     * @method
     *
     * @param {Object[]} users Список пользователей компании
     */
    setCompanyUsers(users) {
        this.companyUsers = users
    }

    /**
     * Метод сохраняет признак просмотра действий всех пользователей компании
     * @method
     * 
     * @param {Boolean} bool Признак просмотра действий всех пользователей компании
     */
    setIsAllUsers(bool) {
        this.isAllUsers = bool
    }

    /**
     * Метод сохраняет признак изменения фильтра действий
     * @method
     * 
     * @param {Boolean} bool Признак изменения фильтра действий
     */
    setIsFilterChanged(bool) {
        this.isFilterChanged = bool
    }

    /**
     * Метод сохраняет признак видимости фильтров
     * @method
     * 
     * @param {Boolean} bool Признак видимости фильтров  
     */
    setIsFiltersOpen(bool) {
        this.isFiltersOpen = bool
    }
 
    /**
     * Метод сохраняет признак видимости фильтров для запланированных действий 
     * @method
     * 
     * @param {Boolean} bool Признак видимости фильтров для запланированных действий  
     */
    setIsPlannedFilters(bool) {
        this.isPlannedFilters = bool
    }

    /**
     * Метод сохраняет массив фильтров для рекомендованных действий
     * @method
     * 
     * @param {Object[]} filters Массив фильтров для рекомендованных действий  
     */
    setSecurityMeasuresCategories(filters) {
        this.securityMeasuresCategories = filters
    }

    /**
     * Метод сохраняет массив фильтров для рекомендованных действий
     * @method
     * 
     * @param {Object[]} filters Массив фильтров для рекомендованных действий  
     */
    setRecommendedFilters(filters) {
        this.recommendedFilters = filters
    }

    /**
     * Метод сохраняет массив фильтров для рекомендованных действий
     * @method
     * 
     * @param {Object[]} filters Массив фильтров для рекомендованных действий  
     */
    setSavedRecommendedFilters(filters) {
        this.savedRecommendedFilters = filters
    }

    /**
     * Метод сохраняет данные таблицы рекомендованных действий
     * @method
     * 
     * @param {Object} model Данные таблицы рекомендованных действий
     */
    setRecommendedDataModel(model) {
        this.recommendedDataModel = model
    }

    /**
     * Метод сохраняет данные таблицы запланированных действий
     * @method
     * 
     * @param {Object} model Данные таблицы запланированных действий
     */
    setPlannedDataModel(model) {
        this.plannedDataModel = model
    }

    /**
     * Метод сохраняет признак отображения окна редактирования действия
     * @method
     * 
     * @param {Boolean} bool Признак отображения окна редактирования действия
     */
    setIsActionFormOpen(bool) {
        this.isActionFormOpen = bool
    }

    /**
     * Метод сохраняет признак отображения окна завершения действия
     * @method
     * 
     * @param {Boolean} bool Признак отображения окна завершения действия
     */
    setIsDoneFormOpen(bool) {
        this.isDoneFormOpen = bool
    }

    /**
     * Метод сохраняет отображаемый диапазон календаря
     * @method
     * 
     * @param {Object} range Отображаемый диапазон календаря
     */
    setCalendarRange(range) {
        this.calendarRange = range
    }

    /**
     * Метод сохраняет выбранную дату на календаре
     * @method
     * 
     * @param {String} date Выбранная дата на календаре
     */
    setCalendarDate(date) {
        this.calendarDate = date
    }

    /**
     * Метод сохраняет данные фильтров таблицы запланированных действий
     * @method
     * 
     * @param {String} type Тип фильтра
     * @param {Any} filters Данные фильтра
     * @param {Boolean} isField Признак указания поля
     */
    setPlannedFilters(type, filters, isField) {
        if (isField)
            this.plannedFilters[type] = filters
        else
            this.plannedFilters = filters

        localStorage.setItem('plannedFilters', JSON.stringify(this.plannedFilters))
    }

    /**
     * Метод сохраняет данные фильтров отчета по Планировщику
     * @method
     * 
     * @param {String} type Тип фильтра
     * @param {Any} filters Данные фильтра
     * @param {Boolean} isField Признак указания поля
     */
    setReportFilters(type, filters, isField) {
        if (isField)
            this.reportFilters[type] = filters
        else
            this.reportFilters = filters

        // localStorage.setItem('reportFilters', JSON.stringify(this.reportFilters))
    }

    /**
     * Метод осуществляет получение примененных фильтров для рекомендованных действий
     * @method
     * @param {Object} appliedFilters Примененные фильтры
     */
    getAppliedRecommendedFilters (appliedFilters) {
        let filters = []
        Object.entries(appliedFilters).map(([key, value], index) => {
            if (value) 
                filters.push(key)
        })

        let actionFilters = []
        if (filters.length)
            actionFilters = [
                {property: 'data[\"' + this.recommendedDataModel.fields.find(item => item.tech_name === "status_organization").rule_id +
                    '\"].presentations[*].record_id', value: filters, operator: "any_in"}
            ]

        return actionFilters
    }


    /**
     * Метод осуществляет получение списка фильтров для рекомендованных действий
     * @method
     */
    async getRecommendedFiltersList () {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        const filters = JSON.stringify([
            {property: 'data_model_id', value: 'planner_statuses_organization', operator: 'eq'},
            {property: 'transaction_id', value: null, operator: 'eq'},            
            {property: 'active', value: true, operator: 'eq'}
        ])

        DocumentService
            .getAllDataObjects(50, filters, [])
            .then(data => {
                clearTimeout(noResponse)
                this.setRecommendedFilters(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
    }

    /**
     * Метод осуществляет получение списка фильтров для рекомендованных действий
     * @method
     */
    async getSecurityMeasures () {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        const filters = JSON.stringify([
            {property: 'data_model_id', value: 'planner_security_measures', operator: 'eq'},
            {property: 'transaction_id', value: null, operator: 'eq'},
            {property: 'active', value: true, operator: 'eq'}
        ])

        DocumentService
            .getAllDataObjects(50, filters, [])
            .then(data => {
                clearTimeout(noResponse)
                this.setSecurityMeasuresCategories(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
    }

    /**
     * Метод осуществляет получение списка действий определенного типа
     * @method
     * 
     * @param {String} dataModelID ID выбранной таблицы действий
     * @param {Object[]} filters Дополнительные фильтры
     * @param {Function} setDataObjects Функция сохранения списка действий
     */
    async getActions (dataModelID, filters, isShowDeleted) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        if (dataModelID === 'recommended_actions')
            this.setIsRecommendedActionsLoading(true)
        if (dataModelID === 'my_actions')
            this.setIsMyActionsLoading(true)
        if (dataModelID === 'planned_actions')
            this.setIsPlannedActionsLoading(true)

        const resultFilters = JSON.stringify([
            {property: 'data_model_id', value: dataModelID, operator: 'eq'},
            {property: 'transaction_id', value: null, operator: 'eq'},            
            {property: 'active', value: true, operator: 'eq'}
        ].concat(!isShowDeleted ? [{property: 'system_data.deletion_mark', value: [false, null], operator: 'in'}] : []).concat(filters))            
        const sorter = JSON.stringify([
            {property: 'system_data.deletion_mark', desc: false},
            {property: 'data.name', desc: false}
        ].concat(dataModelID === 'planned_actions' ? [{property: 'data["action_completion_date"]', desc: false}] : []))

        DocumentService
            .getAllDataObjects(50, resultFilters, sorter)
            .then(data => {
                clearTimeout(noResponse)
                if (dataModelID === 'recommended_actions') 
                    this.setRecommendedActions(data)
                if (dataModelID === 'my_actions')
                    this.setMyActions(data)
                if (dataModelID === 'planned_actions')
                    this.setPlannedActions(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
            .finally(() => {
                if (dataModelID === 'recommended_actions')
                    this.setIsRecommendedActionsLoading(false)
                if (dataModelID === 'my_actions')
                    this.setIsMyActionsLoading(false)
                if (dataModelID === 'planned_actions')
                    this.setIsPlannedActionsLoading(false)
            })
    }

    /**
     * Метод осуществляет получение списка запланированных периодических действий определенного типа
     * @method
     * 
     */
    async getCalendarActions(email, calendarInfo) {
        let actions = []
        try {
            const actionFilters = this.checkActionFilters(email)
            const prevMonth = calendarInfo.start.setMonth(calendarInfo.start.getMonth() - 1)
            const nextMonth = calendarInfo.end.setMonth(calendarInfo.end.getMonth() + 1)
            const resultFilters = JSON.stringify([
                {property: 'data_model_id', value: 'planned_actions', operator: 'eq'},
                {property: 'transaction_id', value: null, operator: 'eq'},            
                {property: 'active', value: true, operator: 'eq'},
                {property: 'system_data.deletion_mark', value: [false, null], operator: 'in'},
                // {property: 'data["responsible_user_email"].value', value: email, operator: 'eq'},
                {property: 'data["action_completion_date"].value', value: prevMonth, operator: 'gt'},
                {property: 'data["action_completion_date"].value', value: nextMonth, operator: 'lt'},
            ].concat(actionFilters))
            const sorter = JSON.stringify([
                // {property: 'data["action_name"]', desc: false},
                {property: 'data["action_completion_date"]', desc: false}
            ])
            actions = await DocumentService.getAllDataObjects(50, resultFilters, sorter)
            const calendarActions = this.formatCalendarActions(actions, calendarInfo.view.type)
            this.setCalendarActions(calendarActions)
                
        } catch (error) {
            showErrorToast(error, 'fetching', '')
        }
    }

    /**
     * Метод осуществляет получение списка запланированных периодических действий определенного типа
     * @method
     * 
     */
    async getPeriodicActions() {
        this.setIsPlannedActionsLoading(true)
        let actions = []
        try {
            const actionDataModel = await DocumentService.getOneDataModel('planned_actions')
            const ruleID = actionDataModel && actionDataModel.referenced_models.length && actionDataModel.referenced_models[0].rule_id
            const resultFilters = JSON.stringify([
                {property: 'data_model_id', value: 'planned_actions', operator: 'eq'},
                {property: 'transaction_id', value: null, operator: 'eq'},            
                {property: 'active', value: true, operator: 'eq'},
                {property: 'record_id', value: this.selectedAction.record_id, operator: 'neq'},            
                {property: 'system_data.deletion_mark', value: [false, null], operator: 'in'},
                {property: 'data["action_is_done"].value', value: true, operator: 'neq'},
                {property: 'data["action_completion_date"].value', value: this.selectedAction.data["action_completion_date"].value, operator: 'gt'},
                {property: 'data["' + ruleID + '"].presentations[*].record_id',
                            value: `%${this.myActionRecordID}%`,
                            operator: "like_ic"}
            ])
            const sorter = JSON.stringify([
                {property: 'data["action_completion_date"]', desc: false}
            ])
            actions = await DocumentService.getAllDataObjects(50, resultFilters, sorter)
        } catch (error) {
            showErrorToast(error, 'fetching', '')
        } finally {
            this.setIsPlannedActionsLoading(false)
        }

        this.setPeriodicActions(actions)
        return actions
    }

    /**
     * Метод осуществляет редактирование последующих запланированных действий выбранного типа
     * @method
     * 
     */
    async editPeriodicActions(formData, periodicity) {
        try {
            let dataObjects = []
            let actionStartDate = new Date(formData.data.action_completion_date)

            this.periodicActions.forEach(action => {
                actionStartDate.setDate(actionStartDate.getDate() + periodicity)
                let attachedFiles = []
                if (formData?.system_data?.files?.length) {
                    attachedFiles = formData.system_data.files.map(file => { return { id: file.id } })    
                }

                dataObjects.push({
                    record_id: action.record_id,
                    data_model_id: 'planned_actions',
                    system_data: { deletion_mark: false,  files: attachedFiles},
                    data: {
                        'action_name': formData.data.action_name,
                        'action_description': formData.data.action_description,
                        'action_completion_date': new Date(actionStartDate.valueOf()).getTime(),
                        'responsible_user_email': formData.data.responsible_user_email,
                        'responsible_user_name': formData.data.responsible_user_name,
                        'responsible_user_id': formData.data.responsible_user_id,
                    }
                })
            })

            this.setIsPlannedActionsLoading(true)
            await DocumentService.updateDataObjectsPool(dataObjects)

            toast.success('Действия успешно изменены', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            this.setIsUpdatingActionList(true)
        } catch (error) {
            showErrorToast(error, 'saving', '')
        } finally {
            this.setIsPlannedActionsLoading(false)
        }        
    }

    /**
     * Метод осуществляет удаление одного выбранного действия (одной записи таблицы)
     * @method
     * 
     */
    async deleteOneAction() {
        this.setIsPlannedActionsLoading(true)
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        try {
            const response = await DocumentService.deleteDataObject(this.selectedAction.record_id, {system_data: {deletion_mark: !this.selectedAction.system_data.deletion_mark}})
            clearTimeout(noResponse)
            this.setIsPlannedActionsLoading(false)
            this.setIsUpdatingActionList(true)

            return response
        } catch (error) {
            this.setIsPlannedActionsLoading(false)
            clearTimeout(noResponse)
            showErrorToast(error, 'deleting', '')
            return null
        }
    }

    /**
     * Метод осуществляет удаление последующих запланированных действий выбранного типа
     * @method
     * 
     */
    async deletePeriodicActions() {
        this.setIsPlannedActionsLoading(true)
        try {
            const deletedActions = this.periodicActions.map(action => ({record_id: action.record_id, system_data: {deletion_mark: true}}))       
            await DocumentService.updateDataObjectsPool(deletedActions)

            toast.success('Действия успешно удалены', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            this.setIsUpdatingActionList(true)

        } catch (error) {
            showErrorToast(error, 'deleting', '')
        } finally {
            this.setIsPlannedActionsLoading(false)
        }
    }

    /**
     * Метод осуществляет формирование имени пользователя компании
     * @method
     * 
     * @param {Object} user Пользователь компании
     */
    formatUserName(user) {
        let name =  (user.first_name && user.first_name !== '' ? user.first_name + ' ': '') +
                    (user.last_name && user.last_name !== '' ? user.last_name + ' ': '')
        if (name === '')
            name = 'Анонимный пользователь '
        name += '(email: ' + user.email + ')'
        return name
    }

    /**
     * Метод осуществляет формирование планируемого действия
     * @method
     * 
     * @param {Object} action Планируемое действие (из списка моих действий)
     * @param {Object} user Пользователь компании
     * @param {Object} actionDate Дата выполнения действия
     */
    formatPlannedAction(action, user, actionDate, description, actionName, attachedFiles) {
        const fullUserName = this.formatUserName(user)
        const userName = fullUserName.includes('Анонимный')
            ?   fullUserName.split(' (email: ')[1].slice(0, -1)
            :   fullUserName.split(' (email: ')[0]

        const dataObject = {
            data_model_id: 'planned_actions',
            system_data: { deletion_mark: false },
            data: {
                'action_name': actionName,
                'action_description': description,
                'action_completion_date': actionDate,
                'responsible_user_email': user.email,
                'responsible_user_name': userName,
                'responsible_user_id': user.id,
                'my_action_reference': [action.record_id]
            }
        }
        dataObject.system_data.files = attachedFiles.map(file => { return { id: file.id } })

        if (this.editMode === 'plan') {
            dataObject.data.recommended_action = [this.selectedAction.record_id]
            dataObject.data.security_measure = this.selectedAction.data.security_measure.value.values.length ? [this.selectedAction.data.security_measure.value.values[0].record_id] : []
        }

        return dataObject
    }

    /**
     * Метод осуществляет формирование списка периодических планируемых действий
     * @method
     * 
     * @param {Object} action Планируемое действие (из списка моих действий)
     * @param {Object} formData Данные формы
     */
    formatPeriodicActions(action, formData, attachedFiles) {
        let dataObjects = []
        let actionStartDate = new Date(formData.data.startDate.valueOf())

        do {
            dataObjects.push(this.formatPlannedAction(action, formData.data.responsibleUser, new Date(actionStartDate.valueOf()), formData.data.action_description, formData.data.name, attachedFiles))
            actionStartDate.setDate(actionStartDate.getDate() + formData.data.action_periodicity)
        } while (actionStartDate < formData.data.endDate)

        return dataObjects
    }

    /**
     * Метод осуществляет формирование списка запланированных действий для календаря (в требуемом формате)
     * @method
     * 
     */
    formatCalendarActions(actions, viewType) {
        const calendarActions = actions.map(action => {
            const isOutDated = action.data['action_completion_date'].value < Date.now()
            const isDone = action.data['action_is_done'].value
            return {
                title: getFieldValue(action.data['my_action_reference']),
                start: action.data['action_completion_date'].value,
                backgroundColor: isDone ? 'rgb(20 184 166)' : isOutDated ? 'red' : 'blue',
                display: 'list-item',
                classNames: (['timeGridDay', 'timeGridWeek'].includes(viewType) ? ['tw-font-semibold'] : []).concat(isDone ? [`tw-text-teal-600`] : isOutDated ? ['tw-text-red-500'] : []),
                action: action
            }
        })

        return calendarActions
    }

    /**
     * Метод осуществляет получение списка пользователей компании
     * @method
     * 
     */
    async getCompanyUsers() {
        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        try {
            const data = await AuthService.getMyCompanyUsers()
            clearTimeout(noResponse)
            const users = data.data.map(user => ({...user, name: this.formatUserName(user)}))
            users.sort((a, b) => a.name - b.name)
            this.setCompanyUsers(users)
            return users
        
        } catch (error) {
            clearTimeout(noResponse)
            showErrorToast(error, 'fetching', '')
            return []
        }
    }

    /**
     * Метод осуществляет поиск ответственного за выполнение выбранного действия в списке пользователей компании
     * @method
     * 
     */
    getResponsibleUser() {
        const foundUser = this.companyUsers.find(user => user.email === this.selectedAction.data['responsible_user_email'].value)
        
        return foundUser || {id: 0, name: '', email: ''}
    }

    /**
     * Метод осуществляет получение record_id для ссылочного поля
     * @method
     * 
     * @param {Object} field Поле
     */
    getRecordID = (field) => {
        if (field.value && field.value.values && field.value.values[0])
            return field.value.values[0].record_id
        else
            return null
    }

    /**
     * Метод осуществляет обработку выбора действия в списке действий
     * @method
     * 
     * @param {Object} action Действие
     */
    onActionSelect(action) {
        this.setSelectedAction(action)
        const recordID = this.getRecordID(action.data['my_action_reference'])
        this.setMyActionRecordID(recordID)
        this.setIsSortingRecommendedActions(true)
    }

    /**
     * Метод осуществляет сортировку списка рекомендованных действий (для выделения уже используемых и не используемых)
     * @method
     * 
     */
    sortRecommendedActions() {
        const filteredMyActions = this.myActions.filter(action => !action.system_data.deletion_mark)
        const filteredRecommendedActions = this.recommendedActions.map(action => {
            const foundAction = filteredMyActions.find(item => 
                item.data['recommended_action_reference'].value.values.length &&
                item.data['recommended_action_reference'].value.values[0].record_id === action.record_id)

            return {...action, hidden: foundAction !== undefined}
        })
        filteredRecommendedActions.sort(function(a, b) {return (a.data.name.value < b.data.name.value) ? -1 : 1})
        filteredRecommendedActions.sort(function(a, b) {return (a.hidden === b.hidden) ? 0 : a.hidden ? 1 : -1})
        this.setRecommendedActions(filteredRecommendedActions)
        this.setIsSortingRecommendedActions(false)
    }

    /**
     * Метод осуществляет формирование фильтров для запланированных действий
     * @method
     * 
     * @param {String} email Email ответственного за действие пользователя
     */
    checkActionFilters(email) {
        let actionFilters = []
        let typeFilters = []
        let userFilters = []

        const today = Date.now()

        if (this.plannedFilters.isDoneActionShow)
            typeFilters.push({property: 'data["action_is_done"].value', value: true, operator: 'eq'})
        
        if (this.plannedFilters.isOutdatedActionShow) {
            typeFilters.push({
                operator: 'and',
                filters: [
                    {property: 'data["action_completion_date"].value', value: today, operator: 'lt'},
                    {property: 'data["action_is_done"].value', value: true, operator: 'neq'}
                ]
            })
        }
        
        if (this.plannedFilters.isPlannedActionShow) {
            typeFilters.push({
                operator: 'and',
                filters: [
                    {property: 'data["action_completion_date"].value', value: today, operator: 'ge'},
                    {property: 'data["action_is_done"].value', value: true, operator: 'neq'}
                ]
            })
        }

        if (this.plannedFilters.isAssignedToMe)
            userFilters.push({property: 'data["responsible_user_email"].value', value: email, operator: 'eq'})
        if (this.plannedFilters.isAssignedByMe)
            userFilters.push({property: 'author.email', value: email, operator: 'eq'})

        if (typeFilters.length && (userFilters.length || this.plannedFilters.isAllActions)) {
            actionFilters.push({operator: 'or', filters: typeFilters})
            if (this.plannedFilters.fromDate && this.selectedTab === 'actions') {
                const fromDate = new Date(this.plannedFilters.fromDate)
                actionFilters.push({property: 'data["action_completion_date"].value', value: fromDate.getTime(), operator: 'ge'})
            }
            if (this.plannedFilters.tillDate && this.selectedTab === 'actions') {
                const tillDate = new Date(this.plannedFilters.tillDate)
                actionFilters.push({property: 'data["action_completion_date"].value', value: tillDate.getTime(), operator: 'le'})
            }
            if (userFilters.length)
                actionFilters.push({operator: 'or', filters: userFilters})
        } else {
            actionFilters.push({property: 'data["action_completion_date"].value', value: 0, operator: 'eq'})
        }

        if (!this.plannedFilters.isMyRecommendedActionShow) {
            actionFilters.push({property: 'data[\"' + this.plannedDataModel.fields.find(item => item.tech_name === "recommended_action").rule_id +
                '\"].presentations', value: null, operator: "eq"},)
        }
        
        return actionFilters
    }

    /**
     * Метод формирует запрос на обновление списков рекомендуемых и запланированных действий
     * @method
     * 
     */
    requestListUpdate() {
        this.setIsUpdatingActionList(true)
        this.setIsSortingRecommendedActions(true)
    }

    /**
     * Метод осуществляет обновление списка запланированных действий
     * @method
     * 
     * @param {Object[]} filters Фильтры
     */
    updatePlannedActionList(filters) {
        this.getActions('planned_actions', filters, false)
        
        this.setIsUpdatingActionList(false)
        this.setEditMode(null)
    }

    /**
     * Метод осуществляет создание нового действия (на основе рекомендуемого действия или пользовательского)
     * @method
     * 
     * @param {Object} form Информация о действии
     */
    async createMyAction(form) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        const dataObject = {}
        dataObject.data = {}
        dataObject.data['name'] = form.data.name
        dataObject.data['action_description'] = form.data['action_description']
        dataObject.data['action_periodicity'] = form.data['action_periodicity'] || 0

        if (this.editMode !== 'new' && form.data.actionRecordID)
            dataObject.data['recommended_action_reference'] = [form.data.actionRecordID]
        dataObject.data_model_id = 'my_actions'

        try {
            const response = await DocumentService.createDataObject(dataObject)
            return response

        } catch (error) {
            showErrorToast(error, 'saving', '')
            return null

        } finally {
            clearTimeout(noResponse)
        }
    }

    async getReportActions(selectedFilters) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        try {
            const resultFilters = JSON.stringify([
                {property: 'data_model_id', value: 'planned_actions', operator: 'eq'},
                {property: 'transaction_id', value: null, operator: 'eq'},            
                {property: 'active', value: true, operator: 'eq'},
                {property: 'system_data.deletion_mark', value: [false, null], operator: 'in'}
            ].concat(selectedFilters))
            const sorter = JSON.stringify([
                {property: 'data.action_completion_date', desc: false}
            ])
    
            const response = await DocumentService.getAllDataObjects(50, resultFilters, sorter)
            clearTimeout(noResponse)
            return response

        } catch (error) {
            clearTimeout(noResponse)
            showErrorToast(error, 'saving', '')
            return []
        }
    }

    formatAllUserActionsSummary(actions, currentDate) {
        const summaryList = this.companyUsers.map(user => {
            const userName = user.first_name + ' ' + user.last_name
            const userActions = actions.filter(action => action.data['responsible_user_email'].value === user.email)
            const outdatedActions = userActions.filter(action => (action.data["action_completion_date"].value < currentDate) && !action.data['action_is_done'].value)
            const doneActions = userActions.filter(action => action.data['action_is_done'].value)
            return {
                id: user.id,
                data: {
                    responsible_user: {value: userName, tech_name: 'responsible_user', validator_type: 'string'},
                    responsible_user_email: {value: user.email, tech_name: 'responsible_user_email', validator_type: 'string'},
                    all_actions: {value: userActions.length, tech_name: 'all_actions', validator_type: 'int'},
                    done_actions: {value: doneActions.length, tech_name: 'done_actions', validator_type: 'int'},
                    outdated_actions: {value: outdatedActions.length, tech_name: 'outdated_actions', validator_type: 'int'}
                }
            }
        }).sort((a, b) => a.data.responsible_user.value.toLowerCase() < b.data.responsible_user.value.toLowerCase() ? -1 : 1)

        return summaryList
    } 

    /**
     * Метод осуществляет формирование фильтров для запланированных действий
     * @method
     * 
     * @param {Object} type Вид отчета
     * @param {String} email Email ответственного за действие пользователя
     */
    checkReportFilters(email) {
        let reportFilters = []
        let typeFilters = []

        const today = Date.now()

        if (this.reportFilters.selectedType === 'actions' && this.reportFilters.isDoneAction)
            typeFilters.push({property: 'data["action_is_done"].value', value: true, operator: 'eq'})
        
        if (this.reportFilters.selectedType === 'actions' && this.reportFilters.isOutdatedAction) {
            typeFilters.push({
                operator: 'and',
                filters: [
                    {property: 'data["action_completion_date"].value', value: today, operator: 'lt'},
                    {property: 'data["action_is_done"].value', value: true, operator: 'neq'}
                ]
            })
        }
        
        if (this.reportFilters.selectedType === 'actions' && this.reportFilters.isPlannedAction) {
            typeFilters.push({
                operator: 'and',
                filters: [
                    {property: 'data["action_completion_date"].value', value: today, operator: 'ge'},
                    {property: 'data["action_is_done"].value', value: true, operator: 'neq'}
                ]
            })
        }

        if (this.reportFilters.selectedUser)
            reportFilters.push({property: 'data["responsible_user_email"].value', value: this.reportFilters.selectedUser, operator: 'eq'})

        if (this.reportFilters.isAssignedByMe)
            reportFilters.push({property: 'author.email', value: email, operator: 'eq'})

        if (typeFilters.length && this.reportFilters.selectedType === 'actions')
            reportFilters.push({operator: 'or', filters: typeFilters})

        if (this.reportFilters.fromDate) {
            const fromDate = new Date(this.reportFilters.fromDate)
            reportFilters.push({property: 'data["action_completion_date"].value', value: fromDate.getTime(), operator: 'ge'})
        }
        if (this.reportFilters.tillDate) {
            const tillDate = new Date(this.reportFilters.tillDate)
            reportFilters.push({property: 'data["action_completion_date"].value', value: tillDate.getTime(), operator: 'le'})
        }
        
        return reportFilters
    }

    /**
     * Метод осуществляет группировку/разгруппировку запланированных действий
     * @method
     * 
     */
    groupPlannedActions() {
        if (!Object.entries(this.plannedCategoriesDefaultOpen).length) {
            const data = JSON.parse(sessionStorage.getItem('plannedCategoriesDefaultOpen'))
            if (data)
                this.setPlannedCategoriesDefaultOpen(data)
        }

        if (this.selectedAction) {
            const categoryRecordId = this.selectedAction.data.security_measure.value.values[0]?.record_id
            let categoryId = 0

            if (categoryRecordId) {
                const securityItem = this.securityMeasuresCategories.find(item => item.record_id === categoryRecordId)
                categoryId = securityItem ? securityItem.id : 0
            }

            const selectedCategory = document.getElementById('planned-category-item-' + categoryId)
            
            if (selectedCategory?.childNodes[0] && !this.plannedCategoriesDefaultOpen[categoryId]) {
                selectedCategory.childNodes[0].click()
            }

            if (this.plannedFilters.isGrouped) {
                const observer = new MutationObserver(function(mutations) {
                    let myElement = document.getElementById('planned-category-list-' + categoryId)

                    if (document.contains(myElement)) {
                        const selectedAction = document.getElementById('planned-action-item-' + this.selectedAction.record_id)
                        selectedAction?.scrollIntoView({ block: "center", behavior: "smooth" })
                        observer.disconnect()
                    }
                })
                
                observer.observe(document, {attributes: false, childList: true, characterData: false, subtree:true})
            } else {
                const selectedAction = document.getElementById('planned-action-item-' + this.selectedAction.record_id)
                selectedAction?.scrollIntoView({ block: "center", behavior: "smooth" })
            }
        }

    }
        
}

export default PlannerStore