import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../../..'
import { paths } from '../../../config/constsURN'
import { classNames } from '../../../functions/classnames'

/**
 * Визуальный компонент отображает меню "Редактирование данных"
 */
const EditDataMenu = () => {
    const { userStore } = useContext(Context)

    return (
        <>
            { userStore.checkPermission("data:access")
                ?   <a
                        href={paths.DATAOBJECT_ROUTE}
                        className={classNames(
                            'tw-text-sm tw-text-center tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600',
                            'tw-rounded-md tw-px-2 tw-py-3 tw-font-semibold'
                        )}
                        aria-current={'page'}
                    >
                        Редактирование данных
                    </a>
                :   null
            }
        </>
    )
}

export default observer(EditDataMenu)