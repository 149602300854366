import React, { useContext } from 'react'
import { Context } from '../../../../..'
import { DocumentPlusIcon, PencilSquareIcon, TrashIcon, ClockIcon, DocumentDuplicateIcon, ListBulletIcon, QuestionMarkCircleIcon, LinkIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { observer } from 'mobx-react-lite'
import DataImportMenu from './DataImportMenu'
import DataPrintMenu from './DataPrintMenu'
import DataExportMenu from './DataExportMenu'
import SearchInput from '../../../../search/SearchInput'
import { checkDataModelPermission, checkMethodPermissions } from '../../../../../functions/checkPermissions'
import { menuTooltipTimeOut } from '../../../../../config/constTypes'

/**
 * Визуальный компонент отображает меню возможных действий над записями таблицы
 * 
 * @param {Function} onAddItemClick Обработчик клика мыши на кнопке создания новой записи в таблице
 * @param {Function} onDuplicateItemClick Обработчик клика мыши на кнопке создания новой записи в таблице посредством дублирования существующей записи
 * @param {Function} onEditItemClick Обработчик клика мыши на кнопке редактирования выбранной записи в таблице
 * @param {Function} onViewRecordClick Обработчик клика мыши на кнопке просмотра детальной информации о выбранной записи
 * @param {Function} onItemHistoryClick Обработчик клика мыши на кнопке просмотра истории выбранной записи в таблице
 * @param {Function} onDeleteItemClick Обработчик клика мыши на кнопке установки/снятия метки удаления записи в таблице
 * @param {Object} selectedDataObject Выбранный элемент в списке записей таблицы
 * @param {Object} selectedFullDataModel Выбранная таблица
 * @param {Function} handleImportFromFileClick Обработчик клика мыши на кнопке импорта записей из Excel
 * @param {Function} handleImportFromServerClick Обработчик клика мыши на кнопке импорта записей с сервера
 * @param {Function} handlePrintTableClick Обработчик клика мыши на кнопке экспорта всех записей в файл Excel
 * @param {Function} handlePrintRecordClick Обработчик клика мыши на кнопке экспорта выбранной записи в файл Excel
 * @param {Function} handleOpenExportersClick Обработчик клика мыши на кнопке экспорта выбранной записи в файл Excel
 * @param {Function} onCopyLinkClick Обработчик клика мыши на кнопке установки/снятия метки удаления записи в таблице
 * @param {Boolean} isDocumentPage Признак нахождения на странице документов
 * @param {Function} updateDataObjectList Функция обновления списка записей
 * @param {Object} filterStore Экземпляр хранилища информации о применяемых фильтрах
 * 
 */
const DataObjectMenu = ({onAddItemClick, onDuplicateItemClick, onEditItemClick, onViewRecordClick, onItemHistoryClick, onDeleteItemClick, selectedDataObject,
        selectedFullDataModel, handleImportFromFileClick, handleImportFromServerClick, handlePrintTableClick, handlePrintRecordClick, handleOpenExportersClick, 
        onCopyLinkClick, isDocumentPage, updateDataObjectList, filterStore}) => {
    const { docStore } = useContext(Context)
    const [isWrite, isWriteDescription] = checkDataModelPermission(selectedFullDataModel, 'write') 
    const [isRecordWrite, isRecordWriteDescription] = checkMethodPermissions(selectedDataObject, 'write')

    return (
        <div 
            id='data-object-menu'
            className='tw-h-12 tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center tw-space-x-1 tw-py-2'
        >
            <div className='tw-flex tw-flex-row tw-justify-between tw-space-x-1 tw-truncate'>
                {selectedFullDataModel?.description &&
                    <div className="tw-h-6 tw-w-6">
                        <QuestionMarkCircleIcon
                            className="tw-h-6 tw-w-6 tw-text-gray-400 focus:tw-rounded-full focus:tw-outline-gray-400 tw-mr-1" aria-hidden="true"
                            data-tooltip-id="data-object-menu-tooltip" data-tooltip-content={selectedFullDataModel?.description}
                        />
                    </div>
                }
                <p className='tw-text-md tw-font-semibold tw-truncate'>
                    { docStore.isHistoryView 
                        ? docStore.isDetailView
                            ? `Просмотр ${selectedDataObject.active ? 'актуальной' : 'архивной'} версии записи`
                            : 'История изменений записи'
                        : docStore.isDetailView
                            ? 'Просмотр актуальной записи'
                            : `${selectedFullDataModel
                                    ?   selectedFullDataModel.type === 'directory'
                                            ?   'Справочник'
                                            :   selectedFullDataModel.type === 'document'
                                                    ?   'Документ'
                                                    :   'Таблица'
                                    :   'Записи'
                                } ${selectedFullDataModel ? `"${selectedFullDataModel.entity_name}"` : ' '}`
                    }
                </p>
            </div>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <SearchInput
                    isDisabled={!selectedFullDataModel}
                    filterStore={filterStore}
                    selectedFullDataModel={selectedFullDataModel}
                    updateDataObjectList={updateDataObjectList}
                />

                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onAddItemClick}
                    disabled={!selectedFullDataModel || !isWrite}
                    data-tooltip-id="data-object-menu-tooltip" 
                    data-tooltip-html={selectedFullDataModel 
                        ? isWrite ? "Создать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+A)" : isWriteDescription
                        : "Создать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+A)"}
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onDuplicateItemClick(selectedDataObject.id)}
                    disabled={!selectedDataObject || docStore.isHistoryView || docStore.isDetailView || !isWrite}
                    data-tooltip-id="data-object-menu-tooltip" 
                    data-tooltip-html={selectedFullDataModel 
                        ? isWrite ? "Дублировать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+D)" : isWriteDescription
                        :  "Дублировать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+D)"}
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <DocumentDuplicateIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onEditItemClick(selectedDataObject.id)}
                    disabled={!selectedDataObject || docStore.isHistoryView || docStore.isDetailView || !isWrite || !isRecordWrite}
                    data-tooltip-id="data-object-menu-tooltip"
                    data-tooltip-html={(selectedFullDataModel && selectedDataObject)
                        ? isWrite 
                            ? isRecordWrite
                                ? "Редактировать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+E)"
                                :  isRecordWriteDescription
                            : isWriteDescription
                        : "Редактировать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+E)"}
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className={`${docStore.isDetailView ? 'tw-bg-red-600 hover:tw-bg-red-400' : 'tw-bg-gray-800 hover:tw-bg-gray-600'} tw-rounded-md tw-p-1.5 tw-text-white  disabled:tw-bg-gray-400`}
                    onClick={onViewRecordClick}
                    disabled={!selectedDataObject}
                    data-tooltip-id="data-object-menu-tooltip"
                    data-tooltip-html={docStore.isDetailView ? "Закрыть просмотр &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+I)" : "Просмотреть запись &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+I)"}
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <ListBulletIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className={`${docStore.isHistoryView ? 'tw-bg-red-600 hover:tw-bg-red-400' : 'tw-bg-gray-800 hover:tw-bg-gray-600'} tw-rounded-md tw-p-1.5 tw-text-white  disabled:tw-bg-gray-400`}
                    onClick={onItemHistoryClick}
                    disabled={!selectedDataObject}
                    data-tooltip-id="data-object-menu-tooltip"
                    data-tooltip-html={docStore.isHistoryView ? "Скрыть историю &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+H)" : "Просмотреть историю &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+H)"}
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <ClockIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onDeleteItemClick(selectedDataObject)}
                    disabled={!selectedDataObject || docStore.isHistoryView || docStore.isDetailView || !isWrite || !isRecordWrite}
                    data-tooltip-id="data-object-menu-tooltip"
                    data-tooltip-html={(selectedFullDataModel && selectedDataObject) 
                        ? isWrite 
                            ? isRecordWrite
                                ? (selectedDataObject && selectedDataObject.system_data && selectedDataObject.system_data.deletion_mark) 
                                    ? "Отменить удаление &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+R)" 
                                    : "Удалить &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+R)"
                                :  isRecordWriteDescription
                            : isWriteDescription
                        : "Удалить &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+R)"}
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <TrashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                
                {isDocumentPage &&
                    <>
                        <DataImportMenu
                            dataModel={selectedFullDataModel}
                            onImportFromXLSXClick={handleImportFromFileClick}
                            onImportFromServerClick={handleImportFromServerClick}
                        />
                        <DataExportMenu
                            dataModel={selectedFullDataModel}
                            notPrintRecord={!docStore.selectedDataObject}
                            itemHistory={docStore.isHistoryView}
                            onPrintTableClick={handlePrintTableClick}
                            onPrintRecordClick={handlePrintRecordClick}
                        />
                        <DataPrintMenu
                            notPrintRecord={!docStore.selectedDataObject}
                            onPrintDocumentClick={handleOpenExportersClick}
                        />
                    </>
                }
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onCopyLinkClick}
                    disabled={!selectedDataObject}
                    data-tooltip-id="data-object-menu-tooltip"
                    data-tooltip-html="Копировать ссылку в буфер обмена &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+L)"
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <LinkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
            </div>
            <Tooltip id="data-object-menu-tooltip"  className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
        </div>
    )
}

export default observer(DataObjectMenu)