import React from 'react'
import PageTitle from '../main_page/controller/common/panels/PageTitle'
import { licenseText } from './license'
import parse from 'html-react-parser'

/**
 * Визуальный компонент отображает информацию о лицензионном соглашении
 * 
 */
const LicenseInfo = () => {
    return (
        <>
            <PageTitle title='ЛИЦЕНЗИОННОЕ СОГЛАШЕНИЕ БЕТА-ВЕРСИИ ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ «MEDOED»'/>
            <div className='tw-px-4'>
                {parse(licenseText)}
            </div>
        </>
    )
}

export default LicenseInfo