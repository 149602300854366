import React, { useContext } from 'react'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает информацию о разрешениях сущности  
 * 
 */
const RoleInfo = () => {
    const { RolesStore } = useContext(Context)

    return (
        <div id='role-info' className='tw-py-1 tw-h-[calc(100%_-_3rem)] tw-overflow-auto'>
            <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Название</dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0 tw-break-words">{RolesStore.selectedRole?.name}</dd>
            </div>
            <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Описание</dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0 tw-break-words">{RolesStore.selectedRole?.description}</dd>
            </div>
        </div>
    )
}

export default observer(RoleInfo)
