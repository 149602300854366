import React from 'react'
import ImporterItem from './ImporterItem'


/**
 * Визуальный компонент отображает список существующих шаблонов импорта
 * 
 * @param {Object[]} list Список существующих шаблонов импорта
 * @param {Object} activeItem Выбранный элемент в списке шаблонов импорта
 * @param {Function} onItemClick Обработчик клика мыши на элементе списка
 * @param {Object[]} dataModelsList Список таблиц
 * 
 */
const ImporterList = ({list, activeItem, onItemClick, dataModelsList, isDisabled, onDoubleClick}) => {
    return (
        <div  id='importer-list-items' className={`tw-flex tw-flex-col tw-h-[calc(100%_-_3rem)] tw-w-full tw-bg-white tw-overflow-auto 
                                                    ${isDisabled && 'tw-pointer-events-none tw-opacity-50'}`}
        >
            { list.map((importer, index) => 
                <ImporterItem 
                    key={index}
                    item={importer}
                    activeItem={activeItem}
                    onClick={onItemClick}
                    dataModelsList={dataModelsList}
                    onDoubleClick={onDoubleClick}
                />
            )}
        </div>
    )
}

export default ImporterList