import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Context } from '../../../../..'
import PageTitle from '../../common/panels/PageTitle'
import { observer } from 'mobx-react-lite'
import ReportTable from '../ReportTable'
import ReportExportMenu from '../common/ReportExportMenu'
import ReportPlannerFilter from './ReportPlannerFilter'
import Spinner from '../../../../../assets/Spinner'
import { getIdFromPath } from '../../../../../functions/getIdFromPath'
import RecordNotesStore from '../../../../../store/RecordNotesStore'
import EditDataObjectContainer from '../../data_object/edit/EditDataObjectContainer'
import { paths } from '../../../../../config/constsURN'
import { toast } from 'react-toastify'
import { serviceMessageTimeOut } from '../../../../../config/constTypes'


const allUsersFields = {
    fields: [
        {alias: 'Ответственный', tech_name: 'responsible_user', validator_type: 'string', is_clickable: false, data_model_id: ''},
        {alias: 'Email', tech_name: 'responsible_user_email', validator_type: 'string', is_clickable: false, data_model_id: ''},
        {alias: 'Все действия', tech_name: 'all_actions', validator_type: 'int', is_clickable: true, link: paths.REPORT_PLANNER_ROUTE + '/actions', data_model_id: ''},
        {alias: 'Выполнено', tech_name: 'done_actions', validator_type: 'int', is_clickable: true, link: paths.REPORT_PLANNER_ROUTE + '/actions', data_model_id: ''},
        {alias: 'Просрочено', tech_name: 'outdated_actions', validator_type: 'int', is_clickable: true, link: paths.REPORT_PLANNER_ROUTE + '/actions', data_model_id: ''},
    ]
}

const allActionsFields = {
    fields: [
        {alias: 'Наименование', tech_name: 'action_name', validator_type: 'string', order: 0, is_clickable: true, data_model_id: 'planned_actions'},
        {alias: 'Описание', tech_name: 'action_description', validator_type: 'string', order: 1, is_clickable: true, data_model_id: 'planned_actions'},
        {alias: 'Ответственный', tech_name: 'responsible_user_name', validator_type: 'string', order: 2, is_clickable: false, data_model_id: ''},
        {alias: 'Email', tech_name: 'responsible_user_email', validator_type: 'string', order: 3, is_clickable: false, data_model_id: ''},
        {alias: 'Дата выполнения', tech_name: 'action_completion_date', validator_type: 'date', order: 4, options: {format: "dd.MM.yyyy HH:mm:ss"}, is_clickable: false, data_model_id: ''},
        {alias: 'Выполнено', tech_name: 'action_is_done', validator_type: 'bool', order: 5, is_clickable: false, data_model_id: ''},
        {alias: 'Комментарий', tech_name: 'comment', validator_type: 'string', order: 6, is_clickable: false, data_model_id: ''},
        {alias: 'Сообщения', tech_name: 'action_notes', validator_type: 'int', order: 7, is_clickable: false, data_model_id: ''},
        {alias: 'Наличие вложений', tech_name: 'action_files', validator_type: 'bool', order: 8, is_clickable: false, data_model_id: ''},
    ]
}

function filterActions(actions, dataModel, notes) {
    const visibleFields = dataModel.fields.map(field => field.tech_name)
    const filteredActions = actions.map(action => {
        const noteQuantity = notes.filter(note => note.target.id === action.record_id).length
        const isFiles = action.system_data.files ? action.system_data.files.length > 0 : false
        const filteredData = Object.entries(action.data)
                                    .filter(item => visibleFields.includes(item[0])) // оставить только отображаемые поля
                                    .concat([ 
                                            ['action_notes', {value: noteQuantity, tech_name: 'action_notes', validator_type: 'int'}],
                                            ['action_files', {value: isFiles, tech_name: 'action_files', validator_type: 'bool'}]
                                    ])
                                    .map(item => ([item[0], {...item[1], id: action.id}]))

        return {
            ...action,
            data: Object.fromEntries(filteredData)
        }
    })

    return filteredActions
}


/**
 * Компонент реализует логику работы с отчетами по Планировщику
 * 
 */
const ReportPlannerContainer = () => {
    const location = useLocation()
    const { plannerStore, userStore, docStore } = useContext(Context)
    const [recordNotesStore] = useState(() => new RecordNotesStore())

    const [isLoading, setIsLoading] = useState(false)
    const [reportDataModel, setReportDataModel] = useState({fields: []})
    const [reportData, setReportData] = useState([])
    const [reportType, setReportType] = useState(null)
    const [reportFilterString, setReportFilterString] = useState('')
    const [allResponsibleUsers, setAllResponsibleUsers] = useState(null)
    const [isEditFormOpen, setIsEditFormOpen] = useState(false)
    const [dataObjectID, setDataObjectID] = useState(null)
    const [selectedFullDataModel, setSelectedFullDataModel] = useState(null)

    const caption = reportType
                        ?   reportType === 'actions' ? 'Действия' : 'Пользователи'
                        :   ''

    const initReport = async () => {
        setIsLoading(true)
        const loadedUsers = await plannerStore.getCompanyUsers()
        const allUsers = [{id: 0, name: 'Все', username: 'Все', email: null}].concat(loadedUsers)
        setAllResponsibleUsers(allUsers)

        const selectedType = getIdFromPath(location.pathname, 3)
        if (selectedType) {
            setReportType(selectedType)
            plannerStore.setReportFilters('selectedType', selectedType, true)
            if (selectedType === 'users') {
                setReportDataModel(allUsersFields)
            }
            if (selectedType === 'actions') {
                setReportDataModel(allActionsFields)
                if (location.search !== '') {
                    const searchParams = new URLSearchParams(location.search)
                    const userID = searchParams.get('id')
                    const foundUser = loadedUsers.find(user => user.id === Number(userID))
                    if (foundUser) {
                        plannerStore.setReportFilters('selectedUser', foundUser.email, true)
                    } else {
                        toast.error('Пользователь с таким ID не найден!', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                    }
                    plannerStore.setReportFilters('isAssignedByMe', searchParams.get('by_me') === 'true', true)
                    plannerStore.setReportFilters('isDoneAction', ['all_actions', 'done_actions'].includes(searchParams.get('type')), true)
                    plannerStore.setReportFilters('isOutdatedAction', ['all_actions', 'outdated_actions'].includes(searchParams.get('type')), true)
                    plannerStore.setReportFilters('isPlannedAction', ['all_actions', 'planned_actions'].includes(searchParams.get('type')), true)
                    plannerStore.setReportFilters('fromDate', Number(searchParams.get('from')), true)
                    plannerStore.setReportFilters('tillDate', Number(searchParams.get('till')), true)
                    plannerStore.setIsUpdatingActionList(true)
                }
            }
        }
        setIsLoading(false)
    }

    const handleItemClick = async (id, recordID, dataModelID) => {
        if (reportType === 'actions') {
            await docStore.getOneDataModel(dataModelID, dataModel => { setSelectedFullDataModel(dataModel)})
            setDataObjectID(id)
            recordNotesStore.setRecordID(recordID)
            setIsEditFormOpen(true)
        }
    }

    const handleApplyFilterClick = async () => {
        setIsLoading(true)
        const selectedFilters = plannerStore.checkReportFilters(userStore.user.email)
        const actions = await plannerStore.getReportActions(selectedFilters)
        // setLoadedActions(actions)
        if (plannerStore.reportFilters.selectedType === 'users') {
            const today = Date.now()
            const formattedData = plannerStore.formatAllUserActionsSummary(actions, today)
            const summaryData = plannerStore.reportFilters.selectedUser
                                    ?   formattedData.filter(item => item.data['responsible_user_email'].value === plannerStore.reportFilters.selectedUser)
                                    :   formattedData
            setReportData(summaryData)
            let filterText = ''
            if (plannerStore.reportFilters.isAssignedByMe)
                filterText += '&&by_me=' + plannerStore.reportFilters.isAssignedByMe
            if (plannerStore.reportFilters.fromDate)
                filterText += '&&from=' + plannerStore.reportFilters.fromDate
            if (plannerStore.reportFilters.tillDate)
                filterText += '&&till=' + plannerStore.reportFilters.tillDate
            setReportFilterString(filterText)
        }
        if (plannerStore.reportFilters.selectedType === 'actions') {
            const recordIDs = actions.map(action => action.record_id)
            const actionNotes = await recordNotesStore.fetchMultiRecordNotes(recordIDs)
            const filteredReportActions = filterActions(actions, reportDataModel, actionNotes)
            setReportData(filteredReportActions)
        }
        setIsLoading(false)
    }

    const handleEditFormCloseClick = () => {
        setIsEditFormOpen(false)
        recordNotesStore.setRecordID(null)
    }

    useEffect(() => {
        initReport()
    }, [])

    useEffect(() => {
        if (plannerStore.isUpdatingActionList) {
            handleApplyFilterClick()
            plannerStore.setIsUpdatingActionList(false)
        }
    }, [plannerStore.isUpdatingActionList])

    return (
        <>
            <PageTitle title={`Отчет "Планировщик: ${caption}"`}/>
            <div  className='tw-h-full tw-max-h-full tw-w-full tw-grid tw-grid-cols-4 tw-gap-x-8'>
                <div className='tw-col-span-1 tw-h-full tw-min-w-80 tw-flex tw-flex-col tw-overflow-hidden tw-bg-white tw-rounded-md'>
                    <div className='tw-w-full tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-border-b-2 tw-border-gray-400'>
                        <div className='tw-text-md tw-font-semibold'>Настройки отчета</div>
                    </div>
                    <ReportPlannerFilter
                        users={allResponsibleUsers}
                        reportType={reportType}
                        onSubmitClick={handleApplyFilterClick}
                    />
                </div>
                <div id='report-planner-page' className='tw-col-span-3 tw-h-full tw-flex tw-flex-col tw-overflow-hidden tw-bg-white tw-rounded-md'>
                    <div className='tw-w-full tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-border-b-2 tw-border-gray-400'>
                        <div className='tw-text-md tw-font-semibold'>Данные для отчета</div>
                        <ReportExportMenu/>
                    </div>
                    { !isLoading
                        ?   plannerStore.reportFilters.selectedType &&
                            <ReportTable
                                dataModel={reportDataModel}
                                reportData={reportData}
                                onCellClick={handleItemClick}
                                filterString={reportFilterString}
                            />
                        :
                            <Spinner/>
                    }
                </div>
            </div>
            <EditDataObjectContainer
                isOpen={isEditFormOpen} 
                onCloseClick={handleEditFormCloseClick}
                id={dataObjectID}
                selectedFullDataModel={selectedFullDataModel}
                recordNotesStore={recordNotesStore}
            />
        </>
    )
}

export default observer(ReportPlannerContainer)