import React from "react";
import { getFormattedDate } from "../../../config/constTypes";

/**
 * Визуальный компонент отображает сообщение
 * 
 * @param {Object} note Сообщение
 * 
 */
const RecordNotesItem = ({note}) => {
    return (
        <div className='tw-flex tw-flex-col tw-mb-4'>
            <div className='tw-flex tw-flex-row tw-items-center tw-gap-x-4 tw-text-xs'>
                <span className=' tw-text-gray-600'>
                    {getFormattedDate(note.created, 'dd.MM.yyyy HH:mm')}
                </span>
                <span className='tw-font-semibold'>
                    {(note.author && note.author.email) ? note.author.email : note.author_id}
                </span>
            </div>
            <div className="tw-py-1 tw-break-all">
                {note.data.message}
            </div>
        </div>
    )
}

export default RecordNotesItem