import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import FormErrorToastPanel from '../../main_page/controller/common/panels/toast/FormErrorToastPanel'

/**
 * Визуальный компонент отображает форму редактирования информации о пользователе
 * 
 */
const UserInfoForm = () => {
    const { userStore } = useContext(Context)
    const regexInn = new RegExp('^[0-9]{10}$|^[0-9]{12}$')
    const regexOgrn = new RegExp('^[0-9]{13}$|^[0-9]{15}$')
    const regexKpp = new RegExp('^[0-9]{9}$')
    const regexPhone = new RegExp(/^(\+7|7|8)[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/)

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm()

    const onSubmitClick = async (form) => {
        if (!regexInn.test(form.sub_tin?.trim())) {
           setError('sub_tin', { type: 'invalid', message: ' Поле должно содержать 10 или 12 цифр' })
        } else if (!regexKpp.test(form.sub_rrc?.trim())) {
            setError('sub_rrc', { type: 'invalid', message: ' Поле должно содержать 9 цифр' })
        } else if (!regexOgrn.test(form.sub_msrn?.trim())) {
            setError('sub_msrn', { type: 'invalid', message: ' Поле должно содержать 13 или 15 цифр' })
        } else if (!regexPhone.test(form.phone_number?.trim())) {
            setError('phone_number', { type: 'invalid', message: ' Неверный формат номера телефона' })
        } else if (form.username.includes('@')) {
            setError('username', { type: 'invalid', message: ' Логин не должен содержать символ "@"' })
        } else {
            const company = {}
            const user = {}
            const subCompany = {}
            userStore.userFormFields.forEach(item =>{
                if(item.tech_name.includes('company'))
                    company[item.tech_name?.replace('company_', '')] = form[item.tech_name].trim()
                else if (item.tech_name.includes('sub'))
                    subCompany[item.tech_name?.replace('sub_', '')] = form[item.tech_name].trim()
                else 
                    user[item.tech_name] = form[item.tech_name].trim()
            })

            sessionStorage.setItem('editMyCompany', JSON.stringify(company))
            sessionStorage.setItem('editUserMe', JSON.stringify(user))
            sessionStorage.setItem('editMySubCompany', JSON.stringify(subCompany))

            userStore.editUserMe(user)
            if (userStore.checkPermission("company.my:edit")) {
                userStore.editMyCompany(company)
                userStore.editMySubCompany(subCompany, userStore.user.curr_sub_company_id)
            }
        }
    }

    useEffect(() => {
        if (userStore.isEditUserSuccess && ((userStore.isEditCompanySuccess && userStore.isEditSubCompanySuccess) || !userStore.checkPermission("company.my:edit"))) {
            userStore.setIsUserInfoFormOpen(false)
            userStore.setIsEditCompanySuccess(false)
            userStore.setIsEditUserSuccess(false)
            userStore.setIsEditSubCompanySuccess(false)
            userStore.getUserMe()
            userStore.getMySubCompanies()
        } else {
            const userStorage = JSON.parse(sessionStorage.getItem('editUserMe'))
            if(userStorage){
                Object.entries(userStorage).forEach(([key, value]) => {
                    setValue(key, value)
                })
            }

            const companyStorage = JSON.parse(sessionStorage.getItem('editMyCompany'))
            if (companyStorage) {
                Object.entries(companyStorage).forEach(([key, value]) => {
                    setValue(`company_${key}`, value)
                })
            }

            const subCompanyStorage = JSON.parse(sessionStorage.getItem('editMySubCompany'))
            if(subCompanyStorage){
                Object.entries(subCompanyStorage).forEach(([key, value]) => {
                    setValue(`sub_${key}`, value)
                })
            }
        }
    }, [userStore.isEditUserSuccess, userStore.isEditCompanySuccess, userStore.isEditSubCompanySuccess]);

    useEffect(() => {
        if (Object.entries(errors).length > 0 && isSubmitting === false) {
            toast.error(<FormErrorToastPanel errors={errors} fields={userStore.userFormFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <form className="tw-relative tw-mt-6 tw-flex-1 tw-px-4 sm:tw-px-6 tw-overflow-y-auto">
            {userStore.userFormFields.map((item, index) => {
                let permission = (item.tech_name.includes('company') || item.tech_name.includes('sub'))
                    ?   !userStore.checkPermission("company.my:edit")
                    :   false
                let value = item.tech_name.includes('company') 
                    ? userStore.user?.company[item?.tech_name.replace('company_', '')] 
                    : item.tech_name.includes('sub') 
                        ? userStore.currentSubCompany[item.tech_name?.replace('sub_', '')]
                        : userStore.user[item?.tech_name]

                return <div key={index} className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-overflow-y-auto">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center">
                            <label htmlFor={item.tech_name}>{item.alias}</label>
                            <span>
                                { (errors[item.tech_name] || value == null || value === '') && 
                                    <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>
                                }
                            </span>
                        </dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                            <input
                                id={item.tech_name}
                                className={`tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-2 tw-text-gray-700
                                            tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 
                                            sm:tw-text-sm focus-visible:tw-outline-none focus-visible:tw-ring-2
                                            ${(errors[item.tech_name] || value == null || value === '') ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                            disabled:tw-text-gray-400 disabled:tw-ring-gray-300
                                            `}
                                disabled={permission}
                                {...register(item.tech_name, { 
                                    required: item.mandatory, 
                                    value: value
                                })}
                            />
                        </dd>
                    </div>
                })
            }

            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-py-4'>
                <button 
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={handleSubmit(onSubmitClick)}
                >
                    Сохранить
                </button>
                <button
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                            hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={(e) => {e.preventDefault(); userStore.setIsUserInfoFormOpen(false)}}
                >
                    Отменить
                </button>
            </div>
        </form>         
    )
}

export default observer(UserInfoForm)