import React, { useContext, useEffect } from 'react'
import { Context } from '../../../../index'
import { observer } from 'mobx-react-lite'
import Spinner from '../../../../assets/Spinner'
import PageTitle from '../common/panels/PageTitle'
import DataModelList from './data_model/DataModelList'
import CategoryList from './list/CategoryList'
import CategoryMenu from './menu/CategoryMenu'
import CategoryForm from './form/CategoryForm'


/**
 * Компонент реализует логику работы с категориями
 * 
 */
const CategoryListContainer = () => {
    const { docStore } = useContext(Context)
    
    useEffect(() => {
        const defaultFilter = []
        const categorySorter = JSON.stringify([
            {property: 'order', desc: false},
        ])      
        docStore.getCategories(defaultFilter, categorySorter)

        const dataModelSorter = JSON.stringify([
            {property: 'entity_name', desc: false},
        ])
        docStore.getDataModels(defaultFilter, dataModelSorter)
    }, [])

    useEffect(() => {
        if (docStore.selectedCategory) {
            const filteredList = docStore.dataModels.filter(item => item.category_ids.includes(docStore.selectedCategory.id)).map(item => item.id)
            docStore.setCategoryDataModels(filteredList)
        } else if (docStore.selectedDataModel) {
            const updatedDataModel = docStore.dataModels.find(item => item.id === docStore.selectedDataModel.id)
            if (updatedDataModel) {
                docStore.setDataModelCategories(updatedDataModel.category_ids)
                docStore.setSelectedDataModel(updatedDataModel)
            }
        }

        if (docStore.selectedDataModel) {
            const selectedDataModel = document.getElementById(docStore.selectedDataModel.id)
            selectedDataModel.scrollIntoView({ block: "center", behavior: "smooth" })
        }
    }, [docStore.dataModels])

    return (
        <>
            <PageTitle title={'Группировка таблиц'} />
            <div id='category-page' className='tw-grid tw-grid-cols-4 tw-gap-x-8 tw-max-h-full tw-h-full'>
                <div id='data-model-list' className='tw-col-span-2 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <div id='data-model-header' className='tw-h-12 tw-flex tw-items-center tw-border-b-2 tw-border-gray-400 tw-px-4 tw-py-2'>
                        <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Таблицы</p>
                    </div>
                    { docStore.isDataModelLoading
                        ?   <Spinner/>
                        :   <DataModelList/>
                    }
                </div>
                <div id='category-column' className='tw-col-span-2 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <CategoryMenu/>
                    { !docStore.isCategoryCreating && !docStore.isCategoryEditing &&
                        <CategoryList/>
                    }
                    { (docStore.isCategoryCreating || docStore.isCategoryEditing) &&
                        <CategoryForm/>
                    }
                </div>
            </div>
        </>
    )
}

export default observer(CategoryListContainer)