import React from 'react'
import SimpleItem from './SimpleItem'


/**
 * Визуальный компонент отображает список связываемых сущностей (для списка из выбранных пользователем записей)
 * 
 * @param {Object[]} list список связанных объектов КИИ или ресурсов доступа
 * @param {Object} selectedItem Выбранный элемент списка
 * @param {Object} linkingItem Элемент, связанный с выбранным элементом списка
 * @param {String} linkField Имя поля, по которому выполняется связывание
 * @param {Function} onItemClick Обработчик клика мыши на элементе списка
 * @param {Function} onLinkClick Обработчик клика мыши на элементе списка, связанном с выбранным элементом списка
 * 
 */
const SimpleItemList = ({list, selectedItem, linkingItem, linkField, onItemClick, onLinkClick}) => {
    return (
        <div className='tw-grow tw-overflow-auto'>
            { list
                .slice()
                .filter(item => !item.system_data.deletion_mark)
                .map((item, index) => 
                    <SimpleItem 
                        key={index}
                        item={item}
                        selectedItem={selectedItem}
                        linkingItem={linkingItem}
                        linkField={linkField}
                        onClick={onItemClick}
                        onLinkClick={onLinkClick}
                    />
            )}
        </div>
    )
}

export default SimpleItemList