import React from 'react'
import { paths } from '../../config/constsURN'

/**
 * Визуальный компонент отображает нижний блок ("футер") главной страницы
 * 
 */
const Footer = () => {
    return (
        <div id='footer' className="tw-grid tw-grid-cols-3 tw-gap-x-4 tw-text-white tw-bg-gray-800 tw-text-xs sm:tw-text-sm tw--mx-8 tw-mt-8 lg:tw-px-10 tw-py-6">
            <div className='tw-px-4 tw-text-left'>
                <p className='tw-font-semibold'>Общество с ограниченной ответственностью «Медоед»</p>
                <br/>
                <p>Юридический адрес:</p>
                <p>111524, город Москва, Электродная ул., д. 2 стр. 34, помещ. 5/1</p>
                <br/>
                <p>ИНН: 7720922026</p>
                <p>КПП: 772001001</p>
                <p>ОГРН: 1247700065910</p>
            </div>
            <div className='tw-px-4 tw-text-center'>
                <p className='tw-font-semibold'>Контакты</p>
                <br/>
                <p>Тел: +7 (495) 309-31-25</p>
                <p>Эл. почта: <a className='tw-font-semibold hover:tw-text-sky-300' href="mailto:support.medoed@rtmtech.ru">support.medoed@rtmtech.ru</a></p>
            </div>
            <div className='tw-px-4 tw-text-right'>
                <p><a className='tw-font-semibold hover:tw-text-sky-300 focus-visible:tw-outline-none focus-visible:tw-text-sky-500' 
                    href={paths.POLICY_ROUTE}
                >Политика конфиденциальности</a></p>
                <p><a className='tw-font-semibold hover:tw-text-sky-300 focus-visible:tw-outline-none focus-visible:tw-text-sky-500'
                    href={paths.LICENSE_ROUTE}
                >Лицензионное соглашение</a></p>
                <br/>
                <p><a className='tw-font-semibold hover:tw-text-sky-300 focus-visible:tw-outline-none focus-visible:tw-text-sky-500' 
                    href='https://medoed-soft.ru/' target="_blank" rel='noreferrer'
                >Про MEDOED</a></p>
                <br/>
                <p className='tw-italic'>&copy; 2023-2024, ООО «Медоед»</p>
            </div>
        </div>
    )
}

export default Footer