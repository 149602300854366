import { $dataHost } from "../http/http"
import { documentAPI } from "../config/constsURN"


/**
 * Класс реализует API для работы с категориями таблиц
 * @class
 */
class CategoryService {

    /**
     * Отправляет запрос на получение списка всех существующих категорий
     * @static
     * @param {Number} count Лимит на количество возвращаемых категорий
     * @returns {Object[]} Массив категорий
     */
    static async getCategories(count = 50, filters, sorters) {
        let data = []
        let response
        let offset = 0

        do {
            response = await $dataHost.get(documentAPI.CATEGORY_API + '/search', {params: {offset, count, total: true, filter: filters, sort: sorters}})
            data = data.concat(response.data)
            offset += count
        } while (response?.data?.length === count)

        return data
    }

    /**
     * Отправляет запрос на получение одной выбранной категории
     * @param {String} id ID категории
     * @returns {Object} Структура категории
     */
    static async getOneCategory(id) {
        const {data} = await $dataHost.get(documentAPI.CATEGORY_API + '/' + id)
        return data
    }
    

    /**
     * Отправляет запрос на создание одной категории
     * @static
     * @param {Object} category Данные о структуре категории
     * @returns {Object} Созданная категория
     */
    static async createCategory(category) {
        const {data} = await $dataHost.post(documentAPI.CATEGORY_API, category)
        return data
    }

    /**
     * Отправляет запрос на изменение порядкового номера категории
     * @static
     * @param {String} id ID категории
     * @param {Number} offset Смещение порядкового номера категории относительно текущего значения
     * @returns {Object} Созданная категория
     */
    static async moveCategory(id, offset) {
        const {data} = await $dataHost.post(documentAPI.CATEGORY_API + '/' + id + '/move', {}, {params: {count: offset}})
        return data
    }

    /**
     * Отправляет запрос на обновление структуры выбранной категории
     * @static
     * @param {String} id ID категории
     * @param {Object} category Данные о структуре категории
     * @returns {null} Пустой ответ
     */
    static async editCategory(id, category) {
        const {data} = await $dataHost.put(documentAPI.CATEGORY_API + '/' + id, category)
        return data
    }

    /**
     * Отправляет запрос на удаление одной категории
     * @static
     * @param {String} id ID категории
     * @returns {null} Пустой ответ
     */
    static async deleteCategory(id) {
        const {data} = await $dataHost.delete(documentAPI.CATEGORY_API + '/' + id)
        return data
    }

}

export default CategoryService