import React, { useContext, useEffect, useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../../../../index'
import DocumentService from '../../../../../services/DocumentService'
import { paths } from '../../../../../config/constsURN'
import DataModelForm from '../form/DataModelForm'
import { toast } from 'react-toastify'
import PageTitle from '../../common/panels/PageTitle'
import { responseTimeOut, serviceMessageTimeOut } from '../../../../../config/constTypes'
import { showErrorToast } from '../../../../../functions/errorHandlers'

/**
 * Компонент реализует логику создания новой таблицы
 * 
 */
const AddDataModelContainer = () => {
    const navigate = useNavigate()
    const { docStore, FieldStore } = useContext(Context)

    const [dataModelFields, setDataModelFields] = useState([])
   
    const handleFieldEdition = (newField) => {
        if (newField) {
            const foundField = dataModelFields.find(field => field.field_id === newField.id)
            if (foundField) {
                setDataModelFields(dataModelFields.map(field =>
                    field.field_id === foundField.field_id
                        ?   {...newField, field_id: newField.id, hide: field.hide, order: field.order}
                        :   field
                ))
            } else {
                setDataModelFields([...dataModelFields, {...newField, field_id: newField.id, hide: false, order: dataModelFields.length}])
            }
        }

        FieldStore.setSelectedField({...newField, field_id: newField.id})
    }

    const handleHideFieldClick = (selectedField) => {
        if (!selectedField.mandatory) {
            setDataModelFields(dataModelFields.map(field => {
                if (field.id !== selectedField.id) {
                    return field 
                } else {
                    FieldStore.setSelectedField({...field, hide: !field.hide})
                    return {...field, hide: !field.hide}
                }
            }))
        } else {
            FieldStore.setSelectedField(selectedField)
            toast.error('Обязательное поле скрыть нельзя!', { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })   
        }
    }

    const handleUpFieldClick = (position) => {
        setDataModelFields(dataModelFields.map(field => 
            (field.order === position)
            ?   {...field, order: field.order - 1}
            :   (field.order === position - 1)
                ?   {...field, order: field.order + 1}
                :   field
        ))
        FieldStore.setSelectedField({...FieldStore.selectedField, order: FieldStore.selectedField.order - 1})
    }

    const handleDownFieldClick = (position) => {
        setDataModelFields(dataModelFields.map(field =>
            (field.order === position)
            ?   {...field, order: field.order + 1}
            :   (field.order === position + 1)
                ?   {...field, order: field.order - 1}
                :   field
        ))
        FieldStore.setSelectedField({...FieldStore.selectedField, order: FieldStore.selectedField.order + 1})
    }

    const handleRemoveFieldClick = (id) => {
        setDataModelFields(dataModelFields.filter(field => field.id !== id).map((field, index) => {return {...field, order: index}}))
        FieldStore.setSelectedField(null)
    }

    const handleSubmitClick = (form) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        const newDataModel = {}
        newDataModel.entity_name = form.modelName
        newDataModel.description = form.modelDescription
        newDataModel.type = form.modelType.value
        newDataModel.fields = dataModelFields.map((field, index) => {return { field_id: field.id, hide: field.hide, order: index, description: form.data[index].description }})

        DocumentService
            .createDataModel(newDataModel)
            .then(data => {
                clearTimeout(noResponse)
                toast.success('Таблица успешно сохранена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                navigate(paths.DATAMODEL_ROUTE)
            })
            .catch(error => {
                clearTimeout(noResponse)
                if (error.response.data.message.includes('from DOCUMENT data model to DIRECTORY data model')) {
                    toast.error(<div>Ошибка при сохранении данных!<br/><br/>Справочник не может ссылаться на документ</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
                } else {
                    showErrorToast(error, 'saving', '')
                }
            })
    }

    const handleCancelClick = () => navigate(paths.DATAMODEL_ROUTE)

    
    useEffect( () => {
        const filter = JSON.stringify([
            {property: 'hide_mark', value: false, operator: 'eq'},
        ])
        const sorter = JSON.stringify([
            {property: 'entity_name', desc: false},
        ])      

        docStore.getDataModels(filter, sorter)
    }, [])


    return (
        <Fragment>
            <PageTitle title='Новая таблица'/>
            <div id='data-model-add-page' className='tw-h-full tw-overflow-hidden tw-relative tw-pl-4'>
                <DataModelForm
                    editMode={false}
                    dataModel={null}
                    fields={dataModelFields}
                    onEditField={handleFieldEdition}
                    onHideFieldClick={handleHideFieldClick}
                    onRemoveFieldClick={handleRemoveFieldClick}
                    onUpFieldClick={handleUpFieldClick}
                    onDownFieldClick={handleDownFieldClick}
                    onSubmitClick={handleSubmitClick}
                    onCancelClick={handleCancelClick}
                />
            </div>
        </Fragment>
    )
}

export default AddDataModelContainer