import React, { useState, useEffect, useContext, Fragment, useRef } from 'react'
import { Context } from '../../../../../..'
import DocumentService from '../../../../../../services/DocumentService'
import DirectoryPanel from './DirectoryPanel'
import DataObjectMenu from '../../../data_object/menu/DataObjectMenu'
import AddDataObjectContainer from '../../../data_object/add/AddDataObjectContainer'
import DuplicateDataObjectContainer from '../../../data_object/add/DuplicateDataObjectContainer'
import EditDataObjectContainer from '../../../data_object/edit/EditDataObjectContainer'
import Spinner from '../../../../../../assets/Spinner'
import { toast } from 'react-toastify'
import { responseTimeOut, serviceMessageTimeOut, successMessageTimeOut } from '../../../../../../config/constTypes'
import { showErrorToast } from '../../../../../../functions/errorHandlers'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { observer } from 'mobx-react-lite'
import { initNestedModels } from '../../../../../../functions/nestedModels'
import { setSorting } from '../../../../../../functions/sortingsAndFilters'
import FilterStore from '../../../../../../store/FilterStore'
import RecordNotesStore from '../../../../../../store/RecordNotesStore'
import { useHotkeys } from 'react-hotkeys-hook'
import { copyToClipboard } from '../../../../../../functions/copyToClipboard'
import { paths } from '../../../../../../config/constsURN'


/**
 * Компонент реализует логику работы с записями таблиц (данными) во всплывающем окне
 * 
 * @param {Boolean} isOpen Признак видимости (отображения) модального окна
 * @param {Object} selectedDataModel Выбранная таблица
 * @param {Function} onDoubleClick Обработчик двойного клика мыши
 * @param {Function} onCloseClick Обработчик клика мыши для закрытия окна
 * @param {Object[]} chosenDataObjectsList Массив выбранных записей
 * @param {Boolean} isChosenObjectDuplicationForbidden Признак запрета повторного добавления записи
 * @param {Boolean} isMultiSelect Признак возможности добавления нескольких записей сразу
 * @param {Boolean} isDuplicateObject Признак дублирования объекта
 * 
 */
const DirectoryContainer = ({isOpen, selectedDataModel, onDoubleClick, onCloseClick, chosenDataObjectsList, isChosenObjectDuplicationForbidden, 
        isMultiSelect, isDuplicateObject}) => {
    const { docStore } = useContext(Context)
    const [filterStore] = useState(() => new FilterStore())
    const [recordNotesStore] = useState(() => new RecordNotesStore())

    const [isLoading, setIsLoading] = useState(true)
    const [isNestedLoading, setIsNestedLoading] = useState(false)
    const [dataObjects, setDataObjects] = useState(null)
    const [selectedFullDataModel, setSelectedFullDataModel] = useState(null)
    const [nestedDataModels, setNestedDataModels] = useState([])
    const [isHistoryPressed, setIsHistoryPressed] = useState(false)
    const [isSortingPressed, setIsSortingPressed] = useState(false)
    const [selectedNestedDataModel, setSelectedNestedDataModel] = useState(null)
    const [sortingColumn, setSortingColumn] = useState({data_model_id: '', column_tech_name: 'created', column_validator_type: 'date', column_rule_id: '', direction: 'down'})
    const [isFetchingData, setIsFetchingData] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [dataObjectOffset, setDataObjectOffset] = useState(0)

    const [isAddFormOpen, setIsAddFormOpen] = useState(false)
    const [isDuplicateFormOpen, setIsDuplicateFormOpen] = useState(false)
    const [isEditFormOpen, setIsEditFormOpen] = useState(false)
    const [id, setID] = useState(null)
    const [fetchedDataObjects, setFetchedDataObjects] = useState(null)

    const [selectedDataObject, setSelectedDataObject] = useState(null)
    const panelElement = useRef(null)

    const isInitialState = isOpen && !isAddFormOpen && !isDuplicateFormOpen && !isEditFormOpen

    useHotkeys('ctrl+a', () => {if (isInitialState) handleAddDataObjectClick()}, { preventDefault: true })
    useHotkeys('ctrl+d', () => {if (isInitialState && selectedDataObject) handleDuplicateClick(selectedDataObject.id)}, { preventDefault: true })
    useHotkeys('ctrl+e', () => {if (isInitialState && selectedDataObject) handleEditDataObjectClick(selectedDataObject.id)}, { preventDefault: true })
    useHotkeys('ctrl+r', () => {if (isInitialState && selectedDataObject) handleDeleteDataObjectClick(selectedDataObject)}, { preventDefault: true })
    useHotkeys('ctrl+h', () => {if (isInitialState && selectedDataObject) handleViewHistoryClick()}, { preventDefault: true })
    useHotkeys('ctrl+i', () => {if (isInitialState && selectedDataObject) handleViewRecordClick()}, { preventDefault: true })
    useHotkeys('ctrl+l', () => {if (isInitialState && selectedDataObject) handleCopyToClipboardClick()}, { preventDefault: true })

    const handleDataObjectClick = (dataObject) => {
        if(!selectedDataObject || dataObject.id !== selectedDataObject?.id) {
            setSelectedDataObject(dataObject)
            if (dataObject.system_data.files && dataObject.system_data.files.length) {
                docStore.setAttachedFiles(dataObject.system_data.files)
            } else {
                docStore.setAttachedFiles([])
            }
            setNestedDataModels(initNestedModels(selectedFullDataModel))

            recordNotesStore.setRecordID(dataObject.record_id)
        }
    }

    const handleAddDataObjectClick = async () => {
        const isDataModelLocked = await docStore.checkDataModelLock(selectedFullDataModel.id)
        if (isDataModelLocked) {
            toast.error('Создание записи невозможно: таблица заблокирована для импорта данных!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
        } else {
            setIsAddFormOpen(true)
        }
    }

    const handleDuplicateClick = async (id) => {
        const isDataModelLocked = await docStore.checkDataModelLock(selectedFullDataModel.id)
        if (isDataModelLocked) {
            toast.error('Создание записи невозможно: таблица заблокирована для импорта данных!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
        } else {
            setIsDuplicateFormOpen(true)
            setID(id)
        }
    }

    const handleEditDataObjectClick = async (id) => {
        const isDataModelLocked = await docStore.checkDataModelLock(selectedFullDataModel.id)
        if (isDataModelLocked) {
            toast.error('Редактирование записи невозможно: таблица заблокирована для импорта данных!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
        } else {
            setIsEditFormOpen(true)
            setID(id)
        }
    }

    const handleViewRecordClick = () => {
        docStore.setIsDetailView(!docStore.isDetailView)
    }

    const handleViewHistoryClick = () => {
        setIsHistoryPressed(true)
        docStore.setIsDetailView(false)
    }

    const handleDeleteDataObjectClick = (item) => {
        const deletionMark = (item.system_data && item.system_data.deletion_mark) ? false : true
        docStore.deleteDataObject(item.record_id, deletionMark, dataObjects, setDataObjects, selectedFullDataModel.id, setTotalCount, setDataObjectOffset)
    }
    
    const handleCloseClick = () => {
        filterStore.setSearchField('')
        filterStore.setSearchFilters([])
        onCloseClick()
    }

    const updateDataObjectList = (dataModel, offset) => {
        const defaultFilter = [
            {property: 'data_model_id', value: dataModel?.id, operator: 'eq'},
            {property: 'transaction_id', value: null, operator: 'eq'},
            {property: 'active', value: true, operator: 'eq'}
        ].concat(
            dataModel?.allow_hierarchy && !filterStore.selectedFilters.length && !filterStore.searchFilters.length
                ?   [{property: 'system_data.parent_record_id', value: null, operator: 'eq'}]
                :   []
        )
        const filters = JSON.stringify(defaultFilter.concat(filterStore.selectedFilters).concat(filterStore.searchFilters))         
        const sorter = JSON.stringify(setSorting(dataModel, sortingColumn, sortingColumn.direction))

        docStore.setSelectedFilters(filters)
        const mode = isDuplicateObject ? 'r' : 'w'

        docStore.getDataObjectsByID(filters, sorter, offset, dataObjects, (data) => {
                setDataObjects(data)

                if (offset === 0 && !selectedDataObject) {
                    if (!isMultiSelect) {
                        if (chosenDataObjectsList?.length && chosenDataObjectsList[0]?.value?.values?.length) {
                            
                            data.forEach(item => {
                                if (item.record_id === chosenDataObjectsList[0].value.values[0].record_id) {
                                    setSelectedDataObject(item)
                                }
                            })
                        }
                    }
                }
            }, setTotalCount, setDataObjectOffset, mode)
    }

    const handleObjectContainerCloseClick = () => {
        updateDataObjectList(selectedFullDataModel, 0)
        setNestedDataModels(initNestedModels(selectedFullDataModel))

        setIsAddFormOpen(false)
        setIsDuplicateFormOpen(false)
        setIsEditFormOpen(false)
    }

    const handleSortClick = (column) => {
        let direction = 'down'
        if (column.column_tech_name === sortingColumn.column_tech_name) {
            if (sortingColumn.direction === 'down') {
                direction = 'up'
            }
        }
        setSortingColumn({...column, direction: direction})
        setSelectedFullDataModel({...selectedFullDataModel, sortingColumn: column, sortingDirection: direction})
        docStore.setSelectedSorting(column, direction)
        setIsSortingPressed(true)
    }

    const handleHierarchyClick = async (dataObject) => {
        if (!dataObject.hierarchyVisible) {
            const updatedDataObjects = await docStore.showTreeNode(dataObjects, dataObject, filterStore.selectedFilters, setSorting(selectedFullDataModel, sortingColumn, sortingColumn.direction))
            setDataObjects(updatedDataObjects)
        } else {
            const updatedDataObjects = docStore.hideTreeNode(dataObjects, dataObject)
            setDataObjects(updatedDataObjects)
        }
    }

    const handleCopyToClipboardClick = () => {
        try {
            copyToClipboard(`${paths.DATAOBJECT_ROUTE}/${selectedFullDataModel.id}/?text=record_id=${selectedDataObject.record_id}`)
            toast.info('Ссылка скопирована в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
        } catch (error) {
            toast.error('Не удалось скопировать ссылку в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }
    }

    useEffect(() => {
        if (isOpen && selectedDataModel) {
            const noResponse = setTimeout(() => {
                toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                setIsLoading(false)
                setSelectedFullDataModel({id:'0', entity_name: '', fields: []})
            }, responseTimeOut)
            
            setIsLoading(true)
            const savedSortingColumn = docStore.loadSavedSorting(selectedDataModel)
            setSortingColumn(savedSortingColumn)
            setSelectedDataObject(null)

            DocumentService
                .getOneDataModel(selectedDataModel)
                .then(data => {
                    clearTimeout(noResponse)
                    setSelectedFullDataModel({...data, sortingColumn: savedSortingColumn, sortingDirection: savedSortingColumn.direction})

                    const filters = JSON.parse(sessionStorage.getItem(`${data.id}_filters`))
                    let fields = []

                    if (selectedDataModel === 'protection_measures') {
                        fields = filterStore.getFilterColumnNames(data)
                        fields.push({
                            name: "files",
                            value: null,
                            operator: 'in',
                            active: false
                        })
                        fields.push({
                            name: "modifier_id",
                            value: null,
                            operator: 'eq',
                            active: false
                        })
                        fields.push({
                            name: "subgroup_id",
                            value: null,
                            operator: 'eq',
                            active: false
                        })

                        sessionStorage.setItem(`protection_measures_filterFields`, JSON.stringify(fields))
                    } else {
                        fields = JSON.parse(sessionStorage.getItem(`${data.id}_filterFields`))
                    }
                    
                    if (filters?.length && fields?.length) {
                        filterStore.setSelectedFilters(filters)
                        filterStore.setFilteredFields(fields)
                        filterStore.setIsFiltering(true)
                    } else {
                        filterStore.filterClick(data, true, true, true)
                    }

                    setIsLoading(false)
                    setNestedDataModels(initNestedModels(data))
                })
                .catch(error => {
                    clearTimeout(noResponse)
                    setIsLoading(false)
                    showErrorToast(error, 'fetching', '')
                    setSelectedFullDataModel({id: '0', entity_name: '', fields: []})
                })
        } else {
            filterStore.setSearchFilters([])
            if (docStore.isHistoryView)
                docStore.setIsHistoryView(false)
            if (docStore.isDetailView)
                docStore.setIsDetailView(false)
        }
    }, [isOpen])

    useEffect(() => {
        if (isHistoryPressed && selectedDataObject) {
            if (!docStore.isHistoryView) {
                setFetchedDataObjects(dataObjects)
                const historyFilter = JSON.stringify([
                    {property: 'record_id', value: selectedDataObject.record_id, operator: 'eq'},
                ])
                const defaultSorter = []
                docStore.getDataObjectHistory(historyFilter, defaultSorter, false, setDataObjects)
            } else {
                if (fetchedDataObjects) {
                    setDataObjects(fetchedDataObjects)
                } else {
                    updateDataObjectList(selectedFullDataModel, 0) 
                    setSelectedDataObject(null)
                }
            }
            docStore.setIsHistoryView(!docStore.isHistoryView)
            setIsHistoryPressed(false)
        }
    }, [isHistoryPressed])

    useEffect(()=>{
        if (isSortingPressed && selectedFullDataModel){
            updateDataObjectList(selectedFullDataModel, 0) 
            setIsSortingPressed(false)
        }
    }, [sortingColumn])

    useEffect(()=>{
        if (selectedFullDataModel){
            sessionStorage.setItem(`${selectedFullDataModel?.id}_filters`, JSON.stringify(filterStore.selectedFilters))
            sessionStorage.setItem(`${selectedFullDataModel?.id}_filterFields`, JSON.stringify(filterStore.filteredFields))
            
            updateDataObjectList(selectedFullDataModel, 0)
            recordNotesStore.setRecordID(null) 
        }
    }, [filterStore.selectedFilters])

    useEffect(()=>{
        if (isFetchingData){
            if (dataObjectOffset < totalCount) {
                updateDataObjectList(selectedFullDataModel, dataObjectOffset) 
            } else {
                setIsFetchingData(false)
            }
        }
    }, [isFetchingData])

    useEffect(()=>{
        if (!docStore.isDataObjectLoading && isFetchingData){
            setIsFetchingData(false)
        }
    }, [docStore.isDataObjectLoading])

    useEffect(() => {
        if(selectedDataObject){
            try {
                setIsNestedLoading(true)
                const nestedValueRequests = nestedDataModels.map(nestedModel => DocumentService.getNestedDataObjects(50, [], [], selectedDataObject.id, nestedModel.rule_id))
                Promise.all(nestedValueRequests)
                    .then(responses => {
                        setNestedDataModels(nestedDataModels.map((nestedModel, index) =>  {
                            return {
                                ...nestedModel,
                                dataObjects: responses[index]
                            }
                        }))
                        setIsNestedLoading(false)
                    })
            } catch (error) {
                showErrorToast(error, 'fetching', '') 
                setNestedDataModels(nestedDataModels.map((nestedModel) =>  {
                    return {
                        ...nestedModel,
                        dataObjects: []
                    }
                }))
                setIsNestedLoading(false)
            }
        }
    }, [selectedDataObject])

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={handleCloseClick}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-500"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-500"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                    <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                        <div ref={panelElement} className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex  tw-pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                enterFrom="tw-translate-x-full"
                                enterTo="tw-translate-x-0"
                                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                leaveFrom="tw-translate-x-0"
                                leaveTo="tw-translate-x-full"
                            >
                                <Dialog.Panel  className="tw-pointer-events-auto tw-relative tw-w-[90vw]"> 
                                    <Transition.Child
                                        as={Fragment}
                                        enter="tw-ease-in-out duration-500"
                                        enterFrom="tw-opacity-0"
                                        enterTo="tw-opacity-100"
                                        leave="tw-ease-in-out tw-duration-500"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                    >
                                        <div className="tw-absolute tw-left-0 tw-top-0 tw--ml-8 tw-flex tw-pr-2 tw-pt-4 sm:tw--ml-10 sm:tw-pr-4">
                                        <button
                                            type="button"
                                            className="tw-rounded-md hover:tw-text-gray-300 tw-text-white hover:tw-bg-gray-500 focus:tw-outline-none tw-ring-2 tw-ring-white"
                                            onClick={handleCloseClick}
                                        >
                                            <span className="tw-sr-only">Закрыть</span>
                                            <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                                        </button>
                                        </div>
                                    </Transition.Child>
                                    <div id='data-object-fields' 
                                        className={`${isMultiSelect ? 'tw-pt-4' : 'tw-pt-12'} tw-h-full tw-overflow-hidden tw-px-6 
                                                    tw-pb-2 tw-bg-gray-200 tw-shadow-xl`}
                                    >
                                        {isMultiSelect && 
                                            <div className='tw-flex tw-flex-row tw-items-center tw-gap-x-4 tw-mb-4'>
                                                <button 
                                                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 
                                                        tw-text-sm tw-font-semibold tw-border-gray-700 tw-bg-gray-700 tw-text-white hover:tw-bg-gray-500
                                                        disabled:tw-bg-gray-300  disabled:tw-border-gray-300 focus-visible:tw-outline
                                                        focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                                    onClick={handleCloseClick}
                                                >
                                                    <span>Завершить выбор</span>
                                                </button>
                                                <div className='tw-text-sm tw-italic'>Включен режим множественного выбора записей двойным кликом</div>
                                            </div>
                                        }
                                        <div id='data-object-menu' className='tw-flex tw-flex-row tw-items-center tw-bg-white tw-rounded-t-md
                                                                                tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-z-20'
                                        >
                                            <DataObjectMenu
                                                onAddItemClick={handleAddDataObjectClick}
                                                onDuplicateItemClick={handleDuplicateClick}
                                                onEditItemClick={handleEditDataObjectClick}
                                                onViewRecordClick={handleViewRecordClick}
                                                onItemHistoryClick={handleViewHistoryClick}
                                                onDeleteItemClick={handleDeleteDataObjectClick}
                                                selectedDataObject={selectedDataObject}
                                                selectedFullDataModel={selectedFullDataModel}
                                                onCopyLinkClick={handleCopyToClipboardClick}
                                                isDocumentPage={false}
                                                updateDataObjectList={updateDataObjectList}
                                                filterStore={filterStore}
                                            />
                                        </div>
                                        {isLoading
                                            ?   <Spinner/>
                                            :   <DirectoryPanel
                                                    onItemClick={handleDataObjectClick}
                                                    onItemDoubleClick={onDoubleClick}
                                                    selectedDataObject={selectedDataObject}
                                                    selectedFullDataModel={selectedFullDataModel}
                                                    dataObjects={dataObjects}
                                                    nestedDataModels={nestedDataModels}
                                                    setSelectedNestedDataModel={setSelectedNestedDataModel}
                                                    selectedNestedDataModel={selectedNestedDataModel}
                                                    setNestedDataModels={setNestedDataModels}
                                                    onSortClick={handleSortClick}
                                                    onFetchData={setIsFetchingData}
                                                    chosenDataObjectsList={chosenDataObjectsList}
                                                    isNestedLoading={isNestedLoading}
                                                    filterStore={filterStore}
                                                    recordNotesStore={recordNotesStore}
                                                    isChosenObjectDuplicationForbidden={isChosenObjectDuplicationForbidden}
                                                    onHierarchyClick={handleHierarchyClick}
                                                />
                                        }
                                   </div>
                                   <AddDataObjectContainer
                                        isOpen={isAddFormOpen}
                                        onCloseClick={handleObjectContainerCloseClick}
                                        selectedFullDataModel={selectedFullDataModel}
                                        recordNotesStore={recordNotesStore}
                                    />
                                    <DuplicateDataObjectContainer
                                        isOpen={isDuplicateFormOpen} 
                                        onCloseClick={handleObjectContainerCloseClick}
                                        id={id}
                                        selectedFullDataModel={selectedFullDataModel}
                                        recordNotesStore={recordNotesStore}
                                    />
                                    <EditDataObjectContainer
                                        isOpen={isEditFormOpen} 
                                        onCloseClick={handleObjectContainerCloseClick}
                                        id={id}
                                        selectedFullDataModel={selectedFullDataModel}
                                        recordNotesStore={recordNotesStore}
                                    />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default observer(DirectoryContainer)