import React, { useContext } from 'react'
import { DocumentPlusIcon, PencilSquareIcon, TrashIcon, BarsArrowUpIcon, BarsArrowDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { menuTooltipTimeOut } from '../../../../../config/constTypes'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'
import DialogTab from '../../../../dialog_tab/DialogTab'
import { useHotkeys } from 'react-hotkeys-hook'


/**
 * Визуальный компонент отображает меню возможных действий над категориями
 * 
 */
const CategoryMenu = () => {
    const { docStore, DialogTabStore, userStore } = useContext(Context)

    const isInitialState = !docStore.isCategoryLinking && userStore.checkPermission("category:edit")

    useHotkeys('ctrl+a', () => {if (isInitialState) onAddItemClick()}, { preventDefault: true })
    useHotkeys('ctrl+e', () => {if (isInitialState && docStore.selectedCategory) onEditItemClick()}, { preventDefault: true })
    useHotkeys('ctrl+r', () => {if (isInitialState && docStore.selectedCategory) onDeleteItemClick()}, { preventDefault: true })
    useHotkeys('ctrl+up', () => {if (isInitialState && docStore.selectedCategory && docStore.selectedCategory.order > 1) onUpItemClick()}, { preventDefault: true })
    useHotkeys('ctrl+down', () => {if (isInitialState && docStore.selectedCategory && docStore.selectedCategory.order < docStore.categories.length) onDownItemClick()}, { preventDefault: true })

    const onEditItemClick = () => {
        if (!docStore.isCategoryLinking)
            docStore.setIsCategoryEditing(true)
    }

    const onAddItemClick = () => {
        docStore.setIsCategoryCreating(true)
        docStore.setSelectedCategory(null)
    }

    const deleteCategory = () => {
        docStore.deleteCategory()
        DialogTabStore.setDialogTabIsOpen(false) 
    }

    const onDeleteItemClick = () => {
        DialogTabStore.setParentName('CategoryMenu')
        DialogTabStore.setDialogTabTitle("Удаление категории") 
        DialogTabStore.setDialogTabText("Вы уверены, что хотите удалить эту категорию?")
        DialogTabStore.setDialogTabButtons(["Да", "Нет"])
        DialogTabStore.setDialogTabIsOpen(true) 
    }

    const onUpItemClick = () => {
        docStore.moveUpCategory()
    }

    const onDownItemClick = () => {
        docStore.moveDownCategory()
    }

    return (
        <div id='category-menu' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
            <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Категории</p>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onAddItemClick}
                    disabled={docStore.isCategoryLinking || !userStore.checkPermission("category:edit")}
                    data-tooltip-id="category-tooltip"
                    data-tooltip-html="Создать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+A)"
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onEditItemClick}
                    disabled={!docStore.selectedCategory || docStore.isCategoryLinking || !userStore.checkPermission("category:edit")}
                    data-tooltip-id="category-tooltip"
                    data-tooltip-html="Редактировать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+E)"
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onDeleteItemClick}
                    disabled={!docStore.selectedCategory || docStore.isCategoryLinking || !userStore.checkPermission("category:edit")}
                    data-tooltip-id="category-tooltip"
                    data-tooltip-html="Удалить &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+R)"
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <TrashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onUpItemClick}
                    disabled={!docStore.selectedCategory || docStore.isCategoryLinking || docStore.selectedCategory.order === 1 || !userStore.checkPermission("category:edit")}
                    data-tooltip-id="category-tooltip"
                    data-tooltip-html="Переместить вверх &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+стрелка вверх)"
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <BarsArrowUpIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onDownItemClick}
                    disabled={!docStore.selectedCategory || docStore.isCategoryLinking || docStore.selectedCategory.order === docStore.categories.length || !userStore.checkPermission("category:edit")}
                    data-tooltip-id="category-tooltip"
                    data-tooltip-html="Переместить вниз &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+стрелка вниз)"
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <BarsArrowDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
            </div>
            <Tooltip id="category-tooltip" className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
            <DialogTab
                parentName='CategoryMenu'
                dialogTabFunction={deleteCategory}
            />
        </div>
    )
}

export default observer(CategoryMenu)