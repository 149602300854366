import React, { useContext } from 'react'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'
import PDataStageButtons from '../stage/PDataStageButtons'
import EmployeesDataForm from '../../common/forms/employees/EmployeesDataForm'


/**
 * Визуальный компонент отображает этап заполнения данных о сотрудниках
 * 
 */
const PDataEmployeesForm = () => {   
    const { personalDataStore } = useContext(Context)

    const handleBackClick = () => {
        personalDataStore.goPrevStage()
    }

    const handleForwardClick = () => {
        personalDataStore.goNextStage()  
    }

    return (
        <div className='tw-h-[calc(100%_-_3rem)] tw-flex tw-flex-col'>
            <EmployeesDataForm/>
            <PDataStageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleForwardClick}
                disabled={false}
            />
        </div>                                                             
    )
}

export default observer(PDataEmployeesForm)