import React, { useContext } from 'react'
import { DocumentPlusIcon, PencilSquareIcon, DocumentDuplicateIcon, EyeIcon, EyeSlashIcon, TrashIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'
import { menuTooltipTimeOut } from '../../../../../config/constTypes'
import { useHotkeys } from 'react-hotkeys-hook'


/**
 * Визуальный компонент отображает меню редактора полей
 * 
 * @param {Function} onHideFieldClick Обработчик клика мыши на кнопке скрытия поля
 * @param {Function} onRemoveFieldClick Обработчик клика мыши на кнопке удаления поля
 * 
 */
const FieldMenu = ({ onHideFieldClick, onRemoveFieldClick }) => {
    const { FilterStore, FieldStore, docStore } = useContext(Context)

    const isSelectedField = FieldStore.selectedField?.id !== '0'

    useHotkeys('ctrl+a', () => {onSetCreateMode()}, { preventDefault: true })
    useHotkeys('ctrl+d', () => {if (isSelectedField) onSetDuplicateMode()}, { preventDefault: true })
    useHotkeys('ctrl+e', () => {if (isSelectedField) onSetEditMode()}, { preventDefault: true })
    useHotkeys('ctrl+x', () => {if (isSelectedField) onHideFieldClick(FieldStore.selectedField)}, { preventDefault: true })
    useHotkeys('ctrl+r', () => {if (isSelectedField && FieldStore.selectedField.status === 'added') onRemoveFieldClick(FieldStore.selectedField.field_id)}, { preventDefault: true })

    const onSetCreateMode = () => {
        FieldStore.setSelectedField(FilterStore.dummyField)
        FieldStore.setEditionMode('add')
        FieldStore.setIsFormOpen(true)
    }

    const onSetDuplicateMode = () => {
        if (FieldStore.selectedField.id !== '0') {
            FieldStore.setEditionMode('duplicate')
            FieldStore.setIsFormOpen(true)
            if (FieldStore.selectedField.type === 'reference') {
                const dataModelName = docStore.dataModels.find(dataModel => FieldStore.selectedField.ref_model_ids.includes(dataModel.id))
                FieldStore.setSelectedField({...FieldStore.selectedField, ref_model_name: dataModelName ? dataModelName.entity_name : ''})                
            }
        }
    }

    const onSetEditMode = () => {
        if (FieldStore.selectedField.id !== '0') {
            FieldStore.setEditionMode('edit')
            FieldStore.setIsFormOpen(true)
        }
    }

    return (
        <>
            <div className='tw-flex tw-flex-row tw-justify-between tw-items-center tw-space-x-1'>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onSetCreateMode}
                    data-tooltip-id="field-editor-tooltip"
                    data-tooltip-html="Создать поле &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+A)"
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onSetDuplicateMode}
                    disabled={!FieldStore.selectedField || FieldStore.selectedField.id === '0'}
                    data-tooltip-id="field-editor-tooltip"
                    data-tooltip-html="Дублировать поле &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+D)"
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <DocumentDuplicateIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onSetEditMode}
                    disabled={!FieldStore.selectedField || FieldStore.selectedField.id === '0'}
                    data-tooltip-id="field-editor-tooltip"
                    data-tooltip-html="Редактировать поле &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+E)"
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onHideFieldClick(FieldStore.selectedField)}
                    disabled={!FieldStore.selectedField || FieldStore.selectedField.id === '0'}
                    data-tooltip-id="field-editor-tooltip"
                    data-tooltip-html={FieldStore.selectedField?.hide ? "Показать поле &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+X)" : "Скрыть поле &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+X)"}
                    data-tooltip-delay-show={menuTooltipTimeOut}
                    >
                        { FieldStore.selectedField?.hide 
                            ? <EyeIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            : <EyeSlashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        }
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onRemoveFieldClick(FieldStore.selectedField.field_id)}
                    disabled={!FieldStore.selectedField || FieldStore.selectedField.status !== 'added'}
                    data-tooltip-id="field-editor-tooltip"
                    data-tooltip-html="Удалить поле &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+R)"
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <TrashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
            </div>
            <Tooltip id="field-editor-tooltip" className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
        </>
    )
}

export default observer(FieldMenu)
