import React from 'react'
import { Controller } from 'react-hook-form'
import { emptyReferenceValue, setFieldDefaultValue, setFieldValue } from '../../config/constTypes'
import DataSwitch from '../main_page/controller/common/inputs/DataSwitch'
import DataEnumListBox from '../main_page/controller/common/inputs/DataEnumListBox'
import DataReferenceInput from '../main_page/controller/common/inputs/DataReferenceInput'
import { XMarkIcon, CalendarIcon } from '@heroicons/react/20/solid'
import ReactDatePicker, {registerLocale} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import ru from "date-fns/locale/ru"

registerLocale("ru", ru)

/**
 * Визуальный компонент выбора поля для ввода/редактирования значения в зависимости от типа поля записи
 * 
 * @param {Object} item Поле
 * @param {Boolean} isEditMode Признак режима редактирования
 * @param {Boolean} isDuplicateMode Признак режима дублирования
 * @param {Function} onReferenceSelect Обработчик выбора значения ссылочного поля
 * @param {Function} onReferenceClear Обработчик очистки значения ссылочного поля
 * @param {Function} onRecordChange Обработчик изменения значения поля
 * @param {Object} control Объект, реализующий регистрацию компонентов
 * @param {Function} register Метод, реализующий регистрацию поля UseForm
 * @param {Function} setValue Метод, реализующий сохранение значения поля UseForm
 * @param {Boolean} isError Признак ошибки
 * 
 */ 
const FormFieldInput = ({item, isEditMode, isDuplicateMode, onReferenceSelect, onReferenceClear, onRecordChange, control, register, setValue, isError}) => {
    return (
        <>
            { ['one', 'many'].includes(item.validator_type) &&
                <Controller
                    name={'data.' + item.tech_name + '.0'}
                    control={control}
                    rules={ {validate: value => (value?.value?.values && value?.value?.values?.length > 0) || !item.mandatory} }
                    defaultValue={ (isEditMode || isDuplicateMode) ? item  : {value : emptyReferenceValue} }
                    render={({field}) =>
                        <DataReferenceInput
                            value={field.value}
                            onChange={(e) => {field.onChange(e)}}
                            onSelectClick={() => onReferenceSelect(item.ref_model_ids.length > 1 ? item.ref_model_ids : item.ref_model_ids[0], 'data.' + item.tech_name + '.0', item)}
                            onClearClick={() => onReferenceClear('data.' + item.tech_name + '.0')}
                            isError={isError}
                            isNested={false}
                            isRowSelected={false}
                            isCellSelected={true}
                            readOnly={false}
                        />}
                />
            }
            { item.validator_type === 'bool' &&
                <Controller
                    name={'data.' + item.tech_name}
                    control={control}
                    defaultValue={(isEditMode || isDuplicateMode) ? setFieldValue(item) : setFieldDefaultValue(item)}
                    render={({field}) =>
                        <DataSwitch 
                            isChecked={field.value}
                            onClick={(e) => {field.onChange(e); onRecordChange(false)}}
                        />
                    }
                />
            }
            { item.validator_type === 'int' &&
                <input
                    type='number'
                    defaultValue={item.default !== '-' ? item.default : ''}
                    className={`tw-w-52 tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 
                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                        ${isError ? 'tw-ring-red-400 focus-visible:tw-ring-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-gray-400'}
                    `}
                    {...register('data.' + item.tech_name, { 
                        required: item.mandatory, 
                        value: (isEditMode || isDuplicateMode) ? setFieldValue(item) : item.default || 0,
                        valueAsNumber: true,
                        validate: v => !item.mandatory || Number.isInteger(v),
                        onChange: (e) => onRecordChange(false)
                    })} 
                />
            }
            { item.validator_type === 'float' &&
                <input
                    type='number'
                    defaultValue={item.default !== '' ? item.default : 0}
                    step={1 / (10 ** item.options.number_decimal_places)}
                    className={`tw-w-52 tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 
                            tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                            ${isError ? 'tw-ring-red-400 focus-visible:tw-ring-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-gray-400'}
                    `}
                    {...register('data.' + item.tech_name, { 
                        required: item.mandatory,
                        value: (isEditMode || isDuplicateMode) ? setFieldValue(item) : item.default || 0,
                        valueAsNumber: true,
                        validate: v => (v - Number(v?.toFixed(item.options.number_decimal_places)) === 0), // проверка соответствия количества знаков после запятой
                        onChange: (e) => onRecordChange(false)
                    })} 
                />
            }
            { item.validator_type === 'enum' &&
                <div className='tw-flex tw-flex-row tw-items-center tw-w-full'>
                    <div className='tw-grow'>
                        <Controller
                            name={'data.' + item.tech_name}
                            control={control}
                            rules={ {required: item.mandatory, setValueAs: v => v === '' ? undefined : v} }
                            defaultValue={(isEditMode || isDuplicateMode) ? setFieldValue(item) : setFieldDefaultValue(item)}
                            render={({field}) =>
                                <DataEnumListBox
                                    itemList={item.options.allowed_values}
                                    selectedItem={field.value}
                                    onItemChange={(e) => {field.onChange(e); onRecordChange(false)}}
                                    isError={isError}
                                    id={'data.' + item.tech_name}
                                />
                            }
                        />
                    </div>
                </div>
            }
            { item.validator_type === 'date' &&
                <div className='tw-flex tw-flex-row tw-justify-start tw-items-center tw-w-52 tw-py-1'>
                    <Controller
                        name={'data.' + item.tech_name}
                        control={control}
                        defaultValue={(isEditMode || isDuplicateMode) ? setFieldValue(item) : setFieldDefaultValue(item)}
                        rules={ {required: item.mandatory, valueAsDate: true} }
                        render={({field}) =>
                            <ReactDatePicker
                                className={`tw-w-52 tw-border tw-rounded-md tw-text-sm tw-text-gray-900 tw-p-1
                                    focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white 
                                    focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2
                                    ${isError ? 'tw-border-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-border-gray-400 focus-visible:tw-ring-offset-gray-400'}
                                `}
                                calendarStartDay={1}
                                calendarIcon={<CalendarIcon/>}
                                dateFormat={item.options.format.toLowerCase() !== 'dd.mm.yyyy' ? 'dd.MM.yyyy HH:mm:ss' : 'dd.MM.yyyy'}
                                timeFormat="HH:mm:ss"
                                locale='ru'
                                timeCaption='Время'
                                showTimeSelect={item.options.format.toLowerCase() !== 'dd.mm.yyyy'}
                                selected={field.value}
                                onChange={(e) => {field.onChange(new Date(e).getTime()); onRecordChange(false)}}
                            />
                        }
                    />
                    <button 
                        type='button'
                        className='tw-rounded-md tw-p-0.5 tw-ml-1 tw-border-0 tw-text-gray-600 tw-bg-white hover:tw-text-gray-900 hover:tw-border-gray-200 hover:tw-bg-gray-200'
                        onClick={() => {setValue('data.' + item.tech_name, null); onRecordChange(false)}}
                    >
                        <XMarkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    </button>
                </div>
            }
            { item.validator_type === 'string' &&
                <textarea
                    defaultValue={item.default !== '-' ? item.default : ''}
                    placeholder={item.placeholder}
                    className={`tw-w-full tw-h-8 tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900
                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm
                        focus-visible:tw-outline-none focus-visible:tw-border-gray-600 focus-visible:tw-ring-2 
                        focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2
                        ${isError
                            ?   'tw-ring-red-400 focus-visible:tw-ring-offset-red-400'
                            :   item.autogenerable
                                    ?   'tw-ring-gray-300 focus-visible:tw-ring-offset-gray-300'
                                    :   'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                    `}
                    readOnly={item.autogenerable}
                    {...register('data.' + item.tech_name, { 
                        required: item.mandatory,
                        value: (isEditMode || isDuplicateMode) ? setFieldValue(item) : item.default || '',
                        onChange: (e) => onRecordChange(false)
                    })} 
                />
            }
        </>
    )
}

export default FormFieldInput