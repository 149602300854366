import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../../..'
import { getFormattedDate } from '../../../../../../config/constTypes'


/**
 * Визуальный компонент отображает информацию о прогрессе по обучению
 * 
 */
const MaterialProgressFields = () => {
    const { EducationStore } = useContext(Context)
    const progressValue = EducationStore.calculateProgress(EducationStore.progressRecord)

    return (
        <>
            <div className='tw-my-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-py-2'>
                <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-justify-between">
                    Дата и время
                </dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                    <p>{getFormattedDate(EducationStore.progressRecord.data.date.value, 'dd.MM.yyyy HH:mm:ss')}</p>
                </dd>                                    
            </div>
            <div className='tw-my-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-py-2'>
                <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-justify-between">
                    Прогресс
                </dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                    <p>{progressValue ? progressValue + ' %' : '-'}</p>
                </dd>                                    
            </div>
        </>
    )       
}

export default observer(MaterialProgressFields)