import React, { useContext } from 'react'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает кнопку выбора файла
 * 
 * @param {Function} onFileSelect Обработчик клика мыши на кнопке выбора файла
 * 
 */
const FileSelectButton = ({onFileSelect}) => {
    const { userStore } = useContext(Context)
    return (
        <div className='tw-h-10 tw-flex tw-flex-row tw-justify-start tw-items-center tw-p-2 tw-mb-2'>
            <label 
                htmlFor='file-input'
                className={`tw-text-sm tw-font-semibold tw-border-2 tw-rounded-md tw-px-4 tw-py-1.5 tw-select-none 
                            tw-bg-white
                            ${userStore.checkPermission("file:upload")
                                ?   'tw-text-gray-700 tw-border-gray-700 hover:tw-bg-gray-100'
                                :   'tw-text-gray-300 tw-border-gray-300'}
                        `}
            >
                Выбрать файл...
            </label>
            <input
                id='file-input'
                type='file'
                disabled={!userStore.checkPermission("file:upload")}
                onChange={onFileSelect}
                hidden
            />
        </div>
    )
}

export default observer(FileSelectButton)