import React, { useContext } from 'react'
import { Context } from '../../../../../../..'
import { PencilSquareIcon, TrashIcon, ClockIcon, CheckBadgeIcon, DocumentPlusIcon, FunnelIcon, WalletIcon, LinkIcon, ArrowPathIcon } from '@heroicons/react/20/solid'
import { menuTooltipTimeOut } from '../../../../../../../config/constTypes'
import { observer } from 'mobx-react-lite'
import { checkMethodPermissions } from '../../../../../../../functions/checkPermissions'
import { paths } from '../../../../../../../config/constsURN'
import { useNavigate } from 'react-router-dom'

/**
 * Визуальный компонент отображает меню для запланированных действий
 * 
 * @param {Function} onEditClick Обработчик клика мыши на кнопке редактирования действия
 * @param {Function} onDeleteClick Обработчик клика мыши на кнопке удаления действия
 * @param {Function} onHistoryClick Обработчик клика мыши на кнопке просмотра истории изменений действия
 * @param {Function} onGroupClick Обработчик клика мыши на кнопке просмотра групп действия
 * @param {Function} onRefreshClick Обработчик клика мыши на кнопке обновления списка действий
 * @param {Function} onFilterClick Обработчик клика мыши на кнопке выбора фильтров для запланированных действий
 * @param {Function} onMarkClick Обработчик клика мыши на кнопке отметки о завершении действия
 * @param {Function} onCopyLinkClick Обработчик клика мыши на кнопке копирования ссылки в буфер обмена
 * 
 */
const PlannedActionMenu = ({onEditClick, onDeleteClick, onHistoryClick, onGroupClick, onRefreshClick, onFilterClick, onMarkClick, onCopyLinkClick}) => {
    const { plannerStore } = useContext(Context)
    const navigate = useNavigate()
    const [isWrite, isWriteDescription] = checkMethodPermissions(plannerStore.selectedAction, 'write')

    const onAddClick = () => {
        plannerStore.setCalendarDate(null)
        plannerStore.setEditMode('new')
        plannerStore.setSelectedAction(null)
        plannerStore.setIsActionFormOpen(true)
        navigate(paths.PLANNER_ROUTE)
    }

    return (
        <div className='tw-flex tw-flex-row tw-gap-x-1'>
            <button
                className={`tw-rounded-md tw-p-1.5 tw-text-white disabled:tw-bg-gray-300 ${plannerStore.plannedFilters.isGrouped ? 'tw-bg-red-600 hover:tw-bg-red-400' : 'tw-bg-gray-800 hover:tw-bg-gray-600'}`}
                onClick={onGroupClick}
                data-tooltip-id="planner-table-tooltip"
                data-tooltip-html={plannerStore.plannedFilters.isGrouped ? "Разгруппировать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+G)" : "Сгруппировать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+G)"}
                data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <WalletIcon className='tw-h-5 tw-w-5' aria-hidden="true"/>
            </button>            
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-300'
                 onClick={onRefreshClick}
                data-tooltip-id="planner-table-tooltip"
                data-tooltip-html="Обновить список действий &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+O)"
                data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <ArrowPathIcon className='tw-h-5 tw-w-5' aria-hidden="true"/>
            </button>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-300'
                onClick={onFilterClick}
                data-tooltip-id="planner-table-tooltip"
                data-tooltip-html="Настроить фильтры &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+Y)"
                data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <FunnelIcon className='tw-h-5 tw-w-5' aria-hidden="true"/>
            </button>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-300'
                onClick={onAddClick}
                data-tooltip-id="planner-table-tooltip"
                data-tooltip-html="Создать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+A)"
                data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-300'
                onClick={onMarkClick}
                disabled={!plannerStore.selectedAction || !plannerStore.selectedAction.data['action_is_done'] 
                            || plannerStore.selectedAction.data['action_is_done'].value || !isWrite}
                data-tooltip-id="planner-table-tooltip"
                data-tooltip-html={isWriteDescription ? isWriteDescription : "Отметить как выполненное &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+M)"} 
                data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <CheckBadgeIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-300'
                onClick={onEditClick}
                disabled={!plannerStore.selectedAction || !isWrite}
                data-tooltip-id="planner-table-tooltip"
                data-tooltip-html={isWriteDescription ? isWriteDescription : "Редактировать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+E)"} 
                data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-300'
                onClick={onDeleteClick}
                disabled={!plannerStore.selectedAction || !isWrite}
                data-tooltip-id="planner-table-tooltip" data-tooltip-delay-show={menuTooltipTimeOut}
                data-tooltip-content={isWriteDescription ? isWriteDescription 
                    : (plannerStore.selectedAction && plannerStore.selectedAction.system_data?.deletion_mark) 
                        ? "Отменить удаление &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+R)"
                        : "Удалить &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+R)"}
            >
                <TrashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-300'
                onClick={onHistoryClick}
                disabled={!plannerStore.selectedAction}
                data-tooltip-id="planner-table-tooltip"
                data-tooltip-html="Просмотреть историю &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+H)"
                data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <ClockIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-300'
                onClick={onCopyLinkClick}
                disabled={!plannerStore.selectedAction}
                data-tooltip-id="planner-table-tooltip"
                data-tooltip-html="Копировать ссылку в буфер обмена &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+L)"
                data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <LinkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
        </div>
    )
}

export default observer(PlannedActionMenu)