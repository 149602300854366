import React, { useContext } from 'react'
import { contentTooltipTimeOut } from '../../../../config/constTypes'
import { Context } from '../../../..'
import { observer } from 'mobx-react-lite'
import DataSwitch from '../../../main_page/controller/common/inputs/DataSwitch'

/**
 * Визуальный компонент отображает название одной таблицы для списка существующих таблиц
 * 
 * @param {Object} item Текущий элемент списка таблиц
 * 
 */
const DataModelPermissionsItem = ({item}) => {
    const { RolesStore } = useContext(Context)

    return (
            <div
                className={`tw-grid tw-grid-cols-6 tw-py-2 tw-text-sm tw-border-b`}
            >
                <div className={`tw-col-span-3 tw-flex tw-flex-row tw-gap-x-2`}>
                    <div className='tw-justify-self-end tw-self-start tw-px-2 tw-flex tw-flex-row tw-gap-x-1'>
                        <span 
                            className='tw-px-2 tw-py-0.5 tw-w-6 tw-h-6 tw-rounded-md tw-text-gray-900 tw-bg-gray-300 tw-font-bold'
                            data-tooltip-id="datamodel-list-tooltip" data-tooltip-content={item.type === 'directory' ? 'Справочник' : item.type === 'nested' ? 'Вложенная' : 'Документ'} data-tooltip-delay-show={contentTooltipTimeOut}
                        >
                            {item.type === 'document'
                                ?   'Д'
                                :   item.type === 'nested'
                                    ?   'В'
                                    :   'С'
                            }
                        </span>
                        {' '}
                        <span
                            className='tw-px-2 tw-py-0.5 tw-w-6 tw-h-6 tw-rounded-md tw-text-gray-900 tw-bg-gray-300 tw-font-bold'
                            data-tooltip-id="datamodel-list-tooltip" data-tooltip-content={item.built_in ? 'Из конфигурации системы' : 'Пользовательский'} data-tooltip-delay-show={contentTooltipTimeOut}
                        >
                            {item.built_in ? 'К' : 'П'}
                        </span>
                    </div>
                    <div className={`${item.hide_mark ? 'tw-line-through tw-text-red-400' : ''}`}>
                        {item.entity_name}
                    </div>
                </div>
                <div className='tw-col-span-1 tw-justify-self-end tw-min-w-32'>
                    { !RolesStore.isPermissionsEditing
                        ?   RolesStore.selectedRole &&
                            <div className='tw-text-center'>{RolesStore.checkDataModelRights(item, 'read') ? 'Да' : 'Нет'}</div>
                        :                    
                            <DataSwitch
                                isChecked={RolesStore.checkDataModelRights(item, 'read')}
                                onClick={() => RolesStore.setDataModelRights(item.id, 'read')}
                            />
                    }
                </div>
                <div className='tw-col-span-1 tw-justify-self-end tw-min-w-32'>
                    { !RolesStore.isPermissionsEditing
                        ?   RolesStore.selectedRole &&
                            <div className='tw-text-center'>{RolesStore.checkDataModelRights(item, 'write') ? 'Да' : 'Нет'}</div>
                        :                    
                            <DataSwitch
                                isChecked={RolesStore.checkDataModelRights(item, 'write')}
                                onClick={() => RolesStore.setDataModelRights(item.id, 'write')}
                            />
                    }
                </div>
                <div className='tw-col-span-1 tw-justify-self-end tw-min-w-32'>
                    { !RolesStore.isPermissionsEditing
                        ?   RolesStore.selectedRole &&
                            <div className='tw-text-center'>{RolesStore.checkDataModelRights(item, 'edit') ? 'Да' : 'Нет'}</div>
                        :                    
                            <DataSwitch
                                isChecked={RolesStore.checkDataModelRights(item, 'edit')}
                                onClick={() => RolesStore.setDataModelRights(item.id, 'edit')}
                            />
                    }
                </div>

            </div>
    )
}

export default observer(DataModelPermissionsItem)