import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../..'
import ImporterList from './list/ImporterList'
import PageTitle from '../common/panels/PageTitle'
import DocumentService from '../../../../services/DocumentService'
import ImporterService from '../../../../services/ImporterService'
import Spinner from '../../../../assets/Spinner'
import ImporterMenu from './menu/ImporterMenu'
import ImporterInfo from './info/ImporterInfo'
import ImporterForm from './form/ImporterForm'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react-lite'
import { responseTimeOut, serviceMessageTimeOut } from '../../../../config/constTypes'
import { showErrorToast } from '../../../../functions/errorHandlers'
import { Tooltip } from 'react-tooltip'
import DialogTab from '../../../dialog_tab/DialogTab'
import { useLocation, useNavigate } from 'react-router-dom'
import { paths } from '../../../../config/constsURN'
import { scrollElementIntoView } from '../../../../functions/scrollElementIntoView'
import { getIdFromPath } from '../../../../functions/getIdFromPath'

/**
 * Компонент реализует логику работы с шаблонами импорта
 * 
 */
const ImporterListContainer = () => {
    const { docStore, FilterStore, DialogTabStore, userStore } = useContext(Context)

    const location = useLocation()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)
    const [importers, setImporters] = useState(null)
    const [selectedImporter, setSelectedImporter] = useState(null)
    const [isCreating, setIsCreating] = useState(false)  
    const [isEditing, setIsEditing] = useState(false)  
    const [importerDataModel, setImporterDataModel] = useState(null)
    const [dataModelsList, setDataModelsList] = useState([])

    const handleItemClick = (importer) => {
        if (selectedImporter?.id !== importer.id)
            navigate(paths.IMPORTER_ROUTE + '/' + importer.id)
    }

    const handleImporterSelect = (importer) => {
        if (!isCreating && !isEditing && importer) {
            // получение полной информации о структуре таблицы для выбранного шаблона импорта
            const foundDataModel = dataModelsList.find(dataModel => dataModel.id === importer.meta.data_model_id)
            let importerFields
            if (foundDataModel) {
                setImporterDataModel(foundDataModel)
                // дополнение правил подстановки в шаблоне импорта информацией о полном имени поля и псевдониме для удобного представления пользователю
                importerFields = importer.fields.map(field => {
                    const dataModelField = foundDataModel.fields.find(item => item.tech_name === field.tech_name)
                    if (!dataModelField)
                        toast.warning('Поле "' + field.tech_name + '" в таблице отсутствует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                    return {...field,
                        alias: dataModelField ? dataModelField.alias : field.tech_name,
                        full_name: dataModelField ? dataModelField.full_name : field.tech_name,
                        is_incorrect: dataModelField === undefined
                    }
                })

            } else {
                toast.warning('Указанная в шаблоне таблица не найдена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                setImporterDataModel({id: importer.meta.data_model_id, entity_name: importer.meta.data_model_id, fields: [], is_incorrect: true})
                // дополнение правил подстановки в шаблоне импорта информацией о полном имени поля и псевдониме для удобного представления пользователю
                importerFields = importer.fields.map(field => {
                    return {...field,
                        alias: field.tech_name,
                        full_name: field.tech_name
                    }
                })
            }
            setSelectedImporter({...importer, fields: importerFields})

            scrollElementIntoView(importer, '')
        }
    }

    const getAllImporters = () => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис импорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setIsLoading(false)
            setImporters([])
        }, responseTimeOut)

        setIsLoading(true)
        ImporterService
            .getImporters(50)
            .then(data => {
                clearTimeout(noResponse)
                setImporters(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                setImporters([])
            })
            .finally(setIsLoading(false))   
    }

    const formatImporter = (form, isEditMode) => {
        const dataObject = {}
        dataObject.name = form.templateName
        dataObject.meta = {}
        dataObject.meta.data_model_id = form.dataModelID
        if (form.fields.length)
            dataObject.fields = form.fields
                                .filter(field => field.columns.length)
                                .map(field => { 
                                    if (field.ref_tech_name)
                                        return {
                                            tech_name: field.tech_name,
                                            columns: field.columns.map(column => {return {index: Number(column)}}),
                                            ref_tech_name: field.ref_tech_name
                                        }
                                    else 
                                        return {
                                            tech_name: field.tech_name,
                                            columns: field.columns.map(column => {return {index: Number(column)}}),
                                        }
                                })
        if (isEditMode)
            dataObject.id = form.importerID

        return dataObject
    }

    const handleAddImporterClick = (form) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис импорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        ImporterService
            .createImporter(formatImporter(form, isEditing))
            .then(() => {
                clearTimeout(noResponse)
                getAllImporters()
                setIsCreating(false)
                setIsEditing(false)
                toast.success('Шаблон импорта успешно сохранен', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    const handleEditImporterClick = (form) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис импорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        ImporterService
            .updateImporter(formatImporter(form, isEditing))
            .then(() => {
                clearTimeout(noResponse)
                getAllImporters()
                setIsCreating(false)
                setIsEditing(false)
                toast.success('Шаблон импорта успешно сохранен', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    const handleCreateModeClick = () => {
        setIsCreating(true)
    }

    const handleEditModeClick = (item) => {
        handleImporterSelect(item)
        setIsEditing(true)
    }

    const handleCancelClick = () => {
        setIsCreating(false)
        setIsEditing(false)
    }

    const handleDeleteImporterClick = () => {
        DialogTabStore.setDialogTabIsOpen(false)

        const noResponse = setTimeout(() => {
            toast.error('Сервис импорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        ImporterService
            .deleteImporter(selectedImporter.id)
            .then(() => {
                clearTimeout(noResponse)
                getAllImporters()
                setSelectedImporter(null)
                setIsCreating(false)
                setIsEditing(false)
                toast.success('Шаблон импорта успешно удален', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                navigate(paths.IMPORTER_ROUTE)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'deleting', '')
            })
    }

    const getDataModels = (setDataModelsList) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setIsLoading(false)
            setImporters([])
        }, responseTimeOut)

        setIsLoading(true)

        const filters = JSON.stringify(FilterStore.selectedFilters)
        const sorter = JSON.stringify([ {property: 'entity_name', desc: false} ])

        DocumentService
            .getDataModels(filters, sorter)
            .then(data => {
                clearTimeout(noResponse)
                if (setDataModelsList)
                    setDataModelsList(data)
                docStore.setDataModels(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
            .finally(setIsLoading(false))
    }

    useEffect(() => {
        if (userStore.checkPermission("import-template:get"))
            getAllImporters()
        else
            setImporters([])
    }, [userStore.userPermissions])

    useEffect(() => {
        if (FilterStore.selectedFilters.length)
            getDataModels()
        else
            getDataModels(setDataModelsList)
    }, [FilterStore.selectedFilters])

    useEffect(() => {
        if (importers && importers.length && dataModelsList.length) {
            const importerID = getIdFromPath(location.pathname, 2)
            if (importerID) {
                const foundImporter = importers.find(importer => importer.id === importerID)
                handleImporterSelect(foundImporter)
            } else {
                setSelectedImporter(null)
            }
        }
    }, [location.pathname, importers, dataModelsList])

    if (isLoading || !importers) {
        return  <Spinner/>
    }

    return (
        <>
            <PageTitle title={'Шаблоны импорта'} />
            <div id='importer-page' className='tw-grid tw-grid-cols-3 tw-gap-x-8 tw-max-h-full tw-h-full'>
                <div id='importer-list' className='tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <ImporterMenu
                        activeItem={selectedImporter}
                        onAddItemClick={handleCreateModeClick}
                        onEditItemClick={handleEditModeClick}
                        isDisabled={isCreating || isEditing}
                    />
                    <ImporterList
                        list={importers}
                        activeItem={selectedImporter}
                        onItemClick={handleItemClick}
                        dataModelsList={dataModelsList}
                        isDisabled={isCreating || isEditing}
                        onDoubleClick={handleEditModeClick}
                    />
                </div>
                <div id='importer-column' className='tw-col-span-2 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden tw-flex tw-flex-col'>
                    <div id='importer-header' className='tw-h-12 tw-flex tw-items-center tw-justify-between tw-border-b-2 tw-border-gray-400 tw-px-4 tw-py-2'>
                        <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Информация о шаблоне</p>
                    </div>
                    { selectedImporter && !isCreating && !isEditing &&
                        <ImporterInfo
                            activeItem={selectedImporter}
                            dataModel={importerDataModel}
                        />
                    }
                    { (isCreating || isEditing) &&
                        <ImporterForm 
                            editMode={isEditing}
                            importer={selectedImporter}
                            dataModel={importerDataModel}
                            onSubmitClick={isEditing ? handleEditImporterClick: handleAddImporterClick}
                            onCancelClick={handleCancelClick}
                        />
                    }
                </div>
            </div>
            <Tooltip id="importer-editor-tooltip" place="top"/>
            <DialogTab
                parentName='ImporterListContainer'
                dialogTabFunction={handleDeleteImporterClick}
            />
        </>
    )
}

export default observer(ImporterListContainer)