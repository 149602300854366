import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import DataModelItem from './DataModelItem'


/**
 * Визуальный компонент отображает список существующих типов таблиц
 * 
 * @param {Object[]} list Список таблиц определенной категории и типа
 * @param {Object} type Тип таблиц
 * @param {Object} selectedDataModel Выбранная таблица
 * @param {Function} onItemClick Обработчик клика мыши на таблице
 * @param {Function} onItemDoubleClick Обработчик двойного клика мыши на таблице
 * 
 */
const DataModelType = ({list, type, selectedDataModel, onItemClick, onItemDoubleClick}) => {
    const typeDataModels = list.filter(dataModel => dataModel.type === type)

    return (
        <div className="tw-w-full tw-pt-0.5 tw-pr-0.5">
            <Disclosure defaultOpen={selectedDataModel && typeDataModels.some(dataModel => dataModel.id === selectedDataModel.id)}>
            {({ open }) => (
                <>
                <Disclosure.Button className="tw-flex tw-w-full tw-justify-between tw-bg-gray-100 tw-px-4 tw-py-1 tw-border tw-rounded-md
                        tw-text-left tw-text-sm tw-font-medium tw-text-gray-900 hover:tw-bg-gray-200 tw-border-gray-300 
                        focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-75">
                    <span>
                        {type === 'document' 
                            ?   'Документы' 
                            :   type === 'nested' 
                                ? 'Включенные' 
                                : 'Справочники'}
                    </span>
                    <ChevronUpIcon
                        className={`${open ? 'tw-rotate-180 tw-transform' : ''} tw-h-5 tw-w-5 tw-text-gray-500`}
                    />
                </Disclosure.Button>
                <Disclosure.Panel className="tw-pl-4 tw-pt-1 tw-pb-2">
                    { typeDataModels.map((dataModel, index) =>
                        <DataModelItem 
                            key={index}
                            item={dataModel}
                            onClick={onItemClick}
                            onDoubleClick={onItemDoubleClick}
                            selectedDataModel={selectedDataModel}
                        />
                    )}
                </Disclosure.Panel>
                </>
            )}
            </Disclosure>
        </div>
    )
}

export default DataModelType