import React, { useEffect, useState }from 'react'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon, EyeIcon, EyeSlashIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { paths } from '../../../config/constsURN'
import { toast } from 'react-toastify'
import { generatePassword } from '../../../functions/passwordGenerator'
import { Tooltip } from 'react-tooltip'


/**
 * Визуальный компонент отображает форму для самостоятельной регистрации пользователя в системе
 * 
 * @param {Boolean} isPasswordError Признак ошибки при вводе пароля 
 * @param {Boolean} isPhoneError Признак ошибки при вводе номера телефона 
 * @param {Boolean} isUsernameError Признак ошибки при вводе логина
 * @param {Function} onSubmitClick Обработчик клика мыши на кнопке регистрации 
 * 
 */
const RegistrationForm = ({isPasswordError, isPhoneError, isUsernameError, onSubmitClick}) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false)
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors, isSubmitting, isValid },
    } = useForm()

    const watchPersonalData = watch('personal_data', false)

    const onGeneratePasswordClick = (e) => {
        e.preventDefault()
        setValue('password', generatePassword())
    }

    useEffect(() => {
        const userRegistrationStorage = JSON.parse(sessionStorage.getItem('userRegistration'))
        if (userRegistrationStorage) {
            Object.entries(userRegistrationStorage).forEach(([key, value]) => { setValue(key, value) })
        }
    }, [])

    isSubmitting && !isValid && toast.error('Заполните обязательные поля', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })

    return (
        <div id='registration' className='tw-flex tw-min-h-full tw-items-center tw-justify-center tw-px-4 sm:tw-px-6 lg:tw-px-8'>
            <div className='tw-w-full tw-border tw-border-gray-200 tw-rounded-lg tw-max-w-3xl tw-space-y-8 tw-bg-white'>
                <div className='tw-mx-12 tw-my-4'>
                    <h2 className='tw-mt-4 tw-text-center tw-text-2xl tw-font-bold tw-tracking-tight tw-text-gray-700'>Регистрация</h2>
                    <form onSubmit={handleSubmit(onSubmitClick)}>
                        <div className='tw-grid tw-grid-cols-1 tw-gap-x-4 md:tw-grid-cols-2'>
                            <div>
                                <div className='tw-mt-4 tw-flex tw-flex-row tw-items-center tw-gap-x-4'>
                                    <label htmlFor='last_name' className="tw-block tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900">
                                        Фамилия
                                    </label>
                                </div>

                                <input
                                    type='text'
                                    id='last_name'
                                    className={`tw-relative tw-block tw-w-full tw-rounded-md tw-border-0 tw-mt-2 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                                tw-ring-1 tw-ring-inset focus:tw-z-10 focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm 
                                                sm:tw-leading-6 focus-visible:tw-outline-none focus-visible:tw-ring-2 tw-ring-gray-400
                                            `}
                                    {...register('last_name')}
                                />
                            </div>

                            <div>
                                <div className='tw-mt-4 tw-flex tw-flex-row tw-items-center tw-gap-x-4'>
                                    <label htmlFor='first_name' className="tw-block tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900">
                                        Имя
                                    </label>
                                </div>

                                <input
                                    type='text'
                                    id='first_name'
                                    className={`tw-relative tw-block tw-w-full tw-rounded-md tw-border-0 tw-mt-2 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                                tw-ring-1 tw-ring-inset focus:tw-z-10 focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm 
                                                sm:tw-leading-6 focus-visible:tw-outline-none focus-visible:tw-ring-2 tw-ring-gray-400
                                            `}
                                    {...register('first_name')}
                                />
                            </div>
                        </div>

                        <div className='tw-grid tw-grid-cols-1 tw-gap-x-4 md:tw-grid-cols-2'>
                            <div>
                                <div className='tw-mt-4 tw-flex tw-flex-row tw-items-center tw-gap-x-4'>
                                    <label htmlFor='phone_number' className="tw-block tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900">
                                        Телефон
                                        <span className='tw-text-red-400'> *</span>
                                    </label>
                                    
                                    <span>
                                        { (errors.phone_number || isPhoneError) && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> }
                                    </span>
                                </div>

                                <input
                                    type='text'
                                    id='phone_number'
                                    className={`tw-relative tw-block tw-w-full tw-rounded-md tw-border-0 tw-mt-2 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                                tw-ring-1 tw-ring-inset focus:tw-z-10 focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm 
                                                sm:tw-leading-6 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                                ${(errors.phone_number || isPhoneError) ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                            `}
                                    {...register('phone_number', { required: true })}
                                />
                            </div>

                            <div>
                                <div className='tw-mt-4 tw-flex tw-flex-row tw-items-center tw-gap-x-4'>
                                    <label htmlFor='name' className="tw-block tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900">
                                        Полное наименование организации
                                        <span className='tw-text-red-400'> *</span>
                                    </label>
                                    
                                    <span>
                                        { errors.name && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> }
                                    </span>
                                </div>

                                <input
                                    type='text'
                                    id='name'
                                    className={`tw-relative tw-block tw-w-full tw-rounded-md tw-border-0 tw-mt-2 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                                tw-ring-1 tw-ring-inset focus:tw-z-10 focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm 
                                                sm:tw-leading-6 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                                ${errors.name ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                            `}
                                    {...register('name', { required: true, validate: value => value.trim() !== '' })}
                                />
                            </div>
                        </div>

                        <div className='tw-grid tw-grid-cols-1 tw-gap-x-4 md:tw-grid-cols-2'>
                            <div>
                                <div className='tw-mt-4 tw-flex tw-flex-row tw-items-center tw-gap-x-4'>
                                    <label htmlFor='username' className="tw-block tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900">
                                        Логин
                                        <span className='tw-text-red-400'> *</span>
                                    </label>
                                    <span>
                                        { (errors.username || isUsernameError) && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> }
                                    </span>
                                </div>
                                
                                <input 
                                    type='text'
                                    id='username'
                                    className={`tw-relative tw-block tw-w-full tw-rounded-md tw-border-0 tw-mt-2 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                                tw-ring-1 tw-ring-inset focus:tw-z-10 focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm 
                                                sm:tw-leading-6 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                                ${(errors.username || isUsernameError) ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                            `}
                                    {...register('username', { required: true, validate: value => value.trim() !== ''})}
                                />
                            </div>
                            
                            <div>
                                <div className='tw-mt-4 tw-flex tw-flex-row tw-items-center tw-gap-x-2'>
                                    <label htmlFor='email' className="tw-block tw-text-sm tw-font-medium tw-pl-2 tw-text-gray-900">
                                        Email
                                        <span className='tw-text-red-400'> *</span>   
                                    </label>
                                    <span
                                        data-tooltip-id="registration-tooltip"
                                        data-tooltip-content="На указанный email будет отправлено письмо со ссылкой для активации учетной записи"
                                        data-tooltip-delay-show={500}
                                    >
                                        <InformationCircleIcon className='tw-h-5 tw-w-5 tw-text-gray-500 tw-cursor-help' aria-hidden="true"/>
                                    </span>
                                    <span>
                                        { errors.email && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> }
                                    </span>
                                </div>

                                <input
                                    type='email'
                                    id='email'
                                    autoComplete='username'
                                    className={`tw-relative tw-block tw-w-full tw-rounded-md tw-border-0 tw-mt-2 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                                tw-ring-1 tw-ring-inset focus:tw-z-10 focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm 
                                                sm:tw-leading-6 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                                ${errors.email ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                            `}
                                    {...register('email', { required: true, pattern: /.+@.+\..+/i })}
                                />
                            </div>
                        </div>

                        <div className='tw-grid tw-grid-cols-1 tw-gap-x-4 md:tw-grid-cols-2'>
                            <div>
                                <div className='tw-mt-4 tw-flex tw-flex-row tw-items-center tw-justify-between'>
                                    <div className='tw-flex tw-flex-row tw-items-center'>
                                        <label htmlFor="password"  className="tw-block tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900">
                                            Пароль
                                            <span className='tw-text-red-400'> *</span>
                                        </label>
                                        <span>
                                            { (errors.password || isPasswordError) && 
                                                <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> 
                                            }
                                        </span>
                                    </div>
                                    
                                    <button 
                                        className='tw-text-sky-700 tw-font-semibold  tw-text-xs
                                                    hover:tw-text-sky-500 focus-visible:tw-outline-none focus-visible:tw-text-sky-500'
                                        onClick={onGeneratePasswordClick}
                                    >
                                        Сгенерировать
                                    </button>
                                </div>
                                
                                <div className='tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm'>
                                    <input
                                        type={isPasswordVisible ? 'text' : 'password'}
                                        id = "password"
                                        autoComplete='password'
                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-2 tw-pr-8 tw-text-gray-900 
                                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                                focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                                ${(errors.password || isPasswordError) ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                            `}
                                        {...register('password', { required: true })}
                                    />
                                    <div 
                                        className='tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 hover:tw-cursor-pointer'
                                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                    >
                                        { isPasswordVisible 
                                            ?   <EyeSlashIcon className="tw-h-5 tw-w-5 tw-text-gray-400"/>
                                            :   <EyeIcon className="tw-h-5 tw-w-5 tw-text-gray-400"/>
                                        }
                                    </div>
                                </div>
                            
                            </div>
                            
                            <div>
                                <div className='tw-mt-4 tw-flex tw-flex-row tw-items-center tw-gap-x-2'>
                                    <label htmlFor="password_repeat"  className="tw-block tw-text-sm tw-font-medium tw-pl-2 tw-text-gray-900">
                                        Повторите пароль
                                        <span className='tw-text-red-400'> *</span>
                                    </label>
                                    <span
                                        data-tooltip-id="registration-tooltip"
                                        data-tooltip-content="Обязательно запомните или запишите пароль во избежание потери доступа к учетной записи"
                                        data-tooltip-delay-show={500}
                                    >
                                        <InformationCircleIcon className='tw-h-5 tw-w-5 tw-text-gray-500 tw-cursor-help' aria-hidden="true"/>
                                    </span>
                                    <span>
                                        { (errors.password_repeat || isPasswordError) && 
                                            <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> 
                                        }
                                    </span>
                                </div>

                                <div className='tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm'>
                                    <input
                                        type={isRepeatPasswordVisible ? 'text' : 'password'}
                                        id='password_repeat'
                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-2 tw-pr-8 tw-text-gray-900 
                                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                                focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                                ${(errors.password_repeat || isPasswordError) ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                            `}
                                        {...register('password_repeat', { required: true })}
                                    />
                                    <div 
                                        className='tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 hover:tw-cursor-pointer'
                                        onClick={() => setIsRepeatPasswordVisible(!isRepeatPasswordVisible)}
                                    >
                                        { isRepeatPasswordVisible
                                            ?   <EyeSlashIcon className="tw-h-5 tw-w-5 tw-text-gray-400"/>
                                            :   <EyeIcon className="tw-h-5 tw-w-5 tw-text-gray-400"/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='tw-mt-6 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                            <input
                                type='checkbox'
                                id = "personal_data"
                                className={`tw-block tw-rounded-sm tw-border
                                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                        focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                        tw-ring-gray-400
                                    `}
                                {...register('personal_data', { value: false })}
                            />
                            <label className='tw-text-xs tw-text-gray-500'>
                                Даю
                                <a className='tw-font-semibold tw-text-sky-700 hover:tw-text-sky-500 
                                                focus-visible:tw-outline-none focus-visible:tw-text-sky-500' 
                                    href={paths.AGREEMENT_ROUTE} 
                                    target='_blank' rel='noreferrer'
                                > согласие </a>
                                на обработку моих персональных данных
                            </label>
                        </div>
                        <div className='tw-mt-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                            <input
                                type='checkbox'
                                id = "subscription"
                                className={`tw-block tw-rounded-sm tw-border
                                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                        focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                        tw-ring-gray-400
                                    `}
                                {...register('subscription', { value: false })}
                            />
                            <label className='tw-text-xs tw-text-gray-500'>Даю согласие на получение информационной рассылки об обновлениях платформы MEDOED</label>
                        </div>
                        
                        <button 
                            type='submit'
                            className='tw-group tw-relative tw-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-gray-700 tw-mx-auto 
                                        tw-mt-4 tw-mb-6 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white hover:tw-bg-gray-500 
                                        focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 
                                        focus-visible:tw-outline-gray-600 disabled:tw-bg-gray-300 disabled:tw-border-gray-300'
                            disabled={!watchPersonalData}
                        >
                            Зарегистрироваться
                        </button>

                        <p className='tw-text-xs tw-font-medium tw-text-gray-500 tw-px-2 tw-text-center'>
                            Нажимая «Зарегистрироваться», вы принимаете условия
                            <a className='tw-font-semibold tw-text-sky-700 hover:tw-text-sky-500 
                                            focus-visible:tw-outline-none focus-visible:tw-text-sky-500'
                                href={paths.LICENSE_ROUTE}
                                target='_blank' rel='noreferrer'
                            > лицензионного соглашения</a>
                        </p>

                        <p className='tw-text-sm tw-font-medium tw-text-gray-500 tw-px-2 tw-mt-2 tw-text-center'>
                            Уже есть учетная запись?
                            <a className='tw-font-semibold tw-text-sky-700 hover:tw-text-sky-500 
                                            focus-visible:tw-outline-none focus-visible:tw-text-sky-500' 
                                href={paths.LOGIN_ROUTE} 
                            > Авторизоваться</a>
                        </p>
                    </form>
                </div>
            </div>
            <Tooltip id="registration-tooltip" place="top" className='tw-max-w-xl'/>
        </div>
    )
}

export default RegistrationForm
