import React, { useEffect, useState, Fragment, useContext } from 'react'
import { Context } from '../../../../../../..'
import { Dialog, Transition } from '@headlessui/react'
import { observer } from 'mobx-react-lite'
import { XMarkIcon } from '@heroicons/react/24/outline'
import PageTitle from '../../../../common/panels/PageTitle'
import DirectoryFieldsList from '../../../../common/panels/directory/DirectoryFieldsList'
import Spinner from '../../../../../../../assets/Spinner'


/**
 * Компонент реализует логику отображения историю внесения изменений в запланированное действие
 * 
 * @param {Boolean} isOpen Признак видимости (отображения) модального окна
 * @param {Function} setIsActionHistoryOpen Обработчик клика мыши для закрытия окна
 * 
 */
const ActionHistoryContainer = ({isOpen, setIsActionHistoryOpen}) => {
    const { plannerStore, docStore } = useContext(Context)

    const [isLoading, setIsLoading] = useState(true)
    const [dataObjects, setDataObjects] = useState([])

    const onCloseClick = () => {
        setIsActionHistoryOpen(false)
    }
  
    useEffect(() => {
        if (isOpen) {
            docStore.getOneDataModel('planned_actions', 
                                    dataModel => { plannerStore.setDataModel(dataModel); setIsLoading(false) })
            
            const historyFilter = JSON.stringify([
                {property: 'record_id', value: plannerStore.selectedAction.record_id, operator: 'eq'},
            ])
            const defaultSorter = []
            docStore.getDataObjectHistory(historyFilter, defaultSorter, true, setDataObjects)
        
        } else {
            plannerStore.setDataModel(null)
            setDataObjects([])
            setIsLoading(true)
        }
    }, [isOpen])
   
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={onCloseClick}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-500"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-500"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                    <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                        <div className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex  tw-pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                enterFrom="tw-translate-x-full"
                                enterTo="tw-translate-x-0"
                                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                leaveFrom="tw-translate-x-0"
                                leaveTo="tw-translate-x-full"
                            >
                                <Dialog.Panel  className="tw-pointer-events-auto tw-relative tw-w-[90vw]"> 
                                    <Transition.Child
                                        as={Fragment}
                                        enter="tw-ease-in-out duration-500"
                                        enterFrom="tw-opacity-0"
                                        enterTo="tw-opacity-100"
                                        leave="tw-ease-in-out tw-duration-500"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                    >
                                        <div className="tw-absolute tw-left-0 tw-top-0 tw--ml-8 tw-flex tw-pr-2 tw-pt-4 sm:tw--ml-10 sm:tw-pr-4">
                                            <button
                                                type="button"
                                                className="tw-rounded-md hover:tw-text-gray-300 tw-text-white hover:tw-bg-gray-500 focus:tw-outline-none tw-ring-2 tw-ring-white"
                                                onClick={onCloseClick}
                                            >
                                                <span className="tw-sr-only">Закрыть</span>
                                                <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto tw-bg-gray-200 tw-px-4 tw-pb-4 tw-shadow-xl">
                                        <PageTitle title='История изменений'/>
                                        <div id='done-form-column' className='tw-h-full tw-overflow-hidden tw-rounded-md tw-bg-white tw-w-full'>
                                            { isLoading && (!plannerStore.dataModel || plannerStore.dataModel.id !== 'planned_actions')
                                                ?
                                                    <Spinner/>
                                                :
                                                    <DirectoryFieldsList
                                                        dataModel={plannerStore.dataModel}
                                                        selectedDataObject={null}
                                                        dataObjects={dataObjects}
                                                        onItemClick={() => {}}
                                                        onItemDoubleClick={() => {}}
                                                        onSortClick={() => {}}
                                                        onFetchData={() => {}}
                                                        chosenDataObjectsList={[]}
                                                        onHierarchyClick={() => {}}
                                                        isHideSorting={true}
                                                        isHideFilter={true}
                                                    />
                                            }
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default observer(ActionHistoryContainer)