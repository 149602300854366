import React, { useContext } from 'react'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'
import { CheckCircleIcon, MinusIcon } from '@heroicons/react/20/solid'
import { contentTooltipTimeOut } from '../../../../../config/constTypes'
import { checkDataModelPermission } from '../../../../../functions/checkPermissions'

/**
 * Визуальный компонент отображает название одной категории для списка существующих категорий
 * 
 * @param {Object} item Текущий элемент списка
 * 
 */
const CategoryItem = ({item}) => {
    const { docStore, userStore } = useContext(Context)   
    const [isEdit, isEditDescription] = checkDataModelPermission(docStore.selectedDataModel, 'edit') 

    const isCategoryChecked = docStore.dataModelCategories.includes(item.id)

    let className = 'tw-relative tw-pl-12 tw-text-sm tw-select-none tw-border-b'

    if (docStore.isCategoryLinking) {
        className += ' tw-text-gray-900 tw-bg-white'
    } else {
        if (docStore.selectedCategory && docStore.selectedCategory.id === item.id) {
            className += ' tw-text-white tw-bg-gray-500'
        } else {
            className += ` tw-text-gray-900 tw-bg-white hover:tw-bg-gray-100 hover:tw-border-gray-300`
        }
    }

    const onClick = (category) => {
        if (!docStore.selectedCategory || docStore.selectedCategory.id !== category.id) {
            docStore.setSelectedCategory(category)
            const filteredList = docStore.dataModels.filter(item => item.category_ids.includes(category.id)).map(item => item.id)
            docStore.setCategoryDataModels(filteredList)

            docStore.setSelectedDataModel(null)
        }
    }

    const onDoubleClick = () => {
        if (!docStore.isCategoryLinking && userStore.checkPermission("category:edit"))
            docStore.setIsCategoryEditing(true)
    }

    const onLinkClick = (id) => {
        if (userStore.checkPermission("table:edit") && isEdit) {
            const originalCategories = docStore.dataModelCategories.slice()
            const editedCategories = originalCategories.includes(id)
                            ?   originalCategories.filter((category) => category !== id)
                            :   originalCategories.concat([id])
    
            docStore.updateDataModelCategories(docStore.selectedDataModel, editedCategories)    
        }
    }

    return (
        <div
            id={item.id}
            className={className}
        >
            { docStore.selectedDataModel && !docStore.selectedCategory &&
                <div
                    className={`tw-text-gray-500 tw-absolute tw-left-3 tw-py-1.5 ${userStore.checkPermission("table:edit") && 'hover:tw-cursor-pointer'}`}
                    data-tooltip-id="category-list-tooltip" data-tooltip-content={userStore.checkPermission("table:edit") ? isCategoryChecked  ? "Отвязать" : "Привязать" : ''} data-tooltip-delay-show={contentTooltipTimeOut}
                    onClick={() => onLinkClick(item.id)}
                >
                    { isCategoryChecked
                        ?   <CheckCircleIcon className='tw-w-5 tw-h-5 hover:tw-text-gray-700' aria-hidden='true'/>
                        :   <MinusIcon className='tw-w-5 tw-h-5 hover:tw-text-gray-700' aria-hidden='true'/>
                    }
                </div>
            }
            <div
                className={`tw-w-full tw-py-1.5 ${(!docStore.selectedCategory || docStore.selectedCategory.id !== item.id) && 'hover:tw-cursor-pointer'}`}
                onClick={() => onClick(item)}
                onDoubleClick={() => onDoubleClick(item)}
            >
                {item.name}
            </div>
        </div>
    )
}

export default observer(CategoryItem)