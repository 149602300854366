import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import { generatePassword } from '../../../functions/passwordGenerator'

/**
 * Визуальный компонент отображает форму редактирования информации о пользователе
 * 
 */
const CompanyUserForm = () => {
    const { AdminStore } = useContext(Context)
    const [showPassword, setShowPassword] = useState(true)
    const [isPasswordError, setIsPasswordError] = useState(false)
    const [isPhoneError, setIsPhoneError] = useState(false)
    const [isUsernameError, setIsUsernameError] = useState(false)
    const regexPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w])(?!.*[А-я])(?!.*[\s]).{8,}/g)
    const regexPhone = new RegExp(/^(\+7|7|8)[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/)

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm()

    const onCancelClick = () => {
        AdminStore.setIsUserCreating(false)
        AdminStore.setIsUserEditing(false)
    }

    const onSubmitClick = (form) => {
        setIsPasswordError(false)
        setIsPhoneError(false)
        setIsUsernameError(false)

        if (AdminStore.isUserCreating &&!regexPassword.test(form.password)) {
            toast.error(`Пароль должен быть не менее 8 символов, содержать прописные и строчные буквы латинского алфавита, спецсимволы и цифры.\n
                Пароль не должен включать прописные и строчные буквы кириллического алфавита, а так же пробелы`, 
                { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
            setIsPasswordError(true)
        } else if (!regexPhone.test(form.phone_number?.trim())) {
            toast.error(`Неверный формат номера телефона`, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
            setIsPhoneError(true)
        } else if (form.username.includes('@')) {
            toast.error(`Логин не должен содержать символ "@"`, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
            setIsUsernameError(true)
        } else {
            const user = {}
            user.last_name = form.last_name.trim()
            user.first_name = form.first_name.trim()
            user.username = form.username.trim()
            user.phone_number = form.phone_number.trim()
            user.email = form.email.trim()

            if (AdminStore.isUserCreating) {
                user.password = form.password.trim()
                user.company_id = AdminStore.selectedCompany.id
                user.curr_sub_company_id = AdminStore.selectedSubCompany.id
            }
            
            sessionStorage.setItem('userForm', JSON.stringify(user))
            AdminStore.isUserEditing
                ?   AdminStore.editUser(user, AdminStore.selectedUser.id)
                :   AdminStore.registerUser(user)
        }
    }

    const onGeneratePasswordClick = (e) => {
        e.preventDefault()
        setValue('password', generatePassword())
    }

    useEffect(() => {
        const userFormStorage = JSON.parse(sessionStorage.getItem('userForm'))
        if(userFormStorage){
            Object.entries(userFormStorage).map(([key, value], index) => {
                setValue(key, value)
            })
        }
    }, [])

    useEffect(() => {
        if(Object.entries(errors).length > 0 && isSubmitting === false){
            toast.error('Заполните обязательные поля', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
        }
    }, [errors, isSubmitting])

    return (
        <div id='company-user-info' className='tw-w-full tw-min-w-fit tw-h-full tw-rounded-md tw-bg-white'>
            <form className='tw-h-[calc(100%_-_3rem)] tw-overflow-auto tw-flex tw-flex-col'>
                <div className='tw-text-sm tw-font-semibold tw-p-4 tw-text-gray-900'>
                    { AdminStore.isUserEditing ? 'Редактируемый пользователь' : 'Новый пользователь' }
                </div>

                <div className='tw-grow'>
                    <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                            <label htmlFor="last_name">Фамилия</label>
                            <span>
                                { errors.last_name && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> }
                            </span>
                        </dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                            <input
                                id='last_name'
                                className={`tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-2 tw-text-gray-700 
                                            tw-ring-1 tw-ring-inset tw-ring-gray-400 focus:tw-ring-2 focus:tw-ring-inset
                                            focus:tw-z-10 sm:tw-text-sm focus-visible:tw-outline-none focus-visible:tw-ring-2
                                            ${errors.last_name ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                        `}
                                {...register('last_name', { required: true, value: AdminStore.isUserEditing ? AdminStore.selectedUser.last_name : '' })}
                            />
                        </dd>
                    </div>
                    <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                            <label htmlFor="first_name">Имя</label>
                            <span>
                                { errors.first_name && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> }
                            </span>
                        </dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                            <input
                                id='first_name'
                                className={`tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-2 tw-text-gray-700 
                                            tw-ring-1 tw-ring-inset tw-ring-gray-400 focus:tw-ring-2 focus:tw-ring-inset
                                            focus:tw-z-10 sm:tw-text-sm focus-visible:tw-outline-none focus-visible:tw-ring-2
                                            ${errors.first_name ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                        `}
                                {...register('first_name', { required: true, value: AdminStore.isUserEditing ? AdminStore.selectedUser.first_name : '' })}
                            />
                        </dd>
                    </div>
                    <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                            <label htmlFor="username">Логин</label>
                            <span>
                                {(errors.username || isUsernameError) && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> }
                            </span>
                        </dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                            <input
                                id='username'
                                className={`tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-2 tw-text-gray-700 
                                            tw-ring-1 tw-ring-inset tw-ring-gray-400 focus:tw-ring-2 focus:tw-ring-inset
                                            focus:tw-z-10 sm:tw-text-sm focus-visible:tw-outline-none focus-visible:tw-ring-2
                                            ${(errors.username || isUsernameError) ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                        `}
                                {...register('username', { required: true, value: AdminStore.isUserEditing ? AdminStore.selectedUser.username : '' })}
                            />
                        </dd>
                    </div>
                    <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                            <label htmlFor="phone_number">Телефон</label>
                            <span>
                                { (errors.phone_number || isPhoneError) && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> }
                            </span>
                        </dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                            <input
                                id='phone_number'
                                className={`tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-2 tw-text-gray-700 
                                            tw-ring-1 tw-ring-inset tw-ring-gray-400 focus:tw-ring-2 focus:tw-ring-inset
                                            focus:tw-z-10 sm:tw-text-sm focus-visible:tw-outline-none focus-visible:tw-ring-2
                                            ${(errors.phone_number || isPhoneError) ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                        `}
                                {...register('phone_number', { required: true, value: AdminStore.isUserEditing ? AdminStore.selectedUser.phone_number : '' })}
                            />
                        </dd>
                    </div>
                    <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                            <label htmlFor="email">Email</label>
                            <span>
                                { errors.email && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> }
                            </span>
                        </dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                            <input
                                type='email'
                                id='email'
                                className={`tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-2 tw-text-gray-700 
                                            tw-ring-1 tw-ring-inset tw-ring-gray-400 focus:tw-ring-2 focus:tw-ring-inset
                                            focus:tw-z-10 sm:tw-text-sm focus-visible:tw-outline-none focus-visible:tw-ring-2
                                            ${errors.email ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                        `}
                                {...register('email', { required: true, 
                                    value: AdminStore.isUserEditing ? AdminStore.selectedUser.email : '', 
                                    pattern: /.+@.+\..+/i 
                                })}
                            />
                        </dd>
                    </div>

        
                    {AdminStore.isUserCreating &&
                        <>
                            <div className='tw-text-right tw-w-full'>
                                <button 
                                    className='tw-text-sky-700 tw-font-semibold tw-group tw-text-xs tw-px-4
                                                hover:tw-text-sky-500 focus-visible:tw-outline-none focus-visible:tw-text-sky-500'
                                    onClick={onGeneratePasswordClick}
                                >
                                    Сгенерировать
                                </button>
                            </div>

                            <div className="tw-px-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                                <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                                    <label htmlFor="password">Пароль</label>
                                    <span>
                                        { (errors.password || isPasswordError) && 
                                            <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> 
                                        }
                                    </span>
                                </dt>
                                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                    <div className='tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm'>
                                        <input
                                            id='password'
                                            type={showPassword ? 'text' : 'password'}
                                            className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-2 tw-pr-8 tw-text-gray-900 
                                                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm 
                                                        sm:tw-leading-6 disabled:tw-text-gray-400 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                                        ${ (errors.password || isPasswordError) ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                                    `}
                                            {...register('password', { required: true })}
                                        />
                                        <div 
                                            className='tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 hover:tw-cursor-pointer'
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            { showPassword
                                                ?   <EyeSlashIcon className="tw-h-5 tw-w-5 tw-text-gray-400"/>
                                                :   <EyeIcon className="tw-h-5 tw-w-5 tw-text-gray-400"/>
                                            }
                                        </div>
                                    </div>
                                </dd>
                            </div>
                        </>
                    }
                </div>

                <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-py-4'>
                    <button 
                        className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700
                                    tw-text-white hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 
                                    focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                        onClick={handleSubmit(onSubmitClick)}
                    >
                        Сохранить
                    </button>
                    <button
                        className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                                hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2
                                focus-visible:tw-outline-gray-600'
                        onClick={onCancelClick}
                    >
                        Отменить
                    </button>
                </div>
            </form>
        </div>
    )
}

export default observer(CompanyUserForm)