import React, { useEffect, useState, useContext, Fragment, useRef } from 'react'
import { Context } from '../../../../..'
import DocumentService from '../../../../../services/DocumentService'
import DataObjectForm from '../form/DataObjectForm'
import DataObjectTabs from '../../../../tabs/DataObjectTabs'
import PageTitle from '../../common/panels/PageTitle'
import PrintDocumentModal from '../../exporter/to_file/PrintDocumentModal'
import Spinner from '../../../../../assets/Spinner'
import { toast } from 'react-toastify'
import { getReferenceValue, responseTimeOut, serviceMessageTimeOut, setReferenceValue } from '../../../../../config/constTypes'
import { handleFileRemove, handleFileSelect, printDocument } from '../../../../../functions/fileHandlers'
import { getErrorInfo, showErrorToast } from '../../../../../functions/errorHandlers'
import { compareDataObjects, processData } from '../../../../../functions/forms'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import PanelVerticalDivider from '../../common/dividers/PanelVerticalDivider'
import { observer } from 'mobx-react-lite'
import { deleteNestedDataObjects, updateNestedDataObjects } from '../../../../../functions/nestedModels'
import DialogTab from '../../../../dialog_tab/DialogTab'
import parse from 'html-react-parser'
import DialogTabStore from '../../../../../store/DialogTabStore'
import { Tooltip } from 'react-tooltip'
import RecordInputForm from '../../common/modals/RecordInputForm'

/**
 * Компонент реализует логику редактирования существующей записи в таблице
 * 
 * @param {Boolean} isOpen Признак видимости (отображения) модального окна
 * @param {Function} onCloseClick Обработчик клика мыши для закрытия окна
 * @param {Object} id ID записи
 * @param {Object} selectedFullDataModel Выбранная таблица
 * @param {Object} recordNotesStore Экземпляр хранилища информации о сообщениях
 * @param {Boolean} isNested Признак редактирования записей вложенной таблицы
 * @param {Function} saveNestedRecord Функция сохранения записивложенной таблицы
 * @param {Object} selectedNestedDataObject Выбранная вложенная запись
 */
const EditDataObjectContainer = ({isOpen, onCloseClick, id, selectedFullDataModel, recordNotesStore, isNested, saveNestedRecord, selectedNestedDataObject}) => {
    const { docStore } = useContext(Context)
    const [dialogTabStore] = useState(() => new DialogTabStore())

    const dummyDataObject = {id: '0', record_id: '0', entity_name: '', format: '', data: {}}
    const [isLoading, setIsLoading] = useState(true)
    const [isObjLoading, setIsObjLoading] = useState(false)
    const [selectedDataObject, setSelectedDataObject] = useState(dummyDataObject)
    const [errorInfo, setErrorInfo] = useState({})
    const [attachedFiles, setAttachedFiles] = useState([])
    const [isExporterSelecting, setIsExporterSelecting] = useState(false)
    const [isPrinting, setIsPrinting] = useState(false)
    const [isRecordInputFormOpen, setIsRecordInputFormOpen] = useState(false)
    const [recordInputFormQuestions, setRecordInputFormQuestions] = useState(null)
    const [formDataObject, setFormDataObject] = useState({})
    const formElement = useRef(null)
    const panelElement = useRef(null)

    const [nestedDataModels, setNestedDataModels] = useState([])
    const [selectedNestedDataModel, setSelectedNestedDataModel] = useState(null)

    const minLeftColumnSize = 400
    const minRightColumnSize = 300
    const defaultColumnSizes = '66.666667% 33.333333%'
    const savedColumnSizes = sessionStorage.getItem('savedFormSizes')   
    const columnSizes = savedColumnSizes || defaultColumnSizes

    const saveRecord = async (recordID, dataObject) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setIsObjLoading(false)
        }, responseTimeOut)

        setIsObjLoading(true)

        try {
            const response = await DocumentService.updateDataObject(recordID, dataObject)
            clearTimeout(noResponse)
            toast.success('Информация успешно сохранена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            setIsObjLoading(false)
            onCloseClick(false)
            
        } catch (error) {
            clearTimeout(noResponse)
            setIsObjLoading(false)
            const [errorMessage, errorInfoObject] = getErrorInfo(error, selectedFullDataModel.fields.slice())
            setErrorInfo(errorInfoObject)
            showErrorToast(error, 'saving', errorMessage)        
        }
    }

    const getForms = async (dataObject) => {
        try {
            const questions = await DocumentService.getRecordForms(dataObject, selectedFullDataModel.id, selectedDataObject.record_id)
            
            if (questions.show) {
                setRecordInputFormQuestions(questions)
                setIsRecordInputFormOpen(true)
                setFormDataObject(dataObject)
            } else {
                await saveRecord(selectedDataObject.record_id, dataObject)
            }
        } catch (error) {
            showErrorToast(error, 'saving', '')        
        }
    }

    const handleSubmitClick = async (form) => {
        const editedDataObject = processData(form.data, selectedFullDataModel) 

        editedDataObject.system_data = {}
        editedDataObject.system_data.files = attachedFiles.map(file => { return { id: file.id }})    

        if (form.system_data && form.system_data.parent_record_id) {
            if (form.system_data.parent_record_id?.value?.values?.length)
                editedDataObject.system_data.parent_record_id = form.system_data.parent_record_id.value.values[0].record_id
            else
                editedDataObject.system_data.parent_record_id = null
        }

        let isEditedNestedDataObject = false
        nestedDataModels
            .forEach(nestedDataModel => {
                if (!nestedDataModel.hide) {
                    if (nestedDataModel.type === 'reference' && nestedDataModel.validator_type === 'many') {
                        editedDataObject.data[nestedDataModel.tech_name] = []

                        nestedDataModel.dataObjects.forEach(dataObject => {
                            if (dataObject.status !== 'deleted') {
                                editedDataObject.data[nestedDataModel.tech_name].push(dataObject.record_id)
                            }

                            if (dataObject.status !== 'saved')
                                isEditedNestedDataObject = true
                        })
                    } else {
                        editedDataObject.data[nestedDataModel.tech_name] = {
                            upsert: updateNestedDataObjects(nestedDataModel),
                            remove: deleteNestedDataObjects(nestedDataModel)
                        }
                        if (editedDataObject.data[nestedDataModel.tech_name].upsert.length || editedDataObject.data[nestedDataModel.tech_name].remove.length)
                            isEditedNestedDataObject = true
                    }
                }
            })

        const isEditedDataObject = compareDataObjects(selectedDataObject, editedDataObject)
        if (isEditedDataObject || isEditedNestedDataObject) {
            if (isNested) {
                saveNestedRecord(form)
            } else {
                if (selectedFullDataModel.forms && selectedFullDataModel?.forms?.show) {
                    getForms(editedDataObject)
                } else {
                    await saveRecord(selectedDataObject.record_id, editedDataObject)
                }
            }
        } else {
            setIsObjLoading(false)
            toast.info('Изменений в записи не обнаружено', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            onCloseClick()
        }
    }

    const handleOpenExportersClick = () => {
        setIsExporterSelecting(true)
    }

    // Экспорт записи в PDF согласно выбранной печатной форме
    const handlePrintDocumentClick = async (template) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис экспорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        setIsPrinting(true)

        try {
            await printDocument(template.id, docStore.selectedDataObject.id, setIsPrinting)
        } catch (error){
            showErrorToast(error, 'export', '')
        } finally {
            clearTimeout(noResponse)
            setIsExporterSelecting(false)
            setIsPrinting(false)
        }
    }

    const handleCloseDialogClick = () => {
        if (!isObjLoading) {
            dialogTabStore.setParentName('EditDataObjectContainer')
            dialogTabStore.setDialogTabTitle("Завершение редактирования записи")
            dialogTabStore.setDialogTabText(parse(`Вы хотите закрыть форму редактирования записи без сохранения?<br/><br/>В противном случае нажмите "Отменить" и сохраните изменения`))
            dialogTabStore.setDialogTabButtons(["Закрыть", "Отменить"])
            dialogTabStore.setDialogTabIsOpen(true)
        }
    }

    const handleSaveAndCloseClick = () => {
        dialogTabStore.setDialogTabIsOpen(false)
        onCloseClick()
    }

    useEffect(() => {
        if (isOpen) {
            if (isNested) {
                setSelectedDataObject(selectedNestedDataObject)
                setIsLoading(false)
            } else {
                const noResponse = setTimeout(() => {
                    toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                    setIsLoading(false)
                    setSelectedDataObject(dummyDataObject)
                }, responseTimeOut)

                setIsLoading(true)
                DocumentService
                    .getOneDataObject(id)
                    .then(dataObject => {
                        clearTimeout(noResponse)
                        setSelectedDataObject(dataObject)
                        setIsLoading(false)
                        if (dataObject.system_data.files && dataObject.system_data.files.length)
                            setAttachedFiles(dataObject.system_data.files)

                        docStore.getAllNestedDataObjects(dataObject, setNestedDataModels, false)    
                    })
                    .catch(error => {
                        clearTimeout(noResponse)
                        showErrorToast(error, 'fetching', '')
                        setSelectedDataObject(dummyDataObject)
                        setIsLoading(false)
                    })
            }
        } else {
            setAttachedFiles([])
        }
    }, [isOpen])

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={handleCloseDialogClick}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-500"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-500"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                    <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                        <div className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex  tw-pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                enterFrom="tw-translate-x-full"
                                enterTo="tw-translate-x-0"
                                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                leaveFrom="tw-translate-x-0"
                                leaveTo="tw-translate-x-full"
                            >
                                <Dialog.Panel  className="tw-pointer-events-auto tw-relative tw-w-[90vw]"> 
                                    <Transition.Child
                                        as={Fragment}
                                        enter="tw-ease-in-out duration-500"
                                        enterFrom="tw-opacity-0"
                                        enterTo="tw-opacity-100"
                                        leave="tw-ease-in-out tw-duration-500"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                    >
                                        <div className="tw-absolute tw-left-0 tw-top-0 tw--ml-8 tw-flex tw-pr-2 tw-pt-4 sm:tw--ml-10 sm:tw-pr-4">
                                        <button
                                            type="button"
                                            className="tw-rounded-md hover:tw-text-gray-300 tw-text-white hover:tw-bg-gray-500 focus:tw-outline-none tw-ring-2 tw-ring-white"
                                            onClick={handleCloseDialogClick}
                                        >
                                            <span className="tw-sr-only">Закрыть</span>
                                            <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                                        </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto tw-bg-gray-200 tw-px-4 tw-pb-4 tw-shadow-xl">
                                        {isLoading
                                            ? <Spinner/> 
                                            : <>
                                                <PageTitle title={`Редактирование "${selectedDataObject.entity_name}": "${getReferenceValue(setReferenceValue(selectedDataObject))}"`}/>
                                                <div
                                                    id='edit-data-object-page'
                                                    style={{
                                                        display: 'grid', 
                                                        gridTemplateColumns: isNested ? '100%' : columnSizes,
                                                        height: 'calc(100% - 4rem)'
                                                    }}
                                                    ref={panelElement}
                                                >
                                                    <div id='data-form-column' className='tw-h-full tw-overflow-hidden' ref={formElement}>
                                                        <DataObjectForm
                                                            editMode={true}
                                                            dataObjectFields={Object.values(selectedDataObject.data)}
                                                            onSubmitClick={handleSubmitClick}
                                                            userErrors={errorInfo}
                                                            recordID={selectedDataObject.record_id}
                                                            onPrintDocumentClick={handleOpenExportersClick}
                                                            isLoading={isObjLoading}
                                                            dataModel={selectedFullDataModel}
                                                            parent={selectedDataObject?.system_data?.parent}
                                                        />
                                                    </div>

                                                    {!isNested &&
                                                        <div id='data-files-column' 
                                                            className='tw-h-full tw-overflow-hidden tw-relative tw-pl-4'
                                                        >
                                                            <PanelVerticalDivider 
                                                                leftElement={formElement}
                                                                panel={panelElement}
                                                                minLeftSize={minLeftColumnSize}
                                                                minRightSize={minRightColumnSize}
                                                            />
                                                                { !isObjLoading
                                                                    ?
                                                                        <DataObjectTabs
                                                                            files={attachedFiles}
                                                                            readOnly={false}
                                                                            onFileSelect={handleFileSelect(attachedFiles, setAttachedFiles)}
                                                                            onFileRemove={handleFileRemove(attachedFiles, setAttachedFiles)}
                                                                            selectedNestedDataModel={selectedNestedDataModel}
                                                                            nestedDataModels={nestedDataModels}
                                                                            setSelectedNestedDataModel={setSelectedNestedDataModel}
                                                                            setNestedDataModels={setNestedDataModels}
                                                                            isDocumentPage={true}
                                                                            isNestedLoading={false}
                                                                            panel={panelElement}
                                                                            panelID='edit-tabs'
                                                                            recordNotesStore={recordNotesStore}
                                                                        />
                                                                    :
                                                                        <div className='tw-h-full tw-w-full tw-bg-white tw-rounded-md'></div>
                                                                }
                                                        </div>
                                                    }
                                                </div>
                                                <PrintDocumentModal
                                                    isVisible={isExporterSelecting}
                                                    dataModelID={selectedDataObject.data_model_id}
                                                    isPrinting={isPrinting}
                                                    onSubmitClick={handlePrintDocumentClick}
                                                    onCloseModal={() => setIsExporterSelecting(false)}
                                                />
                                                <DialogTab 
                                                    parentName='EditDataObjectContainer'
                                                    dialogTabFunction={handleSaveAndCloseClick}
                                                    dialogTabStore={dialogTabStore}
                                                />
                                                {recordInputFormQuestions && recordInputFormQuestions?.show &&
                                                    <RecordInputForm
                                                        isVisible={isRecordInputFormOpen}
                                                        recordInputFormQuestions={recordInputFormQuestions}
                                                        onCloseModal={() => setIsRecordInputFormOpen(false)}
                                                        formDataObject={formDataObject}
                                                        saveRecord={saveRecord} 
                                                        selectedDataObject={selectedDataObject}
                                                    />
                                                }
                                            </>
                                        }
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
                <Tooltip id="edit-tabs-tooltip" className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
            </Dialog>
        </Transition.Root>
    )
}

export default observer(EditDataObjectContainer)
