// Функция предназначена для копирования текста в буфер обмена при незащищенном соединении (http://)
function unsecuredCopyToClipboard(text) {
    const textArea = document.createElement("textarea")
    textArea.value=text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)
}

/**
 * Функция осуществляет копирование текста ссылки в буфер обмена
 * @method
 * 
 * @param {String} path Путь к записи
 */
export function copyToClipboard (path) {
    const link = `${window.location.origin.toString()}${path}`
    console.log('Copied link: ', link)
    if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(link)
    } else {
        unsecuredCopyToClipboard(link)
    }
}