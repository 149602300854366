//Текст лицензионного соглашения
export const licenseText = `
<p  className='tw-text-sm'>Опубликовано: 28.09.2023 г.</p>
<br/> 
<p>Настоящий документ (далее - Соглашение) является Договором присоединения и Публичной офертой в соответствии со статьями 428 и 437 Гражданского кодекса Российской Федерации. Соглашение распространяется в форме электронного документа, размещенного в веб-интерфейсе бета-версии Программного обеспечения «MEDOED» (далее – бета-версия ПО «MEDOED»). 
Соглашение регулирует порядок использования бета-версии ПО «MEDOED», правообладателем которого является Музалевский Федор Александрович (далее – Правообладатель).
Используя функционал бета-версии ПО «MEDOED», Пользователь подтверждает, что ознакомлен, согласен и принимает в полном объеме условия Соглашения.
</p>
<br/>
<ol>
    <li>1. ИСПОЛЬЗОВАНИЕ БЕТА-ВЕРСИИ ПО</li>
    <br/>
        <ol>
            <li className='tw--indent-10 tw-ml-10'>1.1 &nbsp;&nbsp; ПО предназначено для автоматизации процесса подготовки адаптированных документов для выполнения регуляторных требований.</li>
            <li className='tw--indent-10 tw-ml-10'>1.2 &nbsp;&nbsp; Доступ к бета-версии ПО предоставляется Пользователю на безвозмездной основе в форме простой неисключительной лицензии.</li>
            <li className='tw--indent-10 tw-ml-10'>1.3 &nbsp;&nbsp; Доступ к использованию бета-версии ПО предоставляется посредством обращения к Правообладателю по адресу <a className='tw-font-semibold tw-text-sky-700' href="mailto:support.medoed@rtmtech.ru">support.medoed@rtmtech.ru</a>. В результате обращения Пользователю присваивается пароль для входа в систему.</li>
            <li className='tw--indent-10 tw-ml-10'>1.4 &nbsp;&nbsp; Право доступа к бета-версии ПО предоставляется на срок бета-тестирования ПО «MEDOED», а именно – в течение 1 года с момента выпуска бета-версии ПО «MEDOED».</li>
            <li className='tw--indent-10 tw-ml-10'>1.5 &nbsp;&nbsp; Пользователь использует бета-версию ПО на свой страх и риск. Пользователь самостоятельно определяет режим использования бета-версии ПО и обеспечивает конфиденциальность данных, предоставляемых в процессе использования функционала ПО.</li>
            <li className='tw--indent-10 tw-ml-10'>1.6 &nbsp;&nbsp; Пользователь обязуется предоставлять тестовые и/или обезличенные данные в рамках использования бета-версии ПО. Пользователь не должен использовать данную бета-версию ПО в отношении критически важной информации.</li>
            <li className='tw--indent-10 tw-ml-10'>1.7 &nbsp;&nbsp; Пользователь может оставлять Правообладателю комментарии об эффективности работы бета-версии ПО и выявленных дефектах путем направления обращений по адресу <a className='tw-font-semibold tw-text-sky-700' href="mailto:support.medoed@rtmtech.ru">support.medoed@rtmtech.ru</a>.</li>
        </ol>
    <br/>
    <li>2. ГАРАНТИИ СТОРОН</li>
    <br/>
        <ol>
            <li className='tw--indent-10 tw-ml-10'>2.1 &nbsp;&nbsp; Пользователь гарантирует, что он:</li>
                <ul>
                    <li className='tw-pl-10'>&#8212; достиг совершеннолетнего возраста;</li>
                    <li className='tw-pl-10'>&#8212; имеет полномочия на предоставление определенных документов и данных в процессе использования функционала ПО;</li>
                    <li className='tw-pl-10'>&#8212; ознакомился с настоящим соглашением и согласен с ним.</li>
                </ul>
            <li className='tw--indent-10 tw-ml-10'>2.2 &nbsp;&nbsp; Правообладатель гарантирует, что:</li>
                <ul>
                    <li className='tw-pl-10'>&#8212; обладает исключительным правом на бета-версию ПО;</li>
                    <li className='tw-pl-10'>&#8212; ПО и его бета-версия разработаны в законных целях автоматизации процесса категорирования КИИ;</li>
                    <li className='tw-pl-10'>&#8212; ПО свободно от вредоносного программного обеспечения;</li>
                    <li className='tw-pl-10'>&#8212; предпринимает разумные усилия для проработки выявленных дефектов в работе ПО и повышению его эффективности.</li>
                </ul>
        </ol>
    <br/>
    <li>3 ПРЕДУПРЕЖДЕНИЯ ПРАВООБЛАДАТЕЛЯ И ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</li>
    <br/>
        <ol>
            <li className='tw--indent-10 tw-ml-10'>3.1 &nbsp;&nbsp; Правообладатель предупреждает, что право на использование функционала бета-версии ПО предоставляется Пользователю в рамках тестирования ПО «MEDOED». Правообладатель оставляет за собой право вносить изменения в ПО, приостанавливать и/или останавливать работу ПО.</li>
            <li className='tw--indent-10 tw-ml-10'>3.2 &nbsp;&nbsp; Правообладатель не гарантирует, что доступ к бета-версии ПО будет предоставляться непрерывно, быстро, надежно и без ошибок.</li>
            <li className='tw--indent-10 tw-ml-10'>3.3 &nbsp;&nbsp; Функционал ПО направлен на автоматизацию процесса подготовки адаптированных документов для выполнения регуляторных требований, в связи с чем Пользователь может по своей инициативе предоставить данные конфиденциального характера. Правообладатель не отвечает за предоставление таких данных и не несет ответственности за возможные случаи разглашения информации.</li>
            <li className='tw--indent-10 tw-ml-10'>3.4 &nbsp;&nbsp; Пользователь использует бета-версию ПО на свой страх и риск. Пользователь самостоятельно несет ответственность за возможные последствия использования ПО.</li>
            <li className='tw--indent-10 tw-ml-10'>3.5 &nbsp;&nbsp; Правообладатель не отвечает перед Пользователем и/или третьими лицами за любой ущерб, включая убытки и упущенную выгоду или потерянные данные, вред деловой репутации, вызванные в связи с реализацией функционала ПО.</li>
            <li className='tw--indent-10 tw-ml-10'>3.6 &nbsp;&nbsp; Бета-версия ПО, включая все отдельные элементы, и веб-интерфейс, предоставляется в соответствии с общепринятым в мировой практике принципом «As Is» («такой, каков он есть»). Правообладатель не предоставляет никаких дополнительных явных или подразумеваемых гарантий относительно использования ПО.</li>
            <li className='tw--indent-10 tw-ml-10'>3.7 &nbsp;&nbsp; Используя бета-версию ПО «MEDOED», Пользователь соглашается, что результаты выполнения задач имеют информационный характер. Правообладатель не несет ответственности за его соответствие действительности и достижение целей Пользователя.</li>
        </ol>
    <br/>
    <li>4 ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</li>
    <br/>
        <ol>
            <li className='tw--indent-10 tw-ml-10'>4.1 &nbsp;&nbsp; ПО «MEDOED» является объектом авторского и смежных прав и включает в себя следующие объекты интеллектуальной собственности:</li>      
                <ul>
                    <li className='tw-ml-10'>&#8212; программа для ЭВМ;</li>
                    <li className='tw-ml-10'>&#8212; элементы дизайна, фирменного стиля и оформления ПО;</li>
                    <li className='tw-ml-10'>&#8212; базы данных;</li>
                    <li className='tw-ml-10'>&#8212; средства индивидуализации;</li>
                    <li className='tw-ml-10'>&#8212; все доработки, обновления, исправления и дополнения к ПО или его компонентам.</li>
                </ul>      
            <li className='tw--indent-10 tw-ml-10'>4.2 &nbsp;&nbsp; Пользователю не отчуждаются никакие объекты интеллектуальной собственности. Пользователь имеет право использовать функционал ПО только по прямому назначению в соответствии с условиями Соглашения.</li>
            <li className='tw--indent-10 tw-ml-10'>4.3 &nbsp;&nbsp; Пользователям, в частности, запрещается:</li>
                <ol>
                    <li className='tw-ml-10'>4.3.1 &nbsp;&nbsp; Осуществлять модификацию ПО, в том числе изменять, декомпилировать и производить иные действия с исходным кодом;</li>
                    <li className='tw-ml-10'>4.3.2 &nbsp;&nbsp; Распространять, копировать или иным образом осуществлять обнародование ПО;</li>
                    <li className='tw-ml-10'>4.3.3 &nbsp;&nbsp; Создавать произведения, являющиеся переработкой ПО;</li>
                    <li className='tw-ml-10'>4.3.4 &nbsp;&nbsp; Копировать элементы дизайна ПО, его структуры, фирменного наименования и средств индивидуализации и создавать на их основе объекты авторского или смежных прав или использовать любым другим способом;</li>
                    <li className='tw-ml-10'>4.3.5 &nbsp;&nbsp; Любым иным образом нарушать права на интеллектуальную собственность Правообладателя.</li>
                </ol>
        </ol>
    <br/>
    <li>5 ЗАЩИТА ИНФОРМАЦИИ</li>
    <br/>
        <ol>
            <li className='tw--indent-10 tw-ml-10'>5.1 &nbsp;&nbsp; Правообладатель принимает разумные усилия по обеспечению защиты информации, содержащейся в ПО.</li>
            <li className='tw--indent-10 tw-ml-10'>5.2 &nbsp;&nbsp; ПО собирает только те данные, которые предоставляются Пользователем. Пользователь самостоятельно предпринимает меры технического и организационно-правового характера для обеспечения защиты и конфиденциальности информации, предоставляемой в ПО, в том числе критически важной. Правообладатель не отвечает за предоставление Пользователем такой информации.</li>
            <li className='tw--indent-10 tw-ml-10'>5.3 &nbsp;&nbsp; Правообладатель не собирает и не осуществляет никаких действий с персональными данными, предоставляя доступ к ПО на основании обезличенных данных. Правообладатель не требует предоставления и не отвечает за использование Пользователем собственных персональных данных и персональных данных третьих лиц при использовании ПО.</li>
        </ol>
    <br/>
    <li>6 ПРОЧИЕ ПОЛОЖЕНИЯ</li>
    <br/>
        <ol>
            <li className='tw--indent-10 tw-ml-10'>6.1 &nbsp;&nbsp; Правообладатель осуществляет техническую поддержку бета-версии ПО «MEDOED» по мере возможности, в порядке поступления заявок по адресу электронной почты <a className='tw-font-semibold tw-text-sky-700' href="mailto:support.medoed@rtmtech.ru">support.medoed@rtmtech.ru</a>.</li>
            <li className='tw--indent-10 tw-ml-10'>6.2 &nbsp;&nbsp; Использование ПО означает полное и безоговорочное принятие Лицензионного Соглашения.</li>
            <li className='tw--indent-10 tw-ml-10'>6.3 &nbsp;&nbsp; Соглашение может быть изменено или дополнено Правообладателем без дополнительного уведомления Пользователя в любое время. Новая редакция Соглашения вступает в силу с момента его размещения.</li>
            <li className='tw--indent-10 tw-ml-10'>6.4 &nbsp;&nbsp; Продолжение использования ПО после внесения изменений и/или дополнений в Соглашение означает принятие и согласие Пользователя с такими изменениями и/или дополнениями.</li>
            <li className='tw--indent-10 tw-ml-10'>6.5 &nbsp;&nbsp; Вопросы, не урегулированные Соглашением, подлежат разрешению в соответствии с законодательством РФ.</li>
        </ol>
</ol>
<br/>
`