import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../../../..'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { checkNestedTables } from '../../../../../functions/checkNestedTables'
import {registerLocale} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import ru from "date-fns/locale/ru"
import DirectoryContainer from '../../common/panels/directory/DirectoryContainer'
import StageButtons from '../stage/StageButtons'
import { observer } from 'mobx-react-lite'
import DocumentService from '../../../../../services/DocumentService'
import Spinner from '../../../../../assets/Spinner'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import { processData } from '../../../../../functions/forms'
import { deleteAllNestedDataObjects, deleteNestedDataObjects, updateNestedDataObjects } from '../../../../../functions/nestedModels'
import FieldItem from '../../../../form_fields/FieldItem'
import FormErrorToastPanel from '../../common/panels/toast/FormErrorToastPanel'
import { emptyReferenceValue, setReferenceValue } from '../../../../../config/constTypes'

registerLocale("ru", ru)

/**
 * Визуальный компонент отображает этап создания или редактирования информации о компании
 * 
 */
const OrganizationForm = () => {   
    const {
        control,
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        clearErrors,
        formState: { errors, isSubmitting },
    } = useForm()

    const { userStore, categorizingCIIStore, docStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [selectedDataModelID, setSelectedDataModelID] = useState(null)
    const [selectedName, setSelectedName] = useState(null)
    const [isSavedRecord, setIsSavedRecord] = useState(false)
    const [formFields, setFormFields] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [hideStateOrder, setHideStateOrder] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isNestedLoading, setIsNestedLoading] = useState(false)
    const [chosenDataObjectsList, setChosenDataObjectsList] = useState([])

    const handleSelectClick = (dataModel, name) => {
        let fieldValue = getValues(name)
        if (fieldValue) {
            setChosenDataObjectsList([fieldValue])
        }

        setSelectedDataModelID(dataModel)
        setSelectedName(name)
        setIsSelectFormOpen(true)
        clearErrors(name.slice(0, -2))
    }

    const handleClearClick = (name) => {
        setValue(name, emptyReferenceValue)
        setIsSavedRecord(false)
        clearErrors(name.slice(0, -2))
    }

    const handleDoubleClick = (item) => {
        setValue(selectedName, {value: setReferenceValue(item)})
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
        setSelectedName(null)
        setIsSavedRecord(false)

        const [responsibleError, responsibleErrorMessage] = categorizingCIIStore.checkResponsiblePerson(selectedName, item, 'data.responsible_for_protect_zokii__gen_info_about_subj.0')
        if (responsibleError) {
            toast.error(responsibleErrorMessage, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
            setError('data.responsible_for_protect_zokii__gen_info_about_subj', { type: 'invalid', message: responsibleErrorMessage })
        }

        const [functionalError, functionalErrorMessage] = categorizingCIIStore.checkFunctionalPerson(selectedName, item)
        if (functionalError) {
            toast.error(functionalErrorMessage, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
            setError('data.funct_protects_zokii__gen_info_about_subj', { type: 'invalid', message: functionalErrorMessage })
        }
    }

    const handleCloseClick = () => {
        docStore.setIsDetailView(false)
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
    }

    const handleBackClick = () => {
        categorizingCIIStore.goPrevStage()
    }

    const handleForwardClick = async (form) => {
        setIsLoading(true)
        const nestedModelsWithErrors = checkNestedTables(categorizingCIIStore.organizationNestedModels, formFields, true, setError,
            'Включенные таблицы не должны быть пустыми, а также содержать повторяющиеся, пустые или отрицательные значения!')
        categorizingCIIStore.setOrganizationNestedModels(nestedModelsWithErrors)

        if (nestedModelsWithErrors.some(nestedModel => nestedModel.errors) || 
            (errors.data && 
            (errors.data['funct_protects_zokii__gen_info_about_subj'] || errors.data['responsible_for_protect_zokii__gen_info_about_subj']))
        ) {
            setIsLoading(false)

        } else {
            const dataObject = processData(form.data, categorizingCIIStore.activeDataModel)

            categorizingCIIStore.organizationNestedModels.forEach(nestedModel => {
              let deleteNestedDataObjectsArr = deleteNestedDataObjects(nestedModel),
                updateNestedDataObjectsArr = updateNestedDataObjects(nestedModel)
                if(nestedModel.id === 'state_order'){
                    if (hideStateOrder) {
                        dataObject.data['defense_order__gen_info_about_subj'] = {
                            remove: deleteAllNestedDataObjects(nestedModel)
                        }
                    } else {
                        dataObject.data['defense_order__gen_info_about_subj'] = { 
                            upsert: updateNestedDataObjectsArr,
                            remove: deleteNestedDataObjectsArr
                        }
                    }
                } else if (nestedModel.id === 'taxs'){
                    dataObject.data['taxes__gen_info_about_subj'] = { 
                        upsert: updateNestedDataObjectsArr,
                        remove: deleteNestedDataObjectsArr
                    }
                }
            })

            try {
                let isForcedLastStageUpdate = false
                if (!isSavedRecord) {
                    if (categorizingCIIStore.subjectInfo && categorizingCIIStore.subjectInfo.values.length > 0) {
                        const infoRecord = categorizingCIIStore.subjectInfo.values[0]
                        const response = await DocumentService.updateDataObject(infoRecord.record_id, dataObject)
                        categorizingCIIStore.setSubjectInfo({ ...categorizingCIIStore.subjectInfo, values: [{...infoRecord, id: response.id}] })
                        const savedSubjectInfo = await DocumentService.getOneDataObject(response.id)
                        
                        categorizingCIIStore.loadPersons(savedSubjectInfo.id)

                        // формирование необходимости заново сохранить и напечатать существующие акты категорирования
                        if (categorizingCIIStore.objectsCategorization && categorizingCIIStore.objectsCategorization.length) {
                            isForcedLastStageUpdate = true
                            const objectsCategorizationRequests = categorizingCIIStore.objectsCategorization.map(objectCategorization => {
                                return DocumentService.updateDataObject(objectCategorization.record.record_id, { 
                                    data: { 'date_of_print_act__act_of_categorir': null, 'is_data_current__act_of_categorir': false }
                                })
                            })
            
                            await Promise.all(objectsCategorizationRequests)
                                .then(async () => {
                                    await categorizingCIIStore.loadCategorizingActs(categorizingCIIStore.project, categorizingCIIStore.resourceList.dataObjects)
                                })    
                        }
                    } else {
                        const response = await DocumentService.createDataObject(dataObject)
                        await categorizingCIIStore.updateProject('select_info_about_subject__stages_of_categorization', [response.record_id], true)
                    }

                    toast.success('Данные успешно сохранены!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                }

                categorizingCIIStore.goNextStage(isForcedLastStageUpdate)  

            } catch (error) {
                setIsLoading(false)
                showErrorToast(error, 'saving', '')
            }
        }
    }

    const hideFields = (fields, isHidedStateOrder) => {
        return fields.map(fieldItem => { 
            return {
                ...fieldItem,
                hidden: (fieldItem.tech_name === 'defense_order__gen_info_about_subj' && isHidedStateOrder) 
            }
        })
    }

    const loadSavedRecord = async (id, isHidedStateOrder) => {
        try {
            const savedRecord = await DocumentService.getOneDataObject(id)
            const savedFieldValues = Object.values(savedRecord.data)
            setFormFields(hideFields(savedFieldValues, isHidedStateOrder))

            setIsEditMode(true)
            setIsSavedRecord(true)
        } catch (error) {
            showErrorToast(error, 'fetching', '') 
            setFormFields(hideFields(categorizingCIIStore.activeDataModel.fields, isHidedStateOrder))
        }

        return null
    }

    const handleChangeNestedTables = (editedValues, editedNestedModel) => {
        const editedNestedModels = categorizingCIIStore.organizationNestedModels.map((nestedModel) =>  { 
            if(nestedModel.id === editedNestedModel.id){
                const primaryField = nestedModel.fields.find(field => field.order === 0)
                const [isError, checkedNestedModel] = categorizingCIIStore.checkNestedValueErrors(editedValues, primaryField.tech_name, true)

                return {
                ...nestedModel,
                    dataObjects: nestedModel.errors ? checkedNestedModel : editedValues,
                    errors: nestedModel.errors ? isError : false
                }
            }
            return nestedModel
        })
        categorizingCIIStore.setOrganizationNestedModels(editedNestedModels)
        setIsSavedRecord(false)

        if (errors.data)
            clearErrors('data.'+ editedNestedModel.tech_name)
    }

    useEffect(() => {
        categorizingCIIStore.setIsDataLoading(true)
        categorizingCIIStore.loadOrganizationNestedModels(setIsNestedLoading, true)
    }, [])

    useEffect(() => {
        let isHidedStateOrder = false
        categorizingCIIStore.irrelevantIndicatorsValues.forEach(indicator => {
            if(indicator.data['indicator__irrelevant_indicators_2'].value.values[0].code === '13а' 
                || indicator.data['indicator__irrelevant_indicators_2'].value.values[0].code === '13б'
            )
                isHidedStateOrder = true
        })
        setHideStateOrder(isHidedStateOrder)

        if (categorizingCIIStore.activeDataModel && categorizingCIIStore.activeDataModel.id === 'gen_info_about_subj') {
            if (categorizingCIIStore.subjectInfo && categorizingCIIStore.subjectInfo.values.length > 0) {
                loadSavedRecord(categorizingCIIStore.subjectInfo.values[0].id, isHidedStateOrder)
            } else {
                setFormFields(hideFields(categorizingCIIStore.activeDataModel.fields, isHidedStateOrder))
                setValue('data.director_main__gen_info_about_subj', userStore.currentSubCompany?.owner_position + ' ' + userStore.currentSubCompany?.owner)          
            }
        }

        categorizingCIIStore.setIsDataLoading(false)
    }, [categorizingCIIStore.activeDataModel, categorizingCIIStore.irrelevantIndicatorsValues])

    useEffect(() => {
        if(Object.entries(errors).length > 0 && isSubmitting === false){
            toast.error(<FormErrorToastPanel errors={errors.data} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <>
            <div className='tw-grow tw-overflow-auto tw-mt-2'>
                <form className='tw-flex tw-flex-col tw-h-full'>
                    <div className='tw-px-4 tw-py-1'>
                    { !formFields  || categorizingCIIStore.isDataLoading
                        ?
                            <Spinner />
                        :
                            formFields.slice().sort((a, b) => a.order - b.order).map((fieldItem, index) => {
                                if (!fieldItem.hide && !fieldItem.hidden) {
                                    return  <FieldItem
                                                key={index}
                                                fieldItem={fieldItem}
                                                nestedModels={categorizingCIIStore.organizationNestedModels}
                                                selectedNestedValue={categorizingCIIStore.selectedNestedValue}
                                                errors={errors}
                                                handleSelectClick={handleSelectClick}
                                                handleClearClick={handleClearClick}
                                                handleRecordChange={setIsSavedRecord}
                                                isEditMode={isEditMode}
                                                isDuplicateMode={false}
                                                isLoading={isNestedLoading}
                                                control={control}
                                                register={register}
                                                setValue={setValue}
                                                onSelectNestedValue={e => categorizingCIIStore.setSelectedNestedValue(e)}
                                                onNestedTableChange={handleChangeNestedTables}
                                            />
                                } else return null
                            })
                    }
                    </div>
                </form>
            </div>
            <StageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleSubmit(handleForwardClick)}
                disabled={isLoading}
            />
            <DirectoryContainer 
                isOpen={isSelectFormOpen}
                selectedDataModel={selectedDataModelID}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
                chosenDataObjectsList={chosenDataObjectsList}
            />
        </>                                                             
    )
}

export default observer(OrganizationForm)
