import React from 'react'
import ExporterItem from './ExporterItem'


/**
 * Визуальный компонент отображает список существующих печатных форм
 * 
 * @param {Object[]} list Список существующих печатных форм
 * @param {Object} activeItem Выбранный элемент в списке печатных форм
 * @param {Object[]} dataModelsList Список таблиц
 * @param {Boolean} isDisabled Признак недоступности элемента
 * @param {Function} onItemClick Обработчик клика мыши на элементе списка
 * @param {Function} onDoubleClick Обработчик двойного клика мыши на элементе списка
 * 
 */
const ExporterList = ({list, activeItem, dataModelsList, isDisabled, onItemClick, onDoubleClick}) => {
    return (
        <div  id='exporter-list-items' className={`tw-flex tw-flex-col tw-h-[calc(100%_-_3rem)] tw-w-full tw-bg-white tw-overflow-auto
                                                    ${isDisabled && 'tw-pointer-events-none tw-opacity-50'}`}
        >
            { list.map((exporter, index) => 
                <ExporterItem 
                    key={index}
                    item={exporter}
                    activeItem={activeItem}
                    onClick={onItemClick}
                    dataModelsList={dataModelsList}
                    onDoubleClick={onDoubleClick}
                />
            )}
        </div>
    )
}

export default ExporterList