import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../..'
import DataSwitch from '../../../main_page/controller/common/inputs/DataSwitch'

/**
 * Визуальный компонент отображает форму редактирования информации о разрешениях
 * 
 */
const RolePermissionsForm = () => {
    const { RolesStore } = useContext(Context)

    const handleClick = (item) => {
        RolesStore.setPermissions(RolesStore.permissions.map(permission => (
            permission.id === item.id
                ?   {...permission, isTrue: !permission.isTrue}
                :   permission
        )))
    }

    return (
        <div id='permissions-form' className='tw-py-1 tw-h-full tw-overflow-auto'>
            {RolesStore.permissions.map((item, index) => {
                return  <div key={index} className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                            <dt className="sm:tw-col-span-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                                <p>{item.name}</p>
                            </dt>
                            <div className="tw-flex tw-justify-end tw-items-center">
                                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-mt-0">
                                    <DataSwitch
                                        isChecked={item.isTrue}
                                        onClick={() => handleClick(item)}
                                    />
                                </dd>
                            </div>
                        </div>
                })
            }
        </div>
    )
}

export default observer(RolePermissionsForm)
