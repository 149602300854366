import React from 'react'
import Spinner from '../../../../../../assets/Spinner'
import StageList from './StageList'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'


/**
 * Визуальный компонент отображает список этапов модуля (категорирования КИИ, обработка ПДн и др.)
 * 
 * @param {String} title Заголовок
 * @param {Object} list Список этапов модуля 
 * @param {Object} activeStage Текущий этап модуля
 * @param {String} idField Имя поля с номером этапа 
 * @param {Number} lastStageIndex Номер этапа, до которого дошел пользователь
 * @param {Boolean} isHideList Признак скрытия списка этапов 
 * @param {Function} onHideClick Обработчик клика мыши для перехода к следующему этапу
 * @param {Function} onItemClick Обработчик клика мыши для перехода обратно к произвольному завершенному этапу
 * 
 */
const StageListContainer = ({title, list, activeStage, idField, lastStageIndex, isHideList, onHideClick, onItemClick}) => {
    return (
        <>
            <div id='stage-list' 
                className={`${isHideList ? "tw-w-full lg:tw-w-8" : "tw-w-full"} tw-relative tw-h-80 lg:tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden tw-duration-500`}
            >
                <div id='stage-list-header' 
                    className={`tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 
                        tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2  tw-duration-500 ${isHideList && 'lg:tw-pt-16'}`}
                >
                    <p className={`${isHideList ? 'lg:tw-rotate-[-90deg] lg:tw-translate-x-[-1.5rem] lg:tw-translate-y-[-1.7rem]' : ''} 
                                        tw-text-md tw-font-semibold`}
                    >
                        {title}
                    </p>
                </div>

                { !list
                    ?   <Spinner/>
                    :   <StageList 
                            list={list}
                            activeStage={activeStage}
                            idField={idField}
                            lastStageIndex={lastStageIndex}
                            isHideList={isHideList}
                            onItemClick={onItemClick}
                        />
                }
                <button
                    className={`${isHideList ? 'tw-invisible lg:tw-visible tw-opacity-1' : 'tw-h-0 tw-invisible lg:tw-h-8 tw-opacity-0'} 
                                tw-absolute tw-bottom-0 tw-delay-500 tw-duration-500
                                tw-w-full tw-pl-2 tw-py-1
                                tw-text-gray-700 hover:tw-bg-gray-300 focus-visible:tw-outline 
                                focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600
                            `}
                    onClick={() => onHideClick(false)}
                    data-tooltip-id="stage-list" data-tooltip-content="Показать" data-tooltip-delay-show={1000}
                >
                    <ChevronDoubleRightIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                </button>

                <button
                    className={`${isHideList ? 'tw-invisible tw-opacity-0' : 'tw-h-0 tw-invisible lg:tw-h-8 lg:tw-visible tw-opacity-100'} 
                                tw-absolute tw-bottom-0 tw-delay-500 tw-duration-500
                                tw-w-full tw-flex tw-flex-row tw-items-start tw-gap-x-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold
                                tw-text-gray-700 hover:tw-bg-gray-300 focus-visible:tw-outline
                                focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600
                    `}
                    onClick={() => onHideClick(true)}
                >
                    <ChevronDoubleLeftIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                    <span className='tw-overflow-hidden'>Скрыть</span>
                </button>
            </div>
            <Tooltip id="stage-list" place="top-start" style={{ zIndex: 99 }}/> 
        </>
    )
}

export default StageListContainer