import React from 'react'
import { Switch } from '@headlessui/react'

/**
 * Визуальный компонент отображает поле выбора значения логического (булева) типа
 * 
 * @param {String} label Текстовая надпись (заголовок) рядом с компонентом
 * @param {Boolean} isChecked Выбранное значение
 * @param {Function} onClick Обработчик клика мыши при изменении значения {(e) => field.onChange(e)}
 * @param {Boolean} isThirdState Индикация третьего состояния
 * 
 */
const DataSwitch = ({label, isChecked, onClick, isThirdState}) => {
    return (
        <Switch.Group as='div' className='tw-flex tw-flex-row tw-justify-between tw-items-center'>
            { label && (label !== '') &&
                <Switch.Label passive className='tw-text-sm tw-font-medium tw-pl-2 tw-pr-6 tw-text-gray-900'>{label}</Switch.Label>
            }
            <div className='tw-w-28 tw-flex tw-flex-row tw-justify-between tw-items-center tw-rounded-md tw-border tw-px-2 tw-py-1 tw-border-gray-400'>
                <div className='tw-text-sm'>{isThirdState ? '---' : isChecked ? 'Да' : 'Нет'}</div>
                <Switch
                    checked={isChecked}
                    onChange={onClick}
                    className={`${isChecked ? 'tw-bg-gray-800' : 'tw-bg-gray-400'}
                            tw-relative tw-inline-flex tw-h-[24px] tw-w-[60px] tw-shrink-0 tw-cursor-pointer tw-rounded-full tw-border-2 tw-border-transparent 
                            tw-transition-colors tw-duration-200 tw-ease-in-out focus:tw-outline-none focus-visible:tw-ring-2  focus-visible:tw-ring-white 
                            focus-visible:tw-ring-opacity-75`}
                >
                    <span className="tw-sr-only"></span>
                    <span
                        aria-hidden="true"
                        className={`${isChecked ? 'tw-translate-x-9' : 'tw-translate-x-0'}
                            tw-inline-block tw-h-[20px] tw-w-[20px] tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0
                            tw-transition tw-duration-200 tw-ease-in-out`
                        }
                    />
                </Switch>
            </div>
        </Switch.Group>
    )
}

export default DataSwitch