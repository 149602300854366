import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { observer } from 'mobx-react-lite'
import QuestionsListContainer from './QuestionsListContainer'


/**
 * Компонент реализует логику прохождения тестирования во всплывающем окне
 * 
 * @param {Boolean} isOpen Признак видимости (отображения) модального окна
 * @param {Function} onBackClick Обработчик клика мыши для закрытия окна
 * @param {Function} register Метод, реализующий регистрацию поля UseForm
 * @param {Function} saveTestResults Сохранение результатов теста
 * @param {Boolean} isDone Признак просмотра всех вопросов теста
 * 
 */
const TestContainer = ({isOpen, onCloseClick, register, saveTestResults, isDone}) => {    
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={onCloseClick}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-500"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-500"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                    <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                        <div className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex tw-pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                enterFrom="tw-translate-x-full"
                                enterTo="tw-translate-x-0"
                                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                leaveFrom="tw-translate-x-0"
                                leaveTo="tw-translate-x-full"
                            >
                                <Dialog.Panel  className="tw-pointer-events-auto tw-relative tw-w-[90vw]"> 
                                    <Transition.Child
                                        as={Fragment}
                                        enter="tw-ease-in-out duration-500"
                                        enterFrom="tw-opacity-0"
                                        enterTo="tw-opacity-100"
                                        leave="tw-ease-in-out tw-duration-500"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                    >
                                        <div className="tw-absolute tw-left-0 tw-top-0 tw--ml-8 tw-flex tw-pr-2 tw-pt-4 sm:tw--ml-10 sm:tw-pr-4">
                                            <button
                                                type="button"
                                                className="tw-rounded-md hover:tw-text-gray-300 tw-text-white hover:tw-bg-gray-500 focus:tw-outline-none tw-ring-2 tw-ring-white"
                                                onClick={onCloseClick}
                                            >
                                                <span className="tw-sr-only">Закрыть</span>
                                                <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className='tw-pt-4 tw-h-full tw-overflow-hidden tw-px-6 tw-pb-2 tw-bg-gray-200 tw-shadow-xl tw-flex tw-flex-col'>
                                        <div className='tw-mb-4'>
                                            <button 
                                                className={`tw-rounded-md tw-border-2 tw-px-3 tw-py-1 
                                                    ${isDone
                                                        ?   ' tw-border-sky-600 tw-bg-sky-600 hover:tw-border-sky-500 hover:tw-bg-sky-500'
                                                        :   ' tw-border-gray-700 tw-bg-gray-700 hover:tw-border-gray-500 hover:tw-bg-gray-500'
                                                    }
                                                    tw-text-sm tw-font-semibold tw-text-white
                                                    disabled:tw-bg-gray-300  disabled:tw-border-gray-300 focus-visible:tw-outline
                                                    focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600`}
                                                onClick={onCloseClick}
                                            >
                                                <span>Завершить тест</span>
                                            </button>
                                        </div>
                                        <QuestionsListContainer
                                            register={register}
                                            saveTestResults={saveTestResults}
                                        />
                                    </div>        
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default observer(TestContainer)