import React, { useEffect, Fragment, useContext, useState, useRef } from 'react'
import { Context } from '../../../../../../..'
import { Dialog, Transition } from '@headlessui/react'
import { observer } from 'mobx-react-lite'
import { XMarkIcon } from '@heroicons/react/24/outline'
import PageTitle from '../../../../common/panels/PageTitle'
import ActionForm from './ActionForm'
import Spinner from '../../../../../../../assets/Spinner'
import { Tooltip } from 'react-tooltip'
import RecordNotesStore from '../../../../../../../store/RecordNotesStore'
import PanelVerticalDivider from '../../../../common/dividers/PanelVerticalDivider'
import DataObjectTabs from '../../../../../../tabs/DataObjectTabs'
import { handleFileRemove, handleFileSelect } from '../../../../../../../functions/fileHandlers'


/**
 * Визуальный компонент отображает модальное окно с формой редактирования планируемого действия
 * 
 * @param {Boolean} isOpen Признак видимости (отображения) модального окна
 * @param {Boolean} isLoading Признак загрузки/сохранения данных формы
 * @param {Function} setIsLoading Функция сохранения признака загрузки данных
 * @param {Function} onAddActionClick Обработчик клика мыши на кнопке сохранения данных при создании нового действия
 * @param {Function} onEditActionClick Обработчик клика мыши на кнопке сохранения данных при редактировании запланированного действия
 * @param {Function} onMarkActionClick Обработчик клика мыши на кнопке отметки о завершении действия
 * @param {Function} onCloseClick Обработчик клика мыши для закрытия окна
 * 
 */
const ActionFormContainer = ({isOpen, isLoading, setIsLoading, onAddActionClick, onEditActionClick, onMarkActionClick, onCloseClick}) => {
    const { plannerStore, docStore } = useContext(Context)
    const [recordNotesStore] = useState(() => new RecordNotesStore())
    const [attachedFiles, setAttachedFiles] = useState([])
    const [nestedDataModels, setNestedDataModels] = useState([])
    const [selectedNestedDataModel, setSelectedNestedDataModel] = useState(null)
    const formElement = useRef(null)
    const panelElement = useRef(null)

    const minLeftColumnSize = 400
    const minRightColumnSize = 300
    const defaultColumnSizes = '66.666667% 33.333333%'
    const savedColumnSizes = sessionStorage.getItem('savedFormSizes')
    const columnSizes = savedColumnSizes || defaultColumnSizes

    const getReferencedAction = async () => {
        const referencedAction = await docStore.getOneDataObject(plannerStore.selectedAction.data['my_action_reference'].value.values[0].id)
        plannerStore.setEditedMyAction(referencedAction)
    }

    const handleSubmitClick = (form) => {
        if (plannerStore.editMode === 'edit') 
            onEditActionClick(form, attachedFiles)
        else
            onAddActionClick(form, attachedFiles)
    }

    useEffect(() => {
        if (isOpen) {
            if (plannerStore.editMode === 'edit' && plannerStore.selectedAction) {
                getReferencedAction()
                if (plannerStore.selectedAction.system_data.files && plannerStore.selectedAction.system_data.files.length)
                    setAttachedFiles(plannerStore.selectedAction.system_data.files)
                recordNotesStore.setRecordID(plannerStore.selectedAction.record_id)
            }
        } else {
            plannerStore.setDataModel(null)
            plannerStore.setEditedMyAction(null)
            setIsLoading(false)
            setAttachedFiles([])
            recordNotesStore.setRecordID(null)
        }
    }, [isOpen, plannerStore.editMode, plannerStore.selectedAction])

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={onCloseClick}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-500"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-500"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                    <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                        <div className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex  tw-pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                enterFrom="tw-translate-x-full"
                                enterTo="tw-translate-x-0"
                                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                leaveFrom="tw-translate-x-0"
                                leaveTo="tw-translate-x-full"
                            >
                                <Dialog.Panel  className="tw-pointer-events-auto tw-relative tw-w-[75vw]"> 
                                    <Transition.Child
                                        as={Fragment}
                                        enter="tw-ease-in-out duration-500"
                                        enterFrom="tw-opacity-0"
                                        enterTo="tw-opacity-100"
                                        leave="tw-ease-in-out tw-duration-500"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                    >
                                        <div className="tw-absolute tw-left-0 tw-top-0 tw--ml-8 tw-flex tw-pr-2 tw-pt-4 sm:tw--ml-10 sm:tw-pr-4">
                                            <button
                                                type="button"
                                                className="tw-rounded-md hover:tw-text-gray-300 tw-text-white hover:tw-bg-gray-500 focus:tw-outline-none tw-ring-2 tw-ring-white"
                                                onClick={onCloseClick}
                                            >
                                                <span className="tw-sr-only">Закрыть</span>
                                                <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto tw-bg-gray-200 tw-px-4 tw-pb-4 tw-shadow-xl">
                                        <PageTitle title='Планирование действия'/>
                                        { isOpen && plannerStore.editMode &&
                                            <>
                                                {isLoading 
                                                    ?   <Spinner/>
                                                    :   <div
                                                            id='action-page'
                                                            style={{
                                                                display: 'grid', 
                                                                gridTemplateColumns: columnSizes,
                                                                height: 'calc(100% - 4rem)'
                                                            }}
                                                            ref={panelElement}
                                                        > 
                                                            <div id='action-edit-column' ref={formElement} className='tw-h-full tw-overflow-hidden tw-rounded-md tw-bg-white tw-w-full'>
                                                                <ActionForm
                                                                    isLoading={isLoading}
                                                                    onSubmitClick={handleSubmitClick}
                                                                    onMarkClick={onMarkActionClick}
                                                                />
                                                            </div>
                                                            <div id='data-files-column' className='tw-h-full tw-overflow-hidden tw-relative tw-pl-4'>
                                                                <PanelVerticalDivider
                                                                    leftElement={formElement}
                                                                    panel={panelElement}
                                                                    minLeftSize={minLeftColumnSize}
                                                                    minRightSize={minRightColumnSize}
                                                                />
                                                                <DataObjectTabs
                                                                    files={attachedFiles}
                                                                    readOnly={false}
                                                                    onFileSelect={handleFileSelect(attachedFiles, setAttachedFiles)}
                                                                    onFileRemove={handleFileRemove(attachedFiles, setAttachedFiles)}
                                                                    selectedNestedDataModel={selectedNestedDataModel}
                                                                    nestedDataModels={nestedDataModels}
                                                                    setSelectedNestedDataModel={setSelectedNestedDataModel}
                                                                    setNestedDataModels={setNestedDataModels}
                                                                    isDocumentPage={true}
                                                                    isNestedLoading={false}
                                                                    panel={panelElement}
                                                                    panelID='done-tabs'
                                                                    recordNotesStore={recordNotesStore}
                                                                />
                                                            </div>
                                                        </div>
                                                }
                                            </>
                                        }
                                    </div>
                                    <Tooltip id="action-form-tooltip" className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div> 
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default observer(ActionFormContainer)