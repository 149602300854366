import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'
import DataTypeListBox from '../../common/inputs/DataTypeListBox'
import Spinner from '../../../../../assets/Spinner'
import ReportPeriodPanel from '../common/ReportPeriodPanel'
import { paths } from '../../../../../config/constsURN'


/**
 * Визуальный компонент отображает панель настроек отчета по модулю "Повышение осведомленности"
 * 
 * @param {Object[]} users Массив пользователей
 * @param {Boolean} reportType Вид отчета
 * @param {Function} onSubmitClick Обработчик клика мыши на кнопке "Применить"
 * 
 */
const ReportEducationFilter = ({users, reportType, onSubmitClick}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { EducationStore } = useContext(Context)

    const [allTopics, setAllTopics] = useState(null)
    const [allMaterials, setAllMaterials] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedTopic, setSelectedTopic] = useState(null)
    const [selectedMaterial, setSelectedMaterial] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isNewFilter, setIsNewFilter] = useState(false)

    const handleUserChange = (user) => {
        EducationStore.setReportFilters('selectedUser', user, true)
        setSelectedUser(user)
        setIsNewFilter(true)
    }

    const handleTopicChange = (topic) => {
        const topicMaterials = topic.id === '0'
                                ?   EducationStore.allTopics
                                        .map(item => item.dataObjects.map(material => material.materialRecordID))
                                        .flat()
                                :   EducationStore.allTopics
                                        .filter(item => item.id === topic.id)
                                        .map(item => item.dataObjects.map(material => material.materialRecordID))
                                        .flat()
        const filteredMaterials = EducationStore.allMaterials
                                        .filter(material => topicMaterials.includes(material.record_id))
                                        .map(material => ({id: material.id, name: material.data.name.value, record_id: material.record_id}))
        const materials = [{id: '0', name: 'Все', record_id: '0'}].concat(filteredMaterials)
        setAllMaterials(materials)
        setSelectedMaterial(materials[0])

        const materialIDs = filteredMaterials.map(material => material.record_id)
        EducationStore.setReportFilters('selectedMaterials', topic.id === '0' ? [] : materialIDs, true)

        EducationStore.setReportFilters('selectedTopic', topic.id, true)
        setSelectedTopic(topic)
        setIsNewFilter(true)
    }

    const handleMaterialChange = (material) => {
        const materialIDs = material.id === '0'
                                ?   selectedTopic.id === '0'
                                        ?   []
                                        :   allMaterials.map(material => material.record_id).filter(item => item !== '0')
                                :   [material.record_id]
        EducationStore.setReportFilters('selectedMaterials', materialIDs, true)

        setSelectedMaterial(material)
        setIsNewFilter(true)
    }

    const handlePassedFilterChange = () => {
        EducationStore.setReportFilters('isPassed', !EducationStore.reportFilters.isPassed, true)
        setIsNewFilter(true)
    }

    const handleFromDateChange = (date) => {
        EducationStore.setReportFilters('fromDate', new Date(date).getTime(), true)
        setIsNewFilter(true)
    }

    const handleTillDateChange = (date) => {
        EducationStore.setReportFilters('tillDate', new Date(date).getTime(), true)
        setIsNewFilter(true)
    }

    const handleFromDateClear = () => {
        EducationStore.setReportFilters('fromDate', null, true)
        setIsNewFilter(true)
    }

    const handleTillDateClear = () => {
        EducationStore.setReportFilters('tillDate', null, true)
    }

    useEffect(() => {
        if (users && users.length && !isNewFilter) {
            let foundUser
            if (EducationStore.reportFilters.selectedUser)
                foundUser = users.find(user => user.email === EducationStore.reportFilters.selectedUser.email)
            if (foundUser && foundUser.id > 0)
                setSelectedUser(foundUser)
            else 
                setSelectedUser(users[0])

            if (allMaterials)
                setIsLoading(false)
        }
    }, [users, EducationStore.reportFilters.selectedUser])

    useEffect(() => {
        if (EducationStore.allTopics) {
            const updatedTopics = EducationStore.allTopics.map(topic => ({id: topic.id, name: topic.data.name.value}))
            const topics = [{id: '0', name: 'Все'}].concat(updatedTopics)
            setAllTopics(topics)
            setSelectedTopic(topics[0])

            if (users)
                setIsLoading(false)
        }
    }, [EducationStore.allTopics])

    useEffect(() => {
        if (EducationStore.allMaterials) {
            const updatedMaterials = EducationStore.allMaterials.map(material => ({id: material.id, name: material.data.name.value, record_id: material.record_id}))
            const materials = [{id: '0', name: 'Все', record_id: '0'}].concat(updatedMaterials)
            setAllMaterials(materials)
            setSelectedMaterial(materials[0])

            if (users)
                setIsLoading(false)
        }
    }, [EducationStore.allMaterials])

    useEffect(() => {
        if (isNewFilter) {
            if (location.search !== '')
                navigate(paths.REPORT_EDUCATION_ROUTE + '/' + reportType)

            setIsNewFilter(false)
        }
    }, [isNewFilter])

    if (isLoading)
        return <Spinner/>

    return (
        <div className='tw-flex tw-flex-col tw-px-4 tw-overflow-auto'>
            { users &&
                <DataTypeListBox
                    label={'Пользователь'}
                    itemList={users}
                    selectedItem={selectedUser || {id: '0', name: '', email: null}}
                    onItemChange={handleUserChange}
                    error={null}
                    selector={'id'}
                />
            }
            { reportType === 'total' &&
                <>
                    <DataTypeListBox
                        label={'Тема'}
                        itemList={allTopics}
                        selectedItem={selectedTopic || {id: '0', name: ''}}
                        onItemChange={handleTopicChange}
                        error={null}
                        selector={'id'}
                    />
                    <DataTypeListBox
                        label={'Материал'}
                        itemList={allMaterials}
                        selectedItem={selectedMaterial || {id: '0', name: ''}}
                        onItemChange={handleMaterialChange}
                        error={null}
                        selector={'id'}
                    />
                    <div className='tw-mt-4 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                        <input
                            id="is_passed"
                            type='checkbox'
                            className={`tw-block tw-rounded-sm tw-border
                                    tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                    focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                    tw-ring-gray-400
                                `}
                            checked={EducationStore.reportFilters.isPassed}
                            onChange={handlePassedFilterChange}
                        />
                        <label htmlFor="is_passed" className='tw-text-sm tw-text-gray-900'>Материал пройден</label>
                    </div>
                </>
            }
            <ReportPeriodPanel
                fromDate={EducationStore.reportFilters.fromDate}
                tillDate={EducationStore.reportFilters.tillDate}
                onFromDateChange={handleFromDateChange}
                onTillDateChange={handleTillDateChange}
                onFromDateClear={handleFromDateClear}
                onTillDateClear={handleTillDateClear}
            />
            <button
                className='tw-w-full tw-my-4 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold
                        tw-border-gray-600  tw-bg-gray-600 tw-text-white hover:tw-bg-gray-500 hover:tw-border-gray-500
                        disabled:tw-bg-gray-300 disabled:tw-border-gray-300
                        focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                disabled={false}
                onClick={onSubmitClick}
            >
                Применить
            </button>
        </div>
    )
}

export default observer(ReportEducationFilter)