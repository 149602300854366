export const linkListText = ` 
<p className="tw-font-semibold">Перечни типовых отраслевых объектов критической информационной инфраструктуры</p>
<br/>
<p> Перечень типовых объектов КИИ, функционирующих в сфере <strong>ТРАНСПОРТА</strong> (15.05.2023)</p>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://mintrans.gov.ru/documents/7/12506" target='_blank' rel='noreferrer'>• WWW</a>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://mintrans.gov.ru/file/493128" target='_blank' rel='noreferrer'>• PDF</a>
<br/>
<br/>
<p> Перечень типовых объектов КИИ, функционирующих в сфере <strong>ЭНЕРГЕТИКИ</strong> (08.08.2023)</p>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://minenergo.gov.ru/opendata/7715847529-perechen-obektov-kii-2023" target='_blank' rel='noreferrer'>• WWW</a>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://minenergo.gov.ru/opendata/7715847529-perechen-obektov-kii-2023/data-20230808T1400-structure-20230808T1400.csv" target='_blank' rel='noreferrer'>• CSV</a>
<br/>
<br/>
<p> Перечень типовых объектов КИИ, функционирующих в сфере <strong>ЗДРАВООХРАНЕНИЯ</strong> (27.12.2023)</p>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://portal.egisz.rosminzdrav.ru/materials/4525" target='_blank' rel='noreferrer'>• WWW</a>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://portal.egisz.rosminzdrav.ru/files/Перечень%20типовых%20ОКИИ-01.24_утвержденный.pdf" target='_blank' rel='noreferrer'>• PDF</a>
<br/>
<br/>
<p> Перечень типовых объектов КИИ, функционирующих в области <strong>ХИМИЧЕСКОЙ ПРОМЫШЛЕННОСТИ</strong>, согласованный ФСТЭК России (12.01.2024)</p>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://minpromtorg.gov.ru/activities/vgpp/vgpp4/perechni-tipovyh-obektov-kii?pdfModalID=628c9d9c-c689-4b8e-9f3a-414216b2e170" target='_blank' rel='noreferrer'>• WWW</a>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://minpromtorg.gov.ru/storage/797ced43-043d-4b4e-b72b-3d36984adbc7/ssp-documents/628c9d9c-c689-4b8e-9f3a-414216b2e170/a51b81f7-68dd-455f-b295-1da85e588c65.pdf" target='_blank' rel='noreferrer'>• PDF</a>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://minpromtorg.gov.ru/storage/797ced43-043d-4b4e-b72b-3d36984adbc7/ssp-documents/628c9d9c-c689-4b8e-9f3a-414216b2e170/01b6b300-415b-4295-bab9-ed9b68edcfb2.xlsx" target='_blank' rel='noreferrer'>• Excel</a>
<br/>
<br/>
<p> Перечень типовых объектов КИИ, функционирующих в области <strong>ГОРНОДОБЫВАЮЩЕЙ ПРОМЫШЛЕННОСТИ (В ЧАСТИ РУД, КАМНЕЙ)</strong>, согласованный ФСТЭК России (24.01.2024)</p>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://minpromtorg.gov.ru/activities/vgpp/vgpp4/perechni-tipovyh-obektov-kii?pdfModalID=444a7b4a-6a1a-4bf6-b503-4d2f93016f62" target='_blank' rel='noreferrer'>• WWW</a>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://minpromtorg.gov.ru/storage/797ced43-043d-4b4e-b72b-3d36984adbc7/ssp-documents/444a7b4a-6a1a-4bf6-b503-4d2f93016f62/01a07fdf-8b85-4c69-b19c-7dd668d65dcb.pdf" target='_blank' rel='noreferrer'>• PDF</a>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://minpromtorg.gov.ru/storage/797ced43-043d-4b4e-b72b-3d36984adbc7/ssp-documents/444a7b4a-6a1a-4bf6-b503-4d2f93016f62/3f3a2e51-28eb-46c3-8caf-4b516785e943.xlsx" target='_blank' rel='noreferrer'>• Excel</a>
<br/>
<br/>
<p> Перечень типовых объектов КИИ, функционирующих в области <strong>МЕТАЛЛУРГИЧЕСКОЙ ПРОМЫШЛЕННОСТИ</strong>, согласованный ФСТЭК России (24.01.2024)</p>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://minpromtorg.gov.ru/activities/vgpp/vgpp4/perechni-tipovyh-obektov-kii?pdfModalID=742b140c-aa6f-45f7-b170-5686f19c690a" target='_blank' rel='noreferrer'>• WWW</a>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://minpromtorg.gov.ru/storage/797ced43-043d-4b4e-b72b-3d36984adbc7/ssp-documents/742b140c-aa6f-45f7-b170-5686f19c690a/88ed5e17-eacd-4505-8d19-966df075510d.pdf" target='_blank' rel='noreferrer'>• PDF</a>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://minpromtorg.gov.ru/storage/797ced43-043d-4b4e-b72b-3d36984adbc7/ssp-documents/742b140c-aa6f-45f7-b170-5686f19c690a/afd3625c-0dfb-445c-b2bc-fb6a738e8780.xlsx" target='_blank' rel='noreferrer'>• Excel</a>
<br/>
<br/>
<p> Перечень типовых объектов КИИ, функционирующих в области <strong>ОБОРОННОЙ ПРОМЫШЛЕННОСТИ</strong>, согласованный ФСТЭК России (25.01.2024)</p>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://minpromtorg.gov.ru/activities/vgpp/vgpp4/perechni-tipovyh-obektov-kii?pdfModalID=132a4cf9-e70d-4d4d-9698-cf3681fa3939" target='_blank' rel='noreferrer'>• WWW</a>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://minpromtorg.gov.ru/storage/797ced43-043d-4b4e-b72b-3d36984adbc7/ssp-documents/132a4cf9-e70d-4d4d-9698-cf3681fa3939/7c9ee9fa-4a0a-4e22-9fcd-93ffc5990546.pdf" target='_blank' rel='noreferrer'>• PDF</a>
<a className='tw-font-semibold tw-text-sky-700 tw-ml-2' href="https://minpromtorg.gov.ru/storage/797ced43-043d-4b4e-b72b-3d36984adbc7/ssp-documents/132a4cf9-e70d-4d4d-9698-cf3681fa3939/373902de-79d1-4376-86f7-b16938d98a6b.xlsx" target='_blank' rel='noreferrer'>• Excel</a>
`
