import React, { useState, useEffect } from 'react'
import PageTitle from '../../common/panels/PageTitle'
import DocumentService from '../../../../../services/DocumentService'
import ImporterService from '../../../../../services/ImporterService'
import { useNavigate, useParams } from 'react-router-dom'
import { paths } from '../../../../../config/constsURN'
import { toast } from 'react-toastify'
import ImportPreviewTable from './ImportPreviewTable'
import Spinner from '../../../../../assets/Spinner'
import { errorHandler, showErrorToast } from '../../../../../functions/errorHandlers'
import { responseTimeOut, serviceMessageTimeOut } from '../../../../../config/constTypes'

/**
 * Компонент реализует логику предпросмотра импортируемых в таблицу данных
 * 
 */
const ImportPreviewContainer = () => {
    const navigate = useNavigate()
    const [importProcess, setImportProcess] = useState(null)
    const [importedData, setImportedData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [activeDataModel, setActiveDataModel] = useState(null)
    const [timeoutID, setTimeoutID] = useState(null)
    const { id } = useParams()

    function setRefreshTimeout(process) {
        if (process.timeout) {
            // const refreshTime = process.timeout > 10000 ? process.timeout - 10000 : 0
            const refreshTime = 540000
            const id = setTimeout(() => {
                refreshProcessTimeout()
            }, refreshTime)
            setTimeoutID(id)
        }
    }

    function refreshProcessTimeout() {
        ImporterService
            .refreshImportTimeout(id)
            .then(data => {
                setRefreshTimeout(data)
            })
            .catch(() => {
                toast.error('Ошибка при продлении времени ожидания импорта данных в таблицу!', { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
            })
    }

    const handleSubmitClick = (processID) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис импорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        ImporterService
            .confirmDataImport(processID)
            .then(data => {
                clearTimeout(noResponse)
                clearTimeout(timeoutID)
                toast.success('Импорт данных в таблицу успешно выполнен', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                navigate(paths.DATAOBJECT_ROUTE)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'import', '')
            })
    }

    const handleCancelClick = (processID) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис импорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        ImporterService
            .abortDataImport(processID)
            .then(() => {
                clearTimeout(noResponse)
                clearTimeout(timeoutID)
                toast.success('Импорт данных в таблицу отменен', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                navigate(paths.DATAOBJECT_ROUTE)
            })
            .catch(error => {
                clearTimeout(noResponse)
                clearTimeout(timeoutID)
                errorHandler(error.response, 'Ошибка при отмене импорта данных в таблицу!')
            })
    }

    useEffect(() => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис импорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setIsLoading(false)
        }, responseTimeOut)

        setIsLoading(true)
        
        ImporterService
            .getImportProcess(id)
            .then(data => {
                clearTimeout(noResponse)
                setImportProcess(data)
                setRefreshTimeout(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])


    useEffect(() => {
        if (importProcess) {
            const noResponse1 = setTimeout(() => {
                toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            }, responseTimeOut)

            setIsLoading(true)
            
            // запрос структуры таблицы, в которую импортируются данные
            DocumentService
                .getOneDataModel(importProcess.template.meta.data_model_id)
                .then(data => {
                    clearTimeout(noResponse1)
                    setActiveDataModel(data)
                })
                .catch(error => {
                    clearTimeout(noResponse1)
                    showErrorToast(error, 'fetching', '')
                    setActiveDataModel({id: '0', entity_name: '', fields: []})
                })
        
            const noResponse2 = setTimeout(() => {
                toast.error('Сервис импорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                setIsLoading(false)
            }, responseTimeOut)

            const filter = JSON.stringify([
                {property: 'data_model_id', value: importProcess.template.meta.data_model_id, operator: 'eq'},
                {property: 'transaction_id', value: importProcess.result.transaction_id, operator: 'eq'}
            ])
            // запрос импортируемых записей для предварительного просмотра
            DocumentService
                .getAllDataObjects(50, filter)
                .then(data => {
                    clearTimeout(noResponse2)
                    toast.success('Запрос данных выполнен успешно!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                    setImportedData(data)
                })
                .catch(error => {
                    clearTimeout(noResponse2)
                    showErrorToast(error, 'fetching', '')
                    setImportedData([])
                })
                .finally(() => setIsLoading(false))
        }
    }, [importProcess])


    if (isLoading || !importedData || !activeDataModel) {
        return <Spinner/>
    }

    return (
        <div  className='tw-flex tw-flex-col tw-h-full tw-w-full'>
            <PageTitle title={'Предварительный просмотр данных для импорта'} />
            <div className='tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                <div className='tw-h-full tw-overflow-hidden tw-w-full'>
                    { importedData && 
                        <div className='tw-h-[calc(100%_-_5rem)] tw-overflow-hidden'>
                            <ImportPreviewTable 
                                dataModel={activeDataModel}
                                importedData={importedData}
                            />
                        </div>
                    }
                    <div className='tw-w-full tw-py-4 tw-flex tw-flex-row tw-justify-center tw-items-center'>
                        <button 
                            className="tw-inline-flex tw-justify-center tw-border-2 tw-border-gray-700 tw-rounded-md 
                                    tw-bg-gray-700 tw-mx-2 tw-my-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold
                                    tw-text-white tw-shadow-sm hover:tw-bg-gray-500 disabled:tw-bg-gray-400"
                            type='button'
                            disabled={!importedData.length}
                            onClick={() => handleSubmitClick(id)}
                        >
                            Импортировать
                        </button>
                        <button 
                            className="tw-border-2 tw-border-gray-700 tw-rounded-md 
                                    tw-bg-white tw-mx-2 tw-px-3 tw-my-2 tw-py-1 tw-text-sm tw-font-semibold
                                    tw-text-gray-700 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-200"
                            type='button'
                            onClick={() => handleCancelClick(id)}
                        >
                            Отменить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImportPreviewContainer