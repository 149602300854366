import React from 'react'
import { medoedImage } from '../../..'
import logo from '../../../assets/logo.png'

/**
 * Визуальный компонент отображает заголовок с логотип Медоеда
 * 
 */
const AppLogo = () => {
    return (
        <div className="tw-flex tw-flex-row tw-items-center lg:tw-gap-x-2 lg:tw-ml-8">
        <span className='tw-w-16'>
            <a href='/' target='_self'>
                <img src={logo} alt={logo} />
            </a>
        </span>
        <div className='tw-flex tw-flex-col'>
            <span style={{fontFamily: 'TucsonTwo, serif'}} className='tw-text-white tw-text-3xl'><a href='/' target='_self'>MEDOED</a></span>
            <span className='tw-text-white tw-text-xs tw-tracking-widest'>{medoedImage.name && medoedImage.name !== '' ? medoedImage.name : null}</span>
        </div>
    </div>
)
}

export default AppLogo