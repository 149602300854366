/**
 * Константы URN (Unifrorm Resource Name/унифицированное имя ресурса), используемые в приложении
 * @module URNConsts
 */

/**
 * URN для API работы с пользователями
 */
export const userAPI = {
    LOGIN_API: '/auth/v1/token',
    REFRESH_API: '/auth/v1/token/refresh',
    LOGOUT_API: '/auth/v1/token/logout',
    USER_API: '/identity-provider/v1/users',
    COMPANY_API: '/identity-provider/v1/companies',
    PERMISSIONS_API: '/identity-provider/v1/permissions'
}

/**
 * URN для API работы со справочником и таблицами
 */
export const documentAPI = {
    DATAOBJECT_API: '/model-manager/v1/full-data-objects',
    DATAOBJECT_RECORD_API: '/model-manager/v1/records',
    DATAOBJECT_IMPORT_API: '/model-manager/v1/transactions',
    DATAMODEL_DATAOBJECT_API: '/model-manager/v1/data-models',
    DATAMODEL_API: '/model-manager/v1/full-data-models',
    DATAMODEL_ROLE_PERMISSION_API: '/model-manager/v1/user-groups',
    DATAMODEL_USER_PERMISSION_API: '/model-manager/v1/users',
    CATEGORY_API: '/model-manager/v1/categories',
    FIELD_API: '/model-manager/v1/fields',
    EXPORTER_API: '/export-service/v1/templates',
    EXPORTER_ONE_API: '/export-service/v1/template',
    EXPORTER_RUN_API: '/export-service/v1/export',
    EXPORTER_ZIP_API: '/export-service/v1/docs-packs',
    IMPORTER_API: '/import-service/v1/templates',
    IMPORTER_RUN_API: '/import-service/v1/import',
    IMPORTER_PROCESS_API: '/import-service/v1/processes',
    IMPORT_DATA_API: '/migrate-manager/v1/import',
    IMPORT_VIEW_API: '/migrate-manager/v1/data',
    IMPORT_MIGRATE_API: '/migrate-manager/v1/migrate',
    FILE_API: '/file-manager/v1/files',
}

/**
 * URN для API работы с пользователями
 */
export const configurationAPI = {
    VERSION_API: '/configuration-manager/v1/group',
    UPDATE_API: '/configuration-manager/v1/group/update',
    UPLOAD_API: '/configuration-manager/v1/packages',
}

/**
 * URN для маршрутизации в приложении
 */
export const paths = {
    MAIN_ROUTE: '/',
    LOGIN_ROUTE: '/login',
    REGISTRATION_ROUTE: '/registration',
    ACTIVATE_USER_ROUTE: '/activate-user',
    LICENSE_ROUTE: '/license',
    AGREEMENT_ROUTE: '/agreement',
    POLICY_ROUTE: '/policy',
    CONFIGURATION_ROUTE: '/configuration-info',
    CONFIGURATION_UPLOAD_ROUTE: '/upload-configuration',
    CONFIGURATION_UPDATE_ROUTE: '/update-configuration',
    DATAMODEL_ROUTE: '/data-model',
    ADD_DATAMODEL_ROUTE: '/add-data-model',
    EDIT_DATAMODEL_ROUTE: '/edit-data-model',
    DATAOBJECT_ROUTE: '/data-object',
    ADD_DATAOBJECT_ROUTE: '/add-data-object',
    DUPLICATE_DATAOBJECT_ROUTE: '/duplicate-data-object',
    EDIT_DATAOBJECT_ROUTE: '/edit-data-object',
    EXPORTER_ROUTE: '/export',
    ADD_EXPORTER_ROUTE: '/add-export',
    IMPORT_DATA_ROUTE: '/import-data',
    IMPORT_VIEW_ROUTE: '/import-data-view',
    IMPORT_PREVIEW_ROUTE: '/import-data-object-preview',
    IMPORTER_ROUTE: '/import',
    ERROR_ROUTE: '/page-404',
    ADMIN_ROUTE: '/admin',
    CATEGORY_ROUTE: '/categories',
    CATEGORIZING_CII_ROUTE: '/categorizing_cii',
    PERSONAL_DATA_ROUTE: '/personal-data',
    EDUCATION_ROUTE: '/education',
    RESOLUTION_ROUTE: '/low-resolution',
    COMPANY_ADMIN_ROUTE: '/company-admin',
    ROLES_ROUTE: '/roles',
    PLANNER_ROUTE: '/planner',
    REPORT_PLANNER_ROUTE: '/report/planner',
    REPORT_EDUCATION_ROUTE: '/report/education',
}

/**
 * URN для API работы с сообщениями
 */
export const notesAPI = {
    ADD_NOTE_API: '/note-manager/v1/messages/',
    GET_NOTES_API: '/note-manager/v1/messages/search',
}
