import React from 'react'

/**
 * Визуальный компонент отображает кнопку завершения просмотра материала
 * 
 * @param {Function} onCloseClick Обработчик клика мыши на кнопке завершения просмотра материала
 * 
 */
const EducationCloseButton = ({onCloseClick}) => {
    return (
        <div className='tw-px-4 tw-py-1'>
            <button
                type='button'
                className='tw-w-32 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                        hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                onClick={onCloseClick}
            >
                Завершить
            </button>
        </div>
    )
}

export default EducationCloseButton