import React, { useState, useRef, useContext, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ExclamationCircleIcon, DocumentArrowDownIcon, TrashIcon } from '@heroicons/react/24/outline'
import DataModelComboBox from '../../common/inputs/DataModelComboBox'
import DataTypeListBox from '../../common/inputs/DataTypeListBox'
import DataSwitch from '../../common/inputs/DataSwitch'
import Spinner from '../../../../../assets/Spinner'
import { htmlFormatter } from '../../../../../functions/htmlFormatter'
import JoditEditor from 'jodit-react';
import FileSelectButton from '../../common/buttons/FileSelectButton'
import { Context } from '../../../../..'
import { handleFileDownload, handleFileSelect } from '../../../../../functions/fileHandlers'
import ExporterFieldHelp from './ExporterFieldHelp'
import DocumentService from '../../../../../services/DocumentService'
import { observer } from 'mobx-react-lite'
import { menuTooltipTimeOut } from '../../../../../config/constTypes'
import { useHotkeys } from 'react-hotkeys-hook'


/**
 * Визуальный компонент отображает форму для создания/редактирования печатной формы
 * 
 * @param {Boolean} editMode Признак режима редактирования печатной формы
 * @param {Object} exporter Выбранная для редактирования печатная форма
 * @param {Object[]} files Прикрепленные файлы
 * @param {Function} setAttachedFiles Сохранение информации о прикрепленных файлах
 * @param {Function} onFileRemove Обработчик клика мыши на кнопке удаления файла
 * @param {Function} onSubmitClick Обработчик клика мыши на кнопке сохранения печатной формы
 * @param {Function} onCancelClick Обработчик клика мыши на кнопке отмены сохранения печатной формы
 * 
 */
const ExporterForm = ({editMode, exporter, files, setAttachedFiles, onFileRemove, onSubmitClick, onCancelClick}) => {
    const { FilterStore, userStore } = useContext(Context)
    const {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors, isValid, isSubmitting },
    } = useForm()

    const keyRef = useHotkeys('ctrl+s', () => handleSubmit(onSubmitClick)(), { preventDefault: true, enableOnFormTags: true })
    useHotkeys('ctrl+q', () => onCancelClick(), { preventDefault: true, enableOnFormTags: true })

    const dummyType = {id: '0', name: '', value: ''}
    const editor = useRef(null)
    const [selectedDataModel, setSelectedDataModel] = useState({id: '0', entity_name: '', fields: []})
    const object = {fields: [
        {
            alias: "Название таблицы",
            tech_name: "entity_name",
            validator_type: "string"
        }
    ]}
    const fileTypes = [
        {id: 1, name: 'PDF', value: 'html', hidden: false},
        {id: 2, name: 'ODT', value: 'file', hidden: false},
    ]
    
    register('exporterID', { 
        required: false,
        shouldUnregister: true,
        value: editMode ? exporter.id : '0'
    })

    register('dataModelID', { 
        required: true,
        shouldUnregister: true,
        value: editMode ? exporter.meta.data_model_id : '0',
        validate: value => value !== '0'
    })

    register('templateType', { 
        required: true,
        shouldUnregister: true,
        value: editMode ? fileTypes.find(item => item.value === exporter.render_data.type) : dummyType,
        validate: value => value.id !== '0'
    })

    const watchType = watch('templateType', editMode ? fileTypes.find(item => item.value === exporter.render_data.type) : dummyType)
    const watchFields = watch('isShowFields', false)

    const handleDataModelChange = (item) => {
        setValue('dataModelID', item.id, { shouldValidate: true })
        setSelectedDataModel(item)
    }

    const handleTypeChange = (item) => {
        setValue('templateType', item, { shouldValidate: true })
    }

    const loadExporterDataModel = async (dataModelID) => {
        const dataModel = await DocumentService.getOneDataModel(dataModelID)
        setSelectedDataModel(dataModel)
    }

    useEffect(()=>{
        if (editMode)
            loadExporterDataModel(exporter.meta.data_model_id)
    }, [])

    useEffect(()=>{
        setSelectedDataModel({id: '0', entity_name: ''})
    }, [FilterStore.selectedFilters])

    !editMode && isSubmitting && !isValid && toast.error('Заполните обязательное поле', { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })

    return (
        <form ref={keyRef} tabIndex={-1} id='exporter-form' className='tw-flex tw-flex-col tw-w-full tw-h-[calc(100%_-_3rem)] tw-overflow-auto'>
            <div className='tw-h-12 tw-text-md tw-font-semibold tw-py-4 tw-px-4'>
                {editMode ? 'Редактирование шаблона' : 'Новый шаблон'}
            </div>
            <div className='tw-flex tw-flex-col'>
                <div className='tw-block tw-w-full tw-px-4'>
                    <div className='tw-flex tw-flex-row tw-w-full tw-items-center tw-gap-x-2 tw-mt-2'>
                        <label className='tw-text-sm tw-font-medium tw-text-gray-800 tw-leading-6 tw-ml-2'>Название</label>
                        {errors.templateName && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                    </div>
                        <input
                            className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 sm:tw-text-sm focus-visible:tw-outline-none
                                ${errors.templateName ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                            `}
                            {...register('templateName', { 
                                required: true,
                                value: editMode ? exporter.name : ''
                            })}
                        />
                </div>
                { !editMode
                    ?
                        <div className='tw-flex tw-flex-row tw-items-center tw-w-full tw-px-4'>
                            <div className='tw-grow tw-mt-0.5'>
                                <DataModelComboBox
                                    label='Таблица'
                                    selectedItem={selectedDataModel}
                                    error={errors.dataModelID}
                                    typeFilter={['document', 'directory']}
                                    onItemChange={handleDataModelChange}
                                    object={object}
                                />
                            </div>
                            <div className='tw-ml-4 tw-w-28'>
                                <DataTypeListBox
                                    label={'Формат'}
                                    itemList={fileTypes}
                                    selectedItem={watchType}
                                    onItemChange={handleTypeChange}
                                    error={errors.templateType}
                                    selector='value'
                                />
                            </div>
                        </div>
                    :
                        <div className='tw-flex tw-flex-col tw-w-full tw-mt-2 tw-px-4 tw-text-sm tw-font-medium tw-text-gray-800'>
                            <label className=' tw-leading-6 tw-ml-2'>Таблица</label>
                            <div className='tw-w-full tw-px-2 tw-py-1.5 tw-mt-1 tw-rounded-md tw-border tw-text-gray-500 tw-border-gray-300 tw-select-none'>
                                {selectedDataModel?.entity_name}
                            </div>
                        </div>
                }
            { watchType.value &&
                <div className='tw-flex tw-flex-row tw-w-full tw-items-center tw-gap-x-2 tw-px-4 tw-mt-4'>
                    <label className='tw-text-sm tw-font-medium tw-text-gray-800 tw-leading-6 tw-ml-2'>Шаблон</label>
                    {errors.templateText && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                </div>
            }
            { watchType.value === 'file' &&
                <div className='tw-px-2'>
                    { !files.length &&
                        <FileSelectButton
                            onFileSelect={handleFileSelect(files, setAttachedFiles)}
                        />
                    }
                    <ul className='tw-px-2'>
                        {files.map((file, index) => (
                            <div key={index} className='tw-flex tw-flex-row tw-justify-start tw-items-center tw-border tw-border-gray-400 tw-rounded-md tw-mt-2'>
                                {file.isLoading
                                    ?
                                        <div className='tw-w-24 tw-pl-6'>
                                            <Spinner size='small'/>
                                        </div>
                                    :
                                        <div className='tw-flex tw-justify-center tw-gap-x-4 tw-w-24'>
                                            <button 
                                                className='tw-text-gray-500 hover:tw-text-gray-800 focus-visible:tw-text-gray-600 focus-visible:tw-outline 
                                                        focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600 focus-visible:tw-rounded-sm
                                                        disabled:tw-text-gray-300'
                                                data-tooltip-id="file-tab-tooltip" data-tooltip-content={userStore.checkPermission("file:get") ? "Скачать" : "Недостаточно прав для скачивания"} data-tooltip-delay-show={menuTooltipTimeOut}                                                
                                                type='button'
                                                disabled={!userStore.checkPermission("file:get")}
                                                onClick={() => handleFileDownload(file)}
                                            >
                                                <DocumentArrowDownIcon className="tw-w-6 tw-h-6" aria-hidden="true"/>
                                            </button>
                                            <button 
                                                className='tw-text-gray-500 hover:tw-text-red-600 focus-visible:tw-text-red-600 focus-visible:tw-outline 
                                                        focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-red-600 focus-visible:tw-rounded-sm'
                                                type='button'
                                                onClick={() => onFileRemove(file)}
                                            >
                                                <TrashIcon  className="tw-w-6 tw-h-6" aria-hidden="true"/>
                                            </button>
                                        </div>
                                }
                                <li
                                    className="tw-relative tw-rounded-md tw-mb-1 tw-px-3 tw-py-1 tw-border-l tw-truncate"
                                >
                                    <h3 className="tw-text-sm tw-font-medium tw-leading-5 tw-py-2">
                                        {file.metadata.name}.{file.metadata.extension}
                                    </h3>
                                </li>
                            </div>
                        ))}
                    </ul>
                </div>
            }
            { watchType.value === 'html' &&
                <div className='tw-block tw-py-2 tw-px-4'>
                    <JoditEditor
			            ref={editor}
            			value={(editMode && exporter.render_data.content) ? htmlFormatter(exporter.render_data.content) : ''}
			            tabIndex={1}
            			onBlur={newContent => setValue('templateText', newContent, { shouldValidate: true })}
            		/>
                </div>
            }
            </div>
            { watchType.value === 'file' && (selectedDataModel.id !== '0' || editMode) &&                   
                <div className='tw-px-4 tw-mt-2 tw-py-2'>
                    <Controller
                        name={'isShowFields'}
                        control={control}
                        defaultValue={false}
                        render={({field}) =>
                            <DataSwitch 
                                isChecked={field.value}
                                onClick={(e) => field.onChange(e)}
                                label={'Показать доступные для шаблона поля'}
                            />}
                    />
                </div>
            }
            <div className={`tw-grow ${watchFields ? 'tw-border-y-2' : ''}`}>
                { watchFields &&
                    <ExporterFieldHelp dataModel={selectedDataModel}/>
                }
            </div>
            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-py-4'>
                <button 
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={handleSubmit(onSubmitClick)}
                >
                    Сохранить
                </button>
                <button
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                            hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={onCancelClick}
                >
                    Отменить
                </button>
            </div>
        </form>
    )
}

export default observer(ExporterForm)