import React, { useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import RecordNotesItem from './RecordNotesItem'
import { useForm } from 'react-hook-form'

/**
 * Визуальный компонент отображает список сообщений
 * 
 * @param {Object[]} notes Список сообщений
 * @param {Object} recordNotesStore Экземпляр хранилища информации о сообщениях
 * @param {Function} onStartFetching Функция отметки начала запроса данных
 * @param {Function} onFetchData Функция обработки полученных данных
 * 
 */
const RecordNotesTable = ({notes, recordNotesStore, onStartFetching, onFetchData}) => {
    const frameElement = useRef(null)
    const {
        register,
        handleSubmit,
        resetField,
    } = useForm()

    const handleAddNote = (form) => {
        if (form.note?.trim()) {
            recordNotesStore.addNote(recordNotesStore.recordID, form.note, onFetchData)
            resetField("note")
        }
    }
 
    useEffect(() => {
        const handleScroll = (e) => {
            if ((e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 50) {
                onStartFetching(true)
            }
        }
        const element = frameElement.current
        element.addEventListener('scroll', handleScroll)

        return function () {
            element.removeEventListener('scroll', handleScroll)
        }
    }, [])
    
    return (
        <>
            <div
                className='tw-w-full tw-h-full tw-overflow-auto'
                ref={frameElement}
            >
                <div className='tw-w-full tw-text-gray-900 tw-font-medium tw-text-sm'>
                    { notes.map((note, index) => 
                        <RecordNotesItem key={index} note={note}/>
                    )}
                </div>
            </div>            
            <div>
                <form className='tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4' onSubmit={handleSubmit(handleAddNote)}>
                    <input 
                        type='text'
                        autoComplete='off'
                        placeholder='Ваше сообщение...'
                        className='tw-rounded-md tw-border-0 tw-col-span-2 tw-px-2 tw-py-1.5 tw-text-gray-900  tw-ring-1 tw-ring-inset 
                            tw-ring-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                            focus-visible:tw-ring-offset-gray-400'  
                            {...register('note')}
                    />
                    <button className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  
                            tw-bg-gray-700 tw-text-white disabled:tw-bg-gray-300 hover:tw-bg-gray-500 focus-visible:tw-outline 
                            focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600' 
                        type='submit'
                    >
                        Добавить сообщение
                    </button>
                </form>
            </div> 
        </>                  
    )       
}

export default observer(RecordNotesTable)