import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../../../..'
import { toast } from 'react-toastify'
import StageButtons from '../stage/StageButtons'
import { observer } from 'mobx-react-lite'
import DocumentService from '../../../../../services/DocumentService'
import Spinner from '../../../../../assets/Spinner'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import LinkedList from '../list/LinkedList'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { deleteNestedDataObjects, updateNestedDataObjects } from '../../../../../functions/nestedModels'


/**
 * Визуальный компонент отображает этап связывания объектов КИИ из ресурсов доступа
 * 
 */
const SpheresForm = () => {   
    const { categorizingCIIStore } = useContext(Context)

    const [sphereList, setSphereList] = useState([])
    const [selectedSphere, setSelectedSphere] = useState(null)
    const [selectedResource, setSelectedResource] = useState(null)
    const [isChangedRecord, setIsChangedRecord] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [objectField, setObjectField] = useState(null)
    const [sphereField, setSphereField] = useState(null)

    const handleSphereClick = (sphere) => {
        if (selectedSphere?.id !== sphere.id) {
            setSelectedSphere(sphere)
            setSelectedResource(null)
        }
    }

    const handleResourceClick = (resource) => {
        if (selectedResource?.id !== resource.id) {
            setSelectedResource(resource)
            setSelectedSphere(null)
            categorizingCIIStore.setSelectedNestedValue(resource)
        }
    }

    const handleLinkingClick = (value_record_id) => {
        // Клик на объекте КИИ, выбрана сфера деятельности
        if (selectedSphere) {
            const foundResource = categorizingCIIStore.resourceList.dataObjects.find(item => item.value_record_id === value_record_id)
            if (foundResource) {
                const editedLinks = (foundResource.spheres.includes(selectedSphere.value_record_id))
                    ?   foundResource.spheres.filter(item => item !== selectedSphere.value_record_id)
                    :   [...foundResource.spheres, selectedSphere.value_record_id]

                categorizingCIIStore.setResourceList(
                    {...categorizingCIIStore.resourceList,
                        dataObjects: categorizingCIIStore.resourceList.dataObjects.map(resource => 
                            resource.id !== foundResource.id
                                ?   resource
                                :   {...resource, spheres: editedLinks, status: 'edited'}
                        )
                    }
                )
            }
        }

        // Клик на сфере деятельности, выбран объект КИИ
        if (selectedResource) {
            const originalLinks = selectedResource.spheres
            const editedLinks = (originalLinks.includes(value_record_id))
                ?   originalLinks.filter(item => item !== value_record_id)
                :   [...originalLinks, value_record_id]

            categorizingCIIStore.setResourceList(
                {...categorizingCIIStore.resourceList,
                    dataObjects: categorizingCIIStore.resourceList.dataObjects.map(resource => 
                        resource.id !== selectedResource.id
                            ?   resource
                            :   {...resource, spheres: editedLinks, status: 'edited'}
                    )
                }
            )
            setSelectedResource({...selectedResource, spheres: editedLinks})
        }

        setIsChangedRecord(true)
    }

    const handleBackClick = () => {
        categorizingCIIStore.goPrevStage()
    }

    const handleForwardClick = async () => {
        setIsLoading(true)
        // проверка отсутствия связей со сферами деятельности
        const resourcesWithErrors = categorizingCIIStore.resourceList.dataObjects.map(resource => {
            return {...resource,
                        errors: resource.spheres.length === 0
            }
        })
        const isObjectError = resourcesWithErrors.some(resource => resource.errors)

        if (isObjectError) {
            setIsLoading(false)
            toast.error(<div>Для каждого объекта КИИ должна быть выбрана как минимум одна сфера деятельности!</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
            categorizingCIIStore.setResourceList({...categorizingCIIStore.resourceList, dataObjects: resourcesWithErrors })
            setSelectedSphere(null)
            setSelectedResource(null)

        } else {    // переход к следующему этапу
            const updatedListValues = updateObjectSpheres()
            categorizingCIIStore.setResourceList({...categorizingCIIStore.resourceList, dataObjects: updatedListValues })

            const dataObject = {}
            dataObject.data_model_id = 'list_for_categorization_2'
            dataObject.data = {}
            dataObject.data['an_object_vt__list_for_categorization_2'] = {
                upsert: updateNestedDataObjects(categorizingCIIStore.resourceList),
                remove: deleteNestedDataObjects(categorizingCIIStore.resourceList)
            }

            try {
                if (isChangedRecord) {
                    await DocumentService.updateDataObject(categorizingCIIStore.linkedList.values[0].record_id, dataObject)

                    if (categorizingCIIStore.linkedListPrintDate) {
                        // блокировка кнопки "Далее" для этапа "Печать перечня под категорирование"
                        categorizingCIIStore.setLinkedListPrintDate(null)
                        await categorizingCIIStore.updateProject('date_of_print_st_6__stages_of_categorization', null, true)
                    } else
                        await categorizingCIIStore.loadSavedProject(categorizingCIIStore.project.record_id)
                    toast.success('Данные успешно сохранены!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                }                
                categorizingCIIStore.goNextStage()

            } catch (error) {
                setIsLoading(false)
                showErrorToast(error, 'saving', '')
            }
        }       
    }


    const updateObjectSpheres = () => {
        // формирование массива сфер деятельности для каждого процесса
        const editedValues = categorizingCIIStore.resourceList.dataObjects.map(item => {
            const repackedSpheres = item.spheres.map(sphereID => {
                const foundSphere = sphereList.find(item => item.record_id === sphereID)
                if (foundSphere) {
                    const newObject = {entity_name: foundSphere.entity_name, name: foundSphere.data.name.value, record_id: foundSphere.record_id, id: foundSphere.id}
                    return newObject    
                }
                return null
            })
            .filter(item => item !== null)

            const editedObject = JSON.parse(JSON.stringify(item))           // object deep copy
            editedObject.data['types_of_activity_many__an_object_vt2'].value.values = repackedSpheres
            editedObject.errors = false

            return editedObject
        })

        return editedValues
    }

    const checkStageResult = () => {
        const result = categorizingCIIStore.resourceList.dataObjects.some(item => !item.spheres.length) 
        return result
    }

    const loadSphereList = async () => {
        const filter = JSON.stringify([
            {property: 'data_model_id', value: 'types_of_activities', operator: 'eq'},
            {property: 'active', value: true, operator: 'eq'},
        ])
        const sorter = JSON.stringify([
            {property: 'data.name', desc: false},
        ])
        try {
            const spheres = await DocumentService.getAllDataObjects(50, filter, sorter)
            setSphereList(spheres.map(item => { return {...item, value_record_id: item.record_id}}))
        } catch (error) {
            showErrorToast(error, 'fetching', '') 
            setSphereList([])
        }
    }


    useEffect(() => {
        loadSphereList()
        setObjectField(categorizingCIIStore.resourceList.fields.find(field => field.tech_name === 'an_object_vt__list_for_categorization_2'))
        setSphereField(categorizingCIIStore.resourceList.fields.find(field => field.tech_name === 'types_of_activity_many__an_object_vt2'))
        
        // проверка наличия изменений в списке объектов КИИ по отношению к сохраненному перечню под категорирование
        if (categorizingCIIStore.linkedList && categorizingCIIStore.linkedList.values.length > 0 && categorizingCIIStore.linkingTable) {
            const isResourcesChanged = (categorizingCIIStore.resourceList.dataObjects.filter(item => ['added', 'edited', 'deleted'].includes(item.status))).length > 0
            if (!isResourcesChanged && categorizingCIIStore.lastStageIndex > categorizingCIIStore.actualStageIndex)
                setIsChangedRecord(false)
        }
    }, [])


    return (
        <>
           <div className='tw-grow tw-overflow-auto'>
                <div id='stage-6-page' className='tw-grid tw-grid-cols-2 tw-gap-x-2 tw-max-h-full tw-h-full tw-py-1 tw-border-t tw-border-gray-400'>
                    <div id='objects-column' className='tw-h-full tw-bg-white tw-overflow-hidden tw-px-2 tw-border-r tw-border-gray-400'>
                        <div className='tw-h-12 tw-flex tw-items-center tw-gap-x-1 tw-border-b-2 tw-border-gray-400 tw-px-2 tw-py-2'>
                            <span className='tw-text-sm tw-font-semibold'>Объекты КИИ</span>
                            { objectField &&
                                <span
                                    data-tooltip-id="sphere-form" data-tooltip-content={objectField.description} data-tooltip-delay-show={250}
                                >
                                    <InformationCircleIcon className="tw-h-5 tw-w-5 tw-text-gray-500 tw-cursor-help" aria-hidden="true"/>
                                </span>
                            }
                        </div>
                        { categorizingCIIStore.isDataModelLoading
                            ?   <Spinner/>
                            :   <div  id='data-model-list-items' className='tw-flex tw-flex-col tw-h-[calc(100%_-_3rem)] tw-w-full tw-overflow-auto'>
                                    <LinkedList
                                        list={categorizingCIIStore.resourceList.dataObjects}
                                        selectedItem={selectedResource}
                                        linkingItem={selectedSphere}
                                        linkField='spheres'
                                        onItemClick={handleResourceClick}
                                        onLinkClick={handleLinkingClick}
                                        keyFieldNames={[
                                            'tech_process__techn_process',
                                            'business_proces__business_process_2',
                                            'process__types_of_activitess',
                                            'multiple_object__an_object_vt2',
                                        ]}
                                    />
                                </div>
                        }
                    </div>
                    <div id='spheres-column' className='tw-h-full tw-overflow-hidden tw-pr-2'>
                        <div className='tw-h-12 tw-flex tw-items-center tw-gap-x-1 tw-border-b-2 tw-border-gray-400 tw-px-2 tw-py-2'>
                            <span className='tw-text-sm tw-font-semibold'>Сферы деятельности</span>
                            { sphereField &&
                                <span
                                    data-tooltip-id="sphere-form" data-tooltip-content={sphereField.description} data-tooltip-delay-show={250}
                                >
                                    <InformationCircleIcon className="tw-h-5 tw-w-5 tw-text-gray-500 tw-cursor-help" aria-hidden="true"/>
                                </span>
                            }
                        </div>
                        { categorizingCIIStore.isDataModelLoading
                            ?   <Spinner/>
                            :   <div  id='data-model-list-items' className='tw-flex tw-flex-col tw-h-[calc(100%_-_3rem)] tw-w-full tw-overflow-auto'>
                                    <LinkedList
                                        list={sphereList}
                                        selectedItem={selectedSphere}
                                        linkingItem={selectedResource}
                                        linkField='spheres'
                                        onItemClick={handleSphereClick}
                                        onLinkClick={handleLinkingClick}
                                        keyFieldNames={['name']}
                                    />
                                </div>
                        }
                    </div>
                </div>
            </div>
            <StageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleForwardClick}
                disabled={checkStageResult() || isLoading}
            />
            <Tooltip id="sphere-form" place="top" className='tw-max-w-xl'/>
        </>                                                             
    )
}

export default observer(SpheresForm)