import React from 'react'
import Header from './Header'
import UserInfoContainer from './userInfo/UserInfoContainer'

/**
 * Компонент содержит заголовок приложения
 * 
 */
const HeaderContainer = () => {
    return (
        <>
            <Header />
            <UserInfoContainer />
        </>
    )    
}

export default HeaderContainer