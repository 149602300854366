import React from 'react'


/**
 * Визуальный компонент отображает всплывающее уведомление об ошибках в формах
 * 
 * @param {Object} errors Массив ошибок
 * @param {Object[]} fields Массив полей
 * 
 */
const FormErrorToastPanel = ({errors, fields}) => {
    return (
        <div>
            Проверка значений завершилась неудачно!
            <br/><br/>
            { Object.entries(errors)
                .map(([key, value], index) => {
                    return  <p key={index}>
                                {'"' + fields.find(field => field.tech_name === key)?.alias + '": ' +
                                (value.type === 'required' 
                                    ?   'не заполнено обязательное поле'
                                    :   `некорректное значение поля. ${value.message && value.message.length ? value.message : ''}`
                                )}
                            </p>
                })
            }
        </div>
    )
}

export default FormErrorToastPanel