import React from 'react'
import { TrashIcon, FilmIcon, DocumentTextIcon, PencilIcon } from '@heroicons/react/24/outline'

function getItemFileType(item) {
    if (item && item.material && item.material.data["material_type"]) {
        return item.material.data["material_type"].value
    }

    return null
}

/**
 * Визуальный компонент отображает название одного объекта из списка
 * 
 * @param {Object} item Объект 
 * @param {Object} stage Текущий активный объект
 * @param {Boolean} isDelete Признак возможности удаления объектов
 * @param {Function} onDeleteClick Обработчик клика мыши для удаления объектов
 * @param {Function} onJumpStageClick Обработчик клика мыши для перехода к произвольному объекту
 * @param {Boolean} isSelect Признак возможности перехода к произвольному объекту
 * 
 */
const ObjectsListItem = ({item, stage, isDelete, onDeleteClick, onJumpStageClick, isSelect}) => {
    const iconType = getItemFileType(item)

    return (
        <div
            onClick={(e) => {e.preventDefault(); onJumpStageClick(item)}} 
            className={`tw-text-sm tw-border-b tw-select-none tw-flex tw-justify-start tw-items-center tw-pr-1
                        ${ stage?.value === item.value 
                            ? ' tw-text-white tw-bg-gray-500'
                            : item.status === 'finished'
                                ? ' tw-text-gray-900 tw-bg-teal-500' + (isSelect ? ' tw-cursor-pointer hover:tw-bg-teal-400' : '')
                                : item.status === 'viewed'
                                    ? ' tw-text-gray-900 tw-bg-gray-300' + (isSelect ? ' tw-cursor-pointer hover:tw-bg-gray-400' : '')
                                    : ' tw-text-gray-900 tw-bg-white' + (isSelect ? ' tw-cursor-pointer hover:tw-bg-gray-100' : '')
                        }
            `}
        >
            <div className='tw-grow tw-pl-2 tw-pr-2 tw-py-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2 tw-overflow-hidden'>
                <span>
                    { iconType === 'Видео' && <FilmIcon className="tw-w-5 tw-h-5"/>}
                    { iconType === 'Документ' && <DocumentTextIcon className="tw-w-5 tw-h-5"/>}
                    { iconType === 'Тест' && <PencilIcon className="tw-w-5 tw-h-5"/>}
                </span>
                <span
                    className='tw-truncate'
                    data-tooltip-id="object-list-tooltip" data-tooltip-content={item.name} data-tooltip-delay-show={1000}
                >
                    {item.name}
                </span>
            </div>
            { isDelete &&
                <div className='tw-w-5 tw-h-5'>
                    <TrashIcon
                        className={`tw-w-5 tw-h-5 hover:tw-cursor-pointer ${item.status === 'finished' ? 'hover:tw-text-white' : 'hover:tw-text-black'}`}
                        data-tooltip-id="object-list-tooltip" data-tooltip-content="Удалить" data-tooltip-delay-show={500}
                        onClick={() => onDeleteClick(item)}
                    />
                </div>
            }
        </div>
    )
}

export default ObjectsListItem