import React from 'react'
import { DocumentPlusIcon, PencilSquareIcon, TrashIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { menuTooltipTimeOut } from '../../../../../../../config/constTypes'


/**
 * Визуальный компонент отображает меню возможных действий над списками
 * 
 * @param {String} label Заголовок списка
 * @param {String} description Всплывающая подсказка пользователю
 * @param {Object} activeItem Выбранный элемент в списке
 * @param {Boolean} isDisabled Признак недоступности меню
 * @param {Function} onAddItemClick Обработчик клика мыши на кнопке создания нового элемента списка
 * @param {Function} onEditItemClick Обработчик клика мыши на кнопке редактирования выбранного элемента списка
 * @param {Function} onHideItemClick Обработчик клика мыши на кнопке удаления выбранного элемента списка
 * 
 */
const ItemListMenu = ({label, description, activeItem, isDisabled, onAddItemClick, onEditItemClick, onDeleteItemClick}) => {
    return (
        <div id='item-list-menu' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
            <div className='tw-flex tw-flex-row tw-text-sm tw-font-semibold tw-gap-x-2'>
                <span>{label}</span>
                { description &&
                    <span
                        data-tooltip-id="list-menu-tooltip" data-tooltip-content={description} data-tooltip-delay-show={500}
                    >
                        <InformationCircleIcon className='tw-h-5 tw-w-5 tw-text-gray-500 tw-cursor-help' aria-hidden="true"/>
                    </span> 
                }
            </div>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onAddItemClick}
                    disabled={isDisabled}
                    data-tooltip-id="list-menu-tooltip" data-tooltip-content="Создать" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onEditItemClick(activeItem.id)}
                    disabled={!activeItem || isDisabled}
                    data-tooltip-id="list-menu-tooltip" data-tooltip-content="Редактировать" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onDeleteItemClick(activeItem)}
                    disabled={!activeItem || isDisabled}
                    data-tooltip-id="list-menu-tooltip" data-tooltip-content={"Удалить"} data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <TrashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
            </div>
            <Tooltip id="list-menu-tooltip" place="top"/>
        </div>
    )
}

export default ItemListMenu