import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import ReactDatePicker from 'react-datepicker'
import { Context } from '../../../../../../..'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../../../../../../config/constsURN'

/**
 * Визуальный компонент отображает панель настроек фильтров для запланированных действий
 * 
 */
const PlannerFilter = () => {
    const { plannerStore, userStore } = useContext(Context)
    const navigate = useNavigate()

    const [isNewFilter, setIsNewFilter] = useState(false)
    const [isDateFilterOff, setIsDateFilterOff] = useState(false)

    const handleDoneFilterChange = () => {
        setIsDateFilterOff(plannerStore.plannedFilters.isDoneActionShow && !plannerStore.plannedFilters.isOutdatedActionShow 
            && !plannerStore.plannedFilters.isPlannedActionShow && !plannerStore.plannedFilters.isMyRecommendedActionShow)
        plannerStore.setPlannedFilters('isDoneActionShow', !plannerStore.plannedFilters.isDoneActionShow, true)
        setIsNewFilter(true)
    }

    const handleOutdatedFilterChange = () => {
        setIsDateFilterOff(!plannerStore.plannedFilters.isDoneActionShow && plannerStore.plannedFilters.isOutdatedActionShow 
            && !plannerStore.plannedFilters.isPlannedActionShow && !plannerStore.plannedFilters.isMyRecommendedActionShow)
        plannerStore.setPlannedFilters('isOutdatedActionShow', !plannerStore.plannedFilters.isOutdatedActionShow, true)
        setIsNewFilter(true)
    }

    const handlePlannedFilterChange = () => {
        setIsDateFilterOff(!plannerStore.plannedFilters.isDoneActionShow && !plannerStore.plannedFilters.isOutdatedActionShow 
            && plannerStore.plannedFilters.isPlannedActionShow && !plannerStore.plannedFilters.isMyRecommendedActionShow)
        plannerStore.setPlannedFilters('isPlannedActionShow', !plannerStore.plannedFilters.isPlannedActionShow, true)
        setIsNewFilter(true)
    }

    const handleRecommendedFilterChange = () => {
        setIsDateFilterOff(!plannerStore.plannedFilters.isDoneActionShow && !plannerStore.plannedFilters.isOutdatedActionShow 
            && !plannerStore.plannedFilters.isPlannedActionShow && plannerStore.plannedFilters.isMyRecommendedActionShow)
        plannerStore.setPlannedFilters('isMyRecommendedActionShow', !plannerStore.plannedFilters.isMyRecommendedActionShow, true)
        setIsNewFilter(true)
    }

    const handleFromDateChange = (date) => {
        plannerStore.setPlannedFilters('fromDate', new Date(date).getTime(), true)
        setIsNewFilter(true)
    }

    const handleTillDateChange = (date) => {
        plannerStore.setPlannedFilters('tillDate', new Date(date).getTime(), true)
        setIsNewFilter(true)
    }

    const handleForAllFilterChange = () => {
        if (!plannerStore.plannedFilters.isAllActions) {
            plannerStore.setPlannedFilters('isAssignedToMe', false, true)
            plannerStore.setPlannedFilters('isAssignedByMe', false, true)
        } else {
            plannerStore.setPlannedFilters('isAssignedToMe', true, true)
            plannerStore.setPlannedFilters('isAssignedByMe', false, true)
        }
        plannerStore.setPlannedFilters('isAllActions', !plannerStore.plannedFilters.isAllActions, true)
        setIsNewFilter(true)
    }

    const handleForMeFilterChange = () => {
        plannerStore.setPlannedFilters('isAssignedToMe', !plannerStore.plannedFilters.isAssignedToMe, true)
        setIsNewFilter(true)
    }

    const handleByMeFilterChange = () => {
        plannerStore.setPlannedFilters('isAssignedByMe', !plannerStore.plannedFilters.isAssignedByMe, true)
        setIsNewFilter(true)
    }

    const handleApplyFilterClick = () => {
        plannerStore.setIsFilterChanged(true)
        setIsNewFilter(false)
        navigate(paths.PLANNER_ROUTE)
    }

    useEffect(() => {
        if (plannerStore.isFilterChanged) {
            const actionFilters = plannerStore.checkActionFilters(userStore.user.email)
            plannerStore.getActions('planned_actions', actionFilters, false)
            if (plannerStore.selectedTab === 'calendar') {
                plannerStore.getCalendarActions(userStore.user.email, plannerStore.calendarRange)
            }
    
            plannerStore.setIsFilterChanged(false)
            plannerStore.setIsFiltersOpen(false)
        }
    }, [plannerStore.isFilterChanged])

    return (
        <div className='tw-w-full tw-h-full tw-text-sm tw-flex tw-flex-col tw-overflow-hidden'>
            <div className='tw-font-semibold tw-px-4 tw-py-2 tw-border-b-2 tw-border-gray-400'>
                Фильтры запланированных действий
            </div>
            <div className='tw-w-full tw-px-4 tw-overflow-auto'>
                <div className='tw-mt-4 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                    <input
                        id="for-all"
                        type='checkbox'
                        className={`tw-block tw-rounded-sm tw-border
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                tw-ring-gray-400
                            `}
                        checked={plannerStore.plannedFilters.isAllActions}
                        onChange={handleForAllFilterChange}
                    />
                    <label htmlFor="for-all" className='tw-text-sm tw-text-gray-900'>Все действия</label>
                </div>
                <div className='tw-mt-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                    <input
                        id="for-me"
                        type='checkbox'
                        className={`tw-block tw-rounded-sm tw-border
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                tw-ring-gray-400
                            `}
                        checked={plannerStore.plannedFilters.isAssignedToMe}
                        onChange={handleForMeFilterChange}
                        disabled={plannerStore.plannedFilters.isAllActions}
                    />
                    <label htmlFor="for-me" className={`tw-text-sm ${plannerStore.plannedFilters.isAllActions ? 'tw-text-gray-400' : 'tw-text-gray-900'}`}>Назначенные мне</label>
                </div>
                <div className='tw-mt-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2 tw-border-b  tw-pb-4 tw-border-gray-300'>
                    <input
                        id="by-me"
                        type='checkbox'
                        className={`tw-block tw-rounded-sm tw-border
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                tw-ring-gray-400
                            `}
                        checked={plannerStore.plannedFilters.isAssignedByMe}
                        onChange={handleByMeFilterChange}
                        disabled={plannerStore.plannedFilters.isAllActions}
                    />
                    <label htmlFor="by-me" className={`tw-text-sm ${plannerStore.plannedFilters.isAllActions ? 'tw-text-gray-400' : 'tw-text-gray-900'}`}>Назначенные мной</label>
                </div>
                <div className='tw-mt-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                    <input
                        id="completed"
                        type='checkbox'
                        className={`tw-block tw-rounded-sm tw-border
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                tw-ring-gray-400
                            `}
                        checked={plannerStore.plannedFilters.isDoneActionShow}
                        onChange={handleDoneFilterChange}
                    />
                    <label htmlFor="completed" className='tw-text-sm tw-text-gray-900'>Выполненные</label>
                </div>
                <div className='tw-mt-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                    <input
                        id="expired"
                        type='checkbox'
                        className={`tw-block tw-rounded-sm tw-border
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                tw-ring-gray-400
                            `}
                        checked={plannerStore.plannedFilters.isOutdatedActionShow}
                        onChange={handleOutdatedFilterChange}
                    />
                    <label htmlFor="expired" className='tw-text-sm tw-text-gray-900'>Просроченные</label>
                </div>
                <div className='tw-mt-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                    <input
                        id="planned"
                        type='checkbox'
                        className={`tw-block tw-rounded-sm tw-border
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                tw-ring-gray-400
                            `}
                        checked={plannerStore.plannedFilters.isPlannedActionShow}
                        onChange={handlePlannedFilterChange}
                    />
                    <label htmlFor="planned" className='tw-text-sm tw-text-gray-900'>Запланированные</label>
                </div>
                <div className='tw-mt-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                    <input
                        id="recommended"
                        type='checkbox'
                        className={`tw-block tw-rounded-sm tw-border
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                tw-ring-gray-400
                            `}
                        checked={plannerStore.plannedFilters.isMyRecommendedActionShow}
                        onChange={handleRecommendedFilterChange}
                    />
                    <label htmlFor="recommended" className='tw-text-sm tw-text-gray-900'>На базе рекомендуемых</label>
                </div>
                { plannerStore.selectedTab === 'actions' &&
                    <div className='tw-w-full tw-mt-2 tw-flex tw-flex-col'>
                        <label className={`tw-text-sm ${isDateFilterOff ? 'tw-text-gray-300' : 'tw-text-gray-900'}`}>Период:</label>
                        <div className='tw-w-full tw-mt-0.5 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                            <label className={`tw-w-8 tw-text-sm ${isDateFilterOff ? 'tw-text-gray-300' : 'tw-text-gray-900'}`}>с</label>
                            <ReactDatePicker
                                className={`tw-w-40 tw-px-2 tw-py-1 tw-mt-1 tw-text-sm tw-border tw-rounded-md
                                    tw-text-gray-900 tw-border-gray-400 disabled:tw-text-gray-300 disabled:tw-border-gray-300
                                    focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white 
                                    focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-offset-gray-400
                                `}
                                calendarStartDay={1}
                                dateFormat='dd.MM.yyyy'
                                timeFormat='HH:mm:ss'
                                locale='ru'
                                timeCaption='Время'
                                showTimeSelect={false}
                                selected={plannerStore.plannedFilters.fromDate}
                                onChange={handleFromDateChange}
                                disabled={isDateFilterOff}
                            />
                        </div>
                        <div className='tw-w-full tw-mt-0.5 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                            <label className={`tw-w-8 tw-text-sm ${isDateFilterOff ? 'tw-text-gray-300' : 'tw-text-gray-900'}`}>по</label>
                            <ReactDatePicker
                                className={`tw-w-40 tw-px-2 tw-py-1 tw-mt-1 tw-text-sm tw-border tw-rounded-md
                                    tw-text-gray-900 tw-border-gray-400 disabled:tw-text-gray-300 disabled:tw-border-gray-300
                                    focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white 
                                    focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-offset-gray-400
                                `}
                                calendarStartDay={1}
                                dateFormat='dd.MM.yyyy'
                                timeFormat='HH:mm:ss'
                                locale='ru'
                                timeCaption='Время'
                                showTimeSelect={false}
                                selected={plannerStore.plannedFilters.tillDate}
                                onChange={handleTillDateChange}
                                disabled={isDateFilterOff}
                            />
                        </div>
                    </div>
                }
                <button
                    className='tw-w-full tw-my-4 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold
                            tw-border-gray-600  tw-bg-gray-600 tw-text-white hover:tw-bg-gray-500 hover:tw-border-gray-500
                            disabled:tw-bg-gray-300 disabled:tw-border-gray-300
                            focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    disabled={!isNewFilter}
                    onClick={handleApplyFilterClick}
                >
                    Применить
                </button>
            </div>
        </div>
    )
}

export default observer(PlannerFilter)