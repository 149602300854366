import React, { useEffect, Fragment, useContext } from 'react'
import { Context } from '../../../../..'
import PageTitle from '../../common/panels/PageTitle'
import Spinner from '../../../../../assets/Spinner'
import EducationPDFViewer from './document/EducationPDFViewer'
import EducationVideoPlayer from './video/EducationVideoPlayer'
import { Dialog, Transition } from '@headlessui/react'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'


/**
 * Компонент реализует логику отображения содержимого файла, прикрепленного к материалу обучения
 * 
 * @param {Boolean} isOpen Признак видимости (отображения) модального окна
 * @param {Function} onCloseClick Обработчик клика мыши для закрытия окна
 * 
 */
const EducationViewFileContainer = ({isOpen, onCloseClick}) => {
    const { EducationStore, userStore } = useContext(Context)

    const handleAreaClick = () => {}
   
    useEffect(() => {
        if (isOpen) {
            if (EducationStore.materialsStage.material.system_data.files && EducationStore.materialsStage.material.system_data.files.length) {
                EducationStore.getMaterialFile(EducationStore.materialsStage.material.system_data.files[0])    
            } else if (EducationStore.materialsStage.material.data['material_video_link'].value && EducationStore.materialsStage.material.data['material_video_link'].value !== '') {
                EducationStore.getMaterialLink(EducationStore.materialsStage.material.data['material_video_link'].value)
            } else {
                toast.error('Отсутствует файл с обучающим материалом!', { position: toast.POSITION.TOP_CENTER, autoClose: 1500 }) 
            }
            EducationStore.getMaterialProgress("data['date']", true, userStore.user.id)
        } else {
            EducationStore.setIsFileLoading(true)
            EducationStore.setViewedFile(null)
            EducationStore.setSavedMaterialProgress(null)
        }
    }, [isOpen])

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={handleAreaClick}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-500"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-500"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                    <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                        <div className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex  tw-pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                enterFrom="tw-translate-x-full"
                                enterTo="tw-translate-x-0"
                                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                leaveFrom="tw-translate-x-0"
                                leaveTo="tw-translate-x-full"
                            >
                                <Dialog.Panel  className="tw-pointer-events-auto tw-relative tw-w-[90vw]"> 
                                    <Transition.Child
                                        as={Fragment}
                                        enter="tw-ease-in-out duration-500"
                                        enterFrom="tw-opacity-0"
                                        enterTo="tw-opacity-100"
                                        leave="tw-ease-in-out tw-duration-500"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                    >
                                        <div className="tw-absolute tw-left-0 tw-top-0 tw--ml-8 tw-flex tw-pr-2 tw-pt-4 sm:tw--ml-10 sm:tw-pr-4">
                                        </div>
                                    </Transition.Child>
                                    <div id='file-panel' className="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto tw-bg-gray-200 tw-p-4 tw-shadow-xl">
                                        <div id='file-column' className='tw-h-full tw-overflow-hidden tw-rounded-md tw-bg-white tw-w-full'>
                                            {EducationStore.isFileLoading 
                                                ?   <Spinner/> 
                                                :   <>
                                                        { EducationStore.materialType === 'pdf' &&
                                                            <EducationPDFViewer
                                                                onCloseClick={onCloseClick}
                                                            />
                                                        }
                                                        { ['mp4', 'stream'].includes(EducationStore.materialType)  &&
                                                            <EducationVideoPlayer
                                                                onCloseClick={onCloseClick}
                                                            />
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default observer(EducationViewFileContainer)