/**
 * Метод осуществляет получение значения разрешения для записи
 * @method
 * 
 * @param {Object} item Запись
 * @param {String} method Метод
 */
export function checkMethodPermissions (item, method) {
    const itemMethod = item?.methods.find(item => item.type === method)
    const isAvailable = itemMethod?.available
    let description = ''
    
    if (!isAvailable) {
        itemMethod?.unavailable_scopes.forEach(item => {
            if (item === 'subgroup_write')
                description += 'Принадлежит другому юр. лицу'
        })
    }

    return [isAvailable, description]
}

/**
 * Метод осуществляет получение значения разрешения для таблицы
 * @method
 * 
 * @param {Object} dataModel Таблица
 * @param {String} right Обозначение конкретного разрешения
 */
export function checkDataModelPermission (dataModel, right) {
    let isAvailable = false
    switch (right) {
        case 'read':
            isAvailable = dataModel?.rights?.read?.right
            
            if (isAvailable)
                return [isAvailable, '']
            else
                return [isAvailable, 'Отсутствует право на просмотр данной таблицы']
        case 'write': 
            isAvailable = dataModel?.rights?.write?.right

            if (isAvailable)
                return [isAvailable, '']
            else
                return [isAvailable, 'Отсутствует право на редактирование записей данной таблицы']
        case 'edit': 
            isAvailable = dataModel?.rights?.edit?.right
            
            if (isAvailable)
                return [isAvailable, '']
            else
                return [isAvailable, 'Отсутствует право на редактирование данной таблицы']
        default: return [false, '']
    }
}