import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import PageTitle from '../../common/panels/PageTitle'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import DataTypeListBox from '../../common/inputs/DataTypeListBox'
import { protocolTypes } from '../../../../../config/constsImport'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает форму для настройки подключения к серверу домена
 * 
 * @param {Object[]} protocolTypeList Список поддерживаемых протоколов, по которым возможен импорт данных
 * @param {Object} defaultProtocolType Элемент по умолчанию в списке протоколов
 * @param {Object[]} domainControllerList Список протоколов, по которым возможен импорт данных
 * @param {Object} defaultProtocolType Элемент по умолчанию в списке протоколов
 * @param {Function} onSubmitClick Обработчик клика мыши на кнопке подключения к серверу данных 
 * 
 */
const ImportDataForm = ({protocolTypeList, defaultProtocolType, 
                        domainControllerList, defaultDomainController,
                        onSubmitClick}) => {
    const {
        register,
        watch,
        setValue,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm()

    register('protocolType', { value: defaultProtocolType, required: true, validate: value => value.value !== ''})
    register('domainController', { value: defaultDomainController, required: true, validate: value => value.value !== ''})
    // register('dataType', { value: defaultDataType, required: true, validate: value => value.value !== ''})
    register('dataType', { value: { name: 'Пользователи', value: 'users', hidden: false }, required: true, validate: value => value.value !== ''})

    const { userStore } = useContext(Context)
    const selectedProtocolType = watch('protocolType', defaultProtocolType)
    const selectedDomainController = watch('domainController', defaultDomainController)
    // const selectedDataType = watch('dataType', defaultDataType)

    isSubmitting && !isValid &&  toast.error('Заполните обязательные поля!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   

    return (
        <div id='input-form' className='tw-flex tw-flex-col tw-h-full tw-w-full'>
            <PageTitle title={'Импорт данных'} />
            <div className='tw-bg-white tw-rounded-md tw-p-4'>
                <p className='tw-text-sm tw-mt-1 tw-ml-2'>
                    Безопасность важна для нас, поэтому сервис не хранит аутентификационные данные на постоянной основе, а получает доступ только на одну сессию.
                </p>
                <p className='tw-text-sm tw-mt-1 tw-ml-2'>
                    {`Сервис может работать со следующими протоколами: ${protocolTypes.filter(item => !item.hidden).map(item => item.name).join(',')}`}
                </p>
                <form onSubmit={handleSubmit(onSubmitClick)}>
                    <div className='tw-grid tw-gap-x-8 tw-grid-cols-2 tw-py-6 tw-border-b'>
                        <div>
                            <div className='tw-flex tw-flex-row tw-w-full tw-items-center tw-mt-4'>
                                <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-700 tw-leading-6'>URL</label>
                                {errors.URL && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                            </div>
                            <input 
                                className={`tw-relative tw-block tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                    tw-ring-1 tw-ring-inset placeholder:tw-text-gray-400 focus:tw-z-10 focus:tw-ring-2 
                                    focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6 focus-visible:tw-outline-none
                                    ${errors.URL ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                                `}
                                {...register('URL', { required: true })}
                            />
                        </div>
                        <div>
                            <div className='tw-flex tw-flex-row tw-w-full tw-items-center tw-mt-4'>
                                <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-700 tw-leading-6'>Домен</label>
                                {errors.domainAddress && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                            </div>
                            <input 
                                className={`tw-relative tw-block tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                    tw-ring-1 tw-ring-inset placeholder:tw-text-gray-400 focus:tw-z-10 focus:tw-ring-2 
                                    focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6 focus-visible:tw-outline-none
                                    ${errors.domainAddress ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                                `}
                                {...register('domainAddress', { required: true })}
                            />
                        </div>
                        <DataTypeListBox
                            label={'Протокол'}
                            itemList={protocolTypeList}
                            selectedItem={selectedProtocolType}
                            onItemChange={(e) => setValue('protocolType', e)}
                            error={errors.protocolType}
                            selector={'value'}
                        />
                        <DataTypeListBox
                            label={'Контроллер домена'}
                            itemList={domainControllerList}
                            selectedItem={selectedDomainController}
                            onItemChange={(e) => setValue('domainController', e)}
                            error={errors.domainController}
                            selector={'value'}
                        />
                        {/* <DataTypeListBox
                            label={'Тип данных'}
                            itemList={dataTypeList}
                            selectedItem={selectedDataType}
                            onItemChange={(e) => setValue('dataType', e)}
                            error={errors.dataType}
                            selector={'value'}
                        /> */}
                    </div>
                    <div className='tw-grid tw-gap-x-8 tw-grid-cols-2 tw-py-6'>
                        <div>
                            <div className='tw-flex tw-flex-row tw-w-full tw-items-center'>
                                <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-700 tw-leading-6'>Логин</label>
                                {errors.userName && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                            </div>
                            <input 
                                className={`tw-relative tw-block tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                    tw-ring-1 tw-ring-inset placeholder:tw-text-gray-400 focus:tw-z-10 focus:tw-ring-2 
                                    focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6 focus-visible:tw-outline-none
                                    ${errors.userName ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                                `}
                                {...register('userName', { required: true })}
                            />
                        </div>
                        <div>
                            <div className='tw-flex tw-flex-row tw-w-full tw-items-center'>
                                <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-700 tw-leading-6'>Пароль</label>
                                {errors.password && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                            </div>
                            <input
                                className={`tw-relative tw-block tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                    tw-ring-1 tw-ring-inset placeholder:tw-text-gray-400 focus:tw-z-10 focus:tw-ring-2 
                                    focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6 focus-visible:tw-outline-none
                                    ${errors.password ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                                `}
                                type='password'
                                {...register('password', { required: true })}
                            />
                        </div>
                    </div>
                    <div className='tw-flex tw-flex-row tw-justify-center'>
                        <button 
                            className='tw-flex tw-justify-center tw-rounded-md tw-bg-gray-700 tw-mx-auto tw-my-2 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold
                                        tw-text-white hover:tw-bg-gray-500 disabled:tw-bg-gray-300 disabled:tw-border-gray-300
                                        focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                            disabled={!userStore.checkPermission("import-template:import")}
                            type='submit'
                        >
                            Запросить данные
                        </button>
                    </div>
                </form>
            </div>
        </div>
)
}

export default observer(ImportDataForm)