import DocumentService from "../services/DocumentService"
import { showErrorToast } from "./errorHandlers"
import { toast } from "react-toastify"
import { serviceMessageTimeOut } from "../config/constTypes"

// Функция получает последнюю по дате создания запись для таблицы
const getLastRecord = async (dataModel) => {
    try {
        const filter = JSON.stringify([
            {property: 'data_model_id', value: dataModel, operator: 'eq'},
            {property: 'active', value: true, operator: 'eq'}
        ])
        const sorter = JSON.stringify([
            {property: 'created', desc: true},
        ])
        // загрузка последней записи активных процессов
        const lastRecord = await DocumentService.getLastDataObject(filter, sorter, 'w')
        if (lastRecord)
            return lastRecord

    } catch (error) {
        showErrorToast(error, 'fetching', '')
    }

    return null
}

// Функция проверяет активность пользователя по модулю посредством обнаружения последней сохраненной записи (для "Категорирования КИИ", "Обработки и защиты ПДн")
export async function checkActiveModule(modules, link) {
    try {
        const foundModule = modules.find(module => module.link === link)
        const savedRecord = await getLastRecord(foundModule.dataModel[0])
        if (savedRecord) {
            const savedObjects = await DocumentService.getNestedDataObjects(50, [], JSON.stringify([{property: 'created', desc: true}]), savedRecord.id, savedRecord.data[foundModule.objectField].rule_id)
            return {link: link, data: savedRecord, objects: savedObjects.length}
        }
    } catch (error) {
        console.log(error)
        toast.error(<div>Некорректная конфигурация для Вашего профиля!<br /><br />
                    Повторите попытку обновления конфигурации.<br />
                    При повторной неудаче обратитесь в службу поддержки Медоед</div>, 
                    { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
    }
    return null
}

// Функция проверяет активность пользователя по модулю "Повышения осведеомленности" посредством обнаружения последней сохраненной записи (просмотр, тест)
export async function checkActiveEducationModule(modules, link) {
    const foundModule = modules.find(module => module.link === link)
    const savedProgressRecord = await getLastRecord(foundModule.dataModel[0])
    const savedTestAttemptRecord = await getLastRecord(foundModule.dataModel[1])
    let savedRecord

    if (savedProgressRecord) {
        savedRecord = savedProgressRecord
        if (savedTestAttemptRecord && savedProgressRecord.created < savedTestAttemptRecord.created)
            savedRecord = savedTestAttemptRecord
    } else {
        if (savedTestAttemptRecord)
            savedRecord = savedTestAttemptRecord
    }

    if (savedRecord) {
        return {link: link, data: savedRecord, objects: 0}
    }

    return null
}

