//Функция форматирует HTML строки для удобного вывода на экран
export function htmlFormatter(html){
    const tabSize = 4;
    let result = "";
    let indentLevel = 0;
    let currentIndent = "";

    let lines = html.replace(/(><)|(>\n<)/g, ">,<")       
    lines = lines.split(",")
        
    for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();

        if (line.startsWith("</")) {
            indentLevel--;
            if(indentLevel < 0) indentLevel = 0
            currentIndent = " ".repeat(indentLevel * tabSize);
        }
           
        result += currentIndent + line + "\n";

        if (line.startsWith("<") && line.endsWith(">") && !line.includes("</") && !line.includes("<meta")) {
            indentLevel++;
        }
           
        currentIndent = " ".repeat(indentLevel * tabSize);
    }

    return result.trim();
}