import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../../../../..'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react-lite'
import { showErrorToast } from '../../../../../../functions/errorHandlers'
import { responseTimeOut, serviceMessageTimeOut } from '../../../../../../config/constTypes'
import parse from 'html-react-parser'
import DocumentService from '../../../../../../services/DocumentService'
import ItemListMenu from './menu/ItemListMenu'
import SimpleItemList from './list/SimpleItemList'
import SimpleItemForm from './form/SimpleItemForm'
import SimpleEmployeeForm from './form/SimpleEmployeeForm'
import DialogTab from '../../../../../dialog_tab/DialogTab'


/**
 * Визуальный компонент отображает форму заполнения данных о сотрудниках, должностях, подразделениях
 * 
 */
const EmployeesDataForm = () => {   
    const { DialogTabStore } = useContext(Context)

    const [employees, setEmployees] = useState(null)
    const [positions, setPositions] = useState(null)
    const [subdivisions, setSubdivisions] = useState(null)
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const [selectedPosition, setSelectedPosition] = useState(null)
    const [selectedSubdivision, setSelectedSubdivision] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedField, setSelectedField] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [isAddEmployee, setIsAddEmployee] = useState(false)
    const [isEditEmployee, setIsEditEmployee] = useState(false)
    const [isAddPosition, setIsAddPosition] = useState(false)
    const [isEditPosition, setIsEditPosition] = useState(false)
    const [isAddSubdivision, setIsAddSubdivision] = useState(false)
    const [isEditSubdivision, setIsEditSubdivision] = useState(false)
    const [deletingItem, setDeletingItem] = useState(null)
    const [deletingItemModel, setDeletingItemModel] = useState(null)

    const divisionDescription = 'Например, "Департамент информационных технологий"'
    const positionDescription = 'Например, "Старший системный администратор"'

    const getDataObjectsByID = (dataModelID, setDataObjects) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setDataObjects([])
        }, responseTimeOut)

        const filter = JSON.stringify([
            {property: 'data_model_id', value: dataModelID, operator: 'eq'},
            {property: 'transaction_id', value: null, operator: 'eq'},
            {property: 'system_data.deletion_mark', value: [false, null], operator: 'in'},
            {property: 'active', value: true, operator: 'eq'}
        ])            
        const sorter = JSON.stringify([
            {property: 'data.name', desc: false},
        ])

        DocumentService
            .getAllDataObjects(50, filter, sorter)
            .then(data => {
                clearTimeout(noResponse)
                if (dataModelID === 'employees')
                    setDataObjects(data.map(employee => { 
                        return {
                            ...employee,
                            linkPosition: employee.data['employee_position__employees'].value && employee.data['employee_position__employees'].value.values.length
                                            ?   employee.data['employee_position__employees'].value.values[0].record_id
                                            :   '',
                            linkSubdivision: employee.data['employee_subdivision__employees'].value && employee.data['employee_subdivision__employees'].value.values.length
                                            ?   employee.data['employee_subdivision__employees'].value.values[0].record_id
                                            :   ''
                        }
                    }))
                else
                    setDataObjects(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                setDataObjects([])
            })
    }

    const saveRecord = async (dataModelID, formData, isEditMode, selectedDataObject, setDataObjects, setSelectedDataObject) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        const dataObject = {}
        dataObject.data_model_id = dataModelID
        dataObject.data = {}
        if (dataModelID === 'positions') {
            dataObject.data['name'] = formData.positionName
        }
        if (dataModelID === 'subdivisions') {
            dataObject.data['name'] = formData.subdivisionName
        }
        if (dataModelID === 'employees') {
            dataObject.data['name'] = formData.employeeName
            dataObject.data['employee_last_name__employees'] = formData.lastName
            dataObject.data['employee_first_name__employees'] = formData.firstName
            dataObject.data['employee_surname__employees'] = formData.secondName
            dataObject.data['employee_position__employees'] = formData.position !== '0' ? [formData.position] : null
            dataObject.data['employee_subdivision__employees'] = formData.subdivision !== '0' ? [formData.subdivision] : null
            dataObject.data['num_phone__employees'] = formData.phoneNumber && formData.phoneNumber.trim() !== '' ? formData.phoneNumber.trim() : null
            dataObject.data['email_employee__employees'] = formData.emailAddress && formData.emailAddress.trim() !== '' ? formData.emailAddress.trim() : null
        }

        try {
            if (isEditMode) {
                await DocumentService.updateDataObject(selectedDataObject.record_id, dataObject)
            } else {
                await DocumentService.createDataObject(dataObject)
            }
            clearTimeout(noResponse)
            toast.success('Информация успешно сохранена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })

            getDataObjectsByID(dataModelID, setDataObjects)

        } catch (error) {
            clearTimeout(noResponse)
            showErrorToast(error, 'saving', '')
        }
    }

    const handleDeleteObjectClick = async () => {
        DialogTabStore.setDialogTabIsOpen(false) 

        if (deletingItem && deletingItemModel) {
            const noResponse = setTimeout(() => {
                toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            }, responseTimeOut)
    
            DocumentService
                .deleteDataObject(deletingItem.record_id, {system_data: {deletion_mark: true}})
                .then(() => {
                    clearTimeout(noResponse)
                    switch (deletingItemModel) {
                        case 'employees':
                            getDataObjectsByID(deletingItemModel, setEmployees)                            
                            break;
                        case 'positions':
                            getDataObjectsByID(deletingItemModel, setPositions)                            
                            break;
                        case 'subdivisions':
                            getDataObjectsByID(deletingItemModel, setSubdivisions)                            
                            break;                                
                        default:
                            break;
                    }   
                })
                .catch(error => {
                    clearTimeout(noResponse)
                    showErrorToast(error, 'deleting', '')
                })
    
        } else {
            toast.error(<div>Не выбрана запись для удаления! <br/></div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }

    const handleAddEmployeeClick = () => {
        setIsAddEmployee(true)
        setIsEditEmployee(false)
        setIsEditing(true)
    }

    const handleEditEmployeeClick = () => {
        setIsAddEmployee(false)
        setIsEditEmployee(true)
        setIsEditing(true)
    }

    const handleDeleteEmployeeClick = (item) => {
        setDeletingItem(item)
        setDeletingItemModel('employees')
        DialogTabStore.setParentName('EmployeesDataForm')
        DialogTabStore.setDialogTabTitle('Удаление сотрудника') 
        DialogTabStore.setDialogTabText(parse(`Вы уверены, что хотите удалить сотрудника<br/>"${item.data['name'].value}"?`))
        DialogTabStore.setDialogTabButtons(["Да", "Нет"])
        DialogTabStore.setDialogTabIsOpen(true) 
    }

    const handleSubmitEmployeeClick = (form) => {
        saveRecord('employees', form, isEditEmployee, selectedEmployee, setEmployees)
        setIsAddEmployee(false)
        setIsEditEmployee(false)
        setIsEditing(false)
    }

    const handleCancelEmployeeClick = () => {
        setIsAddEmployee(false)
        setIsEditEmployee(false)
        setIsEditing(false)
    }

    const handleAddPositionClick = () => {
        setIsAddPosition(true)
        setIsEditPosition(false)
        setIsEditing(true)
    }

    const handleEditPositionClick = () => {
        setIsAddPosition(false)
        setIsEditPosition(true)
        setIsEditing(true)
    }

    const handleDeletePositionClick = (item) => {
        setDeletingItem(item)
        setDeletingItemModel('positions')
        DialogTabStore.setParentName('EmployeesDataForm')
        DialogTabStore.setDialogTabTitle('Удаление должности') 
        DialogTabStore.setDialogTabText(parse(`Вы уверены, что хотите удалить должность<br/>"${item.data['name'].value}"?`))
        DialogTabStore.setDialogTabButtons(["Да", "Нет"])
        DialogTabStore.setDialogTabIsOpen(true) 
    }

    const handleSubmitPositionClick = (form) => {
        saveRecord('positions', form, isEditPosition, selectedPosition, setPositions)
        setIsAddPosition(false)
        setIsEditPosition(false)
        setIsEditing(false)
    }

    const handleCancelPositionClick = () => {
        setIsAddPosition(false)
        setIsEditPosition(false)
        setIsEditing(false)
    }

    const handleAddSubdivisionClick = () => {
        setIsAddSubdivision(true)
        setIsEditSubdivision(false)
        setIsEditing(true)
    }

    const handleEditSubdivisionClick = () => {
        setIsAddSubdivision(false)
        setIsEditSubdivision(true)
        setIsEditing(true)
    }

    const handleDeleteSubdivisionClick = (item) => {
        setDeletingItem(item)
        setDeletingItemModel('subdivisions')
        DialogTabStore.setParentName('EmployeesDataForm')
        DialogTabStore.setDialogTabTitle('Удаление подразделения') 
        DialogTabStore.setDialogTabText(parse(`Вы уверены, что хотите удалить подразделение<br/>"${item.data['name'].value}"?`))
        DialogTabStore.setDialogTabButtons(["Да", "Нет"])
        DialogTabStore.setDialogTabIsOpen(true) 
    }

    const handleSubmitSubdivisionClick = (form) => {
        saveRecord('subdivisions', form, isEditSubdivision, selectedSubdivision, setSubdivisions)
        setIsAddSubdivision(false)
        setIsEditSubdivision(false)
        setIsEditing(false)
    }

    const handleCancelSubdivisionClick = () => {
        setIsAddSubdivision(false)
        setIsEditSubdivision(false)
        setIsEditing(false)
    }

    const handleEmployeeClick = (item) => {
        if (!isEditing) {
            setSelectedEmployee(item)
            setSelectedItem(null)
            setSelectedSubdivision(null)
            setSelectedPosition(null)   
        }
    }

    const handleEmployeeLinkClick = () => {

    }

    const handlePositionClick = (item) => {
        if (!isEditing) {
            setSelectedPosition(item)
            setSelectedItem(item)
            setSelectedEmployee(null)
            setSelectedSubdivision(null)
            setSelectedField('linkPosition')
        }
    }

    const handleSubdivisionClick = (item) => {
        if (!isEditing) {
            setSelectedSubdivision(item)
            setSelectedItem(item)
            setSelectedEmployee(null)
            setSelectedPosition(null)
            setSelectedField('linkSubdivision')
        }
    }

    useEffect(() => {
        getDataObjectsByID('employees', setEmployees)
        getDataObjectsByID('positions', setPositions)
        getDataObjectsByID('subdivisions', setSubdivisions)
    }, [])

    useEffect(() => {
        if (selectedEmployee) {
            const foundEmployee = employees.find(item => item.record_id === selectedEmployee.record_id)
            if (foundEmployee)
                setSelectedEmployee(foundEmployee)
            else
                setSelectedEmployee(null)
        }
    }, [employees])

    useEffect(() => {
        if (selectedPosition) {
            const foundPosition = positions.find(item => item.record_id === selectedPosition.record_id)
            if (foundPosition)
                setSelectedPosition(foundPosition)
            else
                setSelectedPosition(null)
        }
    }, [positions])

    useEffect(() => {
        if (selectedSubdivision) {
            const foundSubdivision = subdivisions.find(item => item.record_id === selectedSubdivision.record_id)
            if (foundSubdivision)
                setSelectedSubdivision(foundSubdivision)
            else
                setSelectedSubdivision(null)
        }
    }, [subdivisions])

    return (
        <>
            <div className='tw-grid tw-grid-cols-3 tw-gap-x-2 tw-h-full tw-overflow-hidden'>
                <div id='subdivision-column' className='tw-col-span-1 tw-h-full tw-overflow-hidden tw-pl-2'>
                    <ItemListMenu
                        label='Подразделения'
                        description={divisionDescription}
                        activeItem={selectedSubdivision}
                        isDisabled={isEditing}
                        onAddItemClick={handleAddSubdivisionClick}
                        onEditItemClick={handleEditSubdivisionClick}
                        onDeleteItemClick={handleDeleteSubdivisionClick}
                    />
                    <div  id='subdivision-list' className='tw-flex tw-flex-col tw-w-full tw-h-[calc(100%_-_3rem)] tw-overflow-hidden'>
                        {(isAddSubdivision || isEditSubdivision) &&
                            <SimpleItemForm
                                fieldName='subdivisionName'
                                selectedItem={selectedSubdivision}
                                isEditMode={isEditSubdivision}
                                onSubmitClick={handleSubmitSubdivisionClick}
                                onCancelClick={handleCancelSubdivisionClick}
                            />
                        }
                        {subdivisions && !isEditing &&
                            <SimpleItemList
                                list={subdivisions}
                                selectedItem={selectedSubdivision}
                                linkingItem={selectedEmployee}
                                linkField='linkSubdivision'
                                onItemClick={handleSubdivisionClick}
                                onLinkClick={handleEmployeeLinkClick}
                            />
                        }
                    </div>
                </div>
                <div id='position-column' className='tw-col-span-1 tw-h-full tw-overflow-hidden tw-pl-2 tw-border-l'>
                    <ItemListMenu
                        label='Должности'
                        description={positionDescription}
                        activeItem={selectedPosition}
                        isDisabled={isEditing}
                        onAddItemClick={handleAddPositionClick}
                        onEditItemClick={handleEditPositionClick}
                        onDeleteItemClick={handleDeletePositionClick}
                    />
                    <div  id='position-list' className='tw-flex tw-flex-col tw-w-full tw-h-[calc(100%_-_3rem)] tw-overflow-hidden'>
                        {(isAddPosition || isEditPosition) &&
                            <SimpleItemForm
                                fieldName='positionName'
                                selectedItem={selectedPosition}
                                isEditMode={isEditPosition}
                                onSubmitClick={handleSubmitPositionClick}
                                onCancelClick={handleCancelPositionClick}
                            />
                        }
                        {positions && !isEditing &&
                            <SimpleItemList
                                list={positions}
                                selectedItem={selectedPosition}
                                linkingItem={selectedEmployee}
                                linkField='linkPosition'
                                onItemClick={handlePositionClick}
                                onLinkClick={handleEmployeeLinkClick}
                            />
                        }
                    </div>
                </div>
                <div id='employee-column' className='tw-col-span-1 tw-h-full tw-overflow-hidden tw-pl-2 tw-border-l'>
                    <ItemListMenu
                        label='Сотрудники'
                        description=''
                        activeItem={selectedEmployee}
                        isDisabled={isEditing}
                        onAddItemClick={handleAddEmployeeClick}
                        onEditItemClick={handleEditEmployeeClick}
                        onDeleteItemClick={handleDeleteEmployeeClick}
                    />
                    <div  id='employee-list' className='tw-flex tw-flex-col tw-w-full tw-h-[calc(100%_-_3rem)] tw-overflow-hidden'>
                        {(isAddEmployee || isEditEmployee) &&
                            <SimpleEmployeeForm
                                employee={selectedEmployee}
                                isEditMode={isEditEmployee}
                                positions={positions}
                                subdivisions={subdivisions}
                                onSubmitClick={handleSubmitEmployeeClick}
                                onCancelClick={handleCancelEmployeeClick}
                            />
                        }
                        {employees && !isEditing &&
                            <SimpleItemList
                                list={employees}
                                selectedItem={selectedEmployee}
                                linkingItem={selectedItem}
                                linkField={selectedField}
                                onItemClick={handleEmployeeClick}
                                onLinkClick={handleEmployeeLinkClick}
                            />
                        }
                    </div>
                </div>
            </div>
            <DialogTab
                parentName='EmployeesDataForm'
                dialogTabFunction={handleDeleteObjectClick}
            />
        </>                                                             
    )
}

export default observer(EmployeesDataForm)