import React, { useContext, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Context } from '../../../../index'
import DataModelCategoryList from './list/DataModelCategoryList'
import DataModelInfo from './info/DataModelInfo'
import DataModelFieldInfo from './info/DataModelFieldInfo'
import { paths } from '../../../../config/constsURN'
import Spinner from '../../../../assets/Spinner'
import { observer } from 'mobx-react-lite'
import PageTitle from '../common/panels/PageTitle'
import DataModelMenu from './menu/DataModelMenu'
import { scrollElementIntoView } from '../../../../functions/scrollElementIntoView'
import { getIdFromPath } from '../../../../functions/getIdFromPath'

/**
 * Компонент реализует логику работы со структурами таблиц (моделью данных)
 * 
 */
const DataModelListContainer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { docStore } = useContext(Context)
    
    const [activeDataModel, setActiveDataModel] = useState(null)
    const [selectedField, setSelectedField] = useState(null)

    const handleDataModelClick = (item) => {
        if (activeDataModel?.id !== item.id) {
            navigate(paths.DATAMODEL_ROUTE + '/' + item.id)
        }
    }

    const handleDataModelSelect = (item) => {
        if (item) {
            sessionStorage.setItem('activeDataModel', JSON.stringify(item))
            docStore.setSelectedDataModel(item)
            setActiveDataModel(item)
            setSelectedField(null)
            scrollElementIntoView(item, '')
        }
    }

    const selectSavedDataModel = (dataModels) => {
        const pathDataModelID = getIdFromPath(location.pathname, 2)
        const savedDataModel = JSON.parse(sessionStorage.getItem('activeDataModel'))
        const selectedModelID = pathDataModelID || savedDataModel?.id

        if (selectedModelID) {
            const foundDataModel = dataModels.find(item => item.id === selectedModelID)
            if (foundDataModel) {
                docStore.setSelectedDataModel(foundDataModel)
                setActiveDataModel(foundDataModel)
            } else {
                sessionStorage.removeItem('activeDataModel')
            }
        }
    }

    const handleAddDataModelClick = () => {
        navigate(paths.ADD_DATAMODEL_ROUTE)
    }

    const handleEditDataModelClick = () => {
        navigate(paths.EDIT_DATAMODEL_ROUTE + '/' + activeDataModel.id)
    }

    const handleHideDataModelClick = (dataModel) => {
        docStore.hideDataModel(dataModel, selectSavedDataModel)
    }

    useEffect(() => {
        docStore.setShownTypes(['document', 'directory', 'nested'])
        docStore.setIsShowInfo(true)

        const defaultFilter = []
        const categorySorter = JSON.stringify([ {property: 'order', desc: false} ])      
        docStore.getCategories(defaultFilter, categorySorter)

        const dataModelSorter = JSON.stringify([ {property: 'entity_name', desc: false} ])        
        docStore.getDataModels(defaultFilter, dataModelSorter, selectSavedDataModel)
    }, [])

    useEffect(() => {
        if (docStore.dataModels && docStore.dataModels.length) {
            const dataModelID = getIdFromPath(location.pathname, 2)
            if (dataModelID) {
                const foundDataModel = docStore.dataModels.find(dataModel => dataModel.id === dataModelID)
                handleDataModelSelect(foundDataModel)
            } else {
                docStore.setSelectedDataModel(null)
                setActiveDataModel(null)
                setSelectedField(null)
                sessionStorage.removeItem('activeDataModel')
            }
        }
    }, [location.pathname, docStore.dataModels])

    if (docStore.isDataModelLoading) {
        return  <Spinner/>
    }

    return (
        <>
            <PageTitle title={'Настройка таблиц данных'} />
            <div id='data-model-page' className='tw-grid tw-grid-cols-7 tw-gap-x-8 tw-max-h-full tw-h-full'>
                <div id='data-model-list' className='tw-col-span-2 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <DataModelMenu 
                        activeItem={activeDataModel}
                        onAddItemClick={handleAddDataModelClick}
                        onEditItemClick={handleEditDataModelClick}
                        onHideItemClick={handleHideDataModelClick}
                    />
                    <DataModelCategoryList
                        selectedDataModel={docStore.selectedDataModel}
                        onItemClick={handleDataModelClick}
                        onItemDoubleClick={handleEditDataModelClick}
                    />
                </div>
                <div id='data-model-column' className='tw-col-span-3 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <div id='data-model-header' className='tw-h-12 tw-flex tw-items-center tw-border-b-2 tw-border-gray-400 tw-px-4 tw-py-2'>
                        <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Информация о таблице</p>
                    </div>
                    { activeDataModel &&
                        <DataModelInfo 
                            dataModel={activeDataModel}
                            selectedField={selectedField}
                            onFieldClick={setSelectedField}
                        />
                    }
                </div>
                <div id='data-model-field-column' className='tw-col-span-2 tw-h-full'>
                    { selectedField && 
                        <DataModelFieldInfo
                            field={selectedField}
                        />
                    }
                </div>
            </div>           
        </>
    )
}

export default observer(DataModelListContainer)