export const setSignificanceCategory = (indicator, categories) => {
    let category,
        result, 
        value = indicator.data.value_of_the_indicator__indicatorss.value

    switch(indicator.data.indicator__indicatorss.value.values[0].code) {
        case '01':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue >= 1 && categoryValue <= 50)
                    category = 3
                else if(categoryValue > 50 && categoryValue <= 500)
                    category = 2
                else if(categoryValue > 500)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        case '02а':
        case '03а':
            if(value === 'в пределах территории одного муниципального образования (численностью от 2 тыс. человек) или одной внутригородской территории города федерального значения')
                category = 3
            else if(value === 'выход за пределы территории одного муниципального образования (численностью от 2 тыс. человек) или одной внутригородской территории города федерального значения, но не за пределы территории одного субъекта Российской Федерации или территории города федерального значения')
                category = 2
            else if(value === 'выход за пределы территории одного субъекта Российской Федерации или территории города федерального значения')
                category = 1
            else
                category = 4
            break
        case '02б':
        case '03б':
        case '11б':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue >= 2 && categoryValue < 1000)
                    category = 3
                else if(categoryValue >= 1000 && categoryValue < 5000)
                    category = 2
                else if(categoryValue >= 5000)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        case '04':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue >= 3 && categoryValue < 1000)
                    category = 3
                else if(categoryValue >= 1000 && categoryValue < 5000)
                    category = 2
                else if(categoryValue >= 5000)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        case '05а':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue > 12 && categoryValue <= 24)
                    category = 3
                else if(categoryValue > 6 && categoryValue <= 12)
                    category = 2
                else if(categoryValue <= 6)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        case '05б':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue <= 30)
                    category = 3
                else if(categoryValue > 30 && categoryValue <= 70)
                    category = 2
                else if(categoryValue > 70)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        case '06':
            if(value === 'прекращение или нарушение функционирования органа государственной власти субъекта Российской Федерации или города федерального значения')
                category = 3
            else if(value === 'прекращение или нарушение функционирования федерального органа государственной власти')
                category = 2
            else if(value === 'прекращение или нарушение функционирования Администрации Президента Российской Федерации, Правительства Российской Федерации, Федерального Собрания Российской Федерации, Совета Безопасности Российской Федерации, Верховного Суда Российской Федерации, Конституционного Суда Российской Федерации')
                category = 1
            else
                category = 4
            break
        case '07':
            if(value === 'нарушение условий договора межведомственного характера (срыв переговоров или подписания)')
                category = 3
            else if(value === 'нарушение условий межправительственного договора (срыв переговоров или подписания)')
                category = 2
            else if(value === 'нарушение условий межгосударственного договора (срыв переговоров или подписания)')
                category = 1
            else
                category = 4
            break
        case '08':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue >= 1 && categoryValue <= 10)
                    category = 3
                else if(categoryValue > 10 && categoryValue <= 20)
                    category = 2
                else if(categoryValue > 20)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        case '09':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue > 0.0003 && categoryValue <= 0.0006)
                    category = 3
                else if(categoryValue > 0.0006 && categoryValue <= 0.001)
                    category = 2
                else if(categoryValue > 0.001)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        case '10':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue <= 70)
                    category = 3
                else if(categoryValue > 70 && categoryValue <= 120)
                    category = 2
                else if(categoryValue > 120)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        case '10(1)':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue < 1)
                    category = 3
                else if(categoryValue >= 1 && categoryValue < 10)
                    category = 2
                else if(categoryValue >= 10)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        case '10(2)':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue < 10)
                    category = 3
                else if(categoryValue >= 10 && categoryValue < 25)
                    category = 2
                else if(categoryValue >= 25)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        case '10(3)':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue >= 50 && categoryValue < 1000)
                    category = 3
                else if(categoryValue >= 1000 && categoryValue < 2000)
                    category = 2
                else if(categoryValue >= 2000)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        case '10(4)':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue >= 100 && categoryValue < 1500)
                    category = 3
                else if(categoryValue >= 1500 && categoryValue < 5000)
                    category = 2
                else if(categoryValue >= 5000)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        case '10(5)':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue >= 25 && categoryValue < 100)
                    category = 3
                else if(categoryValue >= 100 && categoryValue < 150)
                    category = 2
                else if(categoryValue >= 150)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        case '11а':
            if(value === 'в пределах территории одного муниципального образования (численностью от 2 тыс. чел.) или одной внутригородской территории города федерального значения, с выходом вредных воздействий за пределы территории субъекта критической информационной инфраструктуры')
                category = 3
            else if(value === 'выход за пределы территории одного муниципального образования (численностью от 2 тыс. чел.) или одной внутригородской территории города федерального значения, но не за пределы территории одного субъекта Российской Федерации или территории города федерального значения, с выходом вредных воздействий за пределы территории субъекта критической информационной инфраструктуры')
                category = 2
            else if(value === 'выход за пределы территории одного субъекта Российской Федерации или территории города федерального значения, с выходом вредных воздействий за пределы территории субъекта критической информационной инфраструктуры')
                category = 1
            else
                category = 4
            break
        case '12':
            if(value === 'прекращение <1> или нарушение функционирования <2> пункта управления или ситуационного центра органа государственной власти субъекта Российской Федерации или города федерального значения')
                category = 3
            else if(value === 'прекращение <1> или нарушение функционирования <2> пункта управления или ситуационного центра федерального органа государственной власти или государственной корпорации')
                category = 2
            else if(value === 'прекращение или нарушение функционирования пункта управления государством или ситуационного центра Администрации Президента РФ, Правительства РФ, Федерального Собрания РФ, Совета Безопасности РФ, Верховного Суда РФ, Конституционного Суда РФ')
                category = 1
            else
                category = 4
            break
        case '13а':
        case '13б':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue > 0 && categoryValue <= 10)
                    category = 3
                else if(categoryValue > 10 && categoryValue <= 15)
                    category = 2
                else if(categoryValue > 15)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        case '14':
            if (/^(-|\+)?([0-9]+)?([.|,])?([0-9]+)$/.test(value)){
                let categoryValue = parseFloat(value.replace(/,/, '.'))

                if(categoryValue <= 4 && categoryValue > 2)
                    category = 3
                else if(categoryValue <= 2 && categoryValue > 1)
                    category = 2
                else if(categoryValue <= 1)
                    category = 1
                else
                    category = 4
            } else
                category = 4
            break
        default:
            return false
    }

    categories.forEach(item => {
        if(item.data.code_int__categories_of_significance.value === category)
            result = {
                format: item.format, 
                values: [
                    {
                        entity_name: item.entity_name,
                        id: item.id,
                        name: item.data.name.value,
                        record_id: item.record_id
                    }
                ]
            }
    })

    return result
}