import React from 'react'
import ObjectsListItem from './ObjectsListItem'
import { Tooltip } from 'react-tooltip'


/**
 * Визуальный компонент отображает список объектов
 * 
 * @param {Object[]} objectsList Список существующих объектов
 * @param {Object} objectsListStage Текущий активный объект
 * @param {Boolean} isDelete Признак возможности удаления объектов
 * @param {Function} onDeleteClick Обработчик клика мыши для удаления объектов
 * @param {Function} onJumpStageClick Обработчик клика мыши для перехода к произвольному объекту
 * @param {Boolean} isSearch Признак наличия строки поиска
 * @param {Boolean} isSelect Признак возможности перехода к произвольному объекту
 * 
 */
const ObjectsList = ({objectsList, objectsListStage, isDelete, onDeleteClick, onJumpStageClick, isSearch, isSelect}) => {
    return (
        <>
            <div  id='stage-list-items' className={`${isSearch ? 'tw-h-[calc(100%_-_6rem)]' : 'tw-h-[calc(100%_-_3rem)]' } tw-flex tw-flex-col tw-w-full tw-bg-white tw-overflow-auto`}>
                { objectsList.map((object, index) => 
                    <ObjectsListItem
                        key={index}
                        item={object}
                        stage={objectsListStage}
                        isDelete={isDelete}
                        onDeleteClick={onDeleteClick}
                        onJumpStageClick={onJumpStageClick}
                        isSelect={isSelect}
                    />
                )}
            </div>
            <Tooltip id="object-list-tooltip" place="top-start" className="tw-max-w-xl" style={{ zIndex: 99 }}/>
        </>
    )
}

export default ObjectsList