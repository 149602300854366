import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import AppLogo from './menu/AppLogo'
import AdminMenu from './menu/AdminMenu'
import UserMenu from './menu/UserMenu'
import ModuleMenu from './menu/ModuleMenu'
import ReportMenu from './menu/ReportMenu'
import SignInButton from './menu/SignInButton'
import EditDataMenu from './menu/EditDataMenu'


/**
 * Визуальный компонент отображает заголовок с логотипом, названием приложения и кнопкой авторизированного пользователя
 * 
 */
const Header = () => {
    const { userStore } = useContext(Context)
    
    useEffect(() => {
        if (userStore.configuration && userStore.configuration.status === 'in_progress' && !userStore.updateTimerID) {
            let timerID = setInterval(() => userStore.getUserConfiguration(false), 5000)
            userStore.setUpdateTimerID(timerID)
        }
    }, [userStore.configuration])

    return (
        <div id='header' className="tw-w-full tw-flex tw-flex-row tw-h-16 tw-items-center tw-justify-between tw-px-2 tw-bg-gray-800">
            <AppLogo/>
            { userStore.isAuth  
                ?
                    <div className='tw-flex tw-flex-row tw-items-center lg:tw-gap-8'>
                        <ModuleMenu/>
                        <ReportMenu/>
                        <EditDataMenu/>
                        <AdminMenu />
                        <UserMenu />
                    </div>
                :
                    <SignInButton/>
            }
        </div>
    )    
}

export default observer(Header)