import React, { useContext } from 'react'
import { DocumentDuplicateIcon, DocumentPlusIcon, ListBulletIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import DialogTab from '../../dialog_tab/DialogTab'
import parse from 'html-react-parser'
import { menuTooltipTimeOut } from '../../../config/constTypes'
import { useHotkeys } from 'react-hotkeys-hook'

/**
 * Визуальный компонент отображает меню возможных действий над юр лицом
 * 
 */
const RolesMenu = () => {
    const { RolesStore, DialogTabStore, userStore } = useContext(Context)

    const isRoleInitialState = !RolesStore.isRoleCreating && !RolesStore.isRoleEditing && !RolesStore.isUserCreating && !RolesStore.isUserEditing
    const isPermission = userStore.checkPermission("company.my.role:edit")

    useHotkeys('ctrl+a', () => {if (isRoleInitialState && isPermission) onAddItemClick()}, { preventDefault: true })
    useHotkeys('ctrl+d', () => {if (isRoleInitialState && isPermission && RolesStore.selectedRole) onDuplicateClick()}, { preventDefault: true })
    useHotkeys('ctrl+e', () => {if (isRoleInitialState && isPermission && RolesStore.selectedRole && !RolesStore.selectedRole.built_in) onEditItemClick()}, { preventDefault: true })
    useHotkeys('ctrl+r', () => {if (isRoleInitialState && isPermission && RolesStore.selectedRole && !RolesStore.selectedRole.built_in) onDeleteItemClick()}, { preventDefault: true })
    useHotkeys('ctrl+i', () => {if (isRoleInitialState && RolesStore.selectedRole) onViewInfoClick()}, { preventDefault: true })

    const onAddItemClick = () => {
        RolesStore.setIsRoleCreating(true)
        RolesStore.setSelectedUser(null)
        RolesStore.setIsRoleInfoOpen(false)
        RolesStore.setIsPermissionsEditing(true)
    }

    const onDuplicateClick = () => {
        RolesStore.setIsRoleCreating(true)
        RolesStore.setIsRoleDuplicating(true)
        RolesStore.setSelectedUser(null)
        RolesStore.setIsRoleInfoOpen(false)
        RolesStore.setIsPermissionsEditing(true)
    }

    const onEditItemClick = () => {
        RolesStore.setIsRoleEditing(true)
        RolesStore.setIsRoleDuplicating(false)
        RolesStore.setIsRoleInfoOpen(false)
        RolesStore.setIsPermissionsEditing(true)
    }

    const onViewInfoClick = () => {
        RolesStore.setIsRoleInfoOpen(!RolesStore.isRoleInfoOpen)
    }

    const onDeleteItemClick = () => {
        DialogTabStore.setParentName('RoleMenu')
        DialogTabStore.setDialogTabTitle("Удаление роли") 
        DialogTabStore.setDialogTabText(parse(`Пользователи, связанные с этой ролью, лишатся относящихся к ней прав<br/><br/>Вы уверены, что хотите удалить эту роль?`))
        DialogTabStore.setDialogTabButtons(["Да", "Нет"])
        DialogTabStore.setDialogTabIsOpen(true) 
    }

    const deleteRole = () => {
        RolesStore.setIsRoleInfoOpen(false)
        RolesStore.deleteRole()
        DialogTabStore.setDialogTabIsOpen(false) 
    }

    return (
        <div id='roles-menu' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
            <p className='tw-text-sm tw-font-semibold tw-w-24 md:tw-text-base sm:tw-w-96'>Роли</p>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onAddItemClick}
                    disabled={!isRoleInitialState || !isPermission}
                    data-tooltip-id="roles-menu-tooltip" data-tooltip-content="Создать роль &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+A)" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onDuplicateClick}
                    disabled={!isRoleInitialState || !isPermission || !RolesStore.selectedRole}
                    data-tooltip-id="roles-menu-tooltip" data-tooltip-content="Дублировать роль &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+D)" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <DocumentDuplicateIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onEditItemClick}
                    disabled={!isRoleInitialState || !isPermission || !RolesStore.selectedRole || RolesStore.selectedRole.built_in}
                    data-tooltip-id="roles-menu-tooltip" data-tooltip-content="Редактировать роль &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+E)" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className={`tw-rounded-md tw-p-1.5 tw-text-white ${RolesStore.isRoleInfoOpen ? 'tw-bg-red-600 hover:tw-bg-red-400' : 'tw-bg-gray-800 hover:tw-bg-gray-600'} disabled:tw-bg-gray-400`}
                    disabled={!isRoleInitialState || !RolesStore.selectedRole}
                    onClick={onViewInfoClick}
                    data-tooltip-id="roles-menu-tooltip" data-tooltip-content="Информация о роли &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+I)" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <ListBulletIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onDeleteItemClick}
                    disabled={!isRoleInitialState || !isPermission || !RolesStore.selectedRole || RolesStore.selectedRole.built_in}
                    data-tooltip-id="roles-menu-tooltip" data-tooltip-content="Удалить роль &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+R)" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <TrashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
            </div>
            <Tooltip id="roles-menu-tooltip" place="top"/>
            <DialogTab
                parentName='RoleMenu'
                dialogTabFunction={deleteRole}
            />
        </div>
    )
}

export default observer(RolesMenu)