import React, { useContext } from 'react'
import { DocumentPlusIcon, ListBulletIcon, PencilSquareIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { observer } from 'mobx-react-lite'
import { Context } from '../../..'

/**
 * Визуальный компонент отображает меню возможных действий над пользователями
 * 
 */
const RoleUserMenu = () => {
    const { RolesStore, userStore } = useContext(Context)

    const onEditItemClick = () => {
        RolesStore.setIsUserEditing(true) 
    }

    const onAddItemClick = () => {
        RolesStore.setIsUserCreating(true)
        RolesStore.setSelectedUser(null)
    }

    const onViewInfoClick = () => {
        RolesStore.setIsUserInfoOpen(!RolesStore.isUserInfoOpen)
    }

    return (
        <div id='role-user-menu' 
            className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center 
                        tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'
        >
            <p className='tw-text-xs tw-font-semibold tw-w-24 md:tw-text-base sm:tw-w-96'>Пользователи</p>
                <div className='tw-flex tw-flex-row tw-gap-x-1'>
                    <button 
                        className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                        onClick={onAddItemClick}
                        disabled={RolesStore.isUserCreating || RolesStore.isUserEditing || RolesStore.isRoleEditing || RolesStore.isRoleCreating || !userStore.checkPermission("company.my.user:edit")}
                        data-tooltip-id="role-user-menu-tooltip" data-tooltip-delay-show={1000}
                        data-tooltip-content={`${!userStore.checkPermission("company.my.user:edit") 
                            ? 'Для создания пользователей запросите повышение привилегий у администратора' 
                            : 'Создать пользователя'}`}
                    >
                        <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    </button>
                    <button 
                        className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                        onClick={() => onEditItemClick(RolesStore.selectedUser.id)}
                        disabled={!RolesStore.selectedUser || RolesStore.isUserCreating || RolesStore.isUserEditing || !userStore.checkPermission("company.my.user:edit")}
                        data-tooltip-id="role-user-menu-tooltip" data-tooltip-delay-show={1000}
                        data-tooltip-content={`${!userStore.checkPermission("company.my.user:edit") 
                            ? 'Для редактирования пользователей запросите повышение привилегий у администратора' 
                            : 'Редактировать пользователя'}`}
                    >
                        <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    </button>
                    <button
                        className={`tw-rounded-md tw-p-1.5 tw-text-white ${RolesStore.isUserInfoOpen ? 'tw-bg-red-600 hover:tw-bg-red-400' : 'tw-bg-gray-800 hover:tw-bg-gray-600'} disabled:tw-bg-gray-400`}
                        disabled={!RolesStore.selectedUser || RolesStore.isUserCreating || RolesStore.isUserEditing}
                        onClick={onViewInfoClick}
                        data-tooltip-id="role-user-menu-tooltip" data-tooltip-content="Информация о пользователе" data-tooltip-delay-show={1000}
                    >
                        <ListBulletIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    </button>
                </div>
            <Tooltip id="role-user-menu-tooltip" className="tw-max-w-sm" place="top"/>
        </div>
    )
}

export default observer(RoleUserMenu)