import React, { useState, useContext } from 'react'
import { Context } from '../../../..'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import AuthService from '../../../../services/AuthService'
import { paths } from '../../../../config/constsURN'
import { showErrorToast } from '../../../../functions/errorHandlers'

/**
 * Визуальный компонент отображает модулей для ввода логина и пароля на главной странице приложения
 *  
 */
const SignInModuleItem = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const navigate = useNavigate()
    const { userStore } = useContext(Context)

    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    const handleSubmitClick = async (form) => {
        try {
            const response = await AuthService.auth(form.login, form.password)
            localStorage.setItem('token', response.data.access)
            localStorage.setItem('refresh', response.data.refresh)

            const userData = await AuthService.getUser()
            localStorage.removeItem('isTokenExpired')
            userStore.setAuth(true)
            userStore.setUser(userData.data)
            userStore.getUserConfiguration(false)
            userStore.getMySubCompanies()
            userStore.getMyPermissions()
            userStore.checkModulesAvailable()

            navigate(paths.MAIN_ROUTE)
        } catch (error) {
            showErrorToast(error, 'sign_in', '')
        }
    }

    return (
        <div className={`tw-flex tw-flex-col tw-w-full sm:tw-h-60 tw-rounded-md tw-bg-white`}>
            <div className={`tw-text-sm tw-text-left tw-font-semibold tw-text-gray-800 tw-w-full tw-pl-4 tw-py-1 tw-rounded-t-md  tw-border-gray-400 tw-bg-gray-50 tw-border-b-2`}>
                Вход
            </div>
            <div className='tw-text-sm tw-text-left tw-px-4 tw-py-2 tw-overflow-auto'>
                <form onSubmit={handleSubmit(handleSubmitClick)}>
                    <div className='sm:tw-grid sm:tw-grid-cols-5 sm:tw-gap-x-4'>
                        <label htmlFor="login" className="tw-block tw-text-sm tw-font-semibold tw-mt-2 sm:tw-mt-4 tw-text-gray-900">Логин</label>
                        <div className='sm:tw-col-span-4'>
                            <input 
                                type='text'
                                id='login'
                                placeholder='Введите Ваш логин или email...'
                                className={`tw-relative tw-block tw-w-full tw-rounded-md tw-border-0 tw-mt-2 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                            tw-ring-1 tw-ring-inset placeholder:tw-text-gray-400 focus:tw-z-10 
                                            focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                            focus-visible:tw-outline-none focus-visible:tw-ring-2
                                            ${errors.login ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                        `}
                                {...register('login', { required: true })}
                            />
                        </div>
                        <label htmlFor="password"  className="tw-block tw-text-sm tw-font-semibold tw-mt-2 sm:tw-mt-6 tw-text-gray-900">Пароль</label>
                        <div className='tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm sm:tw-col-span-4'>
                            <input
                                type={isPasswordVisible ? 'text' : 'password'}
                                id = "password"
                                placeholder='Введите пароль...'
                                className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-mt-2 tw-py-1.5 tw-pl-2 tw-pr-20 tw-text-gray-900 
                                            tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                            focus-visible:tw-outline-none focus-visible:tw-ring-2
                                            ${errors.password ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                        `}
                                {...register('password', { required: true })}
                            />
                            <div 
                                className='tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-mt-2 tw-pr-2 hover:tw-cursor-pointer'
                                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                            >
                                { isPasswordVisible 
                                    ?   <EyeSlashIcon className="tw-h-5 tw-w-5 tw-text-gray-400"/>
                                    :   <EyeIcon className="tw-h-5 tw-w-5 tw-text-gray-400"/>
                                }
                            </div>
                        </div>
                        <div></div>
                        <button
                            className='sm:tw-col-span-4 tw-group tw-relative tw-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-gray-700 tw-mx-auto tw-mt-4 tw-px-3 
                                        tw-py-2 tw-text-sm tw-font-semibold tw-text-white hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 
                                        focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600 disabled:tw-bg-gray-300 disabled:tw-border-gray-300'
                            disabled={userStore.isLowResolution}
                            type='submit'
                        >
                            Войти
                        </button>
                    </div>
                </form>
                <p className='tw-text-sm tw-font-medium tw-text-gray-400 tw-text-right tw-mt-2 '>
                    Ещё нет учетной записи?
                    <a className='tw-font-semibold tw-text-sky-700 hover:tw-text-sky-500 
                                    focus-visible:tw-outline-none focus-visible:tw-text-sky-500' 
                        href={paths.REGISTRATION_ROUTE} 
                    > Зарегистрироваться</a>
                </p>
            </div>
        </div>
    )
}

export default observer(SignInModuleItem)