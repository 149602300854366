import { makeAutoObservable, runInAction, toJS } from "mobx"
import { responseTimeOut, serviceMessageTimeOut } from "../config/constTypes"
import AuthService from "../services/AuthService"
import DocumentService from "../services/DocumentService"
import CategoryService from "../services/CategoryService"
import { toast } from "react-toastify"
import { showErrorToast } from "../functions/errorHandlers"

/**
 * Класс реализует хранилище информации о странице администрирования ролей пользователей
 * @class
 * @property {Object[]} roles Массив ролей
 * @property {Object} selectedRole Выбранная роль
 * @property {Boolean} isRoleCreating Создание роли
 * @property {Boolean} isRoleDuplicating Дублирование роли
 * @property {Boolean} isRoleEditing Редактирование роли
 * @property {Boolean} isLoading Загрузка
 * @property {Boolean} isPermissionsLoading Загрузка прав (разрешений)
 * @property {Boolean} isPermissionsEditing Редактирование информации о правах (разрешениях)
 * @property {Object[]} permissions Массив прав (разрешений) для роли или пользователя
 * @property {Boolean} isRoleLoading Загрузка роли
 * @property {Boolean} isDataModelLoading Загрузка списка таблиц
 * @property {Object[]} users  Массив пользователей
 * @property {Object} selectedUser Выбранная пользователь
 * @property {Boolean} isRoleInfoOpen Информации об открытой информации о роли
 * @property {Object[]} dataModelPermissions Массив прав (разрешений) для таблиц
 * @property {Number} permissionTabIndex Индекс выбранной вкладки с правами (разрешениями)
 * @property {Boolean} isUserInfoOpen Признак просмотра информации о пользователе
 * @property {Boolean} isUserCreating Создание пользователя
 * @property {Boolean} isUserEditing Редактирование информации о пользователе
 */
class RolesStore {
    roles = []
    selectedRole = null
    isRoleCreating = false
    isRoleDuplicating = false
    isRoleEditing = false
    isLoading = false
    isPermissionsLoading = false 
    isPermissionsEditing = false
    permissions = []
    isRoleLoading = false
    isDataModelLoading = false
    users = []
    selectedUser = null
    isRoleInfoOpen = false
    defaultDataModelPermissions = []
    dataModelPermissions = []
    permissionTabIndex = 0
    categories = []
    isCategoryOpened = []
    isUserInfoOpen = false
    isUserCreating = false
    isUserEditing = false
    
    /**
    * Конструктор с указанием, что все свойства класса observable
    * @constructor
    */
    constructor(){
        makeAutoObservable(this)
    }
     
    /**
     * Метод осуществляет сохранение информации о выбранной роли
     * @method
     * 
     * @param {Object} role Информация о выбранной роли
     */
     setSelectedRole(role) {
        this.selectedRole = role
    }
    
    /**
     * Метод осуществляет сохранение информации о создании роли
     * @method
     * 
     * @param {Boolean} value Информация о создании роли
     */
     setIsRoleCreating(value) {
        sessionStorage.removeItem('RoleForm')
        this.isRoleCreating = value
    }
    
    /**
     * Метод осуществляет сохранение информации о дублировании роли
     * @method
     * 
     * @param {Boolean} value Информация о дублировании роли
     */
    setIsRoleDuplicating(value) {
        sessionStorage.removeItem('RoleForm')
        this.isRoleDuplicating = value
    }
    

    /**
     * Метод осуществляет сохранение информации о редактировании роли
     * @method
     * 
     * @param {Boolean} value Информация о редактировании роли
     */
     setIsRoleEditing(value) {
        sessionStorage.removeItem('RoleForm')
        this.isRoleEditing = value
    }
    
    /**
     * Метод осуществляет сохранение информации о загрузке
     * @method
     * 
     * @param {Boolean} bool Информация о загрузке
     */
    setIsLoading(bool) {
        this.isLoading = bool
    }
    
    /**
     * Метод осуществляет сохранение признака загрузки информации о роли
     * @method
     * 
     * @param {Boolean} bool Признак загрузки информации о роли
     */
    setIsRoleLoading(bool) {
        this.isRoleLoading = bool
    }

    /**
     * Метод осуществляет сохранение признака загрузки информации о таблицах
     * @method
     * 
     * @param {Boolean} bool знак загрузки информации о таблицах
     */
    setIsDataModelLoading(bool) {
        this.isDataModelLoading = bool
    }

    /**
     * Метод осуществляет сохранение информации о загрузке разрешений
     * @method
     * 
     * @param {Boolean} value Информация о загрузке разрешений
     */
    setIsPermissionsLoading(value) {
        this.isPermissionsLoading = value
    }
    
    /**
     * Метод осуществляет сохранение информации о редактировании разрешений
     * @method
     * 
     * @param {Boolean} value Информация о редактировании разрешений
     */
     setIsPermissionsEditing(value) {
        this.isPermissionsEditing = value
    }

     /**
     * Метод осуществляет сохранение разрешений
     * @method
     * 
     * @param {Number[]} ids ID разрешений
     */
     setPermissions(ids) {
        this.permissions = ids
    }

   /**
     * Метод осуществляет сохранение информации о выбранном пользователе
     * @method
     * 
     * @param {Object} user Информация о выбранном пользователе
     */
    setSelectedUser(user) {
        this.selectedUser = user
    }
    
    /**
     * Метод осуществляет сохранение информации об открытой информации о роли
     * @method
     * 
     * @param {Boolean} bool Информация об открытой информации о роли
     */
    setIsRoleInfoOpen(bool) {
        this.isRoleInfoOpen=bool
    }

     /**
     * Метод осуществляет сохранение разрешений
     * @method
     * 
     * @param {Object[]} permissions ID разрешений
     */
     setDefaultDataModelPermissions(permissions) {
        this.defaultDataModelPermissions = permissions
    }

     /**
     * Метод осуществляет сохранение отображаемых на форме разрешений
     * @method
     * 
     * @param {Object[]} permissions ID разрешений
     */
     setDataModelPermissions(permissions) {
        this.dataModelPermissions = permissions
    }

     /**
     * Метод осуществляет сохранение индекса открытой вкладки разрешений
     * @method
     * 
     * @param {Number} index Индекс вкладки
     */
     setPermissionTabIndex(index) {
        this.permissionTabIndex = index
    }

     /**
     * Метод осуществляет сохранение категорий таблиц
     * @method
     * 
     * @param {Object[]} categories ID разрешений
     */
     setCategories(categories) {
        this.categories = categories
    }

     /**
     * Метод осуществляет сохранение признака раскрытия каждой категории таблиц
     * @method
     * 
     * @param {Boolean[]} isOpened ID разрешений
     */
     setIsCategoryOpened(isOpened) {
        this.isCategoryOpened = isOpened
    }

    /**
     * Метод осуществляет сохранение признака просмотра информации о пользователе
     * @method
     * 
     * @param {Boolean} isOpen Признак просмотра информации о пользователе
     */
    setIsUserInfoOpen(isOpen) {
        this.isUserInfoOpen = isOpen
    }

    /**
     * Метод осуществляет сохранение информации о создании пользователя
     * @method
     * 
     * @param {Boolean} value Информация о создании пользователя
     */
    setIsUserCreating(value) {
        sessionStorage.removeItem('userForm')
        this.isUserCreating = value
    }

     /**
     * Метод осуществляет сохранение информации о редактировании пользователя
     * @method
     * 
     * @param {Boolean} value Информация о редактировании пользователя
     */
     setIsUserEditing(value) {
        sessionStorage.removeItem('userForm')
        this.isUserEditing = value
    }

    /**
     * Метод осуществляет получение массива ролей
     * @method
     */
    async getAllRoles() {
        this.setIsLoading(true)
        
        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .getRoles()
            .then(data => {
                clearTimeout(noResponse)
                runInAction(() => {
                    this.setIsLoading(false)
                    this.roles=data.data
                    if (this.selectedRole) {
                        let selectedRole = data.data.filter((item) => item.id === this.selectedRole.id)
                        this.selectedRole = selectedRole[0]
                    }
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                this.setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет получение таблиц
     * @method
     * 
     * @param {Object[]} filter Примененные фильтры
     * @param {Object[]} sorter Примененные сортировки
     */
    getAllDataModels(filter, sorter) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setDataModelPermissions([])
            this.setDefaultDataModelPermissions([])
        }, responseTimeOut)

        DocumentService
            .getDataModels(filter, sorter)
            .then(data => {
                this.setDataModelPermissions(data)
                const defaultData = data.map(item => item)
                this.setDefaultDataModelPermissions(defaultData)
            })
            .catch(error => {
                this.setDataModelPermissions([])
                this.setDefaultDataModelPermissions([])
                showErrorToast(error, 'fetching', '')
            })
            .finally(() => {
                clearTimeout(noResponse)
            })
    }

    /**
     * Метод осуществляет получение массива разрешений для таблиц для определенной роли пользователей
     * @method
     * 
     * @param {Number} id ID роли
     */
    getRoleDataModelPermissions(id) {
        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        this.setIsPermissionsLoading(true)

        AuthService
            .getRoleDataModelPermissions(id)
            .then(data => {
                const dataModelPermissions = this.defaultDataModelPermissions.map(dataModel => {
                    const foundPermission = data.find(item => item.resource.id === dataModel.id)
                    return  foundPermission
                                ?   {...dataModel,
                                        edited_rights: {
                                            read:   { right: foundPermission.permission.rights.read.right },
                                            write:  { right: foundPermission.permission.rights.write.right },
                                            edit:   { right: foundPermission.permission.rights.edit.right }
                                        }
                                    }
                                :   dataModel
                })
                this.setDataModelPermissions(dataModelPermissions)

            })
            .catch(error => {
                showErrorToast(error, 'fetching', '')                
            })
            .finally(() => {
                clearTimeout(noResponse)
                this.setIsPermissionsLoading(false)
            })
    }

    /**
     * Метод осуществляет получение массива разрешений для таблиц для определенной роли пользователей
     * @method
     * 
     * @param {Number} id ID роли
     */
    getUserDataModelPermissions(id) {
        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        this.setIsPermissionsLoading(true)

        AuthService
            .getUserDataModelPermissions(id)
            .then(data => {
                const clearedDataModelPermissions = this.dataModelPermissions.map(dataModel => (
                    {...dataModel,
                        edited_rights: null
                    }
                ))
                const dataModelPermissions = clearedDataModelPermissions.map(dataModel => {
                    const foundPermission = data.find(item => item.resource.id === dataModel.id)
                    return  foundPermission
                                ?   {...dataModel,
                                        edited_rights: {
                                            read:   { right: foundPermission.permission.rights.read.right },
                                            write:  { right: foundPermission.permission.rights.write.right },
                                            edit:   { right: foundPermission.permission.rights.edit.right }
                                        }
                                    }
                                :   dataModel
                })
                this.setDataModelPermissions(dataModelPermissions)

            })
            .catch(error => {
                showErrorToast(error, 'fetching', '')                
            })
            .finally(() => {
                clearTimeout(noResponse)
                this.setIsPermissionsLoading(false)
            })
    }
    /**
     * Метод осуществляет получение значения разрешения для таблицы
     * @method
     * 
     * @param {Object} dataModel Таблица
     * @param {String} right Обозначение конкретного разрешения
     */
    checkDataModelRights (dataModel, right) {
        if (dataModel.edited_rights) {
            switch (right) {
                case 'read': return dataModel.edited_rights.read.right
                case 'write': return dataModel.edited_rights.write.right
                case 'edit': return dataModel.edited_rights.edit.right
                default: return false
            }
        } else {
            switch (right) {
                case 'read': return dataModel.default_rights.read.right
                case 'write': return dataModel.default_rights.write.right
                case 'edit': return dataModel.default_rights.edit.right
                default: return false
            }
        }
    }

    /**
     * Метод осуществляет изменение значения разрешения для таблицы
     * @method
     * 
     * @param {String} dataModelID ID таблицы
     * @param {String} right Обозначение конкретного разрешения
     */
    setDataModelRights (dataModelID, right) {
        const editedPermissions = this.dataModelPermissions.map(dataModel => (
            dataModel.id === dataModelID
                ?   dataModel.edited_rights
                        ?   {...dataModel,
                                edited_rights: {
                                    read:   {right: right === 'read' ? !dataModel.edited_rights.read.right : dataModel.edited_rights.read.right},
                                    write:  {right: right === 'write' ? !dataModel.edited_rights.write.right : dataModel.edited_rights.write.right},
                                    edit:   {right: right === 'edit' ? !dataModel.edited_rights.edit.right : dataModel.edited_rights.edit.right}
                                }
                            }
                        :   {...dataModel,
                                edited_rights: {
                                    read:   {right: right === 'read' ? !dataModel.default_rights.read.right : dataModel.default_rights.read.right},
                                    write:  {right: right === 'write' ? !dataModel.default_rights.write.right : dataModel.default_rights.write.right},
                                    edit:   {right: right === 'edit' ? !dataModel.default_rights.edit.right : dataModel.default_rights.edit.right}
                                }
                            }
                :   dataModel
        ))
        this.setDataModelPermissions(editedPermissions)
    }

    /**
     * Метод осуществляет изменение значения разрешения для всех таблиц определенной категории
     * @method
     * 
     * @param {Object[]} categoryDataModels Массив таблиц определенной категории
     * @param {String} right Обозначение конкретного разрешения
     */
    setCategoryRights (categoryDataModels, right, value) {
        const editedPermissions = this.dataModelPermissions.map(dataModel => {
            const isThisCategory = categoryDataModels.find(categoryDataModel => categoryDataModel.id === dataModel.id)
            return isThisCategory
                ?   dataModel.edited_rights
                        ?   {...dataModel,
                                edited_rights: {
                                    read:   {right: right === 'read' ? value : dataModel.edited_rights.read.right},
                                    write:  {right: right === 'write' ? value : dataModel.edited_rights.write.right},
                                    edit:   {right: right === 'edit' ? value : dataModel.edited_rights.edit.right}
                                }
                            }
                        :   {...dataModel,
                                edited_rights: {
                                    read:   {right: right === 'read' ? value : dataModel.default_rights.read.right},
                                    write:  {right: right === 'write' ? value : dataModel.default_rights.write.right},
                                    edit:   {right: right === 'edit' ? value : dataModel.default_rights.edit.right}
                                }
                            }
                :   dataModel
        })
        this.setDataModelPermissions(editedPermissions)
    }

    /**
     * Метод осуществляет привязку и отвязку пользователя к роли
     * @method
     * @param {Number} id ID роли
     * @param {Object} users Пользователи
     */
    async rebindRole (id, users) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .rebindRole(id, users)
            .then(() => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                this.getRole(id)
                if (this.selectedUser)
                    this.getUserRoles(this.selectedUser.id)
            })
            .catch(error => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                showErrorToast(error, 'saving', '')
            })
    }

    /**
     * Метод осуществляет удаление роли
     * @method
     */
    async deleteRole () {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .deleteRole(this.selectedRole.id)
            .then(() => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                this.getAllRoles()
            })
            .catch(error => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                showErrorToast(error, 'saving', '')
            })
    }
    
    /**
     * Метод осуществляет создание роли
     * @method
     * @param {Object} role Роль
     */
    async createRole (role) {
        this.setIsRoleLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsRoleLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        try {
            const createdRole = await AuthService.createRole(role)
            const updatedRole = this.updateRolePermissions(createdRole.data)
            await AuthService.editRole(updatedRole, createdRole.data.id)
            const updatedDataModelPermissions = this.updateDataModelPermissions()
            await AuthService.createRoleDataModelPermissions(updatedDataModelPermissions, createdRole.data.tech_id)

            toast.success('Роль успешно создана', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setIsRoleCreating(false)
            this.setIsRoleDuplicating(false)
            this.setIsPermissionsEditing(false)
            this.setSelectedRole(null)
            this.getAllRoles()

        } catch (error) {
            if (error?.response?.status === 409) {
                toast.error(<div>Ошибка при создании роли!<br/><br/>Такая роль уже существует</div>, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            } else {
                showErrorToast(error, 'saving', '')
            }
        
        } finally {
            clearTimeout(noResponse)
            this.setIsRoleLoading(false)
        }
    }

    /**
     * Метод осуществляет редактирование роли
     * @method
     * @param {Object} role Роль
     */
    async editRole (role) {
        this.setIsRoleLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsRoleLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        try {
            const updatedRole = this.updateRolePermissions(role)
            await AuthService.editRole(updatedRole, this.selectedRole.id)
            const updatedDataModelPermissions = this.updateDataModelPermissions()
            await AuthService.createRoleDataModelPermissions(updatedDataModelPermissions, this.selectedRole.tech_id)

            toast.success('Информация о роли изменена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setIsRoleEditing(false)
            this.setIsPermissionsEditing(false)
            this.getRole(this.selectedRole.id)
            this.getAllRoles()

        } catch (error) {
            if (error?.response?.status === 409) {
                toast.error(<div>Ошибка при сохранении роли!<br/><br/>Такая роль уже существует</div>, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            } else {
                showErrorToast(error, 'saving', '')
            }
        
        } finally {
            clearTimeout(noResponse)
            this.setIsRoleLoading(false)
        }
    }

    /**
     * Метод осуществляет получение массива разрешений
     * @method
     */
    async getAllPermissions () {
        this.setIsLoading(true)
        
        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .getPermissions()
            .then(data => {
                this.setPermissions(data.data)
            })
            .catch(error => {
                showErrorToast(error, 'fetching', '')
            })
            .finally(() => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
            })
    }
    
    /**
     * Метод осуществляет получение массива разрешений пользователя
     * @method
     * @param {Number} id ID пользователя
     */
    async getUserPermissions (id) {        
        const noResponse = setTimeout(() => {
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .getMyCompanyUserPermissions(id)
            .then(data => {
                clearTimeout(noResponse)
                this.setPermissions(this.permissions.map(permission => (
                    {...permission, isTrue: data.data.some(item => item.id === permission.id)}
                )))
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
    }

    /**
     * Метод осуществляет получение массива ролей для пользователя
     * @method
     * @param {Number} id ID пользователя
     */
     async getUserRoles (id) {        
        const noResponse = setTimeout(() => {
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .getMyCompanyUserRoles(id)
            .then(data => {
                clearTimeout(noResponse)
                runInAction(() => {
                    this.roles = this.roles.map(role => {
                        if (data.data.find(item => item.id === role.id))
                            return {...role, isAssigned: true}

                        return {...role, isAssigned: false}
                    })
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
    }

    /**
     * Метод осуществляет получение массива пользователей для роли
     * @method
     * @param {Number} id ID роли
     */
    async getRole(id) {        
        const noResponse = setTimeout(() => {
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .getRoleById(id)
            .then(data => {
                clearTimeout(noResponse)
                runInAction(() => {
                    this.users = this.users.map(user => {
                        if (data.data.user_ids.find(item => item === user.id))
                            return {...user, isAssigned: true}

                        return {...user, isAssigned: false}
                    })

                    this.permissions = this.permissions.map(permission => {
                        if (data.data.permission_ids.find(item => item === permission.id))
                            return {...permission, isTrue: true}
            
                        return {...permission, isTrue: false}
                    })
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
    }

     /**
     * Метод осуществляет получение массива пользователей собственной компании
     * @method
     */
     async getMyCompanyUsers() {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .getMyCompanyUsers()
            .then(data => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                runInAction(() => {
                    this.users = data.data
                    if(this.selectedUser){
                        let selectedUser = data.data.filter((item) => item.id === this.selectedUser.id)
                        this.setSelectedUser(selectedUser[0])
                    }
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                this.setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет формирование нового массива разрешений для роли
     * @method
     * @param {Number} id ID роли
     */
    updateRolePermissions(role) {
        const data = {}
        data.name = role.name
        data.description = role.description
        data.permissions = {}
        data.permissions.add = []
        data.permissions.remove = []

        this.permissions.forEach(permission => {
            if (permission.isTrue) {
                data.permissions.add.push(permission.id)
            } else {
                data.permissions.remove.push(permission.id) 
            }
        })

        return data
    }

    /**
     * Метод осуществляет формирование нового массива разрешений для таблиц
     * @method
     * @param {Number} id ID роли
     */
    updateDataModelPermissions() {
        const data = this.dataModelPermissions
            .filter(dataModel => dataModel.edited_rights)
            .map(dataModel => (
                {
                    resource: {
                        resource_type: "data_model",
                        tech_id: dataModel.id,
                    },
                    rights: dataModel.edited_rights
                }
            ))

        return data
    }

    /**
     * Метод осуществляет получение списка категорий
     * @method
     * 
     * @param {Object[]} filter Примененные фильтры
     * @param {Object[]} sorter Примененные сортировки
     */
    getCategories(filter, sorter) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        CategoryService
            .getCategories(50, filter, sorter)
            .then(data => {
                clearTimeout(noResponse)
                this.setCategories(data)
                const isOpened = data.map(item => false)
                isOpened.push(false)
                this.setIsCategoryOpened(isOpened)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
    }
 
    /**
     * Метод осуществляет редактирование пользователя
     * @method
     * 
     * @param {Object} user Информация о пользователе 
     * @param {Number} id ID пользователя 
     */
    async editUser (user, id) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .editMyCompanyUser(user, id)
            .then(() => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                this.setIsUserCreating(false)
                this.setIsUserEditing(false)
                toast.success('Информация о пользователе изменена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                this.getMyCompanyUsers()

            })
            .catch(error => {
                clearTimeout(noResponse)
                if (error?.response?.status === 409) {
                    toast.error(<div>Ошибка при изменении информации о пользователе!<br/><br/>Такой пользователь уже существует</div>, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
                this.setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет создание пользователя
     * @method
     * 
     * @param {Object} user Информация о пользователе 
     */
    async registerUser (user) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .registerMyCompanyUser(user)
            .then((data) => {
                clearTimeout(noResponse)
                this.setSelectedUser(data.data)
                this.setIsLoading(false)
                this.setIsUserCreating(false)
                this.setIsUserEditing(false)
                toast.success('Пользователь успешно создан', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                this.getMyCompanyUsers()
                
            })
            .catch(error => {
                clearTimeout(noResponse)
                if (error?.response?.status === 409) {
                    toast.error(<div>Ошибка при создании пользователя!<br/><br/>Такой пользователь уже существует</div>, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else if (error?.response?.data?.message === 'Exceeded the limit of allowed users in your company') {
                    toast.error(<div>Ошибка при создании пользователя!<br/><br/>К сожалению, вы достигли лимита пользователей в компании</div>, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
                this.setIsLoading(false)
            })
    }
}

export default RolesStore
