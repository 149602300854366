/**
 * Обработчики событий для файлов, прикрепляемых к записям таблиц
 * @module fileHandlers
 */
import { toast } from 'react-toastify'
import { maxFileSize, responseTimeOut, serviceMessageTimeOut, successMessageTimeOut } from '../config/constTypes'
import FileService from '../services/FileService'
import { errorHandler, showErrorToast } from './errorHandlers'
import ExporterService from '../services/ExporterService'

/**
 * Функция реализует логику скачивания файла
 * @method
 * 
 * @param {Object} blob Blob-ссылка
 * @param {String} fileName Название файла
 */
export const saveReceivedFile = (blob, fileName) => {
    // создание Blob-ссылки для скачивания файла с заданным именем
    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    // добавление ссылки к html-странице
    document.body.appendChild(link)
    // скачивание
    link.click()
    // очистка и удаление
    link.parentNode.removeChild(link)
}


/**
 * Функция реализует логику загрузки файлов
 * @method
 * 
 * @param {Object[]} files Массив файлов
 * @param {Function} setFiles Сохранение массива файлов
 * @param {Object} e Событие
 */
export  const handleFileSelect = (files, setFiles) => (e) => {
    const selectedFile = e.target.files[0]
    const sizeKB = selectedFile.size / 1024
    const sizeMB = sizeKB / 1024
    if (sizeMB < maxFileSize) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера файлов не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        const file = {}
        file.metadata = {
            name: selectedFile.name.slice(0, selectedFile.name.lastIndexOf('.')),
            extension: selectedFile.name.slice(selectedFile.name.lastIndexOf('.') + 1)
        }
        file.roundedSize = sizeMB > 1 ? sizeMB.toFixed(1) + ' МБ' : sizeKB.toFixed(1) + ' КБ'
        file.isLoading = true
        setFiles([...files, file])

        const formData = new FormData()
        formData.append('file', e.target.files[0])
        FileService
            .uploadFile(formData)
            .then(data => {
                clearTimeout(noResponse)
                toast.success('Файл успешно загружен', { position: toast.POSITION.TOP_CENTER, autoClose: 500 })
                file.isLoading = false
                file.id = data.id
                setFiles([...files, file])
            })
            .catch(error => {
                clearTimeout(noResponse)
                if (error.response.status === 413) {
                    toast.error(<div>Ошибка при загрузке файла!<br/><br/> Размер файла превышает допустимый лимит</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
                } else {
                    errorHandler(error.response, <div>Ошибка при загрузке файла!</div>)  
                }
                setFiles(files.filter(item => item.name !== file.name))
            })
    } else {
        toast.error(`Размер файла превышает ${maxFileSize} МБ!`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
    }
}

/**
 * Функция реализует логику удаления файлов
 * @method
 * 
 * @param {Object[]} files Массив файлов
 * @param {Function} setFiles Сохранение массива файлов
 * @param {Object} file Файл
 */
export const handleFileRemove = (files, setFiles) => (file) => {
    setFiles(files.filter(item => (item.metadata.name + item.metadata.extension) !== (file.metadata.name + file.metadata.extension)))
}

/**
 * Функция реализует логику скачивания файлов
 * @method
 * 
 * @param {Object} file Файл
 */
export const handleFileDownload = (file) => {
    const noResponse = setTimeout(() => {
        toast.error('Сервис менеджера файлов не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
    }, responseTimeOut)

    FileService
        .downloadFile(file.id)
        .then(blob => {
            clearTimeout(noResponse)
            saveReceivedFile(blob, (file.metadata.name + '.' + file.metadata.extension))
            toast.success('Файл успешно скачан', { position: toast.POSITION.TOP_CENTER, autoClose: 500 })
        })
        .catch(error => {
            clearTimeout(noResponse)
            errorHandler(error.response, <div>Ошибка при скачивании файла!</div>)
        })
}

/**
 * Функция реализует логику печати комплекта документов
 * @method
 * 
 * @param {String} type Тип комплекта документов
 * @param {Object} records Набор данных для печати
 * @param {Boolean} isPrinting Признак процесс печати
 */
export const printDocumentPacket = async (type, records, isPrinting) => {
    isPrinting(true)
    try {
        const [fileBlob, fileName] = await ExporterService.exportDataToZip(type, records)
        await saveReceivedFile(fileBlob, fileName)
        toast.success('Пакет документов успешно сформирован', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
    } catch (error){
        showErrorToast(error, 'printing', '')
    } finally {
        isPrinting(false)
    }
}

/** 
 * Метод реализует логику печати документа
 * @method
 * 
 * @param {String} template Название шаблона
 * @param {String} id ID записи
 * @param {Boolean} isPrinting Признак процесс печати
 */
export const printDocument = async (template, id, isPrinting) => {
    isPrinting(true)
    try {
        const [fileBlob, fileName] = await ExporterService.exportDataObjectToDocument(template, id)
        await saveReceivedFile(fileBlob, fileName)
        toast.success('Документ успешно сформирован!', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
    } catch (error){
        showErrorToast(error, 'printing', '')
    } finally {
        isPrinting(false)
    }
}