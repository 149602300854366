import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import ObjectsList from '../../../common/panels/object_list/ObjectsList'
import { Context } from '../../../../../..'
import StageButtons from '../../stage/StageButtons'
import Spinner from '../../../../../../assets/Spinner'
import CategorizingObjectsForm from './form/CategorizingObjectsForm'
import { toast } from 'react-toastify'
import { showErrorToast } from '../../../../../../functions/errorHandlers'


/**
 * Визуальный компонент отображает этап категорирования объектов КИИ
 * 
 */
const CategorizingObjectsContainer = () => {
    const { categorizingCIIStore } = useContext(Context)
    const [objectsList, setObjectsList] = useState([]) 
    const [objectsListStage, setObjectsListStage] = useState({}) 
    const [lastObjectSaved, setLastObjectSaved] = useState(false) 
    const [stageListWasHidden, setStageListWasHidden] = useState(false) 
    const [isLoading, setIsLoading] = useState(false)
 
    const handleBackClick = () => {
        categorizingCIIStore.goPrevStage()
        !stageListWasHidden && categorizingCIIStore.setHideStageList(false)
    }

    const handleForwardClick = async () => {
        setIsLoading(true)
        const removeArr = categorizingCIIStore.loadedActs.filter(item => item.status === 'deleted').map(item => { return {record_id: item.record_id_vt}})
        try {
            await categorizingCIIStore.updateProject('acts_of_categorization__stages_of_categorization', { upsert: [], remove: removeArr }, true)
            toast.success('Данные успешно сохранены!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })

            categorizingCIIStore.goNextStage()

            !stageListWasHidden && categorizingCIIStore.setHideStageList(false)
        } catch (error) {
            setIsLoading(false)
            showErrorToast(error, 'saving', '')
        }
    }

    const ObjectsListButtonsBackClick = (prevObjFinished) =>{
        markObjectAsFinished(prevObjFinished)
        setObjectsListStage(objectsList[objectsListStage.value - 1])
    }
    
    const ObjectsListButtonsForwardClick = () =>{
        markObjectAsFinished(true)
        setObjectsListStage(objectsList[objectsListStage.value + 1])
    }

    const markObjectAsFinished = (finished) =>{
        setObjectsList(objectsList.map(object => 
            object.value === objectsListStage.value
                ?   {...object, status: finished ? 'finished' : 'empty'}
                :   object
        ))
    }

    const onJumpStageClick = (object) => {
        if (object.status === 'finished') {
            setObjectsListStage(objectsList[object.value])
        }
    }

    useEffect(() => {
        let objList = []

        categorizingCIIStore.resourceList.dataObjects
            .filter(item => item.status !== 'deleted')
            .forEach((item, index) => {
                let isFinished = false

                const foundAct = categorizingCIIStore.objectsCategorization.find(object => {
                    return item.data['multiple_object__an_object_vt2'].value.values[0]?.record_id === object.record.data['object__act_of_categorir'].value.values[0].record_id
                })

                if (foundAct) {
                    if (foundAct.record.data['is_data_current__act_of_categorir'] && foundAct.record.data['is_data_current__act_of_categorir'].value)
                        isFinished = true
                }
                
                objList.push({...item.data['multiple_object__an_object_vt2'].value.values[0], value: index, status: isFinished ? 'finished' : 'empty', 
                    hidden: false,  help: 'Заполните и скачайте информацию об объекте'})
            })

        objList = objList.sort((a) => a.status === 'finished' ? -1 : 1).map((item, index) => {return {...item, value: index}})

        setObjectsList(objList)
        setObjectsListStage(objList[0])
        setStageListWasHidden(categorizingCIIStore.hideStageList)
        categorizingCIIStore.setHideStageList(true)
    }, [])

    return (
        <>
            <div className='tw-grow tw-overflow-auto'>
                <div id='categorizing-cii-page' className='tw-grid tw-grid-cols-4 tw-gap-x-8 tw-max-h-full tw-h-full'>
                    { objectsList.length > 0
                        ?
                            <>
                                <div id='stage-list' className='tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                                    <div id='stage-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                                        <p className='tw-text-md tw-font-semibold'>Объекты</p>
                                    </div>
                                    <ObjectsList 
                                        objectsList={objectsList}
                                        objectsListStage={objectsListStage}
                                        isDelete={false}
                                        onJumpStageClick={onJumpStageClick}
                                        isSearch={false}
                                        isSelect={true}
                                    />
                                </div>
                                <div id='stage-column' className='tw-col-span-3 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden tw-flex tw-flex-col'>
                                    <div id='stage-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                                        <p className='tw-text-md tw-font-semibold'>Объект "{objectsListStage.name}"</p>
                                    </div>
                                    <CategorizingObjectsForm 
                                        onBackClick={ObjectsListButtonsBackClick}
                                        onForwardClick={ObjectsListButtonsForwardClick}
                                        stage={objectsListStage}
                                        objectsList={objectsList}
                                        setLastObjectSaved={setLastObjectSaved}
                                    />
                                </div>
                            </>
                        :
                            <div className='tw-col-span-4'>
                                <Spinner />
                            </div>               
                    }
                </div>
            </div>
            <StageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleForwardClick}
                disabled={!lastObjectSaved || isLoading}
            />            
        </>
    )
}

export default observer(CategorizingObjectsContainer)