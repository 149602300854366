import React, { useContext, useEffect, useState } from 'react'
import { CheckCircleIcon, MinusIcon } from '@heroicons/react/20/solid'
import { Context } from '../../../..'
import { observer } from 'mobx-react-lite'
import { contentTooltipTimeOut } from '../../../../config/constTypes'

/**
 * Визуальный компонент отображает название одного пользователя для списка существующих пользователей
 * 
 * @param {Object} item Текущий элемент списка пользователей 
 * 
 */
const UsersListItem = ({item}) => {
    const [userAssigned, setUserAssigned] = useState(false)
    const { CompanyAdminStore, userStore } = useContext(Context)

    useEffect(()=>{
        if (CompanyAdminStore.selectedSubCompany) {
            if(item.allowed_sub_company_ids.find(item => item === CompanyAdminStore.selectedSubCompany.id)){
                setUserAssigned(true)
            } else {
                setUserAssigned(false)
            }
        }
    }, [CompanyAdminStore.selectedSubCompany])

    const onItemClick = (item) => {
        if (CompanyAdminStore.selectedUser?.id !== item.id) {
            CompanyAdminStore.setSelectedUser(item)
        }
    }

    const bindUser = () => {
        if (userStore.checkPermission("company.my:edit"))
            CompanyAdminStore.rebindMyUsers({users: {add: [item.id]}})
    }

    const unBindUser = () => {
        if (userStore.checkPermission("company.my:edit"))
            CompanyAdminStore.rebindMyUsers({users: {remove: [item.id]}})
    }

    return (
        <div className={`tw-relative tw-pl-12 tw-text-sm tw-select-none tw-border-b tw-py-2
                ${ !item.is_active
                        ?   'tw-line-through tw-text-red-400'
                        :   'tw-text-gray-900 tw-bg-white hover:tw-bg-gray-100 hover:tw-border-gray-300'} 
                `}
        >
            <div
                className={`tw-text-gray-500 tw-absolute tw-left-3 tw-top-4 ${userStore.checkPermission("company.my:edit") && 'hover:tw-cursor-pointer'}`}
                data-tooltip-id="users-list-tooltip" data-tooltip-content={userStore.checkPermission("company.my:edit") ? userAssigned  ? "Отвязать" : "Привязать" : ''} data-tooltip-delay-show={contentTooltipTimeOut}
            >
                { CompanyAdminStore.selectedSubCompany
                    ?   userAssigned 
                            ?   <CheckCircleIcon className="tw-w-5 tw-h-5 hover:tw-text-gray-500" onClick={unBindUser}/> 
                            :   <MinusIcon className="tw-w-5 tw-h-5 hover:tw-text-gray-500" onClick={bindUser}/>
                    :   null
                }
            </div>
            <div
                className={`tw-w-full tw-flex tw-flex-col tw-truncate ${CompanyAdminStore.selectedSubCompany && 'hover:tw-cursor-pointer'}`}
                data-tooltip-id="users-list-tooltip" data-tooltip-content={item.is_active  ? "" : "Пользователь заблокирован"} data-tooltip-delay-show={contentTooltipTimeOut}
                onClick={() => onItemClick(item)}
            >
                <p>
                    {item.first_name} {item.last_name}
                </p>
                <p className={`tw-text-xs`}>
                    {item.email}
                </p>
            </div>
        </div>
    )
}

export default observer(UsersListItem)
