import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../..'
import { observer } from 'mobx-react-lite'
import { ChevronDoubleUpIcon } from '@heroicons/react/20/solid'
import Spinner from '../../../../assets/Spinner'
import { Tooltip } from 'react-tooltip'
import CategoryPermissionsList from './CategoryPermissionsList'

/**
 * Визуальный компонент отображает информацию о разрешениях сущности  
 * 
 */
const DataModelPermissionsInfo = () => {
    const { RolesStore } = useContext(Context)
    const [isAllOpen, setIsAllOpen] = useState(false)

    const handleAllOpenedClick = () => {
        RolesStore.setIsCategoryOpened(RolesStore.isCategoryOpened.map(item => !isAllOpen))
        setIsAllOpen(!isAllOpen)
    }

    useEffect(() => {
        if (RolesStore.selectedUser)
            RolesStore.getUserPermissions(RolesStore.selectedUser.id)
    }, [RolesStore.selectedUser])

    if (RolesStore.isPermissionsLoading) 
        return <Spinner/>

    return (
        <div  id='data-model-list-items' className='tw-flex tw-flex-col tw-h-full tw-w-full tw-overflow-hidden'>
            <div className='tw-grid tw-grid-cols-6 tw-items-center tw-text-sm tw-font-semibold tw-w-full tw-h-12 tw-pr-4 tw-border-b tw-border-gray-300'>
                <div className='tw-col-span-3 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2 tw-pl-2'>
                    <span
                        className='tw-text-gray-600 hover:tw-text-gray-900 hover:tw-cursor-pointer'
                        onClick={handleAllOpenedClick}
                    >
                        <ChevronDoubleUpIcon className={`${isAllOpen ? 'tw-rotate-180 tw-transform' : ''} tw-h-5 tw-w-5`}/>
                    </span>
                    <span>Категории &gt; Таблицы</span>
                </div>
                <div className='tw-col-span-1 tw-text-center tw-min-w-32'>
                    Чтение
                </div>
                <div className='tw-col-span-1 tw-text-center tw-min-w-32'>
                    Запись
                </div>
                <div className='tw-col-span-1 tw-text-center tw-min-w-32'>
                    Редактирование
                </div>
            </div>
            <div className='tw-h-[calc(100%_-_3rem)] tw-overflow-auto'>
                <CategoryPermissionsList/>
            </div>
            <Tooltip id="datamodel-list-tooltip" place="top" style={{ zIndex: 99 }}/>
        </div>
    )
}

export default observer(DataModelPermissionsInfo)
