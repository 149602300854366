import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../..'
import { Tooltip } from 'react-tooltip'
import StageButtons from '../stage/StageButtons'
import { observer } from 'mobx-react-lite'
import Spinner from '../../../../../assets/Spinner'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import { printDocumentPacket } from '../../../../../functions/fileHandlers'
import { useForm } from 'react-hook-form'
import DocumentService from '../../../../../services/DocumentService'
import DialogTab from '../../../../dialog_tab/DialogTab'
import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../../../../config/constsURN'

/**
 * Визуальный компонент отображает этап печати сопроводительных документов
 * 
 */
const PrintActForm = () => {   
    const { categorizingCIIStore, plannerStore, userStore, DialogTabStore } = useContext(Context)
    const [isPrintingDocument, setIsPrintingDocument] = useState(false)
    const navigate = useNavigate()
    const {
        register,
        getValues
    } = useForm()

    const handlePrintDocument = async () => {
        try {
            const documents = {}
            documents.templates = {
                "fstek_cover_letter": categorizingCIIStore.linkedList.values[0].id,
                "fstek_registry": categorizingCIIStore.linkedList.values[0].id
            }

            // печать комплекта документов с перечнем под категорирование
            await printDocumentPacket('kii_fstek_result', documents, setIsPrintingDocument)
            
            // фиксация завершения текущего этапа
            categorizingCIIStore.setAllStages(categorizingCIIStore.allStages.map(stage => 
                stage.data['id_of_stage__guide_stages_of_categ'].value === categorizingCIIStore.actualStageIndex
                    ?   {...stage, status: 'finished'}
                    :   stage
            ))

            if (getValues('plan_action') && !categorizingCIIStore.project.data['planned_action']?.value?.values?.length) {
                setIsPrintingDocument(true)
                const myAction = {
                    data: {
                        action_description: '',
                        action_periodicity: 0,
                        name: "Пересмотр категорий значимости для объектов КИИ",
                        actionRecordID: "ffdc212d-8639-4fdb-993c-e1bfc16739fb"
                    },
                    data_model_id: "my_actions"
                }

                let date = new Date(Date.now())
                date = new Date(date).setDate(date.getDate() + 1825)

                const createdMyAction = await plannerStore.createMyAction(myAction)

                const createdDataObject = plannerStore.formatPlannedAction(createdMyAction, userStore.user, date, myAction.data.action_description, myAction.data.name, [])                      
                const response = await DocumentService.createDataObject(createdDataObject)
                await categorizingCIIStore.updateProject('planned_action', [response.record_id], true)
            }

            const isSignificant = categorizingCIIStore.loadedActs.find(item => item.status === "actual" && !item.record.data.signif_category__act_of_categorir.value.values[0].name.includes("Отсутствует"))

            if (isSignificant) {
                DialogTabStore.setParentName('PrintActForm')
                DialogTabStore.setDialogTabTitle("Перейти в Планировщик") 
                DialogTabStore.setDialogTabText(parse(`Среди объектов, прошедших категорирование, были обнаружены значимые объекты КИИ<br/><br/>
                    Предлагаем перейти в Планировщик, чтобы ознакомиться со списком эталонных действий, составленным нашими экспертами 
                    с учетом требований регуляторов для организаций со значимыми объектами&nbsp;КИИ`))
                DialogTabStore.setDialogTabButtons(["Перейти", "Остаться"])
                DialogTabStore.setDialogTabIsOpen(true)
                DialogTabStore.setAllowAreaCloseClick(false)
            }

            setIsPrintingDocument(false)
        } catch (error) {
            setIsPrintingDocument(false)
            showErrorToast(error, 'printing', '')
        }
    }

    const handleBackClick = () => {
        categorizingCIIStore.goPrevStage()
    }

    const handlePlannerRedirection = async () => {
        await plannerStore.getRecommendedFiltersList()
    }

    useEffect(() => {
        if (plannerStore.recommendedFilters.length) {
            const filters = {} 
            
            plannerStore.recommendedFilters.forEach(item => {
                if (item?.data?.name?.value === 'КИИ') {
                    filters[item.record_id] = true
                } else {
                    filters[item.record_id] = false
                }
            })

            localStorage.setItem('recommendedFilters', JSON.stringify(filters))
            navigate(paths.PLANNER_ROUTE)
        }
    }, [plannerStore.recommendedFilters])

    useEffect(() => {
        // фиксация завершения текущего этапа
        categorizingCIIStore.setAllStages(categorizingCIIStore.allStages.map(stage => 
            stage.data['id_of_stage__guide_stages_of_categ'].value === categorizingCIIStore.actualStageIndex
                ?   {...stage, status: 'finished'}
                :   stage
        ))
    }, [])

    return (
        <>
            { !isPrintingDocument &&
                <>
                    <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-mx-auto tw-py-2'>
                        <button 
                            className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                    hover:tw-bg-gray-600 hover:tw-border-gray-600 
                                    focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                            onClick={handlePrintDocument}
                        >
                            Скачать сопроводительные документы
                        </button>
                    </div>

                    <div className='tw-grow tw-overflow-auto'>
                        <div className='tw-px-4 tw-py-1 tw-mt-1'>
                            <p className='tw-text-md tw-my-2'>Для отправки результатов категорирования во ФСТЭК Вам необходимо:</p>
                            <ul className='tw-text-md tw-mt-1 tw-pl-8 tw-list-disc'>
                                <li>распечатать и подписать акты категорирования (форма №236 уже включена в акт);</li>
                                <li>распечатать и подписать реестр и сопроводительное письмо во ФСТЭК;</li>
                                <li>записать акты категорирования на компакт-диск в форматах odt и ods;</li>
                                <li>все вышеперечисленные объекты направить во ФСТЭК России курьером, спецсвязью или наручно в течении 10 рабочих дней.</li>
                            </ul>
                        </div>
                        
                        <div className='tw-px-4 tw-py-1 tw-mt-4 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                            <input
                                type='checkbox'
                                id = "plan_action"
                                className={`tw-block tw-rounded-sm tw-border
                                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset sm:tw-text-sm sm:tw-leading-6
                                        focus:tw-z-10 focus-visible:tw-outline-none focus-visible:tw-ring-2 tw-ring-gray-500`}
                                {...register('plan_action', { value: true })}
                            />
                            <label htmlFor='plan_action'>Запланировать пересмотр категорий значимости для объектов КИИ</label>
                        </div>
                    </div>
                </>                                                             
            }
            { isPrintingDocument &&
                <div className='tw-grow tw-overflow-auto'>
                    <div className='tw-px-4 tw-py-1 tw-mt-1'>
                        <p className='tw-mt-2 tw-text-center tw-text-sm tw-font-semibold'>
                            Выполняется скачивание сопроводительных документов к акту категорирования объектов КИИ...
                            <br></br>
                            Это может занять несколько минут
                        </p>
                        <Spinner/>
                    </div>
                </div>
            }
            <StageButtons
                onBackClick={handleBackClick}
                //onForwardClick={handleForwardClick}
                disabled={true}
            />
            <DialogTab
                parentName='PrintActForm'
                dialogTabFunction={handlePlannerRedirection}
            />
            <Tooltip id="print-form-tooltip" place="top"/>
        </>                                                             
    )
}

export default observer(PrintActForm)