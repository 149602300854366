import React, { useState, useRef, useEffect, useCallback } from 'react'
import { ChevronDoubleUpIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { sortRecords } from '../../../../../functions/sortRecords'
import { Tooltip } from 'react-tooltip'
import { getFieldValue } from '../../../../../config/constTypes'
import { createHeaders } from '../../../../../functions/createHeaders'


/**
 * Визуальный компонент отображает список записей выбранной таблицы.
 * 
 * @param {Object} dataModel Выбранная таблица
 * @param {Object[]} importedData Массив импортируемых записей
 * 
 */
const ImportPreviewTable = ({dataModel, importedData}) => {
    const [activeColumn, setActiveColumn] = useState(null)
    const [offsetX, setOffsetX] = useState(0)
    const [sortingDirection, setSortingDirection] = useState('down')
    const [sortingColumn, setSortingColumn] = useState('created')
    const [sortingType, setSortingType] = useState('string')
    const [sortedList, setSortedList] = useState([])
    const [isMinScrollPosition, setIsMinScrollPosition] = useState(true)
    const [isVerticalScroll, setIsVerticalScroll] = useState(false)
    let prevScrollLeft = 0

    const tableHeaderElement = useRef(null)
    const tableBodyElement = useRef(null)
    const frameElement = useRef(null)
    const headerElement = useRef(null)

    const headers = dataModel.fields
                        .filter(field => !field.hide)
                        .sort((a, b) => a.order - b.order)
                        .map(field => {return {alias: field.alias, column: field.tech_name, type: field.validator_type}})

    const defaultColumnSizes = headers.map(item => 'minmax(150px, 1000px)').join(' ')
    const savedColumnSizes = sessionStorage.getItem('savedImportColumnSizes')
    const columnSizes = savedColumnSizes || defaultColumnSizes
      
    const columns = createHeaders(headers)

    const mouseDown = (index) => (e) => {
        setActiveColumn(index)
        const offset = e.clientX - columns[index].ref.current.offsetWidth - columns[0].ref.current.offsetLeft
        setOffsetX(offset)
    }
    
    const mouseMove = useCallback((e) => {
        const minCellWidth = 100

        const gridColumns = columns.map((column, index) => {
            if (index === activeColumn) {
                const width = e.clientX - offsetX -  columns[0].ref.current.offsetLeft
                if (width >= minCellWidth) {
                    return `${width}px`
                }
            }
            return `${column.ref.current.offsetWidth}px`
        });
        
        const gridTemplateColumns = `${gridColumns.join(" ")}`
        tableHeaderElement.current.style.gridTemplateColumns = gridTemplateColumns
        tableBodyElement.current.childNodes[0].childNodes.forEach(row =>
            row.style.gridTemplateColumns = gridTemplateColumns
        )
    }, [activeColumn, columns, offsetX])
    
    const removeListeners = useCallback(() => {
        window.removeEventListener("mousemove", mouseMove)
        window.removeEventListener("mouseup", mouseUp)
    }, [mouseMove])
    
    const mouseUp = useCallback(() => {
        if (tableBodyElement.current) {
            sessionStorage.setItem('savedImportColumnSizes', tableBodyElement.current.childNodes[0].style.gridTemplateColumns)
        }
        removeListeners()
        setActiveColumn(null)
        setOffsetX(0)
    }, [setActiveColumn, setOffsetX, removeListeners])

    const handleSortClick = (column, type) => {
        if (column !== 'files') {
            if (column === sortingColumn) {
                if (sortingDirection === 'up') {
                    setSortingDirection('down')
                } else {
                    setSortingDirection('up')
                }
            } else {
                setSortingType(type)
            }
            setSortingColumn(column)
        }
    }

    const processTableScroll = (element) => {
        if (element) {
            headerElement.current.scrollLeft = element.scrollLeft
            setIsMinScrollPosition(frameElement.current.scrollTop === 0)
            setIsVerticalScroll(element.scrollHeight !== element.clientHeight)
        }
    }

    const scrollTableUp = () => {
        if (frameElement) {
            frameElement.current.scrollTop = 0
        }
    }

    useEffect(() => {
        setSortedList(sortRecords(importedData, sortingDirection, sortingColumn, sortingType, true))
    }, [])

    useEffect(() => {
        const handleScroll = (e) => {
            if (prevScrollLeft !==  e.target.scrollLeft)
                prevScrollLeft = e.target.scrollLeft
        }
        const element = frameElement.current
        element.addEventListener('scroll', handleScroll)

        return function () {
            element.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        if (activeColumn !== null) {
            window.addEventListener("mousemove", mouseMove)
            window.addEventListener("mouseup", mouseUp)
        }
    
        return () => {
            removeListeners()
        }
    }, [activeColumn, mouseMove, mouseUp, removeListeners])


    useEffect(() => {
       setSortedList(sortRecords(importedData, sortingDirection, sortingColumn, sortingType, true))
    }, [sortingDirection, sortingColumn, sortingType, importedData])

    
    return (
        <>
            <div className='tw-w-full tw-flex tw-flex-row tw-h-8'>
                <div className='tw-w-full tw-flex tw-flex-row tw-overflow-hidden' ref={headerElement}>
                    <div 
                        style={{
                            display: 'grid', 
                            gridTemplateColumns: columnSizes, 
                            borderBottomWidth: '1px',
                            paddingTop: '0.25rem',
                            paddingBottom: '0.25rem',
                        }} 
                        ref={tableHeaderElement}
                    >
                        { columns.map(({ref, text, column, type}, index) => {
                            return (
                                <div
                                    key={index}
                                    className='tw-group tw-overflow-hidden tw-truncate tw-text-left tw-text-sm tw-font-semibold tw-px-4 tw-py-1 tw-relative'
                                    ref={ref}
                                >
                                    <span
                                        className={`tw-absolute tw-left-0 tw-inset-y-auto group-hover:tw-opacity-50 ${sortingColumn === column ? 'tw-opacity-50' : 'tw-opacity-0'}`}
                                        onClick={() => handleSortClick(column, type)}
                                    >
                                        { sortingDirection === 'up'
                                            ?   <ChevronUpIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                            :   <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                        }
                                    </span>
                                    <span 
                                        data-tooltip-id="import-table-tooltip"
                                        data-tooltip-content={text}
                                        data-tooltip-delay-show={1000}
                                    >
                                        {text}
                                    </span>
                                    <span 
                                        className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-rounded-md tw-bg-gray-500 tw-opacity-0 tw-w-1 tw-cursor-col-resize group-hover:tw-opacity-30'
                                        onMouseDown={mouseDown(index)}
                                    >
                                    </span>
                                </div>
                            )}
                        )}
                    </div>
                </div>
                { isVerticalScroll &&
                    <button
                        type='button'
                        className='tw-w-4 tw-px-0 tw-py-1 tw-bg-gray-100 hover:tw-bg-gray-300 disabled:tw-text-gray-400 disabled:tw-bg-gray-100'
                        onClick={scrollTableUp}
                        disabled={isMinScrollPosition}
                    >
                        <ChevronDoubleUpIcon className='tw-w-4 tw-h-4' aria-hidden='true'/>
                    </button>
                }
            </div>
            <div 
                className='tw-h-[calc(100%_-_2rem)] tw-overflow-auto'
                ref={frameElement}
                onScroll={(e) => processTableScroll(e.target)}
            >
                <table ref={tableBodyElement} className='tw-w-full'>
                    <tbody>
                        { sortedList.map((item, index) => 
                            <tr 
                                key={index}
                                style={{
                                    display: 'grid', 
                                    gridTemplateColumns: columnSizes, 
                                    borderBottomWidth: '1px',
                                    cursor: 'pointer',
                                    background: 'white',
                                    color: 'black',
                                }} 
                            >
                                { Object.values(item.data).sort((a, b) => a.order - b.order).map((field, i) => {
                                    const value = getFieldValue(field)
                                    return ( !field.hide && 
                                        <td 
                                            key={i}
                                            className='tw-text-sm tw-py-2 tw-px-4 tw-overflow-hidden tw-truncate'
                                            data-tooltip-id="import-table-tooltip"
                                            data-tooltip-content={value}
                                            data-tooltip-delay-show={1000}
                                        >
                                            {value}
                                        </td>
                                    )}
                                )}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <Tooltip id="import-table-tooltip" place="top"/>
        </>
    )
}

export default ImportPreviewTable
