import React, { useState, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Context } from '../../../../../..'
import { observer } from 'mobx-react-lite'
import parse from 'html-react-parser'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import DialogTab from '../../../../../dialog_tab/DialogTab'
import RecommendedActionItem from './list/RecommendedActionItem'
import PlannedActionItem from './list/PlannedActionItem'
import Spinner from '../../../../../../assets/Spinner'
import ActionHistoryContainer from './form/ActionHistoryContainer'
import PlannedActionMenu from './menu/PlannedActionMenu'
import PlannedActionList from './list/PlannedActionList'
import RecommendedActionMenu from './menu/RecommendedActionMenu'
import { getFieldValue, menuTooltipTimeOut, serviceMessageTimeOut, successMessageTimeOut } from '../../../../../../config/constTypes'
import { checkMethodPermissions } from '../../../../../../functions/checkPermissions'
import { scrollElementIntoView } from '../../../../../../functions/scrollElementIntoView'
import { useHotkeys } from 'react-hotkeys-hook'
import { paths } from '../../../../../../config/constsURN'
import { copyToClipboard } from '../../../../../../functions/copyToClipboard'

/**
 * Визуальный компонент отображает панель со списками действий планировщика
 * 
 */
const PlannerTable = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { plannerStore, DialogTabStore, userStore, docStore } = useContext(Context)

    const [isActionHistoryOpen, setIsActionHistoryOpen] = useState(false)
    const [isOpenRecordEdit, setIsOpenRecordEdit] = useState(false)

    const isInitialState = !plannerStore.isActionFormOpen && !plannerStore.isDoneFormOpen && !isActionHistoryOpen

    useHotkeys('ctrl+g', () => {if (isInitialState) handleGroupActionClick()}, { preventDefault: true })
    useHotkeys('ctrl+a', () => {if (isInitialState) handleAddActionClick()}, { preventDefault: true })
    useHotkeys('ctrl+o', () => {if (isInitialState) handleRefreshClick()}, { preventDefault: true })
    useHotkeys('ctrl+y', () => {if (isInitialState) handlePlannedFilterClick()}, { preventDefault: true })
    useHotkeys('ctrl+m', () => {if (isInitialState && plannerStore.selectedAction) handleMarkActionAsDone(plannerStore.selectedAction.id)}, { preventDefault: true })
    useHotkeys('ctrl+e', () => {if (isInitialState && plannerStore.selectedAction) handleEditActionClick(plannerStore.selectedAction.id)}, { preventDefault: true })
    useHotkeys('ctrl+r', () => {if (isInitialState && plannerStore.selectedAction) handleDeleteActionClick(plannerStore.selectedAction)}, { preventDefault: true })
    useHotkeys('ctrl+h', () => {if (isInitialState && plannerStore.selectedAction) handleActionHistoryClick()}, { preventDefault: true })
    useHotkeys('ctrl+l', () => {if (isInitialState && plannerStore.selectedAction) handleCopyToClipboardClick()}, { preventDefault: true })

    const handleEditActionClick = () => {
        plannerStore.setEditMode('edit')
        plannerStore.setIsActionFormOpen(true)
        scrollElementIntoView(plannerStore.selectedAction, '')
    }

    const handleAddActionClick = () => {
        plannerStore.setCalendarDate(null)
        plannerStore.setEditMode('new')
        plannerStore.setSelectedAction(null)
        plannerStore.setIsActionFormOpen(true)
    }

    const handleDeleteActionClick = () => {
        if (plannerStore.selectedAction.system_data?.deletion_mark) {
            docStore.updateDataObject(plannerStore.selectedAction.record_id, {system_data: {deletion_mark: false}} ,Object.values(plannerStore.selectedAction.data), () => {}, () => {}, () => plannerStore.requestListUpdate())
        } else {
            const actionName = getFieldValue(plannerStore.selectedAction.data['my_action_reference'])
        
            DialogTabStore.setParentName('PlannerTable')
            DialogTabStore.setDialogTabTitle('Удаление действия') 
            DialogTabStore.setDialogTabText(parse(
                `Вы уверены, что хотите удалить действие<br/> "${actionName}",<br/> 
                запланированное на ${getFieldValue(plannerStore.selectedAction.data['action_completion_date'])}?`
            ))
            DialogTabStore.setDialogTabButtons(["Да", "Нет"])
            DialogTabStore.setIsSecondStage(false)
            DialogTabStore.setDialogTabIsOpen(true)
        }
    }

    const handleDeletePlannedActions = async () => {
        if (!DialogTabStore.isSecondStage) {
            DialogTabStore.setDialogTabIsOpen(false)
            const periodicActions = await plannerStore.getPeriodicActions()
            const response = await plannerStore.deleteOneAction()
            if (response && periodicActions.length) {
                DialogTabStore.setParentName('PlannerTable')
                DialogTabStore.setDialogTabTitle('Удаление последующих действий')
                DialogTabStore.setDialogTabText(parse(`Хотите удалить все последующие действия данного типа?`))
                DialogTabStore.setDialogTabButtons(["Да", "Нет"])
                DialogTabStore.setIsSecondStage(true)
                DialogTabStore.setDialogTabIsOpen(true)
            }
        } else {
            DialogTabStore.setDialogTabIsOpen(false)
            DialogTabStore.setIsSecondStage(false)
            await plannerStore.deletePeriodicActions(userStore.user.email)
            plannerStore.setPeriodicActions([])
        }
        plannerStore.setSelectedAction(null)
        navigate(paths.PLANNER_ROUTE)
    }

    const handleActionHistoryClick = () => {
        setIsActionHistoryOpen(true)
    }

    const getURLFilters = () => {
        let filter = []
        if (location.search.includes('?recommended=')) {
            const fieldRuleId = plannerStore.plannedDataModel.fields.find(item => item.tech_name === 'recommended_action')?.rule_id
            const recordId = location.search.replace('?recommended=', '')
            filter = [
                {property: "data[\"" + fieldRuleId + "\"].presentations[*].record_id", value: [recordId], operator: "eq"}
            ]
            
            return filter
        } else if (location.search.includes('?record_id=')) {
            const recordId = location.search.replace('?record_id=', '')
            filter = [
                {property: "record_id", value: recordId, operator: "eq"}
            ]

            return filter
        }

        filter = plannerStore.checkActionFilters(userStore.user.email)
        return filter
    } 

    const handleMarkActionAsDone = () => {
        plannerStore.setIsActionFormOpen(false)
        plannerStore.setIsDoneFormOpen(true)
    }

    const handleGroupActionClick = () => {
        plannerStore.setPlannedFilters('isGrouped', !plannerStore.plannedFilters.isGrouped, true)
    }

    const handleRefreshClick = () => {
        plannerStore.setIsUpdatingActionList(true)
        navigate(paths.PLANNER_ROUTE)
    }

    const handlePlannedFilterClick = () => {
        plannerStore.setIsFiltersOpen(true)
        plannerStore.setIsPlannedFilters(true)
    }

    const handleCopyToClipboardClick = () => {
        try {
            copyToClipboard(`${paths.PLANNER_ROUTE}/?record_id=${plannerStore.selectedAction.record_id}`)
            toast.info('Ссылка скопирована в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
        } catch (error) {
            toast.error('Не удалось скопировать ссылку в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }
    }

    useEffect(() => {
        if (plannerStore.isUpdatingActionList) {
            let filters = []
            
            if (location.search !== '') {
                filters = getURLFilters()
            } else {
                filters = plannerStore.checkActionFilters(userStore.user.email)
            }

            plannerStore.updatePlannedActionList(filters)
        }
    }, [plannerStore.isUpdatingActionList])

    useEffect(() => {
        if (plannerStore.myActions.length > 0) {
            plannerStore.setIsSortingRecommendedActions(true)
        }
    }, [plannerStore.myActions])

    useEffect(() => {
        if (plannerStore.isSortingRecommendedActions && plannerStore.recommendedActions.length) {
            plannerStore.sortRecommendedActions()
        }
    }, [plannerStore.isSortingRecommendedActions, plannerStore.recommendedActions])

    useEffect(() => {
        if (!plannerStore.isPlannedActionsLoading && isOpenRecordEdit) {
            if (location.search !== '' && location.search.includes('?record_id=')) {
                const actionID = location.search.replace('?record_id=', '')
                if (plannerStore.plannedActions.length) {
                    const foundAction = plannerStore.plannedActions.find(topic => topic.record_id === actionID)
                    if (foundAction) {
                        const [isRecordWrite, isRecordWriteDescription] = checkMethodPermissions(foundAction, 'write')
                        if (!isRecordWrite) {
                            const foundSubGroup = userStore.userSubCompanies.find(item => item.id === Number(foundAction.subgroup_id))
                            const userAdvice = foundSubGroup
                                                ?   parse(`<p>Смените юр.лицо на "${foundSubGroup.name}" для редактирования и сохранения изменений</p>`)
                                                :   parse('<p>Вы не сможете сохранить изменения, поскольку это юр.лицо Вам недоступно.<br/>Обратитесь с данной проблемой к администратору Медоед в Вашей компании</p>')
                            toast.warning(<div><p>Запись принадлежит другому юр.лицу!</p><br/>{userAdvice}</div>, 
                                        { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                        } 
                        plannerStore.onActionSelect(foundAction)
                    }
                } else {
                    plannerStore.setSelectedAction(null)
                    toast.error(<div><p>Запись по указанной ссылке не найдена!</p><br/>
                        <p>Возможно, данная запись принадлежит юр.лицу, которое Вам недоступно!</p>
                        <p>Обратитесь с данной проблемой к администратору Медоед в Вашей компании</p></div>, 
                        { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                }
            }
        }
    }, [plannerStore.plannedActions, plannerStore.isPlannedActionsLoading])

    useEffect(() => {
        if (location.search !== '' && plannerStore.plannedDataModel) {
            const filters = getURLFilters()
            plannerStore.getActions('planned_actions', filters, true)
            setIsOpenRecordEdit(true)
        }
    }, [location.pathname, plannerStore.plannedDataModel, location.search])

    useEffect(() => {
        if (plannerStore.selectedAction && location.search.includes('?record_id=')) {
            const [isRecordWrite, isRecordWriteDescription] = checkMethodPermissions(plannerStore.selectedAction, 'write')
            if (isRecordWrite && isOpenRecordEdit) {
                handleEditActionClick()
                setIsOpenRecordEdit(false)
            }
        }
    }, [plannerStore.selectedAction])

    useEffect(() => {
        if (!plannerStore.isPlannedActionsLoading) {
            plannerStore.groupPlannedActions()
        }
    }, [plannerStore.isPlannedActionsLoading, plannerStore.plannedFilters.isGrouped])

    useEffect(() => {
        if (!plannerStore.isRecommendedActionsLoading) {
            if (!Object.entries(plannerStore.recommendedCategoriesDefaultOpen).length) {
                const data = JSON.parse(sessionStorage.getItem('recommendedCategoriesDefaultOpen'))
                if (data)
                    plannerStore.setRecommendedCategoriesDefaultOpen(data)
            }
        }
    }, [plannerStore.isRecommendedActionsLoading])    

    return (
        <>
            <div className='tw-grid tw-grid-cols-2 tw-gap-x-4 tw-h-full tw-overflow-hidden'>
                <div id='recommended-action-column' className='tw-col-span-1 tw-h-full tw-overflow-hidden tw-bg-white tw-rounded-md'>
                    <div id='item-list-menu' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-text-sm tw-font-semibold tw-gap-x-2 tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                            <div className='tw-flex tw-flex-row tw-gap-x-2'>
                                <span className='tw-select-none'>Рекомендуемые</span>
                                <span
                                    data-tooltip-id="planner-table-tooltip"
                                    data-tooltip-content='Эталонные действия с учетом требований регуляторов'
                                    data-tooltip-delay-show={menuTooltipTimeOut}
                                >
                                    <InformationCircleIcon className='tw-h-5 tw-w-5 tw-text-gray-500 tw-cursor-help' aria-hidden="true"/>
                                </span>
                            </div>
                            <RecommendedActionMenu/>
                    </div>
                    <div  id='recommended-action-list' className='tw-flex tw-flex-col tw-w-full tw-h-[calc(100%_-_3rem)] tw-overflow-auto tw-pb-4'>
                        { plannerStore.isRecommendedActionsLoading
                            ?   <Spinner/>
                            :   <>
                                    {plannerStore.securityMeasuresCategories.map((item, index) => (
                                        <RecommendedActionItem
                                            key={index}
                                            item={item}
                                        />
                                    ))}
                                </>  
                        }
                    </div>
                </div>
                <div id='planned-action-column' className='tw-col-span-1 tw-h-full tw-overflow-hidden tw-bg-white tw-rounded-md'>
                    <div id='item-list-menu' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                        <div className='tw-flex tw-flex-row tw-text-sm tw-font-semibold tw-gap-x-2'>
                            <span className='tw-select-none'>Запланированные</span>
                        </div>
                        <PlannedActionMenu
                            onEditClick={handleEditActionClick}
                            onDeleteClick={handleDeleteActionClick}
                            onHistoryClick={handleActionHistoryClick}
                            onGroupClick={handleGroupActionClick}
                            onRefreshClick={handleRefreshClick}
                            onFilterClick={handlePlannedFilterClick}
                            onMarkClick={handleMarkActionAsDone}
                            onCopyLinkClick={handleCopyToClipboardClick}
                        />
                    </div>
                    <div  id='planned-action-list' className={`tw-flex tw-flex-col tw-w-full tw-h-[calc(100%_-_3rem)] tw-overflow-auto 
                            ${plannerStore.plannedFilters.isGrouped ? 'tw-pb-4' : 'tw-p-4'}`}
                    >
                        { plannerStore.isPlannedActionsLoading
                            ? <Spinner/>
                            : plannerStore.plannedFilters.isGrouped
                                ?   <>                                
                                        {plannerStore.securityMeasuresCategories.map((item, index) => (
                                            <PlannedActionList
                                                key={index}
                                                item={item}
                                                onDoubleClick={handleEditActionClick}
                                            />
                                        ))}
                                        
                                        <PlannedActionList
                                            item={{
                                                id: '0',
                                                data: {name: {value: 'Пользовательские'}}
                                            }}
                                            onDoubleClick={handleEditActionClick}
                                        />
                                    </>
                                :   <>
                                        {plannerStore.plannedActions.map((actionItem) =>
                                            <PlannedActionItem
                                                key={actionItem.record_id}
                                                actionItem={actionItem}
                                                onDoubleClick={handleEditActionClick}
                                            />
                                        )}
                                    </>
                        }
                    </div>
                </div>
            </div>
            <DialogTab
                parentName='PlannerTable'
                dialogTabFunction={handleDeletePlannedActions}
            />
            <ActionHistoryContainer
                isOpen={isActionHistoryOpen}
                setIsActionHistoryOpen={setIsActionHistoryOpen}
            />
            <Tooltip id="planner-table-tooltip"  className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
        </>                                                             
    )
}

export default observer(PlannerTable)