import { PrinterIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { menuTooltipTimeOut } from '../../../../../config/constTypes'


/**
 * Визуальный компонент отображает кнопку с выпадающим меню "Экспорт"
 * 
 * @param {Boolean} notPrintRecord Признак недоступности пунктов меню экспорта выбранной записи таблицы
 * @param {Function} onPrintDocumentClick Обработчик клика мыши на кнопке экспорта выбранной записи в файл .PDF
 * 
 */
function DataPrintMenu({notPrintRecord, onPrintDocumentClick}) {
    return (
        <>
            <button
                type='button'
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                onClick={onPrintDocumentClick}
                disabled={notPrintRecord}
                data-tooltip-id="print-tooltip"
                data-tooltip-html="Скачать PDF/ODT &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+P)"
                data-tooltip-delay-show={menuTooltipTimeOut}
                >
                <PrinterIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <Tooltip id="print-tooltip" className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
        </>
    )
}

export default DataPrintMenu