import { $fileHost } from "../http/http"
import { documentAPI } from "../config/constsURN"


/**
 * Класс реализует API для работы с файлами
 * @class
 */
class FileService {

    /**
     * Отправляет запрос на скачивание одного файла с сервера
     * @static
     * @param {String} id ID файла
     * @returns {Object} Содержимое файла
     */
    static async downloadFile(id) {
        const {data} = await $fileHost.get(documentAPI.FILE_API + '/' + id + '/download', { responseType: 'blob' })
        return data
    }
    
    /**
     * Отправляет запрос на выгрузку одного файла на сервер
     * @static
     * @param {Object} file Файл
     * @returns {Object} Метаинформация о файле
     */
    static async uploadFile(file) {
        const {data} = await $fileHost.post(documentAPI.FILE_API + '/upload', file, { headers: { 'Content-Type': 'multipart/form-data' }})
        return data
    }

}

export default FileService