import React, { useContext } from 'react'
import { Context } from '../../../../../../..'
import { InformationCircleIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline'
import { menuTooltipTimeOut } from '../../../../../../../config/constTypes'
import { observer } from 'mobx-react-lite'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronRightIcon, ListBulletIcon } from '@heroicons/react/20/solid'
import { paths } from '../../../../../../../config/constsURN'
import { useNavigate } from 'react-router-dom'


/**
 * Визуальный компонент отображает информацию одного рекомендуемого действия планировщика
 * 
 * @param {Object} item Рекомендуемое действие
 * 
 */
const RecommendedActionItem = ({item}) => {
    const { plannerStore } = useContext(Context)
    const navigate = useNavigate()

    const getActionDescription = (actionItem) => {
        const description = '<div>' + 
            (actionItem.data['action_description'].value && actionItem.data['action_description'].value !== '' ? actionItem.data['action_description'].value : 'Описание отсутствует') + 
            '<br/>' + 
            '<br/>Источник требований: ' + 
            (actionItem.data['requirements_source'].value && actionItem.data['requirements_source'].value !== '' ? actionItem.data['requirements_source'].value : 'не указан') +
            '<br/>' + 
            '<br/> Данное рекомендуемое действие ' + (actionItem.hidden ? 'уже ' : 'ещё не ') + 'запланировано' + 
            '</div>'
        return description
    }

    const onUseClick = (action) => {
        plannerStore.setEditMode('plan')
        plannerStore.setSelectedAction(action)
        plannerStore.setIsActionFormOpen(true)
    }

    const onViewRecordClick = (action) => {
        plannerStore.setSelectedAction(null)
        navigate(paths.PLANNER_ROUTE + '/?recommended=' + action.record_id)
    }

    const filteredActions = plannerStore.recommendedActions.filter(recommendedItem => 
        recommendedItem.data.security_measure.value.values[0]?.record_id === item.record_id)

    if (filteredActions.length === 0)
        return <></>

    return (
        <div key={item.id} id={`recommended-category-item-${item.id}`} className="tw-w-full tw-pt-0.5">
            <Disclosure defaultOpen={plannerStore.recommendedCategoriesDefaultOpen[item.id]}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className={`tw-flex tw-w-full tw-justify-between tw-bg-gray-300 tw-px-4 tw-border-y tw-py-1 tw-text-left 
                            tw-text-sm tw-font-medium tw-text-gray-900 tw-border-gray-300 focus:tw-outline-none 
                            focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-75
                            `}
                            onClick={() => {plannerStore.setRecommendedCategoriesDefaultOpen({...plannerStore.recommendedCategoriesDefaultOpen, [item.id]: !open})}}
                        >
                            <span>{item.data.name.value}</span>
                            { open
                                ?   <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                :   <ChevronRightIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            }     
                        </Disclosure.Button>
                        <Disclosure.Panel className="tw-pl-4 tw-pt-2 tw-pr-1">
                            {filteredActions.map((actionItem) => 
                                <div
                                    key={actionItem.record_id}
                                    className={`tw-text-gray-900 tw-bg-white hover:tw-bg-gray-100
                                                    tw-w-full tw-p-2 tw-mb-4 tw-rounded-md tw-shadow-md 
                                            ${actionItem.selectedType ? 'tw-border-2 tw-border-teal-500' : 'tw-border tw-border-gray-400'}
                                            tw-flex tw-justify-end tw-items-center tw-gap-x-2`}
                                >
                                    <div className={`tw-grow tw-select-none`}>
                                        <p className='tw-font-semibold'>{actionItem.data['name'].value}</p>
                                        <p className='tw-text-xs tw-mt-2'>Периодичность: {actionItem.data['action_periodicity'].value} дн.</p>
                                    </div>
                                    <div
                                        className={`tw-cursor-help 
                                                    ${actionItem.selectedType
                                                        ?   'tw-text-teal-500'
                                                        :   'tw-text-gray-600'}`}
                                        data-tooltip-id="planner-table-tooltip" data-tooltip-html={getActionDescription(actionItem)} data-tooltip-delay-show={menuTooltipTimeOut}
                                    >
                                        <InformationCircleIcon className='tw-w-8 tw-h-8' aria-hidden='true'/>
                                    </div>
                                    <div className='tw-w-7 tw-h-7'>
                                        { !actionItem.hidden
                                            ?
                                                <button
                                                    className='tw-rounded-full tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600'
                                                    onClick={() => onUseClick(actionItem)}
                                                    data-tooltip-id="planner-table-tooltip" data-tooltip-content="Воспользоваться рекомендацией" data-tooltip-delay-show={menuTooltipTimeOut}
                                                >
                                                    <ArrowRightOnRectangleIcon className='tw-w-4 tw-h-4' aria-hidden='true'/>
                                                </button>
                                            :   
                                                <button
                                                    className='tw-rounded-full tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600'
                                                    onClick={() => onViewRecordClick(actionItem)}
                                                    data-tooltip-id="planner-table-tooltip" data-tooltip-content={"Просмотреть запланированные действия"} data-tooltip-delay-show={menuTooltipTimeOut}
                                                >
                                                    <ListBulletIcon className='tw-w-4 tw-h-4' aria-hidden='true'/>
                                                </button>
                                        }
                                    </div>
                                </div>
                            )}
                        </Disclosure.Panel>
                    </>
                )}     
            </Disclosure>
        </div>
    )
}

export default observer(RecommendedActionItem)