import React, { useContext } from 'react'
import { DocumentPlusIcon, PencilSquareIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает меню возможных действий над юр лицами пользователя
 * 
 */
const SubCompanyListMenu = () => {
    const { CompanyAdminStore, userStore } = useContext(Context)

    const onAddItemClick = () => {
        CompanyAdminStore.setIsSubCompanyCreating(true)
        CompanyAdminStore.setSelectedSubCompany(null)
        CompanyAdminStore.setSelectedUser(null)
    }

    const onEditItemClick = () => {
        CompanyAdminStore.setIsSubCompanyEditing(true)
        CompanyAdminStore.setSelectedUser(null)
    }

    return (
        <div id='sub-company-list-menu' 
            className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center 
                        tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'
        >
            <p className='tw-text-sm tw-font-semibold tw-w-24 md:tw-text-base'>Юр. лица</p>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onAddItemClick}
                    disabled={CompanyAdminStore.isSubCompanyCreating || CompanyAdminStore.isSubCompanyEditing ||
                                CompanyAdminStore.isUserCreating || CompanyAdminStore.isUserEditing || !userStore.checkPermission("company.my.sub-company:create")}
                    data-tooltip-id="sub-company-menu-tooltip" data-tooltip-content="Создать юр. лицо" data-tooltip-delay-show={1000}
                >
                    <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onEditItemClick(CompanyAdminStore.selectedSubCompany.id)}
                    disabled={!CompanyAdminStore.selectedSubCompany  || CompanyAdminStore.isSubCompanyCreating || CompanyAdminStore.isSubCompanyEditing || 
                                CompanyAdminStore.isUserCreating || CompanyAdminStore.isUserEditing || !userStore.checkPermission("company.my:edit")}
                    data-tooltip-id="sub-company-menu-tooltip" data-tooltip-content="Редактировать юр. лицо" data-tooltip-delay-show={1000}
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
            </div>
            <Tooltip id="sub-company-menu-tooltip" place="top"/>
        </div>
    )
}

export default observer(SubCompanyListMenu)