import React from 'react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

function getModuleStatus(status) {
    switch (status) {
        case 'failed':
            return 'Обновление завершилось с ошибкой'
        case 'in_progress':
            return 'Выполняется обновление...'
        case 'successfully':
            return 'Успешно обновлен'
        default:
            return 'Состояние не определено'
    }
}

function getModuleVersion(module) {
    return `v.${module.presentation.version.release}.${module.presentation.version.major}.${module.presentation.version.minor}`
}

/**
 * Визуальный компонент отображает информацию о пакете конфигурации
 * 
 * @param {Object} module Пакет конфигурации
 * 
 */
const ConfigurationModule = ({module}) => {
    const components = (Object.entries(module.apps_mapping)).sort((a, b) => a[0] < b[0] ? -1 : 1).map(item => item[1])

    return (
        <>
            <div className='tw-pl-2 tw-py-2 tw-flex tw-flex-row tw-justify-start tw-items-center'>
                <ChevronRightIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                <div className='tw-text-sm'>
                    <span className='tw-font-semibold'>{module.to_module.presentation.name}&nbsp;&nbsp;&nbsp;</span>
                    <span className=''>(</span>
                    { module.from_module.presentation
                        ?   <span className='tw-font-semibold tw-text-indigo-700'>{getModuleVersion(module.from_module)}</span>
                        :   <span className=''>версия отсутствует</span>
                    }
                    { (module.can_update || module.status === 'in_progress') &&
                        <>
                            <span className=''>&nbsp;&nbsp;&gt;&nbsp;&nbsp;обновление до&nbsp;</span>
                            <span className='tw-font-semibold tw-text-indigo-700'>{getModuleVersion(module.to_module)}</span>
                        </>
                    }
                    <span className=''>)</span>
                </div>
            </div>
            <div className='tw-text-sm tw-pl-6 tw-pb-1 tw-flex tw-flex-row tw-justify-start tw-items-center'>
                <span className='tw-italic'>Описание: {module.to_module.presentation.description}</span>
            </div>
            { components.map((component, index) => (
                <div key={index} className="tw-text-sm tw-font-medium tw-leading-5 tw-pl-8 tw-py-0.5">
                    <span>&#8226; Компонент </span>
                    <span className='tw-font-semibold'>"{component.app.name}"</span>
                    <span> : </span>
                    <span className={`tw-font-semibold ${component.status === 'failed' ? 'tw-text-red-600' : component.status === 'successfully' ? 'tw-text-teal-600' : 'tw-text-gray-600'}`}>
                        {getModuleStatus(component.status)}
                    </span>
                </div>))
            }
            { !components.length &&
                <div className="tw-text-sm tw-font-medium tw-leading-5 tw-pl-8 tw-py-1">
                    <span className='tw-italic'>&#8226; Информация по компонентам отсутствует </span>
                </div>
            }
        </>
    )
}

export default ConfigurationModule