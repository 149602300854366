import React, { useContext, useEffect, useState } from 'react'
import { MinusIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import { Context } from '../../../..'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает название одного пользователя для списка существующих пользователей
 * 
 * @param {Object} item Текущий элемент списка пользователей 
 * 
 */
const UsersListItem = ({item}) => {
    const [userAssigned, setUserAssigned] = useState(false)
    const { AdminStore } = useContext(Context)
    let className = `${!item.is_active && 'tw-line-through tw-text-red-400'} tw-px-4 tw-truncate`

    useEffect(()=>{
        if (item.allowed_sub_company_ids.find(item => item === AdminStore.selectedSubCompany.id)) {
            setUserAssigned(true)
        } else {
            setUserAssigned(false)
        }
    }, [AdminStore.selectedSubCompany])

    const onItemClick = (item) => {
        if (AdminStore.selectedUser?.id !== item.id) {
            AdminStore.setSelectedUser(item)
        }
    }

    const bindUser = () => {
        if (!item.company_id || item.company_id === AdminStore.selectedCompany.id) {
            AdminStore.rebindUsers({users: {add: [item.id]}})

            if (!item.company_id) {
                AdminStore.editUser({company_id: AdminStore.selectedCompany.id, curr_sub_company_id: AdminStore.selectedSubCompany.id}, item.id)
            }
        } else {
            AdminStore.rebindUserToCompany(item.id)
        }
    }

    const unBindUser = () => {
        AdminStore.rebindUsers({users: {remove: [item.id]}})
    }

    return (
        <div className='tw-grid tw-grid-cols-5 tw-gap-2 tw-text-sm tw-border-b hover:tw-cursor-pointer 
                        tw-text-gray-900 tw-bg-white hover:tw-bg-gray-200 hover:tw-border-gray-300'
        >
            <div
                className = 'tw-truncate tw-col-span-4 tw-py-2'
                onClick={() => onItemClick(item)}
            >
                <p className = {className}>
                    {item.first_name} {item.last_name}
                </p>
                <p className = {className}>
                    {item.email}
                </p>
            </div>

            <div className="tw-flex tw-justify-end tw-items-center">
                <div  className="tw-text-gray-500 tw-mr-6" data-tooltip-id="users-list-tooltip" data-tooltip-content={userAssigned ? 'Отвязать' : 'Привязать'}>
                    {userAssigned 
                        ?   <CheckCircleIcon className='tw-w-5 tw-h-5 hover:tw-text-gray-700' aria-hidden='true' onClick={unBindUser}/>
                        :   <MinusIcon className='tw-w-5 tw-h-5 hover:tw-text-gray-700' aria-hidden='true' onClick={bindUser}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default observer(UsersListItem)
