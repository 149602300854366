import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../../../..'
import { Controller, useForm } from 'react-hook-form'
import DataSwitch from '../../../../common/inputs/DataSwitch'

/**
 * Визуальный компонент отображает панель настроек фильтров для рекомендуемых действий
 * 
 */
const RecommendedFilter = () => {
    const { plannerStore } = useContext(Context)
    const {
        handleSubmit,
        control
    } = useForm()

    const [isNewFilter, setIsNewFilter] = useState(false)

    const handleApplyFilterClick = (form) => {
        plannerStore.setSavedRecommendedFilters(form)
        localStorage.setItem('recommendedFilters', JSON.stringify(form))
        plannerStore.getActions('recommended_actions', plannerStore.getAppliedRecommendedFilters(form), false)
        setIsNewFilter(false)
        plannerStore.setIsFiltersOpen(false)
    }

    return (
        <div className='tw-w-full tw-h-full tw-text-sm tw-flex tw-flex-col tw-overflow-hidden'>
            <div className='tw-font-semibold tw-px-4 tw-py-2 tw-border-b-2 tw-border-gray-400'>
                Фильтр рекомендуемых действий
            </div>
            <div className='tw-w-full tw-px-4 tw-overflow-auto'>
                {plannerStore.recommendedFilters.map(item => {
                    let defaultValue = false
                    if (Object.entries(plannerStore.savedRecommendedFilters).length)
                        defaultValue = Object.entries(plannerStore.savedRecommendedFilters)?.find(([key, value], index) => key === item.record_id)[1]
                    
                    return <div key={item.record_id} className='tw-mt-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                            <Controller
                                name={item.record_id}
                                control={control}
                                defaultValue={defaultValue}
                                render={({field}) =>
                                    <DataSwitch
                                        isChecked={field.value}
                                        onClick={(e) => {setIsNewFilter(true); field.onChange(e)}}
                                    />
                                }
                            />
                            <label className='tw-text-sm tw-text-gray-900'>{item.data.name.value}</label> 
                        </div>
                })}
                <button
                    className='tw-w-full tw-my-4 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold
                            tw-border-gray-600  tw-bg-gray-600 tw-text-white hover:tw-bg-gray-500 hover:tw-border-gray-500
                            disabled:tw-bg-gray-300 disabled:tw-border-gray-300
                            focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    disabled={!isNewFilter}
                    onClick={handleSubmit(handleApplyFilterClick)}
                >
                    Применить
                </button>
            </div>
        </div>
    )
}

export default observer(RecommendedFilter)