import React from 'react'
import { useLocation } from 'react-router-dom'
import { paths } from '../../../config/constsURN'
import { classNames } from '../../../functions/classnames'

/**
 * Визуальный компонент отображает кнопку перехода на страницу авторизации
 * 
 */
const SignInButton = () => {
    const location = useLocation()

    return (
        <>
            {
                (location.pathname === '/' || location.pathname === '/registration')
                    ?   <a
                            href={paths.LOGIN_ROUTE}
                            className={classNames(
                                'tw-text-sm tw-font-semibold tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600',
                                'tw-rounded-md tw-px-3 tw-py-2 lg:tw-mr-10',
                                'tw-border-2 tw-border-white'
                            )}
                            aria-current={'page'}
                        >
                            Войти
                        </a>
                    :   null
            }
        </>
    )
}

export default SignInButton