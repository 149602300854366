import React, { useEffect, useRef, useState, useContext, useCallback } from 'react'
import { Context } from '../../../../../..'
import { observer } from 'mobx-react-lite'
import ReactPlayer from 'react-player'
import DialogTab from '../../../../../dialog_tab/DialogTab'
import EducationCloseButton from '../common/EducationCloseButton'
import VideoNavigationMenu from './VideoNavigationMenu'
import VideoScaleMenu from './VideoScaleMenu'
import { getIdFromPath } from '../../../../../../functions/getIdFromPath'
import parse from 'html-react-parser'


/**
 * Визуальный компонент отображает содержимое файла видео в формате mp4, прикрепленного к материалу по обучению
 * 
 * @param {Function} onCloseClick Обработчик клика мыши для закрытия окна
 * 
 */
const EducationVideoPlayer = ({onCloseClick}) => {
    const { EducationStore, DialogTabStore, userStore } = useContext(Context)
    const [videoScale, setVideoScale] = useState(100)
    const [src, setSrc] = useState(null)
    const [duration, setDuration] = useState(0)
    const [playedSeconds, setPlayedSeconds] = useState(0)
    const [maxPlayedSeconds, setMaxPlayedSeconds] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [volume, setVolume] = useState(1)
    const [player, setPlayer] = useState(null)
    let isRutube = EducationStore.viewedFile?.includes('rutube')
    let isDzen = EducationStore.viewedFile?.includes('dzen.ru')

    const playerRef = useRef(null)

    const dialogTabFunction = async () => {
        DialogTabStore.setDialogTabIsOpen(false)
        setPlayedSeconds(maxPlayedSeconds)
        setIsPlaying(true)

        if (isRutube) {
            player.postMessage(JSON.stringify({type: 'player:setCurrentTime', data: {time: maxPlayedSeconds}}), '*')
            player.postMessage(JSON.stringify({type:'player:play', data:{}}), '*')
        } else 
            playerRef.current.seekTo(maxPlayedSeconds)
    }

    const onCloseTabFunction = async () => {
        handleRestartClick()
    }

    const handleCloseDialogClick = () => {
        setIsPlaying(false)
        const progress = (maxPlayedSeconds >= duration - 2) ? duration : maxPlayedSeconds
        if (EducationStore.viewedFile && progress > 0 &&
           (!EducationStore.savedMaterialProgress || (progress > EducationStore.savedMaterialProgress.data["material_progress"].value)))
        {
            EducationStore.saveMaterialProgress(progress, duration, userStore.user.id)
        }
        isRutube = false
        isDzen = false
        onCloseClick()
    }

    const handleProgress = (info) => {
        const seconds = Math.floor(info.playedSeconds)
        setPlayedSeconds(seconds)
        
        if (seconds > maxPlayedSeconds) {
            setMaxPlayedSeconds(seconds)
        }

        if (seconds === duration)
            setIsPlaying(false)
    }

    const handleScaleChange = (isIncreased) => {
        const nextVideoScale = isIncreased ? videoScale + 10 : videoScale - 10
        
        if (nextVideoScale > 0 && nextVideoScale <= 100) {
            setVideoScale(nextVideoScale)
        }
    }

    const handlePlayClick = () => {
        setIsPlaying(!isPlaying)

        if (isRutube) {
            if (isPlaying)
                player.postMessage(JSON.stringify({type:'player:pause', data:{}}), '*')
            else
                player.postMessage(JSON.stringify({type:'player:play', data:{}}), '*')
        }
    }

    const handleRewindClick = (isIncreased) => {
        let nextPlayedSeconds = isIncreased ? playedSeconds + 5 : playedSeconds - 5
        if (nextPlayedSeconds < 0)
            nextPlayedSeconds = 0
        if (nextPlayedSeconds > maxPlayedSeconds)
            nextPlayedSeconds = maxPlayedSeconds

        if (isRutube)
            player.postMessage(JSON.stringify({type: 'player:setCurrentTime', data: {time: nextPlayedSeconds}}), '*')
        else
            playerRef.current.seekTo(nextPlayedSeconds)
    }

    const handleRestartClick = () => {
        if (isRutube)
            player.postMessage(JSON.stringify({type: 'player:setCurrentTime', data: {time: 0}}), '*')
        else
            playerRef.current.seekTo(0)
    }

    const handleVolumeChange = (e) => {
        setVolume(parseFloat(e.target.value))
        
        if (isRutube)
            player.postMessage(JSON.stringify({type: 'player:setVolume', data: {volume: parseFloat(e.target.value)}}), '*')
    }
    
    useEffect(() => {
        if (EducationStore.viewedFile && EducationStore.materialType) {
            if (EducationStore.materialType === 'stream') {
                setSrc(EducationStore.viewedFile)
            } else {
                setSrc([{src: EducationStore.viewedFile, type: 'video/mp4'}])
            }
        }
    }, [EducationStore.viewedFile, EducationStore.materialType])

    useEffect(() => {
        if (isRutube && src) {
            const video = document.getElementById('video')?.contentWindow
            setPlayer(video)
        }
    }, [isRutube, src])

    useEffect(() => {
        if (EducationStore.savedMaterialProgress && !isDzen) {
            setMaxPlayedSeconds(EducationStore.savedMaterialProgress.data["material_progress"].value)
            DialogTabStore.setParentName('EducationVideoPlayer')
            DialogTabStore.setDialogTabTitle("Продолжение обучения")
            DialogTabStore.setDialogTabText('Вы хотите продолжить просмотр с фрагмента, на котором остановились?')
            DialogTabStore.setDialogTabButtons(["Да", "Нет"])
            DialogTabStore.setDialogTabIsOpen(true)
        }
    }, [EducationStore.savedMaterialProgress])

    const playerStatus = useCallback((event) => {
        const data = JSON.parse(event.data)

        if (isRutube) {
            if (data.type === 'player:durationChange') {
                setDuration(Math.floor(data.data.duration))
            }

            if (data.type === 'player:currentTime') {
                handleProgress({playedSeconds: data.data.time})
            }
        }

        if (isDzen) {
            if (data.event === "durationchange") {
                setDuration(Math.floor(data.duration))
            }

            if (data.event === "timeupdate") {
                handleProgress({playedSeconds: data.time})
            }
        }
    }, [handleProgress])

    useEffect(() => {
        if (isRutube || isDzen) {
            window.addEventListener('message', playerStatus)
        }
        
        return () => {
            window.removeEventListener("message", playerStatus)
        }
    }, [isRutube, playerStatus])

    return (
        <div className='tw-mx-auto tw-w-full tw-h-full tw-overflow-hidden tw-flex tw-flex-col tw-bg-black'>
            <div id='menu-panel' className='tw-w-full tw-h-12 tw-py-1 tw-flex tw-flex-row tw-justify-start tw-items-center tw-bg-white tw-border-b tw-border-gray-300'>
                <EducationCloseButton onCloseClick={handleCloseDialogClick}/>
                <VideoNavigationMenu
                    playing={isPlaying}
                    volume={volume}
                    time={duration}
                    activeTime={playedSeconds}
                    lastTime={maxPlayedSeconds}
                    onRestartClick={handleRestartClick}
                    onPlayClick={handlePlayClick}
                    onRewindClick={handleRewindClick}
                    onVolumeChange={handleVolumeChange}
                    isHideControls={isDzen}
                />
                <VideoScaleMenu
                    scale={videoScale}
                    onScaleChange={handleScaleChange}
                />
            </div>
            <div id='video-panel' className='tw-grow tw-w-full tw-overflow-auto'>
                {src &&
                    <>
                        {isRutube
                            ?   <div style={{width: '100%', height: '100%'}}>
                                    <iframe 
                                        id='video'
                                        title={getIdFromPath(src, 4)}
                                        src={`https://rutube.ru/play/embed/${getIdFromPath(src, 4)}`} 
                                        style={{width: videoScale + '%', height: videoScale + '%'}} 
                                        className="tw-mx-auto"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen allow="encrypted-media; autoplay;"
                                    ></iframe> 
                                </div> 
                            :   <>
                                    {isDzen 
                                        ?   <div style={{width: '100%', height: '100%'}}>
                                                <iframe width="480" height="270" id='video'title={'dzen.ru'} 
                                                    style={{width: videoScale + '%', height: videoScale + '%'}} className="tw-mx-auto"
                                                    src={parse(EducationStore.viewedFile)?.props?.src}
                                                    allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media" 
                                                    allowFullScreen
                                                ></iframe>
                                            </div> 
                                        :   <ReactPlayer
                                                className="tw-mx-auto tw-ring-media-focus tw-data-[focus]:ring-4"
                                                url={src}
                                                controls={true}
                                                playing={isPlaying}
                                                volume={volume}
                                                width={videoScale + '%'}
                                                height={videoScale + '%'}
                                                progressInterval={250}
                                                onProgress={handleProgress}
                                                onDuration={(seconds) => setDuration(Math.floor(seconds))}
                                                onContextMenu={(e) => e.preventDefault()}
                                                ref={playerRef}
                                            />
                                    }
                            </> 
                        }
                    </>
                }
            </div>
            <DialogTab
                parentName='EducationVideoPlayer'
                dialogTabFunction={dialogTabFunction}
                onCloseTabFunction={onCloseTabFunction}
            />
        </div>
    )
}

export default observer(EducationVideoPlayer)