import React, { useContext, useEffect } from 'react'
import PageTitle from '../main_page/controller/common/panels/PageTitle'
import Spinner from '../../assets/Spinner'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import UsersList from './users_list/UsersList'
import RolesList from './roles_list/RolesList'
import RolesMenu from './roles_list/RolesMenu'
import RoleForm from './roles_list/RoleForm'
import RoleInfo from './roles_list/RoleInfo'
import RolesPermissionsTabs from './permissions/RolesPermissionsTabs'
import RoleUserMenu from './users_list/RoleUserMenu'
import RoleUserInfo from './users_list/RoleUserInfo'
import RoleUserForm from './users_list/RoleUserForm'


/**
 * Компонент реализует логику создания и редактирования ролей пользователей компаний
 * 
 */
const UserRolesContainer = () => {
    const { RolesStore, userStore } = useContext(Context)

    useEffect(() => {
        RolesStore.getAllPermissions()
        RolesStore.getMyCompanyUsers()

        const defaultFilter = []
        const categorySorter = JSON.stringify([
            {property: 'order', desc: false},
        ])      
        RolesStore.getCategories(defaultFilter, categorySorter)

        const dataModelSorter = JSON.stringify([
            {property: 'entity_name', desc: false},
        ])
        RolesStore.getAllDataModels(defaultFilter, dataModelSorter)

    }, [])

    useEffect(() => {
        if (userStore.checkPermission("company.my.role:get"))
            RolesStore.getAllRoles()
    }, [userStore.userPermissions])

    if (RolesStore.isLoading || !RolesStore.roles) {
        return <Spinner/>
    }

    return (
        <>
            <PageTitle title={'Настройка прав пользователей'} />
            <section id='user-roles-page' className='tw-grid tw-grid-cols-5 tw-gap-x-8 tw-max-h-full tw-h-full'>
                <div id='users-list' className='tw-min-w-60 tw-col-span-1 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <RoleUserMenu/>
                    { RolesStore.isUserInfoOpen && !RolesStore.isUserCreating && !RolesStore.isUserEditing &&        
                        <RoleUserInfo />
                    }
                    { (RolesStore.isUserCreating || RolesStore.isUserEditing) && 
                        <RoleUserForm />
                    }
                    <UsersList/>
                </div>
                <div id='roles-column' className='tw-min-w-60 tw-col-span-1 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <RolesMenu/>
                    { (RolesStore.isRoleCreating || RolesStore.isRoleEditing)
                        ?   <RoleForm/>
                        :   <> { RolesStore.isRoleInfoOpen 
                                    ?   <RoleInfo/>
                                    :   <RolesList/>
                                }
                            </>
                    }
                </div>
                <div id='permissions-column' className='tw-col-span-3 tw-h-full tw-overflow-hidden'>
                    <RolesPermissionsTabs/>
                </div>
            </section>
        </>
    )
}

export default observer(UserRolesContainer)