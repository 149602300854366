import React from 'react'
import { getFormattedDate } from '../../../../../config/constTypes'
import { TableCellsIcon } from '@heroicons/react/20/solid'


/**
 * Визуальный компонент отображает таблицу справочников
 * 
 * @param {Object[]} directories Список справочников  
 * @param {Function} onLinkClick Обработчик клика мыши по ссылке на справочник  
 * 
 */
const DirectoriesTable = ({directories, onLinkClick}) => {
    return (
        <table className='tw-w-full tw-text-sm tw-text-left tw-border-gray-400 tw-table-fixed tw-overflow-auto'>
            <thead>
                <tr className='tw-h-8 tw-border-y tw-border-gray-400'>
                    <th className='tw-w-6/12 tw-px-4 tw-border-x tw-border-gray-400'>Наименование</th>
                    <th className='tw-w-2/12 tw-px-4 tw-border-r tw-border-gray-400'>Кол-во записей</th>
                    <th className='tw-w-3/12 tw-px-4 tw-border-r tw-border-gray-400'>Дата обновления</th>
                    <th className='tw-w-1/12 tw-pl-4 tw-pr-1 tw-border-r tw-border-gray-400 tw-truncate'>Ссылка</th>
                </tr>
            </thead>
            <tbody className=''>
                { directories.sort((a, b) => a.last_modified - b.last_modified).map((directory, index) => 
                    <tr 
                        key={index}
                        className={`tw-h-8 tw-border-b tw-border-gray-400 tw-truncate 
                                    tw-text-gray-900 hover:tw-bg-gray-200 hover:tw-shadow-lg
                                    hover:tw-cursor-pointer`}
                    >
                        <th 
                            className={`tw-px-4 tw-py-1 tw-font-medium tw-border-x tw-border-gray-400 tw-truncate`}
                        >
                            {directory.entity_name}
                        </th>
                        <th 
                            className={`tw-px-4 tw-py-1 tw-font-medium tw-border-x tw-border-gray-400 tw-truncate`}
                        >
                            {directory.records}
                        </th>
                        <th className={`tw-px-4 tw-py-1 tw-font-medium tw-border-r tw-border-gray-400 tw-truncate`}>
                            { getFormattedDate(directory.version_date, 'dd.MM.yyyy') }
                        </th>
                        <th className={`tw-px-4 tw-py-1 tw-font-medium tw-border-r tw-border-gray-400 tw-truncate`}>
                            <button 
                                className='tw-rounded-md tw-text-gray-500 tw-bg-white hover:tw-text-gray-900 hover:tw-bg-gray-200'
                                onClick={() => onLinkClick(directory)}
                            >
                                <TableCellsIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            </button>
                        </th>
                    </tr>
                )}                      
            </tbody>
        </table>
    )
}

export default DirectoriesTable