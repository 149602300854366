import React, { useContext } from 'react'
import CategoryPermissionsItem from './CategoryPermissionsItem'
import { Context } from '../../../..'
import { observer } from 'mobx-react-lite'


/**
 * Визуальный компонент отображает список существующих категорий таблиц
 * 
 */
const CategoryPermissionsList = () => {
    const { RolesStore } = useContext(Context)
    return (
        <div  id='categories-list-items' className='tw-flex tw-flex-col tw-h-full tw-w-full tw-overflow-auto'>
            { RolesStore.categories.map((categoryItem, index) => 
                <CategoryPermissionsItem
                    key={index}
                    category={categoryItem}
                    categoryIndex={index}
                />
            )}
            <CategoryPermissionsItem
                category={{id: '0', name: 'Без категории', order: (RolesStore.categories.length + 1)}}
                categoryIndex={RolesStore.categories.length}
            />
        </div>
    )
}

export default observer(CategoryPermissionsList)