import { Fragment, useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import PawIcon from '../../../../../../../assets/PawIcon'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import { Context } from '../../../../../../..'
import { menuTooltipTimeOut } from '../../../../../../../config/constTypes'
import {ChevronDownIcon} from '@heroicons/react/20/solid'
/**
 * Визуальный компонент отображает выпадающее меню рассчета показателей значимости
 * 
 * @param {Function} handleChangeNestedTables Метод, реализующий изменение записей вложенной таблицы
 * @param {Object} nestedModel Вложенная таблица
 * @param {Function} setError Метод, реализующий добавление ошибок UseForm
 * @param {Function} clearErrors Метод, реализующий сброс ошибок UseForm
 * 
 */
function CalculateIndicatorsMenu ({handleChangeNestedTables, nestedModel, setError, clearErrors}) {
    const { categorizingCIIStore } = useContext(Context)
    let calculate9thIndicatorDisabled = false
    let calculate13AIndicatorDisabled = false
    let calculate13BIndicatorDisabled = false

    if (nestedModel.id === 'indicatorss') {
        nestedModel.dataObjects.forEach(item => {
           
            if (item.status !== 'deleted') {
                if (item.data.indicator__indicatorss.value.values[0].code === '09') {               
                    calculate9thIndicatorDisabled = !!item.data.justification_of_irrelevance__indicatorss.value
                }
                
                if (item.data.indicator__indicatorss.value.values[0].code === '13а') {               
                    calculate13AIndicatorDisabled = !!item.data.justification_of_irrelevance__indicatorss.value
                }
                
                if (item.data.indicator__indicatorss.value.values[0].code === '13б') {               
                    calculate13BIndicatorDisabled = !!item.data.justification_of_irrelevance__indicatorss.value
                }
            }
        })
    }

    const calculate9thIndicator = () => {
        const currentYear = new Date().getFullYear()
        const recoveryTime = document.getElementsByName("data.recovery_time")
        const schedule = document.getElementsByName("data.schedule_object_kii")
        
        let lastYearTax = 0
        categorizingCIIStore.organizationNestedModels.forEach(item => {
            if (item.id === 'taxs') {
                item.dataObjects.forEach(dataObject => {
                    if (dataObject.data.year_string__taxs.value === currentYear - 1) {
                        lastYearTax = dataObject.data.summa__taxs.value
                    }
                })
            }
        })

        if (lastYearTax === 0) {
            toast.error(`Не возможно рассчитать показатель. Укажите налог за ${currentYear - 1} год`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        } else if (!recoveryTime[0].value || recoveryTime[0].value < 0) {
            setError(`data.recovery_time`)
            toast.error(`Не возможно рассчитать показатель. Время восстановления должно быть неотрицательным числом`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        } else if (!schedule[0]) {
            setError(`data.schedule_object_kii`)
            toast.error(`Не возможно рассчитать показатель. Укажите график работы объекта КИИ`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        } else {
            clearErrors()
            let workDays = schedule[0].value === '7 дней в неделю' ? 365 : 247
            let indicator = (lastYearTax * (recoveryTime[0].value / workDays)) / 34000000000000

            if (indicator < 0.0001) {
                indicator = 'менее 0.0001'
            } else {
                indicator = indicator.toFixed(4)
            }

            let editedRow
            const editedValues = nestedModel.dataObjects.map(dataObject => {
                if (dataObject.data.indicator__indicatorss.value.values[0].code === '09' && dataObject.status !== 'deleted') {
                    let editedDataObject = JSON.parse(JSON.stringify(dataObject))
                    editedRow = dataObject

                    if (dataObject.status === 'saved')
                        editedDataObject.status = 'edited'

                    let organizationLoss = (recoveryTime[0].value / workDays) * 100
                    if (organizationLoss < 0.0001) {
                        organizationLoss = 'менее 0.0001'
                    } else {
                        organizationLoss = 'не более ' + organizationLoss.toFixed(4)
                    }

                    editedDataObject.data.justification_of_the_indicator_val__indicatorss.value = 
                        `Максимальное время восстановления после атаки для объекта КИИ составляет ${recoveryTime[0].value} дня(ей). 
                        Таким образом, за период неисправности, организация потеряет ${organizationLoss}% прибыли. 
                        При объеме выплачиваемых (за предыдущий год) в федеральный бюджет налогов ${lastYearTax} рублей, ущерб бюджету составит не более 
                        ${(lastYearTax * (recoveryTime[0].value / workDays)).toFixed(4)} рублей. С учетом планируемого объема федерального бюджета РФ на 
                        ${currentYear} год - ${indicator}%`

                    editedDataObject.data.value_of_the_indicator__indicatorss.value = indicator
                    categorizingCIIStore.setSelectedNestedValue(editedDataObject)

                    document.getElementById(nestedModel.id +'nested_table_row' + dataObject.id).scrollIntoView({ block: "center", behavior: "smooth" });
                    return editedDataObject
                } else {
                    return dataObject
                }
            })
            handleChangeNestedTables(editedValues, nestedModel, editedRow)
            toast.success(`Показатель рассчитан успешно`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        }
    }

    const calculate13AIndicator = () => {
        const recoveryTime = document.getElementsByName("data.recovery_time")
        const schedule = document.getElementsByName("data.schedule_object_kii")

        let averageUnits = 0,
            isNoStateOrder = false

        categorizingCIIStore.organizationNestedModels.forEach(item => {
            if (item?.id === 'state_order') {
                if (item?.dataObjects?.length) {
                    let sumUnits = 0
                    item.dataObjects.forEach(dataObject => {
                        if (dataObject?.data?.natural_units__state_order?.value)
                            sumUnits += dataObject.data.natural_units__state_order.value
                    })
                    averageUnits = sumUnits / item.dataObjects.length
                } else {
                    isNoStateOrder = true
                }
            }
        })

        if (isNoStateOrder) {
            toast.error(`Не возможно рассчитать показатель. Укажите данные об оборонзаказах`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        } else if (!recoveryTime[0].value || recoveryTime[0].value < 0) {
            setError(`data.recovery_time`)
            toast.error(`Не возможно рассчитать показатель. Время восстановления должно быть неотрицательным числом`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        } else if (!schedule[0]) {
            setError(`data.schedule_object_kii`)
            toast.error(`Не возможно рассчитать показатель. Укажите график работы объекта КИИ`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        } else {
            clearErrors()
            let workDays = schedule[0].value === '7 дней в неделю' ? 365 : 247
            let indicator = (averageUnits * (recoveryTime[0].value / workDays)) * 100

            if (indicator < 0.0001) {
                indicator = 'менее 0.0001'
            } else {
                indicator = indicator.toFixed(4)
            }

            let editedRow
            const editedValues = nestedModel.dataObjects.map(dataObject => {
                if (dataObject.data.indicator__indicatorss.value.values[0].code === '13а' && dataObject.status !== 'deleted') {
                    let editedDataObject = JSON.parse(JSON.stringify(dataObject))
                    editedRow = dataObject

                    if (dataObject.status === 'saved')
                        editedDataObject.status = 'edited'

                    let organizationLoss = (recoveryTime[0].value / workDays) * 100
                    if (organizationLoss < 0.0001) {
                        organizationLoss = 'менее 0.0001'
                    } else {
                        organizationLoss = 'не более ' + organizationLoss.toFixed(4)
                    }

                    editedDataObject.data.justification_of_the_indicator_val__indicatorss.value = 
                        `Максимальное время восстановления после атаки для объекта КИИ составляет ${recoveryTime[0].value} дня(ей). Таким образом, 
                        за период неисправности, объем выпуска ГОЗ сократится на ${organizationLoss}%. При среднегодовом объеме выпуска ${averageUnits.toFixed(4)}, 
                        такой простой снизит выпуск на ${indicator}%`

                    editedDataObject.data.value_of_the_indicator__indicatorss.value = indicator
                    categorizingCIIStore.setSelectedNestedValue(editedDataObject)

                    document.getElementById(nestedModel.id +'nested_table_row' + dataObject.id).scrollIntoView({ block: "center", behavior: "smooth" });
                    return editedDataObject
                } else {
                    return dataObject
                }
            })
            handleChangeNestedTables(editedValues, nestedModel, editedRow)
            toast.success(`Показатель рассчитан успешно`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        }
    }

    const calculate13BIndicator = () => {
        const recoveryTime = document.getElementsByName("data.recovery_time")
        const schedule = document.getElementsByName("data.schedule_object_kii")

        if (!recoveryTime[0].value || recoveryTime[0].value < 0) {
            setError(`data.recovery_time`)
            toast.error(`Не возможно рассчитать показатель. Время восстановления должно быть неотрицательным числом`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        } else if (!schedule[0]) {
            setError(`data.schedule_object_kii`)
            toast.error(`Не возможно рассчитать показатель. Укажите график работы объекта КИИ`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        } else {
            clearErrors()
            let workDays = schedule[0].value === '7 дней в неделю' ? 365 : 247
            let indicator = (recoveryTime[0].value / workDays) * 100

            if (indicator < 0.0001) {
                indicator = 'менее 0.0001'
            } else {
                indicator = indicator.toFixed(4)
            }

            let editedRow
            const editedValues = nestedModel.dataObjects.map(dataObject => {
                if (dataObject.data.indicator__indicatorss.value.values[0].code === '13б' && dataObject.status !== 'deleted') {
                    let editedDataObject = JSON.parse(JSON.stringify(dataObject))
                    editedRow = dataObject

                    if (dataObject.status === 'saved')
                        editedDataObject.status = 'edited'

                    editedDataObject.data.justification_of_the_indicator_val__indicatorss.value = 
                        `Максимальное время восстановления после атаки для объекта КИИ составляет ${recoveryTime[0].value} дня(ей). Таким образом, 
                        за период неисправности, время выпуска увеличится на ${indicator}%. При графике работы ${schedule[0].value} время выпуска будет 
                        увеличено на ${indicator}%`

                    editedDataObject.data.value_of_the_indicator__indicatorss.value = indicator
                    categorizingCIIStore.setSelectedNestedValue(editedDataObject)

                    document.getElementById(nestedModel.id +'nested_table_row' + dataObject.id).scrollIntoView({ block: "center", behavior: "smooth" });
                    return editedDataObject
                } else {
                    return dataObject
                }
            })
            handleChangeNestedTables(editedValues, nestedModel, editedRow)
            toast.success(`Показатель рассчитан успешно`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        }
    }

    return (
        <Menu as="div" className="tw-relative tw-inline-block tw-text-left tw-mr-6 tw-mb-1">
            <Menu.Button
                className="tw-inline-flex tw-w-full tw-justify-center tw-gap-x-1.5 tw-rounded-md tw-text-sm tw-text-white tw-px-3 tw-py-1.5 tw-bg-gray-800 tw-shadow-sm  tw-ring-gray-300 hover:tw-bg-gray-600"
                data-tooltip-id="categorizing-objects-form-tooltip" data-tooltip-content='Рассчитать показатели' data-tooltip-delay-show={menuTooltipTimeOut}
            >
                Рассчитать показатель
                <ChevronDownIcon className="tw-w-5 tw-h-5"/>
                {/* <PawIcon className="tw-w-6 tw-h-6"/> */}
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="tw-transition tw-ease-out tw-duration-100"
                enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                enterTo="tw-transform tw-opacity-100 tw-scale-100"
                leave="tw-transition tw-ease-in tw-duration-75"
                leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                leaveTo="tw-transform tw-opacity-0 tw-scale-95"
            >
                <Menu.Items className="tw-absolute tw-right-0 tw-z-10 tw-mt-2 tw-w-48 tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 
                    tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
                >
                    <div className="tw-py-1">
                        <Menu.Item>
                            <button className={`tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-text-gray-700
                                    disabled:tw-text-gray-400 tw-w-full tw-text-left
                                `}
                                onClick={(e) => {e.preventDefault(); calculate9thIndicator()}}
                                disabled={calculate9thIndicatorDisabled}
                                data-tooltip-id="categorizing-objects-form-tooltip"
                                data-tooltip-content={`${calculate9thIndicatorDisabled
                                    ? 'Расчет невозможен, так как для показателя указано "Обоснование неактуальности"' : ''}`}
                            >
                                <span>09</span>
                            </button>
                        </Menu.Item>
                        <Menu.Item>
                            <button className={`tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-text-gray-700
                                    disabled:tw-text-gray-400 tw-w-full tw-text-left
                                `}
                                onClick={(e) => {e.preventDefault(); calculate13AIndicator()}}
                                disabled={calculate13AIndicatorDisabled}
                                data-tooltip-id="categorizing-objects-form-tooltip"
                                data-tooltip-content={`${calculate13AIndicatorDisabled
                                    ? 'Расчет невозможен, так как для показателя указано "Обоснование неактуальности"' : ''}`}
                            >
                                <span>13а</span>
                            </button>
                        </Menu.Item>
                        <Menu.Item>
                            <button className={`tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-text-gray-700
                                    disabled:tw-text-gray-400 tw-w-full tw-text-left
                                `}
                                onClick={(e) => {e.preventDefault(); calculate13BIndicator()}}
                                disabled={calculate13BIndicatorDisabled}
                                data-tooltip-id="categorizing-objects-form-tooltip"
                                data-tooltip-content={`${calculate13BIndicatorDisabled
                                    ? 'Расчет невозможен, так как для показателя указано "Обоснование неактуальности"' : ''}`}
                            >
                                <span>13б</span>
                            </button>
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default observer(CalculateIndicatorsMenu)
