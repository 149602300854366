import React, { useState, useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import {registerLocale} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import ru from "date-fns/locale/ru"
import { Tooltip } from 'react-tooltip'
import { observer } from 'mobx-react-lite'
import { showErrorToast } from '../../../../../../../functions/errorHandlers'
import Spinner from '../../../../../../../assets/Spinner'
import { Context } from '../../../../../../..'
import { emptyReferenceValue, setFieldDefaultValue, setFieldValue, setReferenceValue } from '../../../../../../../config/constTypes'
import DirectoryContainer from '../../../../common/panels/directory/DirectoryContainer'
import DocumentService from '../../../../../../../services/DocumentService'
import ObjectsListButtons from '../../../../common/panels/object_list/ObjectsListButtons'
import CategorizingObjectsFormItem from './CategorizingObjectsFormItem'
import { createEmptyFieldData, createNestedDataObject, deleteNestedDataObjects, updateCIINestedDataObjects, deleteAllNestedDataObjects } from '../../../../../../../functions/nestedModels'
import { processData } from '../../../../../../../functions/forms'
import FormErrorToastPanel from '../../../../common/panels/toast/FormErrorToastPanel'
import { printDocument } from '../../../../../../../functions/fileHandlers'

registerLocale("ru", ru)

/**
 * Визуальный компонент отображает форму для создания или редактирования информации об объекте категорирования
 * 
 * @param {Function} onBackClick Обработчик клика мыши для возвращения к предыдущему объекту
 * @param {Function} onForwardClick Обработчик клика мыши для перехода к следующему объекту
 * @param {Object} stage Текущий активный объект категорирования
 * @param {Object[]} objectsList Список существующих объектов категорирования
 * @param {Function} setLastObjectSaved Сохранение информации о сохранении последнего объекта
 *   
 */
const CategorizingObjectsForm = ({onBackClick, onForwardClick, stage, objectsList, setLastObjectSaved}) => {   
    const {
        control,
        register,
        handleSubmit,
        setValue,
        getValues,
        resetField,
        setError,
        formState: { isSubmitting, errors },
        clearErrors
    } = useForm()

    const { categorizingCIIStore, docStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [selectedDataModelID, setSelectedDataModelID] = useState(null)
    const [selectedName, setSelectedName] = useState(null)
    const [isSavedRecord, setIsSavedRecord] = useState(false)
    const [formFields, setFormFields] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [recordId, setRecordId] = useState('')
    const [formNestedModels, setFormNestedModels] = useState(null)
    const [isDuplicateMode, setIsDuplicateMode] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isPrintingDocument, setIsPrintingDocument] = useState(false)
    const [currentAct, setCurrentAct] = useState(null)
    const [isPrintedAct, setIsPrintedAct] = useState(false)
    const [replacedNestedDataObjects, setReplacedNestedDataObjects] = useState([])
    const [categories, setCategories] = useState([])
    const [totalCount, setTotalCount] = useState([])
    const [offset, setOffset] = useState([])
    const [isSaving, setIsSaving] = useState(false)
    const [chosenDataObjectsList, setChosenDataObjectsList] = useState([])

    register('data.types_of_activity_many__act_of_categorir', { 
        required: false,
        shouldUnregister: true,
    })

    const handleSelectClick = (dataModel, name) => {
        let fieldValue = getValues(name)
        if (fieldValue) {
            setChosenDataObjectsList([fieldValue])
        }

        setSelectedDataModelID(dataModel)
        setSelectedName(name)
        setIsSelectFormOpen(true)
    }

    const handleClearClick = (name) => {
        setValue(name, emptyReferenceValue)
        handleRecordChange()
    }

    const handleRecordChange = () => {
        setIsSavedRecord(false)
        setIsPrintedAct(false);
        setLastObjectSaved(false)
    }

    const handleDoubleClick = (item) => {
        if(selectedName !== 'duplicateRecord'){
            setValue(selectedName, {value: setReferenceValue(item)})
        } else {
            duplicateRecord(item)
        }
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
        setSelectedName(null)
        setIsSavedRecord(false)
        setIsPrintedAct(false)
        setLastObjectSaved(false)
    }

    const handleCloseClick = () => {
        docStore.setIsDetailView(false)
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
    }

    // Функция заполнения полей значениями по умолчанию в зависимости от типа поля записи
    const setFieldInputDefaultValues = (item) => {
        switch(item.validator_type) {
            case 'one':
            case 'many':
                if (item.tech_name === 'respon_for_security_kii__act_of_categorir')
                    return resetField('data.' + item.tech_name + '.0', { defaultValue: 
                        (isEditMode || isDuplicateMode) ? item : categorizingCIIStore.protectionRecord
                    })

                return resetField('data.' + item.tech_name + '.0', { defaultValue: 
                    (isEditMode || isDuplicateMode) ? item : emptyReferenceValue})
            case 'bool':
                return resetField('data.' + item.tech_name, { defaultValue: 
                    (isEditMode || isDuplicateMode) ? setFieldValue(item) : setFieldDefaultValue(item)})
            case 'int':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : item.default || 0})
            case 'float':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : item.default || 0})
            case 'enum':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : setFieldDefaultValue(item)})
            case 'date':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : null})
            default:
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : null})
        }
    }
    
    const handleSaveRecord = async (form) => {
        setIsSaving(true)
        let error = false

        categorizingCIIStore.setNestedModels(
            categorizingCIIStore.nestedModels.map(nestedModel => {
                const primaryField = nestedModel.fields.find(field => field.order === 0)
                if (primaryField) {
                    if (nestedModel.id !== 'indicatorss') {
                        // проверка прочих вложенных таблиц на наличие повторяющихся значений первичного поля и пустых значений ячеек
                        const [isError, checkedNestedModel] = categorizingCIIStore.checkNestedValueErrors(nestedModel.dataObjects, primaryField.tech_name, true)
                        if (isError) {
                            setError(`data.${nestedModel.tech_name}`, { type: 'invalid', message: 'Включенные таблицы не должны быть пустыми, а также содержать повторяющиеся или пустые значения!' })
                            error = true
                            setIsSaving(false)
                        }
                        return {...nestedModel, dataObjects: checkedNestedModel, errors: isError}
                    } else {    // проверка вложенной таблицы с показателями
                        const [isError, checkedNestedModel] = categorizingCIIStore.checkIndicatorErrors(nestedModel.dataObjects) 
                        if (isError) {
                            setError(`data.${nestedModel.tech_name}`, { type: 'invalid', message: 'Для каждого показателя должно быть выбрано обоснование неактуальности или заполнены остальные поля!' })
                            error = true
                            setIsSaving(false)
                        }

                        return {...nestedModel, dataObjects: checkedNestedModel, errors: isError}
                    }
                }

                return nestedModel
            })    
        )

        if(!error) {
            const dataObject = processData(form.data, categorizingCIIStore.activeDataModel)
            dataObject.data['object__act_of_categorir'] = [stage.record_id]
            dataObject.data['is_data_current__act_of_categorir'] = true
            dataObject.data['date_of_print_act__act_of_categorir'] = null
            dataObject.data['subject_of__act_of_categorir'] = [categorizingCIIStore.subjectInfo.values[0].record_id]
            dataObject.data['commission__act_of_categorir'] = [categorizingCIIStore.commissionID.values[0].record_id]

            categorizingCIIStore.nestedModels.forEach(nestedModel => {
                let deleteNestedDataObjectsArr = []

                replacedNestedDataObjects.forEach(replacedNestedModel => {
                    if (replacedNestedModel.techName === nestedModel.tech_name) {
                        deleteNestedDataObjectsArr = replacedNestedModel.dataObjects
                    }
                })

                dataObject.data[nestedModel.tech_name] = {
                    upsert: updateCIINestedDataObjects(nestedModel),
                    remove: deleteNestedDataObjects(nestedModel).concat(deleteNestedDataObjectsArr),
                }

                // определение категории значимости для объекта КИИ как минимального значения (более высокая категория) по показателям значимости
                if (nestedModel.id === 'indicatorss') {
                    let maxCategory = 4, 
                        categoryRecordID = categories.find(item => item.data.code_int__categories_of_significance.value === 4).record_id

                    nestedModel.dataObjects.forEach(value => {
                        if (value.data['category_of_sign_by_indicator__indicatorss'].value.values.length > 0) {
                            categories.forEach(category => {
                                if (value.data['category_of_sign_by_indicator__indicatorss'].value.values[0].name === category.data.name.value
                                    && category.data['code_int__categories_of_significance'].value < maxCategory) 
                                {
                                    maxCategory = category.data['code_int__categories_of_significance'].value
                                    categoryRecordID = category.record_id
                                }
                            })
                        }
                    })

                    dataObject.data['signif_category__act_of_categorir'] = [categoryRecordID]
                }
            })

            try {
                if (isEditMode) {
                    await DocumentService.updateDataObject(recordId, dataObject)
                    await categorizingCIIStore.loadSavedProject(categorizingCIIStore.project.record_id)
                } else {                        
                    const response = await DocumentService.createDataObject(dataObject)

                    const project = {}
                    project.data_model_id = 'stages_of_categorization'
                    project.data = {} 
                    
                    project.data = {
                        'acts_of_categorization__stages_of_categorization':{
                            upsert: [],
                            remove: []
                        } 
                    }

                    project.data['acts_of_categorization__stages_of_categorization'].upsert.push({
                        data: {
                            'act_of_categorization__acts_of_categorization_vt': [response.record_id]
                        }  
                    })
            
                    const savedProject = await DocumentService.updateDataObject(categorizingCIIStore.project.record_id, project)
                    await categorizingCIIStore.loadSavedProject(categorizingCIIStore.project.record_id)
                }

                toast.success('Данные успешно сохранены!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                setIsSavedRecord(true)
                setIsSaving(false)

            } catch (error) {
                showErrorToast(error, 'saving', '')
                setIsSaving(false)
            }
        }
    }

    const handlePrintClick = async () => {
        if (currentAct) {
            setIsPrintingDocument(true)

            try {
                // печать акта категорирования
                await printDocument('kii_result', currentAct.record.id, setIsPrintingDocument)

                await DocumentService.updateDataObject(currentAct.record.record_id, { data: {'date_of_print_act__act_of_categorir': Date.now()}})
                await categorizingCIIStore.loadSavedProject(categorizingCIIStore.project.record_id)

            } catch (error){
                showErrorToast(error, 'printing', '')
            } finally {
                setIsPrintingDocument(false)
            }
        }    
    }

    const duplicateRecord = (item) => {
        //Пометка на удаление сохраненных записей вложенных таблиц дублируемого объекта
        if(categorizingCIIStore.nestedModels.some(nestedModel => nestedModel.dataObjects.some(nestedDataObject => nestedDataObject.status === 'saved'))){
            let replacedArr = []
            categorizingCIIStore.nestedModels.forEach(nestedModel => {
                replacedArr.push({
                    techName: nestedModel.tech_name, 
                    dataObjects: deleteAllNestedDataObjects(nestedModel)
                })
            })
            setReplacedNestedDataObjects(replacedArr)
        }
        
        const fields = Object.values(item.data)
        setIsDuplicateMode(true)
        setFormFields(fields)
        initNestedModels(item.id, true)
    }  
    
    const initNestedModels = async (id, isDuplicate) => {  
        // загрузка списка выбранных неактуальных показателей
        setIsLoading(true)
        let irrelevantIndicators = []
        if (categorizingCIIStore.irrelevantIndicators && categorizingCIIStore.irrelevantIndicators.values.length > 0) {
            let indicatorsModel

            const indicatorsDataObject = await DocumentService.getOneDataObject(categorizingCIIStore.irrelevantIndicators.values[0].id)
            if (indicatorsDataObject)
                indicatorsModel = indicatorsDataObject.nested_models.find(nestedModel => nestedModel.id === 'irrelevant_indicators_2')

            if (indicatorsModel && indicatorsDataObject) {
                irrelevantIndicators = await DocumentService.getNestedDataObjects(50, [], [], indicatorsDataObject.id, indicatorsModel.rule_id)
            }
        }
        setIsLoading(true)
        // загрузка записей всех вложенных таблиц, если категорирование объекта КИИ сохранено
        const nestedValueRequests = formNestedModels.map(nestedModel => id ? DocumentService.getNestedDataObjects(50, [], [], id, nestedModel.rule_id) : nestedModel)
        Promise.all(nestedValueRequests)
            .then(responses => {
                categorizingCIIStore.setNestedModels(formNestedModels.map((nestedModel, nestedModelIndex) =>  { 
                    if (nestedModel.id === 'indicatorss') {                       
                        // формирование списка показателей значимости для конкретного объекта КИИ с учетом списка неактуальных показателей     
                        const loadedValues = categorizingCIIStore.indicatorList
                            .filter(item => !item.system_data.deletion_mark)
                            .map((indicator, index) => {               
                                const fieldData = createEmptyFieldData(nestedModel)
                                const emptyNestedValue = createNestedDataObject(fieldData, index, nestedModel)
            
                                const indicatorValue = {}
                                indicatorValue.format = indicator.format
                                indicatorValue.values = [{entity_name: indicator.entity_name, code: indicator.data['code'].value, name: indicator.data['name'].value, record_id: indicator.record_id, id: indicator.id}]
                                emptyNestedValue.data['indicator__indicatorss'].value = indicatorValue
                                emptyNestedValue.data['unit_of_measurement__indicatorss'].value = indicator.data['unit_of_measurement__significance_indicators'].value
                                // поиск показателя в списке неактуальных показателей
                                const foundIndicator = irrelevantIndicators.find(irrelevant => irrelevant.data['indicator__irrelevant_indicators_2'].value.values[0].record_id === indicator.record_id)
                                if (foundIndicator) {
                                    // копирование обоснования неактуальности
                                    const justificationValue = foundIndicator.data['justification_of_inapplicability__irrelevant_indicators_2'].value
                                    emptyNestedValue.data['justification_of_irrelevance__indicatorss'].value = justificationValue
                                    // формирование новой копии неактуальног показателя для списка
                                    if (responses[nestedModelIndex].length > 0) {
                                        responses[nestedModelIndex].forEach(responsesNestedObject => {
                                            if(responsesNestedObject.data['indicator__indicatorss'].value.values[0].record_id 
                                                === emptyNestedValue.data['indicator__indicatorss'].value.values[0].record_id)
                                            {
                                                responsesNestedObject.status = 'deleted'        // загруженный показатель помечается удаленным
                                                emptyNestedValue.record_id = responsesNestedObject.record_id
                                            }
                                        })
                                        // добавление сформированной копии к списку показателей
                                        responses[nestedModelIndex] = responses[nestedModelIndex].concat([emptyNestedValue])
                                    }
                                } 

                                return emptyNestedValue
                            })

                        return {
                            ...nestedModel,
                            dataObjects: id
                                        ?   responses[nestedModelIndex]
                                            .sort((a, b) => a.data['indicator__indicatorss'].value.values[0].code < b.data['indicator__indicatorss'].value.values[0].code ? -1 : 1)
                                            .map((value, index) => {
                                                if(value.status)
                                                    return {...value, order: index }
                                                else
                                                    return {...value, status: isDuplicate ? 'added' : 'saved', order: index }
                                            })
                                        :   loadedValues
                        }
                    } else {
                        return {
                            ...nestedModel,
                            dataObjects: id ? responses[nestedModelIndex].map(value => { return {...value, status: isDuplicate ? 'added' : 'saved'} }) : []
                        }
                    }
                }))
                setIsLoading(false)
            })
    }

    useEffect(() => {
        categorizingCIIStore.getActiveDataModel('act_of_categorir', setFormNestedModels)
        categorizingCIIStore.loadIrrelevantIndicators()
        categorizingCIIStore.loadSignificanceIndicators()
        categorizingCIIStore.loadOrganizationNestedModels(() => {}, false)
        
        const defaultFilter = JSON.stringify([
            {property: 'data_model_id', value: 'categories_of_significance', operator: 'eq'},
            {property: 'transaction_id', value: null, operator: 'eq'},
            {property: 'active', value: true, operator: 'eq'}
        ])
        const sorter = []
        docStore.getDataObjectsByID(defaultFilter, sorter, 0, categories, setCategories, setTotalCount, setOffset)
    }, [])

    useEffect(() => {
        if (categorizingCIIStore.activeDataModel && categorizingCIIStore.activeDataModel.id === 'act_of_categorir') {
            setIsEditMode(false)
            setIsDuplicateMode(false)
            setIsSavedRecord(false)
            setIsPrintedAct(false)
            setFormFields(categorizingCIIStore.activeDataModel.fields)
            
            if (categorizingCIIStore.objectsCategorization && categorizingCIIStore.objectsCategorization.length > 0) {
                const foundAct = categorizingCIIStore.objectsCategorization.find(object => 
                    stage.record_id === object.record.data['object__act_of_categorir'].value.values[0].record_id
                )
                if (foundAct) {
                    setCurrentAct(foundAct)
                    const fields = Object.values(foundAct.record.data)
                    setRecordId(foundAct.record.record_id)
                    setFormFields(fields)
    
                    setIsEditMode(true)
                    if (foundAct.record.data['is_data_current__act_of_categorir'] && foundAct.record.data['is_data_current__act_of_categorir'].value) {
                        setIsSavedRecord(true)
                        if (foundAct.record.data['date_of_print_act__act_of_categorir'].value)
                            setIsPrintedAct(true)
                    }
                }

                setLastObjectSaved(objectsList.every(object => {
                    const currentAct = categorizingCIIStore.objectsCategorization.find(act => object.record_id === act.record.data['object__act_of_categorir'].value.values[0].record_id)
                    return currentAct && currentAct.record.data['date_of_print_act__act_of_categorir'].value
                }))
            }
        }
    }, [stage, categorizingCIIStore.activeDataModel, categorizingCIIStore.objectsCategorization])

    useEffect(() => {
        if(formFields){
            formFields.forEach(field => {
                if(field.field_id !== 'object' && !field.hide && field.type !== 'include')
                    setFieldInputDefaultValues(field)
            })
            const foundObject = categorizingCIIStore.resourceList.dataObjects.find(item => item.value_record_id === stage.record_id)
            if (foundObject)
                setValue('data.types_of_activity_many__act_of_categorir', foundObject.data['types_of_activity_many__an_object_vt2'].value.values)
        }
    }, [formFields])

    useEffect(() => {
        if (formNestedModels && categorizingCIIStore.indicatorList.length) {

            if (categorizingCIIStore.objectsCategorization && categorizingCIIStore.objectsCategorization.length > 0) {
                const foundAct = categorizingCIIStore.objectsCategorization.find(object => 
                    stage.record_id === object.record.data['object__act_of_categorir'].value.values[0].record_id
                )
                if (foundAct) {
                    setCurrentAct(foundAct)
                    initNestedModels(foundAct.record.id, false)
                } else 
                    initNestedModels(null, false)
            } else {
                initNestedModels(null, false)
            }
        }
    }, [stage, formNestedModels, categorizingCIIStore.indicatorList, categorizingCIIStore.objectsCategorization])

    useEffect(() => {
        if (Object.entries(errors).length > 0 && isSubmitting === false) {
            toast.error(<FormErrorToastPanel errors={errors.data} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <>
            { !isPrintingDocument &&
                <>
                    <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-mx-auto tw-py-2'>
                        <button 
                            className='tw-rounded-md  tw-mr-2 tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                    hover:tw-bg-gray-600 hover:tw-border-gray-600 disabled:tw-bg-gray-300  disabled:tw-border-gray-300
                                    focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                            onClick={handleSubmit(handleSaveRecord)}
                            disabled={isSaving || isLoading || !formFields || categorizingCIIStore.categorizingActsLoading}
                        >
                            Сохранить акт категорирования
                        </button>
                        <button 
                            className='tw-rounded-md  tw-mr-2 tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                    hover:tw-bg-gray-600 hover:tw-border-gray-600 disabled:tw-bg-gray-300  disabled:tw-border-gray-300
                                    focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                            disabled={!isSavedRecord || isSaving || isLoading || !formFields || categorizingCIIStore.categorizingActsLoading}
                            onClick={handlePrintClick}
                        >
                            Скачать акт категорирования
                        </button>
                        <button 
                            className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                    hover:tw-bg-gray-600 hover:tw-border-gray-600 disabled:tw-bg-gray-300  disabled:tw-border-gray-300
                                    focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                            disabled={isSaving || isLoading || !formFields || categorizingCIIStore.categorizingActsLoading}
                            onClick={(e) => {e.preventDefault(); handleSelectClick('act_of_categorir', 'duplicateRecord')}}
                        >
                            Скопировать из другого объекта
                        </button>
                    </div>
                    <div className='tw-grow tw-overflow-auto'>
                        <form className='tw-flex tw-flex-col tw-h-full'>
                            <div className='tw-px-4 tw-py-1'>
                                { !formFields || isSaving
                                    ?
                                        <Spinner />
                                    :
                                        formFields.slice().sort((a, b) => a.order - b.order).map((fieldItem, index) => {
                                            if (!fieldItem.hide &&
                                                fieldItem.tech_name !== 'object__act_of_categorir' &&
                                                fieldItem.tech_name !== 'commission__act_of_categorir' &&
                                                fieldItem.tech_name !== 'subject_of__act_of_categorir'
                                            ) {
                                                return  <CategorizingObjectsFormItem 
                                                            key={index}
                                                            fieldItem={fieldItem}
                                                            errors={errors}
                                                            handleSelectClick={handleSelectClick}
                                                            handleClearClick={handleClearClick}
                                                            handleRecordChange={handleRecordChange}
                                                            isEditMode={isEditMode}
                                                            isDuplicateMode={isDuplicateMode}
                                                            isLoading={isLoading}
                                                            control={control}
                                                            register={register}
                                                            setValue={setValue}
                                                            clearErrors={clearErrors}
                                                            categories={categories}
                                                            setError={setError}
                                                        />
                                            } else return null
                                        })
                                }
                            </div>                            
                        </form>
                    </div>
                </>                                                             
            }
            { isPrintingDocument &&
                <div className='tw-grow tw-overflow-auto'>
                    <div className='tw-px-4 tw-py-1 tw-mt-1'>
                        <p className='tw-mt-2 tw-text-center tw-text-sm tw-font-semibold'>Выполняется скачивание акта категорирования объекта КИИ...</p>
                        <Spinner/>
                    </div>
                </div>
            }
            <ObjectsListButtons
                onBackClick={onBackClick}
                onForwardClick={onForwardClick}
                stage={stage}
                objectsList={objectsList}
                disabled={!isPrintedAct}
                forwardButtonTitle={'Следующий объект'}
                backButtonTitle={'Предыдущий объект'}
            />
            <DirectoryContainer 
                isOpen={isSelectFormOpen}
                selectedDataModel={selectedDataModelID}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
                isDuplicateObject={selectedName === 'duplicateRecord' ? true : false}
                chosenDataObjectsList={chosenDataObjectsList}
            />
            <Tooltip id="categorizing-objects-form-tooltip" place="top" className='tw-max-w-xl'/>
        </>                                                             
    )
}

export default observer(CategorizingObjectsForm)
