import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react-lite'
import { Context } from '../../..'
import DialogTab from '../../dialog_tab/DialogTab'
import FormErrorToastPanel from '../../main_page/controller/common/panels/toast/FormErrorToastPanel'

/**
 * Визуальный компонент отображает форму редактирования информации о компании
 * 
 */
const CompanyForm = () => {
    const { AdminStore, DialogTabStore } = useContext(Context)
    const regexInn = new RegExp('^[0-9]{10}$|^[0-9]{12}$')
    const regexKpp = new RegExp('^[0-9]{9}$')
    const regexOgrn = new RegExp('^[0-9]{13}$|^[0-9]{15}$')
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm()

    const formFields = AdminStore.isCompanyEditing 
        ? [
            {
                tech_name: 'name',
                alias: 'Полное наименование'
            }
        ]
        :[
            {
                tech_name: 'name',
                alias: 'Полное наименование'
            },
            {
                tech_name: 'abbreviation',
                alias: 'Сокращенное наименование'
            },
            {
                tech_name: 'tin',
                alias: 'ИНН'
            },
            {
                tech_name: 'msrn',
                alias: 'ОГРН'
            },
            {
                tech_name: 'rrc',
                alias: 'КПП'
            },
            {
                tech_name: 'owner',
                alias: 'Руководитель'
            },
            {
                tech_name: 'owner_position',
                alias: 'Должность руководителя'
            },
            {
                tech_name: 'registration_address',
                alias: 'Адрес регистрации'
            }
        ]

    const onCancelClick = () => {
        AdminStore.setIsSubCompanyCreating(false)
        AdminStore.setIsSubCompanyEditing(false)
        AdminStore.setIsCompanyEditing(false)
    }

    const onSubmitClick = (form) => {
        if (!AdminStore.isCompanyEditing && !regexInn.test(form.tin?.trim())) {
            setError('tin', { type: 'invalid', message: ' Поле должно содержать 10 или 12 цифр' })
        } else if (!AdminStore.isCompanyEditing && !regexKpp.test(form.rrc?.trim())) {
            setError('rrc', { type: 'invalid', message: ' Поле должно содержать 9 цифр' })
        } else if (!AdminStore.isCompanyEditing && !regexOgrn.test(form.msrn?.trim())) {
            setError('msrn', { type: 'invalid', message: ' Поле должно содержать 13 или 15 цифр' })
        } else {
            const subCompany = {}
            formFields.forEach(item =>{
                subCompany[item.tech_name] = form[item.tech_name].trim()
            })

            sessionStorage.setItem('companyForm', JSON.stringify(subCompany))

            AdminStore.isCompanyEditing 
                ? AdminStore.editCompany(subCompany)
                : AdminStore.isSubCompanyEditing 
                    ? AdminStore.editSubCompany(subCompany) 
                    : AdminStore.selectedCompany?.id 
                        ? AdminStore.registerSubCompany(subCompany)
                        : AdminStore.registerCompany(subCompany)
        }  
    }

    const onBlockButtonClick = () => {
        DialogTabStore.setParentName('CompanyForm')
        DialogTabStore.setDialogTabIsOpen(true)
        if (AdminStore.selectedSubCompany) {
            if (AdminStore.selectedSubCompany.is_active) {
                DialogTabStore.setDialogTabTitle("Блокировка юр. лица") 
                DialogTabStore.setDialogTabText("Вы уверены, что хотите заблокировать это юр. лицо?")
            } else {
                DialogTabStore.setDialogTabTitle("Снятие блокировки") 
                DialogTabStore.setDialogTabText("Вы уверены, что хотите разблокировать это юр. лицо?") 
            }
        } else {
            if (AdminStore.selectedCompany.is_active) {
                DialogTabStore.setDialogTabTitle("Блокировка компании") 
                DialogTabStore.setDialogTabText("Вы уверены, что хотите заблокировать эту компанию?")
            } else {
                DialogTabStore.setDialogTabTitle("Снятие блокировки") 
                DialogTabStore.setDialogTabText("Вы уверены, что хотите разблокировать эту компанию?") 
            }
        }
    }

    const handleBlockSubCompanyClick = () => {
        if (AdminStore.selectedSubCompany) {
            if (AdminStore.selectedSubCompany.is_active)
                AdminStore.editSubCompany({is_active: false})
            else
                AdminStore.editSubCompany({is_active: true})     
        } else {
            if (AdminStore.selectedCompany.is_active)
                AdminStore.editCompany({is_active: false})
            else
                AdminStore.editCompany({is_active: true})
        }
        DialogTabStore.setDialogTabIsOpen(false) 
    }

    useEffect(() => {
        const companyFormStorage = JSON.parse(sessionStorage.getItem('companyForm'))
        if(companyFormStorage){
            Object.entries(companyFormStorage).map(([key, value], index) => {
                setValue(key, value)
            })
        }
    }, [])

    useEffect(() => {
        if(Object.entries(errors).length > 0 && isSubmitting === false){
            toast.error(<FormErrorToastPanel errors={errors} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <form id='system-company-form' className='tw-h-[calc(100%_-_3rem)] tw-overflow-auto tw-flex tw-flex-col'>
            <div className='tw-text-sm tw-font-semibold tw-p-4 tw-text-gray-900'>
                { AdminStore.isCompanyEditing
                    ?   'Редактируемая компания' 
                    :   AdminStore.isSubCompanyEditing
                            ?   'Редактируемое юр. лицо'
                            :   AdminStore.selectedCompany?.id
                                    ?   'Новое юр. лицо'
                                    :   'Новая компания'
                }
            </div>
            <div className='tw-grow'>
                {formFields.map((item, index) => {
                    let fieldValue = '' 
                    if (AdminStore.isSubCompanyEditing) 
                        fieldValue = AdminStore.selectedSubCompany[item.tech_name]
                    else if (AdminStore.isCompanyEditing) 
                        fieldValue = AdminStore.selectedCompany[item.tech_name]

                    return <div key={index} className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                                <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                                    <label htmlFor={item.tech_name}>{item.alias}</label>
                                    <span>
                                        { errors[item.tech_name] && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> }
                                    </span>
                                </dt>
                                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                    <input
                                        id={item.tech_name}
                                        className={`tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-2 tw-text-gray-700 
                                            tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 sm:tw-text-sm 
                                            sm:tw-leading-6 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                            ${errors[item.tech_name] ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                        `}
                                        {...register(item.tech_name, { required: true, value: fieldValue})}
                                    />
                                </dd>
                            </div>
                    })
                }
            </div>
            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-py-4'>
                { (AdminStore.isSubCompanyEditing || AdminStore.isCompanyEditing) &&
                    <button className=' tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700 hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600' 
                        type='button'
                        onClick={onBlockButtonClick}
                    >
                        {AdminStore.isCompanyEditing 
                            ? <>{AdminStore.selectedCompany.is_active ? "Заблокировать" : "Разблокировать"}</>
                            : <>{AdminStore.selectedSubCompany.is_active ? "Заблокировать" : "Разблокировать"}</>
                        }
                    </button>
                }
                <button 
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={handleSubmit(onSubmitClick)}
                >
                    Сохранить
                </button>
                <button
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                            hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={onCancelClick}
                >
                    Отменить
                </button>
            </div>
            <DialogTab 
                parentName='CompanyForm'
                dialogTabFunction={handleBlockSubCompanyClick}
            />
        </form>
    )
}

export default observer(CompanyForm)