import axios from 'axios'
import { $host, $authHost, $dataHost, $importHost, $exportHost, $confHost, $fileHost, $notesHost } from '../http/http'
import { medoedImage } from '..'


// todo change to nginx ssi way
/**
 * Класс для получения переменных окружения Docker-контейнера
 * @class
 */
class Env {
    /**
     * Метод загружает переменные окружения из файла envs.json
     */
    async load() {
        let env = {...process.env}
        if(process.env.REACT_APP_PRODUCTION === "true") {
            const response = await axios.get('/envs.json')
            env = {...env, ...response.data}
        }
        
        $dataHost.defaults.baseURL = env.REACT_APP_SERVER_URL
        $authHost.defaults.baseURL = env.REACT_APP_SERVER_URL
        $host.defaults.baseURL = env.REACT_APP_SERVER_URL
        $importHost.defaults.baseURL = env.REACT_APP_SERVER_URL
        $exportHost.defaults.baseURL = env.REACT_APP_SERVER_URL
        $confHost.defaults.baseURL = env.REACT_APP_SERVER_URL
        $fileHost.defaults.baseURL = env.REACT_APP_SERVER_URL
        $notesHost.defaults.baseURL = env.REACT_APP_SERVER_URL

        medoedImage.name = env.REACT_APP_LABEL
    }
}


export default Env
