// Функция проверяет видимость элемента на экране
function isScrolledIntoView(element) {
    const rect = element.getBoundingClientRect();
    const elementTop = rect.top;
    const elementBottom = rect.bottom;

    // Только полностью видимый элемент возвращает true:
    const isVisible = (elementTop >= 0) && (elementBottom <= window.innerHeight);
    // Частично видимый элемент возвращает true:
    // const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
}

// Функция скроллирует список для показа элемента, если она находится за пределами поля видимости
export function scrollElementIntoView(dataObject, idPrefix) {
    if (dataObject) {
        const element = document.getElementById(idPrefix + dataObject.id)
        if (element && !isScrolledIntoView(element))
            element.scrollIntoView({ behavior: 'smooth' })
    }                
}
