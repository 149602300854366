import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react-lite'
import { Context } from '../../..'
import FormErrorToastPanel from '../../main_page/controller/common/panels/toast/FormErrorToastPanel'
import Spinner from '../../../assets/Spinner'
import { useHotkeys } from 'react-hotkeys-hook'

/**
 * Визуальный компонент отображает форму редактирования информации о компании
 * 
 */
const RoleForm = () => {
    const { RolesStore } = useContext(Context)
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm()

    const keyRef = useHotkeys('ctrl+s', () => handleSubmit(onSubmitClick)(), { preventDefault: true, enableOnFormTags: true })
    useHotkeys('ctrl+q', () => onCancelClick(), { preventDefault: true, enableOnFormTags: true })

    const formFields = [
        {
            tech_name: 'roleName',
            alias: 'Наименование'
        },
        {
            tech_name: 'description',
            alias: 'Описание'
        }
    ]

    const onCancelClick = () => {
        RolesStore.setIsRoleCreating(false)
        RolesStore.setIsRoleEditing(false)
        RolesStore.setIsPermissionsEditing(false)
        if (RolesStore.selectedRole)
            RolesStore.getRoleDataModelPermissions(RolesStore.selectedRole.tech_id)
    }

    const onSubmitClick = async (form) => {
        const role = {}
        role.name = form.roleName.trim()
        if (form.description)
            role.description = form.description.trim()

        // sessionStorage.setItem('RoleForm', JSON.stringify(role))

        if (RolesStore.isRoleEditing)
            await RolesStore.editRole(role)
        else
            await RolesStore.createRole(role)
    }

    useEffect(() => {
        const roleFormStorage = JSON.parse(sessionStorage.getItem('RoleForm'))
        if (roleFormStorage) {
            Object.entries(roleFormStorage).forEach(([key, value]) => {
                setValue(key, value)
            })
        }
    }, [])

    useEffect(() => {
        if (Object.entries(errors).length > 0 && isSubmitting === false) {
            toast.error(<FormErrorToastPanel errors={errors} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    if (RolesStore.isRoleLoading) {
        return <Spinner/>
    }

    return (
        <form id='role-form' ref={keyRef} className='tw-h-[calc(100%_-_3rem)] tw-overflow-auto tw-flex tw-flex-col'>
            <div className='tw-text-sm tw-font-semibold tw-p-4 tw-text-gray-900'> 
                {RolesStore.isRoleEditing  ? 'Редактирование роли' : 'Новая роль'}
            </div>
            <div className='tw-grow tw-overflow-auto'>
                <div className='tw-flex tw-flex-col tw-h-full'>
                    <div className='tw-block tw-mt-2 tw-px-4'>
                        <div className='tw-flex tw-flex-row tw-w-full tw-items-center'>
                            <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-700 tw-leading-6'>Наименование</label>
                            {errors.roleName && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                        </div>
                        <input
                            className={`tw-w-full tw-h-8 tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 sm:tw-text-sm focus-visible:tw-outline-none
                                ${errors.roleName ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                            `}
                            {...register('roleName', { 
                                    required: true,
                                    value: RolesStore.isRoleDuplicating
                                                ?   RolesStore.selectedRole['name'] + ' (дубликат)'
                                                :   RolesStore.isRoleEditing
                                                        ?   RolesStore.selectedRole['name']
                                                        :   ''}
                            )}
                        />
                    </div>
                    <div className='tw-block tw-mt-2 tw-px-4'>
                        <div className='tw-flex tw-flex-row tw-w-full tw-items-center'>
                            <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-700 tw-leading-6'>Описание</label>
                            {errors.description && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                        </div>
                        <textarea
                            className={`tw-w-full tw-h-20 tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 sm:tw-text-sm focus-visible:tw-outline-none
                                ${errors.description ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                            `}
                            {...register('description', { required: false, value: RolesStore.isRoleEditing || RolesStore.isRoleDuplicating ? RolesStore.selectedRole['description'] : ''})}
                        />
                    </div>
                </div>
            </div>
            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-py-4'>
                <button 
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={handleSubmit(onSubmitClick)}
                >
                    Сохранить
                </button>
                <button
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                            hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={onCancelClick}
                >
                    Отменить
                </button>
            </div>
        </form>
    )
}

export default observer(RoleForm)