import { Fragment, useContext, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { paths } from '../../../config/constsURN'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает выпадающее меню "Сервисы"
 * 
 */
function ModuleMenu() {
    const { userStore } = useContext(Context)
    const [kiiClassName, setKiiClassName] = useState('tw-block tw-px-4 tw-py-2 tw-text-sm tw-pointer-events-none tw-text-gray-400')
    const [pdnClassName, setPdnClassName] = useState('tw-block tw-px-4 tw-py-2 tw-text-sm tw-pointer-events-none tw-text-gray-400')
    const [plannerClassName, setPlannerClassName] = useState('tw-block tw-px-4 tw-py-2 tw-text-sm tw-pointer-events-none tw-text-gray-400')
    const [eduClassName, setEduClassName] = useState('tw-block tw-px-4 tw-py-2 tw-text-sm tw-pointer-events-none tw-text-gray-400')

    useEffect(() => {
        if (userStore.modulesPermissions) {
            if (userStore.modulesPermissions?.['kii_only'])
                setKiiClassName('tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-text-gray-700')

            if (userStore.modulesPermissions?.['pdn_only'])
                setPdnClassName('tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-text-gray-700')

            if (userStore.modulesPermissions?.['planner_only'])
                setPlannerClassName('tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-text-gray-700')

            if (userStore.modulesPermissions?.['edu_only'])
                setEduClassName('tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-text-gray-700')
        }
    }, [userStore.modulesPermissions])

    return (
        <Menu as="div" className="tw-relative tw-inline-block tw-text-left">
            <Menu.Button className="tw-inline-flex tw-w-full tw-justify-center tw-gap-x-1.5 tw-rounded-md tw-text-white tw-px-3 tw-py-3 tw-text-sm
                tw-font-semibold tw-bg-gray-800 tw-shadow-sm  tw-ring-gray-300 hover:tw-bg-gray-600"
            >
                Модули
                <ChevronDownIcon className="tw-w-5 tw-h-5"/>
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="tw-transition tw-ease-out tw-duration-100"
                enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                enterTo="tw-transform tw-opacity-100 tw-scale-100"
                leave="tw-transition tw-ease-in tw-duration-75"
                leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                leaveTo="tw-transform tw-opacity-0 tw-scale-95"
            >
                <Menu.Items className="tw-absolute tw-right-0 tw-z-10 tw-mt-2 tw-w-80 tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 
                    tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
                >
                    <div className="tw-py-1">
                        <Menu.Item>
                            <a href={paths.CATEGORIZING_CII_ROUTE} className={kiiClassName}>
                                <span>Категорирование КИИ </span>
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href={paths.PERSONAL_DATA_ROUTE} className={pdnClassName}>
                                <span>Обработка и защита ПДн </span>
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href={paths.EDUCATION_ROUTE} className={eduClassName}>
                                <span>Повышение осведомленности </span><span className='tw-italic'>&middot; [Alpha]</span>
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href={paths.PLANNER_ROUTE} className={plannerClassName}>
                                <span>Планировщик </span><span className='tw-italic'>&middot; [Alpha]</span>
                            </a>
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default observer(ModuleMenu)
