import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../../../..'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import FormFieldName from '../../../../form_fields/FormFieldName'
import FormFieldInput from '../../../../form_fields/FormFieldInput'
import DirectoryContainer from '../../common/panels/directory/DirectoryContainer'
import PDataStageButtons from '../stage/PDataStageButtons'
import { observer } from 'mobx-react-lite'
import DocumentService from '../../../../../services/DocumentService'
import Spinner from '../../../../../assets/Spinner'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import { processData } from '../../../../../functions/forms'
import FormErrorToastPanel from '../../common/panels/toast/FormErrorToastPanel'
import { emptyReferenceValue, setReferenceValue } from '../../../../../config/constTypes'


/**
 * Визуальный компонент отображает этап дополнительных вопросов по ПДн
 * 
 */
const PDataAddQuestionForm = () => {   
    const {
        control,
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        clearErrors,
        setError,
        formState: { isSubmitting, errors },
    } = useForm()

    const { personalDataStore, docStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [selectedDataModelID, setSelectedDataModelID] = useState(null)
    const [selectedName, setSelectedName] = useState(null)
    const [isSavedRecord, setIsSavedRecord] = useState(false)
    const [formFields, setFormFields] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [formNestedModels, setFormNestedModels] = useState(null)
    const [isDataSaving, setIsDataSaving] = useState(false)
    const [chosenDataObjectsList, setChosenDataObjectsList] = useState([])
    
    const regexUrl = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")
    const regexUrlRu = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([а-яёА-ЯЁ0-9-\\.@:%_\+~#=]+)+((\\.[а-яёА-ЯЁ]{2,3})+)(/(.)*)?(\\?(.)*)?")

    const watchWebSite = watch('data.is_internet_site', 
                            formFields && formFields.length
                                ?   formFields.find(field => field.tech_name === 'is_internet_site')?.value
                                :   false
    )

    const handleSelectClick = (dataModelList, name, field) => {
        let fieldValue = getValues(name)
        if (fieldValue) {
            setChosenDataObjectsList([fieldValue])
        }

        let fixedDataModel
        setSelectedName(name)
        fixedDataModel = dataModelList
        setSelectedDataModelID(fixedDataModel)
        setIsSelectFormOpen(true)
    }

    const handleClearClick = (name) => {
        setValue(name, emptyReferenceValue)
        handleRecordChange()
    }

    const handleRecordChange = () => {
        setIsSavedRecord(false)
    }

    const handleDoubleClick = (item) => {
        setValue(selectedName, {value: setReferenceValue(item)})
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
        setSelectedName(null)
        setIsSavedRecord(false)
    }

    const handleCloseClick = () => {
        docStore.setIsDetailView(false);
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
    }

    const handleBackClick = () => {
        personalDataStore.goPrevStage()
    }

    const handleForwardClick = async (form) => {
        let error = false

        const dataObject = processData(form.data, personalDataStore.activeDataModel)

        if (!watchWebSite) {
            dataObject.data['website_address'] = null
        } else {
            if (!(regexUrl.test(dataObject.data['website_address']) || regexUrlRu.test(dataObject.data['website_address']))) {
                setError('data.website_address', { type: 'invalid', message: ' Неверный формат адреса сайта' })
                error = true
            }
        }

        if (!error) {
            setIsDataSaving(true)
            try {
                if (!isSavedRecord) {
                    let response
                    if (personalDataStore.questionRecordID && personalDataStore.questionRecordID.values.length > 0) {
                        await DocumentService.updateDataObject(personalDataStore.questionRecordID.values[0].record_id, dataObject)
                        await personalDataStore.resetPrintPolicyStage(personalDataStore.project.record_id)
                    } else {
                        response = await DocumentService.createDataObject(dataObject)
                        await personalDataStore.updateProject('additional_question_pdn', [response.record_id], true)
                    }

                    toast.success('Данные успешно сохранены!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                }

                personalDataStore.goNextStage()  

            } catch (error) {
                showErrorToast(error, 'saving', '')
            } finally {
                setIsDataSaving(false)
            }
        }
    }

    const loadSavedRecord = async (id) => {
        try {
            const savedRecord = await DocumentService.getOneDataObject(id)
            const fields = Object.values(savedRecord.data)
            handleSetFormFields(fields)

            setIsEditMode(true)
            if (personalDataStore.actualStageIndex >= personalDataStore.lastStageIndex)
                setIsSavedRecord(false)
            else 
                setIsSavedRecord(true)
            
        } catch (error) {
            showErrorToast(error, 'fetching', '')
            handleSetFormFields(personalDataStore.activeDataModel.fields)
        }

        return null
    }

    const handleSetFormFields = (fields) => {
        setFormFields(fields.map(field => 
            ['website_address'].includes(field.tech_name)
                ?   {...field, hide: !watchWebSite, mandatory: watchWebSite, placeholder: "https://text.text.ru/"}
                :   field
        ))
    }

    useEffect(() => {
        personalDataStore.setIsDataLoading(true)
        personalDataStore.getActiveDataModel('additional_questions_for_pdn', setFormNestedModels)
    }, [])

    useEffect(() => {
        if (personalDataStore.activeDataModel && personalDataStore.activeDataModel.id === 'additional_questions_for_pdn') {
            if (personalDataStore.questionRecordID && personalDataStore.questionRecordID.values.length > 0) {
                loadSavedRecord(personalDataStore.questionRecordID.values[0].id)
            } else {
                handleSetFormFields(personalDataStore.activeDataModel.fields)
            }

            personalDataStore.setIsDataLoading(false)
        }
    }, [personalDataStore.activeDataModel])

    useEffect(() => {
        if (formFields) {
            clearErrors()
            handleSetFormFields(formFields)
        }
    }, [watchWebSite])

    useEffect(() => {
        if(Object.entries(errors).length > 0 && isSubmitting === false){
            toast.error(<FormErrorToastPanel errors={errors.data} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <>
            <div className='tw-grow tw-overflow-auto tw-mt-2'>
                <form className='tw-flex tw-flex-col tw-h-full'>
                    <div className='tw-px-4 tw-py-1'>
                        { !formFields
                            ?
                                <Spinner />
                            :
                                formFields.slice().sort((a, b) => a.order - b.order).map((fieldItem, index) => {
                                    return ( !fieldItem.hide && fieldItem.type !== 'include' &&
                                        <div key={index} className="tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                                            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-2">
                                                <FormFieldName
                                                    item={fieldItem}
                                                    errors={errors}
                                                />
                                            </dt>
                                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                <FormFieldInput
                                                    item={fieldItem}
                                                    isEditMode={isEditMode}
                                                    isDuplicateMode={false}
                                                    onReferenceSelect={handleSelectClick}
                                                    onReferenceClear={handleClearClick}
                                                    onRecordChange={handleRecordChange}
                                                    register={register}
                                                    control={control}
                                                    setValue={setValue}
                                                    isError={errors?.data && errors?.data[fieldItem.tech_name]}
                                                />
                                            </dd>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </form>
            </div>
            <PDataStageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleSubmit(handleForwardClick)}
                disabled={isDataSaving}
            />
            <DirectoryContainer 
                isOpen={isSelectFormOpen}
                selectedDataModel={selectedDataModelID}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
                chosenDataObjectsList={chosenDataObjectsList}
            />
        </>                                                             
    )
}

export default observer(PDataAddQuestionForm)
