import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Context } from '../../../..'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import { serviceMessageTimeOut, successMessageTimeOut, menuTooltipTimeOut } from '../../../../config/constTypes'
import parse from 'html-react-parser'
import DialogTab from '../../../dialog_tab/DialogTab'
import PageTitle from '../common/panels/PageTitle'
import HelpButton from '../common/buttons/HelpButton'
import DirectoryContainer from '../common/panels/directory/DirectoryContainer'
import StageListContainer from '../common/panels/stage_list/StageListContainer'
import PDataDirectoriesInfo from './info/PDataDirectoriesInfo'
import PDataOrganizationForm from './form/PDataOrganizationForm'
import PDataPrintOrderForm from './form/PDataPrintOrderForm'
import PDataProcessListContainer from './form/process_data/PDataProcessListContainer'
import ISPDataListContainer from './form/ISPData/ISPDataListContainer'
import PDataPrintTaskForm from './form/PDataPrintTaskForm'
import PDataAddQuestionForm from './form/PDataAddQuestionForm'
import PDataRKNForm from './form/PDataRKNForm'
import PData3PartyListContainer from './form/3party_handler/PData3PartyListContainer'
import PDataEmployeesForm from './form/PDataEmployeesForm'
import Spinner from '../../../../assets/Spinner'
import { paths } from '../../../../config/constsURN'
import { getIdFromPath } from '../../../../functions/getIdFromPath'
import { LinkIcon } from '@heroicons/react/20/solid'
import { copyToClipboard } from '../../../../functions/copyToClipboard'
import { checkMethodPermissions } from '../../../../functions/checkPermissions'
import { useHotkeys } from 'react-hotkeys-hook'
import { Tooltip } from 'react-tooltip'


/**
 * Визуальный компонент реализует логику процесса обработки ПДн
 * 
 */
const PersonalDataContainer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { personalDataStore, DialogTabStore, docStore, userStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useHotkeys('ctrl+l', () => {if (personalDataStore.project) handleCopyToClipboardClick()}, { preventDefault: true })

    const loadSavedProject = async (recordID, stage) => {
        setIsLoading(true)
        await handleDoubleClick({record_id: recordID}, stage)
        docStore.setModuleType(null)
        docStore.setModuleRecordID(null)
        setIsLoading(false)
    }

    const initModule = async () => {
        await personalDataStore.loadStages()

        const projectID = getIdFromPath(location.pathname, 2)
        const savedProjects = await personalDataStore.checkSavedProject()
        if (!projectID) {
            if (savedProjects.length) {
                DialogTabStore.setParentName('PersonalDataContainer')
                DialogTabStore.setDialogTabTitle("Продолжение обработки ПДн") 
                DialogTabStore.setDialogTabText(parse(`Обнаружены сохраненные записи процесса обработки ПДн<br/><br/>Вы хотите выбрать запись и продолжить начатый ранее процесс?`))
                DialogTabStore.setDialogTabButtons(["Продолжить", "Начать заново"])
                DialogTabStore.setDialogTabIsOpen(true)
                DialogTabStore.setAllowAreaCloseClick(false)
            }
        } else {
            const foundProject = savedProjects.find(project => project.record_id === projectID)
            if (foundProject) {
                const [isRecordWrite, isRecordWriteDescription] = checkMethodPermissions(foundProject, 'write')
                if (!isRecordWrite) {
                    const foundSubGroup = userStore.userSubCompanies.find(item => item.id === Number(foundProject.subgroup_id))
                    const userAdvice = foundSubGroup
                                        ?   parse(`<p>Смените юр.лицо на "${foundSubGroup.name}" для редактирования и сохранения изменений</p>`)
                                        :   parse('<p>Вы не сможете сохранить изменения, поскольку это юр.лицо Вам недоступно.<br/>Обратитесь с данной проблемой к администратору Медоед в Вашей компании</p>')
                    toast.warning(<div><p>Запись принадлежит другому юр.лицу!</p><br/>{userAdvice}</div>, 
                                { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    const textIndex = location.search.replace('?stage=', '')
                    const stageIndex = Number.isInteger(+textIndex) ? Number(textIndex) : 0
                    loadSavedProject(projectID, stageIndex)
                }
    
            } else {
                toast.error(<div><p>Запись по указанной ссылке не найдена!</p><br/>
                    <p>Возможно, данная запись принадлежит юр.лицу, которое Вам недоступно!</p>
                    <p>Обратитесь с данной проблемой к администратору Медоед в Вашей компании</p></div>, 
                    { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            }
        }
    }

    const openSelectForm = () => {
        DialogTabStore.setDialogTabIsOpen(false)
        setIsSelectFormOpen(true)
    }

    const handleDoubleClick = async (item, stageIndex) => {
        let lastStageIndex = await personalDataStore.loadSavedProject(item.record_id)
        setIsSelectFormOpen(false)       
        if (lastStageIndex > 0) {
            toast.success('Сохраненная запись успешно загружена', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
        
            const selectedStageIndex = (stageIndex > 0 && stageIndex < lastStageIndex) ? stageIndex : lastStageIndex
            const selectedStage = personalDataStore.allStages.find(stage => stage.data['id_of_stage'].value === selectedStageIndex)
            const isProjectFinished = personalDataStore.allStages.length === lastStageIndex && personalDataStore.project.data['date_of_print_policy_pdn'].value
            if (selectedStage) {
                personalDataStore.setStage(selectedStage)
                personalDataStore.setActualStageIndex(selectedStageIndex)
                personalDataStore.setAllStages(personalDataStore.allStages.map(stage => 
                    ((stage.data['id_of_stage'].value < lastStageIndex) || (stage.data['id_of_stage'].value === lastStageIndex && isProjectFinished))
                        ?   {...stage, status: 'finished'}
                        :   stage
                ))
            }
        } else {
            navigate(paths.PERSONAL_DATA_ROUTE)
        }
    }

    const handleCloseClick = () => {
        setIsSelectFormOpen(false)
        toast.info('Обработка ПДн будет начата заново', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
    }

    const handleHideStageClick = (isVisible) => {
        personalDataStore.setHideStageList(isVisible)
    }

    const handleJumpStageClick = (item) => {
        if (item.status === 'finished' || item.data['id_of_stage'].value === personalDataStore.lastStageIndex) {
            personalDataStore.setStage(item)
            personalDataStore.setActualStageIndex(item.data['id_of_stage'].value)
        }
    }

    const handleCopyToClipboardClick = () => {
        try {
            copyToClipboard(`${paths.PERSONAL_DATA_ROUTE}/${personalDataStore.project.record_id}/?stage=${personalDataStore.actualStageIndex}`)
            toast.info('Ссылка скопирована в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
        } catch (error) {
            toast.error('Не удалось скопировать ссылку в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }
    }

    useEffect(() => {
        initModule()
    }, [])

    useEffect(() => {
        if (personalDataStore.project && personalDataStore.actualStageIndex && personalDataStore.actualStageIndex > 0)
            navigate(`${paths.PERSONAL_DATA_ROUTE}/${personalDataStore.project.record_id}/?stage=${personalDataStore.actualStageIndex}`)
    }, [personalDataStore.actualStageIndex])

    return (
        <>
            <PageTitle title={'Обработка ПДн'}/>
            <div id='personal-data-page' className={`${personalDataStore.hideStageList ? 'lg:tw-grid-cols-[2rem_1fr] lg:tw-gap-x-4' : 'lg:tw-grid-cols-4 lg:tw-gap-x-8'} tw-duration-500 lg:tw-grid lg:tw-max-h-full lg:tw-h-full max-lg:tw-w-full`}>
                <StageListContainer
                    title={'Этапы'}
                    list={personalDataStore.allStages}
                    activeStage={personalDataStore.stage}
                    idField='id_of_stage'
                    lastStageIndex={personalDataStore.lastStageIndex}
                    isHideList={personalDataStore.hideStageList}
                    onHideClick={handleHideStageClick}
                    onItemClick={handleJumpStageClick}
                />
                <div id='stage-column' className={`${!personalDataStore.hideStageList && 'lg:tw-col-span-3'} tw-duration-500 tw-bg-white tw-rounded-md tw-mt-8 lg:tw-mt-0 lg:tw-flex lg:tw-flex-col lg:tw-h-full lg:tw-overflow-hidden`}>
                    { isLoading
                        ?   
                            <Spinner />
                        :
                            personalDataStore.allStages && personalDataStore.stage && 
                            <>
                                <div id='stage-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-4 tw-px-4 tw-py-2'>
                                    <div className='tw-font-semibold'>{personalDataStore.stage.data.name.value}</div>
                                    <div className='tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                                        <HelpButton  info={personalDataStore.stage.data['hint_for_stages'].value}/>                                
                                        <button
                                            className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-300'
                                            onClick={handleCopyToClipboardClick}
                                            disabled={!personalDataStore.project}
                                            data-tooltip-id="personal-data-tooltip"
                                            data-tooltip-html="Копировать ссылку в буфер обмена &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+L)"
                                            data-tooltip-delay-show={menuTooltipTimeOut}
                                        >
                                            <LinkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                        </button>
                                    </div>
                                </div>
                                { personalDataStore.stage.data['id_of_stage'].value === 1 &&
                                    <PDataDirectoriesInfo/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 2 &&
                                    <PDataEmployeesForm/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 3 &&
                                    <PDataOrganizationForm/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 4 &&
                                    <PDataPrintOrderForm/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 5 &&
                                    <ISPDataListContainer/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 6 &&
                                    <PDataProcessListContainer/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 7 &&
                                    <PDataAddQuestionForm/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 8 &&
                                    <PDataRKNForm/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 9 &&
                                    <PData3PartyListContainer/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 10 &&
                                    <PDataPrintTaskForm/>
                                }
                            </>
                    }
                </div>
            </div>
            <DialogTab
                parentName='PersonalDataContainer'
                dialogTabFunction={openSelectForm}
            />
            <DirectoryContainer 
                isOpen={isSelectFormOpen}
                selectedDataModel={'stages_of_documentation_pdn'}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
            />
            <Tooltip id="personal-data-tooltip" className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
        </>
    )
}

export default observer(PersonalDataContainer)