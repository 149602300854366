import React from 'react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'


/**
 * Визуальный компонент для отображения имени категории для группы полей формы
 * 
 * @param {Object[]} categories Список категорий полей формы
 * @param {Object} fieldItem Поле
 * 
 */
const FieldCategory = ({categories, fieldItem}) => {
    const foundCategory = categories.find(item => item.tech_name === fieldItem.tech_name)

    if (!foundCategory) return null

    return (
        <>
            <div className='tw-mt-6 tw-mb-4'>
                <div className='tw-flex tw-flex-row tw-items-center tw-gap-x-2'>
                    <span className='tw-text-md tw-font-semibold'>
                        <span>
                            {foundCategory.name}
                        </span>
                        &nbsp;
                        <span className='tw-text-red-400'>{foundCategory.mandatory ? ' *' : ''}</span>
                    </span>
                    { foundCategory.description !== '' &&
                        <span
                            data-tooltip-id="field-category-tooltip"
                            data-tooltip-content={foundCategory.description}
                            data-tooltip-delay-show={500}
                        >
                            <InformationCircleIcon
                                    className='tw-h-5 tw-w-5 tw-text-gray-500 tw-cursor-help'
                                    aria-hidden="true"
                            />
                        </span> 
                    }
                </div>
            </div>
            <Tooltip id="field-category-tooltip" place="top" className='tw-max-w-xl'/>
        </>
    )
}

export default FieldCategory