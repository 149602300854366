import React from 'react'


/**
 * Визуальный компонент отображает название одного этапа процесса
 * 
 * @param {Object} item Этап процесса
 * @param {Object} activeItem Текущий этап процесса
 * @param {String} idField Имя поля с номером этапа 
 * @param {Boolean} isHideList Признак скрытия списка этапов 
 * @param {Function} onItemClick Обработчик клика мыши для перехода обратно к произвольному завершенному этапу
 * 
 */
const TopicItem = ({item, activeItem, idField, isHideList, onItemClick}) => {
    return (
        <div 
            className={`tw-text-sm tw-border-b tw-select-none
                        ${activeItem?.id === item.id 
                            ?   ' tw-text-white tw-bg-gray-500'
                            :   item.status === 'finished'
                                ?   ' tw-text-gray-900 tw-bg-teal-500 tw-cursor-pointer hover:tw-bg-teal-400'
                                :   ' tw-text-gray-900 tw-bg-white tw-cursor-pointer hover:tw-bg-gray-100'
                        }
            `}
            onClick={() => onItemClick(item)}
        >
            <div 
                className={`${isHideList && 'tw-text-center'} tw-p-2 tw-truncate`}
                data-tooltip-id="topic-list" data-tooltip-content={`${isHideList ? item.data[idField].value : item.data['name'].value}`}
                data-tooltip-delay-show={1000}
            >
                {isHideList 
                    ? <>{item.data[idField].value}</>
                    : <>{item.data['name'].value}</>
                }
            </div>
        </div>
    )
}

export default TopicItem