import React, { useContext, useEffect, useState } from 'react'
import { ArrowLeftOnRectangleIcon, DocumentPlusIcon, PencilSquareIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { observer } from 'mobx-react-lite'
import { Context } from '../../..'

/**
 * Визуальный компонент отображает меню возможных действий над пользователями
 * 
 */
const MyCompanyUserMenu = () => {
    const { CompanyAdminStore, userStore } = useContext(Context)
    const [userAssigned, setUserAssigned] = useState(false)

    const onEditItemClick = () => {
        CompanyAdminStore.setIsUserEditing(true) 
    }

    const onAddItemClick = () => {
        CompanyAdminStore.setIsUserCreating(true)
        CompanyAdminStore.setSelectedUser(null)
    }

    const bindUser = () => {
        CompanyAdminStore.rebindMyUsers({users: {add: [CompanyAdminStore.selectedUser.id]}})
    }

    const unBindUser = () => {
        CompanyAdminStore.rebindMyUsers({users: {remove: [CompanyAdminStore.selectedUser.id]}})
    }

    useEffect(() => {
        if (CompanyAdminStore.selectedUser && CompanyAdminStore.selectedSubCompany) {
            if (CompanyAdminStore.selectedUser.allowed_sub_company_ids.find(item => item === CompanyAdminStore.selectedSubCompany.id)) {
                setUserAssigned(true)
            } else {
                setUserAssigned(false)
            }
        }
    }, [CompanyAdminStore.selectedUser, CompanyAdminStore.selectedSubCompany])

    return (
        <div id='my-company-user-menu' 
            className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center 
                        tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'
        >
            <p className='tw-text-xs tw-font-semibold tw-w-24 md:tw-text-base sm:tw-w-96'>Пользователи</p>
                <div className='tw-flex tw-flex-row tw-gap-x-1'>
                    <button
                        className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                        onClick={(e) => {
                            e.preventDefault()
                            if(userAssigned)
                                unBindUser()
                            else 
                                bindUser()
                        }}
                        disabled={!CompanyAdminStore.selectedSubCompany || !CompanyAdminStore.selectedUser || CompanyAdminStore.isSubCompanyCreating || CompanyAdminStore.isUserCreating || CompanyAdminStore.isUserEditing}
                        data-tooltip-id="my-company-menu-tooltip" data-tooltip-content={userAssigned ? 'Отвязать' : 'Привязать'}
                    >
                        {userAssigned 
                            ? <ArrowRightOnRectangleIcon  className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            : <ArrowLeftOnRectangleIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        }
                    </button>
                    <button 
                        className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                        onClick={onAddItemClick}
                        disabled={CompanyAdminStore.isUserCreating || CompanyAdminStore.isUserEditing || !CompanyAdminStore.selectedSubCompany || !userStore.checkPermission("company.my.user:edit")}
                        data-tooltip-id="my-company-menu-tooltip" data-tooltip-delay-show={1000}
                        data-tooltip-content={`${!userStore.checkPermission("company.my.user:edit") 
                            ? 'Для создания пользователей запросите повышение привилегий у администратора' 
                            : 'Создать пользователя'}`}
                    >
                        <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    </button>
                    <button 
                        className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                        onClick={() => onEditItemClick(CompanyAdminStore.selectedUser.id)}
                        disabled={!CompanyAdminStore.selectedUser || CompanyAdminStore.isUserCreating || CompanyAdminStore.isUserEditing || !userStore.checkPermission("company.my.user:edit")}
                        data-tooltip-id="my-company-menu-tooltip" data-tooltip-delay-show={1000}
                        data-tooltip-content={`${!userStore.checkPermission("company.my.user:edit") 
                            ? 'Для редактирования пользователей запросите повышение привилегий у администратора' 
                            : 'Редактировать пользователя'}`}
                    >
                        <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    </button>
                </div>
            <Tooltip id="my-company-menu-tooltip" className="tw-max-w-sm" place="top"/>
        </div>
    )
}

export default observer(MyCompanyUserMenu)