import React, { useContext, useState } from 'react'
import { Context } from '../../../..'
import { observer } from 'mobx-react-lite'
import parse from 'html-react-parser'
import DocumentService from '../../../../services/DocumentService'
import EventCalendar from './panels/calendar/EventCalendar'
import PlannerTable from './panels/action/PlannerTable'
import ActionFormContainer from './panels/action/form/ActionFormContainer'
import DoneFormContainer from './panels/action/form/DoneFormContainer'
import DialogTab from '../../../dialog_tab/DialogTab'
import PlannerFiltersContainer from './panels/action/filters/PlannerFiltersContainer'
import { showErrorToast } from '../../../../functions/errorHandlers'

/**
 * Визуальный компонент отображает основную панель Планировщика
 * 
 */
const PlannerPanel = () => {
    const { plannerStore, DialogTabStore, docStore, userStore  } = useContext(Context)

    const [editedActionInfo, setEditedActionInfo] = useState(null)
    const [editedActionPeriodicity, setEditedActionPeriodicity] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const finishActionAddition = () => {
        plannerStore.requestListUpdate()
        plannerStore.setIsActionFormOpen(false)
        plannerStore.setEditMode(null)
        plannerStore.setCalendarDate(null)
        if (plannerStore.selectedTab === 'calendar') {
            plannerStore.getCalendarActions(userStore.user.email, plannerStore.calendarRange)
        }
    }

    const handleAddPlannedActionClick = async (form, attachedFiles) => {
        setIsLoading(true)

        const myAction = await plannerStore.createMyAction(form)

        plannerStore.getActions('my_actions', [], false)

        if (form.data.isPeriodic) {
            let createdDataObjects = plannerStore.formatPeriodicActions(myAction, form, attachedFiles)
            docStore.createDataObjectsPool(createdDataObjects, Object.values(createdDataObjects[0].data), setIsLoading, () => {}, finishActionAddition)

        } else {
            const createdDataObject = plannerStore.formatPlannedAction(myAction, form.data.responsibleUser, form.data.startDate, form.data.action_description, form.data.name, attachedFiles)                      
            docStore.createDataObject(createdDataObject, Object.values(createdDataObject.data), setIsLoading, () => {}, finishActionAddition)
        }
    }

    const periodicActionEdition = () => {
        plannerStore.setIsActionFormOpen(false)
        plannerStore.setEditMode(null)
        if (plannerStore.periodicActions.length) {
            DialogTabStore.setParentName('PlannerPanel')
            DialogTabStore.setDialogTabTitle('Изменение последующих действий')
            DialogTabStore.setDialogTabText(parse(`Хотите изменить все последующие действия данного типа?`))
            DialogTabStore.setDialogTabButtons(["Да", "Нет"])
            DialogTabStore.setDialogTabIsOpen(true)
            plannerStore.setIsUpdatingActionList(true) 
        } else {
            plannerStore.requestListUpdate()
        }
    }

    const handleEditPlannedActionClick = async (form, attachedFiles) => {
        setIsLoading(true)
        await plannerStore.getPeriodicActions()
        setEditedActionPeriodicity(plannerStore.editedMyAction.data['action_periodicity'].value)

        if (form.data.name !== plannerStore.editedMyAction.data['name'].value) {
            const myAction = {}
            myAction.data = {}
            myAction.data_model_id = 'my_actions'
            myAction.data['name'] = form.data.name
            try {
                await DocumentService.updateDataObject(plannerStore.editedMyAction.record_id, myAction)                                    
            } catch (error) {
                showErrorToast(error, 'saving', '')               
            }
        }

        const editedDataObject = plannerStore.formatPlannedAction(plannerStore.editedMyAction, form.data.responsibleUser, form.data.startDate, form.data.action_description, form.data.name, attachedFiles)
        setEditedActionInfo(editedDataObject)
        docStore.updateDataObject(plannerStore.selectedAction.record_id, editedDataObject, Object.values(editedDataObject.data), setIsLoading, () => {}, periodicActionEdition)
    }

    const handleEditPlannedActions = async () => {
        await plannerStore.editPeriodicActions(editedActionInfo, editedActionPeriodicity)

        DialogTabStore.setDialogTabIsOpen(false)
        plannerStore.requestListUpdate()
        plannerStore.setPeriodicActions([])
        setEditedActionInfo(null)
        setEditedActionPeriodicity(0)
        if (plannerStore.selectedTab === 'calendar') {
            plannerStore.getCalendarActions(userStore.user.email, plannerStore.calendarRange)
        }
    }

    const handleActionFormClose = () => {
        plannerStore.setIsActionFormOpen(false)
        plannerStore.setEditMode(null)
        if (plannerStore.selectedTab === 'calendar') {
            plannerStore.getCalendarActions(userStore.user.email, plannerStore.calendarRange)
        }
    }

    const handleMarkActionAsDone = () => {
        plannerStore.setIsActionFormOpen(false)
        plannerStore.setIsDoneFormOpen(true)
    }

    return (
        <>
            <div id='planner-column' className='tw-h-full tw-grow tw-p-0 tw-flex tw-flex-col tw-text-sm'>
                { plannerStore.selectedTab === 'actions' &&
                    <PlannerTable/>
                }
                { plannerStore.selectedTab === 'calendar' &&
                    <EventCalendar/>
                }
            </div>
            <DialogTab
                parentName='PlannerPanel'
                dialogTabFunction={handleEditPlannedActions}
            />
            <ActionFormContainer
                isOpen={plannerStore.isActionFormOpen}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                onAddActionClick={handleAddPlannedActionClick}
                onEditActionClick={handleEditPlannedActionClick}
                onMarkActionClick={handleMarkActionAsDone}
                onCloseClick={handleActionFormClose}
            />
            <DoneFormContainer
                isOpen={plannerStore.isDoneFormOpen}
            />
            <PlannerFiltersContainer/>
        </>
    )
}

export default observer(PlannerPanel)