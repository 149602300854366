import { Fragment, useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import ReportPlannerMenu from './ReportPlannerMenu'
import ReportEducationMenu from './ReportEducationMenu'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает выпадающее меню "Отчеты"
 * 
 */
function ReportMenu() {
    const { userStore } = useContext(Context)

    return (
        <Menu as="div" className="tw-relative tw-inline-block tw-text-left">
            <Menu.Button className="tw-inline-flex tw-w-full tw-justify-center tw-gap-x-1.5 tw-rounded-md tw-text-white tw-px-3 tw-py-3 tw-text-sm 
                tw-font-semibold tw-bg-gray-800 tw-shadow-sm  tw-ring-gray-300 hover:tw-bg-gray-600"
            >
                Отчеты
                <ChevronDownIcon className="tw-w-5 tw-h-5"/>
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="tw-transition tw-ease-out tw-duration-100"
                enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                enterTo="tw-transform tw-opacity-100 tw-scale-100"
                leave="tw-transition tw-ease-in tw-duration-75"
                leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                leaveTo="tw-transform tw-opacity-0 tw-scale-95"
            >
                <Menu.Items className="tw-absolute tw-right-0 tw-z-10 tw-mt-2 tw-w-72 tw-origin-top-right tw-rounded-md tw-bg-white tw-py-1 tw-shadow-lg 
                    tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
                >
                    <Menu.Item>
                        {({ active }) => (
                            <div className={`${(userStore.modulesPermissions?.['planner_only'] && active) ? 'tw-bg-gray-300' : ''} 
                                    tw-text-gray-700 tw-block tw-text-sm tw-text-left`}
                            >
                                <ReportPlannerMenu/>
                            </div>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <div className={`${(userStore.modulesPermissions?.['edu_only'] && active) ? 'tw-bg-gray-300' : ''} 
                                    tw-text-gray-700 tw-block tw-text-sm tw-text-left`}
                            >
                                <ReportEducationMenu/>
                            </div>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default observer(ReportMenu)
