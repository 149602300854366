import { makeAutoObservable } from "mobx"
import { responseTimeOut, serviceMessageTimeOut } from "../config/constTypes"
import NotesService from "../services/NotesService"
import { toast } from "react-toastify"
import { showErrorToast } from "../functions/errorHandlers"

/**
 * Класс реализует хранилище информации о полученных от сервера сообщениях
 * @class
 * @property {Object[]} notes  Массив записей
 * @property {Boolean} isLoading Признак загрузки панели сообщений
 * @property {Boolean} isUpdatingNotes Признак запроса на загрузку сообщений
 * @property {Boolean} error Ошибка
 * @property {Number} offset Смещение при получении сообщений
 * @property {Number} totalCount Общее количество сообщений
 * @property {String} recordID id записи
 */
class RecordNotesStore {
    notes=[]
    isLoading = false
    isUpdatingNotes = false
    error = false
    columnName= "created"
    sortDirection= true
    offset = 0
    totalCount=0
    recordID = null

    /**
    * Конструктор с указанием, что все свойства класса observable
    * @constructor
    */
    constructor(){
        makeAutoObservable(this) 
    }

    /**
    * Метод осуществляет сохранение полученного массива сообщений
    * @method
    * 
    * @param {Object[]} notes Массив сообщений
    */
    setNotes(notes) {
        this.notes = notes
    }

    /**
    * Метод осуществляет сохранение признака загрузки сообщений
    * @method
    * 
    * @param {Boolean} bool Признак загрузки сообщений
    */
    setIsLoading(bool) {
        this.isLoading = bool
    }

    /**
    * Метод осуществляет сохранение признака запроса на загрузку сообщений
    * @method
    * 
    * @param {Boolean} bool Признак запроса на загрузку сообщений
    */
    setIsUpdatingNotes(bool) {
        this.isUpdatingNotes = bool
    }

    /**
    * Метод осуществляет сохранение признака наличия ошибки
    * @method
    * 
    * @param {Boolean} bool Признак наличия ошибки
    */
    setError(bool) {
        this.error = bool
    }

    /**
    * Метод осуществляет сохранение информации о смещении
    * @method
    * 
    * @param {Number} offset Значение смещения
    */
    setOffset(offset) {
        this.offset = offset
    }

    /**
    * Метод осуществляет сохранение информации об общем количестве сообщений
    * @method
    * 
    * @param {Number} count Общее количество сообщений
    */
    setTotalCount(count) {
        this.totalCount = count
    }

    /**
    * Метод осуществляет сохранение информации об id записи
    * @method
    * 
    * @param {String} id id записи
    */
    setRecordID(id) {
        this.recordID = id
    }

    /**
     * Метод осуществляет добавление сообщения
     * @method
     * 
     * @param {String} note Сообщение
     * @param {String} id  id записи
     * @param {Function} handleDataFetching Запрос на получение новых записей
     */
    addNote(id, note, handleDataFetching) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис сообщений не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setIsLoading(false)
            this.setError(true)
        }, responseTimeOut)
        
        NotesService
            .addNote(id, note)
            .then(() => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                this.fetchNotes('created', true, [], 0, handleDataFetching)
                this.setIsUpdatingNotes(true)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
                this.setIsLoading(false)
                this.setError(true)
            })
    }

    /**
    * Метод осуществляет получение отсортированного массива сообщений
    * @method
    * 
    * @param {String} field Поле сортировки
    * @param {Boolean} desc  Направление сортировки
    * @param {Object[]} notes  Массив загруженных сообщений
    * @param {Number} offset  Смещение при получении сообщений
    * @param {Function} onFetchData  Функция обработки полученных данных
    */
    async fetchNotes(field, desc, notes, offset, onFetchData) {
        if (this.recordID) {
            this.setIsUpdatingNotes(true)

            const noResponse = setTimeout(() => {
                toast.error('Сервис сообщений не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                this.setIsLoading(false)
                this.setError(true)
            }, responseTimeOut)

            let addedData = []

            NotesService
                .getNotes(field, desc, this.recordID, offset, []) 
                .then(([data, totalCount])  => {
                    clearTimeout(noResponse)
                    this.setIsUpdatingNotes(false)
                    if (!offset) {
                        addedData = data
                    } else {
                        addedData = notes.concat(data)
                    }
    
                    onFetchData(addedData, totalCount, offset + data.length)
                })
                .catch(error => {
                    clearTimeout(noResponse)
                    showErrorToast(error, 'fetching', '')
                    this.setIsUpdatingNotes(false)
                    this.setError(true)
                })
        }
    }

        /**
    * Метод осуществляет получение массива всех сообщений для множества записей
    * @method
    * 
    * @param {String[]} ids Массив ID записей
    */
    async fetchMultiRecordNotes(ids) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис сообщений не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        try {
            let allNotes = []
            let offset = 0
            const count = 50
    
            do {
                const lastIndex = offset + count < ids.length ? offset + count : ids.length
                const selectedIDs = ids.slice(offset, lastIndex)
                const selectedFilters = JSON.stringify([
                    {property: "target[\"id\"]", operator: "in", value: selectedIDs},
                ])
                const selectedSorters = JSON.stringify([
                    {property: 'created', desc: false},
                ])
                const notes = await NotesService.getAllNotes(selectedFilters, selectedSorters) 
                allNotes = allNotes.concat(notes)
                offset += count
            } while (offset < ids.length)

            clearTimeout(noResponse)

            return allNotes
            
        } catch (error) {
            clearTimeout(noResponse)
            showErrorToast(error, 'fetching', '')

            return []
        }
    }
    
}

export default RecordNotesStore