import React from 'react'
import { paths } from '../../../config/constsURN'


/**
 * Визуальный компонент отображает список отчетов для модуля Повышения осведомленности
 * 
 */
function ReportEducationPanel() {
    return (
        <>
            <a
                href={paths.REPORT_EDUCATION_ROUTE + '/total'}
                className='tw-block tw-px-4 tw-py-2 tw-text-sm
                    hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-text-gray-700'
            >
                <span>Отчет "Общий"</span>
            </a>
            <a
                href={paths.REPORT_EDUCATION_ROUTE + '/attendance'}
                className='tw-block tw-px-4 tw-py-2 tw-text-sm
                    hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-text-gray-700'
            >
                <span>Отчет "Посещаемость"</span>
            </a>
        </>        
    )
}

export default ReportEducationPanel
