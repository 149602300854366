import React from 'react'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { observer } from 'mobx-react-lite'
import { Tooltip } from 'react-tooltip'

/**
 * Визуальный компонент отображает кнопки переключения между объектами категорирования КИИ
 * 
 * @param {Function} onBackClick Обработчик клика мыши для возвращения к предыдущему объекту
 * @param {Function} onForwardClick Обработчик клика мыши для перехода к следующему объекту
 * @param {Boolean} disabled Признак возможности перехода к следующему объекту категорирования 
 * @param {Object} stage Текущий активный объект категорирования
 * @param {Object[]} objectsList Список существующих объектов категорирования
 * @param {String} forwardButtonTitle Заголовок кнопки далее
 * @param {String} backButtonTitle Заголовок кнопки назад
 * 
 */
const ObjectsListButtons = ({onBackClick, onForwardClick, disabled, stage, objectsList, forwardButtonTitle, backButtonTitle}) => {
    return (
        <div className='tw-flex tw-justify-between tw-items-center tw-gap-x-4 tw-w-full tw-h-12 tw-mx-auto tw-px-8 tw-py-2 tw-border-t tw-mt-1'>
            { stage && stage.value > 0
                ?
                    <button
                        className='tw-flex tw-flex-row tw-items-start tw-gap-x-2 
                                tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                                hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                        onClick={(e) => {e.preventDefault(); onBackClick(!disabled)}}
                    >
                        <ChevronDoubleLeftIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                        <span>{backButtonTitle}</span>
                    </button>
                :
                    <div></div>
            }
            { stage && stage.value < (objectsList.length - 1)
                ?
                    <div className='tw-flex tw-flex-row tw-items-center tw-gap-x-2'>
                        { disabled && stage.help &&           
                            <QuestionMarkCircleIcon 
                                className="tw-h-6 tw-w-6 tw-text-red-400 focus:tw-rounded-full focus:tw-outline-red-400" aria-hidden="true"
                                data-tooltip-id="object-stage-help-tooltip" data-tooltip-content={stage.help}
                            />
                        }
                        <button 
                            className='tw-flex tw-flex-row tw-items-start tw-gap-x-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  
                                    tw-bg-gray-700 tw-text-white hover:tw-bg-gray-500 disabled:tw-bg-gray-300  disabled:tw-border-gray-300
                                    focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                    disabled={disabled}
                            onClick={onForwardClick}
                        >
                            <span>{forwardButtonTitle}</span>
                            <ChevronDoubleRightIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                        </button>
                    </div>
                :
                    <div></div>
            }
            <Tooltip id="object-stage-help-tooltip" place="top-start"/>
        </div>
    )
}

export default observer(ObjectsListButtons)