import { Tab } from '@headlessui/react'
import Spinner from '../../../assets/Spinner'
import { observer } from 'mobx-react-lite'
import NestedModelMenu from './NestedModelMenu'
import NestedTableFieldsList from './NestedTableFieldsList'
import { useState } from 'react'


/**
 * Визуальный компонент отображает файлы, сообщения и вложенные таблицы выбранной записи 
 * 
 * @param {Boolean} readOnly Признак "только чтение" для файлов
 * @param {Object[]} list Массив записей таблицы
 * @param {Object} selectedNestedDataModel Выбранная вложенная таблица
 * @param {Function} setNestedDataModels Изменение в массиве вложенных таблицы
 * @param {Object} selectedNestedDataObject Выбранная запись вложенной таблицы
 * @param {Function} setSelectedNestedDataObject Изменение выбранной записи вложенной таблицы
 * @param {Object[]} nestedDataModels Массив вложенных таблиц
 * @param {Boolean} isDocumentPage Признак нахождения на странице документов
 * @param {Function} onNestedTableChange Сторонняя функция для изменений в массиве вложенных таблицы
 * @param {Boolean} isNestedLoading Признак загрузки вложенных таблиц
 * @param {Object} panel Панель, где находятся элементы
 * 
 */
const NestedTableTabPanel = ({readOnly, list, selectedNestedDataModel, setNestedDataModels, selectedNestedDataObject, 
                            setSelectedNestedDataObject, nestedDataModels, isDocumentPage, onNestedTableChange, isNestedLoading, panel, panelID}) => {

    const [isAddFormOpen, setIsAddFormOpen] = useState(false)
    const [isDuplicateFormOpen, setIsDuplicateFormOpen] = useState(false)
    const [isEditFormOpen, setIsEditFormOpen] = useState(false)    

    return (
        <Tab.Panel
            className={`${isDocumentPage
                            ?   readOnly
                                    ? 'tw-h-full'
                                    : 'tw-h-[calc(100%_-_2.5rem)]'
                            :   ''} 
                        tw-w-full tw-ring-white tw-ring-opacity-60 tw-ring-offset-2
                        focus:tw-outline-none focus:tw-ring-offset-0`}
        >
            {!isNestedLoading && selectedNestedDataModel
                ?
                    <>
                        {!readOnly && 
                            <NestedModelMenu
                                nestedDataModels={nestedDataModels}
                                selectedNestedDataModel={selectedNestedDataModel}
                                selectedNestedDataObject={selectedNestedDataObject}
                                setNestedDataModels={setNestedDataModels}
                                setSelectedNestedDataObject={setSelectedNestedDataObject}                                
                                isDocumentPage={isDocumentPage}
                                onChange={onNestedTableChange}
                                setIsAddFormOpen={setIsAddFormOpen}
                                setIsDuplicateFormOpen={setIsDuplicateFormOpen}
                                setIsEditFormOpen={setIsEditFormOpen}
                            />
                        }
                        <NestedTableFieldsList
                            readOnly={readOnly}
                            dataModel={selectedNestedDataModel}
                            dataObjects={list}
                            nestedDataModels={nestedDataModels}
                            selectedNestedDataObject={selectedNestedDataObject}
                            setNestedDataModels={setNestedDataModels}
                            setSelectedNestedDataObject={setSelectedNestedDataObject}
                            isDocumentPage={isDocumentPage}
                            onChange={() => {}}
                            panel={panel}
                            panelID={panelID}
                            isAddFormOpen={isAddFormOpen}
                            setIsAddFormOpen={setIsAddFormOpen}
                            isDuplicateFormOpen={isDuplicateFormOpen}
                            setIsDuplicateFormOpen={setIsDuplicateFormOpen}
                            isEditFormOpen={isEditFormOpen}
                            setIsEditFormOpen={setIsEditFormOpen}
                        />
                    </>
                : <Spinner size='small'/>
            }
        </Tab.Panel>
    )
}

export default observer(NestedTableTabPanel)