import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom';
import { responseTimeOut } from '../../../config/constTypes';
import AuthService from '../../../services/AuthService';
import { paths } from '../../../config/constsURN';
import Spinner from '../../../assets/Spinner';


/**
 * Компонент реализует логику активации учетной записи пользователя
 * 
 */
const ActivateUser = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isSuccess, setIsSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const location = useLocation()

    const activateUser = async (payload) => {
        const noResponse = setTimeout(() => {
            setIsLoading(false)
            setIsSuccess(false)
            setErrorMessage('Сервис аутентификации не отвечает')
        }, responseTimeOut)

        AuthService
            .activateUser(payload)
            .then(() => {
                clearTimeout(noResponse)
                setIsLoading(false)
                setIsSuccess(true)
            })
            .catch(error => {
                setIsSuccess(false)
                setIsLoading(false)
                setErrorMessage('')
                clearTimeout(noResponse)
                
                switch (error?.response?.status) {
                        case 400:
                            setErrorMessage('Проверьте корректность ссылки')
                            break
                        case 403:
                            setErrorMessage('Доступ запрещен')
                            break
                        case 404:
                            setErrorMessage('Пользователь не найден')
                            break
                        case 409:
                            setErrorMessage('Такой пользователь уже существует')
                            break
                        case 410:
                            setErrorMessage('410')
                            break
                        default:
                            setErrorMessage('Возникла непредвиденная ошибка!')
                    }
            })
    }

    useEffect(() => {
        const payload = new URLSearchParams(location.search)
        activateUser(payload.get('payload'))
    }, [])

    return (
        <>
           {isLoading 
                ?   <Spinner/>
                :   <>
                        {isSuccess 
                            ?   <div className='tw-mx-auto tw-p-12 tw-text-center tw-text-xl'>
                                    <p className='tw-font-bold'>Активация Вашей учетной записи в системе MEDOED прошла успешно!</p>
                                    <p className='tw-mt-4'>
                                        Для начала работы, необходимо 
                                        <a className='tw-font-semibold tw-text-sky-700 hover:tw-text-sky-500 focus-visible:tw-outline-none
                                            focus-visible:tw-text-sky-500' href={paths.LOGIN_ROUTE}
                                        > авторизоваться</a>
                                    </p>
                                    <p className='tw-mt-4'>Желаем приятной работы!</p>
                                </div>
                            :   <div className='tw-mx-auto tw-p-12 tw-text-center tw-text-xl'>
                                    <p className='tw-font-bold'>Ошибка при активации учетной записи!</p>
                                    {errorMessage !== '410'
                                        ? <p className='tw-mt-4'>{errorMessage}</p> 
                                        :   <p className='tw-mt-4'>
                                                К сожалению срок действия ссылки истёк. <br/>
                                                Для получения новой ссылки необходимо заново<br/> пройти процесс 
                                                <a className='tw-font-semibold tw-text-sky-700 hover:tw-text-sky-500 focus-visible:tw-outline-none
                                                    focus-visible:tw-text-sky-500' href={paths.REGISTRATION_ROUTE}
                                                > регистрации</a>
                                            </p>
                                    }
                                </div>
                        }
                    </>
            }
        </>
    )
}

export default observer(ActivateUser)