import React, { useContext, useState } from 'react'
import { Context } from '../../../../../..'
import { observer } from 'mobx-react-lite'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import ruLocale from '@fullcalendar/core/locales/ru'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'


/**
 * Визуальный компонент отображает календарь с запланированными действиями
 * 
 */
const EventCalendar = () => {
    const { plannerStore, userStore } = useContext(Context)

    const [selectedDate, setSelectedDate] = useState(null)

    const additionalButtons = {
        filters: {
            text: 'Фильтры',
            click: function() {
                plannerStore.setIsFiltersOpen(true)
                plannerStore.setIsPlannedFilters(true)
            }
        }
    }
    
    const headerFormat = {
        left: 'prevYear,prev today next,nextYear',
        center: 'title',
        right: 'filters dayGridMonth,timeGridWeek,timeGridDay,listMonth'
    }
    
    const buttonsText = {
        list: 'Расписание'
    }
    
    const eventTimeFormat = {
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false
    }

    const handleAddActionClick = (info) => {
        if (selectedDate && selectedDate === info.dateStr) {
            plannerStore.setCalendarDate(info.date)
            plannerStore.setEditMode('new')
            plannerStore.setSelectedAction(null)
            plannerStore.setIsActionFormOpen(true)
            setSelectedDate(null)
    
        } else {
            plannerStore.setCalendarDate(null)
            setSelectedDate(info.dateStr)
        }
    }

    const handleEditActionClick = (action) => {
        const selectedAction = action.event.extendedProps.action
        plannerStore.setSelectedAction(selectedAction)
        const recordID = plannerStore.getRecordID(selectedAction.data['my_action_reference'])
        plannerStore.setMyActionRecordID(recordID)

        plannerStore.setEditMode('edit')
        plannerStore.setIsActionFormOpen(true)
    }

    const handleDatesChange = (datesInfo) => {
        plannerStore.setCalendarRange(datesInfo)
        plannerStore.getCalendarActions(userStore.user.email, datesInfo)
    }

    const handleActionDrag = (actionInfo) => {
        const selectedAction = actionInfo.event.extendedProps.action
        selectedAction.data.action_completion_date.value = new Date(actionInfo.event.start).valueOf()
        plannerStore.setSelectedAction(selectedAction)
        const recordID = plannerStore.getRecordID(selectedAction.data['my_action_reference'])
        plannerStore.setMyActionRecordID(recordID)

        plannerStore.setEditMode('edit')
        plannerStore.setIsActionFormOpen(true)
    }

    const createTooltip = (info) => {
        let tooltip = new tippy(info.el, {
            allowHTML: true,
            content: `${info.event.title}<br/><br/>
                    Ответственный: ${info.event.extendedProps.action.data.responsible_user_name.value}<br/>
                    Назначил: ${info.event.extendedProps.action.author.first_name} ${info.event.extendedProps.action.author.last_name}`,
            delay: [500, 100],
            maxWidth: 350,
            placement: "top",
            interactive: false
        })
    }

    return (
        <div className='tw-w-full tw-h-full tw-bg-white tw-rounded-md tw-p-2'>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                events={plannerStore.calendarActions}
                dayMaxEvents={true}
                buttonText={buttonsText}
                customButtons={additionalButtons}
                eventTimeFormat={eventTimeFormat}
                headerToolbar={headerFormat}
                height={'100%'}
                initialView="dayGridMonth"
                locale={ruLocale}
                editable={true}
                selectable={true}
                weekends={true}
                weekNumbers={true}
                dateClick={handleAddActionClick}
                eventClick={handleEditActionClick}
                eventDrop={handleActionDrag}
                datesSet={handleDatesChange}
                eventDidMount={createTooltip}
            />
        </div>
    )
}

export default observer(EventCalendar)