import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../../../..'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import DirectoryContainer from '../../common/panels/directory/DirectoryContainer'
import StageButtons from '../stage/StageButtons'
import { observer } from 'mobx-react-lite'
import DocumentService from '../../../../../services/DocumentService'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import Spinner from '../../../../../assets/Spinner'
import FieldItem from '../../../../form_fields/FieldItem'
import { processData } from '../../../../../functions/forms'
import { deleteNestedDataObjects, updateNestedDataObjects } from '../../../../../functions/nestedModels'
import { checkNestedTables } from '../../../../../functions/checkNestedTables'
import FormErrorToastPanel from '../../common/panels/toast/FormErrorToastPanel'
import { emptyReferenceValue, setReferenceValue } from '../../../../../config/constTypes'


/**
 * Визуальный компонент отображает этап создания и редактирования комиссии
 * 
 */
const CommissionForm = () => {   
    const {
        control,
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        clearErrors,
        formState: { errors, isSubmitting },
    } = useForm()

    const { categorizingCIIStore, docStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [selectedDataModelID, setSelectedDataModelID] = useState(null)
    const [selectedName, setSelectedName] = useState(null)
    const [isSavedRecord, setIsSavedRecord] = useState(false)
    const [formFields, setFormFields] = useState(null)
    const [formNestedModels, setFormNestedModels] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [chosenDataObjectsList, setChosenDataObjectsList] = useState([])

    const handleSelectClick = (dataModelID, name) => {
        let fieldValue = getValues(name)
        if (fieldValue) {
            setChosenDataObjectsList([fieldValue])
        }

        setSelectedDataModelID(dataModelID)
        setSelectedName(name)
        setIsSelectFormOpen(true)
        clearErrors(name.slice(0, -2))
    }

    const handleClearClick = (name) => {
        setValue(name, emptyReferenceValue)
        setIsSavedRecord(false)
        clearErrors(name.slice(0, -2))
    }

    const handleDoubleClick = (item) => {
        setValue(selectedName, {value: setReferenceValue(item)})
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
        setSelectedName(null)
        setIsSavedRecord(false)

        const [responsibleError, responsibleErrorMessage] = categorizingCIIStore.checkResponsiblePerson(selectedName, item, 'data.main_for_execution_of_the_order__creating_a_commission.0')
        if (responsibleError) {
            toast.error(responsibleErrorMessage, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
            setError('data.main_for_execution_of_the_order__creating_a_commission', { type: 'invalid', message: responsibleErrorMessage })
        }
    }

    const handleCloseClick = () => {
        docStore.setIsDetailView(false)
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
    }

    const handleBackClick = () => {
        if (categorizingCIIStore.commissionID.values.length > 0)  {
            categorizingCIIStore.loadCommission(categorizingCIIStore.commissionID.values[0].id)
        }
        categorizingCIIStore.goPrevStage()
    }

    const handleForwardClick = async (form) => {
        setIsLoading(true)
        const nestedModelsWithErrors = checkNestedTables([categorizingCIIStore.commissionMembers], formFields, true, setError,
            'Включенные таблицы не должны быть пустыми, а также содержать повторяющиеся или пустые значения!')
        categorizingCIIStore.setCommissionMembers(nestedModelsWithErrors[0])

        if (nestedModelsWithErrors.some(nestedModel => nestedModel.errors)) {
            setIsLoading(false)
            
        } else {
            const dataObject = processData(form.data, categorizingCIIStore.activeDataModel)

            dataObject.data['participants__creating_a_commission'] = { 
                upsert: updateNestedDataObjects(categorizingCIIStore.commissionMembers),
                remove: deleteNestedDataObjects(categorizingCIIStore.commissionMembers)
            }    

            try {
                if (!isSavedRecord) {
                    let response

                    if (categorizingCIIStore.commissionID && categorizingCIIStore.commissionID.values.length > 0) {
                        response = await DocumentService.updateDataObject(categorizingCIIStore.commissionID.values[0].record_id, dataObject)
                        await categorizingCIIStore.loadSavedProject(categorizingCIIStore.project.record_id)
    
                        if (categorizingCIIStore.linkedListPrintDate) {
                            // блокировка кнопки "Далее" для этапа "Печать перечня под категорирование"
                            categorizingCIIStore.setLinkedListPrintDate(null)
                            await categorizingCIIStore.updateProject('date_of_print_st_6__stages_of_categorization', null, true)
                        } else
                            await categorizingCIIStore.loadSavedProject(categorizingCIIStore.project.record_id)

                    } else {
                        response = await DocumentService.createDataObject(dataObject)
                        await categorizingCIIStore.updateProject('order_to_create_a_commission__stages_of_categorization', [response.record_id], true)
                    }    
                    toast.success('Данные успешно сохранены!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                }
                categorizingCIIStore.goNextStage()

            } catch (error) {
                setIsLoading(false)
                showErrorToast(error, 'saving', '')
            }
        }
    }

    const handleChangeMembers = (editedValues, editedNestedModel) => {
        categorizingCIIStore.setCommissionMembers(
                {...categorizingCIIStore.commissionMembers, dataObjects: editedValues}
        )
        
        setIsSavedRecord(false)

        if (errors.data)
            clearErrors('data.'+ editedNestedModel.tech_name)
    }
   
    useEffect(() => {
        categorizingCIIStore.setIsDataLoading(true)
        categorizingCIIStore.getActiveDataModel('creating_a_commission', setFormNestedModels)
    }, [])

    useEffect(() => {
        if (categorizingCIIStore.activeDataModel && categorizingCIIStore.activeDataModel.id === 'creating_a_commission') {
            if (categorizingCIIStore.commission) {
                setFormFields(Object.values(categorizingCIIStore.commission.data))
                setIsEditMode(true)  
                setIsSavedRecord(true)
            } else {
                setFormFields(categorizingCIIStore.activeDataModel.fields)               
            }
        }
    }, [categorizingCIIStore.activeDataModel])

    useEffect(() => {
        if (formNestedModels) {
            if (categorizingCIIStore.commissionID && categorizingCIIStore.commissionID.values.length > 0) {
                categorizingCIIStore.loadCommission(categorizingCIIStore.commissionID.values[0].id)
            } else {
                const nestedModel = formNestedModels[0]
                categorizingCIIStore.setCommissionMembers({
                    ...nestedModel,
                    dataObjects: []
                })
            }
        }
        categorizingCIIStore.setIsDataLoading(false)
    }, [formNestedModels])

    useEffect(() => {
        if(Object.entries(errors).length > 0 && isSubmitting === false){
            toast.error(<FormErrorToastPanel errors={errors.data} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <>
            <div className='tw-grow tw-overflow-auto tw-mt-2'>
                <form className='tw-flex tw-flex-col tw-h-full'>
                    <div className='tw-px-4 tw-py-1'>
                    { !formFields || !categorizingCIIStore.commissionMembers || categorizingCIIStore.isDataLoading
                            ?
                                <Spinner />
                            :
                                formFields.slice().sort((a, b) => a.order - b.order).map((fieldItem, index) => {
                                    if (!fieldItem.hide) {
                                        return  <FieldItem
                                                    key={index}
                                                    fieldItem={fieldItem}
                                                    nestedModels={[categorizingCIIStore.commissionMembers]}
                                                    selectedNestedValue={categorizingCIIStore.selectedNestedValue}
                                                    errors={errors}
                                                    handleSelectClick={handleSelectClick}
                                                    handleClearClick={handleClearClick}
                                                    handleRecordChange={setIsSavedRecord}
                                                    isEditMode={isEditMode}
                                                    isDuplicateMode={false}
                                                    isLoading={isLoading}
                                                    control={control}
                                                    register={register}
                                                    setValue={setValue}
                                                    onSelectNestedValue={e => categorizingCIIStore.setSelectedNestedValue(e)}
                                                    onNestedTableChange={handleChangeMembers}
                                                    isChosenObjectDuplicationForbidden={true}
                                                />
                                    } else return null
                                })
                        }
                    </div>
                </form>
            </div>
            <StageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleSubmit(handleForwardClick)}
                disabled={isLoading}
            />
            <DirectoryContainer
                isOpen={isSelectFormOpen}
                selectedDataModel={selectedDataModelID}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
                chosenDataObjectsList={chosenDataObjectsList}
            />
        </>                                                             
    )
}

export default observer(CommissionForm)
