//Функция осуществляет генерацию случайных чисел в заданном диапазоне
export function rnd (x,y) {
    let num;
    do {
       num = parseInt(Math.random() * (y - x) + x)
       if (num >= x && num <= y) break;
    } while (true)
   return(num)
}

//Функция осуществляет генерацию пароля
export function generatePassword () {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#№;%:&*()-_+=0123456789'
    let password, isValid
    const regexPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w])(?!.*[А-я])(?!.*[\s]).{8,}/g)

    do {
        password = ''
        for (let i = 0; i < 12; i++) 
            password += charset[rnd(0, charset.length - 1)]
        
        regexPassword.lastIndex = 0
        isValid = regexPassword.test(password)

    } while (!isValid)

    return password
}