import React, { useContext } from 'react'
import { DocumentPlusIcon, PencilSquareIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { menuTooltipTimeOut } from '../../../../../config/constTypes'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../..'
import { useHotkeys } from 'react-hotkeys-hook'
import { checkDataModelPermission } from '../../../../../functions/checkPermissions'


/**
 * Визуальный компонент отображает меню возможных действий над таблицами
 * 
 * @param {Object} activeItem Выбранный элемент в списке таблиц
 * @param {Function} onAddItemClick Обработчик клика мыши на кнопке создания новой таблицы
 * @param {Function} onEditItemClick Обработчик клика мыши на кнопке редактирования выбранной таблицы
 * @param {Function} onHideItemClick Обработчик клика мыши на кнопке скрытия выбранной таблицы
 * 
 */
const DataModelMenu = ({activeItem, onAddItemClick, onEditItemClick, onHideItemClick}) => {
    const { userStore } = useContext(Context)
    const [isEdit, isEditDescription] = checkDataModelPermission(activeItem, 'edit') 

    const isEditionEnabled = activeItem && userStore.checkPermission("table:edit") && isEdit
    
    useHotkeys('ctrl+a', () => {if (userStore.checkPermission("table:edit")) onAddItemClick()}, { preventDefault: true })
    useHotkeys('ctrl+e', () => {if (isEditionEnabled) onEditItemClick(activeItem.id)}, { preventDefault: true })
    useHotkeys('ctrl+x', () => {if (isEditionEnabled) onHideItemClick(activeItem)}, { preventDefault: true })
    
    return (
        <div id='data-model-menu' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
            <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Таблицы</p>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onAddItemClick}
                    disabled={!userStore.checkPermission("table:edit")}
                    data-tooltip-id="data-model-tooltip"
                    data-tooltip-html="Создать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+A)"
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onEditItemClick(activeItem.id)}
                    disabled={!isEditionEnabled}
                    data-tooltip-id="data-model-tooltip"
                    data-tooltip-html={(isEdit || !activeItem)
                        ? "Редактировать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+E)" 
                        : isEditDescription}
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onHideItemClick(activeItem)}
                    disabled={!isEditionEnabled}
                    data-tooltip-id="data-model-tooltip"
                    data-tooltip-html={(isEdit || !activeItem)
                        ? activeItem && activeItem.hide_mark ? "Показать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+X)" : "Скрыть &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+X)"
                        : isEditDescription}
                    data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    { activeItem && activeItem.hide_mark
                        ?   <EyeIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        :   <EyeSlashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    }
                </button>
            </div>
            <Tooltip id="data-model-tooltip" className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
        </div>
    )
}

export default observer(DataModelMenu)