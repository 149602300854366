/**
 * Функция осуществляет определение цветовой схемы модуля
 *
 * @param {String} color Цвет модуля
 * @returns {Object} Цветовая схема модуля
 */
export default function defineModuleColors(color) {
    let themeColors

    switch(color) {
        case 'green':   themeColors = {
                            borderColor: 'tw-border-gray-400',
                            bgColor: 'tw-bg-gray-100',
                            captionColor: 'tw-bg-teal-500',
                            textColor: 'tw-text-gray-900'
                        }
                        break
        case 'blue':    themeColors = {
                            borderColor: 'tw-border-gray-400',
                            bgColor: 'tw-bg-gray-100',
                            captionColor: 'tw-bg-sky-700',
                            textColor: 'tw-text-gray-900'
                        }
                        break
        case 'red':     themeColors = {
                            borderColor: 'tw-border-gray-400',
                            bgColor: 'tw-bg-gray-100',
                            captionColor: 'tw-bg-red-500',
                            textColor: 'tw-text-gray-900'
                        }
                        break
        case 'gray':  themeColors = {
                            borderColor: 'tw-border-gray-400',
                            bgColor: 'tw-bg-gray-100',
                            captionColor: 'tw-bg-gray-700',
                            textColor: 'tw-text-gray-900'
                        }
                        break
        default:        themeColors = {
                            borderColor: 'tw-border-gray-400',
                            bgColor: 'tw-bg-gray-100',
                            captionColor: 'tw-bg-gray-500',
                            textColor: 'tw-text-gray-900'
                        }
    }

    return themeColors
}
