import React, { useContext } from 'react'
import { contentTooltipTimeOut, menuTooltipTimeOut } from '../../../../../config/constTypes'
import { Context } from '../../../../..'
import { CheckCircleIcon, MinusIcon } from '@heroicons/react/20/solid'
import { observer } from 'mobx-react-lite'
import { checkDataModelPermission } from '../../../../../functions/checkPermissions'

/**
 * Визуальный компонент отображает название одной таблицы для списка существующих таблиц
 * 
 * @param {Object} item Текущий элемент списка таблиц
 * 
 */
const DataModelListItem = ({item}) => {
    const { docStore, userStore } = useContext(Context)
    const [isEdit, isEditDescription] = checkDataModelPermission(item, 'edit') 

    const isDataModelChecked = docStore.categoryDataModels.includes(item.id)

    let className = 'tw-relative tw-pl-12 tw-text-sm tw-w-full tw-border-b tw-py-0.5'

    if (docStore.selectedDataModel && docStore.selectedDataModel.id === item.id && !docStore.selectedCategory) {
        className += ` tw-text-white tw-bg-gray-500`
    } else {
        className += ` tw-text-gray-900 tw-bg-white hover:tw-bg-gray-100 hover:tw-border-gray-300`
    }

    const onClick = (dataModel) => {
        docStore.setSelectedDataModel(dataModel)
        docStore.setDataModelCategories(dataModel.categories.map(item => item.id))
        docStore.setSelectedCategory(null)
    }

    const onLinkClick = (item) => {
        if (userStore.checkPermission("table:edit") && isEdit) {
            const originalCategories = item.category_ids
            const editedCategories = originalCategories.includes(docStore.selectedCategory.id)
                            ?   originalCategories.filter((category) => category !== docStore.selectedCategory.id)
                            :   originalCategories.concat([docStore.selectedCategory.id])

            docStore.setSelectedDataModel(item)
            docStore.updateDataModelCategories(item, editedCategories)
        }
    }

    return (
        <div
            id={item.id}
            className={className}
        >
            { docStore.selectedCategory &&
                <div
                    className={`tw-text-gray-500 tw-absolute tw-left-3 tw-py-1 ${userStore.checkPermission("table:edit") && 'hover:tw-cursor-pointer'}`}
                    data-tooltip-id="data-model-list-tooltip" data-tooltip-content={userStore.checkPermission("table:edit") ? isDataModelChecked ? "Отвязать" : "Привязать" : ''} data-tooltip-delay-show={contentTooltipTimeOut}
                    onClick={() => onLinkClick(item)}
                >
                    { isDataModelChecked
                        ?   <CheckCircleIcon className='tw-w-5 tw-h-5 hover:tw-text-gray-700' aria-hidden='true'/>
                        :   <MinusIcon className='tw-w-5 tw-h-5 hover:tw-text-gray-700' aria-hidden='true'/>
                    }
                </div>
            }
            <div
                className={`tw-grid tw-grid-cols-5 ${(!docStore.selectedDataModel || docStore.selectedDataModel.id !== item.id) && 'hover:tw-cursor-pointer'}`}
                onClick={() => onClick(item)}
            >
                <div className={`${item.hide_mark ? 'tw-line-through tw-text-red-400' : ''} tw-col-span-4 tw-py-1`}>
                    {item.entity_name}
                </div>
                <div className='tw-col-span-1 tw-justify-self-end tw-px-2 tw-flex  tw-items-center  tw-flex-row tw-gap-x-2 tw-py-0.5'>
                    <div>
                        <span className={`tw-px-2 tw-py-0.5 tw-w-5 tw-h-5 tw-font-bold tw-rounded-md
                                ${docStore.selectedDataModel?.id === item.id
                                    ? 'tw-text-white tw-bg-gray-600'
                                    : 'tw-text-gray-900 tw-bg-gray-200'}
                            `}
                            data-tooltip-id="data-model-list-tooltip" data-tooltip-delay-show={menuTooltipTimeOut}
                            data-tooltip-content={item.type === 'document' ? 'Документ' : item.type === 'nested' ? 'Вложенная' : 'Справочник'}
                        >
                            {item.type === 'document'
                            ?   'Д'
                            :   item.type === 'nested'
                                ?   'В'
                                :   'С'
                        }
                        </span>
                    </div>
                    <div>
                        <span className={`tw-px-2 tw-py-0.5 tw-w-5 tw-h-5 tw-font-bold tw-rounded-md
                                ${docStore.selectedDataModel?.id === item.id
                                    ?   'tw-text-white tw-bg-gray-600'
                                    :   'tw-text-gray-900 tw-bg-gray-200'} 
                            `}
                            data-tooltip-id="data-model-list-tooltip" data-tooltip-delay-show={menuTooltipTimeOut}
                            data-tooltip-content={item.built_in ? 'Из конфигурации системы' : 'Пользовательский'} 
                        >
                            {item.built_in ? 'К' : 'П'}
                        </span>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default observer(DataModelListItem)