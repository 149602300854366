import React, { useContext, useEffect } from 'react'
import PageTitle from '../main_page/controller/common/panels/PageTitle'
import CompanyList from './companies_list/CompanyList'
import Spinner from '../../assets/Spinner'
import CompanyListMenu from './companies_list/CompanyListMenu'
import CompanyInfo from './company/CompanyInfo'
import CompanyForm from './company/CompanyForm'
import CompanyUserInfo from './users/CompanyUserInfo'
import CompanyUserMenu from './users/CompanyUserMenu'
import CompanyUserForm from './users/CompanyUserForm'
import { Context } from '../..'
import CompanyMenu from './company/CompanyMenu'
import UsersList from './users/users_list/UsersList'
import { observer } from 'mobx-react-lite'


/**
 * Компонент реализует логику создания и редактирования компаний и пользователей компаний
 * 
 */
const AdminContainer = () => {
    const { AdminStore } = useContext(Context)

    useEffect(() => {
        AdminStore.getAllCompanies()
        AdminStore.getAllUsers()
    }, [])

    if (AdminStore.isLoading || !AdminStore.companies) {
        return <Spinner/>
    }

    return (
        <>
            <PageTitle title={'Администрирование компаний и пользователей'} />
            <section id='system-admin-page' className='tw-grid tw-grid-cols-4 tw-gap-x-8 tw-max-h-full tw-h-full'>
                
                <div id='system-company-list' className='tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <CompanyListMenu />
                    <CompanyList />
                </div>

                <div id='system-company-column' className='tw-col-span-2 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <CompanyMenu />
                    { (AdminStore.selectedCompany || AdminStore.selectedSubCompany) && !AdminStore.isSubCompanyCreating 
                        && !AdminStore.isSubCompanyEditing && !AdminStore.isCompanyEditing &&
                        <CompanyInfo />
                    }
                    { (AdminStore.isSubCompanyCreating || AdminStore.isSubCompanyEditing ||  AdminStore.isCompanyEditing ) && 
                        <CompanyForm />
                    }
                </div>
                
                <div id='system-company-user-column' className='tw-col-span-1 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <CompanyUserMenu />
                    { AdminStore.selectedUser && !AdminStore.isUserCreating && !AdminStore.isUserEditing &&        
                        <CompanyUserInfo />
                    }
                    { (AdminStore.isUserCreating || AdminStore.isUserEditing) && 
                        <CompanyUserForm />
                    }
                    {AdminStore.selectedSubCompany &&
                        <UsersList />
                    }
                </div>

            </section>
        </>
    )
}

export default observer(AdminContainer)