import React, { useContext } from 'react'
import { DocumentPlusIcon, PencilSquareIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает меню возможных действий над компанией
 * 
 */
const CompanyMenu = () => {
    const { AdminStore } = useContext(Context)

    const onAddItemClick = () => {
        AdminStore.setIsSubCompanyCreating(true)
        AdminStore.setSelectedSubCompany(null)
        AdminStore.setSelectedUser(null)
    }

    const onEditItemClick = () => {
        AdminStore.setIsSubCompanyEditing(true)
        AdminStore.setSelectedUser(null)
    }

    return (
        <div id='system-sub-company-menu' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
            <p className='tw-text-sm tw-font-semibold tw-w-24 md:tw-text-base sm:tw-w-96'>Информация о юр. лице и пользователях</p>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onAddItemClick}
                    disabled={!AdminStore.selectedCompany?.id || AdminStore.isCompanyEditing || AdminStore.isSubCompanyCreating || AdminStore.isSubCompanyEditing || AdminStore.isUserCreating || AdminStore.isUserEditing}
                    data-tooltip-id="system-company-menu-tooltip" data-tooltip-content="Создать юр. лицо" data-tooltip-delay-show={1000}
                >
                    <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onEditItemClick(AdminStore.selectedSubCompany.id)}
                    disabled={!AdminStore.selectedSubCompany  || AdminStore.isSubCompanyCreating || AdminStore.isSubCompanyEditing || AdminStore.isUserCreating || AdminStore.isUserEditing}
                    data-tooltip-id="system-sub-company-menu-tooltip" data-tooltip-content="Редактировать юр. лицо" data-tooltip-delay-show={1000}
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
            </div>
            <Tooltip id="system-sub-company-menu-tooltip" place="top"/>
        </div>
    )
}

export default observer(CompanyMenu)