import React from 'react'

const SaveIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="IconChangeColor" height="20" width="20" fill='currentColor'>
            <path 
                d="M 0,2 C 0,0.9 0.9,0 2,0 h 14 l 4,4 v 14 c 0,1.104569 -0.895431,2 -2,2 L 3.5,19 h 13 V 12.5 H 3.5 V 19 L 18,20 H 2 C 0.8954305,20 0,19.104569 0,18 Z M 5,2 V 8 H 15 V 2 Z m 6,1 h 3 v 4 h -3 z"
                id="mainIconPathAttribute"
            >
            </path>
        </svg>
    )
}

export default SaveIcon