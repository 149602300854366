import React, { useState, Fragment, useContext, useRef, useEffect } from 'react'
import { Context } from '../../../../../../..'
import { Dialog, Transition } from '@headlessui/react'
import { observer } from 'mobx-react-lite'
import { XMarkIcon } from '@heroicons/react/24/outline'
import PageTitle from '../../../../common/panels/PageTitle'
import DoneForm from './DoneForm'
import PanelVerticalDivider from '../../../../common/dividers/PanelVerticalDivider'
import DataObjectTabs from  '../../../../../../tabs/DataObjectTabs'
import DocumentService from '../../../../../../../services/DocumentService'
import RecordNotesStore from '../../../../../../../store/RecordNotesStore'
import { toast } from 'react-toastify'
import { serviceMessageTimeOut, successMessageTimeOut, responseTimeOut } from '../../../../../../../config/constTypes'
import { showErrorToast } from '../../../../../../../functions/errorHandlers'
import { handleFileSelect, handleFileRemove } from '../../../../../../../functions/fileHandlers'
import RecordInputForm from '../../../../common/modals/RecordInputForm'
import { copyToClipboard } from '../../../../../../../functions/copyToClipboard'
import { paths } from '../../../../../../../config/constsURN'


/**
 * Визуальный компонент отображает модальное окно с формой отметки о выполнении запланированного действия планировщика
 * 
 * @param {Boolean} isOpen Признак видимости (отображения) модального окна
 * 
 */
const DoneFormContainer = ({isOpen}) => {
    const { plannerStore, userStore } = useContext(Context)
    const [recordNotesStore] = useState(() => new RecordNotesStore())

    const [isSaving, setIsSaving] = useState(false)
    const [attachedFiles, setAttachedFiles] = useState([])
    const [nestedDataModels, setNestedDataModels] = useState([])
    const [selectedNestedDataModel, setSelectedNestedDataModel] = useState(null)
    const [isRecordInputFormOpen, setIsRecordInputFormOpen] = useState(false)
    const [recordInputFormQuestions, setRecordInputFormQuestions] = useState(null)
    const [formDataObject, setFormDataObject] = useState({})

    const formElement = useRef(null)
    const panelElement = useRef(null)

    const minLeftColumnSize = 400
    const minRightColumnSize = 300
    const defaultColumnSizes = '66.666667% 33.333333%'
    const savedColumnSizes = sessionStorage.getItem('savedFormSizes')
    const columnSizes = savedColumnSizes || defaultColumnSizes

    const onCloseClick = () => {
        plannerStore.setIsDoneFormOpen(false)
        if (plannerStore.selectedTab === 'calendar') {
            plannerStore.getCalendarActions(userStore.user.email, plannerStore.calendarRange)
        }
    }

    const saveRecord = async (recordID, dataObject) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        DocumentService
            .updateDataObject(recordID, dataObject)
            .then(() => {
                toast.success('Действие отмечено как выполненное', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                plannerStore.setIsUpdatingActionList(true)
                onCloseClick()
            })
            .catch(error => {
                showErrorToast(error, 'saving', '')
            })
            .finally(() => {
                clearTimeout(noResponse)
                setIsSaving(false)
            })
    }

    const handleSubmitClick = async (form) => {
        setIsSaving(true)
        const dataObject = {}
        dataObject.data_model_id = 'planned_actions'
        dataObject.data = {}
        dataObject.data['action_is_done'] = true
        dataObject.data['comment'] = form.comment

        if (attachedFiles.length) {
            dataObject.system_data = {}
            dataObject.system_data.files = attachedFiles.map(file => { return { id: file.id } })    
        }

        try {
            const questions = await DocumentService.getRecordForms(dataObject, 'planned_actions', plannerStore.selectedAction.record_id)
            
            if (questions.show) {
                setRecordInputFormQuestions(questions)
                setIsRecordInputFormOpen(true)
                setFormDataObject(dataObject)
            } else {
                await saveRecord(plannerStore.selectedAction.record_id, dataObject)
            }
        } catch (error) {
            showErrorToast(error, 'saving', '')        
        }
    }

    const handleCopyToClipboardClick = () => {
        try {
            copyToClipboard(`${paths.PLANNER_ROUTE}/${plannerStore.selectedAction.record_id}/?mode=edit`)
            toast.info('Ссылка скопирована в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
        } catch (error) {
            toast.error('Не удалось скопировать ссылку в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }
    }

    useEffect(() => {
        if (isOpen) {
            if (plannerStore.selectedAction.system_data.files && plannerStore.selectedAction.system_data.files.length)
                setAttachedFiles(plannerStore.selectedAction.system_data.files)
            recordNotesStore.setRecordID(plannerStore.selectedAction.record_id)
        } else {
            setAttachedFiles([])
            setIsSaving(false)
            recordNotesStore.setRecordID(null)
        }
    }, [isOpen])
   
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={onCloseClick}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-500"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-500"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                    <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                        <div className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex  tw-pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                enterFrom="tw-translate-x-full"
                                enterTo="tw-translate-x-0"
                                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                leaveFrom="tw-translate-x-0"
                                leaveTo="tw-translate-x-full"
                            >
                                <Dialog.Panel  className="tw-pointer-events-auto tw-relative tw-w-[90vw]"> 
                                    <Transition.Child
                                        as={Fragment}
                                        enter="tw-ease-in-out duration-500"
                                        enterFrom="tw-opacity-0"
                                        enterTo="tw-opacity-100"
                                        leave="tw-ease-in-out tw-duration-500"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                    >
                                        <div className="tw-absolute tw-left-0 tw-top-0 tw--ml-8 tw-flex tw-pr-2 tw-pt-4 sm:tw--ml-10 sm:tw-pr-4">
                                            <button
                                                type="button"
                                                className="tw-rounded-md hover:tw-text-gray-300 tw-text-white hover:tw-bg-gray-500 focus:tw-outline-none tw-ring-2 tw-ring-white"
                                                onClick={onCloseClick}
                                            >
                                                <span className="tw-sr-only">Закрыть</span>
                                                <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto tw-bg-gray-200 tw-px-4 tw-pb-4 tw-shadow-xl">
                                        <PageTitle title='Отметка о выполнении действия'/>
                                        <div
                                            id='add-data-object-page'
                                            style={{
                                                display: 'grid', 
                                                gridTemplateColumns: columnSizes,
                                                height: 'calc(100% - 4rem)'
                                            }}
                                            ref={panelElement}
                                        >
                                            <div id='done-form-column' className='tw-h-full tw-overflow-hidden' ref={formElement}>
                                                { plannerStore.selectedAction &&
                                                    <DoneForm
                                                        isOpen={isOpen}
                                                        isLoading={isSaving}
                                                        onSubmitClick={handleSubmitClick}
                                                        onCopyLinkClick={handleCopyToClipboardClick}
                                                    />
                                                }
                                            </div>
                                            <div id='data-files-column' className='tw-h-full tw-overflow-hidden tw-relative tw-pl-4'>
                                                <PanelVerticalDivider
                                                    leftElement={formElement}
                                                    panel={panelElement}
                                                    minLeftSize={minLeftColumnSize}
                                                    minRightSize={minRightColumnSize}
                                                />
                                                { !isSaving
                                                    ?
                                                        <DataObjectTabs
                                                            files={attachedFiles}
                                                            readOnly={false}
                                                            onFileSelect={handleFileSelect(attachedFiles, setAttachedFiles)}
                                                            onFileRemove={handleFileRemove(attachedFiles, setAttachedFiles)}
                                                            selectedNestedDataModel={selectedNestedDataModel}
                                                            nestedDataModels={nestedDataModels}
                                                            setSelectedNestedDataModel={setSelectedNestedDataModel}
                                                            setNestedDataModels={setNestedDataModels}
                                                            isDocumentPage={true}
                                                            isNestedLoading={false}
                                                            panel={panelElement}
                                                            panelID='done-tabs'
                                                            recordNotesStore={recordNotesStore}
                                                        />
                                                    :
                                                        <div className='tw-h-full tw-w-full tw-bg-white tw-rounded-md'></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {recordInputFormQuestions && recordInputFormQuestions?.show &&
                                        <RecordInputForm
                                            isVisible={isRecordInputFormOpen}
                                            recordInputFormQuestions={recordInputFormQuestions}
                                            onCloseModal={() => {setIsSaving(false); setIsRecordInputFormOpen(false)}}
                                            formDataObject={formDataObject}
                                            saveRecord={saveRecord} 
                                            selectedDataObject={plannerStore.selectedAction}
                                        />
                                    }
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default observer(DoneFormContainer)