import React, { useState, useEffect, Fragment, useRef } from 'react'
import DocumentService from '../../../../../services/DocumentService'
import DataObjectForm from '../form/DataObjectForm'
import DataObjectTabs from '../../../../tabs/DataObjectTabs'
import PageTitle from '../../common/panels/PageTitle'
import Spinner from '../../../../../assets/Spinner'
import { toast } from 'react-toastify'
import { responseTimeOut, serviceMessageTimeOut } from '../../../../../config/constTypes'
import { handleFileRemove, handleFileSelect } from '../../../../../functions/fileHandlers'
import { getErrorInfo, showErrorToast } from '../../../../../functions/errorHandlers'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { observer } from 'mobx-react-lite'
import PanelVerticalDivider from '../../common/dividers/PanelVerticalDivider'
import { addNestedDataObjects, initNestedModels } from '../../../../../functions/nestedModels'
import DialogTab from '../../../../dialog_tab/DialogTab'
import parse from 'html-react-parser'
import DialogTabStore from '../../../../../store/DialogTabStore'
import { Tooltip } from 'react-tooltip'
import RecordInputForm from '../../common/modals/RecordInputForm'

/**
 * Компонент реализует логику создания новой записи в таблице
 * 
 * @param {Boolean} isOpen Признак видимости (отображения) модального окна
 * @param {Function} onCloseClick Обработчик клика мыши для закрытия окна
 * @param {Object} selectedFullDataModel Выбранная таблица
 * @param {Object} recordNotesStore Экземпляр хранилища информации о сообщениях
 * @param {Boolean} isNested Признак редактирования записей вложенной таблицы
 * @param {Function} saveNestedRecord Функция сохранения записивложенной таблицы
 * 
 */
const AddDataObjectContainer = ({isOpen, onCloseClick, selectedFullDataModel, recordNotesStore, isNested, saveNestedRecord}) => {
    const [dialogTabStore] = useState(() => new DialogTabStore())

    const [isLoading, setIsLoading] = useState(true)
    const [isObjLoading, setIsObjLoading] = useState(false)
    const [errorInfo, setErrorInfo] = useState({})
    const [attachedFiles, setAttachedFiles] = useState([])
    const [isRecordInputFormOpen, setIsRecordInputFormOpen] = useState(false)
    const [recordInputFormQuestions, setRecordInputFormQuestions] = useState(null)
    const [formDataObject, setFormDataObject] = useState({})

    const [nestedDataModels, setNestedDataModels] = useState([])
    const [selectedNestedDataModel, setSelectedNestedDataModel] = useState(null)
    
    const formElement = useRef(null)
    const panelElement = useRef(null)

    const minLeftColumnSize = 400
    const minRightColumnSize = 300
    const defaultColumnSizes = '66.666667% 33.333333%'
    const savedColumnSizes = sessionStorage.getItem('savedFormSizes')
    const columnSizes = savedColumnSizes || defaultColumnSizes

    const saveRecord = async (dataObject) => {
        const noResponse = setTimeout(() => {
            setIsObjLoading(false)
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        setIsObjLoading(true)

        try {
            const response = await DocumentService.createDataObject(dataObject)
            clearTimeout(noResponse)
            toast.success('Информация успешно сохранена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            setIsObjLoading(false)
            onCloseClick(false)
            
        } catch (error) {
            clearTimeout(noResponse)
            setIsObjLoading(false)
            const [errorMessage, errorInfoObject] = getErrorInfo(error, selectedFullDataModel.fields.slice())
            setErrorInfo(errorInfoObject)
            showErrorToast(error, 'saving', errorMessage)        
        }
    }

    const getForms = async (dataObject) => {
        try {
            const questions = await DocumentService.getNewRecordForms(dataObject, selectedFullDataModel.id)
            
            if (questions?.show) {
                setRecordInputFormQuestions(questions)
                setIsRecordInputFormOpen(true)
                setFormDataObject(dataObject)
            } else {
                await saveRecord(dataObject)
            }
        } catch (error) {
            showErrorToast(error, 'saving', '')        
        }
    }

    const handleSubmitClick = async (form) => {
        const createdDataObject = {}
        createdDataObject.data = {}

        for (const [key, value] of Object.entries(form.data)) {
            if (Array.isArray(value)) {
                if (value[0]?.value?.values?.length)
                    createdDataObject.data[key] = [value[0].value.values[0].record_id]
                else 
                    createdDataObject.data[key] = []
            } else {
                if (value !== undefined && value !== null && value !== '')
                    createdDataObject.data[key] = value.id || value
            }
        }
        if (attachedFiles.length) {
            createdDataObject.system_data = {}
            createdDataObject.system_data.files = attachedFiles.map(file => { return { id: file.id } })    
        }
        if (form.system_data && form.system_data.parent_record_id && form.system_data.parent_record_id.value?.values?.length) {
            if (!createdDataObject.system_data)
                createdDataObject.system_data = {}
            createdDataObject.system_data.parent_record_id = form.system_data.parent_record_id.value.values[0].record_id
        }
        createdDataObject.data_model_id = selectedFullDataModel.id

        nestedDataModels.forEach(nestedDataModel => {
            if (nestedDataModel.type === 'reference' && nestedDataModel.validator_type === 'many') {
                createdDataObject.data[nestedDataModel.tech_name] = []

                nestedDataModel.dataObjects.forEach(dataObject => {
                    if (dataObject.status !== 'deleted') {
                        createdDataObject.data[nestedDataModel.tech_name].push(dataObject.record_id)
                    }
                })
            } else
                createdDataObject.data[nestedDataModel.tech_name] = { upsert: addNestedDataObjects(nestedDataModel)}
        })

        if (isNested) {
            saveNestedRecord(form)
        } else {
            if (selectedFullDataModel.forms && selectedFullDataModel?.forms?.show) {
                getForms(createdDataObject)
            } else {
                await saveRecord(createdDataObject)
            }
        }
    }

    const handleCloseDialogClick = () => {
        if (!isObjLoading) {
            dialogTabStore.setParentName('AddDataObjectContainer')
            dialogTabStore.setDialogTabTitle("Завершение создания записи") 
            dialogTabStore.setDialogTabText(parse(`Вы хотите закрыть форму создания записи без сохранения?<br/><br/>В противном случае нажмите "Отменить" и сохраните изменения`))
            dialogTabStore.setDialogTabButtons(["Закрыть", "Отменить"])
            dialogTabStore.setDialogTabIsOpen(true)    
        }
    }

    const handleSaveAndCloseClick = () => {
        dialogTabStore.setDialogTabIsOpen(false)
        onCloseClick()
    }

    useEffect(() => {
        if (isOpen && selectedFullDataModel) {
            const noResponse = setTimeout(() => {
                toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                setIsLoading(false)
            }, responseTimeOut)
    
            setIsLoading(true)
            DocumentService
                .getOneDataModel(selectedFullDataModel.id)
                .then(data => {
                    clearTimeout(noResponse)
                    setIsLoading(false)
                    setNestedDataModels(initNestedModels(data))
                })
                .catch(error => {
                    clearTimeout(noResponse)
                    setIsLoading(false)
                    showErrorToast(error, 'fetching', '')
                })
        } else {
            setAttachedFiles([])
        }
    }, [isOpen])

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={handleCloseDialogClick}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-500"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-500"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                    <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                        <div className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex  tw-pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                enterFrom="tw-translate-x-full"
                                enterTo="tw-translate-x-0"
                                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                leaveFrom="tw-translate-x-0"
                                leaveTo="tw-translate-x-full"
                            >
                                <Dialog.Panel  className="tw-pointer-events-auto tw-relative tw-w-[90vw]"> 
                                    <Transition.Child
                                        as={Fragment}
                                        enter="tw-ease-in-out duration-500"
                                        enterFrom="tw-opacity-0"
                                        enterTo="tw-opacity-100"
                                        leave="tw-ease-in-out tw-duration-500"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                    >
                                        <div className="tw-absolute tw-left-0 tw-top-0 tw--ml-8 tw-flex tw-pr-2 tw-pt-4 sm:tw--ml-10 sm:tw-pr-4">
                                        <button
                                            type="button"
                                            className="tw-rounded-md hover:tw-text-gray-300 tw-text-white hover:tw-bg-gray-500 focus:tw-outline-none tw-ring-2 tw-ring-white"
                                            onClick={handleCloseDialogClick}
                                        >
                                            <span className="tw-sr-only">Закрыть</span>
                                            <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                                        </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto tw-bg-gray-200 tw-px-4 tw-pb-4 tw-shadow-xl">
                                        {isLoading 
                                            ?   <Spinner/> 
                                            :   <>
                                                    <PageTitle title={`Новая запись таблицы "${selectedFullDataModel.entity_name}"`} />
                                                    <div
                                                        id='add-data-object-page'
                                                        style={{
                                                            display: 'grid', 
                                                            gridTemplateColumns: isNested ? '100%' : columnSizes,
                                                            height: 'calc(100% - 4rem)'
                                                        }}
                                                        ref={panelElement}
                                                    >
                                                        <div id='data-form-column' className='tw-h-full tw-overflow-hidden' ref={formElement}>
                                                            <DataObjectForm
                                                                editMode={false}
                                                                dataObjectFields={selectedFullDataModel.fields.slice()}
                                                                onSubmitClick={handleSubmitClick}
                                                                userErrors={errorInfo}
                                                                recordID={null}
                                                                onPrintDocumentClick={null}
                                                                isLoading={isObjLoading}
                                                                dataModel={selectedFullDataModel}
                                                                parent={null}
                                                            />
                                                        </div>
                                                        
                                                        {!isNested &&
                                                            <div id='data-files-column' className='tw-h-full tw-overflow-hidden tw-relative tw-pl-4'>
                                                                <PanelVerticalDivider
                                                                    leftElement={formElement}
                                                                    panel={panelElement}
                                                                    minLeftSize={minLeftColumnSize}
                                                                    minRightSize={minRightColumnSize}
                                                                />
                                                                { !isObjLoading
                                                                    ?
                                                                        <DataObjectTabs
                                                                            files={attachedFiles}
                                                                            readOnly={false}
                                                                            onFileSelect={handleFileSelect(attachedFiles, setAttachedFiles)}
                                                                            onFileRemove={handleFileRemove(attachedFiles, setAttachedFiles)}
                                                                            selectedNestedDataModel={selectedNestedDataModel}
                                                                            nestedDataModels={nestedDataModels}
                                                                            setSelectedNestedDataModel={setSelectedNestedDataModel}
                                                                            setNestedDataModels={setNestedDataModels}
                                                                            isDocumentPage={true}
                                                                            isNestedLoading={false}
                                                                            panel={panelElement}
                                                                            panelID='add-tabs'
                                                                            recordNotesStore={recordNotesStore}
                                                                        />
                                                                    :
                                                                        <div className='tw-h-full tw-w-full tw-bg-white tw-rounded-md'></div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <DialogTab 
                                                        parentName='AddDataObjectContainer'
                                                        dialogTabFunction={handleSaveAndCloseClick}
                                                        dialogTabStore={dialogTabStore}
                                                    />
                                                    {recordInputFormQuestions && recordInputFormQuestions?.show &&
                                                        <RecordInputForm
                                                            isVisible={isRecordInputFormOpen}
                                                            recordInputFormQuestions={recordInputFormQuestions}
                                                            onCloseModal={() => setIsRecordInputFormOpen(false)}
                                                            formDataObject={formDataObject}
                                                            saveRecord={saveRecord} 
                                                            selectedDataObject={null}
                                                        />
                                                    }
                                                </>
                                        }
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
                <Tooltip id="add-tabs-tooltip" className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
            </Dialog>
        </Transition.Root>
    )
}

export default observer(AddDataObjectContainer)