import React, { useEffect, useState, useContext, Fragment } from 'react'
import { toast } from 'react-toastify'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import PageTitle from '../main_page/controller/common/panels/PageTitle'
import DataEnumListBox from '../main_page/controller/common/inputs/DataEnumListBox'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { runInAction } from 'mobx'


/**
 * Компонент реализует логику редактирования фильтров
 *
 * @param {Boolean} isOpen Признак видимости (отображения) модального окна
 * @param {Function} onCloseClick Обработчик клика мыши для закрытия окна
 * @param {String} editColumnName Изначальное поле
 * @param {Boolean} author Признак наличия поля автор
 * @param {Object[]} object Массив полей
 * @param {Boolean} data Признак нахождения поля в атрибуте data
 * @param {Object} filterStore Экземпляр хранилища информации о применяемых фильтрах
 *
 */
const EditFilters = ({isOpen, onCloseClick, editColumnName, author, object, data, filterStore}) => {
    const { FilterStore } = useContext(Context)
    const filtersStore = filterStore ? filterStore : FilterStore

    const [field, setField] = useState(false)
    const [activeField, setActiveField] = useState([])

    let fields = object.fields.filter(item => item.type !== "include").map(item => item.alias)
    if (author)
        fields.push("Автор")

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        watch
    } = useForm()
    watch(["include", "key_values", "regExp", "compare"])

    const onSubmitClick = (form) => {
        if (form.regExp === "" && form.include === "" && form.compare === "" && form.key_values === "") {
            toast.error(<div>Заполните хотя бы одно поле</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        } else {
            let filteredFields = filtersStore.filteredFields.map(filter => {
                if(filter.name === activeField[0].tech_name)
                    runInAction(() => {
                        filter.active = true
                    })
                    return filter
                }
            )

            let filters = []
            if (form.key_values) {
                if (activeField[0]?.validator_type === "one") {
                    const referencedModels = object.referenced_models.filter(item => item.rule_id ===  activeField[0]?.rule_id)
                    referencedModels.forEach(model => {
                        model.format_fields.forEach(formatField => {
                            filters.push({
                                property: 'data["' + model.rule_id + '"].presentations[*].data.' + formatField,
                                value: form.key_values.split(",").map(item => item.trim()),
                                operator: "any_in"
                            })
                        })
                    })
                } else {
                    if (field === "Автор") {
                        filters.push({
                            operator: 'or',
                                filters: [
                                    {
                                        property: "modifier_id",
                                        value: form.key_values.split(",").map(item => item.trim()),
                                        operator: "in"
                                    },
                                    {
                                        property: "modifier.email",
                                        value: form.key_values.split(",").map(item => item.trim()),
                                        operator: "in"
                                    }
                                ]
                            }
                        )
                    } else {
                        filters.push({
                            property: data? 'data["' + activeField[0].tech_name + '"].value' : activeField[0].tech_name,
                            value: form.key_values.split(",").map(item => item.trim()),
                            operator: "in"
                        })
                    }
                }
            }
            if (form.compare) {
                if (form.compare.includes(",") || form.compare.includes("и")) {
                    let compareArray = form.compare.split(/[,и]+/).map(item => item.trim())
                    compareArray.forEach(compareItem => {
                        compareConditions(compareItem, activeField, filters)
                    })
                } else {
                    compareConditions(form.compare, activeField, filters)
                }
            }
            if (form.include) {
                let itemValue = ''
                
                itemValue = form.include.replaceAll("\\", "\\\\")
                itemValue = itemValue.replaceAll('_', '\\_')
                itemValue = itemValue.replaceAll('%', '\\%')

                itemValue = `%${itemValue}%`

                if (activeField[0]?.validator_type === "one") {
                    itemValue = itemValue.replaceAll('"', '\"%\\\"%\"')
                    itemValue = itemValue.replaceAll('[', '\"%[%\"')
                    itemValue = itemValue.replaceAll(']', '\"%]%\"')

                    const referencedModels = object.referenced_models.filter(item => item.rule_id ===  activeField[0]?.rule_id)
                    referencedModels.forEach(model => {
                        model.format_fields.forEach(formatField => {
                            filters.push({
                                property: 'data["' + model.rule_id + '"].presentations[*].data.' + formatField,
                                value: itemValue,
                                operator: "like_ic"
                            })
                        })
                    })
                } else {
                    if (field === "Автор") {
                        filters.push({
                            operator: 'or',
                                filters: [
                                    {
                                        property: "modifier_id",
                                        value: itemValue,
                                        operator: "like_ic"
                                    },
                                    {
                                        property: "modifier.email",
                                        value: itemValue,
                                        operator: "like_ic"
                                    }
                                ]
                            }
                        )
                    } else {
                        filters.push({
                            property: data? 'data["' + activeField[0].tech_name + '"].value' : activeField[0].tech_name,
                            value: itemValue,
                            operator: "like_ic"
                        })
                    }
                }
            }
            if (form.regExp) {
                if (activeField[0]?.validator_type === "one") {
                    const referencedModels = object.referenced_models.filter(item => item.rule_id === activeField[0]?.rule_id)
                    referencedModels.forEach(model => {
                        model.format_fields.forEach(formatField => {
                            filters.push({
                                property: 'data["' + model.rule_id + '"].presentations[*].data.' + formatField,
                                value: form.regExp,
                                operator: "reg"
                            })
                        })
                    })
                } else {
                    if (field === "Автор") {
                        filters.push({
                            operator: 'or',
                                filters: [
                                    {
                                        property: "modifier_id",
                                        value: form.regExp,
                                        operator: "reg"
                                    },
                                    {
                                        property: "modifier.email",
                                        value: form.regExp,
                                        operator: "reg"
                                    }
                                ]
                            }
                        )
                    } else {
                        filters.push({
                            property: data? 'data["' + activeField[0].tech_name + '"].value' : activeField[0].tech_name,
                            value: form.regExp,
                            operator: "reg"
                        })
                    }
                }
            }
            
            filtersStore.setSelectedFilters(filters)
            filtersStore.setFilteredFields(filteredFields)
            onCloseClick(false)
        }
    }

    const compareConditions = (compareItem, activeField, filters) => {
        if (compareItem.includes(">=")) {
            filters.push({
                property: data? 'data["' + activeField[0].tech_name + '"].value' : activeField[0].tech_name,
                value: Number(compareItem.replace(/[>=\s]/g, '')),
                operator: "ge"
            })
        }
        else if (compareItem.includes("<=")) {
            filters.push({
                property: data? 'data["' + activeField[0].tech_name + '"].value' : activeField[0].tech_name,
                value: Number(compareItem.replace(/[<=\s]/g, '')),
                operator: "le"
            })
        }
        else if (compareItem.includes(">")) {
            filters.push({
                property: data? 'data["' + activeField[0].tech_name + '"].value' : activeField[0].tech_name,
                value:  Number(compareItem.replace(/[>\s]/g, '')),
                operator: "gt"
            })
        }
        else if (compareItem.includes("<")) {
            filters.push({
                property: data? 'data["' + activeField[0].tech_name + '"].value' : activeField[0].tech_name,
                value:  Number(compareItem.replace(/[<\s]/g, '')),
                operator: "lt"
            })
        }
        else if (compareItem.includes("!=")) {
            filters.push({
                property: data? 'data["' + activeField[0].tech_name + '"].value' : activeField[0].tech_name,
                value:  Number(compareItem.replace(/[!=\s]/g, '')),
                operator: "neq"
            })
        }
        else if (compareItem.includes("=")) {
            filters.push({
                property: data? 'data["' + activeField[0].tech_name + '"].value' : activeField[0].tech_name,
                value:  Number(compareItem.replace(/[=\s]/g, '')),
                operator: "eq"
            })
        }
    }

    const clearSelectedFilter = (e) => {
        e.preventDefault()
        filtersStore.clearSelectedFilter(activeField[0].tech_name, activeField[0].rule_id)
        onDialogCloseClick()
    }

    const clearAllFilters = (e) => {
        e.preventDefault()
        filtersStore.clearAllFilters()
        onCloseClick(false)
    }

    useEffect(() => {
        if (editColumnName) {
            setField(editColumnName)

            if(editColumnName === "Автор")
                setActiveField([{
                    alias: "Автор",
                    built_in: true,
                    field_id: "modifier_id",
                    full_name: "Автор",
                    hide: false,
                    mandatory: false,
                    order: 0,
                    tech_name: "modifier_id",
                    type: "value",
                    validator_type: "string"
                }])
            else setActiveField(object.fields.filter(item => item.alias === editColumnName))
        }
    }, [editColumnName])

    useEffect(() => {
        if (field) {
            setValue('regExp', '')
            setValue('include', '')
            setValue('compare', '')
            setValue('key_values', '')

            if(field === "Автор")
                setActiveField([{
                    alias: "Автор",
                    built_in: true,
                    field_id: "modifier_id",
                    full_name: "Автор",
                    hide: false,
                    mandatory: false,
                    order: 0,
                    tech_name: "modifier_id",
                    type: "value",
                    validator_type: "string"
                }])
            else setActiveField(object.fields.filter(item => item.alias === field))
        }
    }, [field])

    useEffect(() => {
        if(activeField[0] && isOpen) {
            let compareFieldStr = ""
            filtersStore.selectedFilters.forEach(item => {
                if (item.filters && activeField[0]?.tech_name === 'modifier_id') {
                    if (item.filters[0].operator === "in") {
                        setValue('key_values', item.filters[0].value.join(','))
                    }
                    if (item.filters[0].operator === "like_ic") {
                        let itemValue = item.filters[0].value.slice(1, -1)

                        itemValue = itemValue.replaceAll("\\\\", "\\")
                        itemValue = itemValue.replaceAll('\\_', '_')
                        itemValue = itemValue.replaceAll('\\%', '%')
                        itemValue = itemValue.replaceAll('\"%\\\"%\"', '"')
                        itemValue = itemValue.replaceAll('\"%[%\"', '[')
                        itemValue = itemValue.replaceAll('\"%]%\"', ']')

                        setValue('include', itemValue)
                    }
                    if (item.filters[0].operator === "reg") {
                        setValue('regExp', item.filters[0].value)
                    }
                } else {
                    if (item.property) {
                        if ((!item.property?.includes('.presentations[*]') && item.property?.includes(activeField[0]?.tech_name)) 
                            || (item.property?.includes('.presentations[*]') && item.property?.includes(activeField[0]?.rule_id))) 
                        {
                            
                            if (item.operator === "in") {
                                setValue('key_values', item.value.join(','))
                            }
                            if (item.operator === "any_in") {
                                setValue('key_values', item.value.join(','))
                            }
                            if (item.operator === "ge") {
                                compareFieldStr += ">=" + item.value + ","
                            }
                            if (item.operator === "le") {
                                compareFieldStr += "<=" + item.value + ","
                            }
                            if (item.operator === "gt") {
                                compareFieldStr += ">" + item.value + ","
                            }
                            if (item.operator === "lt") {
                                compareFieldStr += "<" + item.value + ","
                            }
                            if (item.operator === "eq") {
                                if(item.value | item.value === 0)
                                    compareFieldStr += "=" + item.value + ","
                            }
                            if (item.operator === "neq") {
                                if(item.value | item.value === 0)
                                    compareFieldStr += "!=" + item.value + ","
                            }
                            if (item.operator === "like_ic") {
                                let itemValue = item.value.slice(1, -1)

                                itemValue = itemValue.replaceAll("\\\\", "\\")
                                itemValue = itemValue.replaceAll('\\_', '_')
                                itemValue = itemValue.replaceAll('\\%', '%')
                                itemValue = itemValue.replaceAll('\"%\\\"%\"', '"')
                                itemValue = itemValue.replaceAll('\"%[%\"', '[')
                                itemValue = itemValue.replaceAll('\"%]%\"', ']')

                                setValue('include', itemValue)
                            }
                            if (item.operator === "reg") {
                                setValue('regExp', item.value)
                            }
                        }
                    }
                }
            })
            setValue('compare', compareFieldStr.slice(0, -1))
        } else {
            setValue('regExp', '')
            setValue('include', '')
            setValue('compare', '')
            setValue('key_values', '')
        }
    }, [isOpen, activeField])

    const onDialogCloseClick = () => {
        if(filtersStore.selectedFilters.length === 0){
            filtersStore.setIsFiltering(false)
        }
        onCloseClick(false)
    }

    return (
    <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="tw-relative tw-z-10" onClose={onDialogCloseClick}>
            <Transition.Child
                as={Fragment}
                enter="tw-ease-in-out tw-duration-500"
                enterFrom="tw-opacity-0"
                enterTo="tw-opacity-100"
                leave="tw-ease-in-out tw-duration-500"
                leaveFrom="tw-opacity-100"
                leaveTo="tw-opacity-0"
            >
                <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
            </Transition.Child>

            <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                    <div className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex  tw-pl-10">
                        <Transition.Child
                            as={Fragment}
                            enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                            enterFrom="tw-translate-x-full"
                            enterTo="tw-translate-x-0"
                            leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                            leaveFrom="tw-translate-x-0"
                            leaveTo="tw-translate-x-full"
                        >
                            <Dialog.Panel className="tw-pointer-events-auto tw-relative tw-w-[50vw]">
                                <Transition.Child
                                    as={Fragment}
                                    enter="tw-ease-in-out duration-500"
                                    enterFrom="tw-opacity-0"
                                    enterTo="tw-opacity-100"
                                    leave="tw-ease-in-out tw-duration-500"
                                    leaveFrom="tw-opacity-100"
                                    leaveTo="tw-opacity-0"
                                >
                                    <div className="tw-absolute tw-left-0 tw-top-0 tw--ml-8 tw-flex tw-pr-2 tw-pt-4 sm:tw--ml-10 sm:tw-pr-4">
                                    <button
                                        type="button"
                                        className="tw-rounded-md hover:tw-text-gray-300 tw-text-white hover:tw-bg-gray-500 focus:tw-outline-none tw-ring-2 tw-ring-white"
                                        onClick={() => onCloseClick(false)}
                                    >
                                        <span className="tw-sr-only">Закрыть</span>
                                        <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                                    </button>
                                    </div>
                                </Transition.Child>
                                <div className="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto tw-bg-gray-200 tw-p-6 tw-shadow-xl">
                                    <PageTitle id='edit-filters-page' title={"Настройка фильтров"}/>
                                    <form className='tw-flex tw-flex-col tw-h-full tw-rounded-md tw-bg-white'>
                                        <div className='tw-grow tw-p-4 tw-overflow-auto'>
                                            <div className="tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                                                <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                                                    <span>Поле</span>
                                                </dt>
                                                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                    <DataEnumListBox
                                                        itemList={fields}
                                                        selectedItem={field}
                                                        onItemChange={(newField) => {setField(newField)}}
                                                    />
                                                </dd>
                                            </div>
                                            {(activeField[0]?.validator_type === "string" || activeField[0]?.validator_type === "one") &&
                                                <div className="tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                                                    <dt className={`tw-text-sm tw-font-medium
                                                            ${(getValues('include') !== "" || getValues('regExp') !== "") ? "tw-text-gray-400" : "tw-text-gray-900"}
                                                            tw-flex tw-flex-row tw-items-center tw-gap-x-4`
                                                        }
                                                    >
                                                        <span>По полным значениям</span>
                                                    </dt>
                                                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                        <input
                                                            type='text'
                                                            placeholder={(getValues('include') !== "" || getValues('regExp') !== "") ? "" : 'Значение, значение....'}
                                                            disabled={getValues('include') !== "" || getValues('regExp') !== ""}
                                                            className='tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900
                                                                tw-ring-1 tw-ring-inset tw-ring-gray-400 focus:tw-ring-2 focus:tw-ring-inset
                                                                focus:tw-z-10 tw-text-sm focus-visible:tw-ring-gray-400 focus-visible:tw-outline-none'
                                                            {...register('key_values')}
                                                        />
                                                    </dd>
                                                </div>
                                            }
                                            {(activeField[0]?.validator_type === "int" || activeField[0]?.validator_type === "float") &&
                                                <div className="tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                                                    <dt className={`tw-text-sm tw-font-medium
                                                            ${getValues('regExp') !== "" ? "tw-text-gray-400" : "tw-text-gray-900"}
                                                            tw-flex tw-flex-row tw-items-center tw-gap-x-4`
                                                        }
                                                    >
                                                        <span>Сравнение</span>
                                                    </dt>
                                                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                        <input
                                                            type='text'
                                                            placeholder={getValues('regExp') !== "" ? "" : '(>=, <=, <, >, =, !=) значение ,(и) (>=, <=, <, >, =, !=) значение...'}
                                                            disabled={getValues('regExp') !== ""}
                                                            className='tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900
                                                                tw-ring-1 tw-ring-inset tw-ring-gray-400 focus:tw-ring-2 focus:tw-ring-inset
                                                                focus:tw-z-10 tw-text-sm focus-visible:tw-ring-gray-400 focus-visible:tw-outline-none'
                                                            {...register('compare')}
                                                        />
                                                    </dd>
                                                </div>
                                            }
                                            {(activeField[0]?.validator_type === "string" || activeField[0]?.validator_type === "enum" || activeField[0]?.validator_type === "one") &&
                                                <div className="tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                                                    <dt className={
                                                            `tw-text-sm tw-font-medium
                                                            ${(getValues('key_values') !== "" || getValues('regExp') !== "") ? "tw-text-gray-400" : "tw-text-gray-900"}
                                                            tw-flex tw-flex-row tw-items-center tw-gap-x-4`
                                                        }
                                                    >
                                                        <span>Содержит</span>
                                                    </dt>

                                                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                        <input
                                                            type='text'
                                                            placeholder={(getValues('key_values') !== "" || getValues('regExp') !== "") ? "" : 'Значение...'}
                                                            disabled={getValues('key_values') !== "" || getValues('regExp') !== ""}
                                                            className='tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900
                                                                tw-ring-1 tw-ring-inset tw-ring-gray-400 focus:tw-ring-2 focus:tw-ring-inset
                                                                focus:tw-z-10 tw-text-sm focus-visible:tw-ring-gray-400 focus-visible:tw-outline-none'
                                                            {...register('include')}
                                                        />
                                                    </dd>
                                                </div>
                                            }
                                            <div className="tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                                                <dt className={
                                                        `tw-text-sm tw-font-medium
                                                        ${(getValues('include') !== "" || getValues('key_values') !== "" || getValues('compare') !== "")
                                                            ? "tw-text-gray-400" : "tw-text-gray-900"
                                                        }
                                                        tw-flex tw-flex-row tw-items-center tw-gap-x-4`
                                                    }
                                                >
                                                    <span>Регулярное выражение</span>
                                                </dt>
                                                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                    <input
                                                        type='text'
                                                        placeholder={(getValues('include') !== "" || getValues('key_values') !== "" || getValues('compare') !== "") ? "" : 'Выражение...'}
                                                        disabled={getValues('include') !== "" || getValues('key_values') !== "" || getValues('compare') !== ""}
                                                        className='tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900
                                                            tw-ring-1 tw-ring-inset tw-ring-gray-400 focus:tw-ring-2 focus:tw-ring-inset
                                                            focus:tw-z-10 tw-text-sm focus-visible:tw-ring-gray-400 focus-visible:tw-outline-none'
                                                        {...register('regExp')}
                                                    />
                                                </dd>
                                            </div>

                                            {(activeField[0]?.validator_type === "string" || activeField[0]?.validator_type === "enum" || activeField[0]?.validator_type === "one") &&
                                                <p className="tw-text-sm tw-font-medium tw-text-red-400 tw-m-2">
                                                    При использовании учитывайте, что фильтр "По полным значениям" регистрозависимый
                                                </p>
                                            }

                                            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-py-4'>
                                                <button
                                                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                                            hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                                    onClick={handleSubmit(onSubmitClick)}
                                                >
                                                    Применить
                                                </button>
                                                <button
                                                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                                                            hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                                    onClick={clearSelectedFilter}
                                                >
                                                    Сбросить
                                                </button>
                                                <button
                                                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                                                            hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                                    onClick={clearAllFilters}
                                                >
                                                    Сбросить все
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </div>
        </Dialog>
    </Transition.Root>
)
}

export default observer(EditFilters)
