import React, { useContext } from 'react'
import { Context } from '../../../../../../..'
import { observer } from 'mobx-react-lite'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import PlannedActionItem from './PlannedActionItem'

/**
 * Визуальный компонент отображает список запланированных действий Планировщика для выбранной категории действий
 * 
 * @param {Object} item Категория действий
 * @param {Function} onDoubleClick Обработчик двойного клика мыши на действии
 * 
 */
const PlannedActionList = ({item, onDoubleClick}) => {
    const { plannerStore } = useContext(Context)

    const filteredActions = item.id === '0'
        ? plannerStore.plannedActions.filter(plannedItem => !plannedItem.data.security_measure.value.values[0]?.record_id)
        : plannerStore.plannedActions.filter(plannedItem => plannedItem.data.security_measure.value.values[0]?.record_id === item.record_id)

    if (filteredActions.length === 0)
        return <></>

    return (
        <div key={item.id} id={`planned-category-item-${item.id}`} className="tw-w-full tw-pt-0.5">
            <Disclosure defaultOpen={plannerStore.plannedCategoriesDefaultOpen[item.id]}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className={`tw-flex tw-w-full tw-justify-between tw-bg-gray-300 tw-px-4 tw-border-y tw-py-1 tw-text-left 
                            tw-text-sm tw-font-medium tw-text-gray-900 tw-border-gray-300 focus:tw-outline-none 
                            focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-75
                            `}
                            onClick={() => {plannerStore.setPlannedCategoriesDefaultOpen({...plannerStore.plannedCategoriesDefaultOpen, [item.id]: !open})}}
                        >
                            <span>{item.data.name.value}</span>
                            { open
                                ?   <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                :   <ChevronRightIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            }     
                        </Disclosure.Button>
                        <Disclosure.Panel className="tw-pl-4 tw-pt-2 tw-pr-1" id={`planned-category-list-${item.id}`}>
                            {filteredActions.map((actionItem) =>
                                <PlannedActionItem
                                    key={actionItem.record_id}
                                    actionItem={actionItem}
                                    onDoubleClick={onDoubleClick}
                                />
                            )}
                        </Disclosure.Panel>
                    </>
                )}     
            </Disclosure>
        </div>
    )
}

export default observer(PlannedActionList)