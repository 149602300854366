import React, { useContext } from 'react'
import { Tab } from '@headlessui/react'
import RolesPermissionsInfo from './role/RolePermissionsInfo'
import RolesPermissionsForm from './role/RolePermissionsForm'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import DataModelPermissionsInfo from './data_model/DataModelPermissionsInfo'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
  
  
/**
 * Компонент отображает вкладки с правами для роли
 * 
 */
const RolesPermissionsTabs = () => {
    const { RolesStore } = useContext(Context)

    return (
            <Tab.Group selectedIndex={RolesStore.permissionTabIndex} onChange={(index) => RolesStore.setPermissionTabIndex(index)}>
                <Tab.List className="tw-flex tw-space-x-1 tw-rounded-md tw-h-12">
                    <Tab
                        className={({ selected }) =>
                            classNames(
                                'tw-h-12 tw-w-52 tw-rounded-t-md tw-p-2 tw-text-sm tw-font-semibold tw-leading-5 tw-text-gray-900 tw-truncate tw-border-b-2',
                                'tw-ring-white tw-ring-opacity-60 focus:tw-outline-none focus:tw-ring-offset-0 disabled:tw-invisible',
                                selected
                                    ? 'tw-bg-white tw-border-gray-400'
                                    : 'tw-bg-gray-300 hover:tw-bg-gray-100'
                            )
                        }
                    >
                        Функциональные права
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            classNames(
                                'tw-h-12 tw-w-52 tw-rounded-t-md tw-p-2 tw-text-sm tw-font-semibold tw-leading-5 tw-text-gray-900 tw-truncate tw-border-b-2',
                                'tw-ring-white tw-ring-opacity-60 focus:tw-outline-none focus:tw-ring-offset-0 disabled:tw-invisible',
                                selected
                                    ? 'tw-bg-white tw-border-gray-400'
                                    : 'tw-bg-gray-300 hover:tw-bg-gray-100'
                            )
                        }
                    >
                        Права для таблиц
                    </Tab>

                </Tab.List>

                <Tab.Panels className='tw-h-[calc(100%_-_3rem)] tw-rounded-b-md tw-rounded-tr-md tw-bg-white tw-overflow-hidden'>
                    <Tab.Panel className='tw-h-full'>
                        { RolesStore.isPermissionsEditing
                            ? <RolesPermissionsForm/>
                            : <RolesPermissionsInfo/>
                        }
                    </Tab.Panel>
                    <Tab.Panel className='tw-h-full'>
                        <DataModelPermissionsInfo/>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
    )
}

export default observer(RolesPermissionsTabs)