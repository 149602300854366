import React, { useContext, useEffect, useState } from 'react'
import PageTitle from '../../main_page/controller/common/panels/PageTitle'
import FileSelectButton from '../../main_page/controller/common/buttons/FileSelectButton'
import { TrashIcon } from '@heroicons/react/24/outline'
import { Context } from '../../..'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react-lite'


/**
 * Визуальный компонент отображает информацию о версиях конфигурации
 * 
 */
const ConfigurationUpload = () => {
    const { AdminStore } = useContext(Context)
    const [files, setFiles] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isUploadSuccess, setIsUploadSuccess] = useState(false)

    const handlePackageSelect = (e) => {
        const selectedFile = e.target.files[0]
        const sizeKB = selectedFile.size / 1024
        const sizeMB = sizeKB / 1024
        if (sizeMB < 100) {
            setFiles([selectedFile])
            setIsUploadSuccess(false)
        } else {
            toast.error('Размер файла превышает 100 МБ!', { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        }
    }

    const getFileSize = (file) => {
        const sizeKB = Math.floor(file.size * 100 / 1024) / 100
        const sizeMB = Math.floor(file.size * 100 / 1048576) / 100
        return sizeMB > 1 ? sizeMB + ' MB' : sizeKB + ' KB'
    }
    
    const handleUploadClick = () => {
        setIsLoading(true)
        AdminStore.uploadConfiguration(files[0], setFiles, setIsLoading)
    }

    useEffect(() => {
        if (isLoading && !files.length) {
            setIsLoading(false)
            setIsUploadSuccess(true)
        }
    }, [files, isLoading])

    return (
        <>
            <PageTitle title='Загрузка пакета с конфигурацией'/>
            <div className='tw-w-full tw-h-full tw-px-4 tw-bg-white tw-rounded-md'>
                <div className='tw-p-4'>
                    { isUploadSuccess && <div className='tw-text-md tw-font-semibold tw-text-center tw-mb-4'>Пакет с конфигурацией загружен</div>}
                    { isLoading 
                        ?
                            <div className='tw-text-md tw-font-semibold tw-text-center'>Выполняется загрузка пакета с конфигурацией...</div>
                        :
                            <>
                                <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-mx-auto tw-py-2'>
                                    <button 
                                        className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                                hover:tw-bg-gray-600 hover:tw-border-gray-600 disabled:tw-bg-gray-300  disabled:tw-border-gray-300
                                                focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                        disabled={!files.length}
                                        onClick={handleUploadClick}
                                    >
                                        Загрузить пакет
                                    </button>
                                </div>
                                { !files.length && <FileSelectButton onFileSelect={handlePackageSelect}/>}
                                <ul className='tw-px-2'>
                                    {files.map((file, index) => (
                                        <div key={index} className='tw-flex tw-flex-row tw-justify-start tw-items-center tw-border tw-border-gray-400 tw-rounded-md tw-mt-2'>
                                            <div className='tw-flex tw-justify-center tw-w-16'>
                                                <button 
                                                    className='tw-text-gray-400 hover:tw-text-red-600 focus-visible:tw-text-red-600 focus-visible:tw-outline 
                                                            focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-red-600 focus-visible:tw-rounded-sm'
                                                    type='button'
                                                    onClick={() => setFiles([])}
                                                >
                                                    <TrashIcon  className="tw-w-6 tw-h-6" aria-hidden="true"/>
                                                </button>
                                            </div>
                                        <li className="tw-relative tw-rounded-md tw-mb-1 tw-px-1 tw-py-1 tw-truncate">
                                            <h3 className="tw-text-sm tw-font-medium tw-leading-5 tw-py-2">
                                                {file.name} &middot; {getFileSize(file)}
                                            </h3>
                                        </li>
                                    </div>
                                    ))}
                                </ul>
                            </>
                    }
                </div>

            </div>
        </>
    )
}

export default observer(ConfigurationUpload)