import React, { useContext } from 'react'
import { CheckCircleIcon, MinusIcon } from '@heroicons/react/20/solid'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import { contentTooltipTimeOut, menuTooltipTimeOut } from '../../../config/constTypes'

/**
 * Визуальный компонент отображает название одной роли для списка существующих ролей пользователей
 * 
 * @param {Object} item Текущий элемент списка ролей пользователей 
 * 
 */
const RolesListItem = ({item}) => {
    const { RolesStore, userStore } = useContext(Context)

    const onItemClick = (item) => {
        if (RolesStore.selectedRole?.id !== item.id) {
            RolesStore.setSelectedRole(item)
            RolesStore.setSelectedUser(null)
            RolesStore.getRole(item.id)
            if (userStore.checkPermission("entity.permission:get") || userStore.checkPermission("entity.permission:edit"))
                RolesStore.getRoleDataModelPermissions(item.tech_id)
        }
    }

    const bindRole = () => {
        if (userStore.checkPermission("company.my.role:edit"))
            RolesStore.rebindRole(item.id, {users: {add: [RolesStore.selectedUser.id]}})
    }

    const unBindRole = () => {
        if (userStore.checkPermission("company.my.role:edit"))
            RolesStore.rebindRole(item.id, {users: {remove: [RolesStore.selectedUser.id]}})
    }
    
    return (
        <div className={`tw-relative tw-pl-12 tw-pr-12 tw-text-sm tw-select-none tw-border-b
                ${RolesStore.selectedRole?.id === item.id
                    ?   'tw-text-white tw-bg-gray-500'
                    :   'tw-text-gray-900 tw-bg-white hover:tw-bg-gray-100 hover:tw-border-gray-300'} 
                `}
        >
            <div
                className='tw-py-2 tw-absolute tw-right-3 '
                data-tooltip-id="data-model-item-tooltip" data-tooltip-content={item.built_in ? 'Из конфигурации системы' : 'Пользовательский'} data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <span className={`tw-px-2 tw-py-0.5 tw-w-5 tw-h-5 tw-font-bold tw-rounded-md
                        ${RolesStore.selectedRole?.id === item.id
                            ? 'tw-text-white tw-bg-gray-600'
                            : 'tw-text-gray-900 tw-bg-gray-200'}
                    `}
                >
                    {item.built_in ? 'К' : 'П'}
                </span>
            </div> 
            { RolesStore.selectedUser &&
                <div
                    className={`tw-text-gray-500 tw-py-2 tw-absolute tw-left-3 ${userStore.checkPermission("company.my.role:edit") && RolesStore.selectedRole?.id !== item.id && 'hover:tw-cursor-pointer'}`}
                    data-tooltip-id="roles-list-tooltip" data-tooltip-content={userStore.checkPermission("company.my.role:edit") ? item.isAssigned  ? "Отвязать" : "Привязать" : ''} data-tooltip-delay-show={contentTooltipTimeOut}
                >
                    {item.isAssigned 
                        ? <CheckCircleIcon className="tw-w-5 tw-h-5 hover:tw-text-gray-700" onClick={unBindRole}/> 
                        : <MinusIcon className="tw-w-5 tw-h-5 hover:tw-text-gray-700" onClick={bindRole}/>
                    }
                </div>
            }
            <div
                className={`tw-w-full tw-py-2 tw-truncate ${RolesStore.selectedRole?.id !== item.id && 'hover:tw-cursor-pointer'}`}
                onClick={() => onItemClick(item)}
                onDoubleClick={() => RolesStore.setIsRoleInfoOpen(true)}
                data-tooltip-id="roles-list-tooltip" data-tooltip-content={item.name} data-tooltip-delay-show={contentTooltipTimeOut}
                >
                {item.name}
            </div>
        </div>
    )
}

export default observer(RolesListItem)
