import React, { useEffect, useContext } from 'react'
import { Context } from '../../../../../../..'
import { useForm } from 'react-hook-form'
import FormFieldName from '../../../../../../form_fields/FormFieldName'
import SaveIcon from '../../../../../../../assets/SaveIcon'
import { observer } from 'mobx-react-lite'
import { getFieldValue, menuTooltipTimeOut } from '../../../../../../../config/constTypes'
import { useHotkeys } from 'react-hotkeys-hook'
import { LinkIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'

/**
 * Визуальный компонент отображает форму отметки о выполнении запланированного действия планировщика
 * 
 * @param {Boolean} isOpen Признак видимости (отображения) модального окна
 * @param {Boolean} isLoading Признак загрузки/сохранения данных формы
 * @param {Function} onSubmitClick Обработчик клика мыши на кнопке сохранения данных при выполнении запланированного действия
 * @param {Function} onCopyLinkClick Обработчик клика мыши на кнопке копирования ссылки в буфер обмена
 * 
 */
const DoneForm = ({isOpen, isLoading, onSubmitClick, onCopyLinkClick}) => {
    const {
        register,
        handleSubmit,
    } = useForm()

    const { plannerStore } = useContext(Context)

    const keyRef = useHotkeys('ctrl+s', () => {if (isOpen && !isLoading) handleSubmit(onSubmitClick)()},
        { preventDefault: true, enableOnFormTags: true }
    )
    useHotkeys('ctrl+l', () => {if (isOpen) onCopyLinkClick()}, { preventDefault: true, enableOnFormTags: true })

    useEffect(() => {
        if (isOpen) {
            document.getElementById('comment-field').focus()
        }
    }, [isOpen])

    return (
        <>
            <form ref={keyRef} tabIndex={-1} className='tw-flex tw-flex-col tw-h-full tw-rounded-md tw-bg-white tw-overflow-hidden'>
                <div className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                    <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Действие</p>
                    <div className='tw-flex tw-flex-row tw-gap-x-1'>
                        <button
                            className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                            onClick={handleSubmit(onSubmitClick)}
                            disabled={isLoading}
                            data-tooltip-id="action-done-form-tooltip"
                            data-tooltip-html="Сохранить &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+S)"
                            data-tooltip-delay-show={menuTooltipTimeOut}
                        >
                            <SaveIcon/>
                        </button>
                        <button
                            className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-300'
                            onClick={onCopyLinkClick}
                            type='button'
                            data-tooltip-id="action-done-form-tooltip"
                            data-tooltip-html="Копировать ссылку в буфер обмена &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+L)"
                            data-tooltip-delay-show={menuTooltipTimeOut}
                        >
                            <LinkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        </button>
                    </div>
                </div>
                <div className='tw-grow tw-overflow-auto'>
                    <div className="tw-mb-1 tw-px-4 tw-pt-2 tw-pb-3 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                            <FormFieldName  
                                item={{alias: 'Наименование'}}
                                errors={[]}
                            />
                        </dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                            <div className='tw-min-h-8 tw-w-full tw-px-2 tw-py-1.5 tw-mt-1 tw-rounded-md tw-border tw-text-gray-500 tw-border-gray-300 tw-select-none'>
                                { getFieldValue(plannerStore.selectedAction.data['my_action_reference']) }
                            </div>
                        </dd>
                    </div>
                    <div className="tw-mb-1 tw-px-4 tw-pt-2 tw-pb-3 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                            <FormFieldName  
                                item={{alias: 'Дата и время (по плану)'}}
                                errors={[]}
                            />
                        </dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                        <div className='tw-h-8 tw-w-full tw-px-2 tw-py-1.5 tw-mt-1 tw-rounded-md tw-border tw-text-gray-500 tw-border-gray-300 tw-select-none'>
                                { getFieldValue(plannerStore.selectedAction.data['action_completion_date']) }
                            </div>
                        </dd>
                    </div>
                    <div className="tw-mb-1 tw-px-4 tw-pt-2 tw-pb-3 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                            <FormFieldName  
                                item={{alias: 'Комментарий'}}
                                errors={[]}
                            />
                        </dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                            <textarea
                                id='comment-field'
                                className={`tw-w-full tw-h-32 tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900
                                    tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm
                                    focus-visible:tw-outline-none focus-visible:tw-border-gray-600 focus-visible:tw-ring-2 
                                    focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2
                                    tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400`}
                                autoFocus={true}
                                {...register('comment', { 
                                    required: false
                                })} 
                            />
                        </dd>
                    </div>
                </div>
            </form>
            <Tooltip id="action-done-form-tooltip" place="top"/>
        </>
    )
}

export default observer(DoneForm)