import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'


/**
 * Визуальный компонент отображает поле выбора значения перечисляемого типа из выпадающего списка доступных значений
 * 
 * @param {Object[]} itemList Список доступных значений данного перечисляемого типа 
 * @param {Object} selectedItem Выбранный элемент в списке доступных значений
 * @param {Function} onItemChange Обработчик клика мыши при выборе значения в списке {(e) => field.onChange(e)}
 * @param {Boolean} isNested Признак нахождения поля в ячейке вложенной таблицы
 * @param {String} id id
 * @param {Boolean} readOnly Признак режима чтения
 * @param {Boolean} isError Признак ошибки
 * @param {Object} panel Панель, где находятся элементы
 * @param {Boolean} isRowSelected Признак выбора строки, в которой находится элемент
 * 
 */
const DataEnumListBox = ({itemList, selectedItem, onItemChange, isNested, id, readOnly, isError, panel, isRowSelected}) => {
    const [top, setTop] = useState(null)
    const [scroll, setScroll] = useState(false)
    const [height, setHeight] = useState(null)
    const [translateY, setTranslateY] = useState(false)
    const [left, setLeft] = useState(null)
    const [width, setWidth] = useState(null)

    const onListClick = (e) => {
        setScroll(false)
        setTranslateY(false)
        setHeight(null)
        setTop(e.clientY + 10)    

        if (isNested) {
            if (panel) {
                let currentWidth
                if (e.currentTarget.offsetWidth < 300)
                    currentWidth = 300
                else
                    currentWidth = e.currentTarget.offsetWidth
                setWidth(currentWidth)
                if (e.clientX + currentWidth > window.innerWidth)
                    setLeft(window.innerWidth - currentWidth)
                else
                    setLeft(e.clientX - currentWidth)

            } else {
                if (e.currentTarget.offsetWidth < 300) {
                    let currentWidth = 300
                    setWidth(currentWidth)
                    if (e.clientX + currentWidth > window.innerWidth)
                        setLeft(window.innerWidth - currentWidth)

                } else {
                    setWidth(e.currentTarget.offsetWidth)
                }
            }
        } else {
            setWidth(e.currentTarget.offsetWidth)
        }
        let clientTop = e.clientY + 10

        setTimeout(() => {
            if(document.getElementById(id)?.offsetHeight > (window.innerHeight - clientTop)){
                setScroll(true)
                if((window.innerHeight - clientTop) < 150){
                    setTranslateY(true)
                    setHeight(150)
                } else {
                    setHeight(window.innerHeight - clientTop)
                }
            }
        }, 1000)
    }

    return (
        <Listbox value={selectedItem} onChange={onItemChange} as='div' name={id}>
            <div className={`${!isNested && 'tw-mt-1'} ${readOnly && 'tw-pointer-events-none'}`}>
                <Listbox.Button onClick={onListClick} 
                    className={({ open }) =>
                        `${isNested ? 'tw-py-1 tw-pl-1' 
                            : `${isError ? open ? 'tw-ring-red-400 !tw-ring-2' : 'tw-border-red-400' 
                                        : open ? 'tw-ring-gray-400 !tw-ring-2' : 'tw-border-gray-400'}
                                tw-rounded-md tw-py-1.5 tw-border tw-h-8 tw-pl-2 focus-visible:tw-ring-offset-gray-400
                        `} tw-relative tw-w-full tw-cursor-default tw-pr-6 tw-text-left focus:tw-outline-none focus-visible:tw-outline-none
                        focus-visible:tw-ring-2 focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2 sm:tw-text-sm
                        ${isRowSelected ? 'tw-bg-gray-300' : 'tw-bg-white'}
                    `}
                >
                    <span className="tw-block tw-truncate">{selectedItem}</span>
                    { !readOnly &&
                        <span className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-1">
                            <ChevronUpDownIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                        </span>
                    }
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    enter={`${isNested && 'tw-transition tw-ease-out tw-duration-200'}`}
                    enterFrom={`${isNested && 'tw-opacity-0 tw-translate-y-1'}`}
                    enterTo={`${isNested && 'tw-opacity-100 tw-translate-y-0'}`}
                    leave="tw-transition tw-ease-in tw-duration-100"
                    leaveFrom={`${isNested && 'tw-translate-y-0'} tw-opacity-100`}
                    leaveTo={`${isNested && 'tw-translate-y-1'} tw-opacity-0`}
                    
                >
                    <Listbox.Options id={id} style={{height: height, top: top, left: left, width: width}} className={`${translateY ? 'tw--translate-y-52' : ''} 
                                                ${isNested ? 'tw-mt-2' : ''}
                                                tw-fixed tw-z-30 tw-mt-1 tw-max-h-60 tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-shadow-lg tw-ring-1
                                                tw-ring-black tw-ring-opacity-5 tw-whitespace-break-spaces focus:tw-outline-none sm:tw-text-sm 
                                                ${scroll ? 'tw-overflow-y-scroll' : ''}
                                            `}
                    >
                        {itemList?.map((item, index) => (
                            <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                    `tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-10 tw-pr-4 
                                    ${ active ? 'tw-bg-gray-500 tw-text-white' : 'tw-text-gray-900' }`
                                }
                                value={item}
                            >
                            {({ selected }) => (
                                <>
                                    <span
                                        className={`tw-block ${
                                            selected ? 'tw-font-semibold' : 'tw-font-normal' }`
                                    }
                                    >
                                        {item}
                                    </span>
                                    {selected ? (
                                        <span className="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
                                            <CheckIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>
                                    ) : null}
                                </>
                            )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}

export default DataEnumListBox