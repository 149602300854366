import React, { useState, useRef, useEffect, useCallback } from 'react'
import { ChevronDoubleUpIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { createReportHeaders } from '../../../../functions/createHeaders'
import { getFieldValue } from '../../../../config/constTypes'
import { sortRecords } from '../../../../functions/sortRecords'


/**
 * Визуальный компонент отображает список записей отчета
 * 
 * @param {Object} dataModel Таблица с полями
 * @param {Object[]} reportData Массив отображаемых записей отчета
 * @param {Boolean} isItemSelect Признак возможности выбора элемента списка
 * @param {Function} onCellClick Обработчик клика мыши на элемента списка
 * @param {String} filterString Выбранные фильтры для отображения в адресной строке браузера
 * 
 */
const ReportTable = ({dataModel, reportData, onCellClick, filterString}) => {
    const [activeColumn, setActiveColumn] = useState(null)
    const [offsetX, setOffsetX] = useState(0)
    const [sortingDirection, setSortingDirection] = useState('down')
    const [sortingColumn, setSortingColumn] = useState('responsible_user')
    const [sortingType, setSortingType] = useState('string')
    const [isMinScrollPosition, setIsMinScrollPosition] = useState(true)
    const [isVerticalScroll, setIsVerticalScroll] = useState(false)
    let prevScrollLeft = 0

    const tableHeaderElement = useRef(null)
    const tableBodyElement = useRef(null)
    const frameElement = useRef(null)
    const headerElement = useRef(null)

    const headers = dataModel.fields
                        .filter(field => !field.hide)
                        .sort((a, b) => a.order - b.order)
                        .map(field => {return {alias: field.alias, column: field.tech_name, type: field.validator_type, 
                                                link: field.link, is_clickable:  field.is_clickable, data_model_id: field.data_model_id}})

    let defaultColumnValues = headers.map(item => 250)
    const savedColumnValues = JSON.parse(localStorage.getItem('savedReportColumnSizes'))
    let columnValues

    if (savedColumnValues && savedColumnValues.length === defaultColumnValues.length) {
        columnValues = savedColumnValues
    } else {
        columnValues = defaultColumnValues
        if (savedColumnValues)
            localStorage.removeItem('savedReportColumnSizes')
    }
    const columnSizes = columnValues.map(item => item ? item + 'px' : '100px').join(' ')

    const columns = createReportHeaders(headers)

    const sortedList = sortRecords(reportData, sortingDirection, sortingColumn, sortingType)

    const mouseDown = (index) => (e) => {
        setActiveColumn(index)
        const offset = e.clientX - columnValues[index]
        setOffsetX(offset)
    }
    
    const mouseMove = useCallback((e) => {
        const minCellWidth = 100

        const gridColumns = headers.map((column, index) => {
            if (index === activeColumn) {
                const width = e.clientX - offsetX
                if (width >= minCellWidth) {
                    return width
                }
            }
            return columnValues[index] ? columnValues[index] : minCellWidth
        })
        
        columnValues = gridColumns
        const gridTemplateColumns = `${gridColumns.map(item => item + 'px').join(" ")}`
        tableHeaderElement.current.style.gridTemplateColumns = gridTemplateColumns
        tableBodyElement.current.childNodes[0].childNodes.forEach(row =>
            row.style.gridTemplateColumns = gridTemplateColumns
        )
    }, [activeColumn, headers, offsetX])
    
    const removeListeners = useCallback(() => {
        window.removeEventListener("mousemove", mouseMove)
        window.removeEventListener("mouseup", mouseUp)
    }, [mouseMove])
    
    const mouseUp = useCallback(() => {
        if (tableBodyElement.current) {
            localStorage.setItem('savedReportColumnSizes', JSON.stringify(columnValues))
        }
        removeListeners()
        setActiveColumn(null)
        setOffsetX(0)
    }, [setActiveColumn, setOffsetX, removeListeners])

    const handleSortClick = (column, type) => {
        if (column === sortingColumn) {
            if (sortingDirection === 'up') {
                setSortingDirection('down')
            } else {
                setSortingDirection('up')
            }
        } else {
            setSortingType(type)
        }
        setSortingColumn(column)
    }

    const processTableScroll = (element) => {
        if (element) {
            headerElement.current.scrollLeft = element.scrollLeft
            setIsMinScrollPosition(frameElement.current.scrollTop === 0)
            setIsVerticalScroll(element.scrollHeight !== element.clientHeight)
        }
    }

    const scrollTableUp = () => {
        if (frameElement) {
            frameElement.current.scrollTop = 0
        }
    }

    useEffect(() => {
        const handleScroll = (e) => {
            if (prevScrollLeft !==  e.target.scrollLeft)
                prevScrollLeft = e.target.scrollLeft
        }
        const element = frameElement.current
        element.addEventListener('scroll', handleScroll)

        return function () {
            element.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        if (activeColumn !== null) {
            window.addEventListener("mousemove", mouseMove)
            window.addEventListener("mouseup", mouseUp)
        }
    
        return () => {
            removeListeners()
        }
    }, [activeColumn, mouseMove, mouseUp, removeListeners])

    return (
        <>
            <div className='tw-w-full tw-flex tw-flex-row tw-h-12'>
                <div className='tw-w-full tw-flex tw-flex-row tw-overflow-hidden' ref={headerElement}>
                    <div 
                        style={{
                            display: 'grid', 
                            gridTemplateColumns: columnSizes,
                            borderBottomWidth: '1px',
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                            flexGrow: '1',
                        }} 
                        ref={tableHeaderElement}
                    >
                        { columns.map(({ref, text, column, type}, index) => {
                            return (
                                <div
                                    key={index}
                                    className='tw-group tw-overflow-hidden tw-truncate tw-text-left tw-text-sm tw-font-semibold tw-px-4 tw-py-1 tw-relative'
                                    ref={ref}
                                >
                                    <span
                                        className={`tw-absolute tw-left-0 tw-inset-y-auto group-hover:tw-opacity-50 ${sortingColumn === column ? 'tw-opacity-50' : 'tw-opacity-0'}`}
                                        onClick={() => handleSortClick(column, type)}
                                    >
                                        { sortingDirection === 'up'
                                            ?   <ChevronUpIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                            :   <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                        }
                                    </span>
                                    <span 
                                        data-tooltip-id="report-table-tooltip"
                                        data-tooltip-content={text}
                                        data-tooltip-delay-show={1000}
                                    >
                                        {text}
                                    </span>
                                    <span 
                                        className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-rounded-md tw-bg-gray-500 tw-opacity-0 tw-w-1 tw-cursor-col-resize group-hover:tw-opacity-30'
                                        onMouseDown={mouseDown(index)}
                                    >
                                    </span>
                                </div>
                            )}
                        )}
                    </div>
                </div>
                { isVerticalScroll &&
                    <button
                        type='button'
                        className='tw-w-4 tw-px-0 tw-py-1 tw-bg-gray-100 hover:tw-bg-gray-300 disabled:tw-text-gray-400 disabled:tw-bg-gray-100'
                        onClick={scrollTableUp}
                        disabled={isMinScrollPosition}
                    >
                        <ChevronDoubleUpIcon className='tw-w-4 tw-h-4' aria-hidden='true'/>
                    </button>
                }
            </div>
            <div 
                className='tw-h-[calc(100%_-_3rem)] tw-overflow-auto'
                ref={frameElement}
                onScroll={(e) => processTableScroll(e.target)}
            >
                <table ref={tableBodyElement} className='tw-w-full'>
                    <tbody>
                        {/* невидимая строка для отображения полосы прокрутки, даже если нет записей */}
                        {sortedList.length === 0 && 
                            <tr 
                                style={{
                                    display: 'grid', 
                                    gridTemplateColumns: columnSizes,
                                    height: '24px',
                                    borderBottomWidth: '1px',
                                    visibility: 'hidden',
                                    pointerEvents: 'none'
                                }} 
                            ></tr>
                        }
                        { sortedList.map((item, index) => {
                            const itemValues = Object.values(item.data)
                            return (
                                <tr 
                                    key={index}
                                    style={{
                                        display: 'grid', 
                                        gridTemplateColumns: columnSizes, 
                                        borderBottomWidth: '1px',
                                        background: 'white',
                                        color: 'black',
                                    }}
                                >
                                    { columns.map(({column, link, is_clickable, data_model_id}, i) => {
                                        const foundValue = itemValues.find(field => field.tech_name === column)
                                        const value = foundValue ? getFieldValue(foundValue) : ''
                                        const isActiveLink = link && value > 0
                                        const searchText = '?id=' + item.id + '&type=' + column + filterString
                                        return (  
                                            <td 
                                                key={i}
                                                className={`tw-text-sm tw-py-3 tw-px-4 tw-overflow-hidden ${(is_clickable && !link) && 'tw-cursor-pointer'}`}
                                                onClick={() => {if (is_clickable && !isActiveLink) onCellClick(foundValue.id, item.record_id, data_model_id)}}
                                            >
                                                { isActiveLink
                                                    ?   <a className='tw-font-semibold tw-text-sky-700' href={link + searchText} target='_blank' rel='noreferrer'>{value}</a>
                                                    :   <span>{value}</span>
                                                }
                                            </td>
                                        )}
                                    )}
                                </tr>
                            )}
                        )}
                    </tbody>
                </table>
            </div>
            <Tooltip id="report-table-tooltip" place="top"/>
        </>
    )
}

export default ReportTable
