import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../..'
import { ClipboardIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react-lite'
import FieldHierarchyHeader from './FieldHierarchyHeader'
import FieldHierarchyTree from './FieldHierarchyTree'
import { Tooltip } from 'react-tooltip'
import { menuTooltipTimeOut } from '../../../../../config/constTypes'

/**
 * Визуальный компонент отображает таблицу доступных полей и соответствующих им тэгов для вставки в шаблон печатной формы
 * 
 * @param {Object} dataModel Активная таблица
 * 
 */
const ExporterFieldHelp = ({dataModel}) => {
    const { FieldStore } = useContext(Context)
    
    const [fieldTags, setFieldTags] = useState([])

    async function copyToClipboard(textToCopy) {
        // Navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textToCopy)
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea")
            textArea.value = textToCopy
                
            // Move textarea out of the viewport so it's not visible
            textArea.style.position = "absolute"
            textArea.style.left = "-999999px"
                
            document.body.prepend(textArea)
            textArea.select()
    
            try {
                document.execCommand('copy')
            } catch (error) {
                console.error(error)
            } finally {
                textArea.remove()
            }
        }
    }

    const handleCopyToClipboard = async (index) => {
        let copyText = document.getElementById('fieldTag' + index).innerHTML
        try {
            await copyToClipboard(copyText)
        } catch (err) {
            console.log(err)
            toast.error('Ошибка копирования в буфер!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 }) 
        }
    }

    const handleHierarchyClick = async (field) => {
        if (!field.hierarchyVisible) {
            const hierarchyFields = await FieldStore.showTreeNode(fieldTags,field)
            setFieldTags(hierarchyFields)

        } else {
            const hierarchyFields = FieldStore.hideTreeNode(fieldTags,field)
            setFieldTags(hierarchyFields)
        }
    }

    const printFieldTag = (field) => {
        return '{{' + field.parent_prefix + FieldStore.getFieldTag(field) + '}}'
    }

    useEffect(() => {
        setFieldTags(dataModel.fields.map(field => (
            {...field,
                hierarchyVisible: false,
                hierarchyLevel: 0,
                parent_rule_id: null,
                parent_prefix: ''
            }
        )))
    }, [dataModel])

    return (
        <div className='tw-w-full'>
            <div className='tw-flex tw-flex-row'>
                <FieldHierarchyHeader
                    treeNodes={fieldTags}
                />
                <div className='tw-grid tw-grid-cols-12 tw-text-sm tw-font-semibold tw-w-full tw-h-8 tw-py-1 tw-border-b'>
                    <div className='tw-col-span-5 tw-pl-6'>Поле</div>
                    <div className='tw-col-span-6'>Тэг</div>
                    <div className='tw-col-span-1'>Буфер</div>
                </div>
            </div>
            <div className='tw-flex tw-flex-row'>
                <FieldHierarchyTree
                    treeNodes={fieldTags}
                    onHierarchyClick={handleHierarchyClick}
                />
                <div className='tw-w-full'>
                { fieldTags.map((field, index) =>
                    <div key={index} className='tw-grid tw-grid-cols-12 tw-text-sm tw-w-full tw-h-6 tw-py-0.5 tw-border-b hover:tw-bg-gray-100'>
                        <div
                            className='tw-col-span-5 tw-pr-4'
                            data-tooltip-id="field-help-tooltip" data-tooltip-content={field.alias} data-tooltip-delay-show={menuTooltipTimeOut}
                        >
                            <p className='tw-pl-6 tw-truncate'>{field.alias}</p>
                        </div>
                        <div
                            className='tw-col-span-6'
                            data-tooltip-id="field-help-tooltip" data-tooltip-content={printFieldTag(field)} data-tooltip-delay-show={menuTooltipTimeOut}
                        >
                            { !['include', 'reference'].includes(field.type) &&
                                <p id={'fieldTag' + index} className='tw-truncate'>{printFieldTag(field)}</p>
                            }
                        </div>
                        <div className='tw-col-span-1'>
                            {!['include', 'reference'].includes(field.type) &&
                                <button
                                    type='button'
                                    className='tw-text-gray-500 hover:tw-text-gray-800 hover:tw-cursor-pointer'
                                    data-tooltip-id="field-help-tooltip" data-tooltip-content="Копировать в буфер обмена" data-tooltip-delay-show={menuTooltipTimeOut}
                                    onClick={() => handleCopyToClipboard(index)}
                                >
                                        <ClipboardIcon className="tw-w-5 tw-h-5" aria-hidden="true"/>
                                </button>
                            }
                        </div>
                    </div>
                )}
                </div>
            </div>
            <Tooltip id="field-help-tooltip" place="top" className='tw-max-w-xl'/>
        </div>
    )
}

export default observer(ExporterFieldHelp)