import React, { useState, useEffect, useContext } from 'react'
import PageTitle from '../../common/panels/PageTitle'
import DocumentService from '../../../../../services/DocumentService'
import ImporterService from '../../../../../services/ImporterService'
import { useNavigate, useParams } from 'react-router-dom'
import { paths } from '../../../../../config/constsURN'
import { toast } from 'react-toastify'
import ImportPreviewTable from '../preview/ImportPreviewTable'
import Spinner from '../../../../../assets/Spinner'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'

/**
 * Компонент реализует логику импорта данных с сервера домена
 * 
 */
const ImportDataViewContainer = () => {
    const { userStore } = useContext(Context)
    const navigate = useNavigate()
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [activeDataModel, setActiveDataModel] = useState(null)
    const { id, domain } = useParams()

    const handleMigrateClick = (domain) => {
        ImporterService
            .migrateData(domain)
            .then(() => {
                toast.success('Миграция в таблицу выполнена успешно!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                navigate(paths.DATAOBJECT_ROUTE)
            })
            .catch(() => {
                toast.error('Ошибка при выполнении миграции в таблицу!', { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
            })
    }

    useEffect(() => {
        setIsLoading(true)
        DocumentService
            .getOneDataModel(id)
            .then(data => setActiveDataModel(data))
            .catch(error => showErrorToast(error, 'fetching', ''))
            .finally(setIsLoading(false))
        ImporterService
            .importViewData(domain)
            .then(data => {
                toast.success('Запрос данных выполнен успешно!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                setData(data)
            })
            .catch(error => {
                showErrorToast(error, 'fetching', '')
                setData([])
            })
    }, [])

    if (isLoading || !data || !activeDataModel) {
        return <Spinner/>
    }

    return (
        <div  className='tw-flex tw-flex-col tw-h-full tw-w-full'>
            <PageTitle title={'Предварительный просмотр данных для импорта'} />
            <div className='tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                <div className='tw-h-full tw-overflow-auto'>
                    { data && 
                        <ImportPreviewTable 
                            dataModel={activeDataModel}
                            importedData={data}
                        />
                    }
                    <div className='tw-w-full tw-py-4 tw-flex tw-flex-row tw-justify-center tw-items-center'>
                        <button 
                            className="tw-inline-flex tw-justify-center tw-border-2 tw-border-gray-700 tw-rounded-md 
                                    tw-bg-gray-700 tw-mx-2 tw-my-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold
                                    tw-text-white tw-shadow-sm hover:tw-bg-gray-500 disabled:tw-bg-gray-300 disabled:tw-border-gray-300"
                            type='button'
                            disabled={!data.length || !userStore.checkPermission("import-template:import")}
                            onClick={() => handleMigrateClick(domain)}
                        >
                            Импортировать
                        </button>
                        <button 
                            className="tw-border-2 tw-border-gray-700 tw-rounded-md 
                                    tw-bg-white tw-mx-2 tw-px-3 tw-my-2 tw-py-1 tw-text-sm tw-font-semibold
                                    tw-text-gray-700 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-200"
                            type='button'
                            onClick={() => navigate(paths.IMPORT_DATA_ROUTE + '/' + id)}
                        >
                            Отменить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(ImportDataViewContainer)