/**
 * Константы для процесса обработки персональных данных (ПДн)
 * @module PersonalDataConsts
 */


/**
 * Справочники по процессу обработки ПДн
 */
export const personalDataDirectories = [
    {id: 'areas_of_activity', entity_name: 'Направления деятельности', records: 0},
    {id: 'handbook_of_yz', entity_name: 'Справочник по УЗ', records: 0},    
]

/**
 * Категории персональных данных для формы направления деятельности
 */
export const personalDataProcessCategories = [
    {id: 0, tech_name: 'is_workers', name: 'Категории субъектов', description: 'Чьи ПДн обрабатываются в рамках данного процесса', mandatory: true, 
    fields: [
        'is_workers', 'is_applicants', 'is_relatives_of_workers', 'is_dismissed_workers', 'is_counterparties', 
        'is_representatives_of_counterparties', 'is_clients', 'is_site_visitors', 'is_beneficiary_on_contracts', 
        'is_subject_of_personal_data_who_has_provided_consent', 'is_learners', 'is_students', 'is_legal_represent_of_incapacitated_subjects'
    ]},
    {id: 1, tech_name: 'is_full_name', name: 'Категории данных', description: 'Какие именно ПДн из списка Вы обрабатываете в рамках процесса', mandatory: true, 
    fields: [
        'is_full_name', 'is_year_of_birth', 'is_month_of_birth', 'is_place_of_birth', 'is_income', 'is_mail', 'is_registration_address', 
        'is_snils', 'is_citizenship', 'is_driver_license', 'is_birth_certificate', 'is_current_account_number', 'is_profession', 
        'is_work_activity', 'is_other_personal_data', 'is_info_about_education', 'is_date_of_birth', 'is_family_status', 'is_property_status', 
        'is_gender', 'is_residence_address', 'is_num_phone', 'is_inn', 'is_identity_document_details', 'is_identity_document_details_outside_rf', 
        'is_bank_card_details', 'is_account_number', 'is_job_title', 'is_info_about_military_registration', 'is_political_views', 
        'is_criminal_record', 'is_nationality', 'is_religious_beliefs', 'is_facial_image_data', 'is_fingerprints', 'is_human_voice_data', 
        'is_other_biometric_personal_data', 'is_pdn_from_open_sources'
    ]},
    {id: 2, tech_name: 'is_collection', name: 'Укажите, какие из следующих действий Вы совершаете с ПДн в рамках данного процесса', description: 'Все из перечисленных действий подпадают под термин "обработка". Если Вы делаете что-то из перечисленного ниже, значит Вы обрабатываете ПДн. В большинстве случаев оператор осуществляет множество из следующих действий', mandatory: true, 
    fields: [
        'is_collection', 'is_systematization', 'is_storage', 'is_extraction', 'is_transference', 'is_blocking', 'is_destruction', 
        'is_other_actions', 'is_recording', 'is_accumulation', 'is_clarification', 'is_usage', 'is_depersonalization', 'is_deletion'
    ]},
    {id: 3, tech_name: 'list_type_of_processing', name: 'Способы обработки', description: '', mandatory: false, 
    fields: [
        'list_type_of_processing', 'list_internal_network', 'list_external_network', 'is_data_transferred_to_foreign_companies', 'is_paper_processing'
    ]},
    {id: 4, tech_name: 'is_obtaining_consent_from_subject', name: 'Основания для обработки', description: 'Какие из следующих оснований для обработки присутствуют в рамках процесса на регулярной или постоянной основе', mandatory: true, 
    fields: [
        'is_obtaining_consent_from_subject', 'is_implementation_of_international_treaty', 
        'is_subject_in_legal_proceedings', 'pdn_carried_out_to_execute_a_court_decision', 'pdn_necessary_to_provide_state_of_municipal_services', 
        'pdn_necessary_for_performance_of_a_contract_to_subject', 'pdn_necessary_to_protect_life_of_subject_when_obtaining_consent', 
        'pdn_necessary_to_exercise_rights_and_legitimate_interests_of_operator', 'pdn_in_media_or_journalistic_activities', 
        'pdn_for_purpose_of_collecting_statistics_without_purpose_promoting_goods_and_services', 
        'pdn_to_improve_quality_of_government_or_municipal_services', 'pdn_according_to_legislation_of_rf_to_mandatory_disclosure'
    ]},
]

export const personalDataISPDnCategories = [
    {id: 0, tech_name: 'is_public_category', name: 'Укажите, какие категории ПДн обрабатываются в ИСПДн', description: '', mandatory: true, 
    fields: [
        'is_public_category', 'is_special_category', 'is_biometric_category', 'is_others_category', 
    ]},
    {id: 1, tech_name: 'is_removable_hard_drivers', name: 'Технологии обработки и элементы информации', description: 'Какие технологии обработки и элементы инфорамции включены/используются в ИСПДн', mandatory: false, 
    fields: [
        'is_removable_hard_drivers', 'is_removable_flash_drivers', 'is_unremovable_hard_drivers', 'is_cd_or_dvd',
        'is_magnetic_tapes', 'is_data_storage_server', 'is_virtual_machines', 'is_wifi', 'is_remote_access_employee', 'is_internet_access'
    ]},
    {id: 2, tech_name: 'is_dlp', name: 'Средства защиты', description: 'Какие средства защиты или их аналоги используются в рамках ИСПДн', mandatory: false, 
    fields: [
        'is_dlp', 'is_siem', 'is_antivirus_tools', 'is_ips_or_ids', 'is_utm_nfgw', 'is_vulnerability_scanner', 'is_backup_system',
        'is_trusted_download_tool', 'is_means_of_access_control', 'is_scud'
    ]},
    {id: 3, tech_name: 'control_measures', name: 'Заполнение Акта ввода ИСПДн в эксплуатацию', description: '', mandatory: false, 
    fields: [
        'control_measures', 'violations_and_disadvantages', 'recommendations'
    ]},
]