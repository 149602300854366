import React, { useContext } from 'react'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'
import { Tooltip } from 'react-tooltip'

/**
 * Визуальный компонент отображает кнопки переключения между этапами категорирования КИИ
 * 
 * @param {Function} onBackClick Обработчик клика мыши для возвращения к предыдущему этапу
 * @param {Function} onForwardClick Обработчик клика мыши для перехода к следующему этапу
 * @param {Boolean} disabled Признак возможности перехода к следующему этапу категорирования 
 * 
 */
const StageButtons = ({onBackClick, onForwardClick, disabled}) => {
    const { categorizingCIIStore } = useContext(Context)

    return (
        <div className='tw-flex tw-justify-between tw-items-center tw-gap-x-4 tw-w-full tw-mx-auto tw-mt-1 tw-px-8 tw-py-2 tw-border-t tw-border-gray-400'>
            { categorizingCIIStore.stage.data['id_of_stage__guide_stages_of_categ'].value > 1
                ?
                    <button
                        className='tw-flex tw-flex-row tw-items-start tw-gap-x-2 
                                tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                                hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                        onClick={onBackClick}
                    >
                        <ChevronDoubleLeftIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                        <span>Назад</span>
                    </button>
                :
                    <div></div>
            }
            { categorizingCIIStore.stage.data['id_of_stage__guide_stages_of_categ'].value < categorizingCIIStore.allStages.length
                ?
                    <div className='tw-flex tw-flex-row tw-items-center tw-gap-x-2'>
                        { disabled &&                    
                            <QuestionMarkCircleIcon 
                                className="tw-h-6 tw-w-6 tw-text-red-400 focus:tw-rounded-full focus:tw-outline-red-400" aria-hidden="true"
                                data-tooltip-id="stage-help-tooltip" data-tooltip-content={categorizingCIIStore.stage.data['hint_for_next_stage__guide_stages_of_categ'].value}
                            />
                        }
                        <button 
                            className='tw-flex tw-flex-row tw-items-start tw-gap-x-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  
                                    tw-bg-gray-700 tw-text-white hover:tw-bg-gray-500 disabled:tw-bg-gray-300  disabled:tw-border-gray-300
                                    focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                    disabled={disabled}
                            onClick={(e) => {e.preventDefault(); onForwardClick(categorizingCIIStore.stage)}}
                        >
                            <span>Далее</span>
                            <ChevronDoubleRightIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                        </button>
                    </div>
                :
                    <div></div>
            }
            <Tooltip id="stage-help-tooltip" place="top-start"/>
        </div>
    )
}

export default observer(StageButtons)