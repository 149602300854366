import React, { useContext } from 'react'
import { Context } from '../../../..'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../../../config/constsURN'

/**
 * Визуальный компонент отображает вкладки на странице Планировщика
 * 
 */
const PlannerTabs = () => {
    const navigate = useNavigate()
    const { plannerStore } = useContext(Context)

    const handleTabSelect = (item) => {
        plannerStore.setSelectedTab(item)
        plannerStore.setSelectedAction(null)
        navigate(paths.PLANNER_ROUTE)
    }

    return (
        <div className='tw-w-full'>
            <RadioGroup value={plannerStore.selectedTab} onChange={handleTabSelect}>
                <div className='tw-rounded-md tw-flex tw-justify-end tw-gap-2'>
                    { plannerStore.tabs.map((tab, index) => 
                        <RadioGroup.Option
                            key={index}
                            value={tab.value}
                            className={({ checked }) =>
                                `${checked ? 'tw-bg-gray-500 tw-text-white' : 'tw-bg-white hover:tw-bg-gray-100 tw-cursor-pointer'}
                                tw-relative tw-flex tw-rounded-md tw-border tw-border-gray-300 tw-select-none
                                tw-px-5 tw-py-2 tw-shadow-md focus:tw-outline-none`
                            }
                        >
                            {({ checked }) => (
                                <>
                                    <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
                                        <div className="tw-flex tw-items-center">
                                            <div className="tw-text-sm">
                                                <RadioGroup.Label
                                                    as="p"
                                                    className={`tw-font-medium  ${
                                                        checked ? 'tw-text-white' : 'tw-text-gray-900'
                                                    }`}
                                                >
                                                    {tab.name}
                                                </RadioGroup.Label>
                                            </div>
                                        </div>
                                        {checked && (
                                            <div className="tw-shrink-0 tw-text-white tw-ml-1">
                                                <CheckIcon className="tw-h-4 tw-w-4" />
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </RadioGroup.Option>
                    )}
                </div>
            </RadioGroup>
        </div>
    )
}

export default observer(PlannerTabs)