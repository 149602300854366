/**
 * Таблица маршрутизации приложения
 * @module Routes
 */
import { paths } from "../../config/constsURN"

import SignInFormContainer from "../sign_page/sign_in/SignInFormContainer"
import MainPageContainer from "../main_page/MainPageContainer"
import AddDataModelContainer from "../main_page/controller/data_model/add/AddDataModelContainer"
import EditDataModelContainer from "../main_page/controller/data_model/edit/EditDataModelContainer"
import DataModelListContainer from "../main_page/controller/data_model/DataModelListContainer"
import ExporterListContainer from "../main_page/controller/exporter/ExporterListContainer"
import DataObjectListContainer from "../main_page/controller/data_object/DataObjectListContainer"
import ImportDataContainer from "../main_page/controller/importer/from_server/ImportDataContainer"
import ImportDataViewContainer from "../main_page/controller/importer/from_server/ImportDataViewContainer"
import ImportPreviewContainer from "../main_page/controller/importer/preview/ImportPreviewContainer"
import ImporterListContainer from "../main_page/controller/importer/ImporterListContainer"
import ErrorPage from "../error_page/ErrorPage"
import AdminContainer from "../admin_page/AdminContainer"
import CategoryListContainer from "../main_page/controller/category/CategoryListContainer"
import CategorizingContainer from "../main_page/controller/categorizing_cii/CategorizingContainer"
import LicenseInfo from "../license/LicenseInfo"
import PersonalDataContainer from "../main_page/controller/personal_data/PersonalDataContainer"
import RegistrationFormContainer from "../sign_page/registration/RegistrationFormContainer"
import ActivateUser from "../sign_page/activate_user/ActivateUser"
import AgreementInfo from "../agreement/AgreementInfo"
import PolicyInfo from "../policy/PolicyInfo"
import EducationContainer from '../main_page/controller/education/EducationContainer'
import ConfigurationUpload from "../configuration_page/upload/ConfigurationUpload"
import ResolutionPage from "../error_page/ResolutionPage"
import PlannerContainer from "../main_page/controller/planner/PlannerContainer"
import CompanyAdminContainer from "../company_admin_page/CompanyAdminContainer"
import ConfigurationUpdate from "../configuration_page/update/ConfigurationUpdate"
import UserRolesContainer from "../user_roles_page/UserRolesContainer"
import ReportPlannerContainer from "../main_page/controller/report/planner/ReportPlannerContainer"
import ReportEducationContainer from "../main_page/controller/report/education/ReportEducationContainer"

/**
 * Список URN, доступных авторизированному пользователю, и соответствующих им страниц (компонентов)
 */
export const authRoutes = [
    {
        path: paths.MAIN_ROUTE,
        Component: MainPageContainer
    },
    {
        path: paths.DATAMODEL_ROUTE,
        Component: DataModelListContainer
    },
    {
        path: paths.DATAMODEL_ROUTE + '/:id',
        Component: DataModelListContainer
    },
    {
        path: paths.ADD_DATAMODEL_ROUTE,
        Component: AddDataModelContainer
    },
    {
        path: paths.EDIT_DATAMODEL_ROUTE + '/:id',
        Component: EditDataModelContainer
    },
    {
        path: paths.DATAOBJECT_ROUTE,
        Component: DataObjectListContainer
    },
    {
        path: paths.DATAOBJECT_ROUTE + '/:id',
        Component: DataObjectListContainer
    },
    {
        path: paths.DATAOBJECT_ROUTE + '/:id/?:filter',
        Component: DataObjectListContainer
    },
    {
        path: paths.EXPORTER_ROUTE,
        Component: ExporterListContainer
    },
    {
        path: paths.EXPORTER_ROUTE + '/:id',
        Component: ExporterListContainer
    },
    {
        path: paths.IMPORTER_ROUTE,
        Component: ImporterListContainer
    },
    {
        path: paths.IMPORTER_ROUTE + '/:id',
        Component: ImporterListContainer
    },
    {
        path: paths.IMPORT_DATA_ROUTE + '/:id',
        Component: ImportDataContainer
    },
    {
        path: paths.IMPORT_VIEW_ROUTE + '/:id/:domain',
        Component: ImportDataViewContainer
    },
    {
        path: paths.IMPORT_PREVIEW_ROUTE + '/:id',
        Component: ImportPreviewContainer
    },
    {
        path: paths.CATEGORY_ROUTE,
        Component: CategoryListContainer
    },
    {
        path: paths.ERROR_ROUTE,
        Component: ErrorPage
    },
    {
        path: paths.CATEGORIZING_CII_ROUTE,
        Component: CategorizingContainer
    },
    {
        path: paths.CATEGORIZING_CII_ROUTE + '/:id',
        Component: CategorizingContainer
    },
    {
        path: paths.CATEGORIZING_CII_ROUTE + '/:id/?:filter',
        Component: CategorizingContainer
    },
    {
        path: paths.PERSONAL_DATA_ROUTE,
        Component: PersonalDataContainer
    },
    {
        path: paths.PERSONAL_DATA_ROUTE + '/:id',
        Component: PersonalDataContainer
    },
    {
        path: paths.PERSONAL_DATA_ROUTE + '/:id/?:filter',
        Component: PersonalDataContainer
    },
    {
        path: paths.EDUCATION_ROUTE,
        Component: EducationContainer
    },
    {
        path: paths.EDUCATION_ROUTE + '/:id',
        Component: EducationContainer
    },
    {
        path: paths.EDUCATION_ROUTE + '/:id/?:filter',
        Component: EducationContainer
    },
    {
        path: paths.CONFIGURATION_UPDATE_ROUTE,
        Component: ConfigurationUpdate
    },
    {
        path: paths.RESOLUTION_ROUTE,
        Component: ResolutionPage
    },
    {
        path: paths.PLANNER_ROUTE,
        Component: PlannerContainer
    },
    {
        path: paths.PLANNER_ROUTE + '/:id',
        Component: PlannerContainer
    },
    {
        path: paths.PLANNER_ROUTE + '/:id/?:filter',
        Component: PlannerContainer
    },
    {
        path: paths.COMPANY_ADMIN_ROUTE,
        Component: CompanyAdminContainer
    },
    {
        path: paths.REPORT_PLANNER_ROUTE + '/:id',
        Component: ReportPlannerContainer
    },
    {
        path: paths.REPORT_EDUCATION_ROUTE + '/:id',
        Component: ReportEducationContainer
    },
]

/**
 * Список URN, доступных неавторизованному пользователю, и соответствующих им страниц (компонентов)
 */
export const publicRoutes = [
    {
        path: paths.LOGIN_ROUTE,
        Component: SignInFormContainer
    },
    {
        path: paths.REGISTRATION_ROUTE,
        Component: RegistrationFormContainer
    },
    {
        path: paths.ACTIVATE_USER_ROUTE,
        Component: ActivateUser
    },
    {
        path: paths.ERROR_ROUTE,
        Component: ErrorPage
    },
    {
        path: paths.MAIN_ROUTE,
        Component: MainPageContainer
    },
    {
        path: paths.LICENSE_ROUTE,
        Component: LicenseInfo
    },
    {
        path: paths.AGREEMENT_ROUTE,
        Component: AgreementInfo
    },
    {
        path: paths.POLICY_ROUTE,
        Component: PolicyInfo
    },
    {
        path: paths.RESOLUTION_ROUTE,
        Component: ResolutionPage
    },
]

/**
 * Список URN, дополнительно доступных авторизированному суперпользователю, и соответствующих им страниц (компонентов)
 */
export const adminRoutes = [
    {
        path: paths.ADMIN_ROUTE,
        Component: AdminContainer
    },
    {
        path: paths.CONFIGURATION_UPLOAD_ROUTE,
        Component: ConfigurationUpload
    },
    {
        path: paths.ROLES_ROUTE,
        Component: UserRolesContainer
    }
]
