export const toastErrorMessages = [
    {   
        type: 'fetching',
        mainText: 'Ошибка при запросе данных!',
        text400: 'Некорректные параметры запроса',
        text401: 'Время действия авторизации истекло',
        text403: 'Доступ запрещен',
        text404: 'Данные не найдены',
        text422: 'Некорректные данные',
        text500: 'Обратитесь к разработчику',
    },
    {   
        type: 'saving',
        mainText: 'Ошибка при сохранении данных!',
        text400: 'Некорректные параметры запроса',
        text401: 'Время действия авторизации истекло',
        text403: 'Доступ запрещен',
        text404: 'Данные не найдены',
        text422: 'Некорректные данные',
        text500: 'Обратитесь к разработчику',
    },
    {   
        type: 'deleting',
        mainText: 'Ошибка при удалении данных!',
        text400: 'Некорректные параметры запроса',
        text401: 'Время действия авторизации истекло',
        text403: 'Доступ запрещен',
        text404: 'Данные не найдены',
        text422: 'Некорректные данные',
        text500: 'Обратитесь к разработчику',
    },
    {   
        type: 'import',
        mainText: 'Ошибка при импорте данных!',
        text400: 'Некорректные параметры запроса',
        text401: 'Время действия авторизации истекло',
        text403: 'Доступ запрещен',
        text404: 'Шаблон импорта не найден',
        text422: 'Некорректный шаблон импорта',
        text500: 'Обратитесь к разработчику',
    },
    {   
        type: 'export',
        mainText: 'Ошибка при экспорте данных!',
        text400: 'Некорректные параметры запроса',
        text401: 'Время действия авторизации истекло',
        text403: 'Доступ запрещен',
        text404: 'Печатная форма не найдена',
        text422: 'Некорректная печатная форма',
        text500: 'Обратитесь к разработчику',
    },
    {   
        type: 'printing',
        mainText: 'Ошибка при скачивании документа!',
        text400: 'Некорректные параметры запроса',
        text401: 'Время действия авторизации истекло',
        text403: 'Доступ запрещен',
        text404: 'Шаблон документа не найден',
        text422: 'Некорректная печатная форма',
        text500: 'Обратитесь к разработчику',
    },
    {   
        type: 'sign_in',
        mainText: 'Ошибка при авторизации!',
        text400: 'Некорректные параметры запроса',
        text401: 'Неправильный логин или пароль',
        text403: 'Доступ запрещен: аккаунт не активирован',
        text404: 'Пользователь не найден',
        text422: 'Некорректные параметры запроса',
        text500: 'Обратитесь к разработчику',
    },
    {   
        type: 'default',
        mainText: '',
        text400: '',
        text401: 'Время действия авторизации истекло',
        text403: '',
        text404: '',
        text422: '',
        text500: '',
    },
]