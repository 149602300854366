import { checkNestedValueErrors } from "./checkNestedValueErrors"

export function checkNestedTables(nestedModels, fields, isCheckEmpty, setError, errorMessage) {
    return nestedModels.map(nestedModel => {
        let sumError = false
        const foundField = fields?.find(field => field.type === 'include' && field.ref_model_ids.includes(nestedModel.id))
        if (foundField && !foundField.hidden) {
            const primaryField = nestedModel.fields.find(field => field.order === 0)
            if (primaryField) {
                    // проверка прочих вложенных таблиц на наличие повторяющихся значений первичного поля и пустых значений ячеек
                    const [isError, checkedNestedModel] = checkNestedValueErrors(nestedModel.dataObjects, primaryField.tech_name, isCheckEmpty)
                    if (isError) {
                        setError(`data.${nestedModel.tech_name}`, { type: 'invalid', message: errorMessage })
                        sumError = true
                    }
                    return {...nestedModel, dataObjects: checkedNestedModel, errors: sumError}
            }
        }

        return {...nestedModel, errors: sumError }
    })    
}
