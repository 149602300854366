import React from 'react'
import { getFieldValue } from '../../../../config/constTypes'

/**
 * Компонент отображает информацию о запланированном действии Планировщика в панели на стартовой странице
 * 
 * @param {Object} item Запланированное действие
 * 
 */
const CalendarItem = ({item}) => {
    return (
        <div
            className={`tw-text-gray-900 tw-bg-white hover:tw-bg-gray-100
                    tw-w-full tw-px-2 tw-py-1 tw-mb-2 tw-rounded-md tw-shadow-md tw-border-2
                    tw-flex tw-justify-between tw-items-center tw-gap-x-2 tw-text-sm
                    tw-border-gray-400`}
        >
            <div className='tw-block'>
                <p className={`${item.data['action_is_done'].value
                                ?   'tw-text-teal-500'
                                :   item.data['action_completion_date'].value < Date.now()
                                        ?   'tw-text-red-500'
                                        :   ''}`}>
                    {getFieldValue(item.data['action_completion_date']).slice(0,-3)}
                </p>
                <p className={`tw-font-semibold ${item.data['action_is_done'].value
                                ?   'tw-text-teal-500'
                                :   item.data['action_completion_date'].value < Date.now()
                                        ?   'tw-text-red-500'
                                        :   ''}`}>
                    {getFieldValue(item.data['my_action_reference'])}
                </p>
                <p className={`${item.data['action_is_done'].value
                                ?   'tw-text-teal-500'
                                :   item.data['action_completion_date'].value < Date.now()
                                        ?   'tw-text-red-500'
                                        :   ''}
                            tw-text-xs tw-italic`}>
                    Назначил: {item.author.first_name} {item.author.last_name} {item.author.email}
                </p>
            </div>
        </div>
    )
}

export default CalendarItem 