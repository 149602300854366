import React from 'react'
import { BarsArrowDownIcon } from '@heroicons/react/20/solid'

function getMaxVisibleLevel(treeNodes) {
    let maxLevel = 0
    treeNodes.forEach(item => {
        if (item.hierarchyLevel > maxLevel)
            maxLevel = item.hierarchyLevel
    })

    return maxLevel
}

/**
 * Визуальный компонент отображает заголовок дерева управления отображением иерархических записей выбранной таблицы.
 * 
 * @param {Object} dataModel Активная таблица
 * @param {Object[]} treeNodes Массив отображаемых записей активной таблицы
 * 
 */
const DirectoryHierarchyHeader = ({dataModel, treeNodes}) => {
    if (!dataModel.allow_hierarchy)
        return null

    const hierarchyWidth = (getMaxVisibleLevel(treeNodes) * 20 + 40) + 'px'

    return (
        <div
            style={{
                minWidth: hierarchyWidth,
                width: hierarchyWidth,
                paddingLeft: '10px',
                paddingTop: '4px',
                paddingBottom: '4px',
                borderBottomWidth: '1px',
                borderRightWidth: '1px'
            }}
        >
            <BarsArrowDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
        </div>
    )
}

export default DirectoryHierarchyHeader