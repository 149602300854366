import React from 'react'
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

function getMaxVisibleLevel(treeNodes) {
    let maxLevel = 0
    treeNodes.forEach(item => {
        if (item.hierarchyLevel > maxLevel)
            maxLevel = item.hierarchyLevel
    })

    return maxLevel
}

/**
 * Визуальный компонент отображает дерева управления отображением иерархических записей полей.
 * По умолчанию отображаются поля верхнего уровня
 * 
 * @param {Object[]} treeNodes Массив отображаемых полей
 * @param {Function} onHierarchyClick Обработчик клика мыши на элементе раскрытия/закрытия родительской записи
 * 
 */
const FieldHierarchyTree = ({treeNodes, onHierarchyClick}) => {
    return (
        <div className='tw-flex-tw-flex-col'>
            <div className='tw-overflow-hidden'>
                { treeNodes.map((node, index) => {
                    return  <div 
                                key={index}
                                style={{
                                    width: (getMaxVisibleLevel(treeNodes) * 20 + 40) + 'px',
                                    paddingLeft: (node.hierarchyLevel * 20 + 10) + 'px'
                                }}
                                className='tw-h-6 tw-pr-2 tw-py-1 tw-border-b tw-border-r'
                            >
                                { ['include', 'reference'].includes(node.type)
                                    ?   <div className='tw-cursor-pointer' onClick={() => onHierarchyClick(node)}>
                                            { node.hierarchyVisible
                                                ?   <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                                :   <ChevronRightIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                            }
                                        </div>
                                    :   <div className="tw-ml-1.5 tw-mt-1.5 tw-h-1.5 tw-w-1.5 tw-rounded-full tw-bg-gray-600"/>
                                    
                                }
                            </div>
                })}
            </div>
        </div>
    )
}

export default FieldHierarchyTree