import React, { useContext, useEffect, useState } from 'react'
import { ArrowLeftOnRectangleIcon, DocumentPlusIcon, PencilSquareIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { observer } from 'mobx-react-lite'
import { Context } from '../../..'

/**
 * Визуальный компонент отображает меню возможных действий над пользователями
 * 
 */
const CompanyUserMenu = () => {
    const { AdminStore } = useContext(Context)
    const [userAssigned, setUserAssigned] = useState(false)

    const onEditItemClick = () => {
        AdminStore.setIsUserEditing(true) 
    }

    const onAddItemClick = () => {
        AdminStore.setIsUserCreating(true)
        AdminStore.setSelectedUser(null)
    }

    const bindUser = () => {
        if (!AdminStore.selectedUser.company_id || AdminStore.selectedUser.company_id === AdminStore.selectedCompany.id) {
            AdminStore.rebindUsers({users: {add: [AdminStore.selectedUser.id]}})

            if (!AdminStore.selectedUser.company_id) {
                AdminStore.editUser({company_id: AdminStore.selectedCompany.id}, AdminStore.selectedUser.id)
            }
        } else {
            AdminStore.rebindUserToCompany(AdminStore.selectedUser.id)
        }
    }

    const unBindUser = () => {
        AdminStore.rebindUsers({users: {remove: [AdminStore.selectedUser.id]}})
    }

    useEffect(()=>{
        if(AdminStore.selectedUser && AdminStore.selectedSubCompany){
            if (AdminStore.selectedUser.allowed_sub_company_ids.find(item => item === AdminStore.selectedSubCompany.id)) {
                setUserAssigned(true)
            } else {
                setUserAssigned(false)
            }
        }
    }, [AdminStore.selectedUser, AdminStore.selectedSubCompany])

    return (
        <div id='company-user-menu' 
            className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center 
                        tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'
        >
            <p className='tw-text-xs tw-font-semibold tw-w-24 md:tw-text-base sm:tw-w-96'>Пользователи</p>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={(e) => {
                        e.preventDefault()
                        if(userAssigned)
                            unBindUser()
                        else 
                            bindUser()
                    }}
                    disabled={!AdminStore.selectedSubCompany || !AdminStore.selectedUser || AdminStore.isSubCompanyCreating || AdminStore.isUserCreating || AdminStore.isUserEditing}
                    data-tooltip-id="company-menu-tooltip" data-tooltip-content={userAssigned ? 'Отвязать' : 'Привязать'}
                >
                    {userAssigned 
                        ? <ArrowRightOnRectangleIcon  className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        : <ArrowLeftOnRectangleIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    }
                </button>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onAddItemClick}
                    disabled={AdminStore.isUserCreating || AdminStore.isUserEditing || !AdminStore.selectedSubCompany || !AdminStore.selectedCompany}
                    data-tooltip-id="company-menu-tooltip" data-tooltip-content="Создать пользователя" data-tooltip-delay-show={1000}
                >
                    <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onEditItemClick(AdminStore.selectedUser.id)}
                    disabled={!AdminStore.selectedUser || AdminStore.isUserCreating || AdminStore.isUserEditing}
                    data-tooltip-id="company-menu-tooltip" data-tooltip-content="Редактировать пользователя" data-tooltip-delay-show={1000}
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
            </div>
            <Tooltip id="company-menu-tooltip" place="top"/>
        </div>
    )
}

export default observer(CompanyUserMenu)