import React from 'react'

/**
 * Визуальный компонент отображает справочную информацию, содержащую ссылку на видеоинструкции по работе с МЕДОЕД, 
 * а также адреса службы поддержки
 *
 */
const Instructions = () => {
    return (
        <div className={`tw-flex tw-flex-col tw-w-full sm:tw-h-60 tw-rounded-md tw-bg-white`}>
            <div className={`tw-text-sm tw-text-left tw-font-semibold tw-text-gray-800 tw-w-full tw-pl-4 tw-py-1 tw-rounded-t-md  tw-border-gray-400 tw-bg-gray-50 tw-border-b-2`}>
                Видеоинструкции
            </div>
            <div className='tw-text-sm tw-text-left tw-px-4 tw-py-2 tw-overflow-auto'>
                <span>В этом разделе Вы сможете найти видеоинструкции, которые помогут Вам в использовании MEDOED. </span>
                <br/><br/>
                <a
                    className={`tw-text-sm tw-font-semibold tw-text-gray-900 tw-px-2 tw-py-0.5 tw-rounded-md tw-border-2 tw-border-gray-400 tw-bg-gray-100 hover:tw-bg-gray-200`}
                    href='https://medoed-soft.ru/instrukciya/' target='_blank' rel='noreferrer'
                >
                    Смотреть
                </a>
                <br/><br/>
                <span>Если у Вас возникнут какие-либо вопросы, напишите нам на                 
                <a
                    className='tw-font-semibold tw-text-sky-700'
                    href="mailto:support.medoed@rtmtech.ru?subject=Вопрос по платформе MEDOED"
                > support.medoed@rtmtech.ru
                </a>
                </span>
                <span> или в группу поддержки в Telegram
                <a
                    className='tw-font-semibold tw-text-sky-700'
                    href="https://t.me/+zDCygPNj9OVlYjA6"
                    target="_blank" rel='noreferrer'
                > MEDOED SUPPORT
                </a></span>
            </div>
        </div>
    )
}

export default Instructions
