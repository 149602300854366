 //Функция формирует запрос на сортировку, основываясь на имени и типе поля, по которому выполняется сортировка выбранной таблицы
export function setSorting(dataModel, field, direction) {
    if (['created', 'last_modified', 'subgroup_id'].includes(field.column_tech_name))
        return [{property: field.column_tech_name, desc: direction !== 'down'}]

    if (['modifier_id'].includes(field.column_tech_name))
        return [{property: 'modifier.email', desc: direction !== 'down'}]

    if (['files'].includes(field.column_tech_name))
        return [{property: 'system_data.' + field.column_tech_name, desc: direction !== 'down'}]

    if (field.column_validator_type === "one") {
        let sort = []
        const referencedModels = dataModel.referenced_models.filter(item => item.rule_id ===  field.column_rule_id)
        referencedModels.forEach(model => {
            model.format_fields.forEach(formatField => {
                sort.push({property: 'data["' + field.column_rule_id + '"].presentations[*].data.' + formatField, desc: direction !== 'down'})
            })
        })
    
        return sort
    }

    return [{property: `data['${field.column_tech_name}'].value`, desc: direction !== 'down'}]
}