import React, { useContext, useRef } from 'react'
import { Context } from '../../../../../..'
import DataObjectInfo from '../../../data_object/info/DataObjectInfo'
import DirectoryFieldsList from './DirectoryFieldsList'
import DataObjectTabs from '../../../../../tabs/DataObjectTabs'
import { observer } from 'mobx-react-lite'
import PanelHorizontalDivider from '../../dividers/PanelHorizontalDivider'
import { Tooltip } from 'react-tooltip'

/**
 * Визуальный компонент отображает список записей выбранной таблицы, а так же файлы, сообщения и вложенные таблицы выбранной записи 
 * 
 * @param {Function} onItemClick Обработчик клика мыши на элементе списка
 * @param {Function} onItemDoubleClick Обработчик двойного клика мыши на элементе списка
 * @param {Object} selectedDataObject Выбранная запись таблицы
 * @param {Object} selectedFullDataModel Выбранная таблица
 * @param {Object[]} dataObjects Массив записей таблицы
 * @param {Object} selectedNestedDataModel Выбранная вложенная таблица
 * @param {Object[]} nestedDataModels Массив вложенных таблиц
 * @param {Function} setSelectedNestedDataModel Изменение выбранной вложенной таблицы
 * @param {Function} setNestedDataModels Изменение в массиве вложенных таблицы
 * @param {Function} onSortClick Обработчик клика мыши на элементе изменения сортировки списка
 * @param {Function} onFetchData Обработчик события достижения конца списка
 * @param {Object[]} chosenDataObjectsList Массив выбранных записей
 * @param {Boolean} isNestedLoading Признак загрузки вложенных таблиц
 * @param {Object} filterStore Экземпляр хранилища информации о применяемых фильтрах
 * @param {Object} recordNotesStore Экземпляр хранилища информации о сообщениях
 * @param {Boolean} isChosenObjectDuplicationForbidden Признак запрета повторного добавления записи
 * @param {Function} onHierarchyClick Обработчик клика мыши на элементе раскрытия/закрытия родительской записи
 * 
 */
const DirectoryPanel = ({onItemClick, onItemDoubleClick, selectedDataObject, selectedFullDataModel, dataObjects, selectedNestedDataModel,
        nestedDataModels, setSelectedNestedDataModel, setNestedDataModels, onSortClick, onFetchData, chosenDataObjectsList, isNestedLoading, 
        filterStore, recordNotesStore, isChosenObjectDuplicationForbidden, onHierarchyClick}) => {

    const {docStore} = useContext(Context)

    const defaultGridHeight = 'calc(100% - 14rem)'
    const defaultTabsHeight = '10rem'

    const savedGridHeight = sessionStorage.getItem('savedGridHeight')
    const savedTabsHeight = sessionStorage.getItem('savedTabsHeight')  
    const gridHeight = savedGridHeight || defaultGridHeight
    const tabsHeight = savedTabsHeight || defaultTabsHeight

    const gridElement = useRef(null)
    const tabsElement = useRef(null)

    return (
        <>
            <div 
                style={{
                    height: gridHeight,
                    backgroundColor: 'white',
                    borderBottomLeftRadius: '0.375rem',
                    borderBottomRightRadius: '0.375rem',
                    overflow: 'hidden',
                }}
                ref={gridElement}
            >
                { dataObjects && selectedFullDataModel && selectedFullDataModel.fields &&
                    ( docStore.isDetailView 
                        ?   
                            <DataObjectInfo
                                selectedDataObject={selectedDataObject}
                            />
                        :
                            <DirectoryFieldsList
                                dataModel={selectedFullDataModel}
                                selectedDataObject={selectedDataObject}
                                dataObjects={dataObjects}
                                onItemClick={onItemClick}
                                onItemDoubleClick={onItemDoubleClick}
                                onSortClick={onSortClick}
                                onFetchData={onFetchData}
                                chosenDataObjectsList={chosenDataObjectsList}
                                filterStore={filterStore}
                                isChosenObjectDuplicationForbidden={isChosenObjectDuplicationForbidden}
                                onHierarchyClick={onHierarchyClick}
                            />
                    )
                }
            </div>
            <PanelHorizontalDivider 
                topElement={gridElement}
                bottomElement={tabsElement}
            />
            <div
                id='data-files-column'
                style={{
                    height: tabsHeight,
                    overflow: 'hidden',
                }}
                ref={tabsElement}
            >
                <DataObjectTabs
                    files={docStore.attachedFiles}
                    readOnly={true}
                    onFileSelect={null}
                    onFileRemove={null}
                    setSelectedNestedDataModel={setSelectedNestedDataModel}
                    nestedDataModels={nestedDataModels}
                    selectedNestedDataModel={selectedNestedDataModel}
                    setNestedDataModels={setNestedDataModels}
                    isDocumentPage={true}
                    isNestedLoading={isNestedLoading}
                    panel={null}
                    panelID='directory-tabs'
                    recordNotesStore={recordNotesStore}
                />
            </div>
            <Tooltip id="directory-tabs-tooltip" className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
        </>
    )
}

export default observer(DirectoryPanel)