import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../../../..'
import { useForm, Controller } from 'react-hook-form'
import FormFieldName from '../../../../../../form_fields/FormFieldName'
import DataTypeListBox from '../../../../common/inputs/DataTypeListBox'
import ReactDatePicker from 'react-datepicker'
import DataSwitch from '../../../../common/inputs/DataSwitch'
import SaveIcon from '../../../../../../../assets/SaveIcon'
import { XMarkIcon, CheckBadgeIcon, LinkIcon } from '@heroicons/react/20/solid'
import { observer } from 'mobx-react-lite'
import { getFieldValue, getFormattedDate, serviceMessageTimeOut, successMessageTimeOut } from '../../../../../../../config/constTypes'
import { menuTooltipTimeOut } from '../../../../../../../config/constTypes'
import { copyToClipboard } from '../../../../../../../functions/copyToClipboard'
import { paths } from '../../../../../../../config/constsURN'
import { toast } from 'react-toastify'
import { useHotkeys } from 'react-hotkeys-hook'

/**
 * Визуальный компонент отображает форму создания и редактирования запланированного действия планировщика
 * 
 * @param {Boolean} isLoading Признак загрузки/сохранения данных формы
 * @param {Function} onSubmitClick Обработчик клика мыши на кнопке сохранения данных при редактировании запланированного действия
 * @param {Function} onMarkActionClick Обработчик клика мыши на кнопке отметки о завершении действия
 * 
 */
const ActionForm = ({isLoading, onSubmitClick, onMarkClick}) => {
    const {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm()

    const { plannerStore } = useContext(Context)
    const [actions, setActions] = useState([])

    const keyRef = useHotkeys('ctrl+s', () => {if (plannerStore.isActionFormOpen && !isLoading) handleSubmit(onSubmitClick)()},
        { preventDefault: true, enableOnFormTags: true }
    )
    useHotkeys('ctrl+m', () => {if (plannerStore.isActionFormOpen && plannerStore.editMode === 'edit') onMarkClick()},
        { preventDefault: true, enableOnFormTags: true }
    )
    useHotkeys('ctrl+l', () => {if (plannerStore.isActionFormOpen && plannerStore.editMode === 'edit') onCopyLinkClick()},
        { preventDefault: true, enableOnFormTags: true }
    )

    const editMode = plannerStore.editMode === 'edit'

    register('data.actionRecordID', { 
        required: false, 
        value: editMode
            ?   plannerStore.selectedAction && plannerStore.selectedAction.data['my_action_reference'].value.values.length
                    ?   plannerStore.selectedAction.data['my_action_reference'].value.values[0].record_id
                    :   null
            :   plannerStore.selectedAction
                    ?   plannerStore.selectedAction.record_id
                    :   null
    })

    const watchIsPeriodic = watch('data.isPeriodic', plannerStore.editMode === 'plan')
    const watchStartDate = watch('data.startDate', null)
    const watchEndDate = watch('data.endDate', (d => d.setFullYear(d.getFullYear() + 2))(new Date()))
    const watchPeriodicAction = watch('data.action_periodicity', editMode || !plannerStore.selectedAction ? 30 : plannerStore.selectedAction.data['action_periodicity'].value)

    const onCopyLinkClick = () => {
        try {
            copyToClipboard(`${paths.PLANNER_ROUTE}/?record_id=${plannerStore.selectedAction.record_id}`)
            toast.info('Ссылка скопирована в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
        } catch (error) {
            toast.error('Не удалось скопировать ссылку в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }
    }

    useEffect(() => {
        if (watchIsPeriodic && watchStartDate && watchEndDate) {
            let dataObjects = []
            let actionStartDate = new Date(watchStartDate.valueOf())

            do {
                dataObjects.push(
                    { 
                        data_model_id: 'planned_actions',
                        system_data: { deletion_mark: false },
                        data: { 'action_completion_date': new Date(actionStartDate.valueOf()) }
                    }
                )
                actionStartDate.setDate(actionStartDate.getDate() + watchPeriodicAction)
            } while (actionStartDate < watchEndDate)

            setActions(dataObjects)
        } 
    }, [watchIsPeriodic, watchPeriodicAction, watchStartDate, watchEndDate])

    return (
        <form ref={keyRef} tabIndex={-1} className='tw-flex tw-flex-col tw-h-full tw-rounded-md tw-bg-white'>
            <div className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Действие</p>
                <div className='tw-flex tw-flex-row tw-gap-x-1'>
                    <button
                        className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                        onClick={handleSubmit(onSubmitClick)}
                        disabled={isLoading}
                        data-tooltip-id="action-form-tooltip"
                        data-tooltip-html="Сохранить &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+S)"
                        data-tooltip-delay-show={menuTooltipTimeOut}
                    >
                        <SaveIcon/>
                    </button>
                    { editMode &&
                        <>
                            <button
                                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-300'
                                onClick={onMarkClick}
                                type='button'
                                disabled={plannerStore.selectedAction.data['action_is_done'].value}
                                data-tooltip-id="action-form-tooltip"
                                data-tooltip-content="Отметить как выполненное &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+M)"
                                data-tooltip-delay-show={menuTooltipTimeOut}
                            >
                                <CheckBadgeIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            </button>
                            <button
                                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-300'
                                onClick={onCopyLinkClick}
                                type='button'
                                data-tooltip-id="action-form-tooltip"
                                data-tooltip-html="Копировать ссылку в буфер обмена &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+L)"
                                data-tooltip-delay-show={menuTooltipTimeOut}
                            >
                                <LinkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            </button>
                        </>
                    }
                </div>
            </div>
            <div className='tw-grow tw-overflow-auto'>
                { (!editMode || plannerStore.editedMyAction) &&
                    <>
                        <div className="tw-px-4 tw-pt-2 tw-pb-3 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                                <FormFieldName  
                                    item={{alias: 'Наименование'}}
                                    errors={errors}
                                />
                            </dt>
                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                <textarea
                                    className={`tw-w-full tw-min-h-8 tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 sm:tw-text-sm 
                                        focus-visible:tw-outline-none tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400
                                    `}
                                    {...register('data.name', { 
                                        required: true,
                                        value: editMode
                                                ?   getFieldValue(plannerStore.selectedAction.data['my_action_reference'])
                                                :   plannerStore.selectedAction
                                                        ?   plannerStore.selectedAction.data['name'].value
                                                        :   ''
                                    })}
                                />
                            </dd>
                        </div>
                        <div className="tw-mb-1 tw-px-4 tw-pt-2 tw-pb-3 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-border-b tw-border-gray-300">
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                                <FormFieldName  
                                    item={{alias: 'Описание'}}
                                    errors={errors}
                                />
                            </dt>
                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                <textarea
                                    className={`tw-w-full tw-min-h-8 tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 sm:tw-text-sm 
                                        focus-visible:tw-outline-none tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400
                                    `}
                                    {...register('data.action_description', { 
                                        required: false,
                                        value: plannerStore.selectedAction
                                                ?   plannerStore.selectedAction.data['action_description'].value
                                                :   ''
                                    })}
                                />
                            </dd>
                        </div>
                    </>
                }
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                        <FormFieldName  
                            item={{alias: 'Ответственный', tech_name: 'responsibleUser'}}
                            errors={errors}
                        />
                    </dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                        <Controller
                            name={'data.responsibleUser'}
                            control={control}
                            rules={ {required: true, validate: value => value.id !== 0} }
                            defaultValue={editMode ?  plannerStore.getResponsibleUser() : {id: 0, name: '', email: ''}}
                            shouldUnregister={true}
                            render={({field}) =>
                                <DataTypeListBox
                                    itemList={plannerStore.companyUsers}
                                    selectedItem={field.value}
                                    onItemChange={(e) => field.onChange(e)}
                                    error={errors && errors.data && errors.data.responsibleUser}
                                    selector='id'
                                />}
                        />
                    </dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                        <FormFieldName  
                            item={{alias: 'Дата и время начала', tech_name: 'startDate'}}
                            errors={errors}
                        />
                    </dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                        <div className='tw-flex tw-flex-row tw-justify-start tw-items-center tw-w-52 tw-py-1'>
                            <Controller
                                name={'data.startDate'}
                                control={control}
                                shouldUnregister={true}
                                rules={ {required: true, valueAsDate: true} }
                                defaultValue={ editMode ?  plannerStore.selectedAction.data['action_completion_date'].value : plannerStore.calendarDate}
                                render={({field}) =>
                                    <ReactDatePicker
                                        className={`tw-w-52 tw-border tw-rounded-md tw-text-sm tw-text-gray-900 tw-px-2 tw-py-1
                                            ${errors && errors.data && errors.data['startDate'] ? 'tw-border-red-400' : 'tw-border-gray-400'}
                                            focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white 
                                            focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-offset-gray-400
                                        `}
                                        calendarStartDay={1}
                                        dateFormat='dd.MM.yyyy HH:mm:ss'
                                        timeFormat='HH:mm:ss'
                                        locale='ru'
                                        timeCaption='Время'
                                        showTimeSelect={true}
                                        selected={field.value}
                                        onChange={(e) => field.onChange(new Date(e).getTime())}
                                    />
                                }
                            />
                            <button 
                                type='button'
                                className='tw-rounded-md tw-p-0.5 tw-ml-1 tw-border-0 tw-text-gray-600 tw-bg-white hover:tw-text-gray-900 hover:tw-border-gray-200 hover:tw-bg-gray-200'
                                onClick={() => {setValue('data.startDate', null)}}
                            >
                                <XMarkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            </button>
                        </div>
                    </dd>
                </div>
                { !editMode &&
                    <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                            <FormFieldName  
                                item={{alias: 'Повторяющееся действие'}}
                                errors={errors}
                            />
                        </dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                            <Controller
                                name={'data.isPeriodic'}
                                control={control}
                                defaultValue={plannerStore.editMode === 'plan'}
                                render={({field}) =>
                                    <DataSwitch 
                                        isChecked={field.value}
                                        onClick={(e) => field.onChange(e)}
                                    />
                                }
                            />
                        </dd>
                    </div>
                }
                { watchIsPeriodic &&
                    <>
                        <div className="tw-px-4 tw-py-2 tw-pb-3 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                                <FormFieldName  
                                    item={{alias: 'Периодичность'}}
                                    errors={errors}
                                />
                            </dt>
                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                <input
                                    type='number'
                                    min={1}
                                    defaultValue={editMode
                                                    ?   getFieldValue(plannerStore.selectedAction.data['my_action_reference'])
                                                    :   plannerStore.selectedAction
                                                            ?   plannerStore.selectedAction.data['action_periodicity'].value
                                                            :   30
                                    }
                                    className={`tw-w-24 tw-h-8 tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                                        tw-ring-gray-400 focus-visible:tw-ring-gray-400
                                    `}
                                    {...register('data.action_periodicity', {
                                        required: false,
                                        shouldUnregister: true,
                                        valueAsNumber: true,
                                        validate: v => Number.isInteger(v),
                                    })}
                                />
                            </dd>
                        </div>
                        <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-border-b tw-border-gray-300">
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                                <FormFieldName  
                                    item={{alias: 'Повторить до'}}
                                    errors={errors}
                                />
                            </dt>
                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                <div className='tw-flex tw-flex-row tw-justify-start tw-items-center tw-w-52 tw-py-1'>
                                    <Controller
                                        name={'data.endDate'}
                                        control={control}
                                        shouldUnregister={true}
                                        rules={ { valueAsDate: true} }
                                        defaultValue={(d => d.setFullYear(d.getFullYear() + 2))(new Date())}
                                        render={({field}) =>
                                            <ReactDatePicker
                                                className={`tw-w-52 tw-border tw-rounded-md tw-text-sm tw-text-gray-900 tw-px-2 tw-py-1
                                                    focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white 
                                                    focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2 tw-border-gray-400 focus-visible:tw-ring-offset-gray-400
                                                `}
                                                calendarStartDay={1}
                                                dateFormat='dd.MM.yyyy'
                                                timeFormat='HH:mm:ss'
                                                locale='ru'
                                                timeCaption='Время'
                                                showTimeSelect={false}
                                                selected={field.value}
                                                onChange={(e) => field.onChange(new Date(e).getTime())}
                                            />
                                        }
                                    />
                                    <button 
                                        type='button'
                                        className='tw-rounded-md tw-p-0.5 tw-ml-1 tw-border-0 tw-text-gray-600 tw-bg-white hover:tw-text-gray-900 hover:tw-border-gray-200 hover:tw-bg-gray-200'
                                        onClick={() => {setValue('data.endDate', null)}}
                                    >
                                        <XMarkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                    </button>
                                </div>
                            </dd>
                        </div>
                        <div className="tw-mt-1 tw-px-4 tw-py-2 tw-pb-3 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-mt-2 tw-flex tw-flex-row tw-items-start tw-gap-x-4">
                                <FormFieldName  
                                    item={{alias: 'Все планируемые действия'}}
                                    errors={errors}
                                />
                            </dt>
                            <dd className="tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                { actions.map((item, index) => (
                                    <div key={index} className='tw-h-8 tw-w-full tw-py-1.5 tw-mt-1 tw-rounded-md tw-text-gray-900 tw-select-none'>
                                        { getFormattedDate(item.data['action_completion_date'], 'dd.MM.yyyy HH:mm') + ', ' + (new Intl.DateTimeFormat("ru-RU", {weekday: "long"}).format(item.data['action_completion_date']))} 
                                    </div>
                                ))}
                            </dd>
                        </div>
                    </>
                }
            </div>
        </form>
    )
}

export default observer(ActionForm)