import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../../..'
import parse from 'html-react-parser'
import { toast } from 'react-toastify'
import ObjectListPanel from '../../../common/panels/object_list/ObjectListPanel'
import PDataStageButtons from '../../stage/PDataStageButtons'
import Spinner from '../../../../../../assets/Spinner'
import PData3PartyForm from './PData3PartyForm'
import DocumentService from '../../../../../../services/DocumentService'
import DialogTab from '../../../../../dialog_tab/DialogTab'

/**
 * Визуальный компонент отображает этап заполнения списка третьих лиц-обработчиков
 * 
 */
const PData3PartyListContainer = () => {
    const { personalDataStore, DialogTabStore } = useContext(Context)
    const [objectsList, setObjectsList] = useState([]) 
    const [objectsListStage, setObjectsListStage] = useState({}) 
    const [lastObjectSaved, setLastObjectSaved] = useState(false) 
    const [stageListWasHidden, setStageListWasHidden] = useState(false)
    const [deletingItem, setDeletingItem] = useState(null)

    const handleBackClick = () => {
        personalDataStore.goPrevStage()
        !stageListWasHidden && personalDataStore.setHideStageList(false)
    }

    const handleForwardClick = async () => {
        personalDataStore.goNextStage()
        !stageListWasHidden && personalDataStore.setHideStageList(false)
    }

    const ObjectsListButtonsBackClick = () =>{
        setObjectsListStage(objectsList[objectsListStage.value - 1])
    }
    
    const ObjectsListButtonsForwardClick = () =>{
        setObjectsListStage(objectsList[objectsListStage.value + 1])
    }

    const markObjectAsFinished = (finished) =>{
        setObjectsList(objectsList.map(object => 
            object.value === objectsListStage.value
                ?   {...object, status: finished ? 'finished' : 'empty'}
                :   object
        ))
    }

    const handleAddObjectClick = () => {
        let obj = [{
            name: 'Новый обработчик ' + (objectsList.length + 1),
            object_record_id: '0',
            record_id: Date.now() + '_' + objectsList.length,
            object_status: 'added',
            value: 0,
            status: 'empty',
            hidden: false,
            help: 'Заполните информацию о стороннем обработчике'
        }],
        objList = obj.concat(objectsList)
        setObjectsList(objList.map((item, index) => {return {...item, value: index}}))
        setObjectsListStage(objList[0])
        setLastObjectSaved(false)
    }

    const handleAskForDeleteClick = (item) => {
        let isDeleteAllowed = true

        if (!lastObjectSaved) {
            if ( item.status === 'finished') {
                isDeleteAllowed = false
                toast.error(`Сохраните лицо-обработчик "${objectsList[0].name}"`, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
            }            
        }

        if (isDeleteAllowed) {
            setDeletingItem(item)
            DialogTabStore.setParentName('PData3PartyListContainer')
            DialogTabStore.setDialogTabTitle('Удаление стороннего обработчика') 
            DialogTabStore.setDialogTabText(parse(`Вы уверены, что хотите удалить обработчика<br/>"${item.name}"?`))
            DialogTabStore.setDialogTabButtons(["Да", "Нет"])
            DialogTabStore.setDialogTabIsOpen(true)
        }
    }

    const handleDeleteObjectClick = async () => {
        DialogTabStore.setDialogTabIsOpen(false) 

        if (deletingItem) {
            const foundObj = objectsList.find(object => 
                deletingItem.record_id === object.record_id
            )

            if (foundObj.object_status === 'added') {
               setObjectsList(objectsList.filter((object) => object.record_id !== deletingItem.record_id))
            } else {
                const project = {}
                project.data_model_id = 'stages_of_documentation_pdn'
                project.data = {} 
            
                project.data['list_of_third_party_processors'] = {
                    upsert: [],
                    remove: [{record_id: deletingItem.record_id}]
                }
    
                const savedProject = await DocumentService.updateDataObject(personalDataStore.project.record_id, project)
                await personalDataStore.resetPrintPolicyStage(personalDataStore.project.record_id)
            }
            
            loadObjectsList(true)
            setLastObjectSaved(true)
        } else {
            toast.error(<div>Ошибка при удалении стороннего обработчика! <br/><br/> Не выбран обработчик</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }

    const loadObjectsList = (isDeleted) => {
        let objList = [],
            foundIndex

        personalDataStore.thirdPartyList.forEach((item, index) => {
            foundIndex = objectsList.find(obj => obj.record_id === item.record_id)

            objList.push({
                name: item.data['type_of_organization'].value?.values[0]?.name,
                object_record_id: item.record_id,
                record_id: item.record_id,
                id: item.id,
                object_status: 'saved',
                value: foundIndex && !isDeleted ? foundIndex.value : index,
                status: 'finished',
                hidden: false,
                help: 'Заполните информацию о стороннем обработчике'
            })
        })

        setObjectsList(objList.sort((a,b) => a.value - b.value))
        setObjectsListStage(foundIndex && !isDeleted ? objList[objectsListStage.value] : objList[0])
    }

    useEffect(() => {
        if (personalDataStore.thirdPartyList && personalDataStore.thirdPartyList.length > 0) {
            loadObjectsList(false)
        } else {
            setLastObjectSaved(true)
        }
        setStageListWasHidden(personalDataStore.hideStageList)
        personalDataStore.setHideStageList(true)
    }, [])

    useEffect(() => {
        if (personalDataStore.thirdPartyList)
            loadObjectsList(false)
    }, [personalDataStore.thirdPartyList])

    return (
        <>
            <div id='3party-page' className='tw-grow tw-overflow-auto'>
                <div className='tw-grid tw-grid-cols-4 tw-gap-x-8 tw-max-h-full tw-h-full'>
                    { objectsList.length >= 0
                        ?
                            <>
                                <ObjectListPanel
                                    label='Обработчики'
                                    list={objectsList}
                                    stage={objectsListStage}
                                    isListSaved={lastObjectSaved}
                                    onAddClick={handleAddObjectClick}
                                    onDeleteClick={handleAskForDeleteClick}
                                    onJumpStageClick={() => {}}
                                    isSearch={false}
                                    isSelect={false}
                                />
                                <div id='3party-form-column' className='tw-col-span-3 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden tw-flex tw-flex-col'>
                                    {objectsList.length > 0
                                        ? <>
                                            <div className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                                                <p className='tw-text-md tw-font-semibold'>Третье лицо-обработчик "{objectsListStage.name}"</p>
                                            </div>
                                                <PData3PartyForm 
                                                    onBackClick={ObjectsListButtonsBackClick}
                                                    onForwardClick={ObjectsListButtonsForwardClick}
                                                    stage={objectsListStage}
                                                    objectsList={objectsList}
                                                    setLastObjectSaved={setLastObjectSaved}
                                                    markObjectAsFinished={markObjectAsFinished}
                                                />
                                            </>
                                        :
                                            <p className='tw-text-md tw-font-semibold tw-mt-2 tw-text-center'>
                                                Для заполнения информации об обработчике необходимо его добавить
                                            </p>
                                    }
                                </div>
                            </>
                        :
                            <div className='tw-col-span-4'>
                                <Spinner />
                            </div>               
                    }

                </div>
            </div>
            <PDataStageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleForwardClick}
                disabled={!lastObjectSaved}
            />
            <DialogTab
                parentName='PData3PartyListContainer'
                dialogTabFunction={handleDeleteObjectClick}
            />
        </>
    )
}

export default observer(PData3PartyListContainer)