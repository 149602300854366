import React, { useState, Fragment, useContext, useEffect } from 'react'
import { Context } from '../../../../..'
import { useForm, Controller } from 'react-hook-form'
import { Dialog, Transition } from '@headlessui/react'
import { observer } from 'mobx-react-lite'
import { XMarkIcon } from '@heroicons/react/24/outline'
import PageTitle from '../../common/panels/PageTitle'
import Spinner from '../../../../../assets/Spinner'
import DataSwitch from '../../common/inputs/DataSwitch'


/**
 * Компонент реализует логику отображения содержимого файла, прикрепленного к материалу обучения
 * 
 * @param {Boolean} isOpen Признак видимости (отображения) модального окна
 * @param {Function} onCloseClick Обработчик клика мыши для закрытия окна
 * 
 */
const ExportDataObjectContainer = ({isOpen, dataModel, onSubmitClick, onCloseClick}) => {
    const { docStore } = useContext(Context)
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,
        watch,
        formState: { errors, isValid, isSubmitting },
    } = useForm()

    const [isAllFields, setIsAllFields] = useState(true)
    const [isAllOn, setIsAllOn] = useState(true)
    const [isAnyOn, setIsAnyOn] = useState(false)

  
    // const handleSubmitClick = (form) => {
    //     setIsSaving(true)
    //     const dataObject = {}
    //     dataObject.data = {}
    //     // data['is_done']
    //     dataObject.data['action_is_done'] = true
    //     dataObject.data['comment'] = form.comment
    //     dataObject.data_model_id = 'planned_actions'   // dataModel.id
        
    //     const noResponse = setTimeout(() => {
    //         toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
    //     }, responseTimeOut)

    //     DocumentService
    //         .updateDataObject(plannerStore.selectedAction.record_id, dataObject)
    //         .then(() => {
    //             toast.success('Действие отмечено как выполненное', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
    //             onSubmitClick()
    //         })
    //         .catch(error => {
    //             showErrorToast(error, 'saving', '')
    //         })
    //         .finally(() => {
    //             clearTimeout(noResponse)
    //             setIsSaving(false)
    //         })

    // }

    const handleAllFieldsClick = () => {
        dataModel.fields.forEach(field => {
            setValue(field.tech_name, !isAllFields)
        })
        setIsAllOn(!isAllFields)
        setIsAnyOn(!isAllFields)
        setIsAllFields(!isAllFields)
    }

    const checkAllFields = () => {
        const allValues = getValues()
        setIsAllOn(Object.values(allValues).every(field => field))
        setIsAnyOn(Object.values(allValues).some(field => field))
        setIsAllFields(Object.values(allValues).some(field => field))
    }

    useEffect(() => {
        if (isOpen) {

        } else {
            if (dataModel) {
                dataModel.fields.forEach(field => {
                    setValue(field.tech_name, true)
                })
                setIsAllFields(true)
            }
        }

    }, [isOpen])

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={onCloseClick}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-500"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-500"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                    <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                        <div className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex  tw-pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                enterFrom="tw-translate-x-full"
                                enterTo="tw-translate-x-0"
                                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                leaveFrom="tw-translate-x-0"
                                leaveTo="tw-translate-x-full"
                            >
                                <Dialog.Panel  className="tw-pointer-events-auto tw-relative tw-w-[50vw]"> 
                                    <Transition.Child
                                        as={Fragment}
                                        enter="tw-ease-in-out duration-500"
                                        enterFrom="tw-opacity-0"
                                        enterTo="tw-opacity-100"
                                        leave="tw-ease-in-out tw-duration-500"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                    >
                                        <div className="tw-absolute tw-left-0 tw-top-0 tw--ml-8 tw-flex tw-pr-2 tw-pt-4 sm:tw--ml-10 sm:tw-pr-4">
                                            <button
                                                type="button"
                                                className="tw-rounded-md hover:tw-text-gray-300 tw-text-white hover:tw-bg-gray-500 focus:tw-outline-none tw-ring-2 tw-ring-white"
                                                onClick={onCloseClick}
                                            >
                                                <span className="tw-sr-only">Закрыть</span>
                                                <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto tw-bg-gray-200 tw-px-4 tw-pb-4 tw-shadow-xl">
                                        <PageTitle title='Выбор полей для экспорта'/>
                                        <div id='export-fields-column' className='tw-h-full tw-flex tw-flex-col tw-overflow-hidden tw-rounded-md tw-bg-white tw-w-full'>
                                            <div className={`tw-grid tw-grid-cols-4 tw-items-center 
                                                            tw-h-12 tw-px-4 tw-py-1.5 
                                                            tw-border-b-2 tw-border-gray-300`}>
                                                <div className='tw-col-span-3 tw-text-sm tw-font-semibold'>
                                                    Поля
                                                </div>
                                                <div className='tw-col-span-1 tw-justify-self-end tw-min-w-32 tw-pr-8'>
                                                    <DataSwitch
                                                        isChecked={isAllFields}
                                                        onClick={handleAllFieldsClick}
                                                        isThirdState={!isAllOn && isAnyOn}
                                                    />
                                                </div>            
                                            </div>
                                            <div className='tw-grow tw-overflow-auto tw-py-1'>
                                                { dataModel
                                                    ?
                                                        dataModel.fields.map((fieldItem, index) => 
                                                            <div key={index} className='tw-grid tw-grid-cols-4 tw-items-center tw-border-b tw-px-4 tw-py-1'>
                                                                <div className='tw-col-span-3 tw-pr-4 tw-text-sm tw-truncate'>
                                                                    {fieldItem.alias}
                                                                </div>
                                                                <div className='tw-col-span-1 tw-justify-self-end tw-min-w-32'>
                                                                    <Controller
                                                                        name={fieldItem.tech_name}
                                                                        control={control}
                                                                        defaultValue={true}
                                                                        render={({field}) =>
                                                                            <DataSwitch 
                                                                                isChecked={field.value}
                                                                                onClick={(e) => {field.onChange(e); checkAllFields()}}
                                                                            />}
                                                                    />
                                                                </div>
                            
                                                            </div>
                                                        )
                                                    :
                                                        <Spinner/>
                                                }
                                            </div>
                                            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-py-4'>
                                                <button 
                                                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                                            hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                                    onClick={handleSubmit(onSubmitClick)}
                                                >
                                                    Экспортировать
                                                </button>
                                                <button
                                                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                                                            hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                                    onClick={onCloseClick}
                                                >
                                                    Отменить
                                                </button>
                                            </div>
                                       </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default observer(ExportDataObjectContainer)