import { $host, $authHost } from '../http/http'
import { documentAPI, userAPI } from '../config/constsURN'

/**
 * Класс реализует API для работы с пользователями
 * @class
 */
class AuthService {

    /**
     * Отправляет запрос с данными пользователя для входа в систему
     * @static
     * @param {String} email Email пользователя
     * @param {String} password Пароль пользователя
     * @returns {Promise} Axios.response
     */
    static async auth(login, password) {
        return $host.post(userAPI.LOGIN_API, {login, password})
    }
    
    /**
     * Отправляет запрос на обновление токена доступа (access-token)
     * @static
     * @param {String} refresh Токен обновления (refresh-token)
     * @returns {Promise} Axios.response
     */
    static async refreshToken(refresh) {
        return $authHost.post(userAPI.REFRESH_API, { refresh })
    }

    /**
     * Отправляет запрос с данными пользователя для самостоятельной регистрации в системе
     * @static
     * @param {Object} data Объект, содержащий информацию пользователя
     * @returns {Promise} Axios.response
     */
    static async userRegistration(data) {
        return $host.post(userAPI.USER_API + '/registration', data)
    }

    /**
     * Отправляет запрос для активации зарегистрированного пользователя
     * @static
     * @param {Object} payload Информация пользователя
     * @returns {Promise} Axios.response
     */
    static async activateUser(payload) {
        return $host.get(userAPI.USER_API + '/activate/', {params: {payload: payload}})
    }

    /**
     * Отправляет запрос с данными пользователя для регистрации в системе через панель администратора
     * @static
     * @param {Object} data данные пользователя 
     * @returns {Promise} Axios.response
     */
    static async registerUser(data){
        return $authHost.post(userAPI.USER_API, data)
    }

    /**
     * Отправляет запрос для смены пароля пользователя
     * @static
     * @param {Object} data Объект, содержащий информацию о старом и новом пароле пользователя
     * @returns {Promise} Axios.response
     */
    static async changePassword(data) {
        return $authHost.post(userAPI.USER_API + '/reset-password', data)
    }

    /**
     * Отправляет запрос на редактирование пользователя
     * @static
     * @param {Object} data данные пользователя 
     * @param {Number} id ID пользователя 
     * @returns {Promise} Axios.response
     */
    static async editUser(data, id) {
        return $authHost.patch(userAPI.USER_API + `/${id}`, data) 
    }

    /**
     * Отправляет запрос на редактирование авторизированного пользователя
     * @static
     * @param {Object} data данные пользователя
     * @returns {Promise} Axios.response
     */
    static async editUserMe(data) {
        return $authHost.patch(userAPI.USER_API + `/me`, data) 
    }

    /**
     * Отправляет запрос на получение данных авторизированного пользователя (определяется по access-token)
     * @static
     * @returns {Promise} Axios.response
     */
    static async getUser() {
        return $authHost.get(userAPI.USER_API + '/me')
    }

    /**
     * Отправляет запрос на получение списка существующих пользователей
     * @static
     * @returns {Promise} Axios.response
     */
    static async getUsers() {
        return $authHost.get(userAPI.USER_API + '/all')
    }
 
    /**
     * Отправляет запрос с данными компании для регистрации в системе
     * @static
     * @param {Object} data данные компании 
     * @returns {Promise} Axios.response
     */
    static async registerCompany(data) {
        return $authHost.post(userAPI.COMPANY_API, data)
    }

    /**
     * Отправляет запрос на редактирование компании
     * @static
     * @param {Object} data данные компании 
     * @param {Number} id ID компании 
     * @returns {Promise} Axios.response
     */
    static async editCompany(data, id) {
        return $authHost.patch(userAPI.COMPANY_API + `/${id}`, data)
    }

    /**
     * Отправляет запрос на получение компании авторизированного пользователя (определяется по access-token)
     * @static
     * @returns {Promise} Axios.response
     */
    static async getCompany() {
        return $authHost.get(userAPI.COMPANY_API + '/my')
    }

    /**
     * Отправляет запрос на редактирование компании авторизованного пользователя
     * @static
     * @param {Object} data данные компании
     * @returns {Promise} Axios.response
     */
    static async editMyCompany(data) {
        return $authHost.patch(userAPI.COMPANY_API + `/my`, data)
    }

    /**
     * Отправляет запрос на получение списка существующих компаний
     * @static
     * @returns {Promise} Axios.response
     */
    static async getCompanies() {
        return $authHost.get(userAPI.COMPANY_API + '/all')
    }
    
    /**
     * Отправляет запрос на получение списка юр лиц пользователя
     * @static
     * @returns {Promise} Axios.response
     */
    static async getMySubCompanies() {
        return $authHost.get(userAPI.USER_API + `/me/sub-companies`)
    }

    /**
     * Отправляет запрос на получение списка существующих пользователей собственной компании
     * @static
     * @returns {Promise} Axios.response
     */
    static async getMyCompanyUsers() {
        return $authHost.get(userAPI.COMPANY_API + `/my/users`)
    }

    /**
     * Отправляет запрос на изменение текущего юр лица пользователя
     * @static
     * @param {Number} id ID юр лица
     * @returns {Promise} Axios.response
     */
    static async changeCurrentSubCompany(id) {
        return $authHost.post(userAPI.USER_API + `/me/sub-companies/${id}`)
    }

    /**
     * Отправляет запрос на редактирование юр лица авторизованного пользователя
     * @static
     * @param {Object} data Данные юр лица 
     * @param {Number} id ID юр лица
     * @returns {Promise} Axios.response
     */
    static async editMySubCompany(data, id) {
        return $authHost.patch(userAPI.COMPANY_API + `/my/sub-companies/${id}`, data)
    }

    /**
     * Отправляет запрос на создание юр лица в авторизованного пользователя
     * @static
     * @param {Object} data Данные юр лица 
     * @returns {Promise} Axios.response
     */
    static async registerMySubCompany(data) {
        return $authHost.post(userAPI.COMPANY_API + `/my/sub-companies`, data)
    }

    /**
     * Отправляет запрос на получение списка существующих юр лиц компании
     * @static
     * @param {Number} id ID компании
     * @returns {Promise} Axios.response
     */
    static async getSubCompanies(id) {
        return $authHost.get(userAPI.COMPANY_API + `/${id}/sub-companies`)
    }
 
    /**
     * Отправляет запрос на привязку и отвязку пользователя к юр лицу компании авторизованного пользователя
     * @static
     * @param {Object} data Пользователи 
     * @param {Number} id ID юр лица
     * @returns {Promise} Axios.response
     */
    static async rebindMyUsers(data, id) {
        return $authHost.post(userAPI.COMPANY_API + `/my/sub-companies/${id}`, data)
    }

    /**
     * Отправляет запрос на редактирование юр лица компании
     * @static
     * @param {Object} data Данные юр лица 
     * @param {Number} id ID компании
     * @param {Number} sub_id ID юр лица
     * @returns {Promise} Axios.response
     */
    static async editSubCompany(data, id, sub_id) {
        return $authHost.patch(userAPI.COMPANY_API + `/${id}/sub-companies/${sub_id}`, data)
    }

    /**
     * Отправляет запрос на создание юр лица компании
     * @static
     * @param {Object} data Данные юр лица 
     * @param {Number} id ID компании
     * @returns {Promise} Axios.response
     */
    static async registerSubCompany(data, id) {
        return $authHost.post(userAPI.COMPANY_API + `/${id}/sub-companies`, data)
    }

    /**
     * Отправляет запрос на привязку и отвязку пользователя к юр лицу компании
     * @static
     * @param {Object} data Пользователи 
     * @param {Number} id ID юр лица
     * @param {Number} id ID компании
     * @returns {Promise} Axios.response
     */
    static async rebindUsers(data, id, sub_id) {
        return $authHost.post(userAPI.COMPANY_API + `/${id}/sub-companies/${sub_id}`, data)
    }

    /**
     * Отправляет запрос на создание роли компании
     * @static
     * @param {Object} data Данные роли
     * @returns {Promise} Axios.response
     */
    static async createRole(data) {
        return $authHost.post(userAPI.COMPANY_API + `/my/roles`, data)
    }

    /**
     * Отправляет запрос на редактирование роли компании
     * @static
     * @param {Object} data Данные роли
     * @param {Number} id ID роли
     * @returns {Promise} Axios.response
     */
    static async editRole(data, id) {
        return $authHost.patch(userAPI.COMPANY_API + `/my/roles/${id}`, data)
    }

    /**
     * Отправляет запрос на получение списка существующих ролей компании пользователя
     * @static
     * @returns {Promise} Axios.response
     */
    static async getRoles() {
        return $authHost.get(userAPI.COMPANY_API + `/my/roles`)
    }
    
    /**
     * Отправляет запрос на удаление роли компании
     * @static
     * @param {Number} id ID роли
     * @returns {Promise} Axios.response
     */
    static async deleteRole(id) {
        return $authHost.delete(userAPI.COMPANY_API + `/my/roles/${id}`)
    }

    /**
     * Отправляет запрос на привязку и отвязку пользователя к роли своей компании
     * @static
     * @param {Object} data Пользователи 
     * @param {Number} id ID роли
     * @returns {Promise} Axios.response
     */
    static async rebindRole(id, data) {
        return $authHost.post(userAPI.COMPANY_API + `/my/roles/${id}`, data)
    }

    /**
     * Отправляет запрос на привязку пользователя к другой компании
     * @static
     * @param {Number} user_id ID пользователя
     * @param {Number} company_id ID компании
     * @returns {Promise} Axios.response
     */
    static async rebindUserToCompany(user_id, company_id) {
        return $authHost.patch(userAPI.USER_API + `/${user_id}/change-company`, {company_id: company_id})
    }

    /**
     * Отправляет запрос на получение списка существующих ролей компании
     * @static
     * @param {Number} id ID компании
     * @returns {Promise} Axios.response
     */
    static async getCompanyRoles(id) {
        return $authHost.get(userAPI.COMPANY_API + `/${id}/roles`)
    }

    /**
     * Отправляет запрос на привязку и отвязку пользователя к роли
     * @static
     * @param {Object} data Пользователи 
     * @param {Number} role_id ID роли
     * @param {Number} company_id ID компании
     * @returns {Promise} Axios.response
     */
    static async rebindCompanyRole(data, role_id, company_id) {
        return $authHost.post(userAPI.COMPANY_API + `/${company_id}/roles/${role_id}`, data)
    }

    /**
     * Отправляет запрос на получение списка существующих разрешений
     * @static
     * @returns {Promise} Axios.response
     */
    static async getPermissions() {
        return $authHost.get(userAPI.PERMISSIONS_API)
    }

    /**
     * Отправляет запрос на получение списка существующих разрешений авторизированного пользователя
     * @static
     * @returns {Promise} Axios.response
     */
    static async getMyPermissions() {
        return $authHost.get(userAPI.USER_API + '/me/permissions')
    }

    /**
     * Отправляет запрос на получение списка существующих разрешений пользователя
     * @static
     * @param {Number} id ID пользователя
     * @returns {Promise} Axios.response
     */
    static async getUserPermissions(id) {
        return $authHost.get(userAPI.USER_API + `/${id}/permissions`)
    }

    /**
     * Отправляет запрос на получение списка существующих ролей пользователя
     * @static
     * @param {Number} id ID пользователя
     * @returns {Promise} Axios.response
     */
    static async getUserRoles(id) {
        return $authHost.get(userAPI.USER_API + `/${id}/roles`)
    }
    
    /**
     * Отправляет запрос на получение списка существующих разрешений пользователя моей компании
     * @static
     * @param {Number} id ID пользователя
     * @returns {Promise} Axios.response
     */
    static async getMyCompanyUserPermissions(id) {
        return $authHost.get(userAPI.COMPANY_API + `/my/users/${id}/permissions`)
    }

    /**
     * Отправляет запрос на получение списка существующих разрешений пользователя моей компании
     * @static
     * @param {Number} tech_id ID пользователя
     * @returns {Promise} Axios.response
     */
    static async getRoleDataModelPermissions(tech_id) {
        let data = []
        let response
        let offset = 0,
            count = 50

        do {
            response = await $authHost.get(documentAPI.DATAMODEL_ROLE_PERMISSION_API + `/${tech_id}/permissions`, {params: {offset, count, total: true, filter: [], sort: []}})
            data = data.concat(response.data)
            offset += count
        } while (response?.data?.length === count)

        return data
    }

    /**
     * Отправляет запрос на получение списка существующих разрешений пользователя моей компании
     * @static
     * @param {Number} id ID пользователя
     * @returns {Promise} Axios.response
     */
    static async getUserDataModelPermissions(id) {
        let data = []
        let response
        let offset = 0,
            count = 50

        do {
            response = await $authHost.get(documentAPI.DATAMODEL_USER_PERMISSION_API + `/${id}/permissions`, {params: {offset, count, total: true, filter: [], sort: []}})
            data = data.concat(response.data)
            offset += count
        } while (response?.data?.length === count)

        return data
    }

    /**
     * Отправляет запрос на получение списка существующих разрешений пользователя моей компании
     * @static
     * @param {Object[]} data Данные о массиве разрешений для таблиц
     * @param {Number} tech_id ID пользователя
     * @returns {Promise} Axios.response
     */
    static async createRoleDataModelPermissions(data, tech_id) {
        return $authHost.post(documentAPI.DATAMODEL_ROLE_PERMISSION_API + `/${tech_id}/permissions/bulk`, data)
    }

    /**
     * Отправляет запрос на получение списка существующих ролей пользователя моей компании
     * @static
     * @param {Number} id ID пользователя
     * @returns {Promise} Axios.response
     */
    static async getMyCompanyUserRoles(id) {
        return $authHost.get(userAPI.COMPANY_API + `/my/users/${id}/roles`)
    }
    
    /**
     * Отправляет запрос на получение существующей роли
     * @static
     * @param {Number} id ID роли
     * @returns {Promise} Axios.response
     */
    static async getRoleById(id) {
        return $authHost.get(userAPI.COMPANY_API + `/my/roles/${id}`)
    }

    /**
     * Отправляет запрос на редактирование пользователя
     * @static
     * @param {Object} data данные пользователя 
     * @param {Number} id ID пользователя 
     * @returns {Promise} Axios.response
     */
    static async editMyCompanyUser(data, id) {
        return $authHost.patch(userAPI.COMPANY_API + `/my/users/${id}`, data) 
    }

    /**
     * Отправляет запрос с данными пользователя для регистрации в системе через панель локального администратора
     * @static
     * @param {Object} data данные пользователя 
     * @returns {Promise} Axios.response
     */
    static async registerMyCompanyUser(data){
        return $authHost.post(userAPI.COMPANY_API + `/my/users`, data)
    }

    /**
     * Метод очищает локальное хранилище при выходе пользователя из системы
     * @static
     * @param {Boolean} isSending Признак необходимости отправки запроса на выход из аккаунта (удаление токенов)
     * @returns {Boolean} True
     */
    static async logout(isSending) {
        try {
            if (isSending) {
                await $authHost.post(userAPI.LOGOUT_API, {})
            }
        } catch (error) {
            console.log(error?.response)           
        } finally {
            localStorage.removeItem('token')
            localStorage.removeItem('refresh')
            sessionStorage.removeItem('activeDataModel')
        }

        return true
    }
}

export default AuthService