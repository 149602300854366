import { Fragment, useEffect, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3BottomLeftIcon } from '@heroicons/react/20/solid'
import { observer } from 'mobx-react-lite'
import DocumentService from '../../../../../../services/DocumentService'

/**
 * Визуальный компонент отображает предлагаемые обоснования неактуальности
 * 
 * @param {String} id ID индикатора
 * @param {Function} setText Обработчик клика мыши при выборе значения в списке
 * @param {String} suggestionsRuleId ID обоснования неактуальности
 * @param {String} tooltipId ID всплывающей подсказки
 * 
 */
const SuggestionsList = ({id, setText, suggestionsRuleId, tooltipId}) => {
    const [top, setTop] = useState(null)
    const [scroll, setScroll] = useState(false)
    const [height, setHeight] = useState(null)
    const [translateY, setTranslateY] = useState(false)
    const [list, setList] = useState([])

    const onSuggestionsClick = () => {
        setScroll(false)
        setTranslateY(false)
        setHeight(null)
       
        setTop(window.event.clientY + 10)
        let clientTop = window.event.clientY + 10

        setTimeout(() => {
            if(document.getElementById(id)?.offsetHeight > (window.window.innerHeight - clientTop)){
                setScroll(true)
                if((window.window.innerHeight - clientTop) < 150){
                    setTranslateY(true)
                    setHeight(150)
                } else {
                    setHeight(window.window.innerHeight - clientTop - 15)
                }
            }
        }, 1000)
    }

    useEffect(() => {
        if(suggestionsRuleId && id){
            DocumentService.getNestedDataObjects(50, [], [], id, suggestionsRuleId)
                .then(response => {
                    setList(response);
                })
        }
    }, [suggestionsRuleId, id])

    return (
        <Popover>
            <Popover.Button>
                <span
                    data-tooltip-id={tooltipId}
                    data-tooltip-content="Примеры обоснований"
                    data-tooltip-delay-show={1000}
                >
                    <Bars3BottomLeftIcon 
                        className='tw-w-5 tw-h-5 hover:tw-cursor-pointer hover:tw-text-gray-400'
                        onClick={onSuggestionsClick}
                    />
                </span>
            </Popover.Button>
            <Transition
                as={Fragment}
                enter="tw-transition tw-ease-out tw-duration-200"
                enterFrom="tw-opacity-0 tw-translate-y-1"
                enterTo="tw-opacity-100 tw-translate-y-0"
                leave="tw-transition tw-ease-in tw-duration-150"
                leaveFrom="tw-opacity-100 tw-translate-y-0"
                leaveTo="tw-opacity-0 tw-translate-y-1"
            >
                <Popover.Panel id={id} style={{top: top}}
                    className={`${translateY ? 'tw--translate-y-52' : ''} tw-fixed tw-z-10 tw-mt-3 tw--translate-x-full tw-transform tw-px-4 sm:tw-px-2 tw-w-2/5`}
                >
                    <div style={{height: height}} className={`${scroll ? 'tw-overflow-y-scroll' : ''} tw-overflow-hidden tw-rounded-lg tw-shadow-xl tw-ring-1 tw-ring-black tw-ring-opacity-20`}>
                        <div   className="tw-flex tw-flex-col tw-justify-start tw-items-center tw-bg-white tw-px-1 tw-py-2 tw-text-sm tw-font-normal">
                            
                            {list.length !== 0 ?
                                <>
                                    {list.map((item) => 
                                        <Popover.Button
                                            key={item.id}
                                            className="tw-w-full tw-px-2 tw-py-1 hover:tw-bg-gray-200 tw-text-left tw-whitespace-break-spaces
                                                    focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-50"
                                            onClick={() => {setText(id, item.data.justification_f_inapplicability__justification_of_inapp_vt.value, false)}}
                                        >
                                            {item.data.justification_f_inapplicability__justification_of_inapp_vt.value}
                                        </Popover.Button>
                                    )}
                                </>
                                : <p className='tw-text-red-400'>Для данного показателя нет примеров обоснований</p>
                            }
                            
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}

export default observer(SuggestionsList)