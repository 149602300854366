import React, { Fragment, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Spinner from '../../../../../assets/Spinner'
import { Listbox } from '@headlessui/react'
import { ChevronUpDownIcon, CheckIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'

  
/**
 * Визуальный компонент отображает модальное окно выбора таблицы из списка для последующего выбора записи
 *
 * @param {Boolean} isVisible Признак видимости (отображения) модального окна
 * @param {Object} dataModelList ID записи, к которой принадлежит экспортируемая запись
 * @param {Object} selectedItem Выбранный пункт в списке таблиц
 * @param {Function} onItemChange Обработчик выбора пункта из списка
 * @param {Function} onSubmit Обработчик клика мыши на кнопке подтверждения выбора таблицы
 * @param {Function} onCloseModal Обработчик клика мыши на кнопке отмены экспорта
 * @param {Boolean} isError Признак ошибки
 * 
 */
const SelectReferencedModel = ({isVisible, dataModelList, selectedItem, onItemChange, onSubmit, onCloseModal, isError}) => {

    const focusRef = useRef(null)

    const keydownHandler = ({ key }) => {
        switch (key) {
          case 'Escape':
            onCloseModal();
            break;
          default:
        }
      }; 

    useEffect(() => {
        document.addEventListener('keydown', keydownHandler);
        return () => document.removeEventListener('keydown', keydownHandler);
    });

    if (!dataModelList.length)
        <Spinner/>

    return (
        <Transition.Root show={isVisible} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" initialFocus={focusRef} onClose={onCloseModal}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-out tw-duration-300"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in tw-duration-200"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
                    <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="tw-ease-out tw-duration-300"
                            enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                            enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leave="tw-ease-in tw-duration-200"
                            leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                        >
                            <Dialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-text-left tw-shadow-xl 
                                                    tw-transition-all tw-h-72 sm:tw-my-4 sm:tw-w-full sm:tw-max-w-md">
                                { dataModelList &&                                                        
                                    <div className="tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 sm:tw-p-6 sm:tw-pb-4">
                                        <div className="sm:tw-flex sm:tw-items-start">
                                            <div className=" tw-w-full tw-mt-3 tw-text-left sm:tw-ml-2 sm:tw-mt-0 sm:tw-text-left">
                                                <Dialog.Title as="h3" className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-md tw-font-semibold tw-py-2 tw-mb-2 tw-text-gray-900">
                                                    <span>Выбор справочника</span>
                                                    {isError && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                                                </Dialog.Title>
                                                <Listbox value={selectedItem} by={'id'} onChange={onItemChange} as='div'>
                                                    <div className="tw-relative tw-mt-1 tw-h-36">
                                                        <Listbox.Button className={({ open }) =>
                                                                `tw-relative tw-w-full tw-h-8 tw-cursor-default tw-rounded-md tw-bg-white tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-left 
                                                                tw-border focus:tw-outline-none focus-visible:tw-border-gray-600 focus-visible:tw-ring-2
                                                                focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 
                                                                focus-visible:tw-ring-offset-2 focus-visible:tw-ring-offset-gray-400 sm:tw-text-sm
                                                                ${isError ? open ? 'tw-ring-red-400 !tw-ring-2' : 'tw-border-red-400' 
                                                                          : open ? 'tw-ring-gray-400 !tw-ring-2' : 'tw-border-gray-400'}
                                                            `}
                                                        >
                                                            <span className="tw-block tw-truncate">{selectedItem.entity_name}</span>
                                                            <span className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
                                                                <ChevronUpDownIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                                                            </span>
                                                        </Listbox.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            leave="tw-transition tw-ease-in tw-duration-100"
                                                            leaveFrom="tw-opacity-100"
                                                            leaveTo="tw-opacity-0"
                                                        >
                                                            <Listbox.Options className="tw-absolute tw-z-30 tw-mt-1 tw-max-h-28 tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base 
                                                                                    tw-border tw-border-gray-300 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none sm:tw-text-sm">
                                                                { dataModelList.length === 0  
                                                                    ?
                                                                        <div className="tw-relative tw-cursor-default tw-select-none tw-py-2 tw-px-4 tw-text-red-400">
                                                                            Список пуст
                                                                        </div>
                                                                    : 
                                                                    ( dataModelList.map((item, index) => (
                                                                        <Listbox.Option
                                                                            key={index}
                                                                            className={({ active }) =>
                                                                                `tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-4 tw-pr-10 
                                                                                ${ active ? 'tw-bg-gray-500 tw-text-white' : 'tw-text-gray-900' }`
                                                                            }
                                                                            value={item}
                                                                        >
                                                                        {({ selected }) => (
                                                                            <>
                                                                            <span
                                                                                className={`tw-block tw-truncate ${
                                                                                    selected ? 'tw-font-semibold' : 'tw-font-normal' }`
                                                                            }
                                                                            >
                                                                                {item.entity_name}
                                                                            </span>
                                                                            {selected ? (
                                                                                <span className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                                                                                    <CheckIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                                                                </span>
                                                                            ) : null}
                                                                            </>
                                                                        )}
                                                                        </Listbox.Option>
                                                                    )))
                                                                }
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                    <div className='tw-mt-4 tw-flex tw-justify-center'>
                                                            <button className='tw-w-24 tw-m-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white disabled:tw-bg-gray-300 disabled:tw-border-gray-300  hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600' 
                                                                type='button'
                                                                onClick={onSubmit}
                                                            >
                                                                ОК
                                                            </button>
                                                            <button className='tw-w-24 tw-m-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700 hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600' 
                                                                type='button'
                                                                onClick={onCloseModal}
                                                            >
                                                                Отмена 
                                                            </button>
                                                        </div>
                                                </Listbox>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default SelectReferencedModel