import React from 'react'
import StageItem from './StageItem'


/**
 * Визуальный компонент отображает список этапов процесса
 * 
 * @param {Object} list Список этапов процесса 
 * @param {Object} activeStage Текущий этап процесса
 * @param {String} idField Имя поля с номером этапа 
 * @param {Number} lastStageIndex Номер этапа, до которого дошел пользователь
 * @param {Boolean} isHideList Признак скрытия списка этапов 
 * @param {Function} onItemClick Обработчик клика мыши для перехода обратно к произвольному завершенному этапу
 * 
 */
const StageList = ({list, activeStage, idField, lastStageIndex, isHideList, onItemClick}) => {
    return (
        <div
            id='stage-list-items'
            className='tw-flex tw-flex-col tw-justify-between tw-w-full tw-h-[calc(100%_-_3rem)] lg:tw-h-[calc(100%_-_6rem)] tw-bg-white tw-overflow-auto'
        >
            <div>
                { list.map((stage, index) => 
                    <StageItem
                        key={index}
                        item={stage}
                        activeItem={activeStage}
                        idField={idField}
                        lastStageIndex={lastStageIndex}
                        isHideList={isHideList}
                        onItemClick={onItemClick}
                    />
                )}
            </div>
        </div>
    )
}

export default StageList