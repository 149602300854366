import { paths } from "../../config/constsURN"

/**
 * Визуальный компонент отображает страницу с ошибкой 404
 * 
 */
function ErrorPage() {
    return (
        <div className="tw-grid tw-min-h-full tw-place-items-center tw-bg-white tw-rounded-md tw-mt-8 tw-px-6 tw-py-24 sm:tw-py-32 lg:tw-px-8">
            <div className="tw-text-center">
                <p className="tw-text-base tw-font-semibold tw-text-indigo-600">404</p>
                <h1 className="tw-mt-4 tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900 sm:tw-text-5xl">Страница не найдена</h1>
                <p className="tw-mt-6 tw-text-base tw-leading-7 tw-text-gray-600">Извините, мы не можем найти запрашиваемую страницу</p>
                <div className="tw-mt-10 tw-flex tw-items-center tw-justify-center tw-gap-x-6">
                    <a
                        href={paths.MAIN_ROUTE}
                        className="tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500
                        focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
                    >
                        Вернуться на главную
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage