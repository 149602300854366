import React, { useContext } from 'react'
import { CheckCircleIcon, MinusIcon } from '@heroicons/react/20/solid'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import { contentTooltipTimeOut } from '../../../config/constTypes'

/**
 * Визуальный компонент отображает название одного пользователя для списка существующих пользователей
 * 
 * @param {Object} item Текущий элемент списка пользователей 
 * 
 */
const UsersListItem = ({item}) => {
    const { RolesStore, userStore } = useContext(Context)

    const onItemClick = (item) => {
        if (RolesStore.selectedUser?.id !== item.id && item.is_active) {
            RolesStore.setSelectedUser(item)
            RolesStore.setSelectedRole(null)
            RolesStore.setIsRoleInfoOpen(false)
            RolesStore.getUserRoles(item.id)
        }
    }

    const onItemDoubleClick = (item) => {
        RolesStore.setIsUserInfoOpen(true)
        RolesStore.setSelectedUser(item)
    }

    const bindUser = () => {
        if (userStore.checkPermission("company.my.role:edit"))
            RolesStore.rebindRole(RolesStore.selectedRole.id, {users: {add: [item.id]}})
    }

    const unBindUser = () => {
        if (userStore.checkPermission("company.my.role:edit"))
            RolesStore.rebindRole(RolesStore.selectedRole.id, {users: {remove: [item.id]}})
    }

    return (
        <div className={`tw-relative tw-pl-12 tw-text-sm tw-select-none tw-border-b tw-py-2
                ${ !item.is_active
                        ?   'tw-line-through tw-text-red-400'
                        :   RolesStore.selectedUser?.id === item.id
                                ?   'tw-text-white tw-bg-gray-500'
                                :   'tw-text-gray-900 tw-bg-white hover:tw-bg-gray-100 hover:tw-border-gray-300'} 
                `}
        >
            { RolesStore.selectedRole && item.is_active &&
                <div
                    className={`tw-text-gray-500 tw-absolute tw-left-3 tw-top-4 ${userStore.checkPermission("company.my.role:edit") && RolesStore.selectedUser?.id !== item.id && 'hover:tw-cursor-pointer'}`}
                    data-tooltip-id="users-list-tooltip" data-tooltip-content={userStore.checkPermission("company.my.role:edit") ? item.isAssigned  ? "Отвязать" : "Привязать" : ''} data-tooltip-delay-show={contentTooltipTimeOut}
                >
                    {item.isAssigned 
                        ? <CheckCircleIcon className="tw-w-5 tw-h-5 hover:tw-text-gray-700" onClick={unBindUser}/> 
                        : <MinusIcon className="tw-w-5 tw-h-5 hover:tw-text-gray-700" onClick={bindUser}/>
                    }
                </div>
            }
            <div
                className={`tw-w-full tw-flex tw-flex-col tw-truncate ${RolesStore.selectedUser?.id !== item.id && 'hover:tw-cursor-pointer'}`}
                data-tooltip-id="users-list-tooltip" data-tooltip-content={item.is_active  ? "" : "Пользователь заблокирован"} data-tooltip-delay-show={contentTooltipTimeOut}
                onClick={() => onItemClick(item)}
                onDoubleClick={() => onItemDoubleClick(item)}
            >
                <p>
                    {item.first_name} {item.last_name}
                </p>
                <p className={`tw-text-xs`}>
                    {item.email}
                </p>
            </div>
        </div>
    )
}

export default observer(UsersListItem)
