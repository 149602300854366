import React, { useContext } from 'react'
import { Context } from '../../../../../../..'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { getFieldValue, menuTooltipTimeOut } from '../../../../../../../config/constTypes'
import { observer } from 'mobx-react-lite'
import { checkMethodPermissions } from '../../../../../../../functions/checkPermissions'

/**
 * Визуальный компонент отображает информацию одного запланированного действия планировщика
 * 
 * @param {Object} actionItem Запланированное действие
 * @param {Function} onDoubleClick Обработчик двойного клика мыши на действии
 * 
 */
const PlannedActionItem = ({actionItem, onDoubleClick}) => {
    const { plannerStore, userStore } = useContext(Context)
    const company = userStore.userSubCompanies.find(company => company.id.toString() === actionItem.subgroup_id)
    const [isWrite, isWriteDescription] = checkMethodPermissions(plannerStore.selectedAction, 'write')

    const handleActionClick = (action) => {
        plannerStore.onActionSelect(action)
    }

    return (
        <div id={'planned-action-item-' + actionItem.record_id} className={`${actionItem.record_id === plannerStore.selectedAction?.record_id
                    ?   'tw-text-white tw-bg-gray-500'
                    :   'tw-text-gray-900 tw-bg-white hover:tw-bg-gray-100 tw-cursor-pointer'}
                tw-w-full tw-p-2 tw-mb-4 tw-rounded-md tw-shadow-md tw-border
                tw-flex tw-justify-between tw-items-center tw-gap-x-2
                tw-border-gray-400 ${actionItem?.system_data?.deletion_mark && 'tw-line-through'}
            `}
            onClick={() => handleActionClick(actionItem)}
            onDoubleClick={isWrite ? onDoubleClick : undefined}
        >
            <div className='tw-block tw-select-none'>
                <p className={`${actionItem.record_id !== plannerStore.selectedAction?.record_id && actionItem.data['action_is_done'].value
                                ? 'tw-text-teal-600'
                                : actionItem.record_id !== plannerStore.selectedAction?.record_id && actionItem.data['action_completion_date'].value < Date.now()
                                        ? 'tw-text-red-500'
                                        : ''}`}>
                    {getFieldValue(actionItem.data['action_completion_date']).slice(0,-3)}
                </p>
                <p className={`tw-font-semibold tw-text-sm 
                    ${actionItem.record_id !== plannerStore.selectedAction?.record_id && actionItem.data['action_is_done'].value
                        ? 'tw-text-teal-600'
                        : actionItem.record_id !== plannerStore.selectedAction?.record_id && actionItem.data['action_completion_date'].value < Date.now()
                                ?   'tw-text-red-500'
                                :   ''}`}>
                    {getFieldValue(actionItem.data['my_action_reference'])}
                </p>
                <p className='tw-text-xs tw-italic'>Ответственный: {getFieldValue(actionItem.data['responsible_user_name'])} {getFieldValue(actionItem.data['responsible_user_email'])}</p>
                <p className='tw-text-xs tw-italic'>Назначил: {actionItem?.author.first_name} {actionItem?.author.last_name} {actionItem?.author.email}</p>
                <p className='tw-text-xs tw-italic'>Юр. лицо: {company?.abbreviation ? company?.abbreviation : company?.name}</p>            
            </div>
            { actionItem.data['action_is_done'].value && actionItem.data['comment'].value && actionItem.data['comment'].value !== '' &&
                <div
                    className={`tw-cursor-help ${actionItem.record_id !== plannerStore.selectedAction?.record_id && 'tw-text-teal-600'}`}
                    data-tooltip-id="planner-table-tooltip" data-tooltip-html={'Комментарий к выполнению:<br/>' + getFieldValue(actionItem.data['comment'])} data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <InformationCircleIcon className='tw-w-8 tw-h-8' aria-hidden='true'/>
                </div>
            }
        </div>
    )
}

export default observer(PlannedActionItem)