import React, { createRef, useContext, useEffect, useState } from 'react'
import CompanyItem from './CompanyItem'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import { scrollElementIntoView } from '../../../functions/scrollElementIntoView'
import SearchInput from '../../search/SearchInput'
import FilterStore from '../../../store/FilterStore'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'

/**
 * Визуальный компонент отображает список существующих компаний
 *
 */
const CompanyList = () => {
    const { AdminStore } = useContext(Context)
    const [filterStore] = useState(() => new FilterStore())
    const [searchResults, setSearchResults] = useState(AdminStore.companies)
    const [elRefs, setElRefs] = useState([]);

    let className = `tw-flex tw-flex-col tw-h-[calc(100%_-_6rem)] tw-w-full tw-overflow-auto`,
        searchIsDisabled = false

    if (AdminStore.isSubCompanyCreating || AdminStore.isSubCompanyEditing || AdminStore.isUserCreating || AdminStore.isUserEditing) {
        className += ` tw-pointer-events-none tw-opacity-50`
        searchIsDisabled = true
    }
    
    const handleChange = () => {
        if (filterStore.searchField !== "") {
            let searchArray = filterStore.searchField.split(" ")
            const results = AdminStore.companies.filter(company => {
                let matches = 0
                searchArray.forEach(item => {
                    if (company.name.toLowerCase().includes(item.trim().toLowerCase())) {
                        matches++
                    }
                })
                if (matches === searchArray.length) {
                    return true
                }
            })
            setSearchResults(results)
        } else {
            setSearchResults(AdminStore.companies)
        }
    }

    useEffect(() => {
        if (AdminStore.selectedCompany?.id) {
            AdminStore.getCompanyUsers(AdminStore.selectedCompany.id)
            scrollElementIntoView(AdminStore.selectedCompany, 'system-company-item-')
        }
    }, [AdminStore.selectedCompany])

    useEffect(() => {
        setElRefs((elRefs) =>
            Array(searchResults.length).fill().map((_, i) => elRefs[i] || createRef()),
        )
    }, [searchResults])

    useEffect(() => {
        setSearchResults(AdminStore.companies)
    }, [AdminStore.companies])

    return (
        <>
            <div className='tw-p-2'>
                <SearchInput
                    isDisabled={searchIsDisabled}
                    filterStore={filterStore}
                    selectedFullDataModel={{id: "companies", fields: []}}
                    updateDataObjectList={handleChange}
                />
            </div>
            <div id='system-company-list-items' className={className}>
                {searchResults.slice().sort((a) => a.is_active ? -1 : 1).map((company, index) => 
                    <div key={company.id} id={`system-company-item-${company.id}`} className="tw-w-full tw-pt-0.5">
                        <Disclosure defaultOpen={AdminStore.selectedCompany && AdminStore.selectedCompany.id === company.id }>
                            {({ open, close }) => (
                                <>
                                    <Disclosure.Button className={`tw-flex tw-w-full tw-justify-between tw-items-center tw-bg-gray-300 tw-px-4 tw-border-y tw-py-1 tw-text-left 
                                        tw-text-sm tw-font-medium tw-text-gray-900 tw-border-gray-300 focus:tw-outline-none 
                                        focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-75
                                        ${!company.is_active && 'tw-line-through tw-text-red-400'}
                                        ${AdminStore.selectedCompany && AdminStore.selectedCompany.id === company.id && 'tw-bg-gray-400'}`}
                                    >
                                        <span>{company.name}</span>
                                        <span>
                                            <ChevronUpIcon className={`${open ? 'tw-rotate-180 tw-transform' : ''} tw-h-5 tw-w-5 tw-text-gray-500`}/>
                                        </span>
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="tw-pl-4 tw-pt-0.5 tw-pb-2">
                                        <CompanyItem
                                            item={company}
                                            elements={elRefs}
                                        />
                                    </Disclosure.Panel>

                                    <button                     // скрытая кнопка для автоматического сворачивания неактивных компаний
                                        ref={elRefs[index]}
                                        data-id={company.id}
                                        data-open={open}
                                        className='tw-hidden'
                                        onClick={(e) => {e.preventDefault(); close(elRefs[index])}}
                                    ></button>
                                </>
                            )}
                        </Disclosure>
                    </div>
                )}
            </div>
        </>
    )
}

export default observer(CompanyList)