import React, { useContext } from 'react'
import { Context } from '../../../../../index'
import { getFieldDefaultValue, getReferenceTypeName, getValueTypeName } from '../../../../../config/constTypes'


/**
 * Визуальный компонент отображает информацию о выбранном поле
 * 
 * @param {Object} field Выбранное поле
 * 
 */
const DataModelFieldInfo = ({field}) => {
    const { docStore } = useContext(Context)

    return (
        <div id='data-model-field-info' className='tw-w-full tw-min-w-fit tw-h-full tw-rounded-md tw-bg-white'>
            <div className='tw-h-12 tw-text-md tw-font-semibold tw-py-3 tw-px-4 tw-border-b-2 tw-border-gray-400'>
                Информация о поле
            </div>
            <dl>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Название</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{field.alias}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Тип</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                        {getValueTypeName(field)}
                    </dd>
                </div>
                { (field.type === 'reference' || field.type === 'include') &&
                    <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Таблица</dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                            {getReferenceTypeName(docStore.dataModels, field.ref_model_ids)}
                        </dd>
                    </div>
                }
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Уникальное</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{field.unique ? 'Да' : 'Нет'}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Обязательное</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{field.mandatory ? 'Да' : 'Нет'}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Видимость</dt>
                    <dd className={`${field.hide ? 'tw-text-red-500' : 'tw-text-gray-900'} tw-mt-1 tw-text-sm sm:tw-col-span-2 sm:tw-mt-0`}>
                        {field.hide ? 'Скрыто' : 'Отображается'}
                    </dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Значение по умолчанию</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{getFieldDefaultValue(field)}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Описание</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{field.description}</dd>
                </div>
            </dl>
        </div>
    )
}

export default DataModelFieldInfo
