import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { XMarkIcon } from '@heroicons/react/20/solid'

/**
 * Визуальный компонент отображает панель выбора периода времени для отчета
 * 
 * @param {Date} fromDate Дата начала периода
 * @param {Date} tillDate Дата конца периода
 * @param {Function} onFromDateChange Обработчик выбора даты начала периода
 * @param {Function} onTillDateChange Обработчик выбора даты конца периода
 * @param {Function} onFromDateClear Обработчик сброса даты начала периода
 * @param {Function} onTillDateClear Обработчик сброса даты конца периода
 * 
 */
const ReportPeriodPanel = ({fromDate, tillDate, onFromDateChange, onTillDateChange, onFromDateClear, onTillDateClear}) => {
    return (
        <div className='tw-w-full tw-my-4 tw-flex tw-flex-col'>
        <label className='tw-text-sm tw-text-gray-900'>Период:</label>
        <div className='tw-w-full tw-mt-0.5 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
            <label className='tw-w-8 tw-text-sm tw-text-gray-900'>с</label>
            <div className='tw-w-48'>
                <ReactDatePicker
                    className={`tw-px-2 tw-py-1 tw-mt-1 tw-text-sm tw-border tw-rounded-md
                        tw-text-gray-900 tw-border-gray-400 disabled:tw-text-gray-300 disabled:tw-border-gray-300
                        focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white 
                        focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-offset-gray-400
                    `}
                    calendarStartDay={1}
                    dateFormat='dd.MM.yyyy'
                    timeFormat='HH:mm:ss'
                    locale='ru'
                    timeCaption='Время'
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect={false}
                    dropdownMode="select"
                    selected={fromDate}
                    onChange={onFromDateChange}
                />
            </div>
            <button 
                type='button'
                className='tw-rounded-md tw-p-0.5 tw-ml-1 tw-border-0 tw-text-gray-600 tw-bg-white hover:tw-text-gray-900 hover:tw-border-gray-200 hover:tw-bg-gray-200'
                onClick={onFromDateClear}
            >
                <XMarkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
        </div>
        <div className='tw-w-full tw-mt-0.5 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
            <label className='tw-w-8 tw-text-sm w-text-gray-900'>по</label>
            <div className='tw-w-48'>
                <ReactDatePicker
                    className={`tw-px-2 tw-py-1 tw-mt-1 tw-text-sm tw-border tw-rounded-md
                        tw-text-gray-900 tw-border-gray-400 disabled:tw-text-gray-300 disabled:tw-border-gray-300
                        focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white 
                        focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-offset-gray-400
                    `}
                    calendarStartDay={1}
                    dateFormat='dd.MM.yyyy'
                    timeFormat='HH:mm:ss'
                    locale='ru'
                    timeCaption='Время'
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect={false}
                    dropdownMode="select"
                    selected={tillDate}
                    onChange={onTillDateChange}
                />
            </div>
            <button 
                type='button'
                className='tw-rounded-md tw-p-0.5 tw-ml-1 tw-border-0 tw-text-gray-600 tw-bg-white hover:tw-text-gray-900 hover:tw-border-gray-200 hover:tw-bg-gray-200'
                onClick={onTillDateClear}
            >
                <XMarkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
        </div>
    </div>
)
}

export default ReportPeriodPanel