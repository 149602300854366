import { useContext } from 'react'
import { Context } from '../../../..'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { observer } from 'mobx-react-lite'
import DataModelPermissionsItem from './DataModelPermissionsItem'
import DataSwitch from '../../../main_page/controller/common/inputs/DataSwitch'


/**
 * Визуальный компонент отображает список существующих категорий таблиц для отображения прав по таблицам
 * 
 * @param {Object} category Категория таблиц
 * @param {Number} categoryIndex Индекс категории в массиве
 * 
 */
const CategoryPermissionsItem = ({category, categoryIndex}) => {
    const { RolesStore } = useContext(Context)

    const filteredDataModels = category.id === '0'
                ?   RolesStore.dataModelPermissions.filter(dataModel => !dataModel.categories.length)
                :   RolesStore.dataModelPermissions.filter(dataModel => dataModel.category_ids.includes(category.id))

    const isShowControls = RolesStore.isPermissionsEditing
    const isAllRead = filteredDataModels.every(dataModel => RolesStore.checkDataModelRights(dataModel, 'read'))
    const isAllWrite = filteredDataModels.every(dataModel => RolesStore.checkDataModelRights(dataModel, 'write'))
    const isAllEdit = filteredDataModels.every(dataModel => RolesStore.checkDataModelRights(dataModel, 'edit'))
    const isAnyRead = filteredDataModels.some(dataModel => RolesStore.checkDataModelRights(dataModel, 'read'))
    const isAnyWrite = filteredDataModels.some(dataModel => RolesStore.checkDataModelRights(dataModel, 'write'))
    const isAnyEdit = filteredDataModels.some(dataModel => RolesStore.checkDataModelRights(dataModel, 'edit'))

    const isOpen = RolesStore.isCategoryOpened[categoryIndex]
    const isAnyOpened = RolesStore.isCategoryOpened.some(item => item)

    const handleCategoryClick = (currentIndex) => {
        const openedCategories = RolesStore.isCategoryOpened.map((categoryState, index) =>
            index === currentIndex
                ?   !categoryState
                :   categoryState
        )
        RolesStore.setIsCategoryOpened(openedCategories)
    }

    return (
        <div className="tw-w-full">
            <>
                <div className={`tw-grid tw-grid-cols-6 tw-w-full tw-h-12
                        tw-text-left tw-text-sm 'tw-font-medium tw-text-gray-900 
                        ${isAnyOpened ? 'tw-pr-0' : 'tw-pr-4'} tw-py-2 tw-border-b tw-bg-gray-100 hover:tw-bg-gray-300 tw-border-gray-300 
                        focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-75`}>
                    <div
                        className='tw-col-span-3 tw-flex tw-flex-row tw-justify-start tw-items-center tw-pl-2 tw-py-1 hover:tw-cursor-pointer'
                        onClick={() => handleCategoryClick(categoryIndex)}
                    >
                        { isOpen
                            ?   <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            :   <ChevronRightIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        }                    
                        <span className='tw-select-none'>{category.name}</span>
                    </div>
                    <div className='tw-col-span-1 tw-justify-self-end tw-min-w-32'>
                        { isShowControls
                            ?
                                <DataSwitch
                                    isChecked={isAnyRead}
                                    onClick={() => RolesStore.setCategoryRights(filteredDataModels, 'read', !isAnyRead)}
                                    isThirdState={!isAllRead && isAnyRead}
                                />
                            :   <div className='tw-text-center'>{}</div>
                        }
                    </div>
                    <div className='tw-col-span-1 tw-justify-self-end tw-min-w-32'>
                        { isShowControls
                            ?
                                <DataSwitch
                                    isChecked={isAnyWrite}
                                    onClick={() => RolesStore.setCategoryRights(filteredDataModels, 'write', !isAnyWrite)}
                                    isThirdState={!isAllWrite && isAnyWrite}
                                />
                            :   <div className='tw-text-center'>{}</div>
                        }
                    </div>
                    <div className='tw-col-span-1 tw-justify-self-end tw-min-w-32'>
                        { isShowControls
                            ?
                                <DataSwitch
                                    isChecked={isAnyEdit}
                                    onClick={() => RolesStore.setCategoryRights(filteredDataModels, 'edit', !isAnyEdit)}
                                    isThirdState={!isAllEdit && isAnyEdit}
                                />
                            :   <div className='tw-text-center'>{}</div>
                        }
                    </div>
                </div>
                { isOpen &&
                    <div className="tw-pt-0.5">
                        {  filteredDataModels.map((dataModel, index) =>
                                <div key={index} className='tw-w-full'>
                                    <DataModelPermissionsItem
                                        item={dataModel}
                                    />
                                </div>
                        )}
                    </div>
                }
            </>
        </div>
    )
}

export default observer(CategoryPermissionsItem)