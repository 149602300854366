import React from 'react'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ForwardIcon } from '@heroicons/react/20/solid'

/**
 * Визуальный компонент отображает меню навигации по страницам документа
 * 
 * @param {Number} pages Общее количество страниц в документе
 * @param {Number} activePage Значение номера текущей страницы документа
 * @param {Number} lastPage Значение номера последней просмотренной страницы документа
 * @param {Function} onPageChange Обработчик клика мыши на кнопках перехода к предыдущей/следующей странице
 * @param {Function} onRewindClick Обработчик клика мыши на кнопке перехода к последнему просмотренному фрагменту видео
 * 
 */
const PDFNavigationMenu = ({pages, activePage, lastPage, onPageChange, onRewindClick}) => {
    return (
        <div id='navigation-menu' className='tw-h-full tw-px-4 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-4 tw-border-l tw-border-gray-300'>
            <button
                type='button'
                className='tw-w-12 lg:tw-w-36 tw-flex tw-flex-row tw-justify-start tw-items-center lg:tw-gap-x-2 tw-rounded-md tw-border-2
                        tw-px-2.5 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  
                        tw-bg-gray-700 tw-text-white hover:tw-bg-gray-500 disabled:tw-bg-gray-300  disabled:tw-border-gray-300
                        focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                onClick={() => onPageChange(false)}
                disabled={activePage === 1}
            >
                <span><ChevronDoubleLeftIcon className='tw-w-5 tw-h-5' aria-hidden='true'/></span>
                <span className='tw-invisible lg:tw-visible'>Предыдущая</span>
            </button>
            <div className='tw-py-1 tw-text-sm tw-text-center'>
                Страница {activePage} из {pages}
            </div>
            <button
                type='button'
                className='tw-w-12 lg:tw-w-36 tw-flex tw-flex-row tw-justify-start tw-items-center lg:tw-gap-x-2 tw-rounded-md tw-border-2
                        tw-px-2.5 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  
                        tw-bg-gray-700 tw-text-white hover:tw-bg-gray-500 disabled:tw-bg-gray-300  disabled:tw-border-gray-300
                        focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                onClick={() => onPageChange(true)}
                disabled={activePage === pages}
            >
                <span><ChevronDoubleRightIcon className='tw-w-5 tw-h-5' aria-hidden='true'/></span>
                <span className='tw-invisible lg:tw-visible'>Следующая</span>
            </button>
            <button
                className='tw-flex tw-flex-row tw-items-start tw-gap-x-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold
                            tw-border-gray-700 tw-text-gray-700 hover:tw-bg-gray-200
                            disabled:tw-text-gray-300 disabled:tw-border-gray-300 disabled:tw-bg-white
                        focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                disabled={activePage === lastPage}
                onClick={onRewindClick}
            >
                <ForwardIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
            </button>
        </div>
    )
}

export default PDFNavigationMenu