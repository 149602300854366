/**
 * Константы, используемые при импорте данных с сервера домена
 * @module ImportConsts
 */

/**
 * Реализованные протоколы
 */
export const protocolTypes = [
    { name: 'LDAP', value: 'ldap', hidden: false},
    { name: 'LDAPS', value: 'ldaps', hidden: true},
]

/**
 * Контроллеры доменов
 */
export const domainControllers = [
    { name: 'Windows Active Directory', value: 'WindowsAD', hidden: false},
    { name: 'SAMBA', value: 'SAMBA', hidden: true},
    { name: 'Astra Linux Domain', value: 'ALD', hidden: false },
]

/**
 * Типы импортируемых данных
 */
export const dataTypes = [
    { name: 'Пользователи', value: 'users', hidden: false },
    { name: 'Компьютеры', value: 'hosts', hidden: true },
    { name: 'Пользователи + Компьютеры', value: 'users, hosts', hidden: true },
]

/**
 * Типы файлов, из которых возможен импорт данных
 */
export const fileTypes = ['.xlsx', '.xls', '.ods']
