import React, { useState, useEffect, useCallback } from 'react'
import { ChevronDoubleUpIcon, ChevronDownIcon, ChevronUpIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

/**
 * Визуальный компонент отображает шапку вложенной таблицы
 * 
 * @param {String} dataModelID id вложенной таблицы
 * @param {Object} table Ссылка на HTML-компонент таблицы
 * @param {String} sizes Размеры колонок таблицы в пикселах
 * @param {Object[]} columns Ссылки на HTML-компоненты колонок таблицы
 * @param {Object[]} values Размеры колонок таблицы в пикселах
 * @param {String} sortingColumn Название колонки таблицы (tech_name), по которой выполняется сортировка записей таблицы
 * @param {String} sortingDirection Направление сортировки записей таблицы
 * @param {Function} onSortClick Обработчик клика мыши на элементе выбора сортировки
 * @param {Boolean} isDocumentPage Признак нахождения на странице документов
 * @param {Object} header Ссылка на HTML-компонент заголовка таблицы
 * @param {Boolean} isVerticalScroll Признак вертикальной прокрутки
 * @param {Boolean} isMinScrollYPosition Признак минимальной вертикальной прокрутки
 * @param {Function} scrollTableUp Обработчик клика мыши для прокрутки к началу таблицы
 * 
 */
const NestedModelHeader = ({dataModelID, table, sizes, columns, values, sortingColumn, sortingDirection, onSortClick, isDocumentPage, header, isVerticalScroll, isMinScrollYPosition, onScrollUp}) => {
    const [sizingColumnIndex, setSizingColumnIndex] = useState(null)
    const [changedValues, setChangedValues] = useState(null)
    const [offsetX, setOffsetX] = useState(0)

    const isChangeable = values.length > 2
    let sizeValues = changedValues || values

    const mouseDown = (index) => (e) => {
        if (isChangeable) {
            setSizingColumnIndex(index)
            const offset = e.clientX - sizeValues[index]
            setOffsetX(offset)    
        }
    }

    const mouseMove = useCallback((e) => {
        const minCellWidth = 100

        if (isChangeable) {
            const gridColumns = columns.map((column, index) => {
                if (index === sizingColumnIndex) {
                    const width = e.clientX - offsetX
                    if (width >= minCellWidth) {
                        return width
                    }
                }
                return sizeValues[index] ? sizeValues[index] : minCellWidth
            })

            sizeValues = gridColumns
            const gridTemplateColumns = `${gridColumns.map(item => item + 'px').join(" ")}`
            header.current.style.gridTemplateColumns = gridTemplateColumns
            table.current.childNodes[0].childNodes.forEach(row =>
                row.style.gridTemplateColumns = gridTemplateColumns
                )    
        }
    }, [sizingColumnIndex, columns, offsetX])

    
    const removeListeners = useCallback(() => {
        window.removeEventListener("mousemove", mouseMove)
        window.removeEventListener("mouseup", mouseUp)
    }, [mouseMove])

    
    const mouseUp = useCallback(() => {
        if (table.current) {
            localStorage.setItem('columns_nested_' + dataModelID, JSON.stringify(sizeValues))
            setChangedValues(sizeValues)
        }
        removeListeners()
        setSizingColumnIndex(null)
        setOffsetX(0)
    }, [setSizingColumnIndex, setOffsetX, removeListeners])

    

    useEffect(() => {
        if (sizingColumnIndex !== null) {
            window.addEventListener("mousemove", mouseMove)
            window.addEventListener("mouseup", mouseUp)
        }
    
        return () => {
            removeListeners()
        }
    }, [sizingColumnIndex, mouseMove, mouseUp, removeListeners])


    return (
        <div id='nested-table-header' className='tw-w-full tw-flex tw-flex-row tw-h-6 tw-bg-white'>
            <div 
                style={{
                    display: 'grid', 
                    gridTemplateColumns: sizes, 
                    borderTopWidth: '1px',
                    borderBottomWidth: '1px',
                    borderColor: 'rgb(156 163 175)',
                    overflow: 'hidden',
                    flexGrow: '1',
                }}
                ref={header}
            >
                { columns.map(({text, column, info, type}, index) => 
                    !index
                    ?
                        <div
                            key={index}
                            className={`tw-text-sm tw-font-semibold tw-pl-3 tw-py-0.5 ${!isDocumentPage ? 'tw-border-x' : 'tw-border-r'} tw-border-gray-400`}
                        >
                            {text}
                        </div>
                    :
                        <div
                            key={index}
                            className={`tw-relative tw-group tw-overflow-hidden tw-truncate tw-border-r
                                        tw-text-left tw-text-sm tw-font-semibold tw-pl-5 tw-pr-6 tw-py-0.5 tw-border-gray-400`}
                        >
                            <span
                                className={`tw-absolute tw-left-0 tw-inset-y-auto group-hover:tw-opacity-50 
                                            ${sortingColumn ? sortingColumn === column ? 'tw-opacity-100' : 'tw-opacity-0' : 'tw-opacity-50'}
                                        `}
                                onClick={() => onSortClick(column, type)}
                            >
                                { sortingDirection === 'up'
                                    ?   <ChevronUpIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                    :   <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                }
                            </span>
                            <span 
                                data-tooltip-id="nested-model-panel-tooltip"
                                data-tooltip-content={text}
                                data-tooltip-delay-show={1000}
                            >
                                {text}
                            </span>
                            { info &&
                                <span
                                    data-tooltip-id="nested-model-panel-tooltip"
                                    data-tooltip-content={info}
                                    data-tooltip-delay-show={1000}
                                >
                                    
                                    <InformationCircleIcon className="tw-h-5 tw-w-5 tw-absolute tw-top-0 tw-right-1 tw-text-gray-500 tw-cursor-help" aria-hidden="true"/>
                                </span>
                            }
                            { isChangeable &&
                                <span 
                                    className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-rounded-md tw-w-0.5 tw-cursor-col-resize'
                                    onMouseDown={mouseDown(index)}
                                >
                                </span>
                            }
                        </div>
                )}
            </div>
            { isVerticalScroll &&
                <button
                    type='button'
                    className='tw-w-4 tw-px-0 tw-py-1 tw-bg-gray-100 hover:tw-bg-gray-300 disabled:tw-text-gray-400 disabled:tw-bg-gray-100'
                    onClick={onScrollUp}
                    disabled={isMinScrollYPosition}
                >
                    <ChevronDoubleUpIcon className='tw-w-4 tw-h-4' aria-hidden='true'/>
                </button>
            }
        </div>
    ) 
}   
        
export default NestedModelHeader