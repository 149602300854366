import { $confHost } from '../http/http'
import { configurationAPI } from '../config/constsURN'

/**
 * Класс реализует API для работы с конфигурациями системы
 * @class
 */
class ConfigurationService {

    /**
     * Отправляет запрос на обновление конфигурации
     * @static
     * @param {String} refresh Токен обновления (refresh-token)
     * @returns {Promise} Axios.response
     */
    static async updateConfiguration(force) {
        return $confHost.post(configurationAPI.UPDATE_API, { force })
    }

    /**
     * Отправляет запрос на получение информации о конфигурации
     * @static
     * @returns {Promise} Axios.response
     */
    static async getConfiguration() {
       return $confHost.get(configurationAPI.VERSION_API)
    }

    /**
     * Отправляет запрос на выгрузку пакета с конфигурацией на сервер
     * @static
     * @param {Object} packageFile Пакет с конфигурацией
     * @returns {Object} Метаинформация о файле
     */
    static async uploadConfiguration(packageFile) {
        const response = await $confHost.post(configurationAPI.UPLOAD_API, packageFile, { headers: { 'Content-Type': 'multipart/form-data' }})
        return response
    }
}

export default ConfigurationService