import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { getReferenceValue } from '../../../../../config/constTypes'

/**
 * Визуальный компонент отображает поле выбора записи таблицы из выдвигающегося компонента, реализующего логику работы со структурами таблиц (моделью данных)
 * 
 * @param {Object} value Значение поля 
 * @param {Function} onChange Обработчик клика мыши при изменении значения поля {(e) => field.onChange(e)}
 * @param {Function} onSelectClick Обработчик клика мыши при выборе записи
 * @param {Function} onClearClick Обработчик клика мыши для очистки поля
 * @param {Boolean} isNested Признак нахождения поля в ячейке вложенной таблицы
 * @param {Boolean} isRowSelected Признак выделения строки вложенной таблицы
 * @param {Boolean} isCellSelected Признак выделения ячейки вложенной таблицы
 * @param {Boolean} readOnly Признак доступности поля только для чтения
 * @param {Boolean} isError Признак ошибки
 * 
 */
const DataReferenceInput = ({value, onChange, onSelectClick, onClearClick, isNested, isRowSelected, isCellSelected, readOnly, isError}) => {
    return  (
        <div className={`${isNested ? 'tw-mt-1' : 'tw-rounded-md tw-shadow-sm'} tw-relative`}>
            <input
                type='text'
                className={`${!isNested && `tw-h-8 tw-rounded-md tw-border-0 tw-py-1.5 
                        tw-ring-1 tw-ring-inset tw-ring-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 focus-visible:tw-border-gray-600
                        focus-visible:tw-ring-2 focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2
                        ${isError ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                    `}
                    tw-text-sm tw-w-full tw-text-gray-900 focus-visible:tw-outline-none tw-px-2 tw-overflow-hidden tw-truncate
                    ${!readOnly && 'tw-pr-12'}
                    ${isRowSelected && 'tw-bg-gray-300'}
                `}
                value={typeof value === 'string' ? value : getReferenceValue(value.value)}
                readOnly={true}
                onChange={onChange}
            />
             
            {!readOnly && isCellSelected &&
                <div className='tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 hover:tw-cursor-pointer'>
                    { value.value?.values?.length
                        ? <XMarkIcon onClick={onClearClick} className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        : <></>
                    }
                    <MagnifyingGlassIcon onClick={onSelectClick} className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </div>
            }
        </div>
    )
}

export default DataReferenceInput