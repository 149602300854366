import React, { useContext } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { observer } from "mobx-react-lite";
import { Context } from "../../../../../..";

/**
 * Визуальный компонент отвечает за сортировку строк в таблице с результатами попыток теста
 * 
 * @param {String} title Заголовок столбца сортировки
 * @param {String} name Название столбца сортировки
 * 
 */
const TestAttemptsSort = ({title, name}) => {
    const { EducationStore, userStore } = useContext(Context)
    
    const changeSortValue = ()=> {
        let sortValue = EducationStore.sortDirection ? false : true;
    
        EducationStore.loadSelectedTestAttempts(name, sortValue, userStore.user.id)
        EducationStore.setSort(name, sortValue)
    }

    return (
        <div onClick={changeSortValue} className="tw-cursor-pointer tw-flex tw-justify-center" >
            {EducationStore.sortDirection
                ?  <ChevronUpIcon 
                        className={`tw-w-5 tw-h-5 group-hover:tw-opacity-50 
                            ${EducationStore.columnName ? EducationStore.columnName === name ? 'tw-opacity-100' : 'tw-opacity-0' : 'tw-opacity-50'}
                        `} aria-hidden='true'
                    />
                :  <ChevronDownIcon 
                        className={`tw-w-5 tw-h-5 group-hover:tw-opacity-50 
                            ${EducationStore.columnName ? EducationStore.columnName === name ? 'tw-opacity-100' : 'tw-opacity-0' : 'tw-opacity-50'}
                        `} aria-hidden='true'
                    />
            }
            {title}
        </div>
    )
}

export default observer(TestAttemptsSort)