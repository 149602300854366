import React, { useContext, useEffect, useState } from 'react'
import { paths } from '../../config/constsURN'
import { Context } from '../..'
import { medoedModules, moduleTypes } from '../../config/constTypes'
import ModuleGroup from './controller/summary/ModuleGroup'
import { observer } from 'mobx-react-lite'
import PageTitle from './controller/common/panels/PageTitle'
import Instructions from './controller/summary/Instructions'
import Footer from '../footer/Footer'
import SignInModuleItem from './controller/summary/SignInModuleItem'
import CalendarPanel from './controller/summary/CalendarPanel'
import { checkActiveModule, checkActiveEducationModule } from '../../functions/checkActiveModule'


/**
 * Визуальный компонент отображает стартовую страницы приложения
 *
 */
const MainPageContainer = () => {
    const { userStore, plannerStore } = useContext(Context)

    const [moduleGroups, setModuleGroups] = useState(moduleTypes)
    const [lastRecords, setLastRecords] = useState([])

    const sortModules = async (isAuth) => {
        let updatedTypes = []

        if (isAuth && userStore.modulesPermissions) {
            let checkedModules = []

            if (userStore.modulesPermissions['kii_only'])
                checkedModules.push(await checkActiveModule(medoedModules, paths.CATEGORIZING_CII_ROUTE))
    
            if (userStore.modulesPermissions['pdn_only'])
                checkedModules.push(await checkActiveModule(medoedModules, paths.PERSONAL_DATA_ROUTE))
            
            if (userStore.modulesPermissions['edu_only'])
                checkedModules.push(await checkActiveEducationModule(medoedModules, paths.EDUCATION_ROUTE))
            
            const activeModulesRecords = checkedModules.filter(item => item !== null)
            const usedModules = medoedModules.filter(module => activeModulesRecords.find(active => active.link === module.link))
            const availableModules = medoedModules.filter(module => ['available'].includes(module.state) && !activeModulesRecords.find(active => active.link === module.link))
            const activeModules = usedModules.concat(availableModules)
            const developedModules = medoedModules.filter(module => ['plan'].includes(module.state))

            const activeGroup = moduleTypes.find(type => type.value === 'available')
            updatedTypes.push({...activeGroup, modules: activeModules})
            const plannedGroup = moduleTypes.find(type => type.value === 'plan')
            updatedTypes.push({...plannedGroup, modules: developedModules})

            setLastRecords(activeModulesRecords)
        } else {
            const availableModules = medoedModules.filter(module => ['available', 'unavailable'].includes(module.state))
            const developedModules = medoedModules.filter(module => ['plan'].includes(module.state))
            updatedTypes = moduleTypes
                    .filter(type => ['available', 'plan'].includes(type.value))
                    .map(type => {
                        switch (type.value) {
                            case 'available': return {...type, modules: availableModules}
                            case 'plan': return {...type, modules: developedModules}
                            default: return type
                        }
                    })
            setLastRecords([])
        }

        setModuleGroups(updatedTypes)
    }

    const getUserPlannedActions = async () => {
        if (userStore.modulesPermissions) {
            if (userStore.modulesPermissions['kii_only']) {
                const today = new Date()
                const nextMonth = today.setMonth(today.getMonth() + 1)
                const addOnFilter = [
                    {property: 'data["responsible_user_email"].value', value: userStore.user.email, operator: 'eq'},
                    {property: 'data["action_is_done"].value', value: true, operator: 'neq'},
                    {property: 'data["action_completion_date"].value', value: nextMonth, operator: 'le'},
                ]
                plannerStore.getActions('planned_actions', addOnFilter, false)
            }
        }
    }

    useEffect(() => {
        sortModules(userStore.isAuth)

        if (userStore.isAuth) {
            getUserPlannedActions()
        } else {
            plannerStore.setPlannedActions([])
        }
    }, [userStore.isAuth, userStore.modulesPermissions])

    return (
        <>
            <PageTitle title={'Главная'} />
            <div className='tw-flex tw-flex-col lg:tw-h-full'>
                <div className='tw-grow tw-mx-auto tw-px-4 tw-pb-8 tw-flex tw-flex-col lg:tw-h-full'>
                    <div className='tw-grow lg:tw-grid lg:tw-grid-cols-6 lg:tw-gap-x-12 lg:tw-gap-y-8'>
                        { userStore.isAuth &&
                            <div className='lg:tw-col-span-2 tw-h-60 lg:tw-h-full tw-mt-6 lg:tw-mt-0 tw-overflow-hidden'>
                                <CalendarPanel/>
                            </div>
                        }
                        <div className={`${!userStore.isAuth ? 'lg:tw-col-span-4' : 'lg:tw-col-span-2'}`}>
                            <div className={`${!userStore.isAuth ? 'sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-6 lg:tw-gap-12' : 'tw-flex tw-flex-col tw-gap-8'}`}>
                                { moduleGroups.map((group, index) =>
                                    <ModuleGroup
                                        key={index}
                                        group={group}
                                        records={lastRecords}
                                    />
                                )}
                            </div>
                        </div>
                        <div className='lg:tw-col-span-2 tw-mt-6 lg:tw-mt-0'>
                            { !userStore.isAuth && 
                                <div className='tw-mb-6 lg:tw-mb-8'>
                                    <SignInModuleItem/>
                                </div>
                            }
                            <Instructions/>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default observer(MainPageContainer)
