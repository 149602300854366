import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import SubCompanyUserTableRow from './SubCompanyUserTableRow'
import { Tooltip } from 'react-tooltip'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'

/**
 * Визуальный компонент отображает список пользователей юр лица
 * 
 */
const SubCompanyUserTable = () => {
    const { CompanyAdminStore } = useContext(Context)
  
    const [sortingDirection, setSortingDirection] = useState('down')
    const [sortingColumn, setSortingColumn] = useState('')
    const [sortedList, setSortedList] = useState([])
    const [activeColumn, setActiveColumn] = useState(null)
    const [offsetX, setOffsetX] = useState(0)

    const lastNameColumn = useRef(null)
    const firstNameColumn = useRef(null)
    const emailColumn = useRef(null)
    const columns = [{ref: lastNameColumn}, {ref: firstNameColumn}, {ref: emailColumn}]
    const sizes = "170px 170px 170px"
    const tableElement = useRef(null)
    
    const mouseMove = useCallback((e) => {
        const gridColumns = columns.map((column, index) => {
            if (index === activeColumn) {
                const width = e.clientX - offsetX -  columns[0].ref.current.offsetLeft
                if (width >= 100) {
                    return `${width}px`
                }
            }
            return `${column.ref.current.offsetWidth}px`
        });
        
        const gridTemplateColumns = `${gridColumns.join(" ")}`
        tableElement.current.childNodes[0].childNodes[0].style.gridTemplateColumns = gridTemplateColumns
        tableElement.current.childNodes[1].childNodes.forEach(row =>
            row.style.gridTemplateColumns = gridTemplateColumns)
    }, [activeColumn, columns])
    
    const removeListeners = useCallback(() => {
        window.removeEventListener("mousemove", mouseMove)
        window.removeEventListener("mouseup", mouseUp)
    }, [mouseMove])

    const mouseDown = (index) => (e) => {
        setActiveColumn(index)
        const offset = e.clientX - columns[index].ref.current.offsetWidth - columns[0].ref.current.offsetLeft
        setOffsetX(offset)
    }
    const mouseUp = useCallback(() => {
        if (tableElement.current) {
            sessionStorage.setItem('storedSubCompanyUserTableSizes', tableElement.current.childNodes[0].childNodes[0].style.gridTemplateColumns)
        }
        removeListeners()
        setActiveColumn(null)
        setOffsetX(0)
    }, [setActiveColumn, setOffsetX, removeListeners]) 

    const handleSortClick = (column) => {
        if (column === sortingColumn) {
            if (sortingDirection === 'up') {
                setSortingDirection('down')
            } else {
                setSortingDirection('up')
            }
        }
        setSortingColumn(column)
    }

    useEffect(() => {
        if (activeColumn !== null) {
            window.addEventListener("mousemove", mouseMove)
            window.addEventListener("mouseup", mouseUp)
        }
    
        return () => {
            removeListeners()
        }
    }, [activeColumn, mouseMove, mouseUp, removeListeners])
   
    useEffect(() => {
        if(sortingColumn !== ''){
            setSortedList(CompanyAdminStore.subCompanyUsers.slice().sort((a, b) => {
                const value1 = sortingDirection === 'up' ?  b[sortingColumn] : a[sortingColumn]
                const value2 = sortingDirection === 'up' ? a[sortingColumn] :  b[sortingColumn]
                return value1 < value2 ? -1 : 1 
            }))
        }else{
            setSortedList(CompanyAdminStore.subCompanyUsers.slice().sort((a) => a.is_active ? -1 : 1))
        }
        
    }, [sortingDirection, sortingColumn, CompanyAdminStore.subCompanyUsers])

    useEffect(() => {
        setSortedList(CompanyAdminStore.subCompanyUsers.slice().sort((a) => a.is_active ? -1 : 1))
    }, [])

    return (
        <div className='tw-h-[calc(100%_-_21rem)] tw-overflow-auto tw-border-y'>
            <table ref={tableElement}>
            <thead className='tw-h-10 tw-grid tw-grid-cols-3 tw-gap-4 tw-border-y tw-text-sm tw-font-semibold tw-border-gray-300 tw-py-2'>
                <tr style={{
                    display: 'grid',
                    gridTemplateColumns: sizes
                }}>
                    <th className='tw-group tw-relative tw-truncate tw-px-4' ref={lastNameColumn}>
                        Фамилия
                        <span
                            className={`tw-absolute group-hover:tw-opacity-50 ${sortingColumn === 'last_name' ? 'tw-opacity-50' : 'tw-opacity-0'}`}
                            onClick={() => handleSortClick('last_name')}
                        >
                            { sortingDirection === 'up'
                                ?   <ChevronUpIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                :   <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            }
                        </span>
                        <span 
                            className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-rounded-md tw-bg-gray-500 tw-w-1 tw-cursor-col-resize tw-opacity-30'
                            onMouseDown={mouseDown(0)}
                        >
                        </span>
                    </th>
                    <th className='tw-group tw-relative tw-truncate tw-px-4' ref={firstNameColumn}>
                        Имя
                        <span
                            className={`tw-absolute group-hover:tw-opacity-50 ${sortingColumn === 'first_name' ? 'tw-opacity-50' : 'tw-opacity-0'}`}
                            onClick={() => handleSortClick('first_name')}
                        >
                            { sortingDirection === 'up'
                                ?   <ChevronUpIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                :   <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            }
                        </span>
                        <span 
                            className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-rounded-md tw-bg-gray-500 tw-w-1 tw-cursor-col-resize tw-opacity-30'
                            onMouseDown={mouseDown(1)}
                        >
                        </span>
                    </th>
                    <th className='tw-group tw-relative tw-truncate tw-px-4' ref={emailColumn}>
                        Email
                        <span
                            className={`tw-absolute group-hover:tw-opacity-50 ${sortingColumn === 'email' ? 'tw-opacity-50' : 'tw-opacity-0'}`}
                            onClick={() => handleSortClick('email')}
                        >
                            { sortingDirection === 'up'
                                ?   <ChevronUpIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                :   <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            }
                        </span>
                        <span 
                            className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-rounded-md tw-bg-gray-500 tw-w-1 tw-cursor-col-resize tw-opacity-30'
                            onMouseDown={mouseDown(2)}
                        >
                        </span>
                    </th>
                </tr>
            </thead>
            
            <tbody className='tw-w-full tw-h-[calc(100%_-_21rem)] tw-overflow-auto'>
                { CompanyAdminStore.subCompanyUsers.length === 0 && 
                    <tr className='tw-h-10 tw-w-full tw-text-sm tw-italic tw-text-left tw-px-4 tw-py-8'>
                        <td className='tw-text-sm tw-font-medium tw-px-4 tw-py-1.5'>Пользователи отсутствуют</td>
                    </tr>
                }
                { sortedList.map((user) => 
                    <SubCompanyUserTableRow sizes={sizes} key={user.id} {...user} />
                )}                      
            </tbody>
        </table>  
        <Tooltip id="sub-company-user-table-tooltip" classNameArrow="!tw-left-1/2" place="top-start"/>  
    </div>
)}

export default observer(SubCompanyUserTable)