import { Fragment, useContext, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { FunnelIcon } from '@heroicons/react/20/solid'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import { runInAction } from 'mobx'


/**
 * Визуальный компонент отображает меню фильтров по юр лицу
 * 
 * @param {String} column Поле
 * @param {Object} filterStore Экземпляр хранилища информации о применяемых фильтрах
 * 
 */
const SubCompanyFilterMenu = ({column, filterStore}) => {
    const { FilterStore, userStore } = useContext(Context)
    const filtersStore = filterStore ? filterStore : FilterStore
    const [left, setLeft] = useState(null)
    const columnFilter = filtersStore.filteredFields.find(field => field.name === column.tech_name)

    const onSetFilter = (value) => {
        let filteredFields = filtersStore.filteredFields.map(field => {
                if(field.name === column.tech_name)
                    runInAction(() => {
                        field.active = true
                    })
                return field
            })

        let filters = [{
                property: 'subgroup_id',
                value: value,
                operator: 'eq'
            }]

        filtersStore.setSelectedFilters(filters)
        filtersStore.setFilteredFields(filteredFields)
    }

    return (
        <>
            <Popover 
                className='tw-absolute tw-top-1 tw-right-2 tw-inset-y-auto tw-rounded-md tw-p-0.5 tw-border-0 
                            tw-bg-white tw-text-gray-600 hover:tw-text-gray-900 hover:tw-border-gray-200 hover:tw-bg-gray-200'
            >
                <Popover.Button className={`focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 
                    focus-visible:tw-ring-opacity-50 focus-visible:tw-rounded-sm ${columnFilter && columnFilter.active 
                        ? 'tw-text-sky-600 hover:tw-text-sky-500' : 'tw-text-gray-600 hover:tw-text-gray-900'}
                    `}
                >
                    <FunnelIcon 
                        onClick={(e) => setLeft(window.event.clientX)}
                        className='tw-h-4 tw-w-4' aria-hidden="true"
                    />
                </Popover.Button>
                <Transition
                    as={Fragment}
                    enter="tw-transition tw-ease-out tw-duration-200"
                    enterFrom="tw-opacity-0 tw-translate-y-1"
                    enterTo="tw-opacity-100 tw-translate-y-0"
                    leave="tw-transition tw-ease-in tw-duration-150"
                    leaveFrom="tw-opacity-100 tw-translate-y-0"
                    leaveTo="tw-opacity-0 tw-translate-y-1"
                >
                    <Popover.Panel style={{left: left}} className={`tw-fixed tw-z-10  tw-mt-3 tw-max-w-sm tw--translate-x-full tw-transform tw-px-4 sm:tw-px-2 lg:tw-max-w-3xl`}>
                        <div className="tw-overflow-hidden tw-rounded-lg tw-shadow-xl tw-ring-1 tw-ring-black tw-ring-opacity-20">
                            <div className="tw-flex tw-flex-col tw-justify-start tw-items-center tw-bg-white tw-px-1 tw-py-2 tw-text-sm tw-font-normal">
                                {userStore.userSubCompanies.map(sub_company =>
                                    <button
                                        key={sub_company.id}
                                        className="tw-w-full tw-px-2 tw-py-1 hover:tw-bg-gray-200 tw-text-left 
                                                focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-50"
                                        onClick={() => {onSetFilter(sub_company.id.toString())}}
                                    >
                                        {sub_company.abbreviation ? sub_company.abbreviation : sub_company.name}
                                    </button>
                                )}                      
                                <button
                                    className="tw-w-full tw-px-2 tw-py-1 hover:tw-bg-gray-200 tw-text-left 
                                            focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-50"
                                    onClick={() => {filtersStore.clearSelectedFilter(column.tech_name, column.rule_id)}}
                                >
                                    Сбросить
                                </button>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </Popover>
        </>
    )
}

export default observer(SubCompanyFilterMenu)