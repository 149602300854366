import React, { useContext } from 'react'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'
import StageButtons from '../stage/StageButtons'
import EmployeesDataForm from '../../common/forms/employees/EmployeesDataForm'


/**
 * Визуальный компонент отображает этап заполнения данных о сотрудниках
 * 
 */
const EmployeesForm = () => {   
    const { categorizingCIIStore } = useContext(Context)

    const handleBackClick = () => {
        categorizingCIIStore.goPrevStage()
    }

    const handleForwardClick = () => {
        categorizingCIIStore.goNextStage()  
    }

    return (
        <div className='tw-h-[calc(100%_-_3rem)] tw-flex tw-flex-col'>
            <EmployeesDataForm/>
            <StageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleForwardClick}
                disabled={false}
            />
        </div>                                                             
    )
}

export default observer(EmployeesForm)