import React from 'react'
import { getReferenceTypeName } from '../../../../../config/constTypes'
import { observer } from 'mobx-react-lite'


/**
 * Визуальный компонент отображает название одной печатной формы для списка печатных форм
 * 
 * @param {Object} item Текущий элемент печатных форм 
 * @param {Object} activeItem Выбранный элемент в списке печатных форм
 * @param {Object[]} dataModelsList Список таблиц
 * @param {Function} onClick Обработчик клика мыши на текущем элементе 
 * @param {Function} onDoubleClick Обработчик двойного клика мыши на элементе списка
 * 
 */
const ExporterItem = ({item, activeItem, dataModelsList, onClick, onDoubleClick}) => {
    let className = `tw-grid tw-grid-cols-3 tw-text-sm tw-border-b hover:tw-cursor-pointer`

    if (activeItem && activeItem.id === item.id) {
        className += ` tw-text-white tw-bg-gray-500`
    } else {
        className += ` tw-text-gray-900 tw-bg-white hover:tw-bg-gray-200 hover:tw-border-gray-300`
    }

    return (
        <div 
            id={item.id}
            className={className}
            onClick={() => onClick(item)}
            onDoubleClick={() => onDoubleClick(item)}
        >
            <div className='tw-col-span-2 tw-px-4 tw-py-2 tw-truncate'>
                {item.name}
            </div>
            <div className='tw-px-2 tw-py-2 tw-truncate tw-border-l'>
                {getReferenceTypeName(dataModelsList, item?.meta?.data_model_id)}
            </div>
        </div>
    )
}

export default observer(ExporterItem)