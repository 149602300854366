import React, {useEffect, useState} from 'react'
import { observer } from 'mobx-react-lite'
import { Tab } from '@headlessui/react'
import Spinner from '../../../assets/Spinner'
import RecordNotesTable from './RecordNotesTable'
import { Tooltip } from 'react-tooltip'

/**
 * Визуальный компонент отображает вкладку с сообщениями
 * 
 * @param {Object} recordNotesStore Экземпляр хранилища информации о сообщениях
 * 
 */
const NoteTabPanel = ({recordNotesStore}) => {
    const [notes, setNotes] = useState([])
    const [noteOffset, setNoteOffset] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [isFetchingData, setIsFetchingData] = useState(false)

    const handleDataFetching = (notes, totalCount, offset) => {
        setNotes(notes)
        setNoteOffset(offset)
        setTotalCount(totalCount)
    }

    useEffect(() => {       
        if (recordNotesStore.recordID) {
            recordNotesStore.fetchNotes('created', true, [], 0, handleDataFetching)
        }
    }, [recordNotesStore.recordID])

    useEffect(()=>{
        if (isFetchingData) {
            if (noteOffset < totalCount) {
                recordNotesStore.fetchNotes('created', true, notes, noteOffset, handleDataFetching)
            } else {
                setIsFetchingData(false)
            }
        }
    }, [isFetchingData])

    useEffect(()=>{
        if (!recordNotesStore.isUpdatingNotes && isFetchingData) {  
            setIsFetchingData(false)
        }
    }, [recordNotesStore.isUpdatingNotes])

    return (
        <Tab.Panel
            className='tab-panel tw-overflow-hidden tw-w-full tw-rounded-b-md tw-rounded-tr-md tw-bg-white tw-px-4 tw-py-2 tw-ring-white 
            tw-ring-opacity-60 tw-ring-offset-2 focus:tw-outline-none focus:tw-ring-offset-0 tw-flex tw-flex-col tw-justify-between tw-h-full'
        >
            {!recordNotesStore.isLoading
                ?
                    <>
                        {!recordNotesStore.error && recordNotesStore.recordID && 
                            <RecordNotesTable
                                notes={notes}
                                recordNotesStore={recordNotesStore}
                                onStartFetching={setIsFetchingData}
                                onFetchData={handleDataFetching}
                            />
                        }
                    </>
                :
                    <Spinner/>
            }
            <Tooltip id="record-notes-form-tooltip" className="tw-break-all tw-max-w-lg" classNameArrow="!tw-left-1/2" place="top-start"/>
        </Tab.Panel>
    )
}

export default observer(NoteTabPanel)