import React, { useContext, useEffect, useState } from 'react'
import UsersListItem from './UsersListItem';
import { Context } from '../../../..';
import { observer } from 'mobx-react-lite';
import SearchInput from '../../../search/SearchInput';
import FilterStore from '../../../../store/FilterStore';
import { Tooltip } from 'react-tooltip';

/**
 * Визуальный компонент отображает список существующих пользователей
 * 
 */
const UsersList = () => {
    const { CompanyAdminStore } = useContext(Context)
    const [filterStore] = useState(() => new FilterStore())
    const [searchResults, setSearchResults] = useState([])
    const [users, setUsers] = useState([])

    let className = `tw-flex tw-flex-col tw-h-[calc(100%_-_6rem)] tw-w-full tw-overflow-auto`,
        searchIsDisabled = false

    if (CompanyAdminStore.isSubCompanyCreating || CompanyAdminStore.isSubCompanyEditing || CompanyAdminStore.isUserCreating || CompanyAdminStore.isUserEditing) {
        className += ` tw-pointer-events-none tw-opacity-50`
        searchIsDisabled = true
    }
    
    const handleChange = () => {
        if (filterStore.searchField !== "") {
            let searchArray = filterStore.searchField.split(" ")
            const results = CompanyAdminStore.users.filter(user => {
                let matches = 0
                searchArray.forEach(item => {                
                    if (user.first_name?.toLowerCase().includes(item.trim().toLowerCase()) 
                        || user.last_name?.toLowerCase().includes(item.trim().toLowerCase())
                        || user.email?.toLowerCase().includes(item.trim().toLowerCase())
                    ) {
                        matches++
                    }
                })
                if (matches === searchArray.length) {
                    return true
                }
            })
            setSearchResults(results)
        } else {
            setSearchResults(CompanyAdminStore.users)
        }
    }

    useEffect(() => {
        if (searchResults.length) {
            setUsers([...searchResults])
        } else {
            setUsers([...CompanyAdminStore.users])
        }
    }, [searchResults, CompanyAdminStore.users])

    return (
        <>
            <div className='tw-px-4 tw-py-2 tw-border-b tw-border-gray-300'>
                <SearchInput
                    isDisabled={searchIsDisabled}
                    filterStore={filterStore}
                    selectedFullDataModel={{id: "users", fields: []}}
                    updateDataObjectList={handleChange}
                />
            </div>
            
            <div  id='users-list-items' className={className}>
                { users.slice()
                    .sort((a, b) => a.first_name.toLowerCase() < b.first_name.toLowerCase() ? -1 : 1)
                    .sort((a) => a.is_active ? -1 : 1)
                    .sort((a, b) => a.is_active && (a.first_name.toLowerCase() < b.first_name.toLowerCase()) ? -1 : 1)
                    .map(user => (
                        <UsersListItem 
                            key={user.id}
                            item={user}
                        />
                    ))
                }
            </div>
            <Tooltip id="users-list-tooltip" place="top" style={{ zIndex: 99 }}/>
        </>
    )
}

export default observer(UsersList)