import { useRef } from 'react'
// Функция создает массив заголовков столбцов таблицы для вывода в компоненте
export function createHeaders(headers) {
    return headers.map((item) => ({
      text: item.alias,
      column: item.column,
      type: item.type,
      ref: useRef()
    }))
}

// Функция создает массив заголовков столбцов таблицы для вывода в компоненте
export function createReportHeaders(headers) {
  return headers.map((item) => ({
    text: item.alias,
    column: item.column,
    type: item.type,
    link: item.link,
    is_clickable: item.is_clickable,
    data_model_id: item.data_model_id,
    ref: useRef()
  }))
}
