import React from 'react'
import { DocumentPlusIcon, DocumentDuplicateIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { observer } from 'mobx-react-lite'
import { createEmptyFieldData, createNestedDataObject } from '../../../functions/nestedModels'
import { hideTooltipTimeOut, menuTooltipTimeOut } from '../../../config/constTypes'


/**
 * Визуальный компонент отображает меню редактора вложенных таблиц
 * 
 * @param {Object[]} nestedDataModels Массив вложенных таблиц
 * @param {Object} selectedNestedDataModel Выбранная вложенная таблица
 * @param {Object} selectedNestedDataObject Выбранная запись вложенной таблицы
 * @param {Function} setNestedDataModels Изменение в массиве вложенных таблицы
 * @param {Function} setSelectedNestedDataObject Изменение выбранной записи вложенной таблицы
 * @param {Boolean} isDocumentPage Признак нахождения на странице документов
 * @param {Function} onChange Сторонняя функция для изменений в массиве вложенных таблицы 
 * 
 */
const NestedModelMenu = ({nestedDataModels, selectedNestedDataModel, selectedNestedDataObject, setNestedDataModels, 
                            setSelectedNestedDataObject, isDocumentPage, onChange, setIsAddFormOpen, setIsDuplicateFormOpen, setIsEditFormOpen}) => {
                                
    let isButtonDisabled = !selectedNestedDataObject || !selectedNestedDataModel.fields?.length || selectedNestedDataModel.id === 'indicatorss' 
                            || selectedNestedDataModel?.id !== selectedNestedDataObject?.data_model_id

    const handleAddItemClick = (e) => {
        e.preventDefault()

        const originalNestedModel = nestedDataModels.find(item => item.rule_id === selectedNestedDataModel.rule_id)

        const dataField = createEmptyFieldData(selectedNestedDataModel)

        const editedNestedModel = originalNestedModel.dataObjects.concat([
            createNestedDataObject(dataField, originalNestedModel.dataObjects.length, selectedNestedDataModel)
        ])

        if (selectedNestedDataModel.ui_options?.flags?.line_edit === undefined || selectedNestedDataModel.ui_options?.flags?.line_edit) {
            if (isDocumentPage) {
                if (selectedNestedDataModel.type === "nested") {
                    setNestedDataModels(nestedDataModels.map(item => 
                        item.rule_id === selectedNestedDataModel.rule_id
                            ?   {...item, dataObjects: editedNestedModel}
                            :   item
                    ))
                } else {
                    onChange(originalNestedModel)
                }
            } else {
                onChange(editedNestedModel, selectedNestedDataModel)
            }
        } else {
            setIsAddFormOpen(true)
        }
    }

    const handleDuplicateItemClick = (e) => {
        e.preventDefault()

        const originalNestedModel = nestedDataModels.find(item => item.rule_id === selectedNestedDataModel.rule_id)

        const dataField = JSON.parse(JSON.stringify(selectedNestedDataObject.data))
        const duplicatedDataObject = createNestedDataObject(dataField, originalNestedModel.dataObjects.length, selectedNestedDataModel)

        const editedNestedModel = originalNestedModel.dataObjects.concat([duplicatedDataObject])

        setSelectedNestedDataObject(duplicatedDataObject)

        if (selectedNestedDataModel.ui_options?.flags?.line_edit === undefined || selectedNestedDataModel.ui_options?.flags?.line_edit) {
            if (isDocumentPage) {
                setNestedDataModels(nestedDataModels.map(item => 
                    item.rule_id === selectedNestedDataModel.rule_id
                        ?   {...item, dataObjects: editedNestedModel}
                        :   item
                ))
            } else {
                onChange(editedNestedModel, selectedNestedDataModel)
            }
        } else {
            setIsDuplicateFormOpen(true)
        }
    }

    const handleEditItemClick = (e) => {
        e.preventDefault()
        setIsEditFormOpen(true)
    }

    const handleDeleteItemClick = (e) => {
        e.preventDefault()

        const originalNestedModel = nestedDataModels.find(item => item.rule_id === selectedNestedDataModel.rule_id)

        let editedNestedModel
        if (selectedNestedDataObject && selectedNestedDataObject.status === 'added')
            // вновь добавленная запись удаляется из списка
            editedNestedModel = originalNestedModel.dataObjects.filter(item => item.id !== selectedNestedDataObject.id).map((item, index) => {return {...item, order: index}})
        else
            // у сохраненной записи меняется статус
            editedNestedModel = originalNestedModel.dataObjects.map(item => item.id !== selectedNestedDataObject.id ? item : {...item, status: 'deleted'})

        setSelectedNestedDataObject(null)

        if (isDocumentPage) {
            setNestedDataModels(nestedDataModels.map(item =>
                item.rule_id === selectedNestedDataModel.rule_id
                    ?   {...item, dataObjects: editedNestedModel}
                    :   item
            ))
        } else {
            onChange(editedNestedModel, selectedNestedDataModel)
        }
    }

    return (
        <div className='tw-flex tw-flex-row tw-justify-end tw-items-center tw-space-x-1 tw-pr-2 tw-pb-1 tw-border-b tw-border-gray-400'>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                onClick={handleAddItemClick}
                disabled={!selectedNestedDataModel.fields?.length || selectedNestedDataModel.id === 'indicatorss'}
                data-tooltip-id="nested-menu-tooltip" data-tooltip-content="Создать" data-tooltip-delay-show={menuTooltipTimeOut} data-tooltip-delay-hide={hideTooltipTimeOut}
            >
                <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            {selectedNestedDataModel.type === "nested" &&
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={handleDuplicateItemClick}
                    disabled={isButtonDisabled}
                    data-tooltip-id="nested-menu-tooltip" data-tooltip-content="Дублировать" data-tooltip-delay-show={menuTooltipTimeOut} data-tooltip-delay-hide={hideTooltipTimeOut}
                >
                    <DocumentDuplicateIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
            }
            {!selectedNestedDataModel.ui_options?.flags?.line_edit && selectedNestedDataModel.type === "nested" &&
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={handleEditItemClick}
                    disabled={isButtonDisabled}
                    data-tooltip-id="data-object-menu-tooltip" data-tooltip-content="Редактировать"
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
            }
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                onClick={handleDeleteItemClick}
                disabled={isButtonDisabled}
                data-tooltip-id="nested-menu-tooltip" data-tooltip-content="Удалить" data-tooltip-delay-show={menuTooltipTimeOut} data-tooltip-delay-hide={hideTooltipTimeOut}
            >
                <TrashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <Tooltip id="nested-menu-tooltip" place="top"/>
        </div>
    )
}

export default observer(NestedModelMenu)