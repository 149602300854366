/**
 * Функция осуществляет проверку на дублирующиеся и пустые значения поля таблицы
 * 
 * @param {Object[]} list Массив записей выбранной таблицы
 * @param {String} primaryField Название первого поля выбранной таблицы
 * @param {String} type Тип первого поля выбранной таблицы
 */
export function  checkNestedValueErrors(list, primaryFieldName, isCheckEmpty) {
    let values = []
    let isError = isCheckEmpty ? list.filter(item => item.status !== 'deleted').length === 0 : false

    const checkedList = list.map(nestedValue => {
        const checkedData = {}

        if (nestedValue.status !== 'deleted') {
            for (const [key, field] of Object.entries(nestedValue.data)) {
                let foundError = false
                if (!field.hide) {
                    if (field.type === 'reference') {
                        if (!field.value.values.length) {
                            foundError = true
                        } else if (key === primaryFieldName) {
                            const record_id = field.value.values[0].record_id
                            if (values.includes(record_id)) {
                                foundError = true
                            } else {
                                values = [...values, record_id]
                            }
                        }
                    } else {
                        if (field.value == null || field.value === '' || field.value < 0) {
                            foundError = true
                        } else if (key === primaryFieldName) {
                            const record_value = field.value
                            if (values.includes(record_value)) {
                                foundError = true
                            } else {
                                values = [...values, record_value]
                            }    
                        }    
                    }    
                }

                if (foundError)
                    isError = true

                checkedData[key] = {
                    ...field,
                    error: foundError
                }
            }
            return {...nestedValue, data: checkedData}
        } else {
            return nestedValue
        }            
    })

    return [isError, checkedList]
}
