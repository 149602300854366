import React, { useContext } from 'react'
import { DocumentPlusIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { Context } from '../../../../..'
import { menuTooltipTimeOut } from '../../../../../config/constTypes'
import { observer } from 'mobx-react-lite'
import { useHotkeys } from 'react-hotkeys-hook'


/**
 * Визуальный компонент отображает меню возможных действий над печатными формами
 * 
 * @param {Object} activeItem Выбранный элемент в списке печатных форм
 * @param {Function} onAddItemClick Обработчик клика мыши на кнопке создания новой печатной формы
 * @param {Function} onEditItemClick Обработчик клика мыши на кнопке редактирования выбранной печатной формы
 * @param {Boolean} isDisabled Признак недоступности кнопок меню
 * 
 */
const ExporterMenu = ({activeItem, onAddItemClick, onEditItemClick, isDisabled}) => {
    const { DialogTabStore, userStore } = useContext(Context)

    const isEditionEnabled = activeItem && userStore.checkPermission("export-template:edit")

    useHotkeys('ctrl+a', () => {if (userStore.checkPermission("export-template:edit")) onAddItemClick()}, { preventDefault: true })
    useHotkeys('ctrl+e', () => {if (isEditionEnabled) onEditItemClick(activeItem)}, { preventDefault: true })
    useHotkeys('ctrl+r', () => {if (isEditionEnabled) onDeleteItemClick()}, { preventDefault: true })

    const onDeleteItemClick = () => {
        DialogTabStore.setParentName('ExporterListContainer')
        DialogTabStore.setDialogTabTitle("Удаление шаблона") 
        DialogTabStore.setDialogTabText("Вы уверены, что хотите удалить этот шаблон?")
        DialogTabStore.setDialogTabButtons(["Да", "Нет"])
        DialogTabStore.setDialogTabIsOpen(true)
    }

    return (
        <div id='exporter-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
            <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Шаблоны</p>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onAddItemClick}
                    disabled={!userStore.checkPermission("export-template:edit") || isDisabled}
                    data-tooltip-id="exporter-tooltip" data-tooltip-content="Создать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+A)" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onEditItemClick(activeItem)}
                    disabled={!isEditionEnabled || isDisabled}
                    data-tooltip-id="exporter-tooltip" data-tooltip-content="Редактировать &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+E)" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onDeleteItemClick}
                    disabled={!isEditionEnabled || isDisabled}
                    data-tooltip-id="exporter-tooltip" data-tooltip-content="Удалить &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+R)" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <TrashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
            </div>
            <Tooltip id="exporter-tooltip" className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
        </div>
    )
}

export default observer(ExporterMenu)