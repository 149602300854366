import React, { Fragment, useContext } from 'react'
import { Context } from '../../../../../../..'
import { Dialog, Transition } from '@headlessui/react'
import { observer } from 'mobx-react-lite'
import { XMarkIcon } from '@heroicons/react/24/outline'
import PageTitle from '../../../../common/panels/PageTitle'
import Spinner from '../../../../../../../assets/Spinner'
import { Tooltip } from 'react-tooltip'
import PlannerFilter from './PlannerFilter'
import RecommendedFilter from './RecommendedFilter'


/**
 * Визуальный компонент отображает модальное окно с фильтрами для действий
 * 
 */
const PlannerFiltersContainer = () => {
    const { plannerStore } = useContext(Context)

    const onCloseClick = () => {
        plannerStore.setIsFiltersOpen(false)
    }

    return (
        <Transition.Root show={plannerStore.isFiltersOpen} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={onCloseClick}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-500"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-500"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"/>
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                    <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                        <div className={`tw-pointer-events-none tw-fixed tw-inset-y-0 tw-flex 
                            ${plannerStore.isPlannedFilters ? 'tw-right-0 tw-pl-10' : 'tw-left-0 tw-pr-10'}`}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                enterFrom={plannerStore.isPlannedFilters ? 'tw-translate-x-full' : '-tw-translate-x-full'}
                                enterTo={plannerStore.isPlannedFilters ? 'tw-translate-x-0' : 'tw-translate-x-0'}
                                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                leaveFrom={plannerStore.isPlannedFilters ? 'tw-translate-x-0' : 'tw-translate-x-0'}
                                leaveTo={plannerStore.isPlannedFilters ? 'tw-translate-x-full' : '-tw-translate-x-full'}
                            >
                                <Dialog.Panel  className="tw-pointer-events-auto tw-relative tw-w-screen tw-max-w-lg"> 
                                    <Transition.Child
                                        as={Fragment}
                                        enter="tw-ease-in-out duration-500"
                                        enterFrom="tw-opacity-0"
                                        enterTo="tw-opacity-100"
                                        leave="tw-ease-in-out tw-duration-500"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                    >
                                        <div className={`tw-absolute tw-top-0 tw-flex tw-pt-4 
                                            ${plannerStore.isPlannedFilters ? 'tw-left-0 sm:tw-pr-4 tw-pr-2 tw--ml-8 sm:tw--ml-10' 
                                                : 'tw-right-0 sm:tw-pl-4 tw-pl-2 tw--mr-8 sm:tw--mr-10'}`}
                                        >
                                            <button
                                                type="button"
                                                className="tw-rounded-md hover:tw-text-gray-300 tw-text-white hover:tw-bg-gray-500 focus:tw-outline-none tw-ring-2 tw-ring-white"
                                                onClick={onCloseClick}
                                            >
                                                <span className="tw-sr-only">Закрыть</span>
                                                <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="tw-flex tw-h-full tw-flex-col tw-bg-gray-200 tw-px-4 tw-pb-4 tw-shadow-xl">
                                        <PageTitle title='Настройка фильтров'/>
                                        { plannerStore.isFiltersOpen
                                            ?
                                                <div id='filters-edit-column' className='tw-h-full tw-overflow-hidden tw-rounded-md tw-bg-white tw-w-full'>
                                                    {plannerStore.isPlannedFilters 
                                                        ? <PlannerFilter/>
                                                        : <RecommendedFilter/>
                                                    }
                                                </div>
                                            :
                                                <Spinner/>
                                        }
                                    </div>
                                    <Tooltip id="action-filters-tooltip" className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div> 
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default observer(PlannerFiltersContainer)