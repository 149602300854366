import React, { useContext } from "react";
import { Context } from "../../..";
import { observer } from "mobx-react-lite";

/**
 * Визуальный компонент отображает строку в таблице пользователей юр лиц
 * 
 * @param {Object} user Пользователь
 * 
 */
const SubCompanyUserTableRow = (user) => {
    const { CompanyAdminStore } = useContext(Context)

    const onUserClick = (item) => {
        if (CompanyAdminStore.selectedUser?.id !== item.id) {
            CompanyAdminStore.setSelectedUser(item)
        }
    }

    return (
        <tr 
            style={{
                display: 'grid',
                gridTemplateColumns: user.sizes
            }}
            className={`${!user.is_active && 'tw-line-through tw-text-red-400'} tw-grid tw-grid-cols-3 tw-gap-4 tw-h-8 tw-border-b tw-truncate
                        ${CompanyAdminStore.selectedUser?.id === user.id 
                            ? 'tw-text-white tw-bg-gray-500' 
                            : 'tw-text-gray-900 hover:tw-bg-gray-200 hover:tw-z-10 '
                        } 
                        ${(CompanyAdminStore.isUserEditing || CompanyAdminStore.isUserCreating) && 'tw-pointer-events-none tw-opacity-50'} 
                        hover:tw-cursor-pointer`}
            onClick={() => onUserClick(user)}
        >
            <td className='tw-text-sm tw-text-left tw-font-medium tw-px-4 tw-py-1 tw-truncate tw-relative'>
                <span
                    data-tooltip-id="sub-company-user-table-tooltip"
                    data-tooltip-content={user.last_name}
                    data-tooltip-delay-show={1000}
                >
                    {user.last_name}
                </span>
            </td>

            <td className='tw-text-sm tw-text-left tw-font-medium tw-px-4 tw-py-1 tw-truncate tw-relative'>
                <span
                    data-tooltip-id="sub-company-user-table-tooltip"
                    data-tooltip-content={user.first_name}
                    data-tooltip-delay-show={1000}
                >
                    {user.first_name}
                </span>
            </td>

            <td className='tw-text-sm tw-text-left tw-font-medium tw-px-4 tw-py-1 tw-truncate tw-relative'>
                <span
                    data-tooltip-id="sub-company-user-table-tooltip"
                    data-tooltip-content={user.email}
                    data-tooltip-delay-show={1000}
                >
                    {user.email}
                </span>
            </td>
        </tr>       
    )
}

export default observer(SubCompanyUserTableRow)