import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Context } from '../../../../..'
import PageTitle from '../../common/panels/PageTitle'
import { observer } from 'mobx-react-lite'
import ReportTable from '../ReportTable'
import ReportExportMenu from '../common/ReportExportMenu'
import ReportEducationFilter from './ReportEducationFilter'
import Spinner from '../../../../../assets/Spinner'
import { getIdFromPath } from '../../../../../functions/getIdFromPath'
import RecordNotesStore from '../../../../../store/RecordNotesStore'
import EditDataObjectContainer from '../../data_object/edit/EditDataObjectContainer'
import { paths } from '../../../../../config/constsURN'
import { toast } from 'react-toastify'
import { serviceMessageTimeOut } from '../../../../../config/constTypes'


const allUsersFields = {
    fields: [
        {alias: 'Пользователь', tech_name: 'responsible_user', validator_type: 'string', is_clickable: false, data_model_id: ''},
        {alias: 'Email', tech_name: 'responsible_user_email', validator_type: 'string', is_clickable: false, data_model_id: ''},
        {alias: 'Открыто', tech_name: 'opened_materials', validator_type: 'int', is_clickable: true, link: paths.REPORT_EDUCATION_ROUTE + '/total', data_model_id: ''},
        {alias: 'Пройдено', tech_name: 'passed_materials', validator_type: 'int', is_clickable: true, link: paths.REPORT_EDUCATION_ROUTE + '/total', data_model_id: ''},
    ]
}

const allMaterialsFields = {
    fields: [
        {alias: 'Пользователь', tech_name: 'user_name', validator_type: 'string', order: 0, is_clickable: false, data_model_id: ''},
        // {alias: 'Тема', tech_name: 'topic_name', validator_type: 'string', order: 1, is_clickable: true, data_model_id: 'topics'},
        {alias: 'Материал', tech_name: 'material_name', validator_type: 'string', order: 2, is_clickable: true, data_model_id: 'topic_materials'},
        {alias: 'Тип материала', tech_name: 'material_type', validator_type: 'string', order: 3, is_clickable: false, data_model_id: ''},
        {alias: 'Дата прохождения', tech_name: 'material_date', validator_type: 'string', order: 4, is_clickable: false, data_model_id: ''},
        {alias: 'Результат, %', tech_name: 'material_percent', validator_type: 'int', order: 5, is_clickable: false, data_model_id: ''},
    ]
}


/**
 * Компонент реализует логику работы с отчетами по модулю Повышение осведомленности
 * 
 */
const ReportEducationContainer = () => {
    const location = useLocation()
    const { EducationStore, docStore } = useContext(Context)
    const [recordNotesStore] = useState(() => new RecordNotesStore())

    const [isLoading, setIsLoading] = useState(false)
    const [reportDataModel, setReportDataModel] = useState({fields: []})
    const [reportData, setReportData] = useState([])
    const [reportType, setReportType] = useState(null)
    const [reportFilterString, setReportFilterString] = useState('')
    const [allResponsibleUsers, setAllResponsibleUsers] = useState(null)
    const [isUpdatingProgressList, setIsUpdatingProgressList] = useState(false)

    const [isEditFormOpen, setIsEditFormOpen] = useState(false)
    const [dataObjectID, setDataObjectID] = useState(null)
    const [selectedFullDataModel, setSelectedFullDataModel] = useState(null)

    const caption = reportType
                        ?   reportType === 'total' ? 'Общий' : 'Посещаемость'
                        :   ''

    const initReport = async () => {
        setIsLoading(true)
        const loadedUsers = await EducationStore.getCompanyUsers()
        const allUsers = [{id: 0, name: 'Все', username: 'Все', email: null}].concat(loadedUsers)
        setAllResponsibleUsers(allUsers)

        await EducationStore.loadTopics([])

        const materials = await EducationStore.getMaterialsList([])
        EducationStore.setAllMaterials(materials)

        await EducationStore.getMaterialRuleID()

        const selectedType = getIdFromPath(location.pathname, 3)
        if (selectedType) {
            setReportType(selectedType)
            EducationStore.setReportFilters('selectedType', selectedType, true)
            if (selectedType === 'attendance') {
                setReportDataModel(allUsersFields)
            }
            if (selectedType === 'total') {
                setReportDataModel(allMaterialsFields)
                if (location.search !== '') {
                    const searchParams = new URLSearchParams(location.search)
                    const userID = searchParams.get('id')
                    const foundUser = loadedUsers.find(user => user.id === Number(userID))
                    if (foundUser) {
                        EducationStore.setReportFilters('selectedUser', foundUser, true)
                    } else {
                        toast.error('Пользователь с таким ID не найден!', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                    }
                    EducationStore.setReportFilters('isPassed', searchParams.get('type') === 'passed_materials', true)
                    EducationStore.setReportFilters('fromDate', Number(searchParams.get('from')), true)
                    EducationStore.setReportFilters('tillDate', Number(searchParams.get('till')), true)
                    setIsUpdatingProgressList(true)
                }
            }
        }
        setIsLoading(false)
    }

    const handleItemClick = async (id, recordID, dataModelID) => {
        await docStore.getOneDataModel(dataModelID, dataModel => { setSelectedFullDataModel(dataModel)})
        setDataObjectID(id)
        recordNotesStore.setRecordID(recordID)
        setIsEditFormOpen(true)
    }

    const handleApplyFilterClick = async () => {
        setIsLoading(true)
        const selectedFilters = EducationStore.checkReportFilters()
        await EducationStore.loadAllMaterialProgress(selectedFilters)
        await EducationStore.loadAllTestAttempts(selectedFilters)
        if (EducationStore.reportFilters.selectedType === 'attendance') {
            const formattedData = EducationStore.formatAllUserMaterialsSummary()
            const summaryData = EducationStore.reportFilters.selectedUser && EducationStore.reportFilters.selectedUser.id > 0
                                    ?   formattedData.filter(item => item.data['responsible_user_email'].value === EducationStore.reportFilters.selectedUser.email)
                                    :   formattedData
            setReportData(summaryData)
            let filterText = ''
            if (EducationStore.reportFilters.fromDate)
                filterText += '&&from=' + EducationStore.reportFilters.fromDate
            if (EducationStore.reportFilters.tillDate)
                filterText += '&&till=' + EducationStore.reportFilters.tillDate
            setReportFilterString(filterText)
        }
        if (EducationStore.reportFilters.selectedType === 'total') {
            const summaryData = EducationStore.formatMaterialProgressList()
            setReportData(summaryData)
        }
        setIsLoading(false)
    }

    const handleEditFormCloseClick = () => {
        setIsEditFormOpen(false)
        recordNotesStore.setRecordID(null)
    }

    useEffect(() => {
        initReport()
    }, [])

    useEffect(() => {
        if (isUpdatingProgressList) {
            handleApplyFilterClick()
            setIsUpdatingProgressList(false)
        }
    }, [isUpdatingProgressList])

    return (
        <>
            <PageTitle title={`Отчет "Повышение осведомленности: ${caption}"`}/>
            <div  className='tw-h-full tw-max-h-full tw-w-full tw-grid tw-grid-cols-4 tw-gap-x-8'>
                <div className='tw-col-span-1 tw-h-full tw-min-w-80 tw-flex tw-flex-col tw-overflow-hidden tw-bg-white tw-rounded-md'>
                    <div className='tw-w-full tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-border-b-2 tw-border-gray-400'>
                        <div className='tw-text-md tw-font-semibold'>Настройки отчета</div>
                    </div>
                    <ReportEducationFilter
                        users={allResponsibleUsers}
                        reportType={reportType}
                        onSubmitClick={handleApplyFilterClick}
                    />
                </div>
                <div id='report-planner-page' className='tw-col-span-3 tw-h-full tw-flex tw-flex-col tw-overflow-hidden tw-bg-white tw-rounded-md'>
                    <div className='tw-w-full tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-border-b-2 tw-border-gray-400'>
                        <div className='tw-text-md tw-font-semibold'>Данные для отчета</div>
                        <ReportExportMenu/>
                    </div>
                    { !isLoading
                        ?   EducationStore.reportFilters.selectedType &&
                            <ReportTable
                                dataModel={reportDataModel}
                                reportData={reportData}
                                onCellClick={handleItemClick}
                                filterString={reportFilterString}
                            />
                        :
                            <Spinner/>
                    }
                </div>
            </div>
            <EditDataObjectContainer
                isOpen={isEditFormOpen} 
                onCloseClick={handleEditFormCloseClick}
                id={dataObjectID}
                selectedFullDataModel={selectedFullDataModel}
                recordNotesStore={recordNotesStore}
            />
        </>
    )
}

export default observer(ReportEducationContainer)