import React, { useState, useEffect, Fragment } from 'react'
import PageTitle from '../../common/panels/PageTitle'
import Spinner from '../../../../../assets/Spinner'
import PDFViewer from './PDFViewer'
import VideoPlayer from './VideoPlayer'
import FileService from '../../../../../services/FileService'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { errorHandler } from '../../../../../functions/errorHandlers'


/**
 * Компонент реализует логику отображения содержимого файла, прикрепленного к записи
 * 
 * @param {Boolean} isOpen Признак видимости (отображения) модального окна
 * @param {Object} file Информация о файле
 * @param {Function} onCloseClick Обработчик клика мыши для закрытия окна
 * 
 */
const ViewFileContainer = ({isOpen, file, onCloseClick}) => {
    const [isLoading, setIsLoading] = useState(true)
    const [viewedFile, setViewedFile] = useState(null)
   
    const handleCloseDialogClick = () => {
        onCloseClick()
    }

    const handleRenderError = () => {
        setIsLoading(false)
    }

    const getFile = async (file) => {
        try {
            const blob = await FileService.downloadFile(file.id)
            const url = window.URL.createObjectURL(new Blob([blob]))
            setViewedFile(url)
            setIsLoading(false)           
        } catch (error) {
            errorHandler(error.response, <div>Ошибка при скачивании файла!</div>)            
        }
    }

    useEffect(() => {
        if (isOpen) {
            if (file) {
                getFile(file)
            }
        } else {
            setIsLoading(true)
        }
    }, [isOpen, file])

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={handleCloseDialogClick}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-500"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-500"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                    <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                        <div className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex  tw-pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                enterFrom="tw-translate-x-full"
                                enterTo="tw-translate-x-0"
                                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                leaveFrom="tw-translate-x-0"
                                leaveTo="tw-translate-x-full"
                            >
                                <Dialog.Panel  className="tw-pointer-events-auto tw-relative tw-w-[90vw]"> 
                                    <Transition.Child
                                        as={Fragment}
                                        enter="tw-ease-in-out duration-500"
                                        enterFrom="tw-opacity-0"
                                        enterTo="tw-opacity-100"
                                        leave="tw-ease-in-out tw-duration-500"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                    >
                                        <div className="tw-absolute tw-left-0 tw-top-0 tw--ml-8 tw-flex tw-pr-2 tw-pt-4 sm:tw--ml-10 sm:tw-pr-4">
                                        <button
                                            type="button"
                                            className="tw-rounded-md hover:tw-text-gray-300 tw-text-white hover:tw-bg-gray-500 focus:tw-outline-none tw-ring-2 tw-ring-white"
                                            onClick={handleCloseDialogClick}
                                        >
                                            <span className="tw-sr-only">Закрыть</span>
                                            <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                                        </button>
                                        </div>
                                    </Transition.Child>
                                    <div id='file-panel' className="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto tw-bg-gray-200 tw-px-4 tw-py-0.5 tw-shadow-xl">
                                        {isLoading 
                                            ?   <Spinner/> 
                                            :   <>
                                                    <PageTitle title={`Просмотр файла`} />
                                                    <div id='file-column' className='tw-h-[calc(100%_-_5rem)] tw-overflow-hidden tw-rounded-md tw-bg-white tw-w-full'>
                                                        { file?.metadata?.extension === 'pdf' &&
                                                            <PDFViewer
                                                                file={viewedFile}
                                                                fileName={file?.metadata?.name + '.' + file?.metadata?.extension}
                                                                onError={handleRenderError}
                                                            />
                                                        }
                                                        { ['mp4', 'webm'].includes(file?.metadata?.extension)  &&
                                                            <VideoPlayer
                                                                file={viewedFile}
                                                                fileName={file?.metadata?.name + '.' + file?.metadata?.extension}
                                                            />
                                                        }
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ViewFileContainer