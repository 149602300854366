import React from 'react'
import TopicItem from './TopicItem'


/**
 * Визуальный компонент отображает список этапов процесса
 * 
 * @param {Object} list Список этапов процесса 
 * @param {Object} activeStage Текущий этап процесса
 * @param {String} idField Имя поля с номером этапа 
 * @param {Boolean} isHideList Признак скрытия списка этапов 
 * @param {Function} onItemClick Обработчик клика мыши для перехода обратно к произвольному завершенному этапу
 * @param {Boolean} isSearch Признак наличия строки поиска
 * 
 */
const TopicList = ({list, activeStage, idField, isHideList, onItemClick, isSearch}) => {
    return (
        <div
            id='stage-list-items'
            className={`${isSearch ? 'tw-h-[calc(100%_-_6rem)]' : 'tw-h-[calc(100%_-_3rem)]'}
                        ${isSearch & !isHideList ? 'lg:tw-h-[calc(100%_-_8rem)]' : 'lg:tw-h-[calc(100%_-_6.5rem)]'}
                        tw-flex tw-flex-col tw-justify-between tw-w-full tw-bg-white tw-overflow-auto`}
        >
            <div>
                { list.map((stage, index) => 
                    <TopicItem
                        key={index}
                        item={stage}
                        activeItem={activeStage}
                        idField={idField}
                        isHideList={isHideList}
                        onItemClick={onItemClick}
                    />
                )}
            </div>
        </div>
    )
}

export default TopicList