/**
 * Константы для процесса категорирования
 * @module CategorizingConsts
 */


/**
 * Справочники по процессу категорирования
 */
export const categorizingDirectories = [
    {id: 'okvad', entity_name: 'ОКВЭД', records: 0},
    {id: 'types_of_activities', entity_name: 'Виды деятельности', records: 0},
    {id: 'types_of_object_kii', entity_name: 'Типы объектов КИИ', records: 0},
    {id: 'bdu', entity_name: 'БДУ', records: 0},
    {id: 'business_process', entity_name: 'Бизнес-процессы', records: 0},
    {id: 'technical_processes', entity_name: 'Технологические процессы', records: 0},
    {id: 'significance_indicators', entity_name: 'Показатели значимости', records: 0},
]
