import React, { useContext } from 'react'
import { Context } from '../../../../../../..'
import { observer } from 'mobx-react-lite'
import { FunnelIcon } from '@heroicons/react/20/solid'
import { menuTooltipTimeOut } from '../../../../../../../config/constTypes'

/**
 * Визуальный компонент отображает меню для рекомендуемых действий
 * 
 */
const RecommendedActionMenu = () => {
    const { plannerStore } = useContext(Context)

    const isActiveFilter = () => {
        const filters = Object.values(plannerStore.savedRecommendedFilters)
        if (filters)
            return filters.some(value => value === true)

        return false
    }

    return (
        <div>
            <button
                className={`tw-rounded-md tw-p-1.5 tw-text-white disabled:tw-bg-gray-300 
                    ${isActiveFilter() ? 'tw-bg-sky-600 hover:tw-bg-sky-500' : 'tw-bg-gray-800 hover:tw-bg-gray-600'}
                `}
                onClick={() => {plannerStore.setIsFiltersOpen(true); plannerStore.setIsPlannedFilters(false)}}
                data-tooltip-id="planner-table-tooltip" data-tooltip-content="Настроить фильтры" data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <FunnelIcon className='tw-h-5 tw-w-5' aria-hidden="true"/>
            </button>
        </div>
    )
}

export default observer(RecommendedActionMenu)