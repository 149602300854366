import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Context } from '../../../../index'
import DocumentService from '../../../../services/DocumentService'
import DataModelCategoryList from '../data_model/list/DataModelCategoryList'
import { paths } from '../../../../config/constsURN'
import { observer } from 'mobx-react-lite'
import Spinner from '../../../../assets/Spinner'
import PageTitle from '../common/panels/PageTitle'
import { toast } from 'react-toastify'
import DataObjectMenu from './menu/DataObjectMenu'
import PrintDocumentModal from '../exporter/to_file/PrintDocumentModal'
import ImportFromFileModal from '../importer/from_file/ImportFromFileModal'
import { responseTimeOut, serviceMessageTimeOut, successMessageTimeOut } from '../../../../config/constTypes'
import { handleFileSelect, handleFileRemove, printDocument } from '../../../../functions/fileHandlers'
import { showErrorToast } from '../../../../functions/errorHandlers'
import DirectoryPanel from '../common/panels/directory/DirectoryPanel'
import { scrollElementIntoView } from '../../../../functions/scrollElementIntoView'
import DuplicateDataObjectContainer from './add/DuplicateDataObjectContainer'
import AddDataObjectContainer from './add/AddDataObjectContainer'
import EditDataObjectContainer from './edit/EditDataObjectContainer'
import { initNestedModels } from '../../../../functions/nestedModels'
import ImporterService from '../../../../services/ImporterService'
import { setSorting } from '../../../../functions/sortingsAndFilters'
import FilterStore from '../../../../store/FilterStore'
import RecordNotesStore from '../../../../store/RecordNotesStore'
import ExportDataObjectContainer from './export/ExportDataObjectContainer'
import { useHotkeys } from 'react-hotkeys-hook'
import { getIdFromPath } from '../../../../functions/getIdFromPath'
import { checkDataModelPermission, checkMethodPermissions } from '../../../../functions/checkPermissions'
import parse from 'html-react-parser'
import { copyToClipboard } from '../../../../functions/copyToClipboard'


/**
 * Компонент реализует логику работы с записями в таблицах (просмотр, создание, редактирование, импорт, экспорт)
 * 
 */
const DataObjectListContainer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { docStore, userStore } = useContext(Context)
    const [filterStore] = useState(() => new FilterStore())
    const [recordNotesStore] = useState(() => new RecordNotesStore())

    const [isLoading, setIsLoading] = useState(false)
    const [isNestedLoading, setIsNestedLoading] = useState(false)
    const [dataObjects, setDataObjects] = useState([])
    const [selectedFullDataModel, setSelectedFullDataModel] = useState(null)
    const [nestedDataModels, setNestedDataModels] = useState([])
    const [isHistoryPressed, setIsHistoryPressed] = useState(false)
    const [isSortingPressed, setIsSortingPressed] = useState(false)
    const [isSearchPressed, setIsSearchPressed] = useState(false)
    const [selectedNestedDataModel, setSelectedNestedDataModel] = useState(null)
    const [sortingColumn, setSortingColumn] = useState({data_model_id: '', column_tech_name: 'created', column_validator_type: 'date', column_rule_id: '', direction: 'down'})
    const [isFetchingData, setIsFetchingData] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [dataObjectOffset, setDataObjectOffset] = useState(0)

    const [isAddFormOpen, setIsAddFormOpen] = useState(false)
    const [isDuplicateFormOpen, setIsDuplicateFormOpen] = useState(false)
    const [isEditFormOpen, setIsEditFormOpen] = useState(false)
    const [isExportFieldsFormOpen, setIsExportFieldsFormOpen] = useState(false)
    const [exportType, setExportType] = useState(null)
    const [exportData, setExportData] = useState(null)
    const [dataObjectID, setDataObjectID] = useState(null)
    const [fetchedDataObjects, setFetchedDataObjects] = useState(null)
    const [searchedText, setSearchedText] = useState('')

    const [isExporterSelecting, setIsExporterSelecting] = useState(false)
    const [isPrinting, setIsPrinting] = useState(false)
    const [isImporting, setIsImporting] = useState(false)
    const [importFiles, setImportFiles] = useState([])

    const [isRead, isReadDescription] = checkDataModelPermission(selectedFullDataModel, 'read') 
    const [isWrite, isWriteDescription] = checkDataModelPermission(selectedFullDataModel, 'write') 

    const isInitialState = !isAddFormOpen && !isDuplicateFormOpen && !isEditFormOpen

    useHotkeys('ctrl+a', () => {if (isInitialState && selectedFullDataModel) handleAddDataObjectClick()}, { preventDefault: true })
    useHotkeys('ctrl+d', () => {if (isInitialState && docStore.selectedDataObject) handleDuplicateClick(docStore.selectedDataObject.id)}, { preventDefault: true })
    useHotkeys('ctrl+e', () => {if (isInitialState && docStore.selectedDataObject) handleEditDataObjectClick(docStore.selectedDataObject.id)}, { preventDefault: true })
    useHotkeys('ctrl+r', () => {if (isInitialState && docStore.selectedDataObject) handleDeleteDataObjectClick(docStore.selectedDataObject)}, { preventDefault: true })
    useHotkeys('ctrl+p', () => {if (isInitialState && docStore.selectedDataObject) handleOpenExportersClick()}, { preventDefault: true })
    useHotkeys('ctrl+h', () => {if (isInitialState && docStore.selectedDataObject) handleViewHistoryClick()}, { preventDefault: true })
    useHotkeys('ctrl+i', () => {if (isInitialState && docStore.selectedDataObject) handleViewRecordClick()}, { preventDefault: true })
    useHotkeys('ctrl+l', () => {if (isInitialState && docStore.selectedDataObject) handleCopyToClipboardClick()}, { preventDefault: true })

    const handleDataModelClick = (dataModel) => {
        if (selectedFullDataModel?.id !== dataModel.id) {
            const baseURL = paths.DATAOBJECT_ROUTE + '/' + dataModel.id
            const newURL =  location.search !== '' && !location.search.includes('?text=record_id=')
                            ?   baseURL + (searchedText !== '' ? '/?text=' + searchedText : '')
                            :   baseURL
            navigate(newURL)
        }
    }

    const handleDataModelSelect = (dataModel) => {
        if (dataModel) {
            setSelectedFullDataModel(dataModel)
            setNestedDataModels(initNestedModels(dataModel))
            setSelectedNestedDataModel(null)
            setDataObjects([])
            setDataObjectOffset(0)

            docStore.setSelectedDataObject(null)
            docStore.setAttachedFiles([])
            docStore.setIsHistoryView(false)
            docStore.setIsDetailView(false)

            recordNotesStore.setRecordID(null)

            if ((searchedText === filterStore.searchField) && (searchedText !== '')) {
                filterStore.updateSearchFilters(dataModel)
            } else {
                setSearchedText('')
                filterStore.setSearchField('')
                filterStore.setSearchFilters([])
            }

            sessionStorage.setItem('activeDataModel', JSON.stringify(dataModel))
            const savedSortingColumn = docStore.loadSavedSorting(dataModel.id)
            setSortingColumn(savedSortingColumn)

            const filters = JSON.parse(sessionStorage.getItem(`${dataModel.id}_filters`))
            const fields = JSON.parse(sessionStorage.getItem(`${dataModel.id}_filterFields`))

            if (filters?.length && fields?.length) {
                filterStore.setSelectedFilters(filters)
                filterStore.setFilteredFields(fields)
                filterStore.setIsFiltering(true)
            } else {
                filterStore.filterClick(dataModel, true, true, true)
            }
        }
    }

    const handleDataObjectClick = (dataObject) => {
        if (!docStore.selectedDataObject || dataObject.id !== docStore.selectedDataObject?.id) {
            docStore.setSelectedDataObject(dataObject)
            if (dataObject.system_data.files && dataObject.system_data.files.length) {
                docStore.setAttachedFiles(dataObject.system_data.files)
            } else {
                docStore.setAttachedFiles([])
            }
        }
    }

    const handleAddDataObjectClick = async () => {
        const isDataModelLocked = await docStore.checkDataModelLock(selectedFullDataModel.id)
        if (isDataModelLocked) {
            toast.error('Создание записи невозможно: таблица заблокирована для импорта данных!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
        } else {
            setIsAddFormOpen(true)
        }
    }

    const handleDuplicateClick = async (id) => {
        const isDataModelLocked = await docStore.checkDataModelLock(selectedFullDataModel.id)
        if (isDataModelLocked) {
            toast.error('Создание записи невозможно: таблица заблокирована для импорта данных!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
        } else {
            setIsDuplicateFormOpen(true)
            setDataObjectID(id)
        }
    }

    const handleEditDataObjectClick = async (id) => {
        const isDataModelLocked = await docStore.checkDataModelLock(selectedFullDataModel.id)
        if (isDataModelLocked) {
            toast.error('Редактирование записи невозможно: таблица заблокирована для импорта данных!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
        } else {
            setIsEditFormOpen(true)
            setDataObjectID(id)
        }
    }

    const handleViewRecordClick = () => {
        docStore.setIsDetailView(!docStore.isDetailView)
    }

    const handleViewHistoryClick = () => {
        setIsHistoryPressed(true)
        docStore.setIsDetailView(false)
    }

    const handleDeleteDataObjectClick = (item) => {
        const deletionMark = (item.system_data && item.system_data.deletion_mark) ? false : true
        deleteDataObject(item.record_id, deletionMark)
    }

    const handlePrintTableClick = (type) => {
        setExportType(type)
        setExportData('table')
        setIsExportFieldsFormOpen(true)
    }

    const handlePrintRecordClick = (type) => {
        setExportType(type)
        setExportData('record')
        setIsExportFieldsFormOpen(true)
    }

    // Вызов модального окна печати в PDF/ODT (для выбора печатной формы)
    const handleOpenExportersClick = () => {
        setIsExporterSelecting(true)
    }

    // Экспорт записи в PDF согласно выбранной печатной форме
    const handlePrintDocumentClick = async (template) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис экспорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        setIsPrinting(true)

        try {
            await printDocument(template.id, docStore.selectedDataObject.id, setIsPrinting)
        } catch (error){
            showErrorToast(error, 'export', '')
        } finally {
            clearTimeout(noResponse)
            setIsExporterSelecting(false)
            setIsPrinting(false)
        }
    }

    // Вызов модального окна импорта из файла (для выбора шаблона импорта)
    const handleImportFromFileClick = () => {
        setIsImporting(true)
    }

    // Импорт записей из файла согласно выбранному шаблону
    const handleImportTableClick = (form) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис импорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            docStore.setAttachedFiles([])
        }, responseTimeOut)

        const importConfig = {}
        importConfig.source = {}
        importConfig.source.file_id = importFiles[0].id
        importConfig.settings = {}
        importConfig.settings.sheet_name = form.sheetNumber
        importConfig.settings.header = form.headerRowCount
        importConfig.settings.offset = form.startRowNumber
        if (form.endRowNumber < 65535 && form.endRowNumber >= form.startRowNumber )
            importConfig.settings.count = form.endRowNumber - form.startRowNumber + 1

        if (userStore.user.is_staff && importConfig.settings.count > 100) {
            importConfig.settings.count = null
        }
        setIsImporting(false)

        ImporterService
            .importFileData(form.template.id, importConfig)
            .then(data => {
                clearTimeout(noResponse)
                sessionStorage.removeItem('savedImportColumnSizes')
                navigate(paths.IMPORT_PREVIEW_ROUTE + '/' + data.id)
            })            
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'import', '')
            })
            .finally(() => setImportFiles([]))
    }

    const deleteDataObject = (id, mark) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        DocumentService
            .deleteDataObject(id, {system_data: {deletion_mark: mark}})
            .then(() => {
                clearTimeout(noResponse)
                updateDataObjectList(selectedFullDataModel)
                setNestedDataModels(initNestedModels(selectedFullDataModel))
                docStore.setSelectedDataObject(null)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'deleting', '')
            })
    }

    const handleImportFromServerClick = () => {
        navigate(paths.IMPORT_DATA_ROUTE + '/' + selectedFullDataModel.id)
    }

    const handleDataObjectDoubleClick = (item) => {
        const [isRecordWrite, isRecordWriteDescription] = checkMethodPermissions(item, 'write')
        
        if (item.active && isRecordWrite && isWrite) {
            handleEditDataObjectClick(item.id)
        } else {
            handleViewRecordClick()
        }
    }

    const handleImportModalClose = () => {
        setIsImporting(false)
        setImportFiles([])
    }
    
    const applySearchFilter = (dataModel) => {
        setSearchedText(filterStore.searchField)
        setIsSearchPressed(true)

        if (filterStore.searchField === '') {
            navigate(paths.DATAOBJECT_ROUTE + '/' + dataModel.id)
        } else {
            const textValue = filterStore.searchField
                                .replace(/\s{2,}/g, ' ')
                                .trim()
                                .replace(/\+/g, '%2B')
                                .replace(/\s/g, '+')
            navigate(paths.DATAOBJECT_ROUTE + '/' + dataModel.id + '/?text=' + textValue)
        }
    }

    const updateDataObjectList = (dataModel) => {
        const defaultFilter = [
            {property: 'data_model_id', value: dataModel?.id, operator: 'eq'},
            {property: 'transaction_id', value: null, operator: 'eq'},
            {property: 'active', value: true, operator: 'eq'}
        ].concat(
            dataModel?.allow_hierarchy && !filterStore.selectedFilters.length && !filterStore.searchFilters.length
                ?   [{property: 'system_data.parent_record_id', value: null, operator: 'eq'}]
                :   []
        )

        const filters = JSON.stringify(defaultFilter.concat(filterStore.selectedFilters).concat(filterStore.searchFilters))    
        const sorter = JSON.stringify(setSorting(dataModel, sortingColumn, sortingColumn.direction))
        docStore.setSelectedFilters(filters)

        if (dataModel)
            setSelectedFullDataModel({...dataModel, sortingColumn: sortingColumn, sortingDirection: sortingColumn.direction})
        docStore.getDataObjectsByID(filters, sorter, 0, dataObjects, setDataObjects, setTotalCount, setDataObjectOffset)
    }

    const handleObjectContainerCloseClick = () => {
        updateDataObjectList(selectedFullDataModel)
        docStore.setSelectedDataObject(null)
        setNestedDataModels(initNestedModels(selectedFullDataModel))

        setIsAddFormOpen(false)
        setIsDuplicateFormOpen(false)
        setIsEditFormOpen(false)
    }

    const handleExportDataobjectClick = (form) => {
        setIsExportFieldsFormOpen(false)
        const selectedFields = Object.entries(form).filter(field => field[1]).map(field => field[0])
        docStore.exportDataObject(selectedFullDataModel, exportType, exportData, selectedFields)
    }

    const handleExportObjectCloseClick = () => {
        setIsExportFieldsFormOpen(false)
    }

    const handleSortClick = (column) => {
        let direction = 'down'
        if (column.column_tech_name === sortingColumn.column_tech_name) {
            if (sortingColumn.direction === 'down') {
                direction = 'up'
            }
        }

        setSortingColumn({...column, direction: direction})
        setSelectedFullDataModel({...selectedFullDataModel, sortingColumn: column, sortingDirection: direction})
        docStore.setSelectedSorting(column, direction)
        setIsSortingPressed(true)
    }

    const handleHierarchyClick = async (dataObject) => {
        if (!dataObject.hierarchyVisible) {
            const updatedDataObjects = await docStore.showTreeNode(dataObjects, dataObject, filterStore.selectedFilters, setSorting(selectedFullDataModel, sortingColumn, sortingColumn.direction))
            setDataObjects(updatedDataObjects)
        } else {
            const updatedDataObjects = docStore.hideTreeNode(dataObjects, dataObject)
            setDataObjects(updatedDataObjects)
        }
    }

    const handleCopyToClipboardClick = () => {
        try {
            copyToClipboard(`${paths.DATAOBJECT_ROUTE}/${selectedFullDataModel.id}/?text=record_id=${docStore.selectedDataObject.record_id}`)
            toast.info('Ссылка скопирована в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
        } catch (error) {
            toast.error('Не удалось скопировать ссылку в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }
    }

    useEffect(() => {
        if (userStore.checkPermission("data:access")) {
            docStore.setShownTypes(['document', 'directory'])
            docStore.setIsShowInfo(false)

            const defaultFilter = []
            const categorySorter = JSON.stringify([
                {property: 'order', desc: false},
            ])      
            docStore.getCategories(defaultFilter, categorySorter)

            const noResponse = setTimeout(() => {
                toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                setIsLoading(false)
            }, responseTimeOut)

            const pathDataModelID = getIdFromPath(location.pathname, 2)
            let savedDataModel = JSON.parse(sessionStorage.getItem('activeDataModel'))
            const selectedModelID = pathDataModelID || savedDataModel?.id
    
            const filter = JSON.stringify([
                {property: 'hide_mark', value: false, operator: 'eq'},
            ])
            const dataModelSorter = JSON.stringify([
                {property: 'entity_name', desc: false},
            ])      
            setIsLoading(true)

            DocumentService
                .getDataModels(filter, dataModelSorter)
                .then(data => {
                    clearTimeout(noResponse)
                    docStore.setDataModels(data)

                    if (selectedModelID) {
                        const foundDataModel = data.find(item => item.id === selectedModelID)
                        if (foundDataModel && foundDataModel.type !== 'nested') {
                            const savedSortingColumn = docStore.loadSavedSorting(foundDataModel.id)
                            setSortingColumn(savedSortingColumn)
                            setSelectedFullDataModel({...foundDataModel, sortingColumn: savedSortingColumn, sortingDirection: savedSortingColumn.direction})
                            setNestedDataModels(initNestedModels(foundDataModel))

                            const filters = JSON.parse(sessionStorage.getItem(`${selectedModelID}_filters`))
                            const fields = JSON.parse(sessionStorage.getItem(`${selectedModelID}_filterFields`))
                                
                            if (filters?.length && fields?.length) {
                                filterStore.setSelectedFilters(filters)
                                filterStore.setFilteredFields(fields)
                                filterStore.setIsFiltering(true)
                            } else {
                                filterStore.filterClick(foundDataModel, true, true, true)
                            }

                            if (location.search !== '') {
                                if (location.search.includes('?text=record_id=')) {
                                    const text = location.search.replace('?text=', '')
                                    setSearchedText(decodeURI(text))
                                    filterStore.setSearchField(decodeURI(text))
                                    const recordID = location.search.replace('?text=record_id=', '')
                                    filterStore.setSearchFilters([{
                                        property: "record_id",
                                        value: recordID,
                                        operator: "eq"
                                    }])

                                } else {
                                    const text = location.search.replace('?text=', '').replace('+', ' ').replace('%2B', '+')
                                    setSearchedText(decodeURI(text))
                                    filterStore.setSearchField(decodeURI(text))
                                    filterStore.updateSearchFilters(foundDataModel)
                                }
                            }
                        } else {
                            sessionStorage.removeItem('activeDataModel')
                        }
                    } else {
                        setSelectedFullDataModel(null)
                        setNestedDataModels([])
                    }
                    docStore.setSelectedDataObject(null)
                })
                .catch(error => {
                    clearTimeout(noResponse)
                    showErrorToast(error, 'fetching', '')
                })
                .finally(() => setIsLoading(false))
        }
    }, [userStore.userPermissions])

    useEffect(() => {
        if (isHistoryPressed && docStore.selectedDataObject) {
            if (!docStore.isHistoryView) {
                setFetchedDataObjects(dataObjects)
                const historyFilter = JSON.stringify([
                    {property: 'record_id', value: docStore.selectedDataObject.record_id, operator: 'eq'},
                ])
                const defaultSorter = []

                docStore.getDataObjectHistory(historyFilter, defaultSorter, true, setDataObjects)

            } else {
                if (fetchedDataObjects) {
                    setDataObjects(fetchedDataObjects)
                } else {
                    updateDataObjectList(selectedFullDataModel)
                    setNestedDataModels(initNestedModels(selectedFullDataModel))
                    docStore.setSelectedDataObject(null)
                }
            }
            docStore.setIsHistoryView(!docStore.isHistoryView)
            setIsHistoryPressed(false)
        }
    }, [isHistoryPressed])

    useEffect(()=>{
        if (isSortingPressed && selectedFullDataModel && isRead){
            updateDataObjectList(selectedFullDataModel)
            setIsSortingPressed(false)
        }
    }, [sortingColumn])

    useEffect(()=>{
        if (selectedFullDataModel) {
            if (isRead) {
                sessionStorage.setItem(`${selectedFullDataModel.id}_filters`, JSON.stringify(filterStore.selectedFilters))
                sessionStorage.setItem(`${selectedFullDataModel.id}_filterFields`, JSON.stringify(filterStore.filteredFields))
                
                updateDataObjectList(selectedFullDataModel)
            } else 
                toast.warning(isReadDescription, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            recordNotesStore.setRecordID(null)
        }
    }, [filterStore.selectedFilters])

    useEffect(()=>{
        if (isSearchPressed && selectedFullDataModel) {
            if (isRead) {
                updateDataObjectList(selectedFullDataModel)
            } else 
                toast.warning('Отсутствует право на чтение записей данной таблицы', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setIsSearchPressed(false)
        }
    }, [isSearchPressed])

    useEffect(()=>{
        if (isFetchingData && isRead){
            if (dataObjectOffset < totalCount) {
                const sorter = JSON.stringify(setSorting(selectedFullDataModel, sortingColumn, sortingColumn.direction))

                docStore.getDataObjectsByID(docStore.selectedFilters, sorter, dataObjectOffset, dataObjects, setDataObjects, setTotalCount, setDataObjectOffset)
            } else {
                setIsFetchingData(false)
            }
        }
    }, [isFetchingData])

    useEffect(()=>{
        if (!docStore.isDataObjectLoading && isFetchingData){
            setIsFetchingData(false)
        }
    }, [docStore.isDataObjectLoading])
    
    useEffect(()=>{
        if (docStore.selectedDataObject && !isDuplicateFormOpen && !isAddFormOpen) {
            recordNotesStore.setRecordID(docStore.selectedDataObject.record_id)
        } else {
            recordNotesStore.setRecordID(null)
        }

        if (docStore.selectedDataObject && selectedNestedDataModel && !isDuplicateFormOpen && !isAddFormOpen && isRead) {
            docStore.getNestedDataObjects(docStore.selectedDataObject, selectedNestedDataModel, setNestedDataModels, setIsNestedLoading)    
        }

        if (!docStore.selectedDataObject && selectedFullDataModel) {
            setNestedDataModels(initNestedModels(selectedFullDataModel))
        }
        scrollElementIntoView(selectedFullDataModel, '')

    }, [docStore.selectedDataObject, isDuplicateFormOpen, isAddFormOpen])

    useEffect(()=>{
        if (docStore.selectedDataObject && selectedNestedDataModel && !isDuplicateFormOpen && !isAddFormOpen && isRead) {
            docStore.getNestedDataObjects(docStore.selectedDataObject, selectedNestedDataModel, setNestedDataModels, setIsNestedLoading)    
        }
    }, [selectedNestedDataModel])
    
    useEffect(() => {
        const dataModelID = getIdFromPath(location.pathname, 2)
        if (docStore.dataModels && docStore.dataModels.length && dataModelID !== selectedFullDataModel?.id) {
            const foundDataModel = docStore.dataModels.find(dataModel => dataModel.id === dataModelID)
            if (foundDataModel) {
                handleDataModelSelect(foundDataModel)
            } else {
                docStore.setSelectedDataModel(null)
                docStore.setSelectedDataObject(null)
                setSelectedFullDataModel(null)
                sessionStorage.removeItem('activeDataModel')
            }
        }
    }, [location.pathname, docStore.dataModels])

    useEffect(()=>{
        if (docStore.isRequestFulfilled && searchedText !== '' && searchedText.includes('record_id=')){
            if (totalCount) {
                const recordID = searchedText.replace('record_id=', '')
                const foundDataObject = dataObjects.find(dataObject => dataObject.record_id === recordID)
                
                if (foundDataObject) {
                    const [isRecordWrite, isRecordWriteDescription] = checkMethodPermissions(foundDataObject, 'write')
                  
                    if (!isRecordWrite) {
                        const foundSubGroup = userStore.userSubCompanies.find(item => item.id === Number(foundDataObject.subgroup_id))
                        const userAdvice = foundSubGroup
                                            ?   parse(`<p>Смените юр.лицо на "${foundSubGroup.name}" для редактирования и сохранения изменений</p>`)
                                            :   parse('<p>Вы не сможете сохранить изменения, поскольку это юр.лицо Вам недоступно.<br/>Обратитесь с данной проблемой к администратору Медоед в Вашей компании</p>')
                        toast.warning(<div><p>Запись принадлежит другому юр.лицу!</p><br/>{userAdvice}</div>, 
                                    { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                    } else {
                        setSearchedText('')
                        docStore.setSelectedDataObject(foundDataObject)
                        setIsEditFormOpen(true)
                        setDataObjectID(foundDataObject.id)
                    }
                }
            } else {
                toast.error(<div><p>Запись по указанной ссылке не найдена!</p><br/>
                    <p>Возможно, данная запись принадлежит юр.лицу, которое Вам недоступно!</p>
                    <p>Обратитесь с данной проблемой к администратору Медоед в Вашей компании</p></div>, 
                    { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            }
        }
    }, [docStore.isRequestFulfilled])

    if (isLoading || docStore.isDataModelLoading) {
        return  <Spinner/>
    }

    return (
        <>
            <PageTitle title={'Редактирование данных'} />
            <div id='data-object-page' className='tw-grid tw-grid-cols-5 tw-gap-x-8 tw-h-full'>
                <div id='data-model-list' className='tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <div id='data-model-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                        <p className='tw-text-md tw-font-semibold'>Таблицы</p>
                    </div>
                    <DataModelCategoryList
                        selectedDataModel={selectedFullDataModel}
                        onItemClick={handleDataModelClick}
                        onItemDoubleClick={handleDataModelClick}
                    />
                </div>
                <div id='data-object-fields' className='tw-col-span-4 tw-h-full tw-overflow-hidden'>
                    <div id='data-object-menu' className='tw-flex tw-flex-row tw-items-center tw-bg-white tw-rounded-t-md
                        tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4'>
                        <DataObjectMenu
                            onAddItemClick={handleAddDataObjectClick}
                            onDuplicateItemClick={handleDuplicateClick}
                            onEditItemClick={handleEditDataObjectClick}
                            onViewRecordClick={handleViewRecordClick}
                            onItemHistoryClick={handleViewHistoryClick}
                            onDeleteItemClick={handleDeleteDataObjectClick}
                            selectedDataObject={docStore.selectedDataObject}
                            selectedFullDataModel={selectedFullDataModel}
                            handleImportFromFileClick={handleImportFromFileClick}
                            handleImportFromServerClick={handleImportFromServerClick}
                            handlePrintTableClick={handlePrintTableClick}
                            handlePrintRecordClick={handlePrintRecordClick}
                            handleOpenExportersClick={handleOpenExportersClick}
                            onCopyLinkClick={handleCopyToClipboardClick}
                            isDocumentPage={true}
                            updateDataObjectList={applySearchFilter}
                            filterStore={filterStore}
                        />
                    </div>
                    { docStore.isStartLoading
                        ?   <Spinner/>
                        :   <DirectoryPanel
                                onItemClick={handleDataObjectClick}
                                onItemDoubleClick={handleDataObjectDoubleClick}
                                selectedDataObject={docStore.selectedDataObject}
                                selectedFullDataModel={selectedFullDataModel}
                                dataObjects={dataObjects}
                                selectedNestedDataModel={selectedNestedDataModel}
                                nestedDataModels={nestedDataModels}
                                setSelectedNestedDataModel={setSelectedNestedDataModel}
                                setNestedDataModels={setNestedDataModels}
                                onSortClick={handleSortClick}
                                onFetchData={setIsFetchingData}
                                isNestedLoading={isNestedLoading}
                                filterStore={filterStore}
                                recordNotesStore={recordNotesStore}
                                isChosenObjectDuplicationForbidden={true}
                                onHierarchyClick={handleHierarchyClick}
                            />
                    }
                </div>
            </div>
            <PrintDocumentModal
                isVisible={isExporterSelecting}
                dataModelID={selectedFullDataModel?.id}
                isPrinting={isPrinting}
                onSubmitClick={handlePrintDocumentClick}
                onCloseModal={() => setIsExporterSelecting(false)}
            />
            <ImportFromFileModal 
                isVisible={isImporting}
                dataModel={selectedFullDataModel}
                files={importFiles}
                onFileSelect={handleFileSelect(importFiles, setImportFiles)}
                onFileRemove={handleFileRemove(importFiles, setImportFiles)}
                onSubmitClick={handleImportTableClick}
                onCloseModal={handleImportModalClose}
            />    
            <AddDataObjectContainer
                isOpen={isAddFormOpen}
                onCloseClick={handleObjectContainerCloseClick}
                selectedFullDataModel={selectedFullDataModel}
                recordNotesStore={recordNotesStore}
            />
            <DuplicateDataObjectContainer
                isOpen={isDuplicateFormOpen} 
                onCloseClick={handleObjectContainerCloseClick}
                id={dataObjectID}
                selectedFullDataModel={selectedFullDataModel}
                recordNotesStore={recordNotesStore}
            />
            <EditDataObjectContainer
                isOpen={isEditFormOpen} 
                onCloseClick={handleObjectContainerCloseClick}
                id={dataObjectID}
                selectedFullDataModel={selectedFullDataModel}
                recordNotesStore={recordNotesStore}
            />
            <ExportDataObjectContainer
                isOpen={isExportFieldsFormOpen}
                dataModel={selectedFullDataModel}
                onSubmitClick={handleExportDataobjectClick}
                onCloseClick={handleExportObjectCloseClick}
            />
        </>
    )
}

export default observer(DataObjectListContainer)
