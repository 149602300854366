import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import { generatePassword } from '../../../functions/passwordGenerator'

/**
 * Визуальный компонент отображает форму смены пароля авторизованного пользователя
 * 
 * @param {Function} onCloseClick Обработчик клика мыши для закрытия формы
 * 
 */
const ChangePasswordForm = ({ onCloseClick }) => {
    const { userStore } = useContext(Context)
    const [showOldPassword, setShowOldPassword] = useState(true)
    const [showNewPassword, setShowNewPassword] = useState(true)
    const [showRepeatPassword, setShowRepeatPassword] = useState(true)
    const [error, setError] = useState(false)
    const regexPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w])(?!.*[А-я])(?!.*[\s]).{8,}/g)

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid, isSubmitting },
    } = useForm()

    const onSubmitClick = (form) => {
        if(form.newPassword.trim() === form.repeatPassword.trim()){
            setError(false)
            const data = {}
            data.old_password = form.oldPassword
            data.new_password = form.newPassword

            if (!regexPassword.test(form.newPassword)) {
                toast.error(`Пароль должен быть не менее 8 символов, содержать прописные и строчные буквы латинского алфавита, спецсимволы и цифры.\n
                    Пароль не должен включать прописные и строчные буквы кириллического алфавита, а так же пробелы`, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
                setError(true)
            } else{
                userStore.changePassword(data)
                onCloseClick(false)
            }
        } else {
            setError(true)
            toast.error('Подтверждение не совпадает с паролем', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
        }
    }

    const onGeneratePasswordClick = (e) => {
        e.preventDefault()
        setValue('newPassword', generatePassword())
    }

    isSubmitting && !isValid && toast.error('Заполните обязательные поля', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })

    return (
        <form className="tw-relative tw-mt-6 tw-flex-1 tw-px-4 sm:tw-px-6">
            <div className="tw-px-4 tw-py-2 tw-flex tw-flex-col">
                <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                    <label htmlFor='oldPassword'>Текущий пароль</label>
                    <span>
                        { errors.oldPassword && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> }
                    </span>
                </dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-mt-0">
                    <div className='tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm'>
                        <input
                            id='oldPassword'
                            type={showOldPassword ? 'text' : 'password'}
                            className={`tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-2 tw-text-gray-700 
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 sm:tw-text-sm 
                                sm:tw-leading-6 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                ${errors.oldPassword ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                            `} 
                            {...register('oldPassword', {required: true})}

                        />
                        <div 
                            className='tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 hover:tw-cursor-pointer'
                            onClick={() => setShowOldPassword(!showOldPassword)}
                        >
                            { showOldPassword
                                ?   <EyeSlashIcon className="tw-h-5 tw-w-5 tw-text-gray-400"/>
                                :   <EyeIcon className="tw-h-5 tw-w-5 tw-text-gray-400"/>
                            }
                        </div>
                    </div>
                </dd>
            </div>
            <div className="tw-px-4 tw-py-2 tw-flex tw-flex-col">
                <dt className='tw-mt-4 tw-flex tw-flex-row tw-items-center tw-justify-between'>
                    <div className='tw-flex tw-flex-row tw-items-center'>
                        <label htmlFor="newPassword"  className="tw-block tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900">
                            Новый пароль
                        </label>
                        <span>
                            { (errors.newPassword || error) && 
                                <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> 
                            }
                        </span>
                    </div>
                    
                    <button 
                        className='tw-text-sky-700 tw-font-semibold  tw-text-xs
                                    hover:tw-text-sky-500 focus-visible:tw-outline-none focus-visible:tw-text-sky-500'
                        onClick={onGeneratePasswordClick}
                    >
                        Сгенерировать
                    </button>
                </dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-mt-0">
                    <div className='tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm'>
                        <input
                            id='newPassword'
                            type={showNewPassword ? 'text' : 'password'}
                            className={`tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-2 tw-text-gray-700 
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 sm:tw-text-sm 
                                sm:tw-leading-6 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                ${ (errors.newPassword || error) ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                            `}
                            {...register('newPassword', {required: true})}

                        />
                        <div 
                            className='tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 hover:tw-cursor-pointer'
                            onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                            { showNewPassword
                                ?   <EyeSlashIcon className="tw-h-5 tw-w-5 tw-text-gray-400"/>
                                :   <EyeIcon className="tw-h-5 tw-w-5 tw-text-gray-400"/>
                            }
                        </div>
                    </div>
                </dd>
            </div>
            <div className="tw-px-4 tw-py-2 tw-flex tw-flex-col">
                <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                    <label htmlFor='repeatPassword'>Повторите новый пароль</label>
                    <span>
                        { (errors.repeatPassword || error) && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> }
                    </span>
                </dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-mt-0">
                    <div className='tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm'>
                        <input
                            id='repeatPassword'
                            type={showRepeatPassword ? 'text' : 'password'}
                            className={`tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-2 tw-text-gray-700 
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 sm:tw-text-sm 
                                sm:tw-leading-6 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                ${ (errors.repeatPassword  || error) ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                            `}
                            {...register('repeatPassword', {required: true})}
                        />
                        <div 
                            className='tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 hover:tw-cursor-pointer'
                            onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                        >
                            { showRepeatPassword
                                ?   <EyeSlashIcon className="tw-h-5 tw-w-5 tw-text-gray-400"/>
                                :   <EyeIcon className="tw-h-5 tw-w-5 tw-text-gray-400"/>
                            }
                        </div>
                    </div>
                </dd>
            </div>

            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-py-4'>
                <button 
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={handleSubmit(onSubmitClick)}
                >
                    Сохранить
                </button>
                <button
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                            hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={(e) => {e.preventDefault(); onCloseClick(false)}}
                >
                    Отменить
                </button>
            </div>
        </form>         
    )
}

export default observer(ChangePasswordForm)