import React, { useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import Spinner from '../../assets/Spinner'
import FormFieldName from './FormFieldName'
import FormFieldInput from './FormFieldInput'
import NestedTableFieldsList from '../tabs/nested_tables/NestedTableFieldsList'
import NestedModelMenu from '../tabs/nested_tables/NestedModelMenu'


/**
 * Визуальный компонент отображает поле формы в зависимости от типа поля записи
 * 
 * @param {Object} fieldItem Поле формы
 * @param {Object[]} nestedModels Массив вложенных таблиц для редактируемой формы текущего этапа
 * @param {Object} selectedNestedValue Выбранная запись вложенной таблицы
 * @param {Object[]} errors Список ошибок
 * @param {Function} handleSelectClick Обработчик клика для открытия таблицы относящейся к полю ссылочного типа
 * @param {Function} handleClearClick Обработчик клика для очистки поля ссылочного типа
 * @param {Function} handleRecordChange Обработчик изменения значения в любом поле формы
 * @param {Boolean} isEditMode Признак режима редактирования
 * @param {Boolean} isDuplicateMode Признак режима дублирования
 * @param {Boolean} isLoading Признак загрузки
 * @param {Object} control Объект, реализующий регистрацию компонентов
 * @param {Function} register Метод, реализующий регистрацию поля UseForm
 * @param {Function} setValue Метод, реализующий сохранение значения поля UseForm
 * @param {Function} onSelectNestedValue Обработчик клика для выбора ячейки вложенной таблицы
 * @param {Function} onNestedTableChange Обработчик изменения значения вложенной таблицы
 * @param {Boolean} isChosenObjectDuplicationForbidden Признак запрета повторного добавления записи
 * 
 */
const FieldItem = ({fieldItem, nestedModels, selectedNestedValue, errors, handleSelectClick, handleClearClick, handleRecordChange, isEditMode, 
        isDuplicateMode, isLoading, control, register, setValue, onSelectNestedValue, onNestedTableChange, isChosenObjectDuplicationForbidden}) => {
    const [isNestedModelsShow, setIsNestedModelsShow] = useState(true)
    const [isAddFormOpen, setIsAddFormOpen] = useState(false)
    const [isDuplicateFormOpen, setIsDuplicateFormOpen] = useState(false)
    const [isEditFormOpen, setIsEditFormOpen] = useState(false)

    return (
        <div className={`${fieldItem.type === 'include' ? 'tw-my-4' : 'sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-py-2'}`}>
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-justify-between">
                <FormFieldName
                    item={fieldItem}
                    errors={errors}
                />
                { fieldItem.type === 'include' && 
                    <button 
                        type='button'
                        className='tw-p-0.5 tw-ml-1 hover:tw-text-gray-700 tw-flex tw-flex-row tw-items-center'
                        onClick={() => setIsNestedModelsShow(!isNestedModelsShow)}
                    >
                        { isNestedModelsShow
                            ?   <>
                                    <span>Свернуть</span>
                                    <ChevronUpIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                </>
                            :   <>
                                    <span>Развернуть</span>
                                    <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                </> 
                        }
                    </button> 
                }
            </dt>
            { fieldItem.type === 'include' 
                ?
                    (nestedModels.map((nestedModel, index) => {
                        if (nestedModel.rule_id === fieldItem.rule_id) {
                            return  <dd key={index} className={`${isNestedModelsShow ? 'tw-p-4' : 'tw-h-4 tw-overflow-hidden tw-pt-4'} tw-duration-500 tw-mt-1 tw-bg-gray-200 tw-rounded-md`}>
                                        { isLoading
                                            ?   <Spinner/>
                                            :   <>
                                                    <NestedModelMenu
                                                        selectedNestedDataModel={nestedModel}
                                                        selectedNestedDataObject={selectedNestedValue}
                                                        setSelectedNestedDataObject={onSelectNestedValue}
                                                        nestedDataModels={nestedModels}
                                                        isDocumentPage={false}
                                                        onChange={onNestedTableChange}
                                                        setIsAddFormOpen={setIsAddFormOpen}
                                                        setIsDuplicateFormOpen={setIsDuplicateFormOpen}
                                                        setIsEditFormOpen={setIsEditFormOpen}
                                                    />
                                                    <NestedTableFieldsList
                                                        readOnly={false}
                                                        dataModel={nestedModel}
                                                        dataObjects={nestedModel.dataObjects}
                                                        nestedDataModels={nestedModels}
                                                        selectedNestedDataObject={selectedNestedValue}
                                                        setSelectedNestedDataObject={onSelectNestedValue}
                                                        isDocumentPage={false}
                                                        onChange={onNestedTableChange}
                                                        isChosenObjectDuplicationForbidden={isChosenObjectDuplicationForbidden}
                                                        isAddFormOpen={isAddFormOpen}
                                                        setIsAddFormOpen={setIsAddFormOpen}
                                                        isDuplicateFormOpen={isDuplicateFormOpen}
                                                        setIsDuplicateFormOpen={setIsDuplicateFormOpen}
                                                        isEditFormOpen={isEditFormOpen}
                                                        setIsEditFormOpen={setIsEditFormOpen}
                                                    />
                                                </> 
                                        }
                                    </dd>
                        }
                    }))
                : 
                    <dd className="tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0 tw-mt-1">
                        <FormFieldInput
                            item={fieldItem}
                            isEditMode={isEditMode}
                            isDuplicateMode={isDuplicateMode}
                            onReferenceSelect={handleSelectClick}
                            onReferenceClear={handleClearClick}
                            onRecordChange={handleRecordChange}
                            register={register}
                            control={control}
                            setValue={setValue}
                            isError={errors?.data && errors?.data[fieldItem.tech_name]}
                        />
                    </dd>
            }
        </div>
    )
}

export default FieldItem
