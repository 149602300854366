import React, { useContext } from 'react'
import SubCompanyUserTable from './SubCompanyUserTable'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает служебную информацию о выбранном юр лице и список пользователей
 * 
 */
const SubCompanyInfo = () => {
    const { CompanyAdminStore } = useContext(Context)

    return (
        <>
            <dl id='sub-company-info' className='tw-py-1'>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Полное наименование</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{CompanyAdminStore.selectedSubCompany.name}</dd>
                </div>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Сокращенное наименование</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0 tw-truncate">{CompanyAdminStore.selectedSubCompany.abbreviation}</dd>
                </div>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ИНН</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{CompanyAdminStore.selectedSubCompany.tin}</dd>
                </div>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">КПП</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{CompanyAdminStore.selectedSubCompany.rrc}</dd>
                </div>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ОГРН</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{CompanyAdminStore.selectedSubCompany.msrn}</dd>
                </div>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ID</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{CompanyAdminStore.selectedSubCompany.id}</dd>
                </div>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Руководитель</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{CompanyAdminStore.selectedSubCompany.owner}</dd>
                </div>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Должность руководителя</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{CompanyAdminStore.selectedSubCompany.owner_position}</dd>
                </div>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Адрес регистрации</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{CompanyAdminStore.selectedSubCompany.registration_address}</dd>
                </div>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Статус</dt>
                    <dd className={`${!CompanyAdminStore.selectedSubCompany.is_active && 'tw-text-red-400'} tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0`}>
                        {CompanyAdminStore.selectedSubCompany.is_active ? "Активна" : "Не активна"}
                    </dd>
                </div>
            </dl>                
            <SubCompanyUserTable />
        </>
    )
}

export default observer(SubCompanyInfo)