import { makeAutoObservable, runInAction } from 'mobx'
import { responseTimeOut, serviceMessageTimeOut } from "../config/constTypes"
import DocumentService from '../services/DocumentService'
import CategoryService from '../services/CategoryService'
import ExporterService from '../services/ExporterService'
import { toast } from 'react-toastify'
import { showErrorToast, getErrorInfo } from '../functions/errorHandlers'
import { checkVersionsModification } from '../functions/checkVersionsModification'
import { findNestedModelName, findNestedModelType } from '../functions/nestedModels'
import { saveReceivedFile } from '../functions/fileHandlers'

/**
 * Класс реализует хранилище информации о полученных от сервера таблицах
 * @class
 * 
 * @property {Object[]} dataModels Массив существующих таблиц
 * @property {Object[]} dataObjects Массив существующих записей в таблице
 * @property {Object} selectedDataModel Информация о выбранной таблице
 * @property {Object} selectedDataObject Информация о выбранной записи таблицы
 * @property {Boolean} isDataModelLoading Признак загрузки таблицы
 * @property {Boolean} isDataObjectLoading Признак загрузки записи таблицы
 * @property {Boolean} isStartLoading Признак начальной загрузки записей таблицы
 * @property {Boolean} isHistoryView Признак видимости истории записи таблицы
 * @property {Boolean} isDetailView Признак видимости информации о записи таблицы
 * @property {Object[]} attachedFiles Массив файлов записи таблицы
 * @property {Object[]} selectedFilters  Массив используемых фильтров
 * @property {Object[]} selectedSorting  Массив используемых сортировок
 * @property {Number} totalCount Общее количество записей
 * @property {Number} dataQueryOffset Смещение при получении записей
 * @property {Boolean} isIncludeTypeField Признак редактирования значений включенной таблицы
 * @property {Object} includeField Поле, соответствующее включенной таблице
 * @property {String} tabsID ID панели, на которой располагаются вкладки с включенными таблицами
 * @property {Object[]} categories Массив существующих категорий таблиц
 * @property {Object[]} shownTypes  Массив видимых типов категорий
 * @property {Boolean} isShowInfo Признак видимости типа категории записи таблицы
 * @property {Object[]} dataModelCategories  Список категорий выбранной таблицы
 * @property {Object[]} categoryDataModels  Массив таблиц, относящихся к выбранной категории
 * @property {Object} selectedCategory  Выбранная категория
 * @property {Boolean} isCategoryCreating Признак создания категории
 * @property {Boolean} isCategoryEditing Признак редактирования категории
 * @property {String} moduleType Таблица, запись которой открывается со стартовой страницы
 * @property {String} moduleRecordID ID записи таблицы, которая открывается со стартовой страницы
 */
class DocumentStore {
    dataModels = []
    dataObjects = []
    selectedDataModel = null
    selectedDataObject = null
    isDataModelLoading = false
    isDataObjectLoading = false
    isStartLoading = false
    isHistoryView = false
    isDetailView = false
    attachedFiles = []
    selectedFilters = []
    selectedSorting = []
    totalCount = 0
    dataQueryOffset = 0
    isIncludeTypeField = false
    includeField = null
    tabsID = null
    isRequestFulfilled = false
    
    categories = []
    shownTypes = []
    isShowInfo = false
    dataModelCategories = []
    categoryDataModels = []
    selectedCategory = null
    isCategoryCreating = false
    isCategoryEditing = false

    moduleType = null
    moduleRecordID = null
    
    /**
     * Конструктор с указанием, что все свойства класса observable
     * @constructor
     */
    constructor() {
        makeAutoObservable(this)
    }

    /**
     * Метод сохраняет полученный от сервера массив существующих таблиц
     * @method
     * 
     * @param {Object[]} dataModels Массив существующих таблиц
     */
    setDataModels(dataModels) {
        this.dataModels = dataModels
    }

    /**
     * Метод сохраняет полученный от сервера массив записей выбранной таблицы
     * @method
     * 
     * @param {Object[]} dataObjects Массив записей выбранной таблицы
     */
    setDataObjects(dataObjects) {
        this.dataObjects = dataObjects
    }

    /**
     * Метод сохраняет полученную от сервера выбранную таблицу
     * @method
     * 
     * @param {Object} dataModel Выбранная таблица
     */
    setSelectedDataModel(dataModel) {
        this.selectedDataModel = dataModel
    }

    /**
     * Метод сохраняет полученную от сервера выбранную запись таблицы
     * @method
     * 
     * @param {Object} dataObject Выбранная запись таблицы
     */
    setSelectedDataObject(dataObject) {
        this.selectedDataObject = dataObject
    }

    /**
     * Метод сохраняет состояние процесса загрузки списка существующих таблиц
     * @method
     * 
     * @param {Boolean} bool Состояние процесса загрузки списка существующих таблиц
     */
    setIsDataModelLoading(bool) {
        this.isDataModelLoading = bool
    }

    /**
     * Метод сохраняет состояние процесса загрузки записей таблицы
     * @method
     * 
     * @param {Boolean} bool Состояние процесса загрузки списка записей таблицы
     */
    setIsDataObjectLoading(bool) {
        this.isDataObjectLoading = bool
    }

    /**
     * Метод сохраняет состояние процесса загрузки записей таблицы
     * @method
     * 
     * @param {Boolean} bool Состояние процесса загрузки списка записей таблицы
     */
    setIsStartLoading(bool) {
        this.isStartLoading = bool
    }

    /**
     * Метод сохраняет состояние просмотра истории изменений записи таблицы
     * @method
     * 
     * @param {Boolean} bool Состояние просмотра истории изменений записи таблицы
     */
    setIsHistoryView(bool) {
        this.isHistoryView = bool
    }

    /**
     * Метод сохраняет состояние детального просмотра записи таблицы
     * @method
     * 
     * @param {Boolean} bool Состояние детального просмотра записи таблицы
     */
    setIsDetailView(bool) {
        this.isDetailView = bool
    }

    /**
     * Метод сохраняет полученные от сервера описания файлов, прикрепленных к выбранной записи таблицы
     * @method
     * 
     * @param {Object[]} files Прикрепленные к записи файлы
     */
    setAttachedFiles(files) {
        this.attachedFiles = files
    }

    /**
     * Метод сохраняет выбранные пользователем фильтры
     * @method
     * 
     * @param {Object[]} filters Выбранные фильтры
     */
    setSelectedFilters(filters) {
        this.selectedFilters = filters
    }

    /**
     * Метод сохраняет общее количества записей в таблице
     * @method
     * 
     * @param {Number} totalCount Общее количество записей
     */
    setTotalCount(totalCount) {
        this.totalCount = totalCount
    }

    /**
     * Метод сохраняет текущее смещение для запрашиваемой порции записей относительно общего количества записей таблицы
     * @method
     * 
     * @param {Number} offset Смещение
     */
    setDataQueryOffset(offset) {
        this.dataQueryOffset = offset
    }

    /**
     * Метод сохраняет состояние признака редактирования значений поля типа "Include many"
     * @method
     * 
     * @param {Boolean} bool Состояние признака редактирования значений поля типа "Include many"
     */
    setIsIncludeTypeField(bool) {
        this.isIncludeTypeField = bool
    }

    /**
     * Метод сохраняет поле типа "Include many"
     * @method
     * 
     * @param {Object} field Поле типа "Include many"
     */
    setIncludeField(field) {
        this.includeField = field
    }
    
    /**
     * Метод сохраняет ID панели с вкладками
     * @method
     * 
     * @param {String} id ID панели с вкладками
     */
    setTabsID(id) {
        this.tabsID = id
    }
    
    /**
     * Метод сохраняет состояние признака успешного завершения запроса
     * @method
     * 
     * @param {Boolean} bool Состояние признака успешного завершения запроса
     */
    setIsRequestFulfilled(bool) {
        this.isRequestFulfilled = bool
    }

    // Методы для работы с категориями

    /**
     * Метод сохраняет полученный от сервера массив существующих категорий
     * @method
     * 
     * @param {Object[]} categories Массив существующих категорий
     */
    setCategories(categories) {
        this.categories = categories
    }

    /**
     * Метод сохраняет массив типов документов, отображаемых на данной странице
     * @method
     * 
     * @param {Object[]} types Массив отображаемых типов
     */
    setShownTypes(types) {
        this.shownTypes = types
    }

    /**
     * Метод сохраняет признак отображения дополнительной информации о типе таблицы в списке таблиц
     * @method
     * 
     * @param {Boolean} bool Признак отображения дополнительной информации о типе таблицы
     */
    setIsShowInfo(bool) {
        this.isShowInfo = bool
    }

    /**
     * Метод сохраняет вычисленный массив таблиц, относящихся к выбранной категории
     * @method
     * 
     * @param {Object[]} dataModels Массив таблиц
     */
    setCategoryDataModels(dataModels) {
        this.categoryDataModels = dataModels
    }

    /**
     * Метод сохраняет выбранную категорию
     * @method
     * 
     * @param {Object} dataModel Выбранная категория
     */
    setSelectedCategory(category) {
        this.selectedCategory = category
    }
    
    /**
     * Метод сохраняет состояние режима создания новой категории
     * @method
     * 
     * @param {Boolean} bool Состояние режима создания новой категории
     */
    setIsCategoryCreating(bool) {
        this.isCategoryCreating = bool
    }
    
    /**
     * Метод сохраняет состояние режима редактирования категории
     * @method
     * 
     * @param {Boolean} bool Состояние режима редактирования категории
     */
    setIsCategoryEditing(bool) {
        this.isCategoryEditing = bool
    }
    
    /**
     * Метод сохраняет список категорий выбранной таблицы
     * @method
     * 
     * @param {Object[]} categories Список категорий выбранной таблицы
     */
    setDataModelCategories(categories) {
        this.dataModelCategories = categories
    }

    /**
     * Метод сохраняет таблицу, запись которой открывается со стартовой страницы
     * @method
     * 
     * @param {String} dataModel Таблица
     */
    setModuleType(dataModel) {
        this.moduleType = dataModel
    }

    /**
     * Метод сохраняет ID записи таблицы, которая открывается со стартовой страницы
     * @method
     * 
     * @param {Boolean} id ID записи
     */
    setModuleRecordID(id) {
        this.moduleRecordID = id
    }

    /**
     * Метод осуществляет получение таблиц
     * @method
     * 
     * @param {Object[]} filter Примененные фильтры
     * @param {Object[]} sorter Примененные сортировки
     * @param {Function} onProcessData Метод дополнительной обработки полученных данных
     */
    getDataModels(filter, sorter, onProcessData) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setDataModels([])
            this.setIsDataModelLoading(false)
        }, responseTimeOut)

        this.setIsDataModelLoading(true)

        DocumentService
            .getDataModels(filter, sorter)
            .then(data => {
                clearTimeout(noResponse)
                this.setDataModels(data)
                if (onProcessData)
                    onProcessData(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                this.setDataModels([])                
                showErrorToast(error, 'fetching', '')
            })
            .finally(() => this.setIsDataModelLoading(false))
    }

    /**
     * Метод осуществляет получение выбранной таблицы
     * @method
     * 
     * @param {String} dataModelID ID выбранной таблицы
     * @param {Function} setDataModel Метод, сохраняющий полученную от сервера выбранную таблицу
     */
    getOneDataModel(dataModelID, setDataModel) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setDataModel({id:'0', entity_name: '', fields: []})
        }, responseTimeOut)

        DocumentService
            .getOneDataModel(dataModelID)
            .then(data => {
                clearTimeout(noResponse)
                setDataModel(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                setDataModel({id: '0', entity_name: '', fields: []})
            })
    }

    /**
     * Метод осуществляет получение выбранной вложенной таблицы
     * @method
     * 
     * @param {Object} dataModel Выбранная вложенная таблица
     * @param {Function} setSelectedNestedDataModel Метод, сохраняющий полученную от сервера выбранную вложенную таблицу
     */
    getNestedDataModel(dataModel, setSelectedNestedDataModel) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setSelectedNestedDataModel({id:'0', entity_name: '', fields: [], ui_options: dataModel.ui_options})
        }, responseTimeOut)

        DocumentService
            .getOneDataModel(dataModel.ref_model_ids[0])
            .then(data => {
                clearTimeout(noResponse)
                setSelectedNestedDataModel({...data, rule_id: dataModel.rule_id, ui_options: dataModel.ui_options})
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                setSelectedNestedDataModel({id:'0', entity_name: '', fields: [], ui_options: dataModel.ui_options})
            })
    }

    /**
     * Метод осуществляет получение выбранной вложенной таблицы
     * @method
     * 
     * @param {Object} dataModel Выбранная вложенная таблица
     * @param {Function} onProcessData Метод дополнительной обработки полученных данных
     */
    hideDataModel(dataModel, onProcessData) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        const editedDataModel = {}
        editedDataModel.id = dataModel.id
        editedDataModel.entity_name = dataModel.entity_name
        editedDataModel.hide_mark = !dataModel.hide_mark
        editedDataModel.type = dataModel.type
        
        DocumentService
            .editDataModel(dataModel.id, editedDataModel)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Таблица успешно сохранена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })

                const sorter = JSON.stringify([ {property: 'entity_name', desc: false} ])        
                this.getDataModels([], sorter, onProcessData)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    /**
     * Метод осуществляет получение записей выбранной вложенной таблицы для одной записи таблицы
     * @method
     * 
     * @param {Object} dataObject Запись таблицы
     * @param {Object} selectedNestedModel Выбранная вложенная таблица 
     * @param {Function} setNestedDataModels Метод, сохраняющий вложенные таблицы выбранной записи 
     */
    getNestedDataObjects = async (dataObject, selectedNestedModel, setNestedDataModels, setIsNestedLoading) => {
        try {
            setIsNestedLoading(true)
            const response = await DocumentService.getNestedDataObjects(50, [], [], dataObject.id, selectedNestedModel.rule_id)
            if (response) {
                const includeFields = Object.values(dataObject.data)
                    .sort((a, b) => a.order - b.order)
                    .filter(field => field.type === 'include' || (field.type === 'reference' && field.validator_type === 'many'))
                    .map(nestedModel => 
                        nestedModel.rule_id === selectedNestedModel.rule_id
                            ?   {...nestedModel, fieldName: findNestedModelName(dataObject, nestedModel), dataObjects: response}
                            :   {...nestedModel, fieldName: findNestedModelName(dataObject, nestedModel)}
                    ) 
                setNestedDataModels(includeFields)
                setIsNestedLoading(false)
            }
        } catch (error) {
            showErrorToast(error, 'fetching', '') 
            setIsNestedLoading(false)
        }
    }
    
    /**
     * Метод осуществляет получение всех записей всех вложенных таблиц для одной записи таблицы
     * @method
     * 
     * @param {Object} dataObject Запись таблицы
     * @param {Function} setNestedDataModels Метод, сохраняющий вложенные таблицы выбранной записи 
     * @param {Boolean} isDuplicate Признак дублирования записи 
     */
    getAllNestedDataObjects = (dataObject, setNestedDataModels, isDuplicate) => {
        const includeFields = Object.values(dataObject.data)
            .sort((a, b) => a.order - b.order)
            .filter(field => field.type === 'include' || (field.type === 'reference' && field.validator_type === 'many'))
        try {
            const nestedValueRequests = includeFields.map(includeField => DocumentService.getNestedDataObjects(50, [], [], dataObject.id, includeField.rule_id))
            Promise.all(nestedValueRequests)
                .then(responses => {
                    setNestedDataModels(includeFields.map((includeField, index) =>  {  
                        return {
                            ...includeField,
                            fieldName: findNestedModelName(dataObject, includeField),
                            ref_model_type: findNestedModelType(dataObject, includeField),
                            dataObjects: responses[index].map(response => {return {...response, status: isDuplicate ? 'added' : 'saved', field_validator_type: includeField.type + ':' + includeField.validator_type}}),
                        }
                    }))
                })
        } catch (error) {
            showErrorToast(error, 'fetching', '') 
            setNestedDataModels(includeFields.map((nestedModel) =>  {
                return {
                    ...nestedModel,
                    fieldName: findNestedModelName(dataObject, nestedModel), 
                    dataObjects: []
                }
            }))
        }
    }

    /**
     * Метод осуществляет получение одной записи таблицы
     * @method
     * 
     * @param {String} dataObjectID ID записи таблицы
     */
    getOneDataObject = async (dataObjectID) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        try {
            const dataObject = await DocumentService.getOneDataObject(dataObjectID)
            clearTimeout(noResponse)
            return dataObject
    
        } catch (error) {
            clearTimeout(noResponse)
            showErrorToast(error, 'fetching', '')
            return null            
        }
    }

    /**
     * Метод осуществляет запрос количества записей таблицы
     * @method
     * 
     * @param {Object} directory Выбранная таблица
     */
    getRecordCount = async (dataModel) => {
        try {
            const filter = JSON.stringify([
                {property: 'data_model_id', value: dataModel.id, operator: 'eq'},
                {property: 'active', value: true, operator: 'eq'}
            ])            
            const count = await DocumentService.getDataObjectCount(filter)
            if (count)
                return count
        } catch (error) {
            showErrorToast(error, 'fetching', '') 
        }

        return 0
    }

    /**
     * Метод осуществляет получение record_id для ссылочного поля
     * @method
     * 
     * @param {Object} field Поле
     */
    getRecordID = (field) => {
        if (field.value && field.value.values && field.value.values[0])
            return field.value.values[0].record_id
        else
            return null
    }

    /**
     * Метод осуществляет изменение категории выбранной таблицы
     * @method
     * 
     * @param {Object} dataModel Выбранная таблица
     * @param {Object[]} categories Массив категорий таблицы
     */
    updateDataModelCategories(dataModel, categories) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            runInAction(() => {
                this.setDataModels([])
                this.setIsDataModelLoading(false)
            })
        }, responseTimeOut)

        DocumentService
            .editDataModel(dataModel.id, {category_ids: categories, type: dataModel.type})
            .then(() => {
                clearTimeout(noResponse)
                const defaultFilter = []
                const defaultSorter = JSON.stringify([
                    {property: 'entity_name', desc: false},
                ])      
                this.getDataModels(defaultFilter, defaultSorter)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    /**
     * Метод осуществляет получение записей таблицы
     * @method
     * 
     * @param {Object[]} filter Примененные фильтры
     * @param {Object[]} sorter Примененные сортировки
     * @param {Boolean} isSelected Признак выбора записи 
     * @param {Function} setDataObjects Метод, сохраняющий записи выбранной таблицы
     * @param {Function} setSelectedDataObject Метод, сохраняющий выбранную запись таблицы   
     * @param {String} mode Тип доступа к записи (r - только чтение, w - чтение и запись)
     * @param {Object} selectedDataObject Выбранная запись таблицы
     */
    getDataObjectsByID = (filter, sorter, offset, dataObjects, setDataObjects, setTotalCount, setOffset, mode) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setDataObjects([])
            this.setIsDataObjectLoading(false)
        }, responseTimeOut)

        this.setIsDataObjectLoading(true)
        this.setIsRequestFulfilled(false)

        DocumentService
            .getDataObjects(50, offset, filter, sorter, mode)
            .then(([data, totalCount]) => {
                clearTimeout(noResponse)
                const updatedData = data.map(item => {return {...item, hierarchyVisible: false, hierarchyLevel: 0}})
                if (!offset) {
                    setDataObjects(updatedData)
                } else {
                    const addedData = dataObjects.concat(updatedData)
                    setDataObjects(addedData)    
                }
                setTotalCount(Number(totalCount))
                setOffset(offset + data.length)
                this.setIsRequestFulfilled(true)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                setDataObjects([])
            })
            .finally(() => this.setIsDataObjectLoading(false))
    }

    /**
     * Метод осуществляет получение истории записи таблицы
     * @method
     * 
     * @param {Object[]} filter Примененные фильтры
     * @param {Object[]} sorter Примененные сортировки
     * @param {Boolean} isStartLoading Признак типа записи 
     * @param {Function} setDataObjects Метод, сохраняющий записи выбранной таблицы
     */
    getDataObjectHistory = (filter, sorter, isStartLoading, setDataObjects) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            isStartLoading ? this.setIsStartLoading(false) : this.setIsDataObjectLoading(false)
        }, responseTimeOut)

        isStartLoading ? this.setIsStartLoading(true) : this.setIsDataObjectLoading(true)

        DocumentService
            .getAllDataObjects(50, filter, sorter)
            .then(data => {
                clearTimeout(noResponse)
                setDataObjects(checkVersionsModification(data))
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
        
        .finally(() =>  isStartLoading ? this.setIsStartLoading(false) : this.setIsDataObjectLoading(false))
    }

    /**
     * Метод осуществляет создание новой записи таблицы
     * @method
     * 
     * @param {Object} dataObject ID выбранной записи
     * @param {Function} setDataObjects Метод, сохраняющий записи выбранной таблицы
     * @param {Function} setSelectedDataObject Метод, сохраняющий выбранную запись таблицы
     * @param {String} selectedDataModelID ID выбранной таблицы
     */
    createDataObject(dataObject, fields, setIsLoading, setErrors, onCloseClick) {
        setIsLoading(true)
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        DocumentService
            .createDataObject(dataObject)
            .then(() => {
                toast.success('Информация успешно сохранена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                onCloseClick(false)
            })
            .catch(error => {
                const [errorMessage, errorInfoObject] = getErrorInfo(error, fields)
                setErrors(errorInfoObject)
                showErrorToast(error, 'saving', errorMessage)
            })
            .finally(() => {
                clearTimeout(noResponse)
                setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет создание новой записи таблицы
     * @method
     * 
     * @param {Object} dataObject ID выбранной записи
     * @param {Function} setDataObjects Метод, сохраняющий записи выбранной таблицы
     * @param {Function} setSelectedDataObject Метод, сохраняющий выбранную запись таблицы
     * @param {String} selectedDataModelID ID выбранной таблицы
     */
    createDataObjectsPool(dataObjects, fields, setIsLoading, setErrors, onCloseClick) {
        setIsLoading(true)
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        DocumentService
            .createDataObjectsPool(dataObjects)
            .then(() => {
                toast.success('Информация успешно сохранена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                onCloseClick(false)
            })
            .catch(error => {
                const [errorMessage, errorInfoObject] = getErrorInfo(error, fields)
                setErrors(errorInfoObject)
                showErrorToast(error, 'saving', errorMessage)
            })
            .finally(() => {
                clearTimeout(noResponse)
                setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет редактирование существующей записи таблицы
     * @method
     * 
     * @param {String} id ID выбранной записи
     * @param {Function} setDataObjects Метод, сохраняющий записи выбранной таблицы
     * @param {Function} setSelectedDataObject Метод, сохраняющий выбранную запись таблицы
     * @param {String} selectedDataModelID ID выбранной таблицы
     */
    updateDataObject(id, dataObject, fields, setIsLoading, setErrors, onCloseClick) {
        setIsLoading(true)
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        DocumentService
            .updateDataObject(id, dataObject)
            .then(() => {
                toast.success('Информация успешно сохранена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                onCloseClick(false)
            })
            .catch(error => {
                const [errorMessage, errorInfoObject] = getErrorInfo(error, fields)
                setErrors(errorInfoObject)
                showErrorToast(error, 'saving', errorMessage)
            })
            .finally(() => {
                clearTimeout(noResponse)
                setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет удаление записи таблицы
     * @method
     * 
     * @param {String} id ID выбранной записи
     * @param {Function} setDataObjects Метод, сохраняющий записи выбранной таблицы
     * @param {Function} setSelectedDataObject Метод, сохраняющий выбранную запись таблицы
     * @param {String} selectedDataModelID ID выбранной таблицы
     */
    deleteDataObject(id, mark, dataObjects, setDataObjects, selectedDataModelID,  setTotalCount, setDataObjectOffset) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        DocumentService
            .deleteDataObject(id, {system_data: {deletion_mark: mark}})
            .then(() => {
                clearTimeout(noResponse)

                const filter = JSON.stringify([
                    {property: 'data_model_id', value: selectedDataModelID, operator: 'eq'},
                    {property: 'transaction_id', value: null, operator: 'eq'},
                    {property: 'active', value: true, operator: 'eq'}
                ])
                const sorter =[]
                this.getDataObjectsByID(filter, sorter, 0, dataObjects, setDataObjects,  setTotalCount, setDataObjectOffset)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'deleting', '')
            })            
    }

    exportDataObject(dataModel, type, data, fields) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис экспорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        let filter

        if (data === 'table') {
            filter = JSON.stringify([
                {property: 'data_model_id', value: dataModel.id, operator: 'eq'},
                {property: 'active', value: true, operator: 'eq'},
            ])
        }

        if (data === 'record') {
            if (this.isHistoryView) {
                filter = JSON.stringify([
                    {property: 'record_id', value: this.selectedDataObject.record_id, operator: 'eq'},
                ])
            } else {
                filter = JSON.stringify([
                    {property: 'data_model_id', value: dataModel.id, operator: 'eq'},
                    {property: '_key', value: [this.selectedDataObject.id], operator: 'in'},
                ])
            }
        }

        const selectedFields = JSON.stringify(fields)

        ExporterService
            .exportDataObjectToTable(type, filter, selectedFields)
            .then((blob) => {
                clearTimeout(noResponse)
                saveReceivedFile(blob, dataModel.entity_name + '.' + type)
            })            
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'export', '')
            })
    }
    
    /**
     * Метод осуществляет сохранение в хранилище сортировки записей выбранной таблицы
     * @method
     * 
     * @param {String} dataModelID ID выбранной таблицы
     * @param {String} column Столбец, по которому выполняется сортировка
     * @param {String} direction Направление сортировки
     */
    setSelectedSorting(column, direction) {
        let editedSorting = []
        if (this.selectedSorting.find(item => item.data_model_id === column.data_model_id)) {
            editedSorting = this.selectedSorting.map(item => 
                item.data_model_id === column.data_model_id
                    ?   {...column, direction: direction}
                    :   item
            )
        } else {
            editedSorting = [...this.selectedSorting, {...column, direction: direction}]
        }
        this.selectedSorting = editedSorting
        localStorage.setItem('sortingParameters', JSON.stringify(editedSorting))
    }

    /**
     * Метод осуществляет загрузку из хранилища сортировки записей выбранной таблицы
     * @method
     * 
     * @param {String} dataModelID ID выбранной таблицы
     */
    loadSavedSorting(dataModelID) {
        const savedSorting = JSON.parse(localStorage.getItem('sortingParameters'))
        if (savedSorting && Array.isArray(savedSorting)) {
            this.selectedSorting = savedSorting
            const foundSorting = savedSorting.find(item => item.data_model_id === dataModelID)
            if (foundSorting) {
                return foundSorting
            }
        }

        return {data_model_id: dataModelID, column_tech_name: 'created', column_validator_type: 'date', direction: 'down', column_rule_id: ''}
    }

    /**
     * Метод осуществляет получение дочерних записей текущей записи иерархической таблицы
     * @method
     * 
     * @param {Object[]} dataObjects Отображаемые записи таблицы
     * @param {Object} dataObject Текущая запись таблицы
     * @param {Object[]} filters Примененные фильтры
     * @param {String} sorting Сортировка
     */
    async showTreeNode(dataObjects, dataObject, filters, sorting) {
        let editedDataObjects = dataObjects.slice()
        try {
            const selectedFilters = JSON.stringify(filters)
            const sorter = JSON.stringify(sorting)
            const data = await DocumentService.getChildDataObjects(50, selectedFilters, sorter, dataObject.id)
            editedDataObjects = dataObjects.map(item => item.id === dataObject.id ? {...item, hierarchyVisible: true} : item)
            if (data.length) {
                const currentLevel = dataObject.hierarchyLevel + 1
                const updatedData = data.map(item => {return {...item, hierarchyVisible: false, hierarchyLevel: currentLevel}})
                const parentIndex = dataObjects.findIndex(item => item.id === dataObject.id)
                editedDataObjects.splice(parentIndex + 1, 0, ...updatedData)
            }                    
        } catch (error) {
            showErrorToast(error, 'fetching', '')
        }

        return editedDataObjects
    }

    /**
     * Метод осуществляет скрытие всех дочерних записей текущей записи иерархической таблицы
     * @method
     * 
     * @param {Object[]} dataObjects Отображаемые записи таблицы
     * @param {Object} dataObject Текущая запись таблицы
     */
    hideTreeNode(dataObjects, dataObject) {
        let hiddenRecords = [dataObject.record_id]
        dataObjects.forEach(item => {
            if (item.system_data && hiddenRecords.includes(item.system_data.parent_record_id))
                hiddenRecords.push(item.record_id)
        })
        const editedDataObjects = dataObjects
                                    .map(item => item.id === dataObject.id ? {...item, hierarchyVisible: false} : item)
                                    .filter(item => !item.system_data || !hiddenRecords.includes(item.system_data.parent_record_id))

        return editedDataObjects
    }

    /**
     * Метод осуществляет проверку таблицы на осуществление импорта данных (наличие активных транзакций)
     * @method
     * 
     * @param {String} dataModelID ID таблицы
     */
    async checkDataModelLock(dataModelID) {
        try {
            const isDataModelLocked = await DocumentService.checkDataModelLock(dataModelID)
            return isDataModelLocked
        } catch (error) {
            showErrorToast(error, 'fetching', '')
            return false
        }
    }

    async sendActionData(dataObject, dataModelID) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        try {           
            clearTimeout(noResponse)
            const response = await DocumentService.performDataModelAction(dataObject, dataModelID, 'edit')
            return response.data

        } catch (error) {
            clearTimeout(noResponse)
            showErrorToast(error, 'fetching', '')
            return {data: {}}
        }
    }

    // метод получает значения полей формы
    setDataObjectValue(value) {
        if (Array.isArray(value)) {
            if (value[0]?.value?.values?.length)
                return [value[0].value.values[0].record_id]
            else 
                return null
        } else if (typeof value === 'object') {
            if (value !== undefined && value !== null && value !== '')
                return value.id || value
            else
                return null
        } else if (typeof value === 'boolean') {
            if(value !== null)
                return value
            else
                return false
        } else if (typeof value === 'number') {
            if(value !== null && value !== '' && !isNaN(value))
                return value
            else
                return null
        } else if (typeof value === 'string') {
            if (value !== undefined && value?.trim() !== '')
                return value.trim()
            else if (value?.trim() === '')
                return null
        } else {
            if (value !== undefined && value !== null && value !== '')
                return value.id || value
            else if (value === '' || value === null)
                return null
        }
        return null
    }

    /**
     * Метод осуществляет обработку действия типа 'edit' при изменении поля типа 'Reference'
     * @method
     * 
     * @param {String} fieldName Имя поля в форме редактирования
     * @param {Object} selectedValue Выбранное значение поля
     * @param {Object} dataModel Таблица
     * @param {Object[]} fields Все поля формы редактирования
     * @param {Function} setValue Функция заполнения значения поля в форме редактирования
     * @param {Function} getValues Функция получения значений полей в форме редактирования
     */
    async performReferenceFieldAction(fieldName, selectedValue, dataModel, fields, setValue, getValues) {
        if (dataModel.actions && dataModel.actions.length > 0) {
            if (dataModel.actions.some(item => item.action === 'edit')) {
                const selectedField = fieldName.split('.')[1]
                const dataObject = {}
                dataObject.data = {}
                dataObject.data_model_id = dataModel.id
                const formFields = getValues()
                for (const [key, value] of Object.entries(formFields.data)) {
                    if (key === selectedField)
                        dataObject.data[selectedField] = [selectedValue.record_id]
                    else {
                        dataObject.data[key] = this.setDataObjectValue(value)
                    }
                }
                
                const record = await this.sendActionData(dataObject, dataModel.id)
                for (const [key, value] of Object.entries(record)) {
                    const foundField = fields.find(field => field.tech_name === value.tech_name)
                    if (foundField) {
                        if (foundField.type === 'reference') {
                            setValue('data.' + key +'.0', {value: value.value})
                        } else {
                            if (foundField.validator_type === 'date') {
                                const dateValue = value.value ? new Date(value.value) : null
                                setValue('data.' + key, dateValue)
                            } else
                                setValue('data.' + key, value.value)
                        }
                    }
                }
            }
        }
    }

    /**
     * Метод осуществляет обработку действия типа 'edit' при изменении поля скалярного типа
     * @method
     * 
     * @param {Object} dataModel Таблица
     * @param {Object[]} fields Все поля формы редактирования
     * @param {Function} setValue Функция заполнения значения поля в форме редактирования
     * @param {Function} getValues Функция получения значений полей в форме редактирования
     */
    async performScalarFieldAction(dataModel, fields, setValue, getValues) {
        if (dataModel.actions && dataModel.actions.length > 0) {
            if (dataModel.actions.some(item => item.action === 'edit')) {
                const dataObject = {}
                dataObject.data = {}
                dataObject.data_model_id = dataModel.id
                const formFields = getValues()
                for (const [key, value] of Object.entries(formFields.data)) {
                    dataObject.data[key] = this.setDataObjectValue(value)
                }
                
                const record = await this.sendActionData(dataObject, dataModel.id)
                for (const [key, value] of Object.entries(record)) {
                    const foundField = fields.find(field => field.tech_name === value.tech_name)
                    if (foundField) {
                        if (foundField.type === 'reference') {
                            setValue('data.' + key +'.0', {value: value.value})
                        } else {
                            if (foundField.validator_type === 'date') {
                                const dateValue = value.value ? new Date(value.value) : null
                                setValue('data.' + key, dateValue)
                            } else
                                setValue('data.' + key, value.value)
                        }
                    }
                }
            }
        }
    }

    /**
     * Метод осуществляет получение списка категорий
     * @method
     * 
     * @param {Object[]} filter Примененные фильтры
     * @param {Object[]} sorter Примененные сортировки
     */
    getCategories(filter, sorter) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        CategoryService
            .getCategories(50, filter, sorter)
            .then(data => {
                clearTimeout(noResponse)
                this.setCategories(data)
                if (this.selectedCategory) {
                    const foundCategory = data.find(category => category.id === this.selectedCategory.id)
                    this.setSelectedCategory(foundCategory ? foundCategory : null)
                }
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
    }

    /**
     * Метод осуществляет создание категории
     * @method
     * 
     * @param {Object} category Категория
     */
    createCategory(category) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        CategoryService
            .createCategory(category)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Категория успешно создана', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                
                const defaultFilter = []
                const defaultSorter = JSON.stringify([
                    {property: 'order', desc: false},
                ])      
                this.getCategories(defaultFilter, defaultSorter)
                this.setIsCategoryCreating(false)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    /**
     * Метод осуществляет редактирование категории
     * @method
     * 
     * @param {Object} category Категория
     */
    editCategory(category) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        CategoryService
            .editCategory(this.selectedCategory.id, category)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Категория успешно обновлена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })

                const defaultFilter = []
                const defaultSorter = JSON.stringify([
                    {property: 'order', desc: false},
                ])      
                this.getCategories(defaultFilter, defaultSorter)
                this.setIsCategoryEditing(false)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    /**
     * Метод осуществляет перемещение категории вверх
     * @method
     */
    moveUpCategory() {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        CategoryService
            .moveCategory(this.selectedCategory.id, -1)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Категория успешно обновлена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })

                const defaultFilter = []
                const defaultSorter = JSON.stringify([
                    {property: 'order', desc: false},
                ])      
                this.getCategories(defaultFilter, defaultSorter)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    /**
     * Метод осуществляет перемещение категории вниз
     * @method
     */
    moveDownCategory() {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        CategoryService
            .moveCategory(this.selectedCategory.id, 1)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Категория успешно обновлена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })

                const defaultFilter = []
                const defaultSorter = JSON.stringify([
                    {property: 'order', desc: false},
                ])      
                this.getCategories(defaultFilter, defaultSorter)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    /**
     * Метод осуществляет удаление категории
     * @method
     */
    deleteCategory() {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        CategoryService
            .deleteCategory(this.selectedCategory.id)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Категория успешно удалена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                
                const dataModelFilter = []
                const dataModelSorter = JSON.stringify([
                    {property: 'entity_name', desc: false},
                ])      
                this.getDataModels(dataModelFilter, dataModelSorter)

                const categoryFilter = []
                const categorySorter = JSON.stringify([
                    {property: 'order', desc: false},
                ])      
                this.getCategories(categoryFilter, categorySorter)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'deleting', '')
            })
    }
}

export default DocumentStore
