import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../../..'
import parse from 'html-react-parser'
import { toast } from 'react-toastify'
import ObjectListPanel from '../../../common/panels/object_list/ObjectListPanel'
import PDataStageButtons from '../../stage/PDataStageButtons'
import Spinner from '../../../../../../assets/Spinner'
import PDataProcessForm from './PDataProcessForm'
import DirectoryContainer from '../../../common/panels/directory/DirectoryContainer'
import DocumentService from '../../../../../../services/DocumentService'
import DialogTab from '../../../../../dialog_tab/DialogTab'
import { showErrorToast } from '../../../../../../functions/errorHandlers'

/**
 * Визуальный компонент отображает этап заполнения процессов обработки (направлений деятельности)
 * 
 */
const PDataProcessListContainer = () => {
    const { personalDataStore, DialogTabStore } = useContext(Context)
    const [objectsList, setObjectsList] = useState([]) 
    const [objectsListStage, setObjectsListStage] = useState({}) 
    const [lastObjectSaved, setLastObjectSaved] = useState(false) 
    const [stageListWasHidden, setStageListWasHidden] = useState(false)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [deletingItem, setDeletingItem] = useState(null)

    const handleBackClick = () => {
        personalDataStore.goPrevStage()
        !stageListWasHidden && personalDataStore.setHideStageList(false)
    }

    const handleForwardClick = async () => {
        personalDataStore.goNextStage()
        !stageListWasHidden && personalDataStore.setHideStageList(false)
    }

    const ObjectsListButtonsBackClick = () =>{
        setObjectsListStage(objectsList[objectsListStage.value - 1])
    }
    
    const ObjectsListButtonsForwardClick = () =>{
        setObjectsListStage(objectsList[objectsListStage.value + 1])
    }

    const markObjectAsFinished = (finished) =>{
        setObjectsList(objectsList.map(object => 
            object.value === objectsListStage.value
                ?   {...object, status: finished ? 'finished' : 'empty'}
                :   object
        ))
    }

    const handleDoubleClick = (item) => {
        if(objectsList.find((object) => object.object_record_id === item.record_id))
            toast.error('Вы уже добавили этот объект!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
        else{
            let obj = [{
                name: item.data.name.value,
                object_record_id: item.record_id,
                record_id: Date.now() + '_' + objectsList.length,
                object_status: 'added',
                value: 0,
                status: 'empty',
                hidden: false,
                help: 'Заполните и распечатайте информацию о процессе обработки'
            }],
            objList = obj.concat(objectsList)
            setObjectsList(objList.map((item, index) => {return {...item, value: index}}))
            setObjectsListStage(objList[0])
            setLastObjectSaved(false)
            setIsSelectFormOpen(false)
        }
    }

    const handleAskForDeleteClick = (item) => {
        let isDeleteAllowed = true

        if (!lastObjectSaved) {
            if ( item.status === 'finished') {
                isDeleteAllowed = false
                toast.error(`Сохраните процесс "${objectsList[0].name}"`, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
            }            
        }

        if (isDeleteAllowed) {
            setDeletingItem(item)
            DialogTabStore.setParentName('PDataProcessListContainer')
            DialogTabStore.setDialogTabTitle('Удаление процесса (направления деятельности)') 
            DialogTabStore.setDialogTabText(parse(`Вы уверены, что хотите удалить процесс<br/>"${item.name}"?`))
            DialogTabStore.setDialogTabButtons(["Да", "Нет"])
            DialogTabStore.setDialogTabIsOpen(true)
        }
    }

    const handleDeleteObjectClick = async () => {
        DialogTabStore.setDialogTabIsOpen(false) 

        if (deletingItem) {
            const foundObj = objectsList.find(object => 
                deletingItem.record_id === object.record_id
            )

            if(foundObj.object_status === 'added'){
               setObjectsList(objectsList.filter((object) => object.record_id !== deletingItem.record_id))
            } else {
                const project = {}
                project.data_model_id = 'stages_of_documentation_pdn'
                project.data = {} 
            
                project.data['process_of_lines_areas_of_activity'] = {
                    upsert: [],
                    remove: [{record_id: deletingItem.record_id}]
                }
    
                try {
                    const savedProject = await DocumentService.updateDataObject(personalDataStore.project.record_id, project)
                    await personalDataStore.resetPrintPolicyStage(personalDataStore.project.record_id)
                    
                    loadObjectsList(true)
                } catch (error) {
                    showErrorToast(error, 'saving', '')
                }
            }
        } else {
            toast.error(<div>Ошибка при удалении процесса! <br/><br/> Не выбран процесс</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }

    const handleAddObjectClick = () => {
        setIsSelectFormOpen(true)
    }

    const loadObjectsList = (isDeleted) => {
        let objList = [],
            foundIndex

        personalDataStore.processList
            .forEach((item, index) => {
                foundIndex = objectsList.find(obj => obj.record_id === item.record_id)

                objList.push({
                    name: item.data['area_of_activity'].value.values[0].name,
                    object_record_id: item.data['area_of_activity'].value.values[0].record_id,
                    record_id: item.record_id,
                    id: item.id,
                    object_status: 'saved',
                    value: foundIndex && !isDeleted ? foundIndex.value : index,
                    status: 'finished', //saved document
                    hidden: false,
                    help: 'Заполните и сохраните информацию о процессе обработки'
                })
            })

        if (objList.length === 0 && isDeleted) {
            setLastObjectSaved(false)
            personalDataStore.updateCurrentStage(true, true)
        }

        setObjectsList(objList.sort((a,b) => a.value - b.value))
        setObjectsListStage(foundIndex && !isDeleted ? objList[objectsListStage.value] : objList[0])
    }

    useEffect(() => {
        if (personalDataStore.processList)
            loadObjectsList(false)
        setStageListWasHidden(personalDataStore.hideStageList)
        personalDataStore.setHideStageList(true)
    }, [])

    useEffect(() => {
        if (personalDataStore.processList)
            loadObjectsList(false)
    }, [personalDataStore.processList])

    return (
        <>
            <div id='process-page' className='tw-grow tw-overflow-auto'>
                <div className='tw-grid tw-grid-cols-4 tw-gap-x-8 tw-max-h-full tw-h-full'>
                    { objectsList.length >= 0
                        ?
                            <>
                                <ObjectListPanel
                                    label='Процессы'
                                    list={objectsList}
                                    stage={objectsListStage}
                                    isListSaved={lastObjectSaved}
                                    onAddClick={handleAddObjectClick}
                                    onDeleteClick={handleAskForDeleteClick}
                                    onJumpStageClick={() => {}}
                                    isSearch={false}
                                    isSelect={false}
                                />
                                <div id='process-form-column' className='tw-col-span-3 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden tw-flex tw-flex-col'>
                                    {objectsList.length > 0
                                        ? <>
                                            <div className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                                                <p className='tw-text-md tw-font-semibold'>Процесс "{objectsListStage.name}"</p>
                                            </div>
                                                <PDataProcessForm 
                                                    onBackClick={ObjectsListButtonsBackClick}
                                                    onForwardClick={ObjectsListButtonsForwardClick}
                                                    stage={objectsListStage}
                                                    objectsList={objectsList}
                                                    setLastObjectSaved={setLastObjectSaved}
                                                    markObjectAsFinished={markObjectAsFinished}
                                                />
                                            </>
                                        :
                                            <p className='tw-text-md tw-font-semibold tw-mt-2 tw-text-center'>
                                                Для заполнения процесса обработки необходимо выбрать направление деятельности
                                            </p>
                                    }
                                </div>
                            </>
                        :
                            <div className='tw-col-span-4'>
                                <Spinner />
                            </div>               
                    }
                </div>
            </div>
            <PDataStageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleForwardClick}
                disabled={!lastObjectSaved}
            />
            <DirectoryContainer
                isOpen={isSelectFormOpen}
                selectedDataModel={'areas_of_activity'}
                onDoubleClick={handleDoubleClick}
                onCloseClick={() => setIsSelectFormOpen(false)}
                chosenDataObjectsList={personalDataStore.processList}
            />
            <DialogTab
                parentName='PDataProcessListContainer'
                dialogTabFunction={handleDeleteObjectClick}
            />
        </>
    )
}

export default observer(PDataProcessListContainer)