import { Popover, Transition } from '@headlessui/react'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import ReportPlannerPanel from './ReportPlannerPanel'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает меню отчетов по модулю Планировщик
 * 
 */
function ReportPlannerMenu() {
    const { userStore } = useContext(Context)
    const [className, setClassName] = useState('tw-text-sm tw-text-left tw-w-full tw-pl-4 tw-pr-2 tw-py-2 tw-flex tw-flex-row tw-justify-between tw-pointer-events-none tw-text-gray-400')

    useEffect(() => {
        if (userStore.modulesPermissions) {
            if (userStore.modulesPermissions?.['planner_only'])
                setClassName('tw-text-sm tw-text-left tw-w-full tw-pl-4 tw-pr-2 tw-py-2 tw-flex tw-flex-row tw-justify-between tw-text-gray-700 hover:tw-text-gray-900 hover:tw-bg-gray-200')
        }
    }, [userStore.modulesPermissions])

    return (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <Popover.Button className={className}>
                        <span>Планировщик</span>
                        <ChevronRightIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="tw-transition tw-ease-out tw-duration-200"
                        enterFrom="tw-opacity-0"
                        enterTo="tw-opacity-100"
                        leave="tw-transition tw-ease-in tw-duration-150"
                        leaveFrom="tw-opacity-100"
                        leaveTo="tw-opacity-0"
                    >
                        <Popover.Panel className="tw-absolute tw-z-10 tw-py-1 tw-w-72 tw-max-h-[calc(100vh_-_9rem)] tw-max-w-sm 
                                    tw-translate-x-full -tw-translate-y-8 tw-transform tw-overflow-auto
                                    tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none tw-rounded-md"
                        >
                            <ReportPlannerPanel/>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}

export default observer(ReportPlannerMenu)
