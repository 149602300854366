import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../../../..'
import { toast } from 'react-toastify'
import PDataStageButtons from '../stage/PDataStageButtons'
import { observer } from 'mobx-react-lite'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import Spinner from '../../../../../assets/Spinner'
import { useForm } from 'react-hook-form'
import FieldItem from '../../../../form_fields/FieldItem'
import DocumentService from '../../../../../services/DocumentService'
import FormErrorToastPanel from '../../common/panels/toast/FormErrorToastPanel'
import { processData } from '../../../../../functions/forms'
import { printDocumentPacket } from '../../../../../functions/fileHandlers'


/**
 * Визуальный компонент отображает этап печати финального пакета документов по ПДн
 * 
 */
const PDataPrintTaskForm = () => {   
    const { personalDataStore } = useContext(Context)
    const [isPrintingDocument, setIsPrintingDocument] = useState(false)
    const [formNestedModels, setFormNestedModels] = useState(null)
    const [formFields, setFormFields] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)

    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm()

    const handlePrintDocument = async (form) => {
        setIsPrintingDocument(true)
        const dataObject = processData(form.data, personalDataStore.activeDataModel)

        try {
            await personalDataStore.updateProject('date_of_print_policy_pdn', Date.now(), true)

            if (personalDataStore.printingDatesFld && personalDataStore.printingDatesFld.values.length > 0) {
                await DocumentService.updateDataObject(personalDataStore.printingDatesFld.values[0].record_id, dataObject)
                personalDataStore.loadSavedProject(personalDataStore.project.record_id)
            } else {
                const response = await DocumentService.createDataObject(dataObject)
                await personalDataStore.updateProject('order_numbers_and_pdn_printing_dates_fld', [response.record_id], true)
            }

            let pdnInfo = personalDataStore.project.id

            const documents = {}
            documents.templates = {
                "act_of_commissioning_ispdn": pdnInfo,
                "act_of_grade_report_levels_protection": pdnInfo,
                "act_on_assessing_harm_to_pdn_subjects": pdnInfo,
                "instructions_for_protecting_paper_media": pdnInfo,
                "regulation_protection_pdn": pdnInfo,
                "policy_protection_pdn": pdnInfo,
                "policy_processing_pdn": pdnInfo,
                "policy_processing_pdn_for_site": pdnInfo,
                "appendix_on_third_party_processors": pdnInfo,
                "technical_specifications_for_szpdn": pdnInfo,
                "notification_to_rkn": pdnInfo
            }

            printDocumentPacket('pdn_finally_package_result', documents, setIsPrintingDocument)

            personalDataStore.setAllStages(personalDataStore.allStages.map(stage => 
                stage.data['id_of_stage'].value === personalDataStore.actualStageIndex
                    ?   {...stage, status: 'finished'}
                    :   stage
            ))

        } catch (error) {
            setIsPrintingDocument(false)
            showErrorToast(error, 'printing', '')
        }
    }

    const handleBackClick = () => {
        personalDataStore.goPrevStage()
    }

    const handleForwardClick = () => {
        personalDataStore.goNextStage()  
    }

    const handleSetFormFields = (fields) => {
        setFormFields(fields.map(field =>             
            field.tech_name.includes('date') && field?.value == null
                ?   {...field, value: Date.now(), default: Date.now()}
                :   field
        ))
    }

    const loadSavedRecord = async (id) => {
        try {
            const savedRecord = await DocumentService.getOneDataObject(id)
            const fields = Object.values(savedRecord.data)
            handleSetFormFields(fields)
            setIsEditMode(true)            
        } catch (error) {
            showErrorToast(error, 'fetching', '') 
            handleSetFormFields(personalDataStore.activeDataModel.fields)
        }

        return null
    }

    useEffect(() => {
        personalDataStore.setIsDataLoading(true)
        personalDataStore.getActiveDataModel('order_numbers_and_pdn_printing_dates', setFormNestedModels)
        
        if (personalDataStore.project && personalDataStore.project.data['date_of_print_policy_pdn'].value) {
            personalDataStore.setAllStages(personalDataStore.allStages.map(stage => 
                stage.data['id_of_stage'].value === personalDataStore.actualStageIndex
                    ?   {...stage, status: 'finished'}
                    :   stage
            ))    
        }
    }, [])
    
    useEffect(() => {
        if (personalDataStore.activeDataModel && personalDataStore.activeDataModel.id === 'order_numbers_and_pdn_printing_dates') {
            if (personalDataStore.printingDatesFld && personalDataStore.printingDatesFld.values.length > 0) {
                loadSavedRecord(personalDataStore.printingDatesFld.values[0].id)
            } else {
                handleSetFormFields(personalDataStore.activeDataModel.fields)
            }

            personalDataStore.setIsDataLoading(false)
        }
    }, [personalDataStore.activeDataModel])

    useEffect(() => {
        if(Object.entries(errors).length > 0 && isSubmitting === false){
            toast.error(<FormErrorToastPanel errors={errors.data} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <>
            <div className='tw-grow tw-overflow-auto'>
                <div className='tw-px-4 tw-py-1'>
                    { !isPrintingDocument &&
                        <>
                            <div className='tw-w-full tw-text-center'>
                                <button 
                                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                            hover:tw-bg-gray-600 hover:tw-border-gray-600 tw-my-4
                                            focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                    onClick={handleSubmit(handlePrintDocument)}
                                >
                                    Скачать пакет документов
                                </button>
                            </div>

                            { !formFields
                                ?
                                    <Spinner />
                                :
                                    formFields.slice().sort((a, b) => a.order - b.order).map((fieldItem, index) => {
                                        if (!fieldItem.tech_name.includes('1st_pack') && !fieldItem.hide) {
                                            return <form key={index}>
                                                    <FieldItem
                                                        fieldItem={fieldItem}
                                                        nestedModels={[]}
                                                        errors={errors}
                                                        handleRecordChange={() => {}}
                                                        isEditMode={isEditMode}
                                                        isLoading={personalDataStore.isDataLoading}
                                                        control={control}
                                                        register={register}
                                                        setValue={setValue}
                                                    />
                                                </form>
                                        } else return null
                                    })
                            }
                        </>                                                             
                    }
                    { isPrintingDocument &&
                        <>
                            <p className='tw-mt-2 tw-text-sm tw-font-semibold tw-text-center'>
                                Выполняется скачивание пакета документов по ПДн...
                                <br></br>
                                Это может занять несколько минут   
                            </p>
                            <Spinner/>
                        </>
                    }
                </div>
            </div>
            <PDataStageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleForwardClick}
                disabled={true}
            />
        </>                                                             
    )
}

export default observer(PDataPrintTaskForm)