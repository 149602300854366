//Текст лицензионного соглашения
export const agreementText = `
<br/> 
<p>Физическое лицо (далее – Субъект персональных данных) действуя свободно, своей волей и в своем интересе, а также подтверждая свою дееспособность, 
информировано и конкретно предоставляет (далее – Согласие) Общество с ограниченной ответственностью «Медоед», 
111524, город Москва, Электродная ул, д. 2 стр. 34, помещ. 5/1, ИНН 7720922026, ОГРН 1247700065910, тел. +7 (495) 309-31-25, эл. почта: <a className='tw-font-semibold tw-text-sky-700' href="mailto:support.medoed@rtmtech.ru">support.medoed@rtmtech.ru</a>, 
(далее – Оператор) согласие на обработку своих персональных данных со следующими условиями:
</p>
<br/>

<ol>
    <li>1. Данное Согласие дается на обработку персональных данных, как без использования средств автоматизации, так и с их использованием.</li>
    <br/>

    <li>2. Обработка персональных данных включает в себя в том числе: сбор; запись; систематизация; накопление; хранение; уточнение (обновление, изменение); 
    извлечение; использование; передача (предоставление, доступ) персональных данных третьим лицам для достижения целей, указанных в п. 5; блокирование; 
    удаление; уничтожение.</li>
    <br/>

    <li>3. Согласие является конкретным, предметным, информированным, сознательным и однозначным.</li>
    <br/>

    <li>4. Согласие дается на обработку следующих персональных данных, не относящихся к специальной категории персональных данных или к биометрическим 
    персональным данным:</li>
    <br/>
    <ul>
        <li className='tw-ml-10'>&#8212; фамилия, имя, отчество;</li>
        <li className='tw-ml-10'>&#8212; адрес электронной почты;</li>
        <li className='tw-ml-10'>&#8212; полное наименование организации;</li>
        <li className='tw-ml-10'>&#8212; логин в сервисе Medoed;</li>
        <li className='tw-ml-10'>&#8212; номер телефона;</li>
        <li className='tw-ml-10'>&#8212; сведения об используемом браузере;</li>
        <li className='tw-ml-10'>&#8212; местоположение;</li>
        <li className='tw-ml-10'>&#8212; IP-адрес;</li>
        <li className='tw-ml-10'>&#8212; запрашиваемые Интернет-страницы;</li>
        <li className='tw-ml-10'>&#8212; источник захода на Сайт <a className='tw-font-semibold tw-text-sky-700' href="https://medoed-soft.ru/" target='_blank' rel='noreferrer'>https://medoed-soft.ru/</a>.</li>
    </ul>
    <br/>

    <li>5. Цели обработки персональных данных: </li>
    <br/>
        <ul>
            <li className='tw-ml-10'>&#8212; использование функционала сервиса Medoed;</li>
            <li className='tw-ml-10'>&#8212; обеспечение Пользователю возможности взаимодействовать с Сайтом 
                <a className='tw-font-semibold tw-text-sky-700' href="https://medoed-soft.ru/" target='_blank' rel='noreferrer'>https://medoed-soft.ru/</a> 
                и сервисом 
                <a className='tw-font-semibold tw-text-sky-700' href="https://medoed.rtmtech.ru/" target='_blank' rel='noreferrer'>https://medoed.rtmtech.ru/</a>;</li>
            <li className='tw-ml-10'>&#8212; обращение в службу поддержки;</li>
            <li className='tw-ml-10'>&#8212; направление информационных и рекламных рассылок.</li>
        </ul>
    <br/>

    <li>6. Согласие Субъекта персональных данных действует до даты его отзыва путем направления заявления в адрес ООО «Медоед» или 
    до даты достижения целей передачи персональных данных, в зависимости от того, какой момент наступит раньше.</li>
    <br/>

    <li>7. Согласие может быть отозвано субъектом или его представителем путем направления ООО «Медоед» заявления по почтовому или 
    электронному адресу Оператора, указанному в начале Согласия.</li>
    <br/>

    <li>8. В случае отзыва Согласия Оператор вправе продолжить обработку персональных данных без него при наличии оснований, указанных в 
    пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О персональных данных» от 27.07.2006 г.</li>
    <br/>
`