import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import AuthService from '../../../services/AuthService'
import { paths } from '../../../config/constsURN'
import { Context } from '../../..'
import SignInForm from './SignInForm'
import { toast } from 'react-toastify'
import { showErrorToast } from '../../../functions/errorHandlers'


/**
 * Компонент реализует логику входа зарегистрированного пользователя
 * 
 */
const SignInFormContainer = () => {
    const navigate = useNavigate()
    const { userStore } = useContext(Context)

    const handleSubmitClick = async (form) => {
            try {
                const response = await AuthService.auth(form.login, form.password)
                localStorage.setItem('token', response.data.access)
                localStorage.setItem('refresh', response.data.refresh)

                const userData = await AuthService.getUser()
                localStorage.removeItem('isTokenExpired')
                userStore.setAuth(true)
                userStore.setUser(userData.data)
                userStore.getUserConfiguration(false)
                userStore.getMySubCompanies()
                userStore.getMyPermissions()
                userStore.checkModulesAvailable()

                navigate(paths.MAIN_ROUTE)
            } catch (error) {
                showErrorToast(error, 'sign_in', '')
            }    
    }

    useEffect(() => {
        if (userStore.isAuth)
            navigate(paths.MAIN_ROUTE)

        const isExpired = localStorage.getItem('isTokenExpired')
        if (isExpired) {
            toast.info('Время действия авторизации текущего пользователя истекло!', { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
            localStorage.removeItem('isTokenExpired')
        }
    }, [])

    return (
        <SignInForm
            onSubmitClick={handleSubmitClick}
        />
    )
}

export default observer(SignInFormContainer)
