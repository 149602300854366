import React, { useCallback, useState, useEffect } from 'react'

/**
 * Визуальный компонент отображает горизонтальный разделитель панели 
 * 
 * @param {Object} topElement Элемент, расположенный выше разделителя
 * @param {Object} bottomElement Элемент, расположенный ниже разделителя
 * 
 */
const PanelHorizontalDivider = ({topElement, bottomElement}) => {
    const [isSizing, setIsSizing] = useState(false)

    const mouseDown = () => {
        setIsSizing(true)
    }
    
    const mouseMove = useCallback((e) => {
        const dividerHeight = 66
        const dividerOffset = 8
        const minGridHeight = 100
        const minTabsHeight = 150

        const totalHeight = topElement.current.offsetHeight + bottomElement.current.offsetHeight + dividerHeight
        let gridHeight = e.clientY - topElement.current.offsetTop - dividerOffset           
        if (gridHeight < minGridHeight)
            gridHeight = minGridHeight
        
        let tabsHeight = totalHeight - gridHeight
        if (tabsHeight < minTabsHeight) {
            tabsHeight = minTabsHeight
            gridHeight = totalHeight - tabsHeight
        }

        topElement.current.style.height = `${gridHeight}px`
        bottomElement.current.style.height = `calc(100% - ${gridHeight + dividerHeight}px)`
    }, [topElement])
    
    const removeListeners = useCallback(() => {
        window.removeEventListener("mousemove", mouseMove)
        window.removeEventListener("mouseup", mouseUp)
    }, [mouseMove])
    
    const mouseUp = useCallback(() => {
        if (topElement.current) {
            sessionStorage.setItem('savedGridHeight', topElement.current.style.height)
        }
        if (bottomElement.current) {
            sessionStorage.setItem('savedTabsHeight', bottomElement.current.style.height)
        }
        removeListeners()
        setIsSizing(false)
    }, [setIsSizing, removeListeners])

    useEffect(() => {
        if (isSizing) {
            window.addEventListener("mousemove", mouseMove)
            window.addEventListener("mouseup", mouseUp)
        }
    
        return () => {
            removeListeners()
        }
    }, [isSizing, mouseMove, mouseUp, removeListeners])

    return (
        <div
            id='panel-horizontal-divider'
            className='tw-w-full tw-h-1 tw-my-1.5 tw-rounded-md tw-bg-gray-500 tw-cursor-row-resize tw-opacity-25 hover:tw-opacity-50'
            onMouseDown={mouseDown}
        >
        </div>
    )
}

export default PanelHorizontalDivider