/**
 * Компонент реализует логику http-запросов посредством клиента Axios
 * @module HTTP
 */
import axios from 'axios'
import AuthService from '../services/AuthService'
import { userAPI } from '../config/constsURN'
import { Mutex } from 'async-mutex'

/**
 * Экземпляр axios для неавторизованного пользователя (регистрация, вход)
 */
const $host = axios.create({})

/**
 * Экземпляр axios для авторизированного пользователя (запрос данных пользователя)
 */
const $authHost = axios.create({})

/**
 * Экземпляр axios для авторизированного пользователя (работа с документами и таблицами)
 */
const $dataHost = axios.create({})

/**
 * Экземпляр axios для импорта данных с сервера домена
 */
const $importHost = axios.create({})

/**
 * Экземпляр axios для экспорта данных в файл
 */
const $exportHost = axios.create({})

/**
 * Экземпляр axios для работы с конфигурациями системы
 */
const $confHost = axios.create({})

/**
 * Экземпляр axios для работы с файлами
 */
const $fileHost = axios.create({})

/**
 * Экземпляр axios для работы с сообщениями
 */
const $notesHost = axios.create({})


/**
 * Экземпляр Mutex для работы с мьютексом при обновлении токена
 */
const mutex = new Mutex()

/**
 * Перехватчик запросов к серверу при авторизации пользователя
 * @param {Object} config Заголовок запроса
 * @returns {Object} Заголовок запроса с типом password
 */
const signInterceptor = config => {
    config.headers['grant-type'] = 'password'
    return config
}

/**
 * Перехватчик запросов к серверу от авторизированного пользователя
 * @param {Object} config Заголовок запроса
 * @returns {Object} Заголовок запроса с токеном доступа
 */
const authInterceptor = config => {
    if (config.url !== userAPI.REFRESH_API)
        config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return config
}

/**
 * Перехватчик ответов сервисов 
 * выполняет однократную повторную посылку запроса с токеном обновления (refresh-token)
 * при истечении срока действия токена доступа (access-token)
 * @param {Object} error Ошибка при обработке запроса
 * @returns {Promise} Axios.response
 */
const refreshInterceptor = (host) => async (error) => {
    if (error?.response?.status === 401){
        if (error?.config && error?.config?.url !== userAPI.REFRESH_API) {
            const originalRequest = error?.config;
            if (!mutex.isLocked()) {
                const release = await mutex.acquire()
                try {
                    const refresh = localStorage.getItem('refresh')
                    if (refresh) {
                        const response = await AuthService.refreshToken(refresh)
                        localStorage.setItem('token', response.data.access)
                        localStorage.setItem('refresh', response.data.refresh)
                        originalRequest.headers = {...originalRequest.headers}
                        originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`
                        
                        return host.request(originalRequest);
                    }
                    return error.response
                } catch (err) {
                    console.log('Refresh attempt is failed...')
                } finally {
                    release()
                }
            } else {
                await mutex.waitForUnlock()
                const access = localStorage.getItem('token')
                if (access) {
                    originalRequest.headers = {...originalRequest.headers}
                    originalRequest.headers['Authorization'] = `Bearer ${access}`                   
                    return host.request(originalRequest);    
                }

                return error.response
            }
        } else {
            if (error?.config && error?.config?.url === userAPI.REFRESH_API) {
                localStorage.removeItem('token')
                localStorage.removeItem('refresh')
                sessionStorage.removeItem('activeDataModel')
                mutex.release()
            }
        }
    } else {
        console.log('Response:', error.response)
    }
    throw error;
}


$host.interceptors.request.use(signInterceptor)
$authHost.interceptors.request.use(authInterceptor)
// $authHost.interceptors.response.use(config => config, refreshInterceptorIDP)
$authHost.interceptors.response.use(config => config, refreshInterceptor($authHost))
$dataHost.interceptors.request.use(authInterceptor)
$dataHost.interceptors.response.use(config => config, refreshInterceptor($dataHost))
$importHost.interceptors.request.use(authInterceptor)
$importHost.interceptors.response.use(config => config, refreshInterceptor($importHost))
$exportHost.interceptors.request.use(authInterceptor)
$exportHost.interceptors.response.use(config => config, refreshInterceptor($exportHost))
$confHost.interceptors.request.use(authInterceptor)
$confHost.interceptors.response.use(config => config, refreshInterceptor($confHost))
$fileHost.interceptors.request.use(authInterceptor)
$fileHost.interceptors.response.use(config => config, refreshInterceptor($fileHost))
$notesHost.interceptors.request.use(authInterceptor)
$notesHost.interceptors.response.use(config => config, refreshInterceptor($notesHost))

export {
    $host,
    $authHost,
    $dataHost,
    $importHost,
    $exportHost,
    $confHost,
    $fileHost,
    $notesHost
}
