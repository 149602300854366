import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'
import ObjectsList from '../../common/panels/object_list/ObjectsList'
import ObjectsListButtons from '../../common/panels/object_list/ObjectsListButtons'
import Spinner from '../../../../../assets/Spinner'
import MaterialsObjectsForm from './MaterialsObjectsForm'
import { Tooltip } from 'react-tooltip'
import { toast } from 'react-toastify'
import { menuTooltipTimeOut, serviceMessageTimeOut, successMessageTimeOut } from '../../../../../config/constTypes'
import { paths } from '../../../../../config/constsURN'
import SearchInput from '../../../../search/SearchInput'
import FilterStore from '../../../../../store/FilterStore'
import { LinkIcon } from '@heroicons/react/20/solid'
import { copyToClipboard } from '../../../../../functions/copyToClipboard'
import { useHotkeys } from 'react-hotkeys-hook'


/**
 * Визуальный компонент отображает список материалов темы
 * 
 */
const MaterialsObjectsContainer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { EducationStore } = useContext(Context)
    const [filterStore] = useState(() => new FilterStore())

    const [isSearchPressed, setIsSearchPressed] = useState(false)

    useHotkeys('ctrl+l', () => {if (EducationStore.materialsStage) handleCopyToClipboardClick()}, { preventDefault: true })

    const materialDataModel = {
        id: "topic_materials",
        fields: [{
            alias: "Наименование",
            field_id: "name",
            full_name: "Наименование",
            tech_name: "name",
            type: "value",
            validator_type: "string"
        }]
    }

    const handleJumpStageClick = (item) => {
        EducationStore.setMaterialsStage(item)
    }

    const handleBackClick = () => {
        EducationStore.setMaterialsStage(EducationStore.materialsList[EducationStore.materialsStage.value - 1])
    }

    const handleForwardClick = async () => {
        EducationStore.setMaterialsStage(EducationStore.materialsList[EducationStore.materialsStage.value + 1])
    }

    const applySearchFilter = () => {
        EducationStore.setSearchedMaterialText(filterStore.searchField)
        setIsSearchPressed(true)

        if (filterStore.searchField === '') {
            navigate(paths.EDUCATION_ROUTE + '/' + EducationStore.topic.record_id)

        } else {
            const textValue = filterStore.searchField
                                .replace(/\s{2,}/g, ' ')
                                .trim()
                                .replace(/\+/g, '%2B')
                                .replace(/\s/g, '+')
            navigate(paths.EDUCATION_ROUTE + '/' + EducationStore.topic.record_id + '/?text=' + textValue)
        }
    }

    const handleCopyToClipboardClick = () => {
        try {
            copyToClipboard(`${paths.EDUCATION_ROUTE}/${EducationStore.topic.record_id}/?text=record_id=${EducationStore.materialsStage.record_id}`)
            toast.info('Ссылка скопирована в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
        } catch (error) {
            toast.error('Не удалось скопировать ссылку в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }
    }

    useEffect(() => {
        if (EducationStore.topic) {
            if (location.search !== '' && filterStore.searchField === '') {
                let searchFilter = []
                let searchText = ''
                if (location.search.includes('?text=record_id=')) {
                    searchText = decodeURI(location.search.replace('?text=', ''))
                    const recordID = location.search.replace('?text=record_id=', '')
                    searchFilter = [{
                        property: "record_id",
                        value: recordID,
                        operator: "eq"
                    }]

                } else {
                    searchText = decodeURI(location.search.replace('?text=', '').replace('+', ' ').replace('%2B', '+'))
                    searchFilter = [{
                        property: "data['name'].value",
                        value: `%${searchText}%`,
                        operator: "like_ic"
                    }]
                }
                EducationStore.setSearchedMaterialText(searchText)
                filterStore.setSearchField(searchText)
                filterStore.setSearchFilters(searchFilter)
                EducationStore.loadMaterials(searchFilter)
            } else {
                if (filterStore.searchField.includes('record_id=')) {
                    EducationStore.setSearchedMaterialText('')
                    filterStore.setSearchField('')
                    filterStore.setSearchFilters([])
                    EducationStore.loadMaterials([])
                } else
                    EducationStore.loadMaterials(filterStore.searchFilters)
            }
        }
    }, [EducationStore.topic])

    useEffect(() => {
        if (EducationStore.isMaterialProgressUpdate || EducationStore.isTestAttemptUpdate) {
            EducationStore.updateMaterialStatus()
        }
    }, [EducationStore.isMaterialProgressUpdate, EducationStore.isTestAttemptUpdate])

    useEffect(()=>{
        if (isSearchPressed && EducationStore.topic) {
            if (location.search.includes('?text=record_id=')) {
                const recordID = location.search.replace('?text=record_id=', '')
                const searchFilter = [{
                    property: "record_id",
                    value: recordID,
                    operator: "eq"
                }]    
                EducationStore.loadMaterials(searchFilter)
                EducationStore.setSearchedMaterialText('')

            } else {
                EducationStore.loadMaterials(filterStore.searchFilters)
            }

            setIsSearchPressed(false)
        }
    }, [isSearchPressed])

    useEffect(()=>{
        if (EducationStore.materialsList && EducationStore.materialsList.length &&
            EducationStore.searchedMaterialText !== '' && EducationStore.searchedMaterialText.includes('record_id=')) 
        {
            const recordID = EducationStore.searchedMaterialText.replace('record_id=', '')
            const foundMaterial = EducationStore.materialsList.find(dataObject => dataObject.record_id === recordID)
            if (foundMaterial) {
                EducationStore.setMaterialsStage(foundMaterial)
            }
        }
    }, [EducationStore.materialsList])

    return (
        <>
            <div className='tw-grow tw-overflow-auto'>
                <div className='tw-grid tw-grid-cols-5 tw-gap-x-2 tw-max-h-full tw-h-full'>
                    <div id='stage-list' className='tw-col-span-2 tw-h-full tw-bg-white tw-overflow-hidden tw-pr-2 tw-border-r-2 tw-border-gray-300'>
                        <div id='stage-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-pl-4 tw-pr-2 tw-py-2'>
                            <p className='tw-text-md tw-font-semibold'>Материалы</p>
                            <button
                                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-300'
                                onClick={handleCopyToClipboardClick}
                                disabled={!EducationStore.materialsStage}
                                data-tooltip-id="material-form-tooltip"
                                data-tooltip-html="Копировать ссылку в буфер обмена &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+L)"
                                data-tooltip-delay-show={menuTooltipTimeOut}
                            >
                                <LinkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            </button>
                        </div>
                        <div className='tw-w-full tw-p-2'>
                            <SearchInput
                                isDisabled={false}
                                filterStore={filterStore}
                                selectedFullDataModel={materialDataModel}
                                updateDataObjectList={applySearchFilter}
                            />
                        </div>
                        { !EducationStore.isMaterialsLoading
                            ?   EducationStore.materialsList.length > 0
                                    ?  
                                        <ObjectsList 
                                            objectsList={EducationStore.materialsList}
                                            objectsListStage={EducationStore.materialsStage}
                                            isDelete={false}
                                            onJumpStageClick={handleJumpStageClick}
                                            isSearch={true}
                                            isSelect={true}
                                        />
                                    :
                                        EducationStore.topic &&
                                            <p className='tw-col-span-5 tw-mt-4 tw-text-sm tw-font-semibold tw-text-center'>
                                                Для данной темы нет доступных материалов
                                            </p>
                            :
                                <div className='tw-col-span-5'>
                                    <Spinner />
                                </div>               
                        }
                    </div>
                    <div id='stage-column' className='tw-col-span-3 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden tw-flex tw-flex-col'>
                        { EducationStore.materialsStage && EducationStore.materialsStage.material &&
                            <>
                                <div id='stage-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                                    <p
                                        className='tw-text-md tw-font-semibold tw-truncate'
                                        data-tooltip-id="material-form-tooltip" data-tooltip-content={EducationStore.materialsStage.name} data-tooltip-delay-show={1000}
                                    >
                                        Материал "{EducationStore.materialsStage.name}"
                                    </p>
                                </div>
                                <MaterialsObjectsForm/>
                            </>
                        }
                    </div>                                             
                </div> 
            </div>
            <ObjectsListButtons
                onBackClick={handleBackClick}
                onForwardClick={handleForwardClick}
                disabled={false}
                stage={EducationStore.materialsStage}
                objectsList={EducationStore.materialsList}
                forwardButtonTitle={'Следующий материал'}
                backButtonTitle={'Предыдущий материал'}
            />
            <Tooltip id="material-form-tooltip" place="top-start" className="tw-max-w-xl"/>
        </>
    )
}

export default observer(MaterialsObjectsContainer)