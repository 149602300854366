import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { contentTooltipTimeOut } from '../../../../../config/constTypes'


/**
 * Визуальный компонент отображает поле выбора типа создаваемого поля из выпадающего списка существующих типов данных
 * 
 * @param {String} label Текстовая надпись (заголовок) над компонентом
 * @param {Object[]} itemList Список доступных типов данных
 * @param {Object} selectedItem Выбранный элемент в списке существующих типов
 * @param {Function} onItemChange Обработчик клика мыши при выборе типа в списке (в основном вида {(e) => field.onChange(e)}), а также
 * @param {Object} error Ошибка при валидации выбранного значения (для визуализации)
 * @param {String} selector Наименование атрибута объекта, по которому определяется выбранное значение
 * 
 */
const DataTypeListBox = ({label, itemList, selectedItem, onItemChange, error, selector}) => {
    return (
        <Listbox value={selectedItem} by={selector} onChange={onItemChange} as='div'>
            <div className='tw-flex tw-flex-row tw-w-full tw-items-center tw-mt-2'>
                    <Listbox.Label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900 tw-leading-6'>{label}</Listbox.Label>
                    {label && error && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
            </div>
            <div className="tw-relative tw-mt-1">
                <Listbox.Button 
                    className={({ open }) =>
                        `"tw-relative tw-w-full tw-h-8 tw-cursor-default tw-rounded-md tw-bg-white tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-left 
                        tw-border focus:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 
                        focus-visible:tw-outline-none focus-visible:tw-ring-offset-2 sm:tw-text-sm
                        ${error ? open ? 'tw-ring-red-400 !tw-ring-2' : 'tw-border-red-400' 
                                  : open ? 'tw-ring-gray-400 !tw-ring-2' : 'tw-border-gray-400'}
                    `}
                >
                    <span
                        className="tw-block tw-truncate"
                        data-tooltip-id="datatype-tooltip" data-tooltip-content={selectedItem.name} data-tooltip-delay-show={contentTooltipTimeOut}
                    >
                        {selectedItem.name}
                    </span>
                    <span className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
                        <ChevronUpDownIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="tw-transition tw-ease-in tw-duration-100"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <Listbox.Options className="tw-absolute tw-z-30 tw-mt-1 tw-max-h-[10.5rem] tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base 
                                               tw-border tw-border-gray-300 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none sm:tw-text-sm">
                        { itemList.length === 0  
                            ?
                                <div className="tw-relative tw-cursor-default tw-select-none tw-py-2 tw-px-4 tw-text-red-400">
                                    Список пуст
                                </div>
                            : 
                            ( itemList.map((item, index) => (
                                <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                        `tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-10 tw-pr-4 
                                        ${ active ? 'tw-bg-gray-500 tw-text-white' : 'tw-text-gray-900' }`
                                    }
                                    value={item}
                                    hidden={item.hidden}
                                >
                                {({ selected }) => (
                                    <>
                                    <span
                                        className={`tw-block tw-whitespace-break-spaces ${
                                            selected ? 'tw-font-semibold' : 'tw-font-normal' }`
                                    }
                                    >
                                        {item.name}
                                    </span>
                                    {selected ? (
                                        <span className="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
                                            <CheckIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>
                                    ) : null}
                                    </>
                                )}
                                </Listbox.Option>
                            )))
                        }
                    </Listbox.Options>
                </Transition>
            </div>
            <Tooltip id="datatype-tooltip" place="top-start" className="tw-max-w-xl"/>
        </Listbox>
    )
}

export default DataTypeListBox