import React, { useState, useRef, useEffect, Fragment, useContext } from 'react'
import { contentTooltipTimeOut, emptyReferenceValue, getFieldValue, hideTooltipTimeOut, setFieldValue, setReferenceValue } from '../../../config/constTypes'
import { Tooltip } from 'react-tooltip'
import { sortRecords } from '../../../functions/sortRecords'
import { observer } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { createFieldsListHeaders } from '../../../functions/createFieldsListHeaders'
import NestedModelHeader from './NestedModelHeader'
import { Controller, useForm } from 'react-hook-form'
import { Context } from '../../..'
import { toast } from 'react-toastify'
import SuggestionsList from '../../main_page/controller/categorizing_cii/form/significanceIndicators/SuggestionsList'
import { CalendarIcon } from '@heroicons/react/20/solid'
import ReactDatePicker from 'react-datepicker'
import DataEnumListBox from '../../main_page/controller/common/inputs/DataEnumListBox'
import DataReferenceInput from '../../main_page/controller/common/inputs/DataReferenceInput'
import DocumentService from '../../../services/DocumentService'
import DirectoryContainer from '../../main_page/controller/common/panels/directory/DirectoryContainer'
import { createEmptyFieldData, createNestedDataObject } from '../../../functions/nestedModels'
import AddDataObjectContainer from '../../main_page/controller/data_object/add/AddDataObjectContainer'
import DuplicateDataObjectContainer from '../../main_page/controller/data_object/add/DuplicateDataObjectContainer'
import EditDataObjectContainer from '../../main_page/controller/data_object/edit/EditDataObjectContainer'


/**
 * Визуальный компонент отображает список записей выбранной вложенной таблицы.
 * По умолчанию записи сортируются по дате создания (от более ранних к более поздним)
 * 
 * @param {Boolean} readOnly Признак "только чтение" для значений вложенной таблицы
 * @param {Object} dataModel Выбранная вложенная таблица
 * @param {Object[]} dataObjects Записи вложенной таблицы
 * @param {Object[]} nestedDataModels Массив вложенных таблиц
 * @param {Object} selectedNestedDataObject Выбранная запись вложенной таблицы
 * @param {Function} setNestedDataModels Изменение в массиве вложенных таблицы
 * @param {Function} setSelectedNestedDataObject Изменение выбранной записи вложенной таблицы
 * @param {Boolean} isDocumentPage Признак нахождения на странице документов
 * @param {Function} onChange Сторонняя функция для изменений в массиве вложенных таблицы
 * @param {Object} panel Панель, где находятся элементы
 * @param {Function} onScrollX Обработчик прокрутки вложенной таблицы
 * @param {Boolean} isChosenObjectDuplicationForbidden Признак запрета повторного добавления записи
 * 
 */
const NestedTableFieldsList = ({readOnly, dataModel, dataObjects, nestedDataModels, selectedNestedDataObject, setNestedDataModels, setSelectedNestedDataObject,
            isDocumentPage, onChange, panel, onScrollX, isChosenObjectDuplicationForbidden, panelID, isAddFormOpen, setIsAddFormOpen, isDuplicateFormOpen, 
            setIsDuplicateFormOpen, isEditFormOpen, setIsEditFormOpen}) => {
    const {
        control,
        register,
        setValue,
    } = useForm()
    const {categorizingCIIStore, docStore, FilterStore} = useContext(Context)

    const [selectedRowIndex, setSelectedRowIndex] = useState(null)
    const [selectedColIndex, setSelectedColIndex] = useState(null)
    const [editedRow, setEditedRow] = useState(null)
    const [selectedField, setSelectedField] = useState(null)
    const [editedField, setEditedField] = useState(null)
    const [sortingDirection, setSortingDirection] = useState('down')
    const [sortingColumn, setSortingColumn] = useState('order')
    const [sortingType, setSortingType] = useState('string')
    const [sortedList, setSortedList] = useState([])
    const [fixedValues, setFixedValues] = useState([])
    const [suggestionsRuleId, setSuggestionsRuleId] = useState('')
    const [isRefFormOpen, setIsRefFormOpen] = useState(false)
    const [isMultiSelect, setIsMultiSelect] = useState(false)
    const [isMinScrollYPosition, setIsMinScrollYPosition] = useState(true)
    const [isVerticalScroll, setIsVerticalScroll] = useState(false)
    const [isCellEditionAvailable, setIsCellEditionAvailable] = useState(false)
    const [chosenDataObjectsList, setChosenDataObjectsList] = useState([])

    const frameElement = useRef(null)
    const tableElement = useRef(null)
    const headerElement = useRef(null)

    const headers = dataModel.fields
                        ?.slice()
                        .filter(field => !field.hide && field.type !== 'include')
                        .sort((a, b) => a.order - b.order)
                        .map(field => {return {alias: field.alias, column: field.tech_name, info: field.description, type: field.validator_type}})

    const isMultiColumn = headers.length > 1

    let defaultColumnSizes
    let defaultColumnValues
    let isEnterPressed = false

    if (headers.length < 6) {
        defaultColumnSizes = '40px ' + (headers.map(() => 'minmax(150px, 1500px)').join(' '))
        defaultColumnValues = headers.map(item => Math.floor(1000/headers.length))  
    } else {
        defaultColumnSizes = '40px ' + (headers.map(() => '200px').join(' '))
        defaultColumnValues = headers.map(item => 200)    
    }

    headers.unshift({alias: '№'})
    defaultColumnValues.unshift(40)

    const savedColumnValues = JSON.parse(localStorage.getItem('columns_nested_' + dataModel.id))
    let columnValues
    if (savedColumnValues && savedColumnValues.length === defaultColumnValues.length) {
        columnValues = savedColumnValues
    } else {
        columnValues = defaultColumnValues
        if (savedColumnValues)
            localStorage.removeItem('columns_nested_' + dataModel.id)
    }

    const columnSizes = isMultiColumn
        ?   columnValues.map(item => item ? item + 'px' : '100px').join(' ')
        :   defaultColumnSizes

    const columns = createFieldsListHeaders(headers)

    const handleSortClick = (column, type) => {
        if (column === sortingColumn) {
            if (sortingDirection === 'up') {
                setSortingDirection('down')
            } else {
                setSortingDirection('up')
            }
        } else {
            setSortingType(type)
        }
        setSortingColumn(column)
    }

    const checkCellEdition = (dataObject, field, isToastShow) => {
        let isEditionAvailable = true
        let warningMessage = ''

        // HARD CODE для таблицы показателей значимости
        if (dataObject.data['indicator__indicatorss']) {
            // поиск показателя в справочнике (для получения в дальнейшем списка подсказок при необходимости обоснования его неактуальности)
            const foundIndicator = categorizingCIIStore.indicatorList.find(indicator => indicator.record_id === dataObject.data['indicator__indicatorss'].value.values[0].record_id) 
            if (foundIndicator)
                categorizingCIIStore.setSelectedIndicator(foundIndicator)

            // если выбран другой столбец при наличии заполненного столбца "Обоснование неактуальности"
            if (field.tech_name !== 'justification_of_irrelevance__indicatorss') {
                if (dataObject.data['justification_of_irrelevance__indicatorss'] &&
                    dataObject.data['justification_of_irrelevance__indicatorss'].value &&
                    dataObject.data['justification_of_irrelevance__indicatorss'].value.trim() !== '') {
                        isEditionAvailable = false
                        warningMessage = 'Неактуальный показатель редактировать нельзя!'
                }
            }

            // если выбран столбец "Показатель"
            if (field.tech_name === 'indicator__indicatorss') {
                isEditionAvailable = false
                warningMessage = 'Наименование показателя редактировать нельзя!'
            }

            // если выбран столбец "Единицы измерения"
            if (field.tech_name === 'unit_of_measurement__indicatorss') {
                isEditionAvailable = false
                warningMessage = 'Единицы измерения редактировать нельзя!'
            }

            if (field.tech_name === 'category_of_sign_by_indicator__indicatorss') {
                isEditionAvailable = false
                warningMessage = 'Значение категории определяется по значению показателя!'
            }

            // если выбран столбец "Значение показателя" для неколичественного показателя (со списком текстовых значений)
            if (field.tech_name === 'value_of_the_indicator__indicatorss' &&
                ['02а', '03а', '06', '07', '11а', '12'].includes(dataObject.data['indicator__indicatorss'].value.values[0].code)) {
                    if (foundIndicator) {
                        setFixedValues([
                            foundIndicator.data['i_kat_min__significance_indicators'].value,
                            foundIndicator.data['ii_kat_min__significance_indicators'].value,
                            foundIndicator.data['iii_kat_min__significance_indicators'].value
                        ])
                    }
            } else {
                setFixedValues([])
            }

            if (!isEditionAvailable && isToastShow)
                toast.warn(warningMessage, { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
        }

        return isEditionAvailable
    }

    const handleSelectCellClick = (rowIndex, colIndex, dataObject, field) => {
        //Возвращение к последнему сохраненному значению редактируемого поля при выборе другого поля
        if(!(selectedRowIndex === rowIndex && selectedColIndex === colIndex) && editedField?.type !== 'reference' && editedField){
            setEditedField(null)
        }
        setSelectedNestedDataObject(null)

        if (selectedRowIndex !== rowIndex || selectedColIndex !== colIndex) {   // если выбор другой ячейки
            setSelectedRowIndex(rowIndex)
            setSelectedColIndex(colIndex)
            setSelectedField(field)
            setEditedField(null)
            setEditedRow(null)
            setIsCellEditionAvailable(checkCellEdition(dataObject, field, false))
        } else {  // если повторный клик на уже выбранной ячейке
            if (!readOnly && field.type !== 'reference' && dataObject.field_validator_type !== "reference:many") {
                handleEditCellClick(dataObject,field)
            }
        }
    }

    const handleSelectRowClick = (dataObject, rowIndex) => {
        setSelectedRowIndex(rowIndex)
        setSelectedColIndex(0)
        setSelectedNestedDataObject(dataObject)
    }

    const handleClearCellClick = (name, field) => {
        if (field.tech_name !== 'category_of_sign_by_indicator__indicatorss') {
            runInAction(() => {
                setValue(name, {value: emptyReferenceValue})
            })        
        }
    }
    
    const handleEditCellClick = (dataObject, field) => {
        const isCurrentCell = selectedField && field.tech_name === selectedField.tech_name
        const isCellEdition = checkCellEdition(dataObject, field, isCurrentCell)

        if (isCellEdition) {
            if (!dataModel.ref_model_type || dataModel.ref_model_type === 'nested') {
                setEditedRow(dataObject)
                setEditedField(field)

                if (field.type === 'reference') {
                    // HARD CODE фильтры для справочника МЕРЫ ЗАЩИТЫ модуля КИИ
                    if (field.field_id === 'org_measures_of_zokii') {
                        FilterStore.clearAllFilters()
                        sessionStorage.setItem(`protection_measures_filters`, JSON.stringify([{property:"data[\"org_or_tech__protection_measures\"].value",value:["О","ОТ"],operator:"in"}]))
                    }    
                    if (field.field_id === 'tech_measures_of_zokii') {
                        FilterStore.clearAllFilters()
                        sessionStorage.setItem(`protection_measures_filters`, JSON.stringify([{property:"data[\"org_or_tech__protection_measures\"].value",value:["Т","ОТ"],operator:"in"}]))
                    }
                    
                    setIsRefFormOpen(true)
                    setIsMultiSelect(!isMultiColumn)

                    let chosenObjects = []
                    sortedList.forEach(item => {
                        chosenObjects.push({...item.data[field.tech_name], status: item.status})
                    })
                    setChosenDataObjectsList(chosenObjects)
                }
            } else {
                setSelectedField(null)
                toast.warn('Записи данного справочника здесь редактировать нельзя!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            }
        } else {
            setSelectedField(null)
        }
    }

    const replaceFieldValue = (dataObject) => {
        if ((editedField.type === 'include' || (editedField.type === 'reference' && editedField.validator_type === 'many')) && docStore.isIncludeTypeField) {
            const addedDataObject = {...JSON.parse(JSON.stringify(dataObject)), status: 'added'}
            setNestedDataModels(nestedDataModels.map(nestedModel => nestedModel.rule_id !== dataModel.rule_id
                ?   nestedModel
                :   {...nestedModel, 
                        dataObjects: nestedModel.dataObjects.concat([addedDataObject])
                            .map((item, index) => {return {...item, order: index, field_validator_type: editedField.type + ':' + editedField.validator_type}})
                    }
            ))
        } else {
            let newValue

            if (editedField.type === 'reference') {
                if (dataObject.record_id !== '0') {
                    newValue = setReferenceValue(dataObject)
                } else {
                    newValue = emptyReferenceValue
                }
            } else {
                newValue = dataObject.editedValue
                setEditedField({...editedField, value: newValue})
            }
    
            const nestedDataObject = dataObjects.find(item => item.id === editedRow.id && item.status !== 'deleted')
            const editedDataObject = nestedDataObject ? JSON.parse(JSON.stringify(nestedDataObject)) : {}  // object deep copy
    
            for (const field in editedDataObject.data) {
                if (field === editedField.tech_name)
                    editedDataObject.data[field].value = newValue
            }
    
            if (editedDataObject.status === 'saved')
                editedDataObject.status = 'edited'    

            if (isDocumentPage) {   // редактирование записи на странице "Просмотр данных"
                let editedNestedModel
                if (editedField.type === 'reference') {
                    if (!isMultiColumn) {
                        const  dataField = createEmptyFieldData(dataModel)

                        editedNestedModel = dataObjects.concat([
                            createNestedDataObject(dataField, dataObjects.length, dataModel)
                        ])

                        setEditedField(editedField)
                    } else {
                        setEditedField(null)
                    }
                }

                if (editedNestedModel) {
                    setNestedDataModels(nestedDataModels.map(nestedModel => nestedModel.rule_id !== dataModel.rule_id
                        ?   nestedModel
                        :   {...nestedModel, 
                                dataObjects: editedNestedModel.map(dataObject => dataObject.id !== editedRow.id
                                    ?   dataObject
                                    :   editedDataObject
                            )}
                    ))
                } else {
                    setNestedDataModels(nestedDataModels.map(nestedModel => nestedModel.rule_id !== dataModel.rule_id
                        ?   nestedModel
                        :   {...nestedModel, 
                                dataObjects: nestedModel.dataObjects.map(dataObject => dataObject.id !== editedRow.id
                                    ?   dataObject
                                    :   editedDataObject
                            )}
                    ))
                }
                
            } else {    // редактирование в формах модулей
                let editedValues = dataObjects.map(dataObject => 
                    (dataObject.id !== editedRow.id || dataObject.status === 'deleted')
                        ?   dataObject
                        :   editedDataObject
                )
                .sort((a, b) => a.order - b.order)

                onChange(editedValues, dataModel, editedRow)

                if (editedField.type === 'reference') {
                    if (!isMultiColumn) {
                        const  dataField = createEmptyFieldData(dataModel)

                        const editedNestedModel = editedValues.concat([
                            createNestedDataObject(dataField, editedValues.length, dataModel)
                        ])
                        
                        setEditedField(editedField)
                        onChange(editedNestedModel, dataModel)
                    } else {
                        setIsRefFormOpen(false)
                        setIsMultiSelect(false)
                        setEditedField(null)
                    }
                }
            }    
        }

    }

    const handleValueChange = (selectedValue) => {
        setSelectedRowIndex(null)
        setSelectedColIndex(null)

        let isEditedValue = true

        // HARD CODE для показателей значимости модуля КИИ
        if (editedRow && editedRow.data && editedRow.data['indicator__indicatorss']) {
            if (editedField.tech_name === 'justification_of_irrelevance__indicatorss' && categorizingCIIStore.irrelevantIndicatorsValues) {
                const foundIndicator = categorizingCIIStore.irrelevantIndicatorsValues.find(indicator => 
                    indicator.record_id === editedRow.data['indicator__indicatorss'].value.values[0].record_id
                )

                if (foundIndicator) {
                    isEditedValue = false
                    toast.error('Для данного показателя изменить обоснование неактуальности нельзя!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                }
            }
        }

        if (isEditedValue) {
            replaceFieldValue(selectedValue)
        }
    }

    const findEmptyReference = () => {
        const refField = Object.values(sortedList[0].data).find(item => editedField?.type === 'reference' && item.tech_name === editedField?.tech_name)
        if (refField){
            const foundEmptyRefRecord = sortedList.find(item => !item.data[refField.tech_name].value.values.length)
            if (foundEmptyRefRecord)
                return [foundEmptyRefRecord, refField]
        }

        return [null, null]
    }

    const handleReferenceCloseClick = () => {
        if (docStore.isIncludeTypeField) {
            docStore.setIsIncludeTypeField(false)
            docStore.setIncludeField(null)
            docStore.setTabsID(null)

        } else {
            const [foundEmptyRefRecord, refField] = findEmptyReference()
            if (foundEmptyRefRecord && foundEmptyRefRecord.status === 'added') {
                // вновь добавленная запись удаляется из списка
                const editedFieldValues = dataObjects.filter(item => item.id !== foundEmptyRefRecord.id).map((item, index) => {return {...item, order: index}})
    
                if (isDocumentPage) {
                    setNestedDataModels(nestedDataModels.map(nestedModel => nestedModel.rule_id !== dataModel.rule_id
                        ?   nestedModel
                        :   {...nestedModel, 
                                dataObjects: editedFieldValues
                            }
                    ))
                } else {
                    onChange(editedFieldValues, dataModel)
                }
            }
    
        }

        docStore.setIsDetailView(false)
        setEditedField(null)
        setIsRefFormOpen(false)
        setIsMultiSelect(false)
    }

    const isNumber = (num) => {
        const editedNum = num?.replace(',', '.')
        return !isNaN(parseFloat(editedNum)) && isFinite(editedNum)
    }

    const handleCellExit = (e) => {
        if (editedField) {
            if (editedField.validator_type === 'float') {
                if(!isNaN(e.target.valueAsNumber) && 
                    (e.target.valueAsNumber - Number(e.target.valueAsNumber.toFixed(editedField.options.number_decimal_places)) === 0))
                        handleValueChange({'editedValue': e.target.valueAsNumber})
                else {
                    toast.error('Некорректное значение поля!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                    handleValueChange({'editedValue': 0})
                }
                e.target.blur()
                setEditedField(null)
            }
    
            if (editedField.validator_type === 'int') {
                if(!isNaN(e.target.valueAsNumber))
                    handleValueChange({'editedValue': e.target.valueAsNumber})
                else {
                    toast.error('Некорректное значение поля!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                    handleValueChange({'editedValue': 0})
                }
                e.target.blur()
                setEditedField(null)
            }

            if (editedField.validator_type === 'string' && !(fixedValues.length > 0)) {
                if (editedField.tech_name !== 'justification_of_irrelevance__indicatorss') {
                    if (editedField.tech_name === 'value_of_the_indicator__indicatorss' && !isNumber(e.target.value)) {
                        toast.info('Поле должно иметь числовое значение', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                    } else {
                        handleValueChange({'editedValue': e.target.value})
                        e.target.blur()
                        setEditedField(null)
                    }
               } else {
                    if (e.target.value !== editedField.value && e.target.type !== 'button' && !isEnterPressed) {
                        handleValueChange({'editedValue': e.target.value})
                        e.target.blur()
                        setEditedField(null)
                    }                    
               }
            }    
        }
    }

    const handleKeyPress = (e, isEditedCell, field) => {
        if (e.code === 'Enter' || e.code === 'Tab' || e.code === 'Escape' || e.code === 'NumpadEnter') {
            e.preventDefault()
            isEnterPressed = true
        }
        if (isEditedCell) {
            if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.code === 'Tab') {
                e.preventDefault()
                
                if (field.validator_type === 'string')
                    handleValueChange({'editedValue': e.target.value})
                
                if (field.validator_type === 'float') {
                    if (!isNaN(e.target.valueAsNumber) && 
                       (e.target.valueAsNumber - Number(e.target.valueAsNumber.toFixed(field.options.number_decimal_places)) === 0))
                        handleValueChange({'editedValue': e.target.valueAsNumber})
                    else {
                        toast.error('Некорректное значение поля!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                        handleValueChange({'editedValue': 0})
                    }
                }

                if (field.validator_type === 'int') {
                    if (!isNaN(e.target.valueAsNumber))
                        handleValueChange({'editedValue': e.target.valueAsNumber})
                    else {
                        toast.error('Некорректное значение поля!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                        handleValueChange({'editedValue': 0})
                    }
                }

                e.target.blur()
                setEditedField(null)
            }
            if (e.code === 'Escape') {
                e.preventDefault()
                e.target.value = field.value
                e.target.blur()
                setEditedField(null)
            }
        }
    }

    // Функция выбора поля для ввода/редактирования значения в зависимости от типа поля записи
    const selectFieldInput = (fieldItem, dataObject, rowIndex, colIndex ,selectedDataObject, isCellSelected, isCellEdited) => {
        const fieldName = 'data.' + fieldItem.tech_name + '.' + rowIndex + '.' + colIndex
        const isRowSelected = (selectedDataObject !== null && dataObject.id === selectedDataObject.id)
        const editedCell = fieldItem === selectedField && isCellEditionAvailable
        const editedIndicatorValueCell = editedCell && fieldItem.tech_name === 'value_of_the_indicator__indicatorss' && fixedValues.length > 0

        if ((fieldItem.validator_type === 'one' || fieldItem.validator_type === 'many') && fieldItem.type === 'reference') {
            return <Controller
                    name={fieldName}
                    control={control}
                    rules={ {validate: value => (value?.value?.values && value?.value?.values?.length > 0) || !fieldItem.mandatory} }
                    defaultValue={fieldItem}
                    render={() =>
                        <DataReferenceInput
                            value={fieldItem}
                            onSelectClick={() => handleEditCellClick(dataObject, fieldItem)}
                            onClearClick={() => {handleClearCellClick(fieldName, fieldItem); handleEditCellClick(dataObject, fieldItem)}}
                            isNested={true}
                            isRowSelected={isRowSelected}
                            isCellSelected={isCellSelected}
                            readOnly={fieldItem.tech_name === 'indicator__indicatorss' || readOnly || (!readOnly && dataObject.field_validator_type === "reference:many")}
                        />
                    }
                />
        } else if (fieldItem.validator_type === 'bool') {
            const booleanValues = ['Да', 'Нет']

            return  <div className={`${!editedCell && 'tw-pointer-events-none'} tw-w-full`}>
                            <Controller
                                name={fieldName}
                                control={control}
                                rules={ {required: fieldItem.mandatory} }
                                render={() =>
                                    <DataEnumListBox
                                        itemList={booleanValues}
                                        selectedItem={fieldItem.value ? 'Да' : 'Нет'}
                                        onItemChange={(value) => {
                                            setValue(fieldName, value === 'Да');
                                            handleValueChange({'editedValue': value === 'Да'})
                                        }}
                                        isNested={true}
                                        id={rowIndex + '.' + colIndex}
                                        readOnly={readOnly || (!readOnly && dataObject.field_validator_type === "reference:many")}
                                        isRowSelected={isRowSelected}
                                        panel={panel}
                                    />
                                }
                            />
                    </div>

        } else if (fieldItem.validator_type === 'int') {
            if(!editedCell)
                setValue(fieldName, setFieldValue(fieldItem))

            return  <input
                        type='number'
                        disabled={readOnly || (!readOnly && dataObject.field_validator_type === "reference:many")}
                        defaultValue={setFieldValue(fieldItem)}
                        className={`${!editedCell && 'tw-pointer-events-none'} ${isRowSelected && 'tw-bg-gray-300'}
                                    tw-mt-1 tw-w-full tw-px-2 tw-text-gray-900 tw-text-sm focus-visible:tw-outline-none`
                        }
                        onKeyDown={(e) => handleKeyPress(e, editedCell, fieldItem)}
                        autoFocus={isCellEdited}
                        {...register(fieldName, { 
                            required: fieldItem.mandatory, 
                            valueAsNumber: true,
                        })} 
                    />
        } else if (fieldItem.validator_type === 'float') {
            if(!editedCell)
                setValue(fieldName, setFieldValue(fieldItem))

            return  <input
                        type='number'
                        disabled={readOnly || (!readOnly && dataObject.field_validator_type === "reference:many")}
                        defaultValue={setFieldValue(fieldItem)}
                        step={1 / (10 ** fieldItem.options.number_decimal_places)}
                        className={`${!editedCell && 'tw-pointer-events-none'} ${isRowSelected && 'tw-bg-gray-300'} ${editedCell && ' tw-bg-gray-100'} 
                                    tw-mt-1 tw-w-full tw-px-2 tw-text-gray-900 tw-text-sm focus-visible:tw-outline-none`
                        }
                        onKeyDown={(e) => handleKeyPress(e, editedCell, fieldItem)}
                        autoFocus={isCellEdited}
                        {...register(fieldName, { 
                            required: fieldItem.mandatory,
                            valueAsNumber: true,
                        })} 
                    />
        } else if (fieldItem.validator_type === 'enum' || editedIndicatorValueCell) {
            let value = setFieldValue(fieldItem)
            
            return  <div className={`${!editedCell && 'tw-pointer-events-none'} tw-w-full`}>
                        <Controller
                            name={fieldName}
                            control={control}
                            rules={ {required: fieldItem.mandatory, setValueAs: v => v === '' ? undefined : v} }
                            render={() =>
                                <DataEnumListBox
                                    itemList={editedIndicatorValueCell ? fixedValues : fieldItem?.options?.allowed_values}
                                    selectedItem={value? value : ''}
                                    onItemChange={editedIndicatorValueCell
                                        ? (e) => {setValue(fieldName, value); handleValueChange({'editedValue': e})}
                                        : (e) => {handleValueChange({'editedValue': e})}
                                    }
                                    isNested={true}
                                    id={rowIndex + '.' + colIndex}
                                    readOnly={readOnly || (!readOnly && dataObject.field_validator_type === "reference:many")}
                                    panel={panel}
                                    isRowSelected={isRowSelected}
                                />
                            }
                        />
                    </div>
        } else if (fieldItem.validator_type === 'date')
            return  <div className=''>
                        <Controller
                            name={fieldName}
                            control={control}
                            defaultValue={setFieldValue(fieldItem)}
                            rules={ {required: fieldItem.mandatory, valueAsDate: true} }
                            render={() =>
                                <ReactDatePicker
                                    disabled={readOnly || (!readOnly && dataObject.field_validator_type === "reference:many")}
                                    className={`${!editedCell && 'tw-pointer-events-none'} ${isRowSelected && 'tw-bg-gray-300'} tw-w-full tw-text-sm tw-text-gray-900 tw-p-1 focus-visible:tw-outline-none`}
                                    calendarStartDay={1}
                                    calendarIcon={<CalendarIcon/>}
                                    dateFormat={fieldItem.options.format.toLowerCase() !== 'dd.mm.yyyy' ? 'dd.MM.yyyy HH:mm:ss' : 'dd.MM.yyyy'}
                                    timeFormat="HH:mm:ss"
                                    locale='ru'
                                    timeCaption='Время'
                                    showTimeSelect={fieldItem.options.format.toLowerCase() !== 'dd.mm.yyyy'}
                                    selected={setFieldValue(fieldItem)}
                                    onChange={(e) => {handleValueChange({'editedValue': new Date(e).getTime()})}}
                                />
                            }
                        />
                    </div>
        else {
            let value = setFieldValue(fieldItem)
            setValue(fieldName, value)
            
            return <div className='tw-w-full tw-flex'>
                    <input
                        type='text'
                        disabled={readOnly || (!readOnly && dataObject.field_validator_type === "reference:many")}
                        className={`${!editedCell && 'tw-pointer-events-none'} ${isRowSelected && 'tw-bg-gray-300'} tw-mt-1 tw-w-full tw-text-gray-900 tw-px-1
                                    tw-text-sm focus-visible:tw-outline-none tw-overflow-hidden tw-truncate`
                        }
                        onKeyDown={(e) => handleKeyPress(e, editedCell, fieldItem)}
                        autoFocus={isCellEdited}
                        {...register(fieldName, { 
                            required: fieldItem.mandatory,
                            value: setFieldValue(fieldItem)
                        })} 
                    />

                    {fieldItem.tech_name === 'justification_of_irrelevance__indicatorss' 
                        && categorizingCIIStore.selectedIndicator && editedCell && editedField &&
                        <SuggestionsList
                            id={categorizingCIIStore.selectedIndicator.id}
                            setText={(id, text, init) => handleValueChange({'editedValue': text})}
                            suggestionsRuleId={suggestionsRuleId}
                            tooltipId={"nested-model-panel-tooltip"}
                        />
                    }
                </div>
        }
    }

    const processTableScroll = (element) => {
        if (element) {
            headerElement.current.scrollLeft = element.scrollLeft
            setIsMinScrollYPosition(frameElement.current.scrollTop === 0)

            if (!isDocumentPage && dataModel.id === 'indicatorss') {
                onScrollX(element)
            }
        }
    }

    const scrollTableUp = () => {
        if (frameElement) {
            frameElement.current.scrollTop = 0
        }
    }

    const saveNestedRecord = (data) => {
        const  dataField = createEmptyFieldData(dataModel)
        const newDataField = {}
        
        Object.entries(dataField).map(([dataFieldKey, dataFieldValue], index) => {
            const dataValue = Object.entries(data.data).find(([key, value], index) => dataFieldKey === key)

            if (dataFieldValue.type === 'reference') {
                newDataField[dataFieldKey] = {...dataFieldValue, ...dataValue[1][0]}
            } else {
                newDataField[dataFieldKey] = {...dataFieldValue, value: dataValue[1]}
            }
        })

        const createDataObject  = createNestedDataObject(newDataField, sortedList.length, dataModel)
        const originalNestedModel = nestedDataModels.find(item => item.rule_id === dataModel.rule_id)
        const editedNestedModel = originalNestedModel.dataObjects.concat([createDataObject])

        if (isDocumentPage) {
            if (dataModel.type === "nested") {
                setNestedDataModels(nestedDataModels.map(item => 
                    item.rule_id === dataModel.rule_id
                        ?   {...item, dataObjects: editedNestedModel}
                        :   item
                ))
            } else {
                onChange(originalNestedModel)
            }
        } else {
            onChange(editedNestedModel, dataModel)
        }
        
        setIsAddFormOpen(false)
        setIsDuplicateFormOpen(false)
    }

    const editNestedRecord = (data) => {
        const editedDataField = {}
        
        Object.entries(selectedNestedDataObject.data).map(([dataFieldKey, dataFieldValue], index) => {
            const dataValue = Object.entries(data.data).find(([key, value], index) => dataFieldKey === key)

            if (dataFieldValue.type === 'reference') {
                editedDataField[dataFieldKey] = {...dataFieldValue, ...dataValue[1][0]}
            } else {
                editedDataField[dataFieldKey] = {...dataFieldValue, value: dataValue[1]}
            }
        })

        let editedDataObject = {...selectedNestedDataObject, data: editedDataField}
        if (selectedNestedDataObject.status === 'saved')
            editedDataObject.status = 'edited'

        if (isDocumentPage) {
            setNestedDataModels(nestedDataModels.map(nestedModel => nestedModel.rule_id !== dataModel.rule_id
                ?   nestedModel
                :   {...nestedModel, 
                        dataObjects: nestedModel.dataObjects.map(dataObject => dataObject.id !== selectedNestedDataObject.id
                            ?   dataObject
                            :   editedDataObject
                    )}
            ))
        } else {
            let editedValues = dataObjects.map(dataObject => 
                (dataObject.id !== selectedNestedDataObject.id || dataObject.status === 'deleted')
                    ?   dataObject
                    :   editedDataObject
            )
            .sort((a, b) => a.order - b.order)

            onChange(editedValues, dataModel, selectedNestedDataObject)
        }

        setIsEditFormOpen(false)
    }

    const handleObjectContainerCloseClick = () => {
        setIsAddFormOpen(false)
        setIsDuplicateFormOpen(false)
        setIsEditFormOpen(false)
    }
    
    useEffect(() => {
        if(dataModel.id === "indicatorss" && !isDocumentPage){
            setSortedList(dataObjects?.slice().sort((a, b) => a.order - b.order).filter(item => item.status !== 'deleted'))
        } else {
            setSortedList(sortRecords(dataObjects, sortingDirection, sortingColumn, sortingType, true).filter(item => item.status !== 'deleted'))
        }

        if(dataModel.id === "indicatorss"){
            DocumentService.getOneDataModel('significance_indicators')
                .then(response => {
                    response.nested_models.forEach(nestedModel => {    
                        if(nestedModel.id === 'justification_of_inapp_vt'){
                            setSuggestionsRuleId(nestedModel.rule_id)
                        }
                    })
                })
        }
    }, [])

    useEffect(() => {
        if (dataModel.id === "indicatorss" && !isDocumentPage) {
            setSortedList(dataObjects?.slice().filter(item => item.status !== 'deleted').sort((a, b) => a.order - b.order))
        } else {
            setSortedList(sortRecords(dataObjects, sortingDirection, sortingColumn, sortingType, true).filter(item => item.status !== 'deleted'))
        }
    }, [sortingDirection, sortingColumn, sortingType, dataObjects])

    useEffect(() => {
        if (sortedList.length) {
            const [foundEmptyRefRecord, refField] = findEmptyReference()
            if (foundEmptyRefRecord) {
                if (refField.field_id === 'org_measures_of_zokii') {
                    FilterStore.clearAllFilters()
                    sessionStorage.setItem(`protection_measures_filters`, JSON.stringify([{property:"data[\"org_or_tech__protection_measures\"].value",value:["О","ОТ"],operator:"in"}]))
                }
                
                if (refField.field_id === 'tech_measures_of_zokii') {
                    FilterStore.clearAllFilters()
                    sessionStorage.setItem(`protection_measures_filters`, JSON.stringify([{property:"data[\"org_or_tech__protection_measures\"].value",value:["Т","ОТ"],operator:"in"}]))
                }

                setEditedRow(foundEmptyRefRecord)
                setEditedField(refField)
            }
            setIsVerticalScroll(frameElement.current.scrollHeight !== frameElement.current.clientHeight)

            if (editedField?.type === 'reference') {
                let chosenObjects = []
                sortedList.forEach(item => {
                    chosenObjects.push({...item.data[editedField.tech_name], status: item.status})
                })
                setChosenDataObjectsList(chosenObjects)
            }
        }
    }, [sortedList])

    useEffect(() => {
        if (docStore.isIncludeTypeField && isDocumentPage && docStore.tabsID === panelID) {
            setEditedField(docStore.includeField)
            docStore.setIncludeField(null)
            setIsRefFormOpen(true)
            setIsMultiSelect(true)
        }
    }, [docStore.isIncludeTypeField, docStore.tabsID])

    return (
        <div className='tw-h-full tw-overflow-hidden'>
            <NestedModelHeader
                dataModelID={dataModel.id}
                table={tableElement}
                sizes={columnSizes}
                columns={columns}
                values={columnValues}
                sortingColumn={sortingColumn}
                sortingDirection={sortingDirection}
                onSortClick={handleSortClick}
                isDocumentPage={isDocumentPage}
                header={headerElement}
                isVerticalScroll={isVerticalScroll}
                isMinScrollYPosition={isMinScrollYPosition}
                onScrollUp={scrollTableUp}
            />
            <div id={'nested_' + dataModel.id} ref={frameElement} className='tw-h-[calc(100%_-_1.5rem)] tw-overflow-auto' onScroll={(e) => processTableScroll(e.target)}>
                <table ref={tableElement} className='tw-w-full tw-mb-3'>
                    <tbody>
                        {/* невидимая строка для отображения полосы прокрутки, даже если нет записей */}
                        {sortedList.length === 0 && 
                            <tr 
                                style={{
                                    display: 'grid', 
                                    gridTemplateColumns: columnSizes,
                                    borderBottomWidth: '1px',
                                    visibility: 'hidden',
                                    pointerEvents: 'none'
                                }} 
                            ></tr>
                        }
                        { sortedList.map((dataObject, rowIndex) =>
                            <Fragment key={rowIndex}>
                                <tr 
                                    style={{
                                        display: 'grid', 
                                        gridTemplateColumns: columnSizes, 
                                        borderBottomWidth: '1px',
                                        borderColor: 'rgb(156 163 175)',
                                        background: selectedNestedDataObject && dataObject.id === selectedNestedDataObject.id ? 'rgb(209 213 219)' : 'white',
                                        color: 'black',
                                        lineHeight: '1.5rem',
                                        height: '1.9rem',
                                    }} 
                                    id={dataModel.id +'nested_table_row' + dataObject.id}
                                >
                                    <td 
                                        className={`tw-py-1 tw-text-sm tw-text-center tw-text-gray-500 hover:tw-cursor-pointer ${!isDocumentPage ? 'tw-border-x' : 'tw-border-r'} tw-border-gray-400`}
                                        onClick={() => handleSelectRowClick(dataObject, rowIndex)}
                                    >
                                        {(rowIndex + 1)}
                                    </td>
                                    { Object.values(dataObject.data).sort((a, b) => a.order - b.order).map((field, colIndex) => {
                                        const value = getFieldValue(field)
                                        const isCellSelected = selectedRowIndex === rowIndex && selectedColIndex === colIndex
                                        const isCellEdited = isCellSelected && editedField !== null
                                        return ( !field.hide && field.type !== 'include' && !(field.type === 'reference' && field.validator_type === 'many') &&
                                            <td 
                                                key={colIndex}
                                                className={`tw-overflow-hidden tw-truncate tw-select-none tw-text-left tw-text-sm
                                                            ${isCellSelected && (field.type === 'reference' || !isCellEdited)
                                                                ?   'tw-border-2'
                                                                :   isCellEdited || field.error
                                                                        ?   'tw-border'
                                                                        :   'tw-border-r'}
                                                            ${isCellEdited
                                                                ?   'tw-border-red-200'
                                                                :   field.error 
                                                                        ?   'tw-border-red-500 tw-rounded-sm'
                                                                        :   'tw-border-gray-400'}`}
                                                data-tooltip-id="nested-model-panel-tooltip" data-tooltip-content={value?.toString().trim()} data-tooltip-delay-show={contentTooltipTimeOut} data-tooltip-delay-hide={hideTooltipTimeOut}
                                                onClick={(e) => {e.preventDefault(); handleSelectCellClick(rowIndex, colIndex, dataObject, field)}}
                                                onDoubleClick={(e) => {
                                                    e.preventDefault()
                                                    if (!readOnly && dataObject.field_validator_type !== "reference:many")
                                                        handleEditCellClick(dataObject, field)
                                                }}
                                                onBlur={handleCellExit}
                                            >
                                                { selectFieldInput(field, dataObject, rowIndex, colIndex, selectedNestedDataObject, isCellSelected, isCellEdited) } 
                                            </td>
                                        )}
                                    )}
                                </tr>
                            </Fragment>
                        )}
                    </tbody>
                </table>
            </div>
            
            { editedField && (editedField.type === 'reference' || editedField.type === 'include') &&
                <DirectoryContainer
                    isOpen={isRefFormOpen}
                    selectedDataModel={editedField.ref_model_ids[0]}
                    onDoubleClick={handleValueChange}
                    onCloseClick={handleReferenceCloseClick}
                    chosenDataObjectsList={docStore.isIncludeTypeField ? sortedList : chosenDataObjectsList}
                    isChosenObjectDuplicationForbidden={isChosenObjectDuplicationForbidden}
                    isMultiSelect={isMultiSelect}
                />
            }
            <AddDataObjectContainer
                isOpen={isAddFormOpen}
                onCloseClick={handleObjectContainerCloseClick}
                selectedFullDataModel={dataModel}
                isNested={true}
                saveNestedRecord={saveNestedRecord}
            />
            <DuplicateDataObjectContainer
                isOpen={isDuplicateFormOpen} 
                onCloseClick={handleObjectContainerCloseClick}
                selectedFullDataModel={dataModel}
                isNested={true}
                saveNestedRecord={saveNestedRecord}
                selectedNestedDataObject={selectedNestedDataObject}
            />
            <EditDataObjectContainer
                isOpen={isEditFormOpen} 
                onCloseClick={handleObjectContainerCloseClick}
                selectedFullDataModel={dataModel}
                isNested={true}
                saveNestedRecord={editNestedRecord}
                selectedNestedDataObject={selectedNestedDataObject}
            />
            <Tooltip id="nested-model-panel-tooltip" className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
        </div>
    )
}

export default observer(NestedTableFieldsList)