import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../../../config/constsURN'
import { Context } from '../../../..'
import CalendarItem from './CalendarItem'
import Spinner from '../../../../assets/Spinner'
import { observer } from 'mobx-react-lite'

/**
 * Панель с запланированными действиями Планировщика на стартовой странице
 * 
 */
const CalendarPanel = () => {
    const { plannerStore, userStore } = useContext(Context)
    const navigate = useNavigate()

    return (
        <div className='tw-h-[512px] tw-flex tw-flex-col tw-bg-white tw-rounded-md tw-overflow-hidden'>
            <div className={`tw-h-8 tw-text-sm tw-text-left tw-font-semibold tw-w-full tw-pl-4 tw-py-1 tw-rounded-t-md  tw-border-gray-400 
                tw-bg-gray-50 tw-border-b-2 ${userStore.modulesPermissions?.['planner_only'] ? 'tw-text-gray-800' : 'tw-text-gray-400'}`}
            >
                Календарь событий
            </div>
            { plannerStore.isPlannedActionsLoading
                ?
                    <Spinner/>
                :   <>
                        {userStore.modulesPermissions?.['planner_only']
                            ?   <div className='tw-h-grow tw-w-full tw-p-4 tw-cursor-pointer tw-overflow-auto'
                                    onClick={() => navigate(paths.PLANNER_ROUTE)}
                                >
                                    { plannerStore.plannedActions.map((item, index) => (
                                        <CalendarItem key={index} item={item}/>
                                    ))}
                                    { !plannerStore.plannedActions.length &&
                                        <span className='tw-w-full tw-text-sm tw-italic'>
                                            Отсутствуют запланированные действия
                                        </span>
                                    }
                                </div>
                            :   <div className='tw-h-grow tw-w-full tw-p-4 tw-overflow-auto'>
                                    <span className='tw-w-full tw-text-sm tw-italic tw-text-gray-400'>
                                        Отсутствует право на просмотр данного модуля
                                    </span>
                                </div>
                        }
                    </>
            }
        </div>
    )
}

export default observer(CalendarPanel)