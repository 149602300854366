import React from 'react'
import { ForwardIcon, PlayIcon, PauseIcon, PlayPauseIcon } from '@heroicons/react/20/solid'
import { toHHMMSS } from '../../../../../../functions/toHHMMSS'

/**
 * Визуальный компонент отображает меню навигации по видео
 * 
 * @param {Boolean} playing Признак проигрывания видео
 * @param {Number} volume Значение громкости звука
 * @param {Number} time Значение длительности видео (в секундах)
 * @param {Number} activeTime Значение текущего фрагмента видео (в секундах)
 * @param {Number} lastTime Значение последнего просмотренного фрагмента видео (в секундах)
 * @param {Function} onRestartClick Обработчик клика мыши на кнопке просмотра видео с самого начала
 * @param {Function} onPlayClick Обработчик клика мыши на кнопке запуска/останова просмотра видео
 * @param {Function} onRewindClick Обработчик клика мыши на кнопке перехода к последнему просмотренному фрагменту видео
 * @param {Function} onVolumeChange Обработчик клика мыши на шкале изменения громкости звука
 * @param {Boolean} isHideControls Признак отображения кнопок управления
 * 
 */
const VideoNavigationMenu = ({playing, volume, time, activeTime, lastTime, onRestartClick, onPlayClick, onRewindClick, onVolumeChange, isHideControls}) => {
    return (
        <div id='navigation-menu' className='tw-h-full tw-px-4 tw-py-1 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2 tw-border-l tw-border-gray-300'>
            <div className='tw-py-1 tw-text-sm tw-text-center'>
                {toHHMMSS(activeTime)}/{toHHMMSS(Math.floor(time))}
            </div>

            {!isHideControls &&
                <>
                    <button
                        className='tw-flex tw-flex-row tw-items-start tw-gap-x-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold
                                    tw-border-gray-500 tw-text-gray-600 hover:tw-bg-gray-200
                                    disabled:tw-text-gray-300 disabled:tw-border-gray-300 disabled:tw-bg-white
                                focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                        disabled={activeTime === 0}
                        onClick={onRestartClick}
                    >
                        <PlayPauseIcon className="tw-h-5 tw-w-5 tw-rotate-180" aria-hidden="true"/>
                    </button>
                    <button
                        className='tw-flex tw-flex-row tw-items-start tw-gap-x-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold
                                    tw-border-gray-500 tw-text-gray-600 hover:tw-bg-gray-200
                                    disabled:tw-text-gray-300 disabled:tw-border-gray-300 disabled:tw-bg-white
                                focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                        disabled={activeTime === 0}
                        onClick={() => onRewindClick(false)}
                    >
                        <ForwardIcon className="tw-h-5 tw-w-5 tw-rotate-180" aria-hidden="true"/>
                    </button>
                    <button
                        className='tw-flex tw-flex-row tw-items-start tw-gap-x-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold
                                    tw-border-gray-500 tw-text-gray-600 hover:tw-bg-gray-200
                                    disabled:tw-text-gray-300 disabled:tw-border-gray-300 disabled:tw-bg-white
                                focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                        onClick={onPlayClick}
                    >
                        { playing
                            ?   <PauseIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                            :   <PlayIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                        }
                    </button>
                    <button
                        className='tw-flex tw-flex-row tw-items-start tw-gap-x-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold
                                    tw-border-gray-500 tw-text-gray-600 hover:tw-bg-gray-200
                                    disabled:tw-text-gray-300 disabled:tw-border-gray-300 disabled:tw-bg-white
                                focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                        disabled={activeTime === lastTime}
                        onClick={() => onRewindClick(true)}
                    >
                        <ForwardIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                    </button>
                    <div className='tw-ml-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2'>
                        <label className='tw-text-sm'>Громкость</label>
                        <input
                            className='tw-accent-gray-600'
                            type='range'
                            min={0}
                            max={1}
                            step='any'
                            value={volume}
                            onChange={onVolumeChange}
                        />
                    </div>
                </>
            }
        </div>
    )
}

export default VideoNavigationMenu