import React, { useCallback, useState, useEffect } from 'react'

/**
 * Визуальный компонент отображает вертикальный разделитель панели 
 * 
 * @param {Object} leftElement Элемент, расположенный слева от разделителя
 * @param {Object} panel Панель, элементы которой делит разделитель
 * @param {Number} minLeftSize Минимально допустимый размер элемента, расположенного слева от разделителя
 * @param {Number} minRightSize Минимально допустимый размер элемента, расположенного справа от разделителя
 * 
 */
const PanelVerticalDivider = ({leftElement, panel, minLeftSize, minRightSize}) => {
    const [isSizing, setIsSizing] = useState(false)
    const [startLeft, setStartLeft] = useState(0)

    const dividerOffset = 10

    const mouseDown = (e) => {
        setStartLeft(e.clientX - leftElement.current.offsetWidth - dividerOffset)
        setIsSizing(true)
    }


    const mouseMove = useCallback((e) => {
        let firstColWidth = e.clientX - startLeft
        if (firstColWidth < minLeftSize)
            firstColWidth = minLeftSize

        let secondColWidth = panel.current.offsetWidth - firstColWidth
        if (secondColWidth < minRightSize) {
            secondColWidth = minRightSize
            firstColWidth = panel.current.offsetWidth - secondColWidth
        }

        const firstColPercent = firstColWidth / panel.current.offsetWidth * 100
        const secondColPercent = (100 - firstColPercent)
   
        panel.current.style.gridTemplateColumns = `${firstColPercent.toFixed(6)}% ${secondColPercent.toFixed(6)}%`
    }, [panel, startLeft])

    
    const removeListeners = useCallback(() => {
        window.removeEventListener("mousemove", mouseMove)
        window.removeEventListener("mouseup", mouseUp)
    }, [mouseMove])

    
    const mouseUp = useCallback(() => {
        if (panel.current) {
            sessionStorage.setItem('savedFormSizes', panel.current.style.gridTemplateColumns)
        }

        removeListeners()
        setIsSizing(false)
    }, [setIsSizing, removeListeners])


    useEffect(() => {
        if (isSizing) {
            window.addEventListener("mousemove", mouseMove)
            window.addEventListener("mouseup", mouseUp)
        }
    
        return () => {
            removeListeners()
        }
    }, [isSizing, mouseMove, mouseUp, removeListeners])


    return (
        <div
            id='panel-vertical-divider'
            className='tw-absolute tw-top-0 tw-left-0 tw-bottom-0 tw-h-full tw-rounded-md tw-w-1 tw-mx-1.5 
                        tw-bg-gray-500 tw-cursor-col-resize tw-opacity-30'
            onMouseDown={mouseDown}
        >
        </div>
    )
}

export default PanelVerticalDivider