import React, { useContext } from 'react'
import {registerLocale} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import ru from "date-fns/locale/ru"
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../../..'
import ObjectsListButtons from '../../../common/panels/object_list/ObjectsListButtons'
import FormFieldName from '../../../../../form_fields/FormFieldName'
import FieldCategory from '../../../../../form_fields/FieldCategory'

registerLocale("ru", ru)

/**
 * Визуальный компонент отображает форму вопроса теста
 * 
 * @param {Function} register Метод, реализующий регистрацию поля UseForm
 * @param {Function} saveTestResults Сохранение результатов теста
 *   
 */
const QuestionsForm = ({register, saveTestResults}) => {   
    const { EducationStore } = useContext(Context)

    const handleBackClick = () => {
        saveTestResults()
        EducationStore.setQuestionsStage(EducationStore.questionsList[EducationStore.questionsStage.value - 1])
    }

    const handleForwardClick = async () => {
        saveTestResults()
        EducationStore.setQuestionsStage(EducationStore.questionsList[EducationStore.questionsStage.value + 1])
    }

    const handleAnswerClick = () => {
        const updatedQuestionsList = EducationStore.questionsList.map(item => (            
            (EducationStore.questionsStage.id === item.id) 
                ?   {...item, status: 'viewed'}
                :   {...item}
        ))        
        EducationStore.setQuestionsList(updatedQuestionsList)
    }

    return (
        <>
            <div className='tw-grow tw-overflow-auto'>
                <div className='tw-flex tw-flex-col tw-h-full'>
                    <div className='tw-px-4 tw-py-1'>
                        <div className='tw-my-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-py-2'>
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-start tw-justify-between">
                                <FormFieldName 
                                    item={{...EducationStore.questionsStage.question.data['question_text'], mandatory: false}}
                                    errors={[]}
                                />
                            </dt>
                            <dd className="tw-mt-1 tw-font-semibold tw-text-md tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                <p>{EducationStore.questionsStage.question.data['question_text'].value}</p>
                            </dd>                                    
                        </div>
                        <div className='tw-my-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-py-2'>
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-start tw-justify-between">
                                <FieldCategory
                                    categories={[{
                                        tech_name: "question_options",
                                        name: "Варианты ответов",
                                        mandatory: false,
                                        description: EducationStore.questionsStage.question.data['is_multiple_select'].value 
                                            ? "Выберите несколько вариантов ответа"
                                            : "Выберите один ответ"
                                    }]}
                                    fieldItem={{tech_name: "question_options"}}
                                />
                            </dt>
                            <dd className="tw-mt-1 tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                <form>
                                    {EducationStore.questionsStage.question.data['is_multiple_select'].value
                                        ?   <>
                                                {EducationStore.questionsStage.options.map(item => 
                                                    <div key={item.id} className='tw-text-sm tw-my-4 tw-py-2 tw-flex tw-flex-row tw-items-center'>
                                                        <input
                                                            id={item.id}
                                                            type="checkbox"
                                                            className='tw-mr-4 tw-cursor-pointer'
                                                            name={item.id}
                                                            onClick={handleAnswerClick}
                                                            {...register(item.id, { value: item.isAnswer })}
                                                        />
                                                        <label className='tw-cursor-pointer' htmlFor={item.id}>{item.data['answer_option'].value}</label>
                                                    </div>
                                                )}
                                            </>
                                        :   <>
                                                {EducationStore.questionsStage.options.map(item => 
                                                    <div key={item.id} className='tw-text-sm tw-my-4 tw-py-2 tw-flex tw-flex-row tw-items-center'>
                                                        <input
                                                            id={item.id}
                                                            type="radio" 
                                                            className='tw-mr-4 tw-cursor-pointer'
                                                            value={item.id}
                                                            defaultChecked={item.isAnswer} 
                                                            onClick={handleAnswerClick}
                                                            {...register(EducationStore.questionsStage.id)}
                                                        />
                                                        <label className='tw-cursor-pointer' htmlFor={item.id}>{item.data['answer_option'].value}</label> 
                                                    </div>
                                                )}
                                            </>
                                    }
                                </form>
                            </dd>                                    
                        </div>
                    </div>                            
                </div>
            </div>
            <ObjectsListButtons
                onBackClick={handleBackClick}
                onForwardClick={handleForwardClick}
                stage={EducationStore.questionsStage}
                objectsList={EducationStore.questionsList}
                disabled={false}
                forwardButtonTitle={'Следующий вопрос'}
                backButtonTitle={'Предыдущий вопрос'}
            />
        </>                                                             
    )
}

export default observer(QuestionsForm)
