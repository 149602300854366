import React, { useContext, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify'
import {registerLocale} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import ru from "date-fns/locale/ru"
import { Tooltip } from 'react-tooltip'
import DataPrintMenu from '../menu/DataPrintMenu'
import DirectoryContainer from '../../common/panels/directory/DirectoryContainer'
import { Context } from '../../../../..'
import FormFieldInput from '../../../../form_fields/FormFieldInput'
import FormFieldName from '../../../../form_fields/FormFieldName'
import DataReferenceInput from '../../common/inputs/DataReferenceInput'
import { emptyReferenceValue, setReferenceValue } from '../../../../../config/constTypes'
import SaveIcon from '../../../../../assets/SaveIcon'
import SelectReferencedModel from '../../common/modals/SelectReferencedModel'
import Spinner from '../../../../../assets/Spinner'
import { useHotkeys } from 'react-hotkeys-hook'
import { LinkIcon } from '@heroicons/react/20/solid'
import { menuTooltipTimeOut, serviceMessageTimeOut, successMessageTimeOut } from '../../../../../config/constTypes'
import { copyToClipboard } from '../../../../../functions/copyToClipboard'
import { paths } from '../../../../../config/constsURN'

registerLocale("ru", ru)


/**
 * Визуальный компонент отображает форму для создания или редактирования записи в таблице
 * 
 * @param {Boolean} editMode Флаг режима редактирования поля
 * @param {Object} dataObjectFields Поля записи
 * @param {Function} onSubmitClick Обработчик клика мыши на кнопке сохранения данных
 * @param {Object[]} userErrors Массив ошибок в полях формы (пользовательских)
 * @param {String} recordID ID редактируемой записи
 * @param {Function} onPrintDocumentClick Обработчик клика мыши на пункте меню экспорта записи в файл PDF согласно выбранной печатной форме
 * @param {Boolean} isLoading Признак загрузки формы
 * @param {Object} dataModel Выбранная таблица
 * @param {Object} parent Родительская запись
 * 
 */
const DataObjectForm = ({editMode, dataObjectFields, onSubmitClick, userErrors, recordID,
                            onPrintDocumentClick, isLoading, dataModel, parent}) => {   
    const {
        control,
        register,
        handleSubmit,
        setValue,
        getValues,
        resetField,
        clearErrors,
        formState: { errors, isSubmitting, isValid },
    } = useForm()


    const dummyModel = {id: '0', entity_name: ''}

    const parentField = {
        alias: 'Запись-родитель',
        tech_name: 'parent_record_id',
        description: 'Данное необязательное поле заполняется при необходимости создания иерархической записи',
        mandatory: false,
        type: 'reference',
        validator_type: 'one',
        ref_model_ids: [dataModel.id],
        value: parent
                    ?   {
                            format: parent.data_model.format, 
                            values: [ {
                                        id: parent.id,
                                        record_id: parent.record_id,
                                        entity_name: parent.data_model.entity_name,
                                        name: parent.data.name,
                                        active: parent.active,
                                        data_model_id: parent.data_model_id,
                                        deletion_mark: parent.system_data.deletion_mark
                                    }]
                        }
                    :   emptyReferenceValue
    }

    const { docStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [selectedDataModelID, setSelectedDataModelID] = useState(null)
    const [selectedName, setSelectedName] = useState(null)
    const [isRefSelectOpen, setIsRefSelectOpen] = useState(false)
    const [referencedModels, setReferencedModels] = useState([])
    const [selectedRefModel, setSelectedRefModel] = useState(dummyModel)
    const [isSelectedRefModelError, setIsSelectedRefModelError] = useState(false)
    const [chosenDataObjectsList, setChosenDataObjectsList] = useState([])

    const keyRef = useHotkeys('ctrl+s', () => {if (dataObjectFields.length && !isLoading) handleSubmit(onSubmitClick)()},
        { preventDefault: true, enableOnFormTags: true }
    )
    useHotkeys('ctrl+p', () => {if (editMode && recordID && !isSelectFormOpen && !isRefSelectOpen) onPrintDocumentClick()},
        { preventDefault: true, enableOnFormTags: true }
    )
    useHotkeys('ctrl+l', () => {if (editMode && recordID && !isSelectFormOpen && !isRefSelectOpen) handleCopyToClipboardClick()},
        { preventDefault: true, enableOnFormTags: true }
    )

    const handleSelectClick = (dataModelList, name, field) => {
        let fieldValue = getValues(name)
        if (fieldValue) {
            setChosenDataObjectsList([fieldValue])
        }

        clearErrors(name)
        if (Array.isArray(dataModelList)) {
            setSelectedName(name)
            setReferencedModels(dataModel.referenced_models.filter(model => model.rule_id === field.rule_id))
            setSelectedDataModelID(null)
            setIsRefSelectOpen(true)
        } else {
            setSelectedName(name)
            setSelectedDataModelID(dataModelList)
            setIsSelectFormOpen(true)
        }
    }

    const handleSelectDirectory = (item) => {
        setSelectedRefModel(item)
        setSelectedDataModelID(item.id)
    }

    const handleSubmitClick = () => {
        if(selectedRefModel.id === '0')
            setIsSelectedRefModelError(true)
        else{
            setIsSelectedRefModelError(false)
            setIsRefSelectOpen(false)
            setIsSelectFormOpen(true)
        }
    }

    const handleCloseModalClick = () => {
        setIsSelectedRefModelError(false)
        setReferencedModels([])
        setSelectedRefModel(dummyModel)
        setIsRefSelectOpen(false)
    }

    const handleClearClick = (name) => {
        setValue(name, emptyReferenceValue)
        setChosenDataObjectsList([])
    }

    const handleDoubleClick = async (item) => {
        resetField(selectedName)
        setValue(selectedName, {value: setReferenceValue(item)})
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
        setSelectedName(null)

        docStore.performReferenceFieldAction(selectedName, item, dataModel, dataObjectFields, setValue, getValues)
    }

    const handleCloseClick = () => {
        docStore.setIsDetailView(false)
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
        setChosenDataObjectsList([])
    }

    const handleCopyToClipboardClick = () => {
        try {
            copyToClipboard(`${paths.DATAOBJECT_ROUTE}/${dataModel.id}/?text=record_id=${recordID}`)
            toast.info('Ссылка скопирована в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
        } catch (error) {
            toast.error('Не удалось скопировать ссылку в буфер обмена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }
    }

    isSubmitting && (!isValid || errors.data) && 
        toast.error('Не заполнено обязательное поле или введено некорректное значение!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })

    return (
        <>
            <form ref={keyRef} tabIndex={-1} className='tw-flex tw-flex-col tw-h-full tw-rounded-md tw-bg-white'>
                <div className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                    <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Запись</p>
                    <div className='tw-flex tw-flex-row tw-gap-x-1'>
                        <button
                            className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                            onClick={handleSubmit(onSubmitClick)}
                            disabled={!dataObjectFields.length || isLoading}
                            data-tooltip-id="data-object-form-tooltip"
                            data-tooltip-html="Сохранить &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+S)"
                            data-tooltip-delay-show={menuTooltipTimeOut}
                        >
                            <SaveIcon/>
                        </button>
                        { editMode && recordID &&
                            <DataPrintMenu
                                notPrintRecord={isLoading}
                                onPrintDocumentClick={onPrintDocumentClick}        
                            />
                        }
                        { editMode && recordID &&
                            <button
                                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                                onClick={handleCopyToClipboardClick}
                                type='button'
                                data-tooltip-id="data-object-form-tooltip"
                                data-tooltip-html="Копировать ссылку в буфер обмена &nbsp;&nbsp;&nbsp;&nbsp; (Ctrl+L)"
                                data-tooltip-delay-show={menuTooltipTimeOut}
                            >
                                <LinkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            </button>
                        }
                    </div>
                </div>
                <div className='tw-grow tw-p-4 tw-overflow-auto'>
                    { !isLoading
                        ?
                            <>
                                { dataModel.allow_hierarchy &&
                                    <div className="tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-pb-3 tw-border-b">
                                            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                                                <FormFieldName  
                                                    item={parentField}
                                                    errors={errors}
                                                />
                                            </dt>
                                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                <Controller
                                                    name={'system_data.' + parentField.tech_name}
                                                    control={control}
                                                    defaultValue={ (editMode) ? parentField : emptyReferenceValue}
                                                    render={({field}) =>
                                                        <DataReferenceInput
                                                            value={field.value}
                                                            onChange={(e) => {field.onChange(e)}}
                                                            onSelectClick={() => handleSelectClick(parentField.ref_model_ids[0], 'system_data.' + parentField.tech_name)}
                                                            onClearClick={() => handleClearClick('system_data.' + parentField.tech_name)}
                                                            isError={false}
                                                            isNested={false}
                                                            isRowSelected={false}
                                                            isCellSelected={true}
                                                            readOnly={false}
                                                        />}
                                                />

                                            </dd>
                                    </div>
                                }
                                { dataObjectFields.sort((a, b) => a.order - b.order).map((fieldItem, index) => {
                                    let isError = (errors?.data && errors?.data[fieldItem.tech_name]) || (userErrors?.data && userErrors.data[fieldItem.full_name])
                                    return ( (!fieldItem.hide || fieldItem.mandatory) && fieldItem.type !== 'include' && !(fieldItem.type === 'reference' && fieldItem.validator_type === 'many') &&
                                        <div key={index} className="tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                                            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                                                <FormFieldName  
                                                    item={fieldItem}
                                                    errors={errors}
                                                />
                                            </dt>
                                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                <FormFieldInput
                                                    item={fieldItem}
                                                    isEditMode={editMode}
                                                    onReferenceSelect={handleSelectClick}
                                                    onReferenceClear={handleClearClick}
                                                    onRecordChange={(e) => docStore.performScalarFieldAction(dataModel, dataObjectFields, setValue, getValues)}
                                                    register={register}
                                                    control={control}
                                                    setValue={setValue}
                                                    isError={isError}
                                                />
                                            </dd>
                                        </div>
                                    )}
                                )}
                            </>
                    :   <Spinner/>
                    }
                </div>
            </form>
            <Tooltip id="data-object-form-tooltip" className="tw-break-all tw-max-w-lg tw-z-20" place="top-start"/>
            <DirectoryContainer 
                isOpen={isSelectFormOpen}
                selectedDataModel={selectedDataModelID}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
                chosenDataObjectsList={chosenDataObjectsList}
            />
            <SelectReferencedModel
                isVisible={isRefSelectOpen}
                dataModelList={referencedModels}
                selectedItem={selectedRefModel}
                onItemChange={handleSelectDirectory}
                onSubmit={handleSubmitClick}
                onCloseModal={handleCloseModalClick}
                isError={isSelectedRefModelError}
            />
        </>                                               
    )
}

export default DataObjectForm