import React, { useContext } from 'react'
import DataModelListItem from './DataModelListItem'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../..'
import { Tooltip } from 'react-tooltip'

/**
 * Визуальный компонент отображает список существующих таблиц
 * 
 */
const DataModelList = () => {
    const { docStore } = useContext(Context)

    return (
        <>
            <div  id='data-model-list-items' className='tw-flex tw-flex-col tw-h-[calc(100%_-_3rem)] tw-w-full tw-overflow-auto'>
                {  docStore.dataModels
                    .slice()
                    .map((dataModel, index) => 
                        <DataModelListItem 
                            key={index}
                            item={dataModel}
                        />
                )}
            </div>
            <Tooltip id="data-model-list-tooltip" place="top" style={{ zIndex: 99 }}/>
        </>
    )
}

export default observer(DataModelList)