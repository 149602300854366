import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../../..'
import Spinner from '../../../../../../assets/Spinner'
import FieldCategory from '../../../../../form_fields/FieldCategory'
import TestAttemptsTable from './TestAttemptsTable'


/**
 * Визуальный компонент отображает список результатов попыток теста
 * 
 */
const TestAttempts = () => {
    const { EducationStore, userStore } = useContext(Context)

    useEffect(() => {
        EducationStore.loadSelectedTestAttempts('created', true, userStore.user.id)
    }, [EducationStore.materialsStage])
    
    return (
        <div className='tw-grow tw-overflow-hidden'>
            {!EducationStore.isTestAttemptsLoading
                ?   <>
                        {EducationStore.attemptsList.length > 0
                            ?   <>
                                    <FieldCategory
                                        categories={[{
                                            tech_name: "attempts",
                                            name: "Попытки",
                                            mandatory: false,
                                            description: ''
                                        }]}
                                        fieldItem={{tech_name: "attempts"}}
                                    />
                                    <TestAttemptsTable/>
                                </>
                            :
                                <p className='tw-text-sm tw-mt-4 tw-text-center tw-col-span-4 tw-text-gray-500'>
                                    Для данного теста нет истории попыток
                                </p>
                        } 
                    </>
                :
                    <div className='tw-col-span-4'>
                        <Spinner />
                    </div>
            }
        </div>
    )
}

export default observer(TestAttempts)