import { makeAutoObservable } from "mobx"
import DocumentService from "../services/DocumentService"
import { toast } from "react-toastify"
import { responseTimeOut, serviceMessageTimeOut } from "../config/constTypes"
import { showErrorToast } from "../functions/errorHandlers"
import { getFieldTechName } from "../functions/getFieldTechName"
import { findNestedModelType } from "../functions/nestedModels"

/**
 * Класс реализует хранилище информации о процессе категорирования КИИ
 * @class
 * 
 * @property {Object[]} allStages Этапы процесса категорирования
 * @property {Object} stage Текущий этап процесса категорирования
 * @property {Number} actualStageIndex Номер текущего этапа процесса категорирования
 * @property {Object[]} directories Справочники, используемые в процессе категорирования
 * @property {Object} activeDataModel Структура таблицы, относящейся к текущему этапу категорирования
 * @property {Object[]} nestedModels Массив вложенных таблиц для редактируемой формы текущего этапа
 * @property {Object[]} dataObjects Массив записей вложенной таблицы
 * @property {Object[]} resourceList Массив ресурсов доступа
 * @property {Object[]} processList Массив процессов
 * @property {Object[]} indicatorList Массив показателей значимости
 * @property {Object} selectedIndicator Выбранный индикатор
 * @property {Object} selectedNestedValue Выбранная запись вложенной таблицы
 * @property {Boolean} isDataLoading Состояние процесса загрузки данных
 * @property {Object} project Запись процесса категорирования
 * @property {Number} lastStageIndex Номер этапа, на котором остановлен процесс категорирования
 * @property {Object} commissionID Запись с информацией о составе комиссии
 * @property {Object} commission Информация о комиссии
 * @property {Object} commissionMembers Список членов комиссии
 * @property {Object[]} irrelevantIndicatorsValues Массив неактуальных показателей, выбранных пользователем
 * @property {Date} commissionPrintDate Дата печати приказа о создании комиссии
 * @property {Object} selectedMethod Запись с перечнем выбранных процессов
 * @property {Object[]} processes Список выбранных процессов
 * @property {Object[]} resources Список выбранных ресурсов
 * @property {Object} irrelevantIndicators Запись с информацией о неактуальных показателях
 * @property {Object[]} linkingTable Список связей объектов КИИ с процессами
 * @property {Object} linkedList Запись с информацией о перечне под категорирование
 * @property {Date} linkedListPrintDate Дата печати перечня под категорирование
 * @param {Object} subjectInfo Информация об организации
 * @param {Object[]} objectsCategorization Информация об объектах категорирования
 * @param {Object[]} loadedActs Список актов категорирования для данного процесса категорирования
 * @param {Boolean} hideStageList Информация о скрытии списка этапов категорирования
 * @property {Object} protectionRecord Запись с информацией о лице по умолчанию, реализующем защиту ЗОКИИ
 * @property {Object} protectionPerson Лицо по умолчанию, реализующее защиту ЗОКИИ
 * @property {Object} responsiblePerson Лицо, ответственное за безопасность ЗОКИИ
 * @property {Object[]} organizationNestedModels Массив вложенных таблиц этапа заполнения информации об организации
 * @property {Boolean} categorizingActsLoading Информации о загрузке актов категорирования
 */
class CategorizingCIIStore {
    allStages = null 
    stage = null
    actualStageIndex = 1
    directories = null
    activeDataModel = null
    nestedModels = []
    dataObjects = []
    resourceList = null
    processList = null
    indicatorList = []
    selectedIndicator = null
    selectedNestedValue = null
    isDataLoading = false
    project = null
    lastStageIndex = 0
    commissionID = null
    commission = null
    commissionMembers = null
    irrelevantIndicatorsValues = []
    commissionPrintDate = null
    selectedMethod = null
    processes = null
    processValues = null
    resources = null
    irrelevantIndicators = null
    linkingTable = null
    linkedList = null
    linkedListPrintDate = null
    subjectInfo = null
    objectsCategorization = null
    loadedActs = null
    hideStageList = false
    protectionRecord = null
    protectionPerson = null
    responsiblePerson = null
    organizationNestedModels = []
    categorizingActsLoading = false


    /**
    * Конструктор с указанием, что все свойства класса observable
    * @constructor
    */
    constructor(){
        makeAutoObservable(this) 
    }

    /**
     * Метод осуществляет сохранение справочной информации об этапах процесса категорирования
     * @method
     * 
     * @param {Object[]} stages Этапы процесса категорирования
     */
    setAllStages(stages) {
        this.allStages = stages
    }

    /**
     * Метод осуществляет сохранение текущего этапа процесса категорирования
     * @method
     * 
     * @param {Object} stage Текущий этап
     */
    setStage(stage) {
        this.stage = stage
    }

    /**
     * Метод осуществляет сохранение номера текущего этапа, на котором находится пользователь
     * @method
     * 
     * @param {Number} stage Номер этапа
     */
    setActualStageIndex(stage) {
        this.actualStageIndex = stage
    }

    /**
     * Метод осуществляет сохранение состояния справочников, используемых в процессе категорирования
     * @method
     * 
     * @param {Object[]} directories Справочники
     */
    setDirectories(directories) {
        this.directories = directories
    }

    /**
     * Метод осуществляет сохранение структуры таблицы, относящейся к текущему этапу категорирования
     * @method
     * 
     * @param {Object} dataModel Структура текущей таблицы
     */
    setActiveDataModel(dataModel) {
        this.activeDataModel = dataModel
    }

    /**
     * Метод осуществляет сохранение списка вложенных таблиц для редактируемой формы текущего этапа
     * @method
     * 
     * @param {Object[]} dataModels Список вложенных таблиц
     */
    setNestedModels(dataModels) {
        this.nestedModels = dataModels
    }

    /**
     * Метод осуществляет сохранение списка записей вложенной таблицы
     * @method
     * 
     * @param {Object[]} dataObjects Список записей вложенной таблицы
     */
    setDataObjects(dataObjects) {
        this.dataObjects = dataObjects
    }

    /**
     * Метод осуществляет сохранение выбранной записи вложенной таблицы
     * @method
     * 
     * @param {Object} nestedModelRow Запись вложенной таблицы
     */
    setSelectedNestedValue(nestedModelRow) {
        this.selectedNestedValue = nestedModelRow
    }

    /**
     * Метод осуществляет сохранение состояния процесса загрузки данных
     * @method
     * 
     * @param {Boolean} bool Состояние процесса загрузки данных
     */
    setIsDataLoading(bool) {
        this.isDataLoading = bool
    }

    /**
     * Метод осуществляет сохранение записи с информацией о процессе категорирования
     * @method
     * 
     * @param {Object} project Запись с информацией о процессе категорирования
     */
    setProject(project) {
        this.project = project
    }

    /**
     * Метод осуществляет сохранение номера этапа, на котором остановлен процесс категорирования
     * @method
     * 
     * @param {Number} stage Номер этапа
     */
    setLastStageIndex(stage) {
        this.lastStageIndex = stage
    }

    /**
     * Метод осуществляет сохранение записи с информацией о составе комиссии
     * @method
     * 
     * @param {Object} commissionRecord Запись с информацией о составе комиссии
     */
    setCommissionID(commissionRecord) {
        this.commissionID = commissionRecord
    }

    /**
     * Метод осуществляет сохранение информации о комиссии
     * @method
     * 
     * @param {Object} commission Информация о комиссии
     */
    setCommission(commission) {
        this.commission = commission
    }

    /**
     * Метод осуществляет сохранение списка членов комиссии
     * @method
     * 
     * @param {Object} members Список членов комиссии
     */
    setCommissionMembers(members) {
        this.commissionMembers = members
    }

     /**
     * Метод осуществляет сохранение списка неактуальных показателей, выбранных пользователем
     * @method
     * 
     * @param {Object[]} indicators Массив неактуальных показателей, выбранных пользователем
     */
    setIrrelevantIndicatorsValues(indicators) {
        this.irrelevantIndicatorsValues = indicators
    }

    /**
     * Метод осуществляет сохранение даты печати приказа о создании комиссии
     * @method
     * 
     * @param {Date} date Дата печати приказа о создании комиссии
     */
    setCommissionPrintDate(date) {
        this.commissionPrintDate = date
    }

    /**
     * Метод осуществляет сохранение записи с перечнем выбранных процессов
     * @method
     * 
     * @param {Object} method Запись с перечнем выбранных процессов
     */
    setSelectedMethod(method) {
        this.selectedMethod = method
    }

    /**
     * Метод осуществляет сохранение списка выбранных процессов
     * @method
     * 
     * @param {Object[]} processes Список выбранных процессов
     */
    setProcesses(processes) {
        this.processes = processes
    }

    /**
     * Метод осуществляет сохранение списка выбранных процессов
     * @method
     * 
     * @param {Object[]} values Список выбранных процессов
     */
    setProcessValues(values) {
        this.processValues = values
    }

    /**
     * Метод осуществляет сохранение списка выбранных ресурсов
     * @method
     * 
     * @param {Object[]} resources Список выбранных ресурсов
     */
    setResources(resources) {
        this.resources = resources
    }

    /**
     * Метод осуществляет сохранение списка связей объектов КИИ с процессами
     * @method
     * 
     * @param {Object[]} records Список связей объектов КИИ с процессами
     */
    setLinkingTable(records) {
        this.linkingTable = records
    }

    /**
     * Метод осуществляет сохранение записи с информацией о перечне под категорирование
     * @method
     * 
     * @param {Object} linkedListRecord Запись с информацией о перечне под категорирование
     */
    setLinkedList(linkedListRecord) {
        this.linkedList = linkedListRecord
    }

    /**
     * Метод осуществляет сохранение даты печати перечня под категорирование
     * @method
     * 
     * @param {Date} date Дата печати перечня под категорирование
     */
    setLinkedListPrintDate(date) {
        this.linkedListPrintDate = date
    }

    /**
     * Метод осуществляет сохранение списка ресурсов доступа
     * @method
     * 
     * @param {Object[]} resources Список ресурсов доступа
     */
    setResourceList(resources) {
        this.resourceList = resources
    }

    /**
     * Метод осуществляет сохранение списка процессов
     * @method
     * 
     * @param {Object[]} processes Список процессов
     */
    setProcessList(processes) {
        this.processList = processes
    }

    /**
     * Метод осуществляет сохранение списка показателей значимости
     * @method
     * 
     * @param {Object[]} indicators Показатели значимости
     */
    setIndicatorList(indicators) {
        this.indicatorList = indicators
    }
    
    /**
     * Метод осуществляет сохранение записи с информацией о неактуальных показателях
     * @method
     * 
     * @param {Object} indicatorsRecord Запись с информацией о неактуальных показателях
     */
    setIrrelevantIndicators(indicatorsRecord) {
        this.irrelevantIndicators = indicatorsRecord
    }
    
    /**
     * Метод осуществляет сохранение выбранного индикатора
     * @method
     * 
     * @param {Object} indicator Выбранный индикатор
     */
    setSelectedIndicator(indicator) {
        this.selectedIndicator = indicator
    }

    /**
     * Метод осуществляет сохранение информации об организации
     * @method
     * 
     * @param {Object} subjInfo Информация об организации
     */
    setSubjectInfo(subjInfo) {
        this.subjectInfo = subjInfo
    }

    /**
     * Метод осуществляет сохранение информации об объектах категорирования
     * @method
     * 
     * @param {Object[]} objectsCategorization Информация об объектах категорирования
     */
    setObjectsCategorization(objectsCategorization) {
        this.objectsCategorization = objectsCategorization
    }

    /**
     * Метод осуществляет сохранение информации о загруженных актах категорирования, относящихся к данному процессу
     * @method
     * 
     * @param {Object[]} acts Акты категорирования
     */
    setLoadedActs(acts) {
        this.loadedActs = acts
    }
    
    /**
     * Метод осуществляет сохранение информации о скрытии списка этапов категорирования
     * @method
     * 
     * @param {Boolean} value Информация о скрытии списка
     */
    setHideStageList(value) {
        this.hideStageList = value
    }

    /**
     * Метод осуществляет сохранение поля о сотруднике по умолчанию, реализующем защиту ЗОКИИ, из записи общих характеристик организации
     * (для подстановки как значения по умолчанию в акт категорирования) 
     * @method
     * 
     * @param {Object} record Информация о сотруднике
     */
    setProtectionRecord(record) {
        this.protectionRecord = record
    }

    /**
     * Метод осуществляет сохранение информации о сотруднике по умолчанию, реализующем защиту ЗОКИИ
     * @method
     * 
     * @param {Object} person Информация о сотруднике
     */
    setProtectionPerson(person) {
        this.protectionPerson = person
    }

    /**
     * Метод осуществляет сохранение информации о сотруднике, ответственном за безопасность ЗОКИИ
     * @method
     * 
     * @param {Object} person Информация о сотруднике
     */
    setResponsiblePerson(person) {
        this.responsiblePerson = person
    }

    /**
     * Метод осуществляет сохранение информации о вложенных таблицах этапа заполнения информации об организации
     * @method
     * 
     * @param {Object} models Вложенные таблицы
     */
    setOrganizationNestedModels(models) {
        this.organizationNestedModels = models
    }

    /**
     * Метод осуществляет сохранение информации о загрузке актов категорирования
     * @method
     * 
     * @param {Boolean} models Информации о загрузке актов категорирования
     */
    setCategorizingActsLoading(bool) {
        this.categorizingActsLoading = bool
    }

    /**
     * Метод осуществляет возвращение к предыдущему этапу категорирования
     * @method
     */
    goPrevStage() {
        const prevStage = this.allStages.find(stage => stage.data['id_of_stage__guide_stages_of_categ'].value === (this.actualStageIndex - 1))
        if (prevStage) {
            this.setStage(prevStage)
            this.setActualStageIndex(this.actualStageIndex - 1)
        }
    }

    /**
     * Метод осуществляет переход к следующему этапу категорирования
     * @method
     * 
     * @param {Boolean} isForcedUpdate Признак принудительного изменения номера этапа, на котором остановился процесс категорирования
     */
    goNextStage(isForcedUpdate){
        // фиксация завершения текущего этапа
        this.setAllStages(this.allStages.map(stage => 
            stage.data['id_of_stage__guide_stages_of_categ'].value === this.actualStageIndex
                ?   {...stage, status: 'finished'}
                :   stage
        ))
        // обновление номера этапа, на котором остановился процесс категорирования
        if (this.actualStageIndex >= this.lastStageIndex || isForcedUpdate) {
            this.setLastStageIndex(this.actualStageIndex + 1)
            this.updateProject('current_stage__stages_of_categorization', this.actualStageIndex + 1, false)
            this.setAllStages(this.allStages.map(stage => 
                stage.data['id_of_stage__guide_stages_of_categ'].value < this.actualStageIndex + 1
                    ?   {...stage, status: 'finished'}
                    :   {...stage, status: ''}
            ))
        }
        // переход к следующему этапу
        const nextStage = this.allStages.find(stage => stage.data['id_of_stage__guide_stages_of_categ'].value === (this.actualStageIndex + 1))
        if (nextStage) {
            this.setStage(nextStage)
            this.setActualStageIndex(this.actualStageIndex + 1)
        }
    }

    /**
     * Метод осуществляет получение записей вложенной таблицы
     * @method
     * 
     * @param {Number} count Смещение
     * @param {Object[]} filter Примененные фильтры
     * @param {Object[]} sorter Примененные сортировки
     * @param {String} id ID выбранной таблицы
     * @param {Object} field Поле выбранной таблицы
     * @param {Boolean} isCopy Признак копирования
     */
    getNestedDataObjects = (count, filter, sorter, id, field, isCopy) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        DocumentService
            .getNestedDataObjects(count, filter, sorter, id, field.rule_id)
            .then(data => {
                clearTimeout(noResponse)
                this.setNestedModels(this.nestedModels.map(dataModel => dataModel.id === field.ref_model_id && dataModel.rule_id === field.rule_id
                    ?   {...dataModel, dataObjects: data.map(item => { return {...item, status: isCopy ? 'added' : 'saved'} })}
                    :   dataModel
                ))
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
    }

    /**
     * Метод осуществляет загрузку информации об этапах категорирования
     * @method
     */
    loadStages = async () => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setAllStages([])
        }, responseTimeOut)

        try {
            const infoFilter = JSON.stringify([
                {property: 'data_model_id', value: 'guide_stages_of_categ', operator: 'eq'},
                {property: 'active', value: true, operator: 'eq'}
            ])
            const infoSorter = JSON.stringify([
                {property: 'data["id_of_stage__guide_stages_of_categ"]', desc: false},
            ])      
            // загрузка информации по этапам категорирования
            const info = await DocumentService.getAllDataObjects(50, infoFilter, infoSorter)
            clearTimeout(noResponse)
            if (info) {
                this.setAllStages(info)
                if (info.length > 0)
                    this.setStage(info[0])   
            }   
        } catch (error) {
            clearTimeout(noResponse)
            showErrorToast(error, 'fetching', '')
            this.setAllStages([])
        }
    }            

    /**
     * Метод осуществляет проверку наличия сохраненных категорирований КИИ
     * @method
     */
    checkSavedProject = async () => {
        try {
            const defaultFilter = JSON.stringify([
                {property: 'data_model_id', value: 'stages_of_categorization', operator: 'eq'},
                {property: 'active', value: true, operator: 'eq'}
            ])
            const defaultSorter = JSON.stringify([
                {property: 'created', desc: true},
            ])
            // загрузка существующих записей процессов категорирования
            const savedRecords = await DocumentService.getAllDataObjects(50, defaultFilter, defaultSorter, 'r')
            return savedRecords

        } catch (error) {
            showErrorToast(error, 'fetching', '')
        }

        return []
    }            

    /**
     * Метод осуществляет загрузку сохраненной информации о категорировании
     * @method
     */
    loadSavedProject = async (projectID) => {
        let actualStage = 0
            
        try {
            const filter = JSON.stringify([
                {property: 'data_model_id', value: 'stages_of_categorization', operator: 'eq'},
                {property: 'record_id', value: projectID, operator: 'eq'},
                {property: 'active', value: true, operator: 'eq'}
            ])
            const sorter = JSON.stringify([
                {property: 'created', desc: true},
            ])
            const savedProject = await DocumentService.getLastDataObject(filter, sorter, 'w')

            this.setProject(savedProject)

            const commissionRecord = savedProject.data['order_to_create_a_commission__stages_of_categorization'].value
            this.setCommissionID(commissionRecord)

            if (savedProject.data['date_of_print__stages_of_categorization'] && savedProject.data['date_of_print__stages_of_categorization'].value) {
                this.setCommissionPrintDate(savedProject.data['date_of_print__stages_of_categorization'].value)
            }
            const selectedMethodRecord = savedProject.data['categorization_method_for_stages__stages_of_categorization'].value
            this.setSelectedMethod(selectedMethodRecord)

            const irrelevantIndicatorsRecord = savedProject.data['irrelevant_indicators_for_stgs__stages_of_categorization'].value
            this.setIrrelevantIndicators(irrelevantIndicatorsRecord)

            const linkedListRecord = savedProject.data['conn_of_objects_process_for_stages__stages_of_categorization'].value
            this.setLinkedList(linkedListRecord)

            if (savedProject.data['date_of_print_st_6__stages_of_categorization'] && savedProject.data['date_of_print_st_6__stages_of_categorization'].value) {
                this.setLinkedListPrintDate(savedProject.data['date_of_print_st_6__stages_of_categorization'].value)
            }
            
            const companyInfo = savedProject.data['select_info_about_subject__stages_of_categorization'].value
            this.setSubjectInfo(companyInfo)
            if (companyInfo && companyInfo.values.length > 0) {
                this.loadPersons(companyInfo.values[0].id)
            }

            if (savedProject.data['current_stage__stages_of_categorization'] && savedProject.data['current_stage__stages_of_categorization'].value) {
                this.setLastStageIndex(savedProject.data['current_stage__stages_of_categorization'].value)
                actualStage = savedProject.data['current_stage__stages_of_categorization'].value
            }

            if (commissionRecord && commissionRecord.values.length > 0) {
                this.loadCommission(commissionRecord.values[0].id)
            }

            if (selectedMethodRecord && selectedMethodRecord.values.length > 0) {
                this.loadProcesses(selectedMethodRecord.values[0].id)
            }

            const resources = await this.loadResources(linkedListRecord)

            if (irrelevantIndicatorsRecord && irrelevantIndicatorsRecord.values.length > 0) {
                this.loadIrrelevantIndicators()
            }

            await this.loadCategorizingActs(savedProject, resources)

        } catch (error) {
            showErrorToast(error, 'fetching', '')
        }

        return actualStage
    }

    /**
     * Метод осуществляет загрузку сохраненных актов категорирования, относящихся к выбранному процессу категорирования
     * @method
     */
    loadCategorizingActs = async (project, resourceList) => {
        this.setCategorizingActsLoading(true)
        const foundNestedModel = project.nested_models.find(nestedModel => nestedModel.id === 'acts_of_categorization_vt')
        const projectActs = await DocumentService.getNestedDataObjects(50, [], [], project.id, foundNestedModel.rule_id)

        const allRecordID = projectActs.map(item => item.data['act_of_categorization__acts_of_categorization_vt'].value.values[0].record_id)

        const defaultFilter = JSON.stringify([
            {property: 'data_model_id', value: 'act_of_categorir', operator: 'eq'},
            {property: 'record_id', value: allRecordID, operator: 'in'},
            {property: 'active', value: true, operator: 'eq'}
        ]) 
        const defaultSorter = JSON.stringify([
            {property: 'created', desc: true},
        ])      

        const loadedActs = await DocumentService.getAllDataObjects(50, defaultFilter, defaultSorter, 'w')
        const searchedActs = loadedActs.map(item => { 
            const foundAct = projectActs.find(act => act.data['act_of_categorization__acts_of_categorization_vt'].value.values[0].record_id === item.record_id)
            return {
                record_id_vt: foundAct.record_id,
                record: item
            }
        })

        let objectsCategorizationArray = []
        let recordIDArray = []
        const resourceArray = resourceList.map(item => item.value_record_id)

        searchedActs.forEach(item => {
            const recordID = item.record.data['object__act_of_categorir'].value.values[0].record_id
            if (!recordIDArray.includes(recordID) && resourceArray.includes(recordID)) {
                recordIDArray.push(recordID)
                objectsCategorizationArray.push(item)
                item.status = 'actual'
            } else {
                item.status = 'deleted'
            }
        })

        this.setLoadedActs(searchedActs)
        this.setObjectsCategorization(objectsCategorizationArray)
        this.setCategorizingActsLoading(false)

        return objectsCategorizationArray
    }


    /**
     * Метод осуществляет обновление информации о категорировании
     * @method
     */
    updateProject = async (field, value, isReload) => {
        const project = {}
        project.data_model_id = 'stages_of_categorization'
        project.data = {}
        project.data[field] = value

        if (field !== 'current_stage__stages_of_categorization' && this.actualStageIndex < this.lastStageIndex) {
            project.data['current_stage__stages_of_categorization'] = this.actualStageIndex
        }


        const response = await DocumentService.updateDataObject(this.project.record_id, project)
        if (response) {
            if (isReload)
                await this.loadSavedProject(this.project.record_id)
            else 
                this.setProject( {...this.project, id: response.id} )
        }
    }
    
    /**
     * Метод осуществляет получение выбранного этапа категорирования
     * @method
     * 
     * @param {String} id ID выбранного этапа категорирования
     * @param {Function} setModels Метод, сохраняющий полученные вложенные таблицы
     */
    getActiveDataModel = async (id, setModels) => {
        try {
            const dataModel = await DocumentService.getOneDataModel(id)
            this.setActiveDataModel(dataModel)

            let nestedModels = dataModel.fields.slice()
                .filter(field => (field.type === 'include' || (field.type === 'reference' && field.validator_type === 'many')) && !field.hide)
                .map(field => {
                    return {
                        ...field,
                        id: field.ref_model_ids[0],
                        type: findNestedModelType(dataModel, field),
                        dataObjects: []
                    }
                }) 
            
            let requests = nestedModels.map(nestedModel => DocumentService.getOneDataModel(nestedModel.id))
            Promise.all(requests)
                .then(responses => {
                    setModels(nestedModels.map((nestedModel, index) =>  { 
                        return {
                            ...nestedModel,
                            fieldName: responses[index].entity_name,
                            fields: responses[index].fields,
                            dataObjects: []
                        }
                    }))
                })

            return dataModel

        } catch (error) {
            showErrorToast(error, 'fetching', '')
        }

        return null
    }

    /**
     * Метод осуществляет получение record_id записи
     * @method
     * 
     * @param {Object} dataObject Запись
     */
    getLinkRecordID = (dataObject) => {
        const keyFieldNames = [
            'tech_process__techn_process',
            'business_proces__business_process_2',
            'process__types_of_activitess',
            'multiple_object__an_object_vt2',
        ]
        const keyFieldName = getFieldTechName(Object.values(dataObject.data), keyFieldNames)

        if(dataObject.data[keyFieldName].value.values[0])
            return dataObject.data[keyFieldName].value.values[0].record_id
        
        return null
    }

    /**
     * Метод осуществляет получение record_id поля
     * @method
     * 
     * @param {Object} field Поле
     */
    getRecordID = (field) => {
        if(field.value.values[0])
            return field.value.values[0].record_id
        return null
    }

    /**
     * Метод осуществляет загрузку списка сохраненных неактуальных показателей
     * @method
     */
    loadIrrelevantIndicators = async () => {
        this.setIsDataLoading(true)
        
        let indicators = []
        if (this.irrelevantIndicators && this.irrelevantIndicators.values.length > 0) {
            let indicatorsModel

            const indicatorsDataObject = await DocumentService.getOneDataObject(this.irrelevantIndicators.values[0].id)
            if (indicatorsDataObject)
                indicatorsModel = indicatorsDataObject.nested_models.find(nestedModel => nestedModel.id === 'irrelevant_indicators_2')

            if (indicatorsModel && indicatorsDataObject) {
                indicators = await DocumentService.getNestedDataObjects(50, [], [], indicatorsDataObject.id, indicatorsModel.rule_id)
                const irrelevantIndicators = indicators.map(item => {
                    return {
                        ...item,
                        format: item.data['indicator__irrelevant_indicators_2'].value.format,
                        data: { 
                            ...item.data,
                            name: {value: item.data['indicator__irrelevant_indicators_2'].value.values[0].name},
                            code: {value: item.data['indicator__irrelevant_indicators_2'].value.values[0].code},
                        },
                        status: 'saved'
                    }
                })
                this.setIrrelevantIndicatorsValues(irrelevantIndicators)
            }

            this.setIsDataLoading(false)
            return true
        }

        this.setIrrelevantIndicatorsValues([])
        this.setIsDataLoading(false)

        return false
    }

    /**
     * Метод осуществляет загрузку списка показателей значимости из справочника
     * @method
     */
    loadSignificanceIndicators = async () => {
        const filter = JSON.stringify([
            {property: 'data_model_id', value: 'significance_indicators', operator: 'eq'},
            {property: 'active', value: true, operator: 'eq'}
        ])
        const sorter = []
        const indicators = await DocumentService.getAllDataObjects(50, filter, sorter)
        indicators.sort((a, b) => a.data.code.value < b.data.code.value ? -1 : 1)
        this.setIndicatorList(indicators)

        return indicators
    }

    /**
     * Метод осуществляет проверку на дублирующиеся и пустые значения поля таблицы
     * @method
     * 
     * @param {Object[]} list Массив записей выбранной таблицы
     * @param {String} field Название первого поля выбранной таблицы
     * @param {String} type Тип первого поля выбранной таблицы
     */
    checkValueErrors = (list, field, type) => {
        let values = []
        let isError = false

        if (type === 'reference') {
            const filteredList = list.filter(item => item.status !== 'deleted')
            if (!filteredList.length) {
                isError = true
            } else
                filteredList.forEach(item => {
                    if (!item.data[field].value.values.length) {
                        isError = true
                    } else {
                        const record_id = item.data[field].value.values[0].record_id
                        if (values.includes(record_id)) {
                            isError = true
                        } else {
                            values = [...values, record_id]
                        }    
                    }
                })
        } else {
            const filteredList = list.filter(item => item.status !== 'deleted')
            if (!filteredList.length) {
                isError = true
            } else
                filteredList.forEach(item => {
                    if (item.data[field].value === '' || item.data[field].value < 0) {
                        isError = true
                    } else {
                        const record_value = item.data[field].value
                        if (values.includes(record_value)) {
                            isError = true
                        } else {
                            values = [...values, record_value]
                        }    
                    }
                })
        }

        return isError
    }

    /**
     * Метод осуществляет проверку на дублирующиеся и пустые значения поля таблицы
     * @method
     * 
     * @param {Object[]} list Массив записей выбранной таблицы
     * @param {String} primaryField Название первого поля выбранной таблицы
     * @param {String} type Тип первого поля выбранной таблицы
     */
    checkNestedValueErrors = (list, primaryFieldName, isCheckEmpty) => {
        let values = []
        let isError = isCheckEmpty ? list.filter(item => item.status !== 'deleted').length === 0 : false

        const checkedList = list.map(nestedValue => {
            const checkedData = {}

            if (nestedValue.status !== 'deleted') {
                for (const [key, field] of Object.entries(nestedValue.data)) {
                    let foundError = false
                    if (!field.hide) {
                        if (field.type === 'reference') {
                            if (!field.value.values.length) {
                                foundError = true
                            } else if (key === primaryFieldName) {
                                const record_id = field.value.values[0].record_id
                                if (values.includes(record_id)) {
                                    foundError = true
                                } else {
                                    values = [...values, record_id]
                                }
                            }
                        } else {
                            if (field.value == null || field.value === '' || field.value < 0) {
                                foundError = true
                            } else if (key === primaryFieldName) {
                                const record_value = field.value
                                if (values.includes(record_value)) {
                                    foundError = true
                                } else {
                                    values = [...values, record_value]
                                }    
                            }    
                        }    
                    }

                    if (foundError)
                        isError = true

                    checkedData[key] = {
                        ...field,
                        error: foundError
                    }
                }
                return {...nestedValue, data: checkedData}
            } else {
                return nestedValue
            }            
        })

        return [isError, checkedList]
    }

    /**
     * Метод осуществляет проверку значений полей вложенной таблицы "Показатели значимости"
     * @method
     * 
     * @param {Object[]} list Массив записей выбранной таблицы
     */
    checkIndicatorErrors = (list) => {
        let isError = false
        
        const checkedList = list.map(indicator => {
            // отсутствует обоснование неактуальности
            const noIrrJustification =  !indicator.data['justification_of_irrelevance__indicatorss'].value ||
                                        indicator.data['justification_of_irrelevance__indicatorss'].value.trim() === ''

            // категория не присвоена
            const noCategory = indicator.data['category_of_sign_by_indicator__indicatorss'].value.values.length === 0

            // отсутствует обоснование значения показателя
            const noValJustification = !indicator.data['justification_of_the_indicator_val__indicatorss'].value ||
                                        indicator.data['justification_of_the_indicator_val__indicatorss'].value === ''

            // отсутствует значение показателя
            const noValue = !indicator.data['value_of_the_indicator__indicatorss'].value ||
                            indicator.data['value_of_the_indicator__indicatorss'].value === ''
            
            const foundError =  noIrrJustification && (noCategory || noValJustification || noValue)
            if (foundError)
                isError = true

            const checkedData = {}
            for (const [key, field] of Object.entries(indicator.data)) {
                checkedData[key] = {
                    ...field,
                    error: !foundError
                                ?   false
                                :   key === 'value_of_the_indicator__indicatorss'
                                        ?   noValue
                                        :   key === 'justification_of_the_indicator_val__indicatorss'
                                                ?   noValJustification
                                                :   key === 'category_of_sign_by_indicator__indicatorss'
                                                        ?   noCategory
                                                        : false
                }
            }
            
            return {...indicator, data: checkedData}
        })

        return [isError, checkedList]
    }

    /**
     * Метод осуществляет проверку значений полей вложенных таблиц "Налоги" и "Оборонзаказ"
     * @method
     * 
     * @param {String} field Название поля выбранной таблицы
     * @param {Object[]} list Массив записей выбранной таблицы
     */
    checkBudgetErrors = (list) => {
        let isError = false
        
        list.forEach(value => {
            if (value.status !== 'deleted') {
                Object.entries(value).forEach(([key, field]) =>{
                    if (key === 'data') {
                        Object.entries(field).forEach(([key, item]) =>{
                            if (item.validator_type === 'date' && (!item.value || item.value === '')) {
                                isError = true
                            }
                            if (['int', 'float'].includes(item.validator_type) &&
                                (item.value < 0 || item.value == null || item.value === '' || isNaN(item.value))) 
                            {
                                isError = true
                            }
                        })
                    }
                })
            }
        })

        return isError
    }

    /**
     * Метод осуществляет проверку значений для первичного поля вложенной таблицы
     * @method
     * 
     * @param {Object} nestedModel Вложенная таблица
     * @param {Object[]} values Значения первичного поля вложенной таблицы
     */
    checkPrimaryFieldErrors = (nestedModel, values) => {
        let isError = false
        const primaryField = nestedModel.fields.find(field => field.order === 0)
        if (primaryField) {
            isError = this.checkValueErrors(values, primaryField.tech_name, primaryField.type)
        }

        return isError
    }

    /**
     * Метод осуществляет проверку значений вложенной таблицы
     * @method
     * 
     * @param {Object} nestedModel Вложенная таблица
     */
    checkNestedModel = (nestedModel) => {
        let fieldError = false
        const primaryField = nestedModel.fields.find(field => field.order === 0)
        if (primaryField) {
            fieldError = this.checkPrimaryFieldErrors(nestedModel, nestedModel.dataObjects)
        }
        const isError = !nestedModel.hidden && fieldError

        return {...nestedModel, errors: isError }
    }

    /**
     * Метод осуществляет для текущей записи формирование запросов на получение всех записей ее включенных таблиц
     * @method
     * 
     * @param {Object[]} nestedModels Массив включенных таблиц
     * @param {String} id ID текущей записи
     */
    getNestedValueRequests = (nestedModels, id) => {
        return nestedModels.map(nestedModel => {
            let sorter = []
            const primaryField = nestedModel.fields.find(field => field.order === 0)
            if (primaryField) {
                sorter = JSON.stringify([
                    {property: `data["${primaryField.tech_name}"]`, desc: false},
                ])     
            }
            return DocumentService.getNestedDataObjects(50, [], sorter, id, nestedModel.rule_id)
        })
    }

    /**
     * Метод осуществляет загрузку списка процессов
     * @method
     * 
     * @param {String} id ID записи
     */
    loadProcesses = async (id) => {
        this.setIsDataLoading(true)
        let processes = []
        try {
            const dataModel = await DocumentService.getOneDataModel('list_of_processes')

            let nestedModels = dataModel.fields.slice()
                .filter(field => (field.type === 'include' || (field.type === 'reference' && field.validator_type === 'many')) && !field.hide)
                .map(field => {
                    return {
                        ...field,
                        id: field.ref_model_ids[0],
                        type: findNestedModelType(dataModel, field),
                        dataObjects: []
                    }
                }) 

            // загрузка списка связей объектов КИИ и процессов
            const nestedValueRequests = nestedModels.map(nestedModel => DocumentService.getNestedDataObjects(50, [], [], id, nestedModel.rule_id))
            Promise.all(nestedValueRequests)
                .then(responses => {
                    processes = nestedModels.map((nestedModel, modelIndex) =>  { 
                        return {
                            ...nestedModel,
                            dataObjects: responses[modelIndex].map((value, index) => { return {...value, status: 'saved', order: index} }).sort((a, b) => a.order - b.order)
                        }
                    })
                    this.setProcessValues(processes)

                    const processList = processes
                        .filter(nestedModel => !nestedModel.hidden)         // выбрать таблицы процессов согласно способу категорирования
                        .map(nestedModel => [...nestedModel.dataObjects])        // оставить только записи процессов
                        .flat()                                             // в виде единого плоского массива
                        .map(item => { return {...item, value_record_id: this.getLinkRecordID(item)}})  // добавить на верхний уровень record_id процесса       
                    this.setProcessList(processList)

                    return processes
                })
        } catch (error){
            showErrorToast(error, 'fetching', '') 
            processes = [ {hidden: true, dataObjects: []} ]
            this.setProcessValues(processes)
            this.setProcessList([])
        } finally {
            this.setIsDataLoading(false)
        }

        return processes
    }

    /**
     * Метод осуществляет загрузку списка ресурсов
     * @method
     * 
     * @param {Object} record Запись
     */
    loadResources = async (record) => {
        this.setIsDataLoading(true)

        try {
            // загрузка необходимых данных из БД
            const dataModel = await DocumentService.getOneDataModel('list_for_categorization_2')
            const objectNestedModel = await DocumentService.getOneDataModel('an_object_vt2')
            const linkingField = dataModel.nested_models.find(nestedModel => nestedModel.id === 'communic_wt_proces')
            const objectField = dataModel.nested_models.find(nestedModel => nestedModel.id === 'an_object_vt2')
            const actNumberField = dataModel.fields.find(item => item.tech_name === 'act_number__list_for_categorization_2')
            const objectListField = dataModel.fields.find(field => field.tech_name === 'an_object_vt__list_for_categorization_2')
            const processListField = dataModel.fields.find(field => field.tech_name === 'process_for_lists__list_for_categorization_2')
            const resourceFields = objectNestedModel.fields.concat([objectListField, processListField])
    
            if (record && record.values.length > 0) {
                const id = record.values[0].id

                const savedList = await DocumentService.getOneDataObject(id)

                // загрузка списка связей объектов КИИ и процессов
                const linkingList = await DocumentService.getNestedDataObjects(50, [], [], id, linkingField.rule_id)

                // загрузка списка объектов КИИ
                const objectList = await DocumentService.getNestedDataObjects(50, [], [], id, objectField.rule_id)
                const resourceValues = objectList.map((item, index) => { 
                    return {...item,
                            status: 'saved',
                            order: index,
                            value_record_id: this.getLinkRecordID(item),
                            links: [],
                            spheres: item.data['types_of_activity_many__an_object_vt2']
                                        ?   item.data['types_of_activity_many__an_object_vt2'].value.values.map(sphere => sphere.record_id)
                                        :   []
                    }
                })
                // занесение ID процесса в массив связей с процессами для каждого объекта КИИ
                linkingList.forEach(link => {
                    const foundObject = resourceValues.find(item => item.value_record_id === this.getRecordID(link.data['object__communic_wt_proces']))
                    if (foundObject) {
                        foundObject.links.push(this.getRecordID(link.data['process_to_communc_processes__communic_wt_proces']))
                    }
                })

                this.setLinkingTable(linkingList)
                this.setResourceList({id: 'an_object_vt2', fields: resourceFields, dataObjects: resourceValues, actNumber: savedList.data['act_number__list_for_categorization_2'], ui_options: {flags: {line_edit: true}}})

                return resourceValues
            } else {
                this.setResourceList({ fields: resourceFields, dataObjects: [], actNumber: {...actNumberField, value: ''}, id: 'an_object_vt2', ui_options: {flags: {line_edit: true}}})
                this.setLinkingTable([])
                
                return []
            }

        } catch (error){
            this.setResourceList({fields: [], dataObjects: [], actNumber: {full_name: '', alias: '', description: ''}, id: 'an_object_vt2', ui_options: {flags: {line_edit: true}}})
            this.setLinkingTable([])
            showErrorToast(error, 'fetching', '') 
            
            return []
        } finally {
            this.setIsDataLoading(false)
        }
    }

    /**
     * Метод осуществляет загрузку состава комиссии
     * @method
     * 
     * @param {String} id ID записи
     */
    loadCommission = async (id) => {
        let commission = {}
        try {
            commission = await DocumentService.getOneDataObject(id)
            const dataModel = await DocumentService.getOneDataModel('creating_a_commission')
            const memberListField = dataModel.fields.find(field => (field.type === 'include' && field.field_id === 'participants'))
            const membersNestedModel = await DocumentService.getOneDataModel('participants')

            // загрузка списка участников комиссии
            const memberList = await DocumentService.getNestedDataObjects(50, [], [], id, memberListField.rule_id)

            this.setCommission(commission)
            this.setCommissionMembers({
                ...memberListField,
                id: memberListField.ref_model_ids[0],
                type: findNestedModelType(dataModel, memberListField),
                fields: membersNestedModel.fields,
                dataObjects: memberList.map((value, index) => { return {...value, status: 'saved', order: index} }).sort((a, b) => a.order - b.order)
            })

        } catch (error){
            showErrorToast(error, 'fetching', '') 
            this.setCommissionMembers({ fields: [], dataObjects: [] })
        }
        
        return commission
    }

    /**
     * Метод осуществляет загрузку информации о сотрудниках по умолчанию, реализующих защиту и ответственных за безопасность ЗОКИИ
     * @method
     * 
     * @param {String} id ID записи
     */
    loadPersons = async (id) => {
        try {
            const info = await DocumentService.getOneDataObject(id)
            const protectionPerson = await DocumentService.getOneDataObject(info.data['funct_protects_zokii__gen_info_about_subj'].value.values[0].id)
            const responsiblePerson = await DocumentService.getOneDataObject(info.data['responsible_for_protect_zokii__gen_info_about_subj'].value.values[0].id)

            this.setProtectionRecord(info.data['funct_protects_zokii__gen_info_about_subj'].value)
            this.setProtectionPerson(protectionPerson)
            this.setResponsiblePerson(responsiblePerson)

        } catch (error){
            showErrorToast(error, 'fetching', '') 
            this.setProtectionRecord(null)
            this.setProtectionPerson(null)
            this.setResponsiblePerson(null)
        }
    }

    /**
     * Метод осуществляет проверку наличия необходимых полей у сотрудника отвечающего за защиту ЗОКИИ 
     * @method
     * 
     * @param {String} selected Название выбранного поля этапа
     * @param {Object} person Информация о сотруднике
     * @param {String} fieldName Название поля этапа
     */
    checkResponsiblePerson = (selected, person, fieldName) => {
        let isError = false
        let errorMessage = ''
        if (selected === fieldName) {
            let errorFields = []
            if (!person.data['num_phone__employees'].value) errorFields.push('"Телефон"')
            if (!person.data['email_employee__employees'].value) errorFields.push('"Электронная почта"')
            if (!person.data['employee_position__employees'].value ||
                !person.data['employee_position__employees'].value.values.length) 
                    errorFields.push('"Должность"')
            if (!person.data['employee_subdivision__employees'].value ||
                !person.data['employee_subdivision__employees'].value.values.length) 
                    errorFields.push('"Подразделение"')
            if (errorFields.length) {
                isError = true
                errorMessage = `У сотрудника "${person.data['name'].value}" не заполнены необходимые поля: ${errorFields.join(', ')}`
            }
        }

        return [isError, errorMessage]
    }

    /**
     * Метод осуществляет проверку наличия необходимых полей у сотрудника функционально защищающего ЗОКИИ 
     * @method
     * 
     * @param {String} name Название выбранного поля этапа
     * @param {Object} employee Информация о сотруднике
     */
    checkFunctionalPerson = (name, employee) => {
        let isError = false
        let errorMessage = ''
        if (name === 'data.funct_protects_zokii__gen_info_about_subj.0') {
            let errorFields = []
            if (!employee.data['employee_position__employees'].value ||
                !employee.data['employee_position__employees'].value.values.length) 
                    errorFields.push('"Должность"')
            if (errorFields.length) {
                isError = true
                errorMessage = `У сотрудника "${employee.data['name'].value}" не заполнены необходимые поля: ${errorFields.join(', ')}`
            }
        }

        return [isError, errorMessage]
    }

    /**
     * Метод осуществляет загрузку вложенных таблиц этапа заполнения информации об организации
     * @method
     * 
     * @param {Function} setIsNestedLoading Метод, сохраняющий информацию о загрузке вложенных таблиц
     * @param {Boolean} isSetActiveDataModel Признак присвоения таблице активного статуса 
     */
    loadOrganizationNestedModels = async (setIsNestedLoading, isSetActiveDataModel) => {
        let organizationNestedModels = []
        try {
            setIsNestedLoading(true)
            const dataModel = await DocumentService.getOneDataModel('gen_info_about_subj')
            
            if (isSetActiveDataModel)
                this.setActiveDataModel(dataModel)

            let nestedModels = dataModel.fields.slice()
            .filter(field => (field.type === 'include' || (field.type === 'reference' && field.validator_type === 'many')) && !field.hide)
            .map(field => {
                return {
                    ...field,
                    id: field.ref_model_ids[0],
                    type: findNestedModelType(dataModel, field),
                    dataObjects: []
                }
            }) 

            let requests = nestedModels.map(nestedModel => DocumentService.getOneDataModel(nestedModel.id))
            Promise.all(requests)
            .then(responses => {
                organizationNestedModels = nestedModels.map((nestedModel, index) =>  {
                    return {
                        ...nestedModel,
                        fieldName: responses[index].entity_name,
                        fields: responses[index].fields,
                        dataObjects: []
                    }
                })
                
                if (this.subjectInfo && this.subjectInfo.values.length > 0) {
                    const nestedValueRequests = nestedModels.map(nestedModel => DocumentService.getNestedDataObjects(50, [], [], this.subjectInfo.values[0].id, nestedModel.rule_id))
                    Promise.all(nestedValueRequests)
                    .then(responses => {
                        organizationNestedModels = organizationNestedModels.map((nestedModel, modelIndex) =>  {
                            return {
                                ...nestedModel,
                                dataObjects: responses[modelIndex].map((value, index) => { return {...value, status: 'saved', order: index} }).sort((a, b) => a.order - b.order)
                            }
                        })
                        this.setOrganizationNestedModels(organizationNestedModels)
                    })
                } else {
                    this.setOrganizationNestedModels(organizationNestedModels.map((nestedModel) =>  {
                        return {
                            ...nestedModel,
                            dataObjects: []
                        }
                    }))
                }
            })
        } catch (error){
            showErrorToast(error, 'fetching', '') 
            this.setOrganizationNestedModels([{fields: [], dataObjects: []}])
        } finally {
            this.setIsDataLoading(false)
            setIsNestedLoading(false)
        }
    }
}

export default CategorizingCIIStore