import React, { useContext } from 'react'
import CategoryItem from './CategoryItem'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'
import { Tooltip } from 'react-tooltip'

/**
 * Визуальный компонент отображает список существующих категорий
 * 
 */
const CategoryList = () => {
    const { docStore } = useContext(Context)

    return (
        <>
            <div  id='data-model-list-items' className='tw-flex tw-flex-col tw-h-[calc(100%_-_3rem)] tw-w-full tw-overflow-auto'>
                { docStore.categories
                    .slice()
                    .sort((a, b) => a.order - b.order)
                    .map((category, index) => 
                        <CategoryItem 
                            key={index}
                            item={category}
                        />
                )}
            </div>
            <Tooltip id="category-list-tooltip" place="top" style={{ zIndex: 99 }}/>
        </>
    )
}

export default observer(CategoryList)