import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { authRoutes, publicRoutes, adminRoutes } from "./routes"
import { paths } from "../../config/constsURN"
import { observer } from 'mobx-react-lite'
import ErrorPage from '../error_page/ErrorPage'


/**
 * Компонент реализует логику переходов по URL приложения
 * 
 * @param {Boolean} userIsAuth Признак наличия авторизированного пользователя
 * 
 */
const AppRouter = ({userIsAuth}) => {
    return (
        <Routes>
            { userIsAuth && 
                authRoutes.map(({path, Component}) =>
                    <Route key={path} path={path} element={<Component/>} exact/>
            )}
            { publicRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={<Component/>} exact/>
            )}
            { userIsAuth &&
                adminRoutes.map(({path, Component}) =>
                    <Route key={path} path={path} element={<Component/>} exact/>
            )}
            <Route path='*' element={userIsAuth ? <ErrorPage/> : <Navigate to={paths.MAIN_ROUTE}/>} />
        </Routes>
    )    
}

export default observer(AppRouter)