import React, { useContext, useEffect } from 'react'
import { Context } from '../../../..'
import { observer } from 'mobx-react-lite'
import Spinner from '../../../../assets/Spinner'

/**
 * Визуальный компонент отображает информацию о разрешениях сущности  
 * 
 */
const RolePermissionsInfo = () => {
    const { RolesStore } = useContext(Context)

    useEffect(() => {
        if (RolesStore.selectedUser)
            RolesStore.getUserPermissions(RolesStore.selectedUser.id)
    }, [RolesStore.selectedUser])

    if (RolesStore.isPermissionsLoading) 
        return <Spinner/>

    return (
        <div id='permissions-info' className='tw-py-1 tw-h-full tw-overflow-auto'>
            {RolesStore.permissions.map((item) =>
                <div key={item.id} className="tw-px-4 tw-py-2 sm:tw-grid tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4 tw-border-b tw-select-none hover:tw-bg-gray-100">
                    <dt className={`tw-text-sm tw-col-span-2 tw-font-medium 
                        ${(RolesStore.selectedRole || RolesStore.selectedUser) ? 'tw-text-gray-900': 'tw-text-gray-600'}`}
                    >
                        {item.name}
                    </dt>
                    {(RolesStore.selectedRole || RolesStore.selectedUser) &&
                        <div className="tw-flex tw-justify-end">
                            <dd className="tw-font-semibold tw-mt-1 tw-mr-2 tw-text-sm tw-text-gray-900 sm:tw-mt-0">
                                {item.isTrue ? 'Да' : 'Нет'}
                            </dd>
                        </div>
                    }
                </div>
            )}
        </div>
    )
}

export default observer(RolePermissionsInfo)
