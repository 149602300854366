import React from 'react'
import { ArrowUpTrayIcon } from '@heroicons/react/20/solid'
import { menuTooltipTimeOut } from '../../../../../config/constTypes'

/**
 * Визуальный компонент отображает меню для отчета
 * 
 * @param {Function} onExportClick Обработчик клика мыши на кнопке экспорта отчета
 * 
 */
const ReportExportMenu = ({onExportClick}) => {
    return (
        <div className='tw-flex tw-flex-row tw-gap-x-1'>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-300'
                onClick={onExportClick}
                disabled={true}
                data-tooltip-id="planner-table-tooltip" data-tooltip-content="Создать" data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <ArrowUpTrayIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
        </div>
    )
}

export default ReportExportMenu