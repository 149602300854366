import { Fragment, useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../..'
import { checkDataModelPermission } from '../../../../../functions/checkPermissions'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/**
 * Визуальный компонент отображает кнопку с выпадающим меню "Импорт"
 * 
 * @param {Object} dataModel Выбранная таблица
 * @param {Function} onImportFromXLSXClick Обработчик клика мыши на кнопке импорта записей из Excel
 * @param {Function} onImportFromServerClick Обработчик клика мыши на кнопке импорта записей с сервера
 * 
 */
function DataImportMenu({dataModel, onImportFromXLSXClick, onImportFromServerClick}) {
    const { userStore } = useContext(Context)
    const [isWrite, isWriteDescription] = checkDataModelPermission(dataModel, 'write') 

    return (
        <>
            <Menu as='div' className='tw-relative tw-inline-block tw-text-left'>
                <div  data-tooltip-id="import-tooltip"
                    data-tooltip-content={isWrite || !dataModel
                        ? "Импорт данных"
                        : isWriteDescription}
                >
                    <Menu.Button className={`tw-rounded-md tw-p-1.5 tw-text-white   tw-ring-gray-300 tw-shadow-md 
                            ${(!dataModel || !isWrite) ? 'tw-bg-gray-400' : 'tw-bg-gray-800 hover:tw-bg-gray-600'}`}
                    >
                        <ArrowDownTrayIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="tw-transition tw-ease-out tw-duration-100"
                    enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                    enterTo="tw-transform tw-opacity-100 tw-scale-100"
                    leave="tw-transition tw-ease-in tw-duration-75"
                    leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                    leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                >
                    <Menu.Items className="tw-absolute tw-right-0 tw-z-30 tw-mt-2 tw-w-72 tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                        <div className="tw-py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={classNames(
                                            active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                            'tw-block tw-w-full tw-px-4 tw-py-2 tw-text-sm tw-text-left hover:tw-cursor-pointer disabled:tw-text-gray-300'
                                        )}
                                        disabled={!dataModel || !userStore.checkPermission("import-template:import") || !isWrite}
                                        onClick={onImportFromXLSXClick}
                                    >
                                        Импорт данных из файла
                                    </button>
                                )}
                            </Menu.Item>
                            {/* Отсутствует на беке */}
                            {/* <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={classNames(
                                            active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                            'tw-block tw-w-full tw-px-4 tw-py-2 tw-text-sm tw-text-left hover:tw-cursor-pointer disabled:tw-text-gray-300'
                                        )}
                                        disabled={!dataModel || dataModel.entity_name !== 'Сотрудник' || !userStore.checkPermission("import-template:import")} // временное решение по ограничению импорта
                                        onClick={onImportFromServerClick}
                                    >
                                        Импорт данных с сервера домена
                                    </button>
                                )}
                            </Menu.Item> */}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
            <Tooltip id="import-tooltip" place="top"/>
        </>
    )
}

export default observer(DataImportMenu)