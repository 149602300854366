import { $notesHost } from "../http/http"
import { notesAPI } from "../config/constsURN"

/**
 * Класс реализует API для работы с сообщениями
 * @class
 */
class NotesService {
    /**
     * Отправляет запрос на получение отсортированного списка существующих сообщений
     * @static
     * @param {String} field Поле сортировки
     * @param {Boolean} desc  Направление сортировки
     * @param {String} id  id записи
     * @param {Number} offset Смещение при получении сообщений
     * @param {Object[]} filter  Массив фильтров
     * @returns {Object[], Number} Массив и общее количество сообщений
     */
    static async getNotes(field, desc, id, offset, filter) {
        const sorts = JSON.stringify([
            {property: field, desc: desc},
        ])

        const defaultFilter = ([
            {property: "target[\"id\"]", operator: "eq", value: id}
        ])
        const filters = JSON.stringify(defaultFilter.concat(filter))

        const response = await $notesHost.get(notesAPI.GET_NOTES_API, {params: {total: true, offset: offset, count: 50, filter: filters, sort: sorts }})
        const totalCount = response.headers["total-count"]
        
        return [response.data, totalCount]
    }
    
    /**
     * Отправляет запрос на получение списка всех доступных сообщений 
     * @static
     * @param {Object[]} filters  Массив фильтров
     * @param {Object[]} sortes  Массив параметров сортировки
     * @returns {Object[], Number} Массив и общее количество сообщений
     */
    static async getAllNotes(filters, sorters) {       
        let data = []
        let response
        let offset = 0
        const count = 50

        do {
            response = await $notesHost.get(notesAPI.GET_NOTES_API, {params: {offset, count, total: true, filter: filters, sort: sorters}})
            data = data.concat(response.data)
            offset += count
        } while (response?.data?.length === count)

        return data
    }
    
    /**
     * Отправляет запрос на добавление сообщения
     * @static
     * @param {String} note Сообщение
     * @param {String} id  id записи
     * @returns {Object} Метаинформация о сообщении
     */
    static async addNote(id, note) {
        const data = await $notesHost.post(notesAPI.ADD_NOTE_API, {target: {id: id}, data: {message: note}})
        return data
    }
}

export default NotesService