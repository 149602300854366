import { makeAutoObservable, runInAction } from "mobx"

/**
 * Класс реализует хранилище информации о применяемых фильтрах
 * @class
 *
 * @property {Boolean} isFiltering Применение фильтров
 * @property {Object[]} selectedFilters  Массив используемых фильтров
 * @property {Object[]} filteredFields  Массив полей, к которым применяются фильтры
 * @property {Object[]} searchFilters  Массив полей, по которым происходит поиск
 * @property {String} searchField Значения поля поиска
 */
class FilterStore {
    isFiltering = false
    selectedFilters = []
    filteredFields = []
    searchFilters = []
    searchField = ''

    /**
    * Конструктор с указанием, что все свойства класса observable
    * @constructor
    */
    constructor(){
        makeAutoObservable(this)
    }

    /**
     * Метод осуществляет получение массива полей, к которым применяются фильтры
     * @method
     *
     * @param {Object} object Объект с полями
     */
    getFilterColumnNames = (object) => {
        const fields = Object.values(object.fields)
        return fields.map((field) => ({
            name: field.tech_name,
            value: null,
            operator: 'eq',
            active: false,
          }))
    }

    /**
     * Метод осуществляет добавление фильтров
     * @method
     *
     * @param {Object} object Объект с полями
     * @param {Boolean} files Наличие поля файлов
     * @param {Boolean} author Наличие поля автора
     * @param {Boolean} subgroup Наличие поля юр. лицо
     */
    filterClick = (object, files, author, subgroup) => {
        this.selectedFilters = []
        this.filteredFields = []

        this.filteredFields  = this.getFilterColumnNames(object)
        if(files)
            this.filteredFields.push({
                name: "files",
                value: null,
                operator: 'in',
                active: false,
            })
        if(author)
            this.filteredFields.push({
                name: "modifier_id",
                value: null,
                operator: 'eq',
                active: false,
            })
        if(subgroup)
            this.filteredFields.push({
                name: "subgroup_id",
                value: null,
                operator: 'eq',
                active: false,
            })

        this.isFiltering = true
    }

    /**
     * Метод осуществляет обновление массива фильтров
     * @method
     *
     * @param {Object[]} filters Массив фильтров
     */
    setSelectedFilters(filters) {
        this.selectedFilters = this.selectedFilters.filter(
            selectedFiltersItem => !(filters[0]?.property === selectedFiltersItem.property)
        ).concat(filters)
    }

    /**
     * Метод осуществляет удаление фильтров для определенного поля
     * @method
     *
     * @param {String} fieldName Название поля
     */
    clearSelectedFilter(fieldName, ruleID){
        this.filteredFields = this.filteredFields.map(filteredField => {
            if(filteredField.name === fieldName)
                runInAction(() => {
                    filteredField.active = false
                })
            return filteredField
        })
        
        if (fieldName === 'modifier_id') {
            this.selectedFilters = this.selectedFilters.filter(selectedFiltersItem => 
                !(!selectedFiltersItem.property)
            )
        }
        else 
            this.selectedFilters = this.selectedFilters.map(selectedFiltersItem => {
                if (selectedFiltersItem.property) {
                    if (!((!selectedFiltersItem.property.includes('.presentations') && selectedFiltersItem.property.includes(fieldName)) 
                        || (selectedFiltersItem.property.includes('.presentations') && selectedFiltersItem.property.includes(ruleID))))
                    {
                        return selectedFiltersItem
                    }
                } else {
                    return selectedFiltersItem
                }
            }).filter(selectedFiltersItem => selectedFiltersItem !== undefined)
    }

    /**
     * Метод осуществляет удаление всех фильтров
     * @method
     */
    clearAllFilters(){
        this.selectedFilters = []
        this.filteredFields = this.filteredFields.map(filteredField => {
                runInAction(() => {
                    filteredField.active = false
                })
                return filteredField
            })
        this.isFiltering = false
    }

    /**
     * Метод осуществляет обновление массива полей, по которым происходит поиск
     * @method
     * 
     * @param {Object} selectedFullDataModel Выбранная таблица
     */
    updateSearchFilters (selectedFullDataModel) {
        let searchFilter = [],
        searchReplacedField = this.searchField.trim()
        searchReplacedField = searchReplacedField.replaceAll("\\", "\\\\")
        searchReplacedField = searchReplacedField.replaceAll('_', '\\_')
        searchReplacedField = searchReplacedField.replaceAll('%', '\\%')

        selectedFullDataModel?.fields?.forEach(field => {
            if (field.validator_type === 'string' || field.validator_type === 'enum' || field.validator_type === 'int'
                    || field.validator_type === 'float'
                ) {
                searchFilter.push({
                    property: 'data["' + field.tech_name + '"].value',
                    value: `%${searchReplacedField}%`,
                    operator: "like_ic"
                })
            } else if (field.validator_type === 'one') {
                searchReplacedField = searchReplacedField.replaceAll('"', '\"%\\\"%\"')
                searchReplacedField = searchReplacedField.replaceAll('[', '\"%[%\"')
                searchReplacedField = searchReplacedField.replaceAll(']', '\"%]%\"')

                const referencedModels = selectedFullDataModel.referenced_models.filter(item => item.rule_id ===  field.rule_id)
                referencedModels.forEach(model => {
                    model.format_fields.forEach(formatField => {
                        searchFilter.push({
                            property: 'data["' + model.rule_id + '"].presentations[*].data.' + formatField,
                            value: `%${searchReplacedField}%`,
                            operator: "like_ic"
                        })
                    })
                })
            }
        })

        searchFilter.push(
            {
                property: "modifier_id",
                value: `%${searchReplacedField}%`,
                operator: "like_ic"
            }
        )
        searchFilter.push(
            {
                property: "modifier.email",
                value: `%${searchReplacedField}%`,
                operator: "like_ic"
            }
        )
        const filter = [{ operator: 'or',
            filters: searchFilter
        }]

        this.setSearchFilters(filter)

        return filter
    }

    /**
     * Метод осуществляет обновление массива с фильтром для поиска по record_id записи
     * @method
     * 
     * @param {String} recordID record_id записи
     */
    updateSearchRecordID (recordID) {
        const filter = [{
            property: "record_id",
            value: `${recordID}`,
            operator: "eq"
        }]

        this.setSearchFilters(filter)

        return filter
    }

    /**
     * Метод осуществляет сохранение информации полях, к которым применены фильтры
     * @method
     *
     * @param {Object[]} fields  Массив полей, к которым применяются фильтры
     */
    setFilteredFields(fields) {
        this.filteredFields = fields
    }

     /**
     * Метод осуществляет сохранение информации о применении фильтров
     * @method
     *
     * @param {Boolean} bool Информация о применении фильтров
     */
     setIsFiltering (bool) {
        this.isFiltering = bool
    }


    /**
     * Метод осуществляет сохранение информации полях, по которым происходит поиск
     * @method
     *
     * @param {Object[]} fields Массив полей, по которым происходит поиск
     */
    setSearchFilters (fields) {
        this.searchFilters = fields
    }

    /**
     * Метод осуществляет сохранение значения поля поиска
     * @method
     *
     * @param {String} string Значения поля поиска
     */
    setSearchField (string) {
        this.searchField = string
    }
}

export default FilterStore
