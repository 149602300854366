import React, { useContext, useEffect, useState } from 'react'
import AppRouter from './components/router/AppRouter'
import HeaderContainer from './components/header/HeaderContainer'
import { observer } from 'mobx-react-lite'
import { Context, medoedImage } from '.'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Spinner from './assets/Spinner'
import ErrorBoundary from './components/error_page/ErrorBoundary'
import { useLocation, useNavigate } from 'react-router-dom'
import { paths } from './config/constsURN'

/**
 * Главный компонент приложения
 * 
 */
const AppHoneyBadger = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { userStore } = useContext(Context)
    const [isLoading, setIsLoading] = useState(true)

    document.title = 'MEDOED' + (medoedImage.name && medoedImage.name !== '' ? ' [' + medoedImage.name + ']' : '')

    useEffect( () => {
        userStore.checkScreenResolution(location.pathname, () => navigate(paths.RESOLUTION_ROUTE))

        userStore.initUserByToken(setIsLoading, () => navigate(paths.LOGIN_ROUTE))
    }, [])

    if (isLoading || userStore.isConfigurationLoading) {
        return  <>
            <Spinner/>
            {userStore.isConfigurationLoading &&
                <p className='tw-text-center tw-mt-4'>Пожалуйста, подождите. Происходит обновление конфигурации</p>
            }
        </> 
    }

    return (
        <>
            <HeaderContainer/>
            <ToastContainer className='toasts'/>
            <div id='container' className='tw-mx-8 tw-h-[calc(100vh_-_9rem)] tw-max-h-[calc(100vh_-_9rem)]'>
                <ErrorBoundary
                    fallback={
                        <div className='tw-w-full tw-mt-4 tw-text-center tw-text-xl tw-font-bold tw-text-red-600'>
                            Обнаружена внутренняя ошибка. <br/> Информация отображена в консоли браузера
                        </div>
                    }
                >
                    <AppRouter userIsAuth={userStore.isAuth}/>
                </ErrorBoundary>
            </div>
        </>
    )        
}

export default observer(AppHoneyBadger)
