import { runInAction } from "mobx"
import { fieldTypes } from "../config/constTypes"
// Функция сортирует полученные от сервера записи по заданным пользователем правилам (столбец, направление)
export function sortRecords(list, direction, column, type, data) {
    let sortedList = []

    if (list) {
        if (column === 'modifier_id' || column === 'created' || column === 'last_modified' || column === 'order') {
            runInAction(() => {
                sortedList = (list.sort((a, b) => {
                    const value1 = direction === 'up' ? b[column] : a[column]
                    const value2 = direction === 'up' ? a[column] : b[column]
                    return value1 < value2 ? -1 : 1
                }))
            })
        } else {
            if (type  === 'one' || type === 'many') {
                runInAction(() => {
                    sortedList = (list.sort((a, b) => {
                        let valueA, valueB
                        if (a.data[column].value.format.includes('{name}')) {
                            valueA = a.data[column].value.values.length ? a.data[column].value.values.map(item => item.name).join(',') : 'я'
                            valueB = b.data[column].value.values.length ? b.data[column].value.values.map(item => item.name).join(',') : 'я'
                        } else {
                            valueA = a.data[column].value.values.length ? a.data[column].value.values.map(item => item.id).join(',') : 'я'
                            valueB = b.data[column].value.values.length ? b.data[column].value.values.map(item => item.id).join(',') : 'я'
                        }
                        const value1 = direction === 'up' ? valueB : valueA
                        const value2 = direction === 'up' ? valueA : valueB
                        return value1 < value2 ? -1 : 1
                    }))  
                })              
            } else if(column === "type") {
                runInAction(() => {
                    sortedList = (list.sort((a, b) => {
                        const valueA = fieldTypes.find(type => a.validator_type === type.value).name || 'я'
                        const valueB = fieldTypes.find(type => b.validator_type === type.value).name || 'я'
                        const value1 = direction === 'up' ? valueB : valueA
                        const value2 = direction === 'up' ? valueA : valueB
                        return value1 < value2 ? -1 : 1
                    }))
                })
            } else {
                runInAction(() => {
                    sortedList = (list.sort((a, b) => {
                        const valueA = data? a.data[column].value : a[column] || 'я'
                        const valueB = data? b.data[column].value : b[column] || 'я'
                        const value1 = direction === 'up' ? valueB : valueA
                        const value2 = direction === 'up' ? valueA : valueB
                        return value1 < value2 ? -1 : 1
                    }))
                })
            }
        }    
    }

    return sortedList
}
