import React, { useContext } from 'react'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает название одного юр лица для списка существующих юр лиц
 * 
 * @param {Object} item Текущий элемент списка юр лиц 
 * 
 */
const SubCompanyItem = ({item}) => {
    const { CompanyAdminStore } = useContext(Context)
    let className = `${!item.is_active && 'tw-line-through tw-text-red-400'} tw-text-sm tw-py-2 tw-border-b hover:tw-cursor-pointer`

    if (CompanyAdminStore.selectedSubCompany && CompanyAdminStore.selectedSubCompany.id === item.id) {
        className += ` tw-text-white tw-bg-gray-500`
    } else {
        className += ` tw-text-gray-900 tw-bg-white hover:tw-bg-gray-200 hover:tw-border-gray-300`
    }
    
    const onDoubleClick = () => {
        CompanyAdminStore.setIsSubCompanyEditing(true)
        CompanyAdminStore.setSelectedUser(null)
    }

    const onItemClick = (item) => {
        if (CompanyAdminStore.selectedSubCompany?.id !== item.id) {
            CompanyAdminStore.getSubCompanyUsers(item.id)
            CompanyAdminStore.setSelectedSubCompany(item)
            CompanyAdminStore.setSelectedUser(null)
            CompanyAdminStore.setIsUserCreating(false)
            CompanyAdminStore.setIsUserEditing(false)
        }
    }
    
    return (
        <div
            id={`sub-company-item-${item.id}`}
            className={className}
            onClick={() => onItemClick(item)}
            onDoubleClick={() => onDoubleClick(item)}
        >
            <div
                className='tw-px-4 tw-truncate tw-w-full'
                >
                {item.name}
            </div>
        </div>
    )
}

export default observer(SubCompanyItem)