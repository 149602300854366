import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../../../..'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import DirectoryContainer from '../../common/panels/directory/DirectoryContainer'
import PDataStageButtons from '../stage/PDataStageButtons'
import { observer } from 'mobx-react-lite'
import DocumentService from '../../../../../services/DocumentService'
import Spinner from '../../../../../assets/Spinner'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import SelectReferencedModel from '../../common/modals/SelectReferencedModel'
import { processData } from '../../../../../functions/forms'
import FieldItem from '../../../../form_fields/FieldItem'
import { deleteNestedDataObjects, updateNestedDataObjects, addIncludedDataObjects } from '../../../../../functions/nestedModels'
import FormErrorToastPanel from '../../common/panels/toast/FormErrorToastPanel'
import { emptyReferenceValue, setReferenceValue } from '../../../../../config/constTypes'


/**
 * Визуальный компонент отображает этап создания или редактирования информации о компании-обработчике ПДн
 * 
 */
const PDataOrganizationForm = () => {   
    const {
        control,
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        clearErrors,
        setError,
        formState: { isSubmitting, errors },
    } = useForm()

    const dummyModel = {id: '0', entity_name: ''}

    const { personalDataStore, docStore, userStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [selectedDataModelID, setSelectedDataModelID] = useState(null)
    const [selectedName, setSelectedName] = useState(null)
    const [isSavedRecord, setIsSavedRecord] = useState(false)
    const [formFields, setFormFields] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [formNestedModels, setFormNestedModels] = useState(null)
    const [isRefSelectOpen, setIsRefSelectOpen] = useState(false)
    const [referencedModels, setReferencedModels] = useState([])
    const [selectedRefModel, setSelectedRefModel] = useState(dummyModel)
    const [isDataSaving, setIsDataSaving] = useState(false)
    const [hiddenCompanyFields, setHiddenCompanyFields] = useState([])
    const [isSelectedRefModelError, setIsSelectedRefModelError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [chosenDataObjectsList, setChosenDataObjectsList] = useState([])
    
    const regexEmail = new RegExp(/.+@.+\..+/i)
    const regexPhone = new RegExp(/^(\+7|7|8)[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/)
    const regexFax = new RegExp(/^(\+[0-9]{1})[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/)

    const watchIsMatchAddress = watch('data.is_matches_the_location_address', 
                                formFields && formFields.length
                                    ?   formFields.find(field => field.tech_name === 'is_matches_the_location_address')?.value
                                    :   null
    )

    const initNestedModels = async (id, isDuplicate) => {  
        setIsLoading(true)

        const nestedValueRequests = formNestedModels.map(nestedModel => id ? DocumentService.getNestedDataObjects(50, [], [], id, nestedModel.rule_id) : nestedModel)
        Promise.all(nestedValueRequests)
            .then(responses => {
                personalDataStore.setNestedModels(formNestedModels.map((nestedModel, nestedModelIndex) =>  { 
                    return {
                        ...nestedModel,
                        dataObjects: id ? responses[nestedModelIndex].map(value => { return {...value, status: isDuplicate ? 'added' : 'saved'} }) : []
                    }
                }))
            })
            .finally(() => setIsLoading(false))
    }

    const handleChangeNestedTables = (editedValues, editedNestedModel) => {
        personalDataStore.setNestedModels(personalDataStore.nestedModels.map((nestedModel) =>  { 
            if (nestedModel.rule_id === editedNestedModel.rule_id) {
                return {
                ...nestedModel,
                    dataObjects: editedValues,
                    errors: false
                }
            }
            return nestedModel
        }))
        clearErrors()
        handleRecordChange()

        if (editedNestedModel.ref_model_type === 'directory') {
            if (editedValues.some(item => !item.data['name'].value)) {
                setSelectedRefModel(editedNestedModel)
                setSelectedDataModelID(editedNestedModel.ref_model_ids[0])
                setIsSelectFormOpen(true)    
            }
        }
    }

    const handleSelectClick = (dataModelList, name, field) => {
        let fieldValue = getValues(name)
        if (fieldValue) {
            setChosenDataObjectsList([fieldValue])
        }

        if (Array.isArray(dataModelList)) {
            setSelectedName(name)
            setReferencedModels(personalDataStore.activeDataModel.referenced_models.filter(dataModel => dataModel.rule_id === field.rule_id))
            setSelectedDataModelID(null)
            setIsRefSelectOpen(true)
        } else {
            setSelectedName(name)
            setSelectedDataModelID(dataModelList)
            setIsSelectFormOpen(true)
        }
    }

    const handleClearClick = (name) => {
        setValue(name, emptyReferenceValue)
        handleRecordChange()
    }

    const handleRecordChange = () => {
        setIsSavedRecord(false)
    }

    const handleDoubleClick = (item) => {
        if (selectedName) {
            setValue(selectedName, {value: setReferenceValue(item)})
        } else {
            if (selectedRefModel) {
                personalDataStore.setNestedModels(personalDataStore.nestedModels.map((nestedModel) =>  { 
                    if (nestedModel.rule_id === selectedRefModel.rule_id) {
                        return {
                        ...nestedModel,
                            dataObjects: [...selectedRefModel.dataObjects, {...item, status: 'added'} ],
                            errors: false
                        }
                    }
                    return nestedModel
                }))
        
                setSelectedRefModel(dummyModel)
            }
        }
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
        setSelectedName(null)
        setIsSavedRecord(false)
    }

    const handleCloseClick = () => {
        if (selectedRefModel) {
            personalDataStore.setNestedModels(personalDataStore.nestedModels.map((nestedModel) =>  { 
                if (nestedModel.rule_id === selectedRefModel.rule_id) {
                    return {
                    ...nestedModel,
                        dataObjects: selectedRefModel.dataObjects.filter(item => item.data['name'].value),
                        errors: false
                    }
                }
                return nestedModel
            }))
    
            setSelectedRefModel(dummyModel)
        }
        docStore.setIsDetailView(false);
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
    }

    const handleCloseModalClick = () => {
        setIsSelectedRefModelError(false)
        setReferencedModels([])
        setSelectedRefModel(dummyModel)
        setIsRefSelectOpen(false)
    }

    const handleSelectDirectory = (item) => {
        setSelectedRefModel(item)
        setSelectedDataModelID(item.id)
    }

    const handleSubmitClick = () => {
        if(selectedRefModel.id === '0')
            setIsSelectedRefModelError(true)
        else{
            setIsSelectedRefModelError(false)
            setIsRefSelectOpen(false)
            setIsSelectFormOpen(true)
        }        
    }

    const handleBackClick = () => {
        personalDataStore.goPrevStage()
    }

    const handleSetFormFields = (fields) => {
        setFormFields(fields.map(field => {
            if (field.tech_name === 'mail_of_company') {
                return {...field, placeholder: "text@mail.ru"}
            }
            
            if (field.tech_name === 'num_phone') {
                return {...field, placeholder: "+7(999)999-99-99"}
            }

            if (field.tech_name === 'fax_of_company') {
                return {...field, placeholder: "+7(999)999-99-99"}
            }
           return field
        }))
    }

    const handleForwardClick = async (form) => {
        let error = false

        personalDataStore.nestedModels.forEach(nestedModel => {
            if (!nestedModel.ref_model_type || nestedModel.ref_model_type === 'nested') {
                nestedModel.dataObjects.forEach(value => {
                    if (value.status !== 'deleted') {
                        Object.entries(value).forEach(([key, field]) =>{
                            if (key === 'data') {
                                Object.entries(field).forEach(([key, item]) =>{
                                    if (item.value == null || item.value === ''  || item.value?.values?.length === 0) {
                                        error = true
                                        setError(`data.${nestedModel.tech_name}`, { type: 'invalid', message: ' Таблица не должны содержать пустые значения!' })
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })

        const dataObject = processData(form.data, personalDataStore.activeDataModel)

        if (dataObject.data['mail_of_company'] && !regexEmail.test(dataObject.data['mail_of_company'])) {
            error = true
            setError(`data.mail_of_company`, { type: 'invalid', message: ' Неверный формат адреса электронной почты!' })
        }

        if (dataObject.data['num_phone'] && !regexPhone.test(dataObject.data['num_phone'])) {
            error = true
            setError(`data.num_phone`, { type: 'invalid', message: ' Неверный формат номера телефона!' })
        }
        
        if (dataObject.data['fax_of_company'] && !regexFax.test(dataObject.data['fax_of_company'])) {
            error = true
            setError(`data.fax_of_company`, { type: 'invalid', message: ' Неверный формат номера факса!' })
        }

        if(!error) {
            setIsDataSaving(true)

            if (watchIsMatchAddress) {
                dataObject.data['postal_address'] = dataObject.data['location_address']
            }

            personalDataStore.nestedModels.forEach(nestedModel => {
                if (!nestedModel.ref_model_type || nestedModel.ref_model_type === 'nested') {
                    dataObject.data[nestedModel.tech_name] = {
                        upsert: updateNestedDataObjects(nestedModel),
                        remove: deleteNestedDataObjects(nestedModel),
                    }
                } else {
                    dataObject.data[nestedModel.tech_name] = {
                        upsert: addIncludedDataObjects(nestedModel),
                        remove: deleteNestedDataObjects(nestedModel),
                    }
                }
            })

            try {
                let isForcedLastStageUpdate = false
                if (!isSavedRecord) {
                    let response

                    if (personalDataStore.companyInfoID && personalDataStore.companyInfoID.values.length > 0) {
                        response = await DocumentService.updateDataObject(personalDataStore.companyInfoID.values[0].record_id, dataObject)
                        if (personalDataStore.orderPrintDate) {
                            isForcedLastStageUpdate = true
                            await personalDataStore.updateProject('date_of_print_of_appointment_orders', null, true)
                        } else {
                            personalDataStore.loadSavedProject(personalDataStore.project.record_id)
                        }
                    } else {
                        response = await DocumentService.createDataObject(dataObject)
                        await personalDataStore.updateProject('info_about_company_fld', [response.record_id], true)
                    }

                    toast.success('Данные успешно сохранены!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                }

                personalDataStore.goNextStage(isForcedLastStageUpdate)

            } catch (error) {
                showErrorToast(error, 'saving', '')
            } finally {
                setIsDataSaving(false)
            }
        }
    }
   
    const loadSavedRecord = async (id) => {
        try {
            const savedRecord = await DocumentService.getOneDataObject(id)
            const fields = Object.values(savedRecord.data)
            handleSetFormFields(fields)

            setIsEditMode(true)
            if (personalDataStore.actualStageIndex >= personalDataStore.lastStageIndex)
                setIsSavedRecord(false)
            else 
                setIsSavedRecord(true)

        } catch (error) {
            showErrorToast(error, 'fetching', '') 
            handleSetFormFields(personalDataStore.activeDataModel.fields)
        }

        return null
    }

    useEffect(() => {
        personalDataStore.setIsDataLoading(true)
        personalDataStore.getActiveDataModel('info_about_company_pdn', setFormNestedModels)
    }, [])

    useEffect(() => {
        if (personalDataStore.activeDataModel && personalDataStore.activeDataModel.id === 'info_about_company_pdn') {
            if (personalDataStore.companyInfoID && personalDataStore.companyInfoID.values.length > 0) {
                loadSavedRecord(personalDataStore.companyInfoID.values[0].id)
            } else {
                handleSetFormFields(personalDataStore.activeDataModel.fields)
                setValue('data."location_address', userStore.currentSubCompany?.registration_address)          
            }
                
            personalDataStore.setIsDataLoading(false)
        }
    }, [personalDataStore.activeDataModel])

    useEffect(() => {
        if (watchIsMatchAddress) {
            setHiddenCompanyFields(['postal_address'])
        } else {
            setHiddenCompanyFields([])
        }
    }, [watchIsMatchAddress])

    useEffect(() => {
        if (formNestedModels) {
            initNestedModels(null, false)

            if (personalDataStore.companyInfoID && personalDataStore.companyInfoID.values.length > 0) {               
                initNestedModels(personalDataStore.companyInfoID.values[0].id, false)
            } 
        }
    }, [formNestedModels])

    useEffect(() => {
        if(Object.entries(errors).length > 0 && isSubmitting === false){
            toast.error(<FormErrorToastPanel errors={errors.data} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <>
            <div className='tw-grow tw-overflow-auto tw-mt-2'>
                <form className='tw-flex tw-flex-col tw-h-full'>
                    <div className='tw-px-4 tw-py-1'>
                        { !formFields
                            ?
                                <Spinner />
                            :
                                formFields.slice().sort((a, b) => a.order - b.order).map((fieldItem, index) => {
                                    return (!fieldItem.hide && !hiddenCompanyFields.includes(fieldItem.tech_name) &&
                                        <FieldItem
                                            key={index}
                                            fieldItem={fieldItem}
                                            nestedModels={personalDataStore.nestedModels}
                                            selectedNestedValue={personalDataStore.selectedNestedValue}
                                            errors={errors}
                                            handleSelectClick={handleSelectClick}
                                            handleClearClick={handleClearClick}
                                            handleRecordChange={setIsSavedRecord}
                                            isEditMode={isEditMode}
                                            isDuplicateMode={false}
                                            isLoading={isLoading}
                                            control={control}
                                            register={register}
                                            setValue={setValue}
                                            onSelectNestedValue={e => personalDataStore.setSelectedNestedValue(e)}
                                            onNestedTableChange={handleChangeNestedTables}
                                        />
                                    )
                                })
                        }
                    </div>
                </form>
            </div>
            <PDataStageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleSubmit(handleForwardClick)}
                disabled={isDataSaving}
            />
            <DirectoryContainer 
                isOpen={isSelectFormOpen}
                selectedDataModel={selectedDataModelID}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
                chosenDataObjectsList={chosenDataObjectsList}
            />
            <SelectReferencedModel
                isVisible={isRefSelectOpen}
                dataModelList={referencedModels}
                selectedItem={selectedRefModel}
                onItemChange={handleSelectDirectory}
                onSubmit={handleSubmitClick}
                onCloseModal={handleCloseModalClick}
                isError={isSelectedRefModelError}
            />
        </>                                                             
    )
}

export default observer(PDataOrganizationForm)
