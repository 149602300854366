import { toast } from "react-toastify"
import { getFormattedDate, responseTimeOut, serviceMessageTimeOut } from "../config/constTypes"
import DocumentService from "../services/DocumentService"
import FileService from "../services/FileService"
import AuthService from "../services/AuthService"
import { showErrorToast } from "../functions/errorHandlers"
import { makeAutoObservable, toJS } from "mobx"

/**
 * Класс реализует хранилище информации о процессе обучения персонала
 * @class
 * @property {Object[]} allTopics Массив тем
 * @property {Object} topic Текущая тема
 * @property {Object[]} materialsList Массив материалов
 * @property {Object} materialsStage Текущий материал
 * @property {Boolean} isTopicsLoading Загрузка тем
 * @property {Boolean} isMaterialsLoading Загрузка материалов
 * @property {Boolean} isQuestionsLoading Загрузка вопросов
 * @property {Boolean} isTestAttemptsLoading Загрузка результатов попыток теста
 * @property {Boolean} isFileLoading Загрузка файла
 * @property {Object[]} questionsList Массив вопросов
 * @property {Object} questionsStage Текущий вопрос
 * @property {Object} testRecord Текущий тест
 * @property {Object[]} attemptsList Массив попыток
 * @property {String} columnName Столбец, по которому осуществляется сортировка
 * @property {Boolean} sortDirection Направление сортировки
 * @property {Object} viewedFile Просматриваемый файл с информацией
 * @property {String} materialType Тип материала
 * @property {Object} savedMaterialProgress Запись с прогрессом по материалу
 * @property {Object} progressRecord Запись прогресса по обучению
 * @property {Boolean} isProgressRecordsLoading Загрузка записей прогресса по обучению
 * @property {Object[]} allProgress Массив прогресса по материалам
 * @property {Boolean} isMaterialProgressUpdate Признак загрузки массива записей с прогрессом по всем темам
 * @property {Boolean} isMaterialProgressSaved Признак сохранения прогресса по просмотренному материалу
 * @property {Boolean} isTestAttemptUpdate Признак загрузки массива записей с попытками прохождения по всем тестам
 * @property {Boolean} isTestAttemptSaved Признак сохранения попытки прохождения теста
 * @property {Object} lastViewedMaterial Последний просмотренный материал
 * @property {String} searchedMaterialText Текст поиска среди названий материалов
 * @property {Object[]} companyUsers Пользователи, которым доступно обучение
 */
class EducationStore {
    allTopics = null
    allMaterials = null
    topic = null
    materialsList = []
    materialsStage = null
    isTopicsLoading = false
    isMaterialsLoading = false
    isQuestionsLoading = false
    isTestAttemptsLoading = false
    isFileLoading = true
    questionsList = []
    questionsStage = {}
    testRecord = {}
    attemptsList = []
    columnName = "created"
    sortDirection = false
    viewedFile = null
    materialType = null
    savedMaterialProgress = null
    progressRecord = null
    isProgressRecordsLoading = false
    allProgress = null
    isMaterialProgressUpdate = false
    isMaterialProgressSaved = false
    isTestAttemptUpdate = false
    isTestAttemptSaved = false
    lastViewedMaterial = null
    searchedMaterialText = ''
    companyUsers = []
    reportFilters = {
        fromDate: null,
        tillDate: null,
        isAllTopics: false,
        isPassed: false,
        selectedUser: null,
        selectedTopic: null,
        selectedMaterials: [],
        ruleID: [],
        selectedType: null
    }

    
    /**
     * Конструктор с указанием, что все свойства класса observable
     * @constructor
    */
     constructor () {
        makeAutoObservable(this) 
    }

    /**
     * Метод осуществляет сохранение информации о темах
     * @method
     * 
     * @param {Object[]} topics Массив тем
     */
    setAllTopics(topics) {
        this.allTopics = topics
    }

    /**
     * Метод осуществляет сохранение информации о материалах
     * @method
     * 
     * @param {Object[]} materials Массив материалов
     */
    setAllMaterials(materials) {
        this.allMaterials = materials
    }

    /**
     * Метод осуществляет сохранение информации о текущей теме
     * @method
     * 
     * @param {Object} topic Тема
     */
    setTopic(topic) {
        this.topic = topic
    }

    /**
     * Метод осуществляет сохранение информации о материалах
     * @method
     * 
     * @param {Object[]} list Массив материалов
     */
    setMaterialsList(list) {
        this.materialsList = list
    }

    /**
     * Метод осуществляет сохранение информации о текущем материале
     * @method
     * 
     * @param {Object} stage Материал
     */
    setMaterialsStage(stage) {
        this.materialsStage = stage
    }
    
    /**
     * Метод осуществляет сохранение информации о загрузке тем
     * @method
     * 
     * @param {Boolean} bool Загрузка
     */
    setIsTopicsLoading(bool) {
        this.isTopicsLoading = bool
    }

    /**
     * Метод осуществляет сохранение информации о загрузке материалов
     * @method
     * 
     * @param {Boolean} bool Загрузка
     */
    setIsMaterialsLoading(bool) {
        this.isMaterialsLoading = bool
    }

    /**
     * Метод осуществляет сохранение информации о загрузке вопросов
     * @method
     * 
     * @param {Boolean} bool Загрузка
     */
    setIsQuestionsLoading(bool) {
        this.isQuestionsLoading = bool
    }

    /**
     * Метод осуществляет сохранение признака загрузки файла для обучения
     * @method
     * 
     * @param {Boolean} bool Загрузка
     */
    setIsFileLoading(bool) {
        this.isFileLoading = bool
    }

    /**
     * Метод осуществляет сохранение информации о вопросах
     * @method
     * 
     * @param {Object[]} list Массив вопросов
     */
    setQuestionsList(list) {
        this.questionsList = list
    }

    /**
     * Метод осуществляет сохранение информации о текущем вопросе
     * @method
     * 
     * @param {Object} stage Вопрос
     */
    setQuestionsStage(stage) {
        this.questionsStage = stage
    }
    
    /**
     * Метод осуществляет сохранение информации о тесте
     * @method
     * 
     * @param {Object} test Тест
     */
    setTestRecord(test) {
        this.testRecord = test
    }
    
    /**
     * Метод осуществляет сохранение информации о загрузке результатов попыток теста
     * @method
     * 
     * @param {Boolean} bool Загрузка
     */
    setIsTestAttemptsLoading(bool) {
        this.isTestAttemptsLoading = bool
    }

    /**
     * Метод осуществляет сохранение информации о результатах попыток теста
     * @method
     * 
     * @param {Object[]} list Массив попыток
     */
    setAttemptsList(list) {
        this.attemptsList = list
    }

    /**
    * Метод осуществляет сохранение информации о сортировке результатов попыток теста
    * @method
    * 
    * @param {String} sortName Столбец, по которому осуществляется сортировка
    * @param {Boolean} direction Направление сортировки
    */
    setSort(sortName, direction) {
        this.columnName = sortName
        this.sortDirection = direction
    }

    /**
     * Метод осуществляет сохранение файла для обучения
     * @method
     * 
     * @param {Object} file Файл с информацией
     */
    setViewedFile(file) {
        this.viewedFile = file
    }

    /**
     * Метод осуществляет сохранение типа материала
     * @method
     * 
     * @param {String} type Тип материала
     */
    setMaterialType(type) {
        this.materialType = type
    }

    /**
     * Метод осуществляет сохранение записи с прогрессом по материалу
     * @method
     * 
     * @param {Object} progressRecord Запись с прогрессом по материалу
     */
    setSavedMaterialProgress(progressRecord) {
        this.savedMaterialProgress = progressRecord
    }

    /**
     * Метод осуществляет сохранение запись с прогрессом по материалу
     * @method
     * 
     * @param {Object} record Запись с прогрессом по материалу
     */
    setProgressRecord(record) {
        this.progressRecord = record
    }

    /**
     * Метод осуществляет сохранение загрузки записей с прогрессом по материалу
     * @method
     * 
     * @param {Boolean} isLoading Признак загрузки массива записей с прогрессом по материалу
     */
     setIsProgressRecordsLoading(isLoading) {
        this.isProgressRecordsLoading = isLoading
    }

    /**
     * Метод осуществляет сохранение текущего прогресса по всем материалам обучения
     * @method
     * 
     * @param {Object[]} progress Прогресс по всем материалам
     */
    setAllProgress(progress) {
        this.allProgress = progress
    }

    /**
     * Метод осуществляет сохранение признака загрузки записей с прогрессом по материалу
     * @method
     * 
     * @param {Boolean} bool Признак загрузки массива записей с прогрессом по материалу
     */
    setIsMaterialProgressUpdate(bool) {
        this.isMaterialProgressUpdate = bool
    }

    /**
     * Метод осуществляет сохранение признака сохранения прогресса по просмотренному материалу
     * @method
     * 
     * @param {Boolean} bool Признак сохранения прогресса по просмотренному материалу
     */
    setIsMaterialProgressSaved(bool) {
        this.isMaterialProgressSaved = bool
    }

    /**
     * Метод осуществляет сохранение признака загрузки записей с попытками прохождения по всем тестам
     * @method
     * 
     * @param {Boolean} bool Признак загрузки массива записей с попытками прохождения по всем тестам
     */
    setIsTestAttemptUpdate(bool) {
        this.isTestAttemptUpdate = bool
    }

    /**
     * Метод осуществляет сохранение признака сохранения попытки прохождения теста
     * @method
     * 
     * @param {Boolean} bool Признак сохранения попытки прохождения теста
     */
    setIsTestAttemptSaved(bool) {
        this.isTestAttemptSaved = bool
    }

    /**
     * Метод осуществляет сохранение последнего просмотренного материала или пройденного теста
     * @method
     * 
     * @param {Object} material Материал
     */
    setLastViewedMaterial(material) {
        this.lastViewedMaterial = material
    }

    /**
     * Метод осуществляет сохранение текста, введенного в строке поиска материала
     * @method
     * 
     * @param {Object} text Текст поиска
     */
    setSearchedMaterialText(text) {
        this.searchedMaterialText = text
    }

    /**
     * Метод осуществляет сохранение списка пользователей компании
     * @method
     *
     * @param {Object[]} users Список пользователей компании
     */
    setCompanyUsers(users) {
        this.companyUsers = users
    }

    /**
     * Метод получает record_id материала по обучению
     * @method
     * 
     * @param {Object} material Материал
     */
    getMaterialRecordID(material) {
        if (material.data['topic_material'].value && material.data['topic_material'].value.values.length > 0)
            return material.data['topic_material'].value.values[0].record_id

        return '0'
    }

    /**
     * Метод осуществляет загрузку информации о темах
     * @method
     * 
     * @param {Object[]} searchFilters Фильтр поиска
     */
    loadTopics = async (searchFilters) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setAllTopics([])
            this.setIsTopicsLoading(false)
        }, responseTimeOut)

        this.setIsTopicsLoading(true)
        try {
            const defaultFilter = [
                {property: 'data_model_id', value: 'topics', operator: 'eq'}, 
                {property: 'transaction_id', value: null, operator: 'eq'},
                {property: 'system_data.parent_record_id', value: null, operator: 'eq'},
                {property: 'active', value: true, operator: 'eq'},
                {property: 'system_data.deletion_mark', value: [false, null], operator: 'in'},
            ]
            const defaultSorter = JSON.stringify([
                {property: "data['name']", desc: false}
            ])    

            const filters = JSON.stringify(defaultFilter.concat(searchFilters))
            const topics = await DocumentService.getAllDataObjects(50, filters, defaultSorter)

            clearTimeout(noResponse)
            if (topics) {
                const topicsList = topics.map((item, index) => {return {...item, value: index}})
                let updatedTopicsList = []

                // загрузка списка материалов по каждой теме
                const materialRequests = topicsList.map(topic => DocumentService.getNestedDataObjects(50, [], [], topic.id, topic.data['topic_materials'].rule_id))
                Promise.all(materialRequests)
                    .then(responses => {
                        updatedTopicsList = topicsList.map((topic, index) => (
                            { ...topic, dataObjects: responses[index].map(item => ({...item, materialRecordID: this.getMaterialRecordID(item)})) }
                        ))
                        this.setAllTopics(updatedTopicsList)

                    })
                    .catch(error => {
                        console.log(error)
                        updatedTopicsList = topicsList.map(topic => (
                            { ...topic, dataObjects: [] }
                        ))
                        this.setAllTopics(updatedTopicsList)

                    })
                    .finally(() => {
                        this.setIsTopicsLoading(false)
                    })
            }  
        } catch (error) {
            clearTimeout(noResponse)
            showErrorToast(error, 'fetching', '')
            this.setAllTopics([])
            this.setIsTopicsLoading(false)
        }
    }   
    
    /**
     * Метод осуществляет загрузку информации о материалах
     * @method
     * 
     * @param {Object[]} filters Фильтр поиска
     * @param {String[]} ids Массив ID материалов
     */
    getMaterialsList = async (filters, ids) => {
        try {
            const defaultFilter = [
                {property: 'data_model_id', value: 'topic_materials', operator: 'eq'}, 
                {property: 'active', value: true, operator: 'eq'},
                {property: 'system_data.deletion_mark', value: [false, null], operator: 'in'}
            ]
            if (ids)
                defaultFilter.push({property: 'record_id', value: ids, operator: 'in'})

            const defaultSorter = JSON.stringify([
                {property: "data['name']", desc: false},
            ])
            const resultFilters = JSON.stringify(defaultFilter.concat(filters))
            const materials = await DocumentService.getAllDataObjects(50, resultFilters, defaultSorter)

            return materials

        } catch (error) {
            return []            
        }

    }

    /**
     * Метод осуществляет загрузку информации о материалах для выбранной темы
     * @method
     * 
     * @param {Object[]} searchFilters Фильтр поиска
     */
    loadMaterials = async (searchFilters) => {
        this.setIsMaterialsLoading(true)

        try {
            const materialRecords = await DocumentService.getNestedDataObjects(50, [], [], this.topic.id, this.topic.data['topic_materials'].rule_id) 
            const allRecordID = materialRecords.map(item => this.getMaterialRecordID(item)) 

            const loadedMaterials = await this.getMaterialsList(searchFilters, allRecordID)

            const materialsList = loadedMaterials.map((item, index) => (
                {
                    name: item.data.name.value,
                    record_id: item.record_id,
                    id: item.id,
                    object_status: 'saved',
                    value: index, 
                    status: this.defineMaterialStatus(item, item.data['test_link'].value.values[0]?.record_id), 
                    hidden: false,  
                    help: '',
                    material: item, 
                    testID: item.data['test_link'].value.values[0]?.id
                }
            ))

            this.setMaterialsList(materialsList)

            if  (this.lastViewedMaterial) {
                const foundMaterial = materialsList.find(material => material.record_id === this.lastViewedMaterial.materialRecordID)
                if (foundMaterial) {
                    this.setMaterialsStage(foundMaterial)
                    this.setLastViewedMaterial(null)
                }
            } else {
                const foundMaterial = materialsList.find(material => material.record_id === this.materialsStage?.record_id)
                if (foundMaterial) {
                    this.setMaterialsStage(foundMaterial)
                } else {
                    this.setMaterialsStage(null)
                }
            }

            this.setIsMaterialsLoading(false)

        } catch (error) {
            this.setIsMaterialsLoading(false)
            showErrorToast(error, 'fetching', '')
            this.setMaterialsList([])
        }
    }

    /**
     * Метод формирует признак полного просмотра информации по материалу
     * @method
     * 
     * @param {Object} progress Запись о прогрессе по материалу
     */
    checkMaterialProgress = (progress) => {
        if (progress.data['material_volume'].value && progress.data['material_volume'].value > 0 && progress.data['material_progress'].value) {
            const progressValue = Math.floor(progress.data['material_progress'].value / progress.data['material_volume'].value) * 100

            return progressValue === 100
        }

        return false
    }

    /**
     * Метод формирует статус обучения по материалу
     * @method
     * 
     * @param {Object} material Материал
     */
    defineMaterialStatus = (material) => {
        const foundMaterial = this.allProgress.find(item => item.materialRecordID === material.record_id)

        return (foundMaterial && foundMaterial.finished) ? 'finished' : 'started'
    }

    /**
     * Метод формирует статусы обучения по всем материалам выбранной темы
     * @method
     */
    updateMaterialStatus = () => {
        const updatedMaterials = this.materialsList.map(item => (
            {...item, status: this.defineMaterialStatus(item.material)}
        ))
        this.setMaterialsList(updatedMaterials)
    }

    /**
     * Метод формирует статусы обучения по всем темам
     * @method
     */
    updateTopicStatus = () => {
        const updatedTopics = this.allTopics.map(topic => {
            const topicMaterials = topic.dataObjects.map(item => item.materialRecordID)
            let foundMaterials = []
            topicMaterials.forEach(materialRecordID => {
                const foundProgress = this.allProgress.find(progress => materialRecordID === progress.materialRecordID)
                if (foundProgress)
                    foundMaterials.push(foundProgress)
            })

            return {
                ...topic,
                status: foundMaterials.length === topicMaterials.length && foundMaterials.every(item => item.finished) ? 'finished' : 'started'
            }
        })

        this.setAllTopics(updatedTopics)
        this.setIsMaterialProgressUpdate(false)
        this.setIsMaterialProgressSaved(false)
        this.setIsTestAttemptUpdate(false)
        this.setIsTestAttemptSaved(false)        
    }

    /**
     * Метод загружает весь прогресс по обучению для пользователя
     * @method
     * 
     * @param {Object[]} searchFilters Поисковые фильтры
     */
    loadAllMaterialProgress = async (searchFilters) => {
        this.setIsProgressRecordsLoading(true)
        try {
            // загрузка списка материалов по каждой теме
            const filters = [
                {property: 'data_model_id', value: 'education_progress', operator: 'eq'}, 
                {property: 'active', value: true, operator: 'eq'},
                {property: 'system_data.deletion_mark', value: [false, null], operator: 'in'}
            ]
            // console.log(searchFilters)
            const resultFilters = JSON.stringify(filters.concat(searchFilters))
            const defaultSorter = JSON.stringify([
                {property: "data['date']", desc: true},
            ])

            const loadedMaterialProgress = await DocumentService.getAllDataObjects(50, resultFilters, defaultSorter, 'w')
            const updatedMaterialProgress = loadedMaterialProgress.map(item => ( 
                {
                    ...item,
                    materialType: 'info',
                    materialRecordID: this.getMaterialRecordID(item),
                    finished: this.checkMaterialProgress(item)
                } 
            ))
            const filteredProgress = this.allProgress
                ?   this.allProgress.filter(item => item.materialType !== 'info')
                :   []

            this.setAllProgress(filteredProgress.concat(updatedMaterialProgress))
            this.setIsMaterialProgressUpdate(true)

        } catch (error){
            showErrorToast(error, 'fetching', '') 

        } finally {
            this.setIsProgressRecordsLoading(false)
            this.setIsMaterialProgressSaved(false)
        }

    }

     /**
     * Метод осуществляет загрузку информации о результатах попыток для данного теста
     * @method
     * 
     * @param {Object[]} searchFilters Поисковые фильтры
     */
    loadAllTestAttempts = async (searchFilters) => {
        this.setIsTestAttemptsLoading(true)
        try {
            const defaultFilter = [
                {property: 'data_model_id', value: "test_results", operator: 'eq'}, 
                {property: 'active', value: true, operator: 'eq'},
                {property: 'system_data.deletion_mark', value: [false, null], operator: 'in'}
            ]
            const resultFilters = JSON.stringify(defaultFilter.concat(searchFilters))
            const defaultSorter = JSON.stringify([
                {property: 'created', desc: true},
            ])
            
            const loadedTestAttempts = await DocumentService.getAllDataObjects(50, resultFilters, defaultSorter, 'w')
            const updatedAttemptsList = loadedTestAttempts.map(item => ( 
                {
                    ...item,
                    materialType: 'test',
                    materialRecordID: this.getMaterialRecordID(item),
                    finished: item.data['is_test_passed'].value
                } 
            ))
            const filteredProgress = this.allProgress
                ?   this.allProgress.filter(item => item.materialType !== 'test')
                :   []

            this.setAllProgress(filteredProgress.concat(updatedAttemptsList))
            this.setIsTestAttemptUpdate(true)

        } catch (error) {
            showErrorToast(error, 'fetching', '')

        } finally {
            this.setIsTestAttemptsLoading(false)
            this.setIsTestAttemptSaved(false)
        }

    }

     /**
     * Метод осуществляет загрузку информации о результатах попыток для данного теста
     * @method
     * 
     * @param {String} sortName Столбец, по которому осуществляется сортировка
     * @param {Boolean} direction Направление сортировки
     * @param {Number} userID ID пользователя
     */
     loadSelectedTestAttempts = async (sortName, sortDirection, userID) => {
        this.setIsTestAttemptsLoading(true)

        try {
            const resultDataModel = await DocumentService.getOneDataModel('test_results')
            const ruleID = resultDataModel && resultDataModel.fields.find(field => field.tech_name === 'test_link')?.rule_id
            const testRecord = await DocumentService.getOneDataObject(this.materialsStage.testID)
            this.setTestRecord(testRecord)

            this.setSort(sortName, sortDirection)
            const defaultFilter = JSON.stringify([
                {property: 'data_model_id', value: "test_results", operator: 'eq'}, 
                {property: 'author_id', value: String(userID), operator: 'eq'},
                {property: 'active', value: true, operator: 'eq'},
                {property: 'system_data.deletion_mark', value: [false, null], operator: 'in'},
                {property: 'data["' + ruleID + '"].presentations[*].record_id',
                            value: `%${testRecord.record_id}%`,
                            operator: "like_ic"}
            ]) 
            const defaultSorter = JSON.stringify([
                {property: sortName, desc: sortDirection},
            ])
            
            const attemptsList = await DocumentService.getAllDataObjects(50, defaultFilter, defaultSorter)
            this.setAttemptsList(attemptsList)
            this.setIsTestAttemptsLoading(false)

        } catch (error) {
            this.setIsTestAttemptsLoading(false)
            showErrorToast(error, 'fetching', '')
            this.setTestRecord({})
        }
    }

    /**
     * Метод осуществляет загрузку информации о вопросах теста
     * @method
     */
    loadQuestions = async () => {
        this.setIsQuestionsLoading(true)

        try {
            const questionsRecords = await DocumentService.getNestedDataObjects(50, [], [], this.testRecord.id, this.testRecord.data['test_questions'].rule_id)
            const allQuestionsID = questionsRecords.map(item => item.data['test_question'].value.values[0].record_id)
            const questionsOrder = questionsRecords.map(item => (
                {
                    record_id: item.data['test_question'].value.values[0].record_id,
                    order: item.data['question_number'].value || item.data['question_number'].default
                }
            ))

            const defaultFilter = JSON.stringify([
                {property: 'data_model_id', value: 'questions', operator: 'eq'},
                {property: 'record_id', value: allQuestionsID, operator: 'in'},
                {property: 'active', value: true, operator: 'eq'},
                {property: 'system_data.deletion_mark', value: [false, null], operator: 'in'}
            ]) 
            const loadedQuestions = await DocumentService.getAllDataObjects(50, defaultFilter, [])

            const loadedQuestionsOptions = loadedQuestions.map(item => DocumentService.getNestedDataObjects(50, [], [], item.id, item.data['answer_options'].rule_id)) 
            Promise.all(loadedQuestionsOptions)
            .then(responses => {
                const updatedList = loadedQuestions.map((item, index) => {
                    const foundQuestion = questionsOrder.find(question => question.record_id === item.record_id)
                    return {
                        name: item.data.name.value,
                        record_id: item.record_id,
                        id: item.id,
                        object_status: 'saved',
                        status: 'empty',
                        hidden: false,
                        help: '',
                        question: item,
                        options: responses[index],
                        order: foundQuestion ? foundQuestion.order : 1
                    }
                })
                const orderedList = updatedList.sort((a, b) => a.order - b.order).map((item, index) => ({...item, value: index}))
                
                this.setQuestionsList(orderedList)
                this.setQuestionsStage(orderedList[0])
                this.setIsQuestionsLoading(false)
            })
        } catch (error) {
            this.setIsQuestionsLoading(false)
            showErrorToast(error, 'fetching', '')
            this.setQuestionsList([])
        }
    }

    /**
     * Метод осуществляет обработку ответов на вопросы теста 
     * (подсчет количества правильных ответов + формирование массива ответов для записи)
     * @method
     * 
     * @param {Object[]} questionsList Массив вопросов теста + ответы
     */
    processAnswers = (questionsList) => {
        let answers = [],
            correctAnswers = 0

        questionsList.forEach(item => { 
            let testResult = '',
                isCorrect = false,
                isAnswered = false,
                correctAnswerPoints = 0,
                wrongAnswerPoints = 0,
                multiSelectResult = 0
            
            if (item.question.data['is_multiple_select'].value) {
                let totalCorrectAnswers = 0,
                    totalWrongAnswers = 0

                item.options.forEach(option => {
                    if (option.data['is_correct'].value) {
                        totalCorrectAnswers++
                    } else {
                        totalWrongAnswers++
                    }
                })

                if (totalCorrectAnswers !== 0) {
                    correctAnswerPoints = 100/totalCorrectAnswers
                }
                
                if (totalWrongAnswers !== 0) {
                    wrongAnswerPoints = 100/totalWrongAnswers
                }
            }

            item.options.forEach(option => {
                if (option.isAnswer) {
                    isAnswered = true
                    isCorrect = option.data['is_correct'].value 
                    
                    testResult = `${option.data['answer_option'].value} - ${isCorrect ? "Верно" : "Не верно" }`

                    if (item.question.data['is_multiple_select'].value) {
                        if (isCorrect) {
                            multiSelectResult += correctAnswerPoints
                        } else {
                            multiSelectResult -= wrongAnswerPoints
                        }
                    } else {
                        if (isCorrect) {
                            correctAnswers++
                        }                        
                    }
                }
            })

            if (item.question.data['is_multiple_select'].value && (multiSelectResult > 50)) {
                correctAnswers++
                isCorrect = true
            }

            answers.push({
                data: {
                    'test_question': [item.question.record_id], 
                    'test_result': isAnswered ? testResult : "Нет ответа",
                    'is_answer_correct': isCorrect
                }
            })
        })

        return [answers, correctAnswers]
    }

    /**
     * Метод осуществляет сохранение информации о результатах попытки прохождения теста
     * @method
     * 
     * @param {Object} dataObject Информация о результатах попытки прохождения теста
     * @param {String} testRecordID ID теста
     * @param {Number} userID ID пользователя
     */
    saveAnswers = async (dataObject, userID) => {
        try {
            this.setIsQuestionsLoading(true)
            await DocumentService.createDataObject(dataObject)
            
            toast.success('Результат тестирования успешно сохранен!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            this.setQuestionsList([])
            this.setIsQuestionsLoading(false)
            this.setIsTestAttemptSaved(true)
            this.loadSelectedTestAttempts('created', true, userID)

            return true

        } catch (error) {
            this.setIsQuestionsLoading(false)
            showErrorToast(error, 'saving', '')
            return false
        }
    }

    /**
     * Метод осуществляет загрузку информации о прогрессе по выбранному материалу
     * @method
     * 
     * @param {String} sortName Столбец, по которому осуществляется сортировка
     * @param {Boolean} direction Направление сортировки
     * @param {Number} userID ID пользователя
     */
    getMaterialProgress = (sortName, sortDirection, userID) => {
        this.setIsProgressRecordsLoading(true)
        this.setSort(sortName, sortDirection)

        const filters = JSON.stringify([
            {property: 'data_model_id', value: "education_progress", operator: 'eq'},
            {property: 'author_id', value: String(userID), operator: 'eq'},
            {property: 'active', value: true, operator: 'eq'},
            {property: 'system_data.deletion_mark', value: [false, null], operator: 'in'}
        ])
        const sorter = JSON.stringify([
            {property: sortName, desc: sortDirection},
        ])
        DocumentService.getAllDataObjects(50, filters, sorter, 'w')
            .then(data => {
                const savedRecordsList = data
                    .map(item => { return {...item, materialRecordID: this.getMaterialRecordID(item)} })
                    .filter(item => item.materialRecordID === this.materialsStage.record_id)
                const savedRecord = savedRecordsList[0] || null

                if (savedRecord)
                    this.setProgressRecord({...savedRecord, materialType: 'info'})
                else
                    this.setProgressRecord(null)

                this.setSavedMaterialProgress(savedRecord)
                this.setIsProgressRecordsLoading(false)
            })
            .catch(error => {
                showErrorToast(error, 'fetching', '')
                this.setProgressRecord(null)
                this.setSavedMaterialProgress(null)
                this.setIsProgressRecordsLoading(false)
            })
    }

    /**
     * Метод осуществляет сохранение информации о прогрессе по выбранному материалу
     * @method
     * 
     * @param {Number} progress Величина прогресса (страница - для документа, секунда - для видео)
     * @param {Number} totalSize Общий объем материала (страниц - для документа, секунд - для видео)
     */
    saveMaterialProgress = async (progress, totalSize) => {
        try {
            const dataObject = {}
            dataObject.data_model_id = "education_progress"
            dataObject.data = {}
            dataObject.data["topic_material"] = [this.materialsStage.record_id]
            dataObject.data["material_progress"] = progress
            dataObject.data["material_volume"] = totalSize
            dataObject.data["date"] = Date.now()

            if (this.savedMaterialProgress) {
                await DocumentService.updateDataObject(this.savedMaterialProgress.record_id, dataObject)
            } else {
                await DocumentService.createDataObject(dataObject)
            }

            this.setIsMaterialProgressSaved(true)
        } catch (error) {
            showErrorToast(error, 'saving', '')
        }

    }

    /**
     * Метод осуществляет подсчет процентов прогресса по выбранному материалу
     * @method
     * 
     * @param {Object} materialProgress Запись результата повышения осведомленности (просмотра, теста)
     */
    calculateProgress = (materialProgress) => {
        if (materialProgress.materialType === 'info') {
            if (materialProgress.data['material_volume'] && materialProgress.data['material_volume'].value) {
                let progress = (materialProgress.data.material_progress.value / materialProgress.data.material_volume.value) * 100
                if (progress > 100)
                    progress = 100
                return progress.toFixed(0)
            } else {
                return null
            }
        }
        if (materialProgress.materialType === 'test') {
            if (materialProgress.data['test_result'] && materialProgress.data['test_result'].value) {
                const values = materialProgress.data['test_result'].value.split(' ')
                let progress = materialProgress.data['is_test_passed'].value
                                    ?   100
                                    :   values.length > 3 && values[1] !== values[3]
                                            ?   (values[1] / values[3]) * 100
                                            :   0
                if (progress > 100)
                    progress = 100

                return progress.toFixed(0)
            } else {
                return null
            }
        }
    }

    /**
     * Метод осуществляет сохранение ответов на вопросы теста 
     * @method
     * 
     * @param {Object[]} questionsList Массив вопросов теста + ответы
     * @param {Number} userID ID пользователя
     */
    async saveTestResults (questionsList, userID) {
        const testLink = this.materialsStage.material.data['test_link'].value.values[0].record_id 

        const [result, correctAnswers] = this.processAnswers(questionsList)

        const dataObject = {}
        dataObject.data_model_id = "test_results"
        dataObject.data = {}
        dataObject.data.date = Date.now()
        dataObject.data['test_link'] = [testLink]
        dataObject.data['test_result'] = `Верных ${correctAnswers} из ${questionsList.length}`
        dataObject.data['is_test_passed'] = (questionsList.length === correctAnswers)
        dataObject.data['topic_material'] = [this.materialsStage.record_id]       
        dataObject.data['questions_results'] = { upsert: result }

        const isSaved = await this.saveAnswers(dataObject, userID)

        return isSaved
    }

    /**
     * Метод осуществляет загрузку с сервера файла, прилагаемого к изучаемому материалу
     * @method
     * 
     * @param {Object} file Информация о файле
     */
    getMaterialFile = async (file) => {
        try {
            this.setMaterialType(file.metadata.extension)
            const blob = await FileService.downloadFile(file.id)
            const url = window.URL.createObjectURL(new Blob([blob]))
            this.setViewedFile(url)
            this.setIsFileLoading(false)
        } catch (error) {
            showErrorToast(error, 'saving', '')
        }
    }

    /**
     * Метод осуществляет загрузку с сервера файла, прилагаемого к изучаемому материалу
     * @method
     * 
     * @param {Object} file Информация о файле
     */
    getMaterialLink = (link) => {
        this.setMaterialType('stream')
        this.setViewedFile(link)
        this.setIsFileLoading(false)
    }

    /**
     * Метод осуществляет формирование имени пользователя компании
     * @method
     * 
     * @param {Object} user Пользователь компании
     */
    formatUserName(user) {
        let name =  (user.last_name && user.last_name !== '' ? user.last_name + ' ': '') +
                    (user.first_name && user.first_name !== '' ? user.first_name: '')
        if (name === '')
            name = 'Анонимный пользователь (email: ' + user.email + ')'

        return name
    }

    /**
     * Метод осуществляет получение списка пользователей компании
     * @method
     * 
     */
    async getCompanyUsers() {
        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        try {
            const data = await AuthService.getMyCompanyUsers()
            clearTimeout(noResponse)
            const users = data.data.map(user => ({...user, name: this.formatUserName(user)}))
            users.sort((a, b) => a.name - b.name)
            this.setCompanyUsers(users)
            return users
        
        } catch (error) {
            clearTimeout(noResponse)
            showErrorToast(error, 'fetching', '')
            return []
        }
    }


    /**
     * Метод сохраняет данные фильтров отчета по Обучению
     * @method
     * 
     * @param {String} type Тип фильтра
     * @param {Any} filters Данные фильтра
     * @param {Boolean} isField Признак указания поля
     */
    setReportFilters(type, filters, isField) {
        if (isField)
            this.reportFilters[type] = filters
        else
            this.reportFilters = filters
    }
    
    /**
     * Метод осуществляет формирование фильтров для формирования отчета по повышению осведомленности
     * @method
     * 
     */
    checkReportFilters() {
        let reportFilters = []

        if (this.reportFilters.selectedUser && this.reportFilters.selectedUser.id > 0)
            // reportFilters.push({property: 'author.email', value: this.reportFilters.selectedUser.email, operator: 'eq'})
            reportFilters.push({property: 'author_id', value: String(this.reportFilters.selectedUser.id), operator: 'eq'})

        if (this.reportFilters.selectedType === 'total' && this.reportFilters.selectedMaterials.length > 0 && this.reportFilters.ruleID.length > 0)
            reportFilters.push({
                operator: 'or',
                filters: this.reportFilters.ruleID.map(ruleID => (
                    {property: 'data["' + ruleID + '"].presentations[*].record_id', value: this.reportFilters.selectedMaterials, operator: 'any_in'}
                ))
            })

        if (this.reportFilters.fromDate) {
            const fromDate = new Date(this.reportFilters.fromDate)
            reportFilters.push({property: 'data["date"].value', value: fromDate.getTime(), operator: 'ge'})
        }
        if (this.reportFilters.tillDate) {
            const tillDate = new Date(this.reportFilters.tillDate)
            reportFilters.push({property: 'data["date"].value', value: tillDate.getTime(), operator: 'le'})
        }
        
        return reportFilters
    }

    /**
     * Метод осуществляет получение record_id для ссылочного поля
     * @method
     * 
     * @param {Object} field Поле
     */
    getRecordID = (field) => {
        if (field.value && field.value.values && field.value.values[0])
            return field.value.values[0].record_id
        else
            return null
    }

    /**
     * Метод осуществляет формирование списка прогресса по материалам обучения
     * @method
     * 
     */
    formatMaterialProgressList() {
        const summaryList = []

        this.allProgress.forEach(progress => {
            const foundUser = this.companyUsers.find(user => String(user.id) === progress.author_id)            
            const foundTopics = !this.reportFilters.selectedTopic || this.reportFilters.selectedTopic === '0'
                                    ?   this.allTopics.filter(topic => topic.dataObjects.some(material => material.materialRecordID === progress.materialRecordID))
                                    :   this.allTopics.filter(topic => topic.id === this.reportFilters.selectedTopic)
            const foundMaterial = this.allMaterials.find(material => material.record_id === progress.materialRecordID)
    
            if (foundUser && foundTopics.length && foundMaterial) {
                const userName = this.formatUserName(foundUser)
                const topicNames = foundTopics.map(topic => topic.data['name'].value).join(', ')
                const materialName = foundMaterial.data['name'].value
                const materialType = foundMaterial.data['material_type'].value
                const materialDate = getFormattedDate(progress.data['date'].value, 'dd.MM.yyyy HH:mm')
                const materialProgress = this.calculateProgress(progress)
                if (!this.reportFilters.isPassed || materialProgress === '100')
                    summaryList.push({
                            id: progress.id,
                            data: {
                                user_name: {value: userName, tech_name: 'user_name', validator_type: 'string'},
                                topic_name: {value: topicNames, tech_name: 'topic_name', validator_type: 'string', id: foundTopics[0].id},
                                material_name: {value: materialName, tech_name: 'material_name', validator_type: 'string', id: foundMaterial.id},
                                material_type: {value: materialType, tech_name: 'material_type', validator_type: 'string'},
                                material_date: {value: materialDate, tech_name: 'material_date', validator_type: 'string'},
                                material_percent: {value: materialProgress, tech_name: 'material_percent', validator_type: 'int'},
                            }
                    })
            }
        })
        summaryList
            .sort((a, b) => a.data['material_name'].value.toLowerCase() < b.data['material_name'].value.toLowerCase() ? -1 : 1)
            .sort((a, b) => a.data['topic_name'].value.toLowerCase() < b.data['topic_name'].value.toLowerCase() ? -1 : 1)
            .sort((a, b) => a.data['user_name'].value.toLowerCase() < b.data['user_name'].value.toLowerCase() ? -1 : 1)

        return summaryList
    } 

    /**
     * Метод осуществляет получение значения rule_id материала обучения
     * @method
     * 
     */
    async getMaterialRuleID() {
        try {
            let materialRuleID = []
            const progressDataModel = await DocumentService.getOneDataModel('education_progress')
            const progressRuleID = progressDataModel && progressDataModel.fields.find(field => field.tech_name === 'topic_material')?.rule_id
            if (progressRuleID)
                materialRuleID.push(progressRuleID)
            const testDataModel = await DocumentService.getOneDataModel('test_results')
            const testRuleID = testDataModel && testDataModel.fields.find(field => field.tech_name === 'topic_material')?.rule_id
            if (testRuleID)
                materialRuleID.push(testRuleID)

            this.setReportFilters('ruleID', materialRuleID, true)
            // return materialRuleID

        } catch (error) {
            this.setReportFilters('ruleID', [], true)
            // return []
        }
    }

    /**
     * Метод осуществляет формирование списка посещаемости обучения по пользователям
     * @method
     * 
     */
    formatAllUserMaterialsSummary() {
        const summaryList = this.companyUsers.map(user => {
            const userName = this.formatUserName(user)
            const userMaterials = this.allProgress.filter(progress => progress.author_id === String(user.id))
            const doneMaterials = userMaterials.filter(progress => this.calculateProgress(progress) === '100' && progress.materialRecordID !== '0')

            return {
                id: user.id,
                data: {
                    responsible_user: {value: userName, tech_name: 'responsible_user', validator_type: 'string'},
                    responsible_user_email: {value: user.email, tech_name: 'responsible_user_email', validator_type: 'string'},
                    opened_materials: {value: userMaterials.length, tech_name: 'opened_materials', validator_type: 'int'},
                    passed_materials: {value: doneMaterials.length, tech_name: 'passed_materials', validator_type: 'int'},
                }
            }
        }).sort((a, b) => a.data.responsible_user.value.toLowerCase() < b.data.responsible_user.value.toLowerCase() ? -1 : 1)

        return summaryList
    } 


}

export default EducationStore