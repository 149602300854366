//Текст политики конфиденциальности
export const policyText = `
<br/>
<p className="tw-text-right tw-text-sm">Редакция от «02» февраля 2024 г.</p>
<p className="tw-text-center"><strong>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</strong></p>
<p className="tw-text-center"><strong>ООО «Медоед»</strong></p>
<p className="tw-text-center">(ИНН 7720922026, ОГРН 1247700065910)<strong></strong></p>
<br/>
<br/>
<strong>1. ПОНЯТИЯ И ТЕРМИНЫ, ИСПОЛЬЗУЕМЫЕ В ПОЛИТИКЕ</strong>
<br/>
<br/>
<table className="tw-border-t tw-border-l tw-border-gray-500">
<tbody>
<tr className="tw-border-b tw-border-gray-500">
<td className="tw-border-r tw-border-gray-500 tw-p-2"><strong>1.1. Персональные данные</strong>&nbsp;(далее – ПДн)</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (Субъекту персональных данных), 
являющаяся конфиденциальной информацией ограниченного доступа, не составляющей государственную тайну</td>
</tr>
<tr className="tw-border-b tw-border-gray-500">
<td className="tw-border-r tw-border-gray-500 tw-p-2"><strong>1.2. Субъект персональных данных</strong>&nbsp;(далее – Субъект)</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">физическое лицо, носитель персональных данных, чьи персональные данные переданы Обществу для обработки</td>
</tr>
<tr className="tw-border-b tw-border-gray-500">
<td className="tw-border-r tw-border-gray-500 tw-p-2"><strong>1.3. Оператор персональных данных</strong>&nbsp;</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">ООО «Медоед» (111524, город Москва, Электродная ул, д. 2 стр. 34, помещ. 5/1, ИНН 7720922026, ОГРН 1247700065910)</td>
</tr>
<tr className="tw-border-b tw-border-gray-500">
<td className="tw-border-r tw-border-gray-500 tw-p-2"><strong>1.4. Обработка персональных данных</strong>&nbsp;</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или 
без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение 
(обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление, 
уничтожение персональных данных</td>
</tr>
<tr className="tw-border-b tw-border-gray-500">
<td className="tw-border-r tw-border-gray-500 tw-p-2"><strong>1.5. Сайт</strong>&nbsp;</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">сайт Оператора, который находится в сети Интернет по адресу:&nbsp;
<a className="tw-font-semibold tw-text-sky-700" href="https://medoed-soft.ru/">https://medoed-soft.ru/</a></td>
</tr>
<tr className="tw-border-b tw-border-gray-500">
<td className="tw-border-r tw-border-gray-500 tw-p-2"><strong>1.6. Сервис Medoed</strong></td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Сервис Medoed, который находится в сети Интернет по адресу: <a className="tw-font-semibold tw-text-sky-700" href="https://medoed.rtmtech.ru/">https://medoed.rtmtech.ru/</a></td>
</tr>
<tr className="tw-border-b tw-border-gray-500">
<td className="tw-border-r tw-border-gray-500 tw-p-2"><strong>1.7. Пользователь</strong>&nbsp;</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">физическое лицо, действующее в своих интересах или в интересах других лиц, акцептовавшее Пользовательское соглашение, размещенное на Сайте, 
имеющее доступ к Сайту и использующее его, независимо от факта регистрации на Сайте</td>
</tr>
</tbody>
</table>
<br/>
<p><strong>2. ОБЩИЕ ПОЛОЖЕНИЯ</strong></p>
<br/>
<p><strong>2.1.     Назначение</strong></p>
<p>Политика определяет действия Оператора:</p>
<p>2.1.1.&nbsp;&nbsp; в отношении обработки персональных данных физических лиц, передавших свои персональные данные для обработки;</p>
<p>2.1.2.&nbsp;&nbsp; порядок и условия осуществления обработки персональных данных;</p>
<p>2.1.3.&nbsp;&nbsp; обеспечение безопасности персональных данных с использованием и без использования средств автоматизации;</p>
<p>2.1.4.&nbsp;&nbsp; устанавливает процедуры, направленные на предотвращение нарушений законодательства Российской Федерации;</p>
<p>2.1.5.&nbsp;&nbsp; устранение последствий нарушений, связанных с обработкой персональных данных.</p>
<br/>
<p>2.2.&nbsp;&nbsp;&nbsp; Действие Политики не распространяется на отношения, возникающие при обработке персональных данных сотрудников Оператора, соискателей вакантных должностей Общества.</p>
<br/>
<p><strong>2.3. Область применения</strong></p>
<p>Политика применяется в частности, но не ограничиваясь:</p>
<p>2.3.1.&nbsp;&nbsp; при навигации на сайте&nbsp;<a className="tw-font-semibold tw-text-sky-700" href="https://medoed-soft.ru/">https://medoed-soft.ru/</a> без использования функционала модулей сервиса Medoed;</p>
<p>2.3.2.&nbsp;&nbsp; при пользовании сервисами, предложенными на&nbsp;сайте сервиса Medoed <a className="tw-font-semibold tw-text-sky-700" href="https://medoed.rtmtech.ru/">https://medoed.rtmtech.ru/</a> ;</p>
<p>2.3.3.&nbsp;&nbsp; при ином использовании Сайта и сервиса Medoed в соответствии с Лицензионным соглашением.</p>
<br/>
<p><strong>2.4. Нормативно-правовая основа</strong></p>
<p>Политика разработана и применяется в ООО «Медоед» (далее – Общество, Оператор) на основании:</p>
<p>2.4.1.&nbsp;&nbsp; Статей 23, 24 Конституции Российской Федерации;</p>
<p>2.4.2.&nbsp;&nbsp; Федерального закона от 27.07.2006 №149-ФЗ «Об информации, информационных технологиях и о защите информации»;</p>
<p>2.4.3.&nbsp;&nbsp; Федерального закона от 27.07.2006 №152-ФЗ «О персональных данных» (далее – ФЗ);</p>
<p>2.4.4.&nbsp;&nbsp; Постановления Правительства Российской Федерации от 01 ноября 2012г. №1119 «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных»;</p>
<p>2.4.5.&nbsp;&nbsp; Приказа Федеральной службы по техническому и экспортному контролю от 18 февраля 2013 г. №21 «Об утверждении состава и содержания организационных мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных» и иными нормативными и ненормативными правовыми актами, регулирующими вопросы обработки персональных данных.</p>
<br/>
<p><strong>2.5. Правовое основание обработки ПДн</strong></p>
<p>2.5.1.&nbsp;&nbsp; Гражданский кодекс РФ (гл.39 ГК РФ);</p>
<p>2.5.2.&nbsp;&nbsp; Устав Общества;</p>
<p>2.5.3.&nbsp;&nbsp; Лицензионное соглашение, размещенное на Сайте;</p>
<p>2.5.4.&nbsp;&nbsp; Согласие Субъекта на обработку ПДн.</p>
<br/>
<p><strong>2.6. Порядок пересмотра</strong></p>
<p>2.6.1.&nbsp;&nbsp; Актуализация Политики осуществляется на плановой и внеплановой основе:</p>
<p className="tw-ml-10">&#8212; плановая актуализация Политики должна осуществляться не реже одного раза в год;</p>
<p className="tw-ml-10">&#8212; внеплановая актуализация Политики может производиться по результатам контрольных мероприятий по выполнению требований законодательства РФ о ПДн, а также при изменении законодательства РФ о ПДн.</p>
<br/>
<p><strong>3. ЦЕЛИ ОБРАБОТКИ ПДн</strong></p>
<br/>
<p>Общество руководствуется конкретными, заранее определенными целями обработки ПДн, в соответствии с которыми ПДн были предоставлены Субъектом.</p>
<br/>
<table className="tw-border-t tw-border-l tw-border-gray-500">
<tbody>
<tr className="tw-border-b tw-border-gray-500">
<td className="tw-border-r tw-border-gray-500 tw-p-2"><strong>Цель обработки</strong></td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Использование функционала сервиса Medoed</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Обеспечение Пользователю возможности взаимодействовать с Сайтом и сервисом Medoed</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Обращение в службу поддержки</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Направление информационных и рекламных рассылок</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования сайта, оказания услуг, обработка запросов и заявок от Пользователя</td>
</tr>
<tr className="tw-border-b tw-border-gray-500">
<td className="tw-border-r tw-border-gray-500 tw-p-2"><strong>Категория ПДн</strong></td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Общая</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Общая</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Общая</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Общая</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Общая</td>
</tr>
<tr className="tw-border-b tw-border-gray-500">
<td className="tw-border-r tw-border-gray-500 tw-p-2"><strong>Перечень ПДн</strong></td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">&#8212; Фамилия и Имя;<br>&#8212; Адрес электронной почты;<br>&#8212; Полное наименование организации;<br>&#8212; Логин в сервисе Medoed</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">&#8212;&nbsp; Сведения об используемом браузере;<br>&#8212; Местоположение;<br>&#8212; IP-адрес;<br>&#8212; Запрашиваемые Интернет-страницы;<br>&#8212; Источник захода на Сайт&nbsp;<a className="tw-font-semibold tw-text-sky-700" href="https://medoed-soft.ru/">https://medoed-soft.ru/</a></td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">&#8212; Фамилия, Имя, Отчество;<br>&#8212; Адрес электронной почты;<br>&#8212; Номер телефона</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">&#8212; Фамилия и Имя;<br>&#8212; Адрес электронной почты;<br>&#8212; Полное наименование организации;<br>&#8212; Логин в сервисе Medoed</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">&#8212; Фамилия, Имя, Отчество;<br>&#8212; Адрес электронной почты;<br>&#8212; Номер телефона;<br>&#8212; Название организации;<br>&#8212; Должность</td>
</tr>
<tr className="tw-border-b tw-border-gray-500">
<td className="tw-border-r tw-border-gray-500 tw-p-2"><strong>Категория субъектов</strong></td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Физическое лицо, чьи ПДн поступили Оператору в связи с использованием функционала сервиса Medoed</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Физическое лицо — Пользователь сайта</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Физическое лицо — Пользователь сайта</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Физическое лицо — Пользователь сайта</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Физическое лицо — Пользователь сайта</td>
</tr>
<tr className="tw-border-b tw-border-gray-500">
<td className="tw-border-r tw-border-gray-500 tw-p-2"><strong>Способы обработки и хранения</strong></td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Автоматизированный</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Автоматизированный</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Автоматизированный</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Автоматизированный</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Автоматизированный</td>
</tr>
<tr className="tw-border-b tw-border-gray-500">
<td className="tw-border-r tw-border-gray-500 tw-p-2"><strong>Сроки обработки и хранения</strong></td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Достижение или утрата необходимости достижения Оператором цели обработки</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Достижение или утрата необходимости достижения Оператором цели обработки</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Достижение или утрата необходимости достижения Оператором цели обработки</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Достижение или утрата необходимости достижения Оператором цели обработки</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Достижение или утрата необходимости достижения Оператором цели обработки</td>
</tr>
<tr className="tw-border-b tw-border-gray-500">
<td className="tw-border-r tw-border-gray-500 tw-p-2"><strong>Порядок уничтожения</strong></td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Путем стирания без возможности восстановления</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Путем стирания без возможности восстановления</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Путем стирания без возможности восстановления</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Путем стирания без возможности восстановления</td>
<td className="tw-border-r tw-border-gray-500 tw-p-2">Путем стирания без возможности восстановления</td>
</tr>
</tbody>
</table>
<br/>
<p><strong>4. ОСНОВНЫЕ ПРАВА И ОБЯЗАННОСТИ СУБЪЕКТА ПДн&nbsp; И ОПЕРАТОРА</strong></p>
<br/>
<p><strong>4.1.&nbsp;&nbsp; Субъект имеет право:</strong></p>
<p>4.1.1.&nbsp;&nbsp; Получить информацию, касающуюся обработки его ПДн, в частности:</p>
<p>4.1.1.1.&nbsp;&nbsp; подтверждение факта обработки ПДн Оператором;</p>
<p>4.1.1.2.&nbsp;&nbsp; правовые основания и цели обработки ПДн;</p>
<p>4.1.1.3.&nbsp;&nbsp; применяемые Оператором способы обработки ПДн;</p>
<p>4.1.1.4.&nbsp;&nbsp; наименование и местонахождение Оператора, сведения о лицах (за исключением сотрудников Оператора), которые имеют доступ к ПДн или которым могут быть раскрыты ПДн на основании договора с Оператором или на основании федерального закона;</p>
<p>4.1.1.5.&nbsp;&nbsp; обрабатываемые ПДн, относящиеся к соответствующему Субъекту ПДн, источник их получения, если иной порядок предоставления не предусмотрен федеральным законом;</p>
<p>4.1.1.6.&nbsp;&nbsp; срок обработки ПДн и срок их хранения;</p>
<p>4.1.1.7.&nbsp;&nbsp; порядок осуществления Субъектом ПДн своих прав;</p>
<p>4.1.1.8.&nbsp;&nbsp; наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку ПДн по поручению Оператора, если обработка поручена или будет поручена такому лицу;</p>
<p>4.1.1.9.&nbsp;&nbsp; о способах исполнения Оператором обязанностей, предусмотренных законодательством;</p>
<p>4.1.1.10.&nbsp;&nbsp; иные сведения, предусмотренные федеральным законом.</p>
<p>4.1.2.&nbsp;&nbsp; Требовать от Оператора уточнения его ПДн, их блокирования или уничтожения в случае, если ПДн являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;</p>
<p>4.1.3.&nbsp;&nbsp; Требовать от Оператора извещения всех лиц, которым ранее были сообщены неверные или неполные ПДн, обо всех произведенных в них исключениях, исправлениях и дополнениях;</p>
<p>4.1.4.&nbsp;&nbsp; Отозвать свое согласие на обработку ПДн;</p>
<p>4.1.5.&nbsp;&nbsp; На свободный безвозмездный доступ к своим ПДн посредством личного обращения либо направления запроса;</p>
<p>4.1.6.&nbsp;&nbsp; Обжаловать действия или бездействие Оператора в уполномоченный орган по защите прав Субъектов ПДн или в судебном порядке.</p>
<p>4.1.7.&nbsp;&nbsp; Иные права, предусмотренные действующим законодательством.</p>
<br/>
<p><strong>4.2.&nbsp;&nbsp; Оператор обязан:</strong></p>
<p>4.2.1.&nbsp;&nbsp; Обеспечивать конфиденциальность ПДн. Оператор и иные лица, получившие доступ к&nbsp; ПДн, обязаны не раскрывать третьим лицам и не распространять ПДн без согласия Субъекта ПДн, если иное не предусмотрено законом;</p>
<p>4.2.2.&nbsp;&nbsp; Опубликовать или иным образом обеспечить неограниченный доступ к документу, определяющему его политику в отношении обработки ПДн, сведениям о реализуемых требованиях к защите ПДн;</p>
<p>4.2.3.&nbsp;&nbsp; Принимать необходимые правовые, организационные и технические меры или обеспечивать их принятие для защиты ПДн от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения ПДн, а также от иных неправомерных действий в отношении ПДн;</p>
<p>4.2.4.&nbsp;&nbsp; Предоставлять ответы на запросы и обращения Субъектов ПДн, их представителей и уполномоченного органа по защите прав субъектов персональных данных;</p>
<p>4.2.5.&nbsp;&nbsp; Иные обязанности Оператора, предусмотренные законодательством.</p>
<br/>
<p><strong>5. ПОРЯДОК И УСЛОВИЯ ОБРАБОТКИ ПДн</strong></p>
<br/>
<p>5.1.&nbsp;&nbsp; Оператор осуществляет обработку ПДн Субъектов посредством совершения любого действия (операции) или совокупности действий (операций), совершаемых с использованием средств автоматизации, включая следующие:</p>
<p className="tw-ml-10">&#8212; сбор;<br>&#8212; запись;<br>&#8212; систематизацию;<br>&#8212; накопление;<br>&#8212; хранение;<br>&#8212; уточнение (обновление, изменение);<br>&#8212; извлечение;<br>&#8212; использование;<br>&#8212; передачу (предоставление);<br>&#8212; обезличивание;<br>&#8212; блокирование;<br>&#8212; удаление;<br>&#8212; уничтожение.</p>
<p>5.2.&nbsp;&nbsp; Оператор получает ПДн путем личной передачи Субъектом ПДн при внесении данных на Сайте.</p>
<p>5.3.1.&nbsp;&nbsp; Оператор получает и начинает обработку ПДн Субъекта с момента получения его согласия.</p>
<p>5.3.2.&nbsp;&nbsp; Согласие на обработку ПДн может быть дано Субъектом в любой форме, позволяющей подтвердить факт получения согласия, если иное не установлено федеральным законом: в письменной, электронной или иной форме, предусмотренной действующим законодательством.</p>
<p>5.3.3.&nbsp;&nbsp; В частности, согласие на обработку ПДн считается предоставленным Субъектом посредством проставления специального знака — «галочки» или «веб-метки» в специальном поле на Сайте Оператора.</p>
<p>5.4.&nbsp;&nbsp; Получение Оператором ПДн от иных лиц, а равно передача поручения по обработке ПДн осуществляется на основании договора, содержащего условия о порядке обработки и сохранения конфиденциальности полученных ПДн.</p>
<p>5.5.&nbsp;&nbsp; Оператор до начала обработки ПДн назначает ответственного за организацию обработки ПДн, который получает указания непосредственно от исполнительного органа Оператора и подотчетен ему.</p>
<p>5.6.&nbsp;&nbsp; Перечень лиц, допущенных к обработке ПДН определяется распоряжением Исполнительного органа и внутренними локальным нормативными актами Общества.</p>
<p>5.7.&nbsp;&nbsp; В случае если Оператор поручает обработку ПДн третьим лицам, не являющимся его сотрудниками, на основании заключенных договоров (либо иных оснований), в силу которых они должны иметь доступ к ПДн пользователей Сайта, соответствующие данные предоставляются Оператором только после подписания с лицами, осуществляющими обработку ПДн по поручению Оператора, соответствующего соглашения, в котором должны быть определены:</p>
<p className="tw-ml-10">&#8212; перечень действий (операций) с ПДн, которые будут совершаться лицом, осуществляющим их обработку;</p>
<p className="tw-ml-10">&#8212; цели обработки;</p>
<p className="tw-ml-10">&#8212; должна быть установлена обязанность такого лица соблюдать конфиденциальность ПДн и обеспечивать безопасность ПДн при их обработке;</p>
<p className="tw-ml-10">&#8212; должны быть указаны требования к защите обрабатываемых ПДн в соответствии со ст. 19 ФЗ «О персональных данных».</p>
<p>5.8.&nbsp;&nbsp; Обработка и хранение ПДн осуществляются не дольше, чем этого требуют цели обработки ПДн, если отсутствуют законные основания для дальнейшей обработки, например, если федеральным законом или согласием Субъекта, договором с Субъектом ПДн не установлен соответствующий срок хранения.</p>
<p>Обрабатываемые ПДн подлежат уничтожению по достижении целей обработки или в случае утраты необходимости в достижении этих целей.</p>
<p>5.9.&nbsp;&nbsp; Сотрудник Оператора, имеющий доступ к ПДн в связи с исполнением трудовых обязанностей обеспечивает хранение информации, содержащей ПДн Субъектов, исключающее доступ к ним третьих лиц.</p>
<p>5.10.&nbsp;&nbsp; Оператор не осуществляет действий, направленных на раскрытие ПДн неопределенному кругу лиц, т.е. не осуществляет распространение ПДн.</p>
<br/>
<p><strong>6. ПРЕКРАЩЕНИЕ ОБРАБОТКИ, УТОЧНЕНИЕ, УНИЧТОЖЕНИЕ ПДн</strong></p>
<br/>
<p><strong>6.1.&nbsp;&nbsp; Прекращение обработки</strong></p>
<p>Оператор прекращает обработку ПДн в случае:</p>
<p>6.1.1.&nbsp;&nbsp; Достижения цели обработки ПДн &#8212; в течение тридцати дней, если иное не предусмотрено договором;</p>
<p>6.1.2.&nbsp;&nbsp; Истечения срока действия согласия Субъекта ПДн &#8212; в течение тридцати дней;</p>
<p>6.1.3.&nbsp;&nbsp; Выявления неправомерной обработки ПДН – в течение трех дней с даты выявления;</p>
<p>6.1.4.&nbsp;&nbsp; Невозможности обеспечения правомерности обработки персональных данных &#8212; в течение десяти рабочих дней;</p>
<p>6.1.5.&nbsp;&nbsp; Отзыва согласия Субъекта ПДн, если сохранение ПДн более не требуется для целей обработки ПДн &#8212; в течение 30 дней;</p>
<p>6.1.6.&nbsp;&nbsp; Предоставления пользователем сведений, подтверждающих, что ПДн являются незаконно полученными или не являются необходимыми для заявленной цели обработки &#8212; в течение 7 рабочих дней со дня представления таких сведений.</p>
<br/>
<p><strong>6.2.&nbsp;&nbsp; Отзыв согласия на обработку ПДн</strong></p>
<p>6.2.1.&nbsp;&nbsp; Субъект ПДН может в любой момент отозвать свое согласие на обработку ПДН при условии, что подобная процедура не нарушает требований законодательства РФ. В случае отзыва Субъектом согласия на обработку ПДН, Оператор вправе продолжить обработку ПДН без согласия Субъекта только при наличии оснований, указанных в ФЗ.</p>
<p>6.2.2.&nbsp;&nbsp; Для отзыва согласия на обработку ПДН необходимо подать соответствующее заявление в письменной форме по месту нахождения Оператора.</p>
<p>6.2.3.&nbsp;&nbsp; В случае отзыва Субъектом согласия на обработку его ПДн, Оператор прекращает их обработку или обеспечивает прекращение такой обработки (если обработка осуществляется другим лицом, действующим по поручению Оператора) и в случае, если сохранение ПДн более не требуется для целей их обработки, уничтожает ПДн или обеспечивает их уничтожение (если обработка осуществляется другим лицом, действующим по поручению Оператора) в срок, не превышающий 30 (Тридцати) дней с даты поступления указанного отзыва, если иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому является Субъект ПДн, иным соглашением между Оператором и Субъектом, либо если Оператор не вправе осуществлять обработку ПДн без согласия Субъекта на основаниях, предусмотренных ФЗ или другими федеральными законами.</p>
<br/>
<p><strong>6.3.&nbsp;&nbsp; Уточнение ПДн</strong></p>
<p>6.3.1.&nbsp;&nbsp; В случае выявления неправомерной обработки ПДн при обращении Субъекта или его представителя либо по запросу Субъекта или его представителя либо уполномоченного органа по защите прав субъектов персональных данных Оператор обязан осуществить блокирование неправомерно обрабатываемых ПДн, относящихся к этому Субъекту, или обеспечить их блокирование (если обработка ПДн осуществляется другим лицом, действующим по поручению оператора) с момента такого обращения или получения указанного запроса на период проверки.</p>
<p>6.3.2.&nbsp;&nbsp; В случае выявления неточных ПДн при обращении Субъекта&nbsp; или его представителя либо по их запросу или по запросу уполномоченного органа по защите прав субъектов персональных данных Оператор обязан осуществить блокирование ПДн, относящихся к этому Субъекту, или обеспечить их блокирование (если обработка ПДн осуществляется другим лицом, действующим по поручению оператора) с момента такого обращения или получения указанного запроса на период проверки, если блокирование ПДн не нарушает права и законные интересы Субъекта или третьих лиц.</p>
<p>6.3.3.&nbsp;&nbsp; В случае подтверждения факта неточности ПДн Оператор на основании сведений, представленных Субъектом или его представителем либо уполномоченным органом по защите прав субъектов персональных данных, или иных необходимых документов обязан уточнить ПДн либо обеспечить их уточнение (если обработка ПДн осуществляется другим лицом, действующим по поручению Оператора) в течение семи рабочих дней со дня представления таких сведений и снять блокирование ПДн.</p>
<p>6.3.4.&nbsp;&nbsp; В случае выявления неправомерной обработки ПДн, осуществляемой Оператором или лицом, действующим по поручению Оператора, Оператор в срок, не превышающий трех рабочих дней с даты этого выявления, обязан прекратить неправомерную обработку ПДн или обеспечить прекращение неправомерной обработки ПДн лицом, действующим по поручению Оператора.</p>
<p>В случае, если обеспечить правомерность обработки ПДн невозможно, Оператор в срок, не превышающий десяти рабочих дней с даты выявления неправомерной обработки ПДн, обязан уничтожить такие персональные данные или обеспечить их уничтожение. Об устранении допущенных нарушений или об уничтожении ПДн Оператор обязан уведомить Субъекта&nbsp; или его представителя, а в случае, если обращение Субъекта&nbsp; или его представителя либо запрос уполномоченного органа по защите прав субъектов персональных данных были направлены уполномоченным органом по защите прав субъектов персональных данных, также указанный орган.</p>
<br/>
<p><strong>6.4.&nbsp;&nbsp; Уничтожение ПДн</strong></p>
<p>6.4.1.&nbsp;&nbsp; При достижении целей обработки персональных данных, а также в случае отзыва субъектом персональных данных согласия на их обработку персональные данные подлежат уничтожению, если:</p>
<p>6.4.1.1.&nbsp;&nbsp; иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных;</p>
<p>6.4.1.2.&nbsp;&nbsp; оператор не вправе осуществлять обработку без согласия субъекта персональных данных на основаниях, предусмотренных Законом о персональных данных или иными федеральными законами;</p>
<p>6.4.1.3.&nbsp;&nbsp; иное не предусмотрено другим соглашением между Оператором и субъектом персональных данных.</p>
<br/>
<p><strong>7. ОБЕСПЕЧЕНИЕ БЕЗОПАСНОСТИ ПДн</strong></p>
<br/>
<p>7.1.&nbsp;&nbsp; При обработке ПДн Оператор применяет правовые, организационные и технические меры и обеспечивает их принятие для защиты ПДн от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения ПДн, а также от иных неправомерных действий в соответствии с требованиями к обеспечению безопасности ПДн при их обработке в информационных системах ПДн, требованиям к материальным носителям ПДн и технологиям хранения таких данных вне информационных систем ПДн, установленными Правительством РФ.</p>
<p>7.2.&nbsp;&nbsp; Мероприятия по защите ПДн определяются Положениями, Приказами, Инструкциями и другими локальными актами Оператора.</p>
<p>7.3.&nbsp;&nbsp; Оператор принимает меры, необходимые и достаточные для обеспечения выполнения обязанностей, предусмотренных законами РФ и принятыми в соответствии с ними нормативно-правовыми актами.</p>
<p>7.4.&nbsp;&nbsp; Оператор самостоятельно определяет состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Федеральным законом «О персональных данных» и принятыми в соответствии с ним нормативно-правовыми актами, если иное не предусмотрено указанным законом иди другими федеральными законами.</p>
<p>7.5.&nbsp;&nbsp; Обеспечение безопасности ПДн достигается в частности:</p>
<p>7.5.1.&nbsp;&nbsp; Назначением ответственного лица за организацию обработки ПДН;</p>
<p>7.5.2.&nbsp;&nbsp; Определением угроз безопасности ПДн при их обработке в информационных системах ПДН;</p>
<p>7.5.3.&nbsp;&nbsp; Применение организационных и технических мер по обеспечению безопасности ПДн;</p>
<p>7.5.4.&nbsp;&nbsp; Оценкой эффективности принимаемых мер по обеспечению безопасности ПДн;</p>
<p>7.5.5.&nbsp;&nbsp; Обнаружением фактов несанкционированного доступа к ПДН и принятием необходимых мер;</p>
<p>7.5.6.&nbsp;&nbsp; Восстановлением ПДн, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</p>
<p>7.5.7.&nbsp;&nbsp; Установлением правил доступа к ПДн, обрабатываемых в информационных системах ПДн;</p>
<p>7.5.8.&nbsp;&nbsp; Контролем над принимаемыми мерами по обеспечению безопасности ПДн и уровня защищенности информационных систем ПДн;</p>
<p>7.5.9.&nbsp;&nbsp; Оценкой вреда, который может быть причинен Субъектам ПДн в случае нарушения ФЗ, соотношение указанного вреда и принимаемых Оператором мер, направленных на обеспечение выполнения обязанностей, предусмотренных ФЗ;</p>
<p>7.5.10.&nbsp;&nbsp; Издание Оператором локальных актов по вопросам обработки ПДн, а также локальных актов, устанавливающих процедуры, направленные на предотвращение и выявление нарушений законодательства РФ, устранения последствий таких нарушений;</p>
<p>7.5.11.&nbsp;&nbsp; Ознакомление сотрудников Оператора, непосредственно осуществляющих обработку ПДн, с положениями законодательства РФ, настоящей Политикой и другими локальными актами по вопросам обработки ПДн, и (или) обучением (инструктаж) указанных сотрудников.</p>
<br/>
<p><strong>8. ПОРЯДОК ОБРАБОТКИ ЗАПРОСОВ И ОБРАЩЕНИЙ СУБЪЕКТОВ ПДн</strong></p>
<br/>
<p>8.1.&nbsp;&nbsp; Подтверждение факта обработки персональных данных Оператором, правовые основания и цели обработки персональных данных, а также иные сведения, указанные в ч. 7 ст. 14 Закона о персональных данных, предоставляются Оператором субъекту персональных данных или его представителю при обращении либо при получении запроса субъекта персональных данных или его представителя.</p>
<p>В предоставляемые сведения не включаются персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных.</p>
<p>8.2.1.&nbsp;&nbsp; Запрос должен содержать:</p>
<p className="tw-ml-10">&#8212; фамилию, имя, отчество субъекта персональных данных, контактные данные, адрес электронной почты;</p>
<p className="tw-ml-10">&#8212; сведения, подтверждающие участие субъекта персональных данных в отношениях с Оператором (номер договора, дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом подтверждающие факт обработки персональных данных Оператором;</p>
<p className="tw-ml-10">&#8212; подпись субъекта персональных данных или его представителя.</p>
<p>8.2.2.&nbsp;&nbsp; Запрос может быть направлен:</p>
<p className="tw-ml-10">&#8212; в письменной форме на адрес Оператора: 111524, город Москва, Электродная ул, д. 2 стр. 34, помещ. 5/1;</p>
<p className="tw-ml-10">&#8212; в электронной форме на адрес электронной почты Оператора: <a className="tw-font-semibold tw-text-sky-700" href="mailto:support.medoed@rtmtech.ru">support.medoed@rtmtech.ru</a>.</p>
<p>Если в обращении (запросе) субъекта персональных данных не отражены в соответствии с требованиями Закона о персональных данных все необходимые сведения или субъект не обладает правами доступа к запрашиваемой информации, то ему направляется мотивированный отказ.</p>
<p>8.3.&nbsp;&nbsp; Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с ч. 8 ст. 14 Закона о персональных данных, в том числе если доступ субъекта персональных данных к его персональным данным нарушает права и законные интересы третьих лиц.</p>
<p>8.4.&nbsp;&nbsp; Оператор обязуется рассмотреть и направить ответ на поступивший запрос в течение 10 (десяти) рабочих дней с момента поступления обращения.</p>
<br/>
<p><strong>9.&nbsp; ОТВЕТСТВЕННОСТЬ ЗА НАРУШЕНИЕ ИЛИ НЕИСПОЛНЕНИЕ ПОЛИТИКИ</strong></p>
<br/>
<p>9.1.&nbsp;&nbsp; Контроль исполнения настоящей Политики возложен на ответственного сотрудника по обработке ПДн.</p>
<p>9.2.&nbsp;&nbsp; Лица, нарушающие или не исполняющие требования Политики, привлекаются к дисциплинарной, административной, гражданско-правовой или уголовной ответственности в соответствии с законодательством РФ.</p>
<br/>
<p><strong>10. ПРОЧИЕ ПОЛОЖЕНИЯ</strong></p>
<br/>
<p>10.1.&nbsp;&nbsp; Политика и изменения к ней утверждаются единоличным исполнительным органом Общества, являются обязательными для исполнения всеми сотрудниками, имеющими доступ к ПДн Субъектов, и вступает в силу со дня ее утверждения.</p>
<p>10.2.&nbsp;&nbsp; Все сотрудники Общества, допущенные к работе с ПДн, должны быть ознакомлены с Политикой до начала работы с ПДн.</p>
<p>10.3.&nbsp;&nbsp; Оператор имеет право вносить изменения в Политику без согласия Субъекта.</p>
<p>10.4.&nbsp;&nbsp; Опубликование или обеспечение иным образом неограниченного доступа к настоящей Политике, иным документам, определяющим политику Оператора в отношении обработки ПДн, к сведениям о реализуемых требованиях к защите персональных данных Оператор осуществляет, в частности, но не ограничиваясь, посредством размещения на электронном сайте, принадлежащем Оператору.</p>
<p>10.5.&nbsp;&nbsp; Новая редакция Политики вступает в силу с момента опубликования.</p>
<p>10.6.&nbsp;&nbsp; Все вопросы, связанные с обработкой ПДн, не урегулированные настоящей Политикой, разрешаются в соответствии с действующими законодательством РФ в области персональных данных, а также принятыми Обществом иными локальными актами в области персональных данных.</p>
<br/>
<p><strong>11. РЕКВИЗИТЫ ОПЕРАТОРА</strong></p>
<br/>
<p><strong>ООО «Медоед»</strong></p>
<p className="tw-mt-2">Юридический адрес: 111524, город Москва, Электродная ул, д. 2 стр. 34, помещ. 5/1</p>
<p className="tw-mt-2">Фактический адрес: 111524, город Москва, Электродная ул, д. 2 стр. 34, помещ. 5/1</p>
<p className="tw-mt-2">ИНН/КПП 7720922026/772001001</p>
<p className="tw-mt-2">ОГРН 1247700065910</p>
<p className="tw-mt-2">Р/счет 40702810038000440121</p>
<p className="tw-mt-2">ПАО "Сбербанк"</p>
<p className="tw-mt-2">К/с 30101810400000000225, БИК 044525225</p>
<p className="tw-mt-2">Тел. +7 (495) 309-31-25</p>
<p className="tw-mt-2">Email: <a className="tw-font-semibold tw-text-sky-700" href="mailto:support.medoed@rtmtech.ru">support.medoed@rtmtech.ru</a></p>
<br/>
`