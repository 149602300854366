import React from 'react'

/**
 * Визуальный компонент отображает универсальный заголовок отображаемой страницы в виде текстовой строки
 * 
 * @param {String} title Заголовок
 * 
 */
const PageTitle = ({title}) => {
    return (
        <div id='page-title' className='tw-h-16 tw-w-full tw-text-xl tw-font-bold tw-px-4 tw-py-4 tw-truncate'>
            {title}
        </div>
    )
}

export default PageTitle