import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../../..'
import Spinner from '../../../../../../assets/Spinner'
import MaterialProgressFields from './MaterialProgressFields'


/**
 * Визуальный компонент отображает список прогресса по обучению
 * 
 */
const MaterialProgress = () => {
    const { EducationStore, userStore } = useContext(Context)

    useEffect(() => {
        EducationStore.getMaterialProgress("data['date']", true, userStore.user.id)
    }, [EducationStore.materialsStage])

    useEffect(() => {
        if (EducationStore.isMaterialProgressSaved) {
            EducationStore.getMaterialProgress("data['date']", true, userStore.user.id)
        }
    }, [EducationStore.isMaterialProgressSaved])

    return (
        <div className='tw-grow tw-overflow-hidden'>
            {!EducationStore.isProgressRecordsLoading
                ?   <>
                        {EducationStore.progressRecord
                            ?   <>
                                    <p className='tw-text-md tw-font-semibold tw-my-4 tw-text-gray-800'>
                                        Прогресс обучения
                                    </p>
                                    <MaterialProgressFields/>
                                </>
                            :
                                <p className='tw-text-sm tw-mt-4 tw-text-center tw-text-gray-500'>
                                    Для данного материала нет прогресса обучения
                                </p>
                        } 
                    </>
                :
                    <div className='tw-col-span-4'>
                        <Spinner />
                    </div>
            }
        </div>
    )
}

export default observer(MaterialProgress)