import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'
import DataTypeListBox from '../../common/inputs/DataTypeListBox'
import ReportPeriodPanel from '../common/ReportPeriodPanel'
import ReportActionPanel from './ReportActionPanel'
import { paths } from '../../../../../config/constsURN'


/**
 * Визуальный компонент отображает панель настроек отчета по модулю "Планировщик"
 * 
 * @param {Object[]} users Массив пользователей
 * @param {Boolean} reportType Вид отчета
 * @param {Function} onSubmitClick Обработчик клика мыши на кнопке "Применить"
 * 
 */
const ReportPlannerFilter = ({users, reportType, onSubmitClick}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { plannerStore } = useContext(Context)

    const [selectedUser, setSelectedUser] = useState(null)
    const [isNewFilter, setIsNewFilter] = useState(false)

    const isButtonDisabled = plannerStore.reportFilters.selectedType === 'actions' &&
                            !plannerStore.reportFilters.isDoneAction &&
                            !plannerStore.reportFilters.isOutdatedAction &&
                            !plannerStore.reportFilters.isPlannedAction &&
                            !plannerStore.reportFilters.isAssignedByMe

    const handleUserChange = (user) => {
        plannerStore.setReportFilters('selectedUser', user.email, true)
        setSelectedUser(user)
        setIsNewFilter(true)
    }

    const handleFromDateChange = (date) => {
        plannerStore.setReportFilters('fromDate', new Date(date).getTime(), true)
        setIsNewFilter(true)
    }

    const handleTillDateChange = (date) => {
        plannerStore.setReportFilters('tillDate', new Date(date).getTime(), true)
        setIsNewFilter(true)
    }

    const handleFromDateClear = () => {
        plannerStore.setReportFilters('fromDate', null, true)
        setIsNewFilter(true)
    }

    const handleTillDateClear = () => {
        plannerStore.setReportFilters('tillDate', null, true)
        setIsNewFilter(true)
    }

    useEffect(() => {
        if (users && users.length) {
            let foundUser
            if (plannerStore.reportFilters.selectedUser)
                foundUser = users.find(user => user.email === plannerStore.reportFilters.selectedUser)
            if (foundUser && foundUser.id > 0)
                setSelectedUser(foundUser)
            else 
                setSelectedUser(users[0])
        }
    }, [users, plannerStore.reportFilters.selectedUser])

    useEffect(() => {
        if (isNewFilter) {
            if (location.search !== '')
                navigate(paths.REPORT_PLANNER_ROUTE + '/' + reportType)

            setIsNewFilter(false)
        }
    }, [isNewFilter])

    return (
        <div className='tw-flex tw-flex-col tw-px-4 tw-overflow-auto'>
            { users &&
                <DataTypeListBox
                    label={'Ответственный'}
                    itemList={users}
                    selectedItem={selectedUser || {id: '0', name: '', email: null}}
                    onItemChange={handleUserChange}
                    error={null}
                    selector={'id'}
                />
            }
            <ReportActionPanel
                reportType={reportType}
                onFilterChange={setIsNewFilter}
            />
            <ReportPeriodPanel
                fromDate={plannerStore.reportFilters.fromDate}
                tillDate={plannerStore.reportFilters.tillDate}
                onFromDateChange={handleFromDateChange}
                onTillDateChange={handleTillDateChange}
                onFromDateClear={handleFromDateClear}
                onTillDateClear={handleTillDateClear}
            />
            <button
                className='tw-w-full tw-my-4 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold
                        tw-border-gray-600  tw-bg-gray-600 tw-text-white hover:tw-bg-gray-500 hover:tw-border-gray-500
                        disabled:tw-bg-gray-300 disabled:tw-border-gray-300
                        focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                disabled={isButtonDisabled}
                onClick={onSubmitClick}
            >
                Применить
            </button>
        </div>
    )
}

export default observer(ReportPlannerFilter)