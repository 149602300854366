import { makeAutoObservable, runInAction } from "mobx"
import DocumentService from "../services/DocumentService"
import { showErrorToast } from "../functions/errorHandlers"
import { maxFieldHierarchyLevel, responseTimeOut, serviceMessageTimeOut } from "../config/constTypes"
import { toast } from "react-toastify"

/**
 * Класс реализует хранилище информации о полях таблиц
 * @class
 * 
 * @property {Object} dummyField Поле заглушка
 * @property {Object} selectedField Выбранное поле
 * @property {Object[]} fieldsCollection Массив полей
 * @property {String} editionMode Режим
 * @property {Boolean} isLoading Загрузка
 * @property {Boolean} error Ошибка
 * @property {String} errorMessage Сообщение об ошибке
 * @property {String} successMessage Сообщение об успехе
 * @property {Boolean} isInfoOpen Видимость информации о поле
 * @property {Boolean} isFormOpen Видимость формы редактирования поля
 * @property {Object[]} fieldFilterObject Массив полей фильтрации
 */
class FieldStore {
    dummyField = {id: '0', full_name: 'Выберите поле ', alias: 'или создайте новое'}
    selectedField = this.dummyField
    fieldsCollection = []
    editionMode = "none"
    isLoading = true
    isInfoOpen = false
    isFormOpen = false
    fieldFilterObject = {fields: [
        {
            full_name: "Полное имя",
            tech_name: "full_name",
            validator_type: "string"
        },
        {
            full_name: "Псевдоним",
            tech_name: "alias",
            validator_type: "string"
        },
        {
            full_name: "Тип",
            tech_name: "validator_type",
            validator_type: "string"
        },
        {
            full_name: "Уникальное",
            tech_name: "unique",
            validator_type: "bool"
        },
        {
            full_name: "Обязательное",
            tech_name: "mandatory",
            validator_type: "bool"
        },
        {
            full_name: "Из конфигурации",
            tech_name: "built_in",
            validator_type: "bool"
        },
        {
            full_name: "Дата создания",
            tech_name: "created",
            validator_type: "date"
        },
        {
            full_name: "Дата изменения",
            tech_name: "last_modified",
            validator_type: "date"
        }
    ]}

    /**
    * Конструктор с указанием, что все свойства класса observable
    * @constructor
    */
    constructor(){
        makeAutoObservable(this) 
    }

    /**
     * Метод осуществляет получение полей
     * @method
     * 
     * @param {Object} field Выбранное поле
     * @param {Object[]} filters Примененные фильтры
     */
    async getAllFields (field, filters) {
        runInAction(() => {
            this.isLoading = true
        })
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            runInAction(() => {
                this.isLoading = false
            })
        }, responseTimeOut)
       
        DocumentService
            .getFields(filters)
            .then(data => {
                clearTimeout(noResponse)
                runInAction(() => {
                    this.fieldsCollection = data
                    const foundField = data.find(item => item.full_name === field.full_name)
                    this.selectedField = foundField || this.dummyField
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
            .finally(() => runInAction(() => {this.isLoading = false}))
    }

    /**
     * Метод осуществляет получение полей
     * @method
     * 
     * @param {Object} field Выбранное поле
     * @param {Object[]} filters Примененные фильтры
     */
    async getFieldByID (fieldID) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setIsLoading(false)
        }, responseTimeOut)

        this.setIsLoading(true)

        try {
            const foundField = await DocumentService.getOneField(fieldID)
            clearTimeout(noResponse)
            return foundField
    
        } catch (error) {
            clearTimeout(noResponse)
            showErrorToast(error, 'fetching', '')
        
        } finally {
            this.setIsLoading(false)
        }

        return null
    }

    /**
     * Метод осуществляет редактирование поля
     * @method
     * 
     * @param {Object} editedField Редактируемое поле
     * @param {Object[]} filters Примененные фильтры
     */
    async updateField (editedField, filters) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        try {
            await DocumentService.updateField(this.selectedField.field_id, editedField)
            clearTimeout(noResponse)
            this.getAllFields(this.selectedField, filters)
            toast.success('Поле успешно сохранено', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setEditionMode('none')
            
            return this.selectedField.field_id
        } catch (error) {
            clearTimeout(noResponse)
            if (error?.response?.status === 409) {
                    toast.error(<div>Ошибка при сохранении поля!<br/><br/>Поле с таким названием уже существует</div>, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            } else {
                showErrorToast(error, 'saving', '')
            }
        }
            
        return null
    }

    /**
     * Метод осуществляет создание поля
     * @method
     * 
     * @param {Object} newField Создаваемое поле
     * @param {Object[]} filters Примененные фильтры
     */
    async createField (newField, filters) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        try {
            const createdField = await DocumentService.createField(newField)
            clearTimeout(noResponse)
            toast.success('Поле успешно сохранено', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.getAllFields(newField, filters)
            this.setEditionMode('none')
        
            return createdField.id
        } catch (error) {
            clearTimeout(noResponse)
            if (error?.response?.status === 409) {
                toast.error('Ошибка при сохранении поля! Поле с таким названием уже существует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            } else {
                showErrorToast(error, 'saving', '')
            }        
        }

        return null
    }

    /**
     * Метод осуществляет сохранение информации о выбранном поле
     * @method
     * 
     * @param {Object} field Выбранное поле
     */
    setSelectedField(field) {
        this.selectedField = field
    }
    
    /**
     * Метод осуществляет сохранение информации о массиве существующих полей
     * @method
     * 
     * @param {Object[]} fields Массив полей
     */
    setFieldsCollection(fields) {
        this.fieldsCollection = fields
    }

    /**
     * Метод осуществляет сохранение информации о режиме редактора полей
     * @method
     * 
     * @param {String} mode Режим
     */
    setEditionMode(mode) {
        this.editionMode = mode
    }

    /**
     * Метод осуществляет сохранение информации о загрузке
     * @method
     * 
     * @param {Boolean} value Значение
     */
    setIsLoading(value) {
        this.isLoading = value
    }

    /**
     * Метод осуществляет сохранение информации об отображении информации поля
     * @method
     * 
     * @param {Boolean} value Значение
     */
    setIsInfoOpen(value) {
        this.isInfoOpen = value
    }

    /**
     * Метод осуществляет сохранение информации об отображении формы
     * @method
     * 
     * @param {Boolean} value Значение
     */
    setIsFormOpen(value) {
        this.isFormOpen = value
    }

    /**
     * Метод осуществляет формирование тэга выбранного поля для шаблона печатной формы
     * @method
     * 
     * @param {String} dataModelID ID выбранной таблицы
     */
    getFieldTag(field) {
        const updatedName = field.alias
                                .toLocaleLowerCase()
                                .replace(/[!@#№;%:&*()=+\-\s]/g, '_')
                                .replace(/_{2,}/g, '_')
                                .replace(/^_|_$/g, '')
                                    
        return updatedName
    }
    
    /**
     * Метод осуществляет добавление дочерних полей ссылочного поля
     * @method
     * 
     * @param {Object[]} fields Отображаемые поля
     * @param {Object} field Текущее поле
     */
    async showTreeNode(fields, field) {
        let editedTags = fields.slice()
        try {
            const dataModel = await DocumentService.getOneDataModel(field.ref_model_ids[0])
            editedTags = fields.map(item => item.rule_id === field.rule_id ? {...item, hierarchyVisible: true} : item)
            if (dataModel) {
                const currentLevel = field.hierarchyLevel + 1
                const updatedData = dataModel.fields.map(item => (
                    {...item,
                        hierarchyVisible: false,
                        hierarchyLevel: currentLevel,
                        parent_rule_id: field.rule_id,
                        parent_prefix: ['include', 'reference'].includes(field.type)
                                        ?   field.parent_prefix + this.getFieldTag(field) + 
                                            (field.validator_type === 'many'
                                            ?   '[index' + currentLevel + '].'
                                            :   '.')
                                        :   ''
                    }
                ))
                    .filter(item => item.hierarchyLevel < maxFieldHierarchyLevel || !['include', 'reference'].includes(item.type))
                const parentIndex = fields.findIndex(item => item.rule_id === field.rule_id)
                editedTags.splice(parentIndex + 1, 0, ...updatedData)
            }                    
        } catch (error) {
            showErrorToast(error, 'fetching', '')
        }

        return editedTags
    }

    /**
     * Метод осуществляет скрытие всех дочерних полей текущего поля
     * @method
     * 
     * @param {Object[]} fields Отображаемые поля
     * @param {Object} field Текущее поле
     */
    hideTreeNode(fields, field) {
        let hiddenRecords = [field.rule_id]
        fields.forEach(item => {
            if (hiddenRecords.includes(item.parent_rule_id))
                hiddenRecords.push(item.rule_id)
        })
        const editedDataObjects = fields
                                    .map(item => item.rule_id === field.rule_id ? {...item, hierarchyVisible: false} : item)
                                    .filter(item => !hiddenRecords.includes(item.parent_rule_id))

        return editedDataObjects
    }    
}

export default FieldStore