import React, { useContext } from 'react'
import DataModelCategory from './DataModelCategory'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'


/**
 * Визуальный компонент отображает список существующих категорий таблиц
 * 
 * @param {Object} selectedDataModel Выбранная таблица
 * @param {Function} onItemClick Обработчик клика мыши на таблице
 * @param {Function} onItemDoubleClick Обработчик двойного клика мыши на таблице
 * 
 */
const DataModelCategoryList = ({selectedDataModel, onItemClick, onItemDoubleClick}) => {
    const { docStore, userStore } = useContext(Context)
    return (
        <div  id='categories-list-items' className='tw-flex tw-flex-col tw-h-[calc(100%_-_3rem)] tw-w-full tw-overflow-auto'>
            { docStore.categories.map((categoryItem, index) => 
                <DataModelCategory
                    key={index}
                    category={categoryItem}
                    selectedDataModel={selectedDataModel}
                    onItemClick={onItemClick}
                    onItemDoubleClick={onItemDoubleClick}
                />
            )}
            { userStore.checkPermission("data:access") &&
                <DataModelCategory
                    category={{id: '0', name: 'Без категории', order: (docStore.categories.length + 1)}}
                    selectedDataModel={selectedDataModel}
                    onItemClick={onItemClick}
                    onItemDoubleClick={onItemDoubleClick}
                />
            }
        </div>
    )
}

export default observer(DataModelCategoryList)