import React, { useContext, useEffect } from 'react'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import Spinner from '../../../assets/Spinner'

/**
 * Визуальный компонент отображает название одной компании для списка существующих компаний
 * 
 * @param {Object} item Текущий элемент списка компаний 
 * @param {Object[]} elements Массив ссылок на элементы списка компаний 
 * 
 */
const CompanyItem = ({item, elements}) => {
    const { AdminStore } = useContext(Context)

    const onItemClick = (subCompany) => {
        if (AdminStore.selectedSubCompany?.id !== subCompany.id) {
            AdminStore.getSubCompanyUsers(subCompany.id)
            AdminStore.setSelectedSubCompany(subCompany)
            AdminStore.setSelectedUser(null)
            AdminStore.setIsUserCreating(false)
            AdminStore.setIsUserEditing(false)
        } else {
            AdminStore.setSelectedSubCompany(null)
        }
    }

    const hideOther = (id) => {
        const items = elements.filter((elm) => {
            return elm.current.getAttribute("data-id") !== id.toString()
        })
        items.forEach((elm) => {
            if (elm.current.getAttribute("data-open") === 'true') {
                elm.current.click()
            }
        })
    }

    useEffect(() => {
        hideOther(item.id)
        AdminStore.setSelectedCompany(item)
        AdminStore.getSubCompanies(item.id)
    }, [])
    
    return (
        <>
            {AdminStore.isSubCompaniesLoading
                ? <div className='tw-w-24 tw-pl-6'><Spinner size='small'/></div>
                : <>
                    {AdminStore.subCompanies.slice().sort((a) => a.is_active ? -1 : 1).map((subCompany) => 
                        <div
                            key={subCompany.id}
                            id={`system-sub-company-item-${subCompany.id}`}
                            className={`${!subCompany.is_active && 'tw-line-through tw-text-red-400'} tw-text-sm tw-py-2 tw-rounded-md tw-border 
                                tw-border-gray-300 hover:tw-cursor-pointer
                                ${(AdminStore.selectedSubCompany && AdminStore.selectedSubCompany.id === subCompany.id) 
                                    ? ` tw-text-white tw-bg-gray-500`
                                    : ` tw-text-gray-900 tw-bg-white hover:tw-bg-gray-200 hover:tw-border-gray-300`
                                }
                            `}
                            onClick={() => onItemClick(subCompany)}
                        >
                            <div className='tw-px-4 tw-truncate tw-w-full'>
                                {subCompany.name}
                            </div>
                        </div>
                    )}
                </>
            }
        </>
    )
}

export default observer(CompanyItem)