import React, { useContext } from 'react'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import Spinner from '../../../assets/Spinner'

/**
 * Визуальный компонент отображает список юр лиц пользователя
 * 
 */
function SubCompaniesPanel() {
    const { userStore } = useContext(Context)

    const onSubCompanyClick = (id) => {
        if (userStore.user.curr_sub_company_id !== id)
            userStore.changeCurrentSubCompany(id)
    }

    if (userStore.isLoading)
        return <div className='tw-flex tw-justify-center'><Spinner size={'small'}/></div>

    return (
        <>
            {userStore.userSubCompanies.length > 0
                ?   <>
                        {userStore.userSubCompanies.map((item, index) => (
                            <div
                                key={index}
                                className={`${userStore.user.curr_sub_company_id === item.id ? 'tw-bg-gray-300 tw-cursor-default' 
                                    : ' tw-cursor-pointer hover:tw-bg-gray-200'} tw-px-4 tw-py-2 focus:tw-outline-none focus-visible:tw-ring 
                                    tw-break-words
                                `}
                                onClick={() => onSubCompanyClick(item.id)}
                            >
                                <p>
                                    {item.name}{item.abbreviation ? ' (' + item.abbreviation + ')' : ''}
                                </p>
                            </div>
                        ))}
                    </>
                : <div className='tw-px-4 tw-py-2 focus:tw-outline-none focus-visible:tw-ring tw-break-words'></div>
            }
        </>        
    )
}

export default observer(SubCompaniesPanel)
